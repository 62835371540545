import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { OutdoorAirAccessories } from './constants/outdoor-air-accessories.const';

@Component({
  selector: 'app-outdoor-air-accessories-page',
  templateUrl: './outdoor-air-accessories-page.component.html',
  styleUrls: ['./outdoor-air-accessories-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OutdoorAirAccessoriesPageComponent implements OnInit {
  accessories = OutdoorAirAccessories;

  constructor() {}

  ngOnInit() {}
}
