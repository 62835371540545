import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductInfoDialogComponent } from './dialogs/product-info-dialog/product-info-dialog.component';
import { SizeChartDialogComponent } from './dialogs/size-chart-dialog/size-chart-dialog.component';
import { ValidSizeWarningDialogComponent } from './dialogs/valid-size-warning-dialog/valid-size-warning-dialog.component';
import { EcapsCoreModule } from '../ecaps-core/ecaps-core.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectionsModule } from '../selections/selections.module';
import { ChartingModule } from './charting/charting.module';
import { CatalogService } from './services/catalog.service';
import { DescriptionService } from './services/description.service';
import { DrawingService } from './services/drawing.service';
import { ProductsService } from './services/products.service';
import { Products } from './controllers/products.controller';
import { SizeChartDialog } from './controllers/size-chart-dialog.controller';
import { ProductInfoDialog } from './controllers/product-info-dialog.controller';
import { SideDialogModule } from '../side-dialog/side-dialog.module';
import { DocumentsService } from '../documents/services/documents.service';
import { DocumentsModule } from '../documents/documents.module';
import { LouverCertificationDialogComponent } from './dialogs/louver-certification-dialog/louver-certification-dialog.component';
import { LouverCertificationDialog } from './controllers/louver-certification-dialog.controller';
import { AmcaSealComponent } from './components/amca-seal/amca-seal.component';
import { AnalyticsModule } from '../analytics/analytics.module';

@NgModule({
  imports: [
    CommonModule,
    ChartingModule,
    EcapsCoreModule,
    ReactiveFormsModule,
    MatDialogModule,
    SelectionsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    SideDialogModule,
    MatTabsModule,
    MatTableModule,
    MatCardModule,
    DocumentsModule,
    MatRippleModule,
    MatTooltipModule,
    AnalyticsModule,
  ],
  declarations: [
    ProductInfoDialogComponent,
    SizeChartDialogComponent,
    ValidSizeWarningDialogComponent,
    LouverCertificationDialogComponent,
    AmcaSealComponent,
  ],
  providers: [
    CatalogService,
    DescriptionService,
    DrawingService,
    ProductsService,
    DocumentsService,
    Products,
    SizeChartDialog,
    ProductInfoDialog,
    LouverCertificationDialog,
  ],
})
export class ProductsModule {}
