import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[ghSpecialChars]'
})
export class GreenheckSpecialCharactersDirective {

  private readonly badChars = '\\/:*?"<>|!~@&#%^[]{}()_+=$;,`';

  constructor(private element: ElementRef,
    private control: NgControl) { }

  @HostListener('keypress', ['$event'])
  onKeypress($event: KeyboardEvent) {

    return (this.badChars.indexOf($event.key) === -1);

  }

  @HostListener('change')
  onChange() {

    if (!this.control.control) {

      const value = this.element.nativeElement.value;

      for (let i = 0; i < value.length; i++) {

        if (this.badChars.indexOf(value[i]) !== -1) {

          this.element.nativeElement.value = '';

          return;

        }

      }

    } else {

      const value = this.control.control.value;

      for (let i = 0; i < value.length; i++) {

        if (this.badChars.indexOf(value[i]) !== -1) {

          this.control.control.setErrors({

            errorMessage: 'Invalid Characters'

          });

          return;

        }

      }

    }

  }

}
