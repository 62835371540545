import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { ColumnList } from '../constants/column-list.const';
import { ProductTypes } from '../../../../products/enums/product-types.enum';

@Component({
  selector: 'louvers-equipment-schedule-grid',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LouversEquipmentScheduleGridComponent
  extends BaseGridComponent
  implements OnInit
{
  constructor(protected injector: Injector) {
    super(injector);

    this.productType = ProductTypes.louver;
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'location',
      'areaServed',
      'model',
      'application',
      'suggestedWidth',
      'suggestedHeight',
      'actualDepth',
      'actualVolume',
      'actualPressureDrop',
      'actualVelocity',
      'actualFreeArea',
      'quantity',
      'mfg',
      'freeAreaPercentage',
      'modelGroup',
      'maxVelocity',
      'weight',
      'sectionsWide',
      'sectionsHigh'
    );

    this.setVisibleColumns([
      'location',
      'areaServed',
      'model',
      'application',
      'suggestedWidth',
      'suggestedHeight',
      'actualDepth',
      'actualVolume',
      'actualPressureDrop',
      'actualVelocity',
      'actualFreeArea',
    ]);
  }
}
