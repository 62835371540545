import { ValidatorFn, AbstractControl } from '@angular/forms';
import { DecimalPipe } from '@angular/common';

export function numericValidator(allowDecimal: boolean): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        if (!!control.value && !isNaN(control.value)) {

            const hasDecimal = (control.value.toString().indexOf('.') > -1);

            return (hasDecimal && !allowDecimal ? { errorMessage: 'Whole number required' } : null);

        } else if (!!control.value) {

            return { errorMessage: 'Invalid entry' };

        }

    };

}

export function minMaxNullValidator(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {

        const decimalFormatter = new DecimalPipe('en_us');

        if (!control.value || isNaN(control.value) || control.value === '') {

            let errorMessage = 'Must be ';

            if ((typeof (min) === 'undefined' || min === null)) {

                errorMessage += `less than ${decimalFormatter.transform(max)}`;

            } else if ((typeof (max) === 'undefined' || max === null)) {

                errorMessage += `greater than ${decimalFormatter.transform(min)}`;

            } else {

                errorMessage += `between ${decimalFormatter.transform(min)} and ${decimalFormatter.transform(max)}`;

            }

            return { errorMessage: errorMessage };

        }

    };

}
