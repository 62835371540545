import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { LachesisService } from '../../analytics/services/lachesis.service';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { GoogleAnalyticsService } from '../../google/services/google-analytics.service';
import { UserData } from '../../users/models/user-data.model';
import { HttpService } from './http.service';

export interface ProfileData {
  Email: string;
  CompanyName: string;
  FirstName: string;
  LastName: string;
  JobTitle: string;
  PhoneNumber: string;
  PostalCode: string;
  State: string;
  City: string;
  CompanyDescription: string;
  Address1: string;
  Address2: string;
  Address3: string;
  UserType: string;
  AccountEnabled: boolean;
  deleted: boolean;
  privacystatementaccepted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authTokenUpdated = new EventEmitter<Record<string, string> | null>();
  public currentUserUpdated = new EventEmitter<UserData>();

  private authToken: Record<string, string> | null;
  private currentUser: UserData;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private http: HttpService,
    private core: EcapsCore,
    private analytics: GoogleAnalyticsService,
    private lachesisService: LachesisService
  ) {
    this.authToken = null;

    this.currentUser = new UserData();
    this.currentUser.authenticated = false;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const signingIn = this.router.isActive('sign-in/cb', true);
        const signingOut = this.router.isActive('sign-out/cb', true);

        if (signingIn) {
          const searchParams = new URLSearchParams(
            this.router.getCurrentNavigation().extractedUrl.fragment
          );

          let nonce = window.localStorage.getItem('authNonce');
          const queryNonce = searchParams.get('nonce');

          window.localStorage.removeItem('authNonce');

          if (!nonce || nonce !== queryNonce) {
            console.error(
              `Nonce mismatch: stored - ${nonce} | query - ${queryNonce}`
            );

            if (!nonce && !!queryNonce) {
              nonce = queryNonce;
            }
          }

          const state: Record<string, any> =
            (() => {
              try {
                if (!nonce || !searchParams.has('state')) {
                  return;
                }

                const encodedState = searchParams.get('state');
                const decodedState = atob(encodedState);
                const unverifiedState = JSON.parse(decodedState);

                if (!nonce || !unverifiedState[nonce]) {
                  const newNonce = Object.keys(unverifiedState).find(
                    (key) => !!unverifiedState[key]?.originalUrl
                  );

                  console.error(
                    `Invalid nonce: Provided - ${nonce} | Found - ${newNonce}`
                  );

                  nonce = newNonce;
                }

                if (!unverifiedState[nonce]) {
                  return;
                }

                return unverifiedState[nonce];
              } catch {
                return;
              }
            })() || {};

          this.authToken = {};
          searchParams.forEach((value, key) => {
            this.authToken[key] = value;
          });

          window.localStorage.setItem(
            'authToken',
            JSON.stringify(this.authToken)
          );

          this.updateAnalytics();

          this.authTokenUpdated.next(this.authToken);

          this.router.navigateByUrl(state.originalUrl || '');
        } else if (signingOut) {
          this.updateAnalytics();
          this.resetAuthentication();
          this.router.navigateByUrl('');
        } else {
          this.loadAuthToken();

          this.validateUser();
        }
      }
    });
  }

  public loadUser() {
    this.loadAuthToken();

    return this.validateUser();
  }

  public loadAuthToken(force = false): Record<string, string> | undefined {
    if (this.authToken && !force) {
      return this.authToken;
    } else {
      const content = window.localStorage.getItem('authToken');
      const userToken = content && JSON.parse(content);

      if (userToken) {
        this.authToken = userToken;
        this.authTokenUpdated.next(this.authToken);

        return this.authToken;
      }
    }
  }

  public resetAuthentication() {
    this.authToken = null;
    window.localStorage.removeItem('authToken');
    this.authTokenUpdated.next(this.authToken);

    this.currentUser = new UserData();
    this.currentUser.authenticated = false;
    this.updateAnalytics();
    this.currentUserUpdated.next(this.currentUser);
  }

  public validateUser(autoRenew = false, showSessionExpired = true) {
    return new Promise<UserData>((results, reject) => {
      if (!this.authToken) {
        this.currentUser = new UserData();

        this.currentUser.authenticated = false;
        this.currentUser.message = 'Invalid JWT';
        this.updateAnalytics();

        this.currentUserUpdated.next(this.currentUser);

        results(this.currentUser);
      } else {
        firstValueFrom(
          this.http.get<any>('/authentication/validate', {
            headers: {
              Authorization: `Bearer ${this.authToken.id_token}`,
              'x-user-token': `${this.currentUser?.token ?? ''}`,
            },
          })
        ).then(
          (data) => {
            this.currentUser = new UserData();

            if (data.authenticated) {
              this.currentUser.authenticated = true;
              this.currentUser.email = data.username;

              for (let i = 0; i < data.details.length; i++) {
                switch (data.details[i].name.toLowerCase()) {
                  case 'firstname': {
                    this.currentUser.firstName = data.details[i].value;
                    break;
                  }
                  case 'lastname': {
                    this.currentUser.lastName = data.details[i].value;
                    break;
                  }
                  case 'salutation': {
                    this.currentUser.salutation = data.details[i].value;
                    break;
                  }
                  case 'suffix': {
                    this.currentUser.suffix = data.details[i].value;
                    break;
                  }
                  case 'phone': {
                    this.currentUser.phone = data.details[i].value;
                    break;
                  }
                }
              }

              this.currentUser.id = data.id;
              this.currentUser.lastLogin = data.lastLogin
                ? new Date(data.lastLogin)
                : null;
              this.currentUser.token = data.token;
            } else if (!data.authenticated || !data.authorized) {
              this.currentUser.authenticated = false;
              this.currentUser.message = data.message;
            }

            if (this.currentUser.authenticated) {
              this.getProfileData()
                .then((profileData) => {
                  this.currentUser.firstName = profileData.FirstName;
                  this.currentUser.lastName = profileData.LastName;
                  this.currentUser.phone = profileData.PhoneNumber;
                  this.updateAnalytics();

                  this.currentUserUpdated.next(this.currentUser);
                  results(this.currentUser);
                })
                .catch((err) => {
                  this.currentUser.message = err.message;
                  this.updateAnalytics();

                  this.currentUserUpdated.next(this.currentUser);
                  results(this.currentUser);
                });
            } else {
              this.updateAnalytics();
              this.currentUserUpdated.next(this.currentUser);
              results(this.currentUser);
            }
          },
          (errorData) => {
            if (window.location.pathname.indexOf('/revit/') === 0) {
              this.login();
            } else if (autoRenew) {
              this.login();
            } else if (showSessionExpired) {
              this.core.hideLoadingGraphic(true);

              if (!showSessionExpired) {
                showSessionExpired = true;

                this.core
                  .showMessageBox({
                    title: 'Session Expired',
                    message:
                      'You have been signed out.  Do you wish to sign back in?',
                    icon: 'info_outline',
                    buttons: [
                      {
                        title: 'Yes',
                        value: true,
                      },
                      {
                        title: 'No',
                        value: false,
                      },
                    ],
                  })
                  .then((success) => {
                    if (!success) {
                      this.resetAuthentication();
                    } else {
                      this.login();
                    }

                    results(this.currentUser);

                    showSessionExpired = false;
                  });
              }
            } else {
              reject(errorData);
            }
          }
        );
      }
    });
  }

  private getProfileData() {
    return new Promise<ProfileData>((results, reject) => {
      if (!this.authToken) {
        reject(new Error('Invalid JWT'));
      } else {
        firstValueFrom(
          this.httpClient.get<{ profiledata: string; sub: string }>(
            '/userinfo',
            {
              headers: {
                Authorization: `Bearer ${this.authToken.access_token}`,
              },
            }
          )
        ).then(
          (data) => {
            const profileData = JSON.parse(data.profiledata);

            results(profileData);
          },
          (errorData) => {
            reject(errorData);
          }
        );
      }
    });
  }

  public isValid() {
    return (
      !!this.authToken && this.currentUser && this.currentUser.authenticated
    );
  }

  login() {
    this.lachesisService.trackEvent({
      eventName: 'Login',
    });

    const nonce = uuid().replace(/-/g, '');
    const originalUrl = window.location.pathname;

    window.localStorage.setItem('authNonce', nonce);

    window.location.href = `/sign-in?nonce=${nonce}&originalUrl=${originalUrl}`;
  }

  logout() {
    const authToken = this.loadAuthToken();
    if (authToken) {
      this.resetAuthentication();

      window.location.href = `/sign-out?id_token=${authToken.id_token}&access_token=${authToken.access_token}`;
    }
  }

  private updateAnalytics() {
    if (
      this.lachesisService.isUserLoggedIn !== this.currentUser.authenticated
    ) {
      this.lachesisService.isUserLoggedIn = this.currentUser.authenticated;
    }
    this.lachesisService.isUserValidated = true;

    if (this.analytics.loggedIn !== this.currentUser.authenticated) {
      this.analytics.loggedIn = this.currentUser.authenticated;

      if (this.analytics.loggedIn) {
        this.analytics.trackEvent('login');
      } else {
        this.analytics.trackEvent('logout');
      }
    }
  }
}
