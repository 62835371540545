import {
  animate,
  sequence,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProjectRevisionUpdateTypes } from '../../enums/project-revision-update-types.enum';
import { IProjectEvent } from '../../models/i-project-event.interface';
import { Item } from '../../models/project-item.model';
import { Project } from '../../models/project.model';
import { ProjectsService } from '../../services/projects.service';

@Component({
  selector: 'job-quick-view',
  templateUrl: './job-quick-view.component.html',
  styleUrls: ['./job-quick-view.component.scss'],
  animations: [
    trigger('showHide', [
      state(
        'show',
        style({
          display: 'block',
          opacity: 1,
        })
      ),
      state(
        'hide',
        style({
          display: 'none',
        })
      ),
      transition('show => hide', [
        sequence([
          style({
            display: 'block',
            opacity: 1,
          }),
          animate('0.5s', style({ opacity: 0 })),
          style({
            display: 'none',
          }),
        ]),
      ]),
      transition('hide => show', [
        sequence([
          style({
            display: 'block',
            opacity: 0,
          }),
          animate('0.5s', style({ opacity: 1 })),
        ]),
      ]),
    ]),
  ],
})
export class JobQuickViewComponent implements OnInit, OnDestroy {
  isVisible = false;
  top = 0;
  left = 0;

  localProject: Project;
  private localProjectSubscription: Subscription;
  private projectChangeSubscription: Subscription;

  mouseover = false;

  newItem: Item;

  constructor(
    private projectsService: ProjectsService,
    private router: Router
  ) {
    this.projectsService.getLocalProject().then((project) => {
      this.localProject = project;

      this.watchForChanges();
    });

    this.localProjectSubscription =
      this.projectsService.localProjectUpdated.subscribe((project) => {
        this.localProject = project;

        this.watchForChanges();
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.localProjectSubscription.unsubscribe();

    if (!!this.projectChangeSubscription) {
      this.projectChangeSubscription.unsubscribe();
    }
  }

  private watchForChanges() {
    if (!!this.projectChangeSubscription) {
      this.projectChangeSubscription.unsubscribe();

      this.projectChangeSubscription = null;
    }

    if (!!this.localProject) {
      this.projectChangeSubscription = this.localProject
        .getActiveRevision()
        .updated.subscribe((event: IProjectEvent) => {
          if (event.type === ProjectRevisionUpdateTypes.itemAdded) {
            this.newItem =
              this.localProject.getActiveRevision().items[
                this.localProject.getActiveRevision().items.length - 1
              ];

            this.show();
          }
        });
    }
  }

  mouseout() {
    const self = this;

    self.mouseover = false;

    setTimeout(() => {
      self.hide();
    }, 1000);
  }

  show() {
    const results = document.getElementsByClassName('equip-sched-icon');

    if (results.length === 0) {
      this.isVisible = false;

      return;
    }

    const equipmentScheduleIcon = results[0];

    this.top = (<HTMLElement>equipmentScheduleIcon).offsetTop + 12;
    this.left = (<HTMLElement>equipmentScheduleIcon).offsetWidth + 12;

    this.isVisible = true;

    const self = this;

    setTimeout(() => {
      self.hide();
    }, 5000);
  }

  hide() {
    if (!this.mouseover) {
      this.isVisible = false;
    }
  }

  itemClick() {
    this.isVisible = false;

    this.router.navigate([`/equipmentschedule/${this.newItem.type}`]);
  }
}
