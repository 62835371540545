import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectsService } from '../../projects/services/projects.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Item } from '../../projects/models/project-item.model';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { Project } from '../../projects/models/project.model';
import { RevitCommsService } from '../../external-communication/services/revit-comms.service';
import { DocumentsService } from '../../documents/services/documents.service';
import { DocumentTypes } from '../../documents/enums/document-types.enum';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../google/services/google-analytics.service';
import { LachesisService } from '../../analytics/services/lachesis.service';

interface IDataRow {
  id: string;
  tag: string;
  model: string;
  quantity: number;
  item: Item;
  type: number;
}

@Component({
  selector: 'app-revit-job-marks-page',
  templateUrl: './revit-job-marks-page.component.html',
  styleUrls: ['./revit-job-marks-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RevitJobMarksPageComponent implements OnInit {
  insertSelection = new SelectionModel<IDataRow>(true, []);

  @ViewChild('insertTableSort', { static: true }) insertSort: MatSort;

  insertDS = new MatTableDataSource<IDataRow>([]);

  insertDisplayedColumns: string[] = ['select', 'tag', 'model', 'quantity'];

  downloadSelection = new SelectionModel<IDataRow>(true, []);

  @ViewChild('downloadTableSort', { static: true }) downloadSort: MatSort;

  downloadDS = new MatTableDataSource<IDataRow>([]);

  downloadDisplayedColumns: string[] = [
    'select',
    'tag',
    'model',
    'type',
    'quantity',
  ];

  jobId: string;

  selectedProject: Project;

  revitVersion: string;
  pluginVersion: string;

  insertDrawing = true;

  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private core: EcapsCore,
    private revitComms: RevitCommsService,
    private documentsService: DocumentsService,
    private analytics: GoogleAnalyticsService,
    private lachesisService: LachesisService
  ) {
    this.jobId = this.route.snapshot.params.jobId;
    this.revitVersion = this.route.snapshot.params.revitVersion;
    this.pluginVersion = this.route.snapshot.params.pluginVersion;
  }

  ngOnInit(): void {
    this.insertDS.sort = this.insertSort;

    this.downloadDS.sort = this.downloadSort;

    let loading = true;

    this.core.showLoadingGraphic('Loading...', () => loading);

    this.analytics.trackEvent_Old(EventCategories.revitAddin, 'Job Loaded');

    this.lachesisService.trackEvent({
      eventName: 'Plugin Job Loaded Event',
      revitVersion: this.revitComms.revitVersion,
      revitPluginVersion: this.revitComms.revitPluginVersion,
    });

    this.projectsService.loadSavedProject(this.jobId).then((project) => {
      this.selectedProject = project;

      const revision = project.getActiveRevision();

      this.downloadDS.data = revision.items.map((item) => {
        let type = 2;

        if (item.revitInfo.hasContent && item.revitInfo.isDownloadable) {
          if (item.revitInfo.hasLod300) {
            type = 0;
          } else if (item.revitInfo.hasProject) {
            type = 1;
          }
        }

        return {
          id: item.id,
          tag: item.tag,
          model: item.name,
          type,
          quantity: item.quantity,
          item,
        };
      });

      this.insertDS.data = revision.items.map((item) => {
        let type = 2;

        if (item.revitInfo.hasContent && item.revitInfo.isDownloadable) {
          if (item.revitInfo.hasLod300) {
            type = 0;
          } else if (item.revitInfo.hasProject) {
            type = 1;
          }
        }

        return {
          id: item.id,
          tag: item.tag,
          model: item.name,
          quantity: item.quantity,
          item,
          type,
        };
      });

      loading = false;
    });
  }

  tabChange(event: MatTabChangeEvent) {
    switch (event.tab.textLabel.toLowerCase().trim()) {
      case 'insert': {
        this.insertDrawing = true;

        break;
      }
      case 'download': {
        this.insertDrawing = false;

        break;
      }
    }
  }

  isInsertAllSelected() {
    const numSelected = this.insertSelection.selected.length;
    const numRows = this.insertDS.data.length;
    return numSelected === numRows;
  }

  insertMasterToggle() {
    this.isInsertAllSelected()
      ? this.insertSelection.clear()
      : this.insertDS.data.forEach((row) => this.insertSelection.select(row));
  }

  insertCheckboxLabel(row?: any): string {
    if (!row) {
      return `${this.isInsertAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${
      this.insertSelection.isSelected(row) ? 'deselect' : 'select'
    } row ${row.position + 1}`;
  }

  isDownloadAllSelected() {
    const numSelected = this.downloadSelection.selected.length;
    const numRows = this.downloadDS.data.filter(
      (item) => item.type !== 2
    ).length;
    return numSelected === numRows;
  }

  downloadMasterToggle() {
    this.isDownloadAllSelected()
      ? this.downloadSelection.clear()
      : this.downloadDS.data
          .filter((item) => item.type !== 2)
          .forEach((row) => this.downloadSelection.select(row));
  }

  downloadCheckboxLabel(row?: any): string {
    if (!row) {
      return `${this.isDownloadAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${
      this.downloadSelection.isSelected(row) ? 'deselect' : 'select'
    } row ${row.position + 1}`;
  }

  insertDownloadClick(isInsert: boolean) {
    let generating = true;

    this.core.showLoadingGraphic('Generating...', () => generating);

    const idList: string[] = isInsert
      ? this.insertSelection.selected.map((item) => item.id)
      : this.downloadSelection.selected.map((item) => item.id);

    this.documentsService
      .getProjectDocuments(
        this.selectedProject,
        [DocumentTypes.revit300],
        idList,
        null,
        true
      )
      .then((results) => {
        generating = false;

        this.core.hideLoadingGraphic();

        const logAnalytics = (): void => {
          const activeRevision = this.selectedProject.getActiveRevision();

          if (!activeRevision || activeRevision.items.length === 0) {
            return;
          }

          const modelList = activeRevision.items
            .map((item) => {
              return {
                model: item.sizeData?.name,
                modelName: item.sizeData?.model,
              };
            })
            .filter((item) => !!item.model && !!item.modelName);

          this.analytics.trackEvent('Drawing', {
            source: 'Revit Job Marks',
            action: isInsert ? 'Insert' : 'Download',
            deliveryMethod: isInsert ? 'Insert' : 'Download',
            items: modelList,
          });
        };

        logAnalytics();

        if (isInsert) {
          this.revitComms.insertBlob(results.data, results.filename);
        } else {
          this.revitComms.downloadBlob(results.data, results.filename);
        }
      });
  }
}
