import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TextChangedEvent } from './models/text-changed-event.model';

@Component({
  selector: 'inline-editor',
  templateUrl: './inline-editor.component.html',
  styleUrls: ['./inline-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InlineEditorComponent implements OnInit {
  @Input('text') text: string;

  @Input('showDelete') showDelete: boolean;

  @Output('textChanged') private textChanged =
    new EventEmitter<TextChangedEvent>();

  @Output('deleteClicked') private deleteClicked = new EventEmitter<void>();

  editText = false;

  newText: string;

  @ViewChild('editor', { static: false }) private editor: ElementRef;

  constructor(private cdRef: ChangeDetectorRef, private element: ElementRef) {}

  ngOnInit() {}

  edit() {
    this.newText = this.text;

    this.editText = true;

    this.cdRef.detectChanges();

    this.editor?.nativeElement.focus();
  }

  closeEdit() {
    this.editText = false;

    this.cdRef.detectChanges();
  }

  @HostListener('window:focusout', ['$event'])
  onBlur(event: FocusEvent) {
    if (
      !!this.editor?.nativeElement &&
      event.target === this.editor.nativeElement &&
      !this.element.nativeElement.contains(event.relatedTarget)
    ) {
      this.commitChange();
    }
  }

  commitChange() {
    if (this.editText && (this.newText ?? '') !== (this.text ?? '')) {
      this.textChanged.next(
        new TextChangedEvent(this.text ?? '', this.newText ?? '')
      );

      this.text = this.newText;
    }

    this.closeEdit();
  }

  cancelChange() {
    this.newText = this.text;

    this.closeEdit();
  }

  delete() {
    this.deleteClicked.next();

    this.closeEdit();
  }
}
