import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProjectInfoDialogComponent } from './dialogs/project-info-dialog/project-info-dialog.component';
import { ProjectPropertiesDialogComponent } from './dialogs/project-properties-dialog/project-properties-dialog.component';
import { ProjectsService } from './services/projects.service';
import { EcapsCoreModule } from '../ecaps-core/ecaps-core.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleModule } from '../google/google.module';
import { JobManagementGridComponent } from './grids/job-management-grid/job-management-grid.component';
import { Projects } from './controllers/projects.controller';
import { JobConflictDialog } from './controllers/job-conflict-dialog.controller';
import { CreateNewProjectDialog } from './controllers/create-new-project-dialog.controller';
import { SideDialogModule } from '../side-dialog/side-dialog.module';
import { DocumentsModule } from '../documents/documents.module';
import { BaseGridComponent } from './grids/equipment-schedule/base-grid/base-grid.component';
import { FansEquipmentScheduleGridComponent } from './grids/equipment-schedule/fans-equipment-schedule-grid/fans-equipment-schedule-grid.component';
import { GravityEquipmentScheduleGridComponent } from './grids/equipment-schedule/gravity-equipment-schedule-grid/gravity-equipment-schedule-grid.component';
import { LouversEquipmentScheduleGridComponent } from './grids/equipment-schedule/louvers-equipment-schedule-grid/louvers-equipment-schedule-grid.component';
import { DoasEquipmentScheduleGridComponent } from './grids/equipment-schedule/doas-equipment-schedule-grid/doas-equipment-schedule-grid.component';
import { ProjectVersionHistoryComponent } from './grids/project-version-history/project-version-history.component';
import { ProjectRevisionDetailsComponent } from './grids/project-revision-details/project-revision-details.component';
import { ProjectPropertiesDialog } from './controllers/project-properties-dialog.controller';
import { CirculatorEquipmentScheduleGridComponent } from './grids/equipment-schedule/circulator-equipment-schedule-grid/circulator-equipment-schedule-grid.component';
import { JobQuickViewComponent } from './components/job-quick-view/job-quick-view.component';
import { ProjectInfoDialog } from './controllers/project-info-dialog.controller';
import { ReselectRequiredDialogComponent } from './dialogs/reselect-required-dialog/reselect-required-dialog.component';
import { MakeupairEquipmentScheduleGridComponent } from './grids/equipment-schedule/makeupair-equipment-schedule-grid/makeupair-equipment-schedule-grid.component';
import { AnalyticsModule } from '../analytics/analytics.module';
import { ErvEquipmentScheduleGridComponent } from './grids/equipment-schedule/erv-equipment-schedule-grid/erv-equipment-schedule-grid.component';

@NgModule({
    imports: [
        CommonModule,
        EcapsCoreModule,
        MatTableModule,
        MatSortModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatIconModule,
        GoogleModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatButtonModule,
        SideDialogModule,
        MatTabsModule,
        DocumentsModule,
        MatTooltipModule,
        MatMenuModule,
        MatListModule,
        MatDividerModule,
        MatToolbarModule,
        MatCardModule,
        MatProgressBarModule,
        AnalyticsModule,
    ],
    declarations: [
        ProjectInfoDialogComponent,
        ProjectPropertiesDialogComponent,
        JobManagementGridComponent,
        BaseGridComponent,
        FansEquipmentScheduleGridComponent,
        GravityEquipmentScheduleGridComponent,
        LouversEquipmentScheduleGridComponent,
        DoasEquipmentScheduleGridComponent,
        ProjectVersionHistoryComponent,
        ProjectRevisionDetailsComponent,
        CirculatorEquipmentScheduleGridComponent,
        JobQuickViewComponent,
        ReselectRequiredDialogComponent,
        MakeupairEquipmentScheduleGridComponent,
        ErvEquipmentScheduleGridComponent,
    ],
    providers: [
        ProjectsService,
        Projects,
        JobConflictDialog,
        CreateNewProjectDialog,
        ProjectPropertiesDialog,
        ProjectInfoDialog,
    ],
    exports: [
        JobManagementGridComponent,
        FansEquipmentScheduleGridComponent,
        MakeupairEquipmentScheduleGridComponent,
        GravityEquipmentScheduleGridComponent,
        LouversEquipmentScheduleGridComponent,
        DoasEquipmentScheduleGridComponent,
        CirculatorEquipmentScheduleGridComponent,
        ErvEquipmentScheduleGridComponent,
        JobQuickViewComponent,
    ]
})
export class ProjectsModule {}
