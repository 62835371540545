import { Component, OnInit, Input } from '@angular/core';
import { ValidSize } from '../../models/selection-results/valid-size.model';

@Component({
  selector: 'ds-performance-svg',
  templateUrl: './ds-performance-svg.component.html',
  styleUrls: ['./ds-performance-svg.component.scss'],
})
export class DsPerformanceSvgComponent implements OnInit {
  @Input('size') size: ValidSize;

  constructor() {}

  ngOnInit() {}

  getDSCoverageAreaTitle(): string {
    if (
      this.size.selectionLayoutConfig.getQuestion('SelectionMethod').value ===
      'ByFanSize'
    ) {
      return 'Maximum Coverage Area';
    } else {
      return 'Required Coverage Area';
    }
  }

  getDSFanCoverageArea(): string {
    if (
      this.size.selectionLayoutConfig.getQuestion('SelectionMethod').value ===
      'ByFanSize'
    ) {
      return this.size.outputs.coverageArea;
    } else {
      return this.size.outputs.affectedArea;
    }
  }

  getDSCoverageRadius(): number {
    if (
      this.size.selectionLayoutConfig.getQuestion('SelectionMethod').value ===
      'ByFanSize'
    ) {
      return parseFloat(this.size.outputs.coverageRadius);
    } else {
      return parseFloat(this.size.outputs.actualFanSpacing) / 2;
    }
  }
}
