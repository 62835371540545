import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[overflowTooltip]',
  providers: [MatTooltip],
})
export class OverflowTooltipDirective {
  @Input('overflowTooltip') overflowTooltip: string;
  @Input('overflowElement') overflowElement?: HTMLElement;

  @Input('matTooltipClass')
  get tooltipClass(): any {
    return this._tooltip.tooltipClass;
  }
  set tooltipClass(value: any) {
    this._tooltip.tooltipClass = value;
  }

  constructor(private _elementRef: ElementRef, private _tooltip: MatTooltip) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    let overFlowing = false;

    let overflowElement: HTMLElement =
      this.overflowElement || this._elementRef.nativeElement;

    if (!this.overflowElement) {
      switch (this._elementRef.nativeElement.tagName) {
        case 'MAT-LIST-OPTION': {
          overflowElement = this._elementRef.nativeElement.querySelector(
            '.mdc-list-item__content'
          ) as HTMLElement;

          break;
        }
      }
    }

    overFlowing = !!overflowElement
      ? overflowElement.offsetWidth < overflowElement.scrollWidth
      : false;

    if (overFlowing) {
      this._tooltip.message = this.overflowTooltip;
      this._tooltip.show();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this._tooltip.hide();
  }
}
