import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProductInfoDialogReturnTypes } from '../models/product-info-dialog-return-types.model';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { ValidSizeWarningDialogComponent } from '../dialogs/valid-size-warning-dialog/valid-size-warning-dialog.component';
import { ProductInfoDialog } from './product-info-dialog.controller';
import { Item } from '../../projects/models/project-item.model';

@Injectable({
  providedIn: 'root'
})
export class Products {
  constructor(
    private dialog: MatDialog,
    private productInfoDialog: ProductInfoDialog
  ) {}
  showProductInfo(
    size: ValidSize,
    tab: string = 'information',
    revisionItem?: Item,
    tag?: string,
    quantity?: number
  ): Promise<ProductInfoDialogReturnTypes> {
    return this.productInfoDialog.show(size, tab, revisionItem, tag, quantity);
  }

  showSizeChart(size: ValidSize): Promise<boolean> {
    return this.showSizeChart(size);
  }

  showValidSizeWarning(size: ValidSize): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = size;

      const dialogRef = this.dialog.open(
        ValidSizeWarningDialogComponent,
        dialogConfig
      );

      const subscription = dialogRef.beforeClosed().subscribe(() => {
        subscription.unsubscribe();

        resolve(true);
      });
    });

    return promise;
  }
}
