export const Certifications = [
  {
    selection: 'AMCA 500-L (Air)',
    description: `The AMCA 500-L Airflow Resistance test measures a louver's resistance to
            airflow under both intake and exhaust conditions. Pressure drop across the louver
            free area is measured and displayed as airflow resistance in units of inches water
            gauge at specific intake or exhaust velocity points. Nearly all Greenheck louvers
            have AMCA Certified Air Performance.`,
  },
  {
    selection: 'AMCA 500-L (Sound)',
    description: `The AMCA 500-L Sound test measures a louver's ability to absorb and reduce
            sound from behind the louver to the exterior. Airflow is not simulated during the
            test procedure. A noise generating station is positioned behind the louver test
            specimen while the sound decibel levels are measured directly in front of the louver
            test specimen. Louvers with AMCA Certified Sound Performance will boast published
            sound decibel reductions at varying octave band frequencies that indicate Free Field
            Noise Reduction. Transmission Loss is also published along with the overall Sound
            Transmission Class. All Greenheck acoustical louvers have AMCA Certified Sound
            performance.`,
  },
  {
    selection: 'AMCA 500-L (Water)',
    description: `The AMCA 500-L Water Penetration test measures a louver's resistance to
            water penetration during normal operating conditions. A wetted wall and water
            manifold above and in front of the tested louver specimen simulate rain falling
            straight down in front of the louver at a rate of 4 in. per hour. Within a fully
            enclosed pressurized chamber behind the louver, a fan attempts to pull the water
            droplets through the louver blades. The louver is measured by the Beginning Point
            of Water Penetration, which is defined as the free area velocity from 0 - 1250 FPM
            where 0.01 oz of water per square foot of louver free area is measurable behind the
            louver. Nearly all Greenheck louvers have AMCA Certified Water Penetration
            performance.`,
  },
  {
    selection: 'AMCA 500-L (WDR)',
    description: `The AMCA 500-L Wind Driven Rain test measures a louver's resistance to
            water penetration under more dynamic storm-like conditions than the AMCA 500-L Water
            Penetration test. A fan in front of the louver test specimen simulates external wind
            speeds of 29 and/or 50 MPH. Between the external fan and louver, spray nozzles
            simulate external rainfall at a rate(s) of 3 in. and/or 8 in. per hour (3 in. at 29
            MPH, 8 in. at 50 MPH). Within a fully enclosed pressurized chamber behind the louver,
            a fan attempts to pull the water droplets through the louver blades. The louver is
            measured by a grading system of Class A, B, C or D, which defines the water rejection
            percentage at specific escalating inlet velocity points (A = 99%, B = 95%-99%, C = 80%
            - 95%, D = <80%). Many Greenheck louvers have AMCA Certified Wind Driven Rain
            performance, but the test is reserved for Wind Driven Rain louvers.`,
  },
  {
    selection: 'AMCA-540 (Debris)',
    description: `The AMCA-540 Test Method for Louvers Impacted by Wind Borne Debris is a
            requirement of the International Building Code and Florida Building code for all
            intake and exhaust louvers located within the Wind Borne Debris Region of the
            Hurricane Prone Region as defined by the International Building Code that are also
            installed within 30 ft. above grade. AMCA 540 simulates two different missile criteria.
            Missile Level D simulates a 9 lb. 2 x 4 traveling at 50 FPS (34 MPH). Missile level E
            simulates a 9 lb. 2 x 4 traveling at 80 FPS (55 MPH). Missile Level D is require for
            Basic Protection while Missile Level E is required for Enhanced Protection for all
            Essential Facilities. The AMCA 540 standard measures "failure" where the subject
            missile (2 x 4) is able to penetrate beyond the plane of the back of the louver or
            where louver components break free in entirety from the assembly. AMCA 540 requires that
            both the manufacturer's shortest and longest unsupported louver blade spans be tested
            along with mullion configurations if qualified as a multi-section assembly. Several
            Greenheck louvers are AMCA 540 Listed.`,
  },
  {
    selection: 'AMCA-550 (HV WDR)',
    description: `The AMCA-550 High Velocity Wind Driven Rain test is a requirement of the
            International Mechanical Code and Florida Mechanical Code for all intake and exhaust
            louvers located within the Hurricane Prone Region as defined by the International
            Building Code and Florida Building Code. A fan in front of the louver test specimen
            simulates external wind speeds of 35, 70, 90 and 110 MPH. Spray nozzles simulate
            external rainfall at a rate of 8 in. per hour. The first three wind speeds (35, 70, 90
            MPH) are at a 15 minute duration each while the final wind speed (110 MPH) is a 5
            minute duration. Unlike the AMCA 500-L Water Penetration or Wind Driven Rain test(s)
            there is no simulated intake air being pulled through the louver. The AMCA 550 standard
            measures "failure" where greater than 1% of the overall sprayed water volume passes
            beyond the louver or louver and damper assembly during the entire 50 minute test
            duration. Several Greenheck louvers are AMCA 550 Listed.`,
  },
  {
    selection: 'FEMA-361 (Tornado)',
    description: `FEMA-361 provides for the construction requirements for safe rooms for
            tornados and hurricane. To obtain FEMA funding design professionals must exhibit
            compliance with the FEMA 361 guidelines. Louvers that protect intake or exhaust
            ventilation ducts must undergo and pass the ICC 500 wind borne debris test standard,
            which simulates a 15 lb. 2 x 4 traveling up to 100 MPH. The subject missile (2 x 4)
            may penetrate the test specimen and remain lodged within the component(s), but may not
            perforate the witness paper located directly behind the test specimen. All openings
            for ventilation into and out of the safe room should be hardened to resist both high
            wind loads and debris impact. The Greenheck AFL-501 louver is a UL Classified Wind
            Storm rated assembly that has undergone and passed the ICC 500 debris impact standard
            while also being capable of withstanding extremely high wind loads.`,
  },
  {
    selection: 'Florida',
    description: `Miami-Dade Notice of Acceptance (NOA) is a requirement in Southeast Florida,
            although it is not referenced as a requirement by the Florida Building Code. Miami-Dade
            NOA requires that louvers must undergo stringent structural testing in Florida test
            protocols TAS 202 (Uniform Static Air Pressure), TAS 203 (Cyclic Wind Loading) and
            TAS 201 (Large Missile Impact). The TAS 201 Large Missile Impact test is very similar in
            spirit to the AMCA 540 debris impact test, although the AMCA 540 standard is widely
            considered more stringent as Florida TAS 201 only tests the largest louver test specimen
            size using a 9 lb. 2 x 4 traveling at 50 FPS (34 MPH). Several Greenheck louvers have
            Miami-Dade NOA.`,
  },
  {
    selection: 'Florida (TAS 100A)',
    description: `Florida (TAS 100A) is nearly identical to the AMCA 550 High Velocity Wind
            Driven rain test standard in that external wind speeds of 35, 70, 90 and 110 MPH are
            simulated with an external rainfall rate of 8.8 in. per hour. The test duration(s) are
            identical to the AMCA 550 standard. It should be noted that the International Mechanical
            Code, Florida Mechanical Code and Miami-Dade Notice of Acceptance only reference the AMCA
            550 High Velocity Wind Driven Rain standard. The difference between Florida TAS 100-A and
            AMCA 550 is the measure of pass or failure. TAS 100-A allows no water passage beyond the
            louver or louver and damper assembly at 35 or 70 MPH and only 0.05% passage of the overall
            sprayed water volume is allowed at 90 and 110 MPH combined. AMCA 550 allows up to 1% of the
            overall sprayed water volume during the entire test duration. Greenheck models EVH-660D and
            EHH-601D with VCD-40 damper are certified for Florida TAS 100A.`,
  },
  {
    selection: 'Miami-Dade & Florida',
    description: `The minimum requirements to obtain Florida Product Approval is the Florida test
            protocol TAS 202 (Uniform Static Air Pressure). Florida Product Approval also recognizes
            Florida test protocol TAS 203 (Cyclic Wind Loading), AMCA 540 debris impact and AMCA 550
            High Velocity Wind Driven Rain as part of the Florida Product Approval process.`,
  },
];
