<ng-template #categoryButton let-group="group">
  <ng-template #flagList let-group="group" let-parent="parent">
    <ng-container *ngIf="!!group.flags && group.flags.length > 0">
      <div
        *ngFor="let flag of group.flags | attrFilter : { parent: parent }"
        [ngClass]="flag.class"
      >
        {{ flag.text }}
      </div>
    </ng-container>
  </ng-template>

  <div
    class="group-card square"
    [ngClass]="group.cssClass"
    [class.schedulable]="group.schedulable"
    (click)="groupSelect(group)"
    matRipple
  >
    <div class="title">
      <span>{{ group.text }}</span>
      <button
        mat-icon-button
        *ngIf="!!group.infoLink"
        (click)="infoClick($event, group)"
      >
        <mat-icon
          [matTooltip]="group.description"
          [matTooltipShowDelay]="500"
          matTooltipPosition="below"
          >info_outline</mat-icon
        >
      </button>
      <mat-icon
        *ngIf="group.schedulable"
        class="no-revit"
        svgIcon="excel"
        matTooltip="Select and Schedule"
      ></mat-icon>
    </div>

    <div class="image">
      <img [src]="'/assets/images/categories/' + group.image" />

      <ng-content
        *ngTemplateOutlet="flagList; context: { group: group, parent: 'Image' }"
      ></ng-content>
    </div>

    <ng-content
      *ngTemplateOutlet="flagList; context: { group: group, parent: 'Card' }"
    ></ng-content>
  </div>
</ng-template>

<div class="landingPage">
  <div class="groups-parent">
    <div [class]="'groups columns-' + displayColumns">
      <ng-container *ngFor="let group of visibleGroups">
        <ng-content
          *ngTemplateOutlet="categoryButton; context: { group: group }"
        ></ng-content>
      </ng-container>
    </div>
  </div>

  <div class="toolbox">
    <mat-toolbar class="title">Toolbox</mat-toolbar>
    <mat-nav-list>
      <mat-list-item
        *ngFor="let item of toolboxItems"
        [ngClass]="item.cssClass"
        (click)="toolboxItemClick(item)"
      >
        <mat-icon
          *ngIf="!!item.icon || !!item.svgIcon"
          [svgIcon]="item.svgIcon"
          matListItemIcon
          >{{ item.icon }}</mat-icon
        >

        <div matListItemTitle>
          {{ item?.text }}
        </div>
      </mat-list-item>
    </mat-nav-list>
  </div>

  <mat-toolbar class="footer">
    <span
      role="link"
      aria-label="View Release Notes"
      class="releaseNotesLink"
      (click)="openReleaseNotes()"
      >Version {{ version }}</span
    >, {{ versionDate }} &copy;{{ copyrightBase }}-{{ currentYear }}
    Greenheck Fan Corp.
  </mat-toolbar>
</div>
