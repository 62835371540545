import { JQueryStatic } from 'jquery/dist/jquery.js';
import { environment } from '../../../environments/environment';
import { IEcapsState } from '../models/i-ecaps-state.interface';
declare var $: JQueryStatic;

export interface ILachesisClient {
  readonly apiUrl: string;
  readonly authRoute: string;
  readonly refreshRoute: string;

  trackError(payload: ErrorPayload): Promise<void>;
  trackFanSelection(payload: FanSelectionPayload): Promise<void>;
  trackPage(pageUrl: string): Promise<void>;
  trackUserError(payload: UserErrorPayload): Promise<void>;
}
export type LachesisParams = Pick<
  LachesisClient,
  'apiUrl' | 'authRoute' | 'refreshRoute'
>;
export class LachesisClient implements ILachesisClient {
  public readonly apiUrl: string;
  public readonly authRoute: string;
  public readonly refreshRoute: string;

  private readonly client?: any;

  public constructor(params: LachesisParams) {
    this.apiUrl = params.apiUrl;
    this.authRoute = params.authRoute;
    this.refreshRoute = params.refreshRoute;

    try {
      this.client = $.lachesis.createClient(
        this.apiUrl,
        this.authRoute,
        this.refreshRoute
      );
    } catch (err) {
      console.debug('[LachesisClient] Error creating client: %o', err);
    }
  }

  public async trackError(payload: ErrorPayload): Promise<void> {
    if (!this.client) {
      console.debug('[LachesisClient] trackError(%o) (no client)', payload);

      return;
    }

    await this.client.postData('/ecaps/v2.0/error', payload).catch((err) => {
      console.error('[LachesisClient] trackError(%o): %o', payload, err);
    });
  }

  public async trackFanSelection(payload: FanSelectionPayload): Promise<void> {
    if (!this.client) {
      console.debug(
        '[LachesisClient] trackFanSelection(%o) (no client)',
        payload
      );

      return;
    }

    await this.client
      .postData('/ecaps/v2.0/fan-selection', payload)
      .catch((err) => {
        console.error(
          '[LachesisClient] trackFanSelection(%o): %o',
          payload,
          err
        );
      });
  }

  public async trackPage(pageUrl: string) {
    if (!this.client) {
      console.debug('[LachesisClient] trackPage(%o) (no client)', pageUrl);

      return;
    }

    let url = `path=${pageUrl}&host=${window.location.hostname}&port=${window.location.port}`;
    url += `&environment=${environment.environment.toLowerCase()}&version=${
      environment.version
    }`;

    url = `${
      environment.lachesis.url
    }/ecaps/v2.0/lodestar.gif?campaign=testing&d=${encodeURI(btoa(url))}`;

    try {
      await $.get({
        url: url,
        data: null,
        success: null,
        dataType: null,
      });
    } finally {
    }
  }

  public async trackUserError(payload: UserErrorPayload): Promise<void> {
    if (!this.client) {
      console.debug('[LachesisClient] trackUserError(%o) (no client)', payload);

      return;
    }

    await this.client
      .postData('/ecaps/v2.0/user-error', payload)
      .catch((err) => {
        console.error('[LachesisClient] trackUserError(%o): %o', payload, err);
      });
  }

  public async trackEvent(payload: EventPayload | any): Promise<void> {
    if (!this.client) {
      console.debug('[LachesisClient] trackEvent(%o) (no client)', payload);

      return;
    }

    await this.client
      .postData(
        `/ecaps/v2.0/event${Array.isArray(payload) ? '?batch=1' : ''}`,
        payload
      )
      .catch((err) => {
        console.error('[LachesisClient] trackEvent(%o): %o', payload, err);
      });
  }
}

export interface Payload {
  readonly environment: string;
  readonly version: string;
  readonly userId: string;
  readonly sessionId: string;
  readonly application: 'eCAPS' | 'Revit Plugin';
  readonly url: string;
  readonly userLoggedIn: boolean;
}

export interface ErrorPayload {
  readonly errorId: string;
  readonly errorMessage: string;
  readonly stack?: string;
  readonly state: IEcapsState;
}

export interface UserErrorPayload extends Payload {
  readonly errorId: string;
  readonly errorMessage: string;
  readonly stack?: string;
  readonly userEmail?: string;
  readonly userMessage?: string;
  readonly state: IEcapsState;
}

export interface FanSelectionPayload {
  readonly models: ReadonlyArray<string>;
  readonly modelGroup: string;
  readonly volume: number;
  readonly staticPressure: number;
  readonly elevation: number;
  readonly airstreamTemp: number;
}

export interface EventPayload {
  readonly eventName: string;
  readonly eventInfo?: string;
  readonly slideout?:
    | 'Product Info'
    | 'Model Comparison'
    | 'Energy Recovery Model Comparison'
    | 'Static Pressure Calculator';
  readonly slideoutTab?: 'Info' | 'Customize';
  readonly modelGroup?: string;
  readonly productType?: string;
  readonly productGroup?: string;
  readonly model?: string;
  readonly retrievalMethod?: 'Download' | 'Email' | 'Insert';
  readonly category?: string;
  readonly subCategory?: string;
  readonly drawingType?: string;
  readonly revitVersion?: string;
  readonly revitPluginVersion?: string;
}
