import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MenuItemList } from '../../side-bar/services/constants/menu-item-list.const';
import { SideBarService } from '../../side-bar/services/side-bar.service';
import { FaqList } from './constants/faq-list.const';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent implements OnInit {
  faqList = FaqList;

  greenheckRootUrl = environment.greenheckUrls.root;

  constructor(
    private sidebarService: SideBarService,
    private menuItemList: MenuItemList
  ) {}

  ngOnInit() {
    this.sidebarService.setSidebarItems([
      {
        menuItems: [
          this.menuItemList.equipmentSchedule,
          this.menuItemList.jobs,
        ],
      },
    ]);
  }
}
