import {
  ComponentFactoryResolver,
  Injectable,
  NgZone,
  OnDestroy,
  Type,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SideDialogComponent } from '../classes/side-dialog-component.interface';
import { SideDialogDirective } from '../directives/side-dialog.directive';
import { SideDialogOptions } from '../models/side-dialog-options.model';
import { SideDialogRef } from '../models/side-dialog-ref.model';

@Injectable({
  providedIn: 'root',
})
export class SideDialogService implements OnDestroy {
  private _sideDialogDrawer: MatDrawer;
  private _sideDialogContainer: SideDialogDirective;

  private _openStartSubscription: Subscription;
  private _closeStartSubscription: Subscription;
  private _openChangedSubscription: Subscription;

  private _activeDialogRef: SideDialogRef;

  private _dialogResults: any;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router,
    private zone: NgZone
  ) {
    this.router.events.subscribe((eventData) => {
      if (eventData instanceof NavigationEnd && eventData.id !== 1) {
        if (!!this._sideDialogDrawer && this._sideDialogDrawer.opened) {
          this._sideDialogDrawer.close();
        }
      }
    });
  }

  ngOnDestroy() {
    if (!!this._openChangedSubscription) {
      this._openChangedSubscription.unsubscribe();
    }

    if (!!this._openStartSubscription) {
      this._openStartSubscription.unsubscribe();
    }

    if (!!this._closeStartSubscription) {
      this._closeStartSubscription.unsubscribe();
    }
  }

  setDrawer(drawer: MatDrawer, container: SideDialogDirective) {
    this.zone.run(() => {
      this._sideDialogDrawer = drawer;

      this._sideDialogContainer = container;

      if (!!this._openChangedSubscription) {
        this._openChangedSubscription.unsubscribe();
      }

      this._openStartSubscription =
        this._sideDialogDrawer.openedStart.subscribe(() => {
          const snackBar = document.body.querySelector(
            '.mat-mdc-snack-bar-container'
          );

          if (!!snackBar) {
            snackBar.classList.add('hide-snackbar');
          }
        });

      this._closeStartSubscription =
        this._sideDialogDrawer.closedStart.subscribe(() => {
          const snackBar = document.body.querySelector(
            '.mat-mdc-snack-bar-container'
          );

          if (!!snackBar) {
            snackBar.classList.remove('hide-snackbar');
          }

          if (!!this._activeDialogRef) {
            this._activeDialogRef.beforeClose.next(this._dialogResults);
          }
        });

      this._openChangedSubscription =
        this._sideDialogDrawer.openedChange.subscribe((opened) => {
          if (!opened) {
            if (!!this._activeDialogRef) {
              this._activeDialogRef.afterClosed.next(this._dialogResults);

              this._activeDialogRef = null;
            }

            this._sideDialogContainer.viewContainerRef.clear();
          } else if (!!this._activeDialogRef) {
            this._activeDialogRef.afterOpen.next();
          }
        });
    });
  }

  open(component: Type<any>, options?: SideDialogOptions): SideDialogRef {
    this.zone.run(() => {
      this._dialogResults = null;

      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(component);

      this._sideDialogContainer.viewContainerRef.clear();

      const componentRef =
        this._sideDialogContainer.viewContainerRef.createComponent(
          componentFactory
        );

      (<SideDialogComponent>componentRef.instance).dialogData = !!options
        ? options.data
        : null;

      this._activeDialogRef = new SideDialogRef();

      this._activeDialogRef.componentInstance = componentRef.instance;

      if (this._sideDialogDrawer.opened) {
        if (!!this._activeDialogRef) {
          this._activeDialogRef.afterOpen.next();
        }
      } else {
        this._sideDialogDrawer.open();
      }
    });

    return this._activeDialogRef;
  }

  close(results?: any) {
    this.zone.run(() => {
      if (!!this._sideDialogDrawer) {
        this._dialogResults = results;

        this._sideDialogDrawer.close();
      }
    });
  }
}
