import { DecimalPipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { EcapsStateService } from '../../ecaps-core/handlers/services/ecaps-state.service';
import { CamelCasePipe } from '../../ecaps-core/pipes/custom-pipes.pipe';
import { ErrorLoggingService } from '../../ecaps-core/services/error-logging.service';
import { HttpService } from '../../ecaps-core/services/http.service';
import {
  ActionTypes,
  GoogleAnalyticsService,
  SourceTypes,
} from '../../google/services/google-analytics.service';
import { ProductTypes } from '../../products/enums/product-types.enum';
import { ValidSizeInflateOptions } from '../../selections/models/selection-results/valid-size-inflate-options.model';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { LayoutService } from '../../selections/services/layout.service';
import { UserData } from '../../users/models/user-data.model';
import { UsersService } from '../../users/services/users.service';
import { EquipmentScheduleGridDefinition } from '../models/equipment-schedule-grid-definition.model';
import { IGridItemSort } from '../models/i-grid-item-sort.interface';
import { LocalStorageProject } from '../models/local-storage-project.model';
import {
  Item,
  Item as ProjectRevisionItem,
} from '../models/project-item.model';
import { Note as ProjectRevisionNote } from '../models/project-note.model';
import { Revision as ProjectRevision } from '../models/project-revision.model';
import { Revision } from '../models/project-summar-revision.model';
import { ProjectSummary } from '../models/project-summary.model';
import { Project } from '../models/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService implements OnDestroy {
  private currentUser: UserData;
  private currentUserSubscription: Subscription;

  private LS_PROJECT_DATA = 'localProject';

  private localProject: Project;

  private _selectedItems: Array<Item> = new Array<Item>();
  private _sortedItems: Array<IGridItemSort> = new Array<IGridItemSort>();

  private _esGridDefinitions: Array<EquipmentScheduleGridDefinition> =
    new Array<EquipmentScheduleGridDefinition>();

  public localProjectUpdated = new EventEmitter<Project>();

  private activeProjects: Array<ProjectSummary>;

  public activeProjectsUpdated = new EventEmitter<Array<ProjectSummary>>();

  private _localProjectRestoring = false;

  private localProjectAltered: Subscription;

  constructor(
    private http: HttpService,
    private users: UsersService,
    private layout: LayoutService,
    private core: EcapsCore,
    private ecapsState: EcapsStateService,
    private errorLogging: ErrorLoggingService,
    private router: Router,
    private analytics: GoogleAnalyticsService
  ) {
    this.ecapsState.projectsService = this;

    users.getCurrentUser().then((userData) => {
      this.currentUser = userData;
    });

    this.currentUserSubscription = users.currentUserUpdated.subscribe(
      (userData: UserData) => {
        const lastAuthState = this.currentUser.authenticated;
        this.currentUser = userData;

        if (!userData.authenticated && lastAuthState) {
          this.createProject().then(
            (project) => {
              this.setLocalProject(project);
            },
            (subErrorData) => {
              this.localProjectUpdated.error(subErrorData);
            }
          );
        }
      }
    );

    let loading = true;

    core.showLoadingGraphic('Loading Job...', function () {
      return loading;
    });

    this.restoreLocalProject().then(
      () => {
        loading = false;

        core.hideLoadingGraphic();
      },
      () => {
        loading = false;

        core.hideLoadingGraphic();
      }
    );
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();

    if (!!this.localProjectAltered) {
      this.localProjectAltered.unsubscribe();
    }
  }

  private storeLocalProject(project: Project) {
    window.localStorage.setItem(
      this.LS_PROJECT_DATA,
      JSON.stringify(new LocalStorageProject(project))
    );
  }

  private restoreLocalProject(): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      if (this._localProjectRestoring) {
        const subscription = this.localProjectUpdated.subscribe(
          (project) => {
            subscription.unsubscribe();

            results(true);
          },
          (errorData) => {
            subscription.unsubscribe();

            reject(errorData);
          }
        );
      } else {
        this._localProjectRestoring = true;

        const createNewProject = function () {
          this.createProject().then(
            (project) => {
              this.setLocalProject(project);

              this._localProjectRestoring = false;

              results(true);
            },
            (subErrorData) => {
              this.localProjectUpdated.error(subErrorData);

              this._localProjectRestoring = false;

              reject(subErrorData);
            }
          );
        };

        const rawJson = window.localStorage.getItem(this.LS_PROJECT_DATA);

        if (rawJson && rawJson !== '') {
          const lsProject: LocalStorageProject = <LocalStorageProject>(
            JSON.parse(rawJson)
          );

          this.getProject(lsProject.key).then(
            (project) => {
              project.isDirty = lsProject.isDirty;

              this.setLocalProject(project);

              this._localProjectRestoring = false;

              results(true);
            },
            () => {
              if (lsProject.lastSavedDate) {
                this.loadSavedProject(lsProject.id, true).then(
                  (project) => {
                    this.setLocalProject(project);

                    this._localProjectRestoring = false;

                    results(true);
                  },
                  () => {
                    createNewProject.call(this);
                  }
                );
              } else {
                createNewProject.call(this);
              }
            }
          );
        } else {
          createNewProject.call(this);
        }
      }
    });
  }

  selectProjectItem(item: Item) {
    if (this._selectedItems.indexOf(item) === -1) {
      this._selectedItems.push(item);
    }
  }

  unselectProjectItem(item: Item) {
    const index = this._selectedItems.findIndex(
      (selecteItem: Item) => selecteItem.id === item.id
    );

    if (index > -1) {
      this._selectedItems.splice(index, 1);
    }
  }

  getSelectedProjectItems(): Array<Item> {
    return this._selectedItems;
  }

  clearSelectedProjectItems() {
    this._selectedItems.splice(0, this._selectedItems.length);
  }

  setSortedProjectItems(productType: ProductTypes, list: Item[]) {
    const typeGroup = this._sortedItems.find(
      (item) => item.productType === productType
    );

    const newList = new Array<string>();

    list.map((item) => {
      newList.push(item.id);
    });

    if (!typeGroup) {
      this._sortedItems.push({
        productType: productType,
        itemIds: newList,
      });
    } else {
      typeGroup.itemIds = newList;
    }
  }

  getSortedProjectItems(): string[] {
    let newList = new Array<string>();

    this._sortedItems.map((group) => {
      newList = newList.concat(group.itemIds);
    });

    return newList;
  }

  setEquipmentScheduleGridDefinitions(
    productType: ProductTypes,
    visibleColumns: Array<string>
  ) {
    const gridIndex = this._esGridDefinitions.findIndex(
      (item) => item.productType === productType
    );

    if (gridIndex > -1) {
      this._esGridDefinitions.splice(gridIndex, 1);
    }

    const columnList = [].concat(visibleColumns);

    columnList.splice(
      columnList.findIndex((item) => item === 'selector'),
      1
    );

    this._esGridDefinitions.push({
      productType: productType,
      visibleColumns: columnList,
    });
  }

  getEquipmentScheduleGridDefinitions() {
    return this._esGridDefinitions;
  }

  setLocalProject(project: Project) {
    if (!!this.localProject && this.localProject.id !== project.id) {
      this.localProject.dispose();
    }

    this.localProject = project;

    this.storeLocalProject(this.localProject);

    if (!!this.localProjectAltered) {
      this.localProjectAltered.unsubscribe();
    }

    this.localProjectAltered = this.localProject.updated.subscribe(() => {
      this.storeLocalProject(this.localProject);
    });

    this.localProjectUpdated.next(this.localProject);

    if (!project.getActiveRevision().isValid) {
      this.core
        .showMessageBox({
          title: 'Job Marks Invalid',
          message:
            'Your job currently contains invalid marks. Please resolve these issues in the equipment schedule.',
        })
        .then(() => {
          this.router.navigate(['/equipmentschedule']);
        });
    }
  }

  getLocalProject(): Promise<Project> {
    return new Promise<Project>((result, reject) => {
      if (!!this.localProject) {
        result(this.localProject);
      } else {
        this.restoreLocalProject().then(
          () => {
            result(this.localProject);
          },
          (errorData) => {
            reject(errorData);
          }
        );
      }
    });
  }

  createProject(): Promise<Project> {
    return new Promise<Project>((results, reject) => {
      firstValueFrom(this.http.post('/project', null)).then(
        (projectData) => {
          const project = new Project(projectData);

          this.createRevision(project).then(
            (revisionData) => {
              results(project);
            },
            (errorData) => {
              reject(errorData);
            }
          );
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getProject(key: string, inflateProject: boolean = true): Promise<Project> {
    return new Promise<Project>((results, reject) => {
      firstValueFrom(this.http.get<any>('/project/' + key)).then(
        (projectData) => {
          projectData.key = key;

          const project = new Project(projectData);

          try {
            if (inflateProject) {
              this.inflateProject(project, { charts: false }).then(
                () => {
                  results(project);
                },
                (errorData) => {
                  reject(errorData);
                }
              );
            } else {
              results(project);
            }
          } catch (errorData) {
            this.errorLogging.logError(errorData);

            reject(errorData);
          }
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  copyProject(key: string): Promise<Project> {
    return new Promise<Project>((results, reject) => {
      firstValueFrom(
        this.http.post(`/project`, {
          type: 'copy',
          projectKey: key,
        })
      ).then(
        (newProject) => {
          const project = new Project(newProject);

          this.inflateProject(project).then(
            () => {
              results(project);
            },
            (errorData) => {
              reject(errorData);
            }
          );
        },
        (errorData) => {
          results(errorData);
        }
      );
    });
  }

  private inflateProject(
    project: Project,
    options?: ValidSizeInflateOptions
  ): Promise<boolean> {
    const tasks: Array<Promise<any>> = project
      .getActiveRevision()
      .items.map<Promise<any>>(async (item) => {
        const productType = new CamelCasePipe().transform(item.type);

        const layoutKey = item.details[productType + 'SelectorLayoutEntityKey'];
        const selectionKey = item.details[productType + 'SelectionKey'];
        const sizeConfigKey = item.details['productLayoutEntityKey'];
        const sizeID = item.details[productType + 'SelectionSizeId'];

        const layoutConfig = await this.layout.getLayoutConfiguration(
          layoutKey
        );
        const size = await this.layout.getSelectionValidSize(
          layoutConfig,
          selectionKey,
          sizeID
        );

        size.status = item.status;

        item.sizeData = size;

        await this.layout.inflateValidSize(size, sizeConfigKey, options);
      });

    return Promise.all(tasks).then(() => true);
  }

  updateProject(project: Project, properties: any): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      let changed = false;

      Object.keys(properties).forEach((key) => {
        if (project[key] !== properties[key]) {
          changed = true;
        }
      });

      if (!changed) {
        results(true);
      } else {
        firstValueFrom(
          this.http.post('/project/' + project.key, properties)
        ).then(
          (resultData) => {
            for (const key of Object.keys(properties)) {
              project[key] = properties[key];
            }

            results(true);
          },
          (errorData) => {
            reject(errorData);
          }
        );
      }
    });
  }

  deleteProject(project: Project): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(this.http.delete('/project/' + project.key)).then(
        (resultData) => {
          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  deleteSavedProject(projectID: string): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.request('DELETE', `/project`, {
          headers: new HttpHeaders({
            'x-user-token': this.currentUser.token,
          }),
          body: {
            user: this.currentUser.email,
            project: projectID,
          },
        })
      ).then(
        () => {
          const index = this.activeProjects.findIndex(
            (project) => project.id === projectID
          );

          if (index > -1) {
            this.activeProjects.splice(index, 1);
          }

          this.getActiveProjects();

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  createRevision(project: Project): Promise<ProjectRevision> {
    return new Promise<ProjectRevision>((results, reject) => {
      let revNum = 0;
      let revName = '';

      project.revisions.forEach((revision) => {
        const lastNum = parseInt(revision.name.split(' ')[1], 10);

        if (lastNum > revNum) {
          revNum = lastNum;
        }
      });

      revNum++;
      revName = `Ver. ${revNum}`;

      firstValueFrom(
        this.http.post('/project/' + project.key + '/revisions', {
          active: true,
          name: revName,
        })
      ).then(
        (revisionData) => {
          const revision = new ProjectRevision(revisionData);

          project.addRevision(revision);

          results(revision);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  updateRevision(
    project: Project,
    revision: ProjectRevision,
    properties: any
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.post(
          `/project/${project.key}/revisions/${revision.id}`,
          properties
        )
      ).then(
        () => {
          Object.keys(properties).forEach((key) => {
            revision[key] = properties[key];
          });

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  copyRevision(
    project: Project,
    revision: ProjectRevision,
    inflateProject: boolean = false
  ): Promise<ProjectRevision> {
    return new Promise<ProjectRevision>((results, reject) => {
      firstValueFrom(
        this.http.post(`/project/${project.key}/revisions/`, {
          projectKey: project.key,
          revision: revision.id,
          type: 'copy',
        })
      ).then(
        (revisionData) => {
          const newRevision = new ProjectRevision(revisionData);

          project.revisions.forEach((revItem) => {
            revItem.active = false;
          });

          project.addRevision(newRevision);

          let revNum = 0;

          project.revisions.forEach((revisionItem) => {
            const verNum = parseInt(revisionItem.name.split(' ')[1], 10);

            if (revNum < verNum) {
              revNum = verNum;
            }
          });

          revNum++;

          this.updateRevision(project, newRevision, {
            name: `Ver. ${revNum.toString()}`,
          }).then(
            () => {
              if (inflateProject) {
                this.inflateProject(project, { charts: false }).then(
                  () => {
                    results(newRevision);
                  },
                  (errorData) => {
                    reject(errorData);
                  }
                );
              } else {
                results(newRevision);
              }
            },
            (errorData) => {
              reject(errorData);
            }
          );
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  deleteRevision(project: Project, revision: ProjectRevision): Promise<void> {
    return new Promise<void>((result, reject) => {
      firstValueFrom(
        this.http.delete(`/project/${project.key}/revisions/${revision.id}`)
      ).then(
        () => {
          project.deleteRevision(revision);

          if (!project.getActiveRevision()) {
            let newRev: ProjectRevision = null;

            project.revisions.forEach((rev) => {
              if (!newRev || newRev.creationDate < rev.creationDate) {
                newRev = rev;
              }
            });

            if (!!newRev) {
              this.updateRevision(project, newRev, { active: true }).then(
                () => {
                  result();
                },
                (errorData) => {
                  reject(errorData);
                }
              );
            } else {
              result();
            }
          } else {
            result();
          }
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  deleteRevisions(project: Project, revisionIDs: string[]): Promise<void> {
    return new Promise<void>((result, reject) => {
      if (revisionIDs.length === 0) {
        result();
      } else if (revisionIDs.length === project.revisions.length) {
        reject();
      } else {
        firstValueFrom(
          this.http.request('DELETE', `/project/${project.key}/revisions/`, {
            body: {
              ids: revisionIDs,
            },
          })
        ).then(
          () => {
            revisionIDs.forEach((id) => {
              const revision = project.revisions.find((rev) => rev.id === id);

              if (!!revision) {
                project.deleteRevision(revision);
              }
            });

            if (!project.getActiveRevision()) {
              let newRev: ProjectRevision = null;

              project.revisions.forEach((rev) => {
                if (!newRev || newRev.creationDate < rev.creationDate) {
                  newRev = rev;
                }
              });

              if (!!newRev) {
                this.updateRevision(project, newRev, { active: true }).then(
                  () => {
                    result();
                  },
                  (errorData) => {
                    reject(errorData);
                  }
                );
              } else {
                result();
              }
            } else {
              result();
            }
          },
          (errorData) => {
            reject(errorData);
          }
        );
      }
    });
  }

  addItemToRevision(
    project: Project,
    productType: string,
    name: string,
    quantity: number,
    tag: string,
    location: string,
    area: string,
    keys: any,
    sizeData?: ValidSize
  ): Promise<ProjectRevisionItem> {
    return new Promise<ProjectRevisionItem>((results, reject) => {
      if (
        project.getActiveRevision().items.length >= environment.projectItemLimit
      ) {
        reject(
          new Error(
            `There is a limit of ${new DecimalPipe('en_us').transform(
              environment.projectItemLimit
            )} items to a project.  ` +
              `To add more items please create a new project.`
          )
        );
      } else {
        if (
          !!project
            .getActiveRevision()
            .items.find(
              (item) =>
                item.tag.toUpperCase().trim() === tag.toUpperCase().trim()
            )
        ) {
          reject(new Error('Tag name already exists on this revision.'));

          return;
        }

        const details = [];

        for (const key of Object.keys(keys)) {
          details.push({
            name: key,
            value: keys[key],
          });
        }

        firstValueFrom(
          this.http.post(
            `/project/${project.key}/revisions/${
              project.getActiveRevision().id
            }/items`,
            {
              name: name,
              tag: tag.toUpperCase().trim(),
              productType: productType,
              quantity: quantity,
              location: location,
              areaServed: area,
              details: details,
            }
          )
        ).then(
          (resultData) => {
            const newItem = new ProjectRevisionItem(resultData, sizeData);

            project.getActiveRevision().addItem(newItem);

            const elevationQuestion =
              sizeData.selectionLayoutConfig.getQuestion('Elevation');

            if (
              !!elevationQuestion &&
              !!elevationQuestion.value &&
              parseInt(elevationQuestion.value, 10) !== 0 &&
              (!project.elevation || project.elevation === 0)
            ) {
              this.updateProject(project, {
                elevation: parseInt(elevationQuestion.value, 10),
              }).catch((errorData) => {
                console.error(errorData);
              });
            }

            results(newItem);
          },
          (errorData) => {
            reject(errorData);
          }
        );
      }
    });
  }

  updateRevisionItem(
    item: ProjectRevisionItem,
    properties: any
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.post(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/items/${item.id}`,
          properties
        )
      ).then(
        () => {
          for (const key of Object.keys(properties)) {
            item[key] = properties[key];
          }

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  deleteRevisionItem(item: ProjectRevisionItem): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.delete(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/items/${item.id}`
        )
      ).then(
        () => {
          this.localProject.getActiveRevision().removeItem(item);

          this.clearSelectedProjectItems();

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getRevisionNotes(): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.get(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/notes`
        )
      ).then(
        (data: []) => {
          data.forEach((noteData) => {
            this.localProject
              .getActiveRevision()
              .addNote(new ProjectRevisionNote(noteData));
          });

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  addRevisionNote(content: string): Promise<ProjectRevisionNote> {
    return new Promise<ProjectRevisionNote>((results, reject) => {
      firstValueFrom(
        this.http.post(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/notes`,
          {
            content: content.trim(),
          }
        )
      ).then(
        (data) => {
          const newNote = new ProjectRevisionNote(data);

          this.localProject.getActiveRevision().addNote(newNote);

          results(newNote);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  updateRevisionNote(
    note: ProjectRevisionNote,
    content: string
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.post(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/notes/${note.id}`,
          {
            content: content.trim(),
          }
        )
      ).then(
        () => {
          note.content = content.trim();

          const revNote = this.localProject
            .getActiveRevision()
            .notes.find((rNote) => rNote.id === note.id);

          if (!!revNote) {
            revNote.content = content.trim();
          }

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  deleteRevisionNote(note: ProjectRevisionNote): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.delete(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/notes/${note.id}`
        )
      ).then(
        () => {
          this.localProject.getActiveRevision().removeNote(note);

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getRevisionItemNotes(item: ProjectRevisionItem): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.get(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/items/${item.id}/notes`
        )
      ).then(
        (data: []) => {
          data.forEach((noteData) => {
            item.addNote(new ProjectRevisionNote(noteData));
          });

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  addRevisionItemNote(
    item: ProjectRevisionItem,
    note: ProjectRevisionNote
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.post(
          `/project/${this.localProject.key}/revisions/${
            this.localProject.getActiveRevision().id
          }/items/${item.id}/notes`,
          {
            revisionNoteId: note.id,
          }
        )
      ).then(
        () => {
          item.addNote(note);

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  addRevisionItemNoteByContent(
    item: ProjectRevisionItem,
    content: string | string[]
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      content = Array.isArray(content) ? content : [content];

      const newRevisionNotes: string[] = content.filter(
        (noteContent) =>
          this.localProject
            .getActiveRevision()
            .notes.findIndex((note) => note.content === noteContent) === -1
      );

      const newNotes: ProjectRevisionNote[] = this.localProject
        .getActiveRevision()
        .notes.filter((note) => content.indexOf(note.content) > -1);

      const addRevisionNotes = () => {
        if (newRevisionNotes.length > 0) {
          this.addRevisionNote(newRevisionNotes.pop()).then((newNote) => {
            newNotes.push(newNote);

            addRevisionNotes();
          }, reject);
        } else {
          addItemNotes();
        }
      };

      const addItemNotes = () => {
        if (newNotes.length > 0) {
          this.addRevisionItemNote(item, newNotes.pop()).then(() => {
            addItemNotes();
          }, reject);
        } else {
          results(true);
        }
      };

      addRevisionNotes();
    });
  }

  deleteRevisionItemNote(
    item: ProjectRevisionItem,
    note: ProjectRevisionNote | ProjectRevisionNote[]
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      note = Array.isArray(note) ? note : [note];

      let itemIndex = 0;
      const removeItemNote = () => {
        if (itemIndex < (note as ProjectRevisionNote[]).length) {
          const oldNote = note[itemIndex];

          firstValueFrom(
            this.http.delete(
              `/project/${this.localProject.key}/revisions/${
                this.localProject.getActiveRevision().id
              }/items/${item.id}/notes/${oldNote.id}`
            )
          ).then(() => {
            item.removeNote(oldNote);

            const itemNoteCount = this.localProject
              .getActiveRevision()
              .items.filter(
                (revItem) =>
                  revItem.notes.findIndex(
                    (noteItem) => noteItem.id === oldNote.id
                  ) > -1
              );

            if (itemNoteCount.length === 0) {
              this.deleteRevisionNote(oldNote).then(() => {
                itemIndex++;

                removeItemNote();
              }, reject);
            } else {
              itemIndex++;

              removeItemNote();
            }
          }, reject);
        } else {
          results(true);
        }
      };

      removeItemNote();
    });
  }

  getActiveProjects(): Array<ProjectSummary> {
    if (!!this.activeProjects) {
      const output = new Array<ProjectSummary>();

      this.activeProjects.forEach((project) => {
        output.push(project);
      });

      this.activeProjectsUpdated.next(output);

      return output;
    } else {
      return null;
    }
  }

  refreshActiveProjects(): Promise<Array<ProjectSummary>> {
    return new Promise<Array<ProjectSummary>>((results, reject) => {
      firstValueFrom(
        this.http.get(`/project/summary?User=${this.currentUser.email}`, {
          headers: new HttpHeaders({
            'x-user-token': this.currentUser.token,
          }),
        })
      ).then(
        (data) => {
          const summaries = new Array<ProjectSummary>();

          if (!!data && !!data['projects']) {
            data['projects'].every((project) => {
              summaries.push(new ProjectSummary(project));

              return true;
            });
          }

          this.activeProjects = summaries;

          results(this.getActiveProjects());
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  loadSavedProject(
    projectID: string,
    newRevision: boolean = false,
    revision?: Revision,
    inflateProject: boolean = true
  ): Promise<Project> {
    return new Promise<Project>((results, reject) => {
      this.users.getCurrentUser().then(
        (userData) => {
          if (userData.authenticated === true) {
            firstValueFrom(
              this.http.post(
                `/project`,
                {
                  project: projectID,
                  user: userData.email,
                  type: 'saved',
                },
                {
                  headers: new HttpHeaders({
                    'x-user-token': this.currentUser.token,
                  }),
                }
              )
            ).then(
              (projectData) => {
                const newProject = new Project(projectData);

                if (newRevision) {
                  let selectedRevision: ProjectRevision;

                  if (!!revision) {
                    selectedRevision = newProject.revisions.find(
                      (revItem) => revItem.id === revision.id
                    );
                  } else {
                    selectedRevision = newProject.getActiveRevision();
                  }

                  this.copyRevision(newProject, selectedRevision).then(
                    () => {
                      newProject.isDirty = false;
                      if (inflateProject) {
                        this.inflateProject(newProject, {
                          charts: false,
                        }).then(
                          () => {
                            results(newProject);
                          },
                          (errorData) => {
                            reject(errorData);
                          }
                        );
                      } else {
                        results(newProject);
                      }
                    },
                    (errorData) => {
                      reject(errorData);
                    }
                  );
                } else {
                  newProject.isDirty = false;

                  if (inflateProject) {
                    this.inflateProject(newProject).then(
                      () => {
                        results(newProject);
                      },
                      (errorData) => {
                        reject(errorData);
                      }
                    );
                  } else {
                    results(newProject);
                  }
                }
              },
              (errorData) => {
                reject(errorData);
              }
            );
          } else {
            reject('User not logged in.');
          }
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  saveProject(
    project: Project,
    source?: SourceTypes,
    action?: ActionTypes
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      if (
        project.size >
        environment.maxProjectSize * environment.maxProjectSizeTolerance
      ) {
        this.core.showMessageBox({
          title: 'Job Too Large',
          message:
            'This job is too large to save. Please delete previous revisions before proceeding.',
          icon: 'warning',
          iconClasses: 'warn',
        });

        results(false);
      } else {
        if (project.isDirty) {
          firstValueFrom(
            this.http.post(
              `/project/${project.key}/persisted`,
              {
                user: this.currentUser.email,
              },
              {
                headers: new HttpHeaders({
                  'x-user-token': this.currentUser.token,
                }),
              }
            )
          ).then(
            () => {
              if (!!source && !!action) {
                this.analytics.viewCart('Save Project', {
                  source,
                  action,
                  items: project
                    .getActiveRevision()
                    .items.map((item) => item.sizeData?.getGAModel()),
                });
              }

              this.refreshActiveProjects().then(
                () => {
                  project.lastSavedDate = new Date();

                  this.getProject(project.key, false).then(
                    (data) => {
                      project.size = data.size;
                      project.isDirty = false;

                      results(true);
                    },
                    (errorData) => {
                      reject(errorData);
                    }
                  );
                },
                (errorData) => {
                  reject(errorData);
                }
              );
            },
            (errorData) => {
              reject(errorData);
            }
          );
        } else {
          results(true);
        }
      }
    });
  }

  updateSavedProject(
    projectSummary: ProjectSummary,
    properties: any
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      this.getLocalProject().then((localProject) => {
        if (projectSummary.id !== localProject.id) {
          this.loadSavedProject(projectSummary.id).then(
            (project) => {
              this.updateProject(project, properties).then(
                () => {
                  this.saveProject(project).then(
                    () => {
                      projectSummary.lastSavedDate = project.lastSavedDate;

                      results(true);
                    },
                    (errorData) => {
                      reject(errorData);
                    }
                  );
                },
                (errorData) => {
                  reject(errorData);
                }
              );
            },
            (errorData) => {
              reject(errorData);
            }
          );
        } else {
          this.updateProject(localProject, properties).then(
            () => {
              this.saveProject(localProject).then(
                () => {
                  projectSummary.lastSavedDate = localProject.lastSavedDate;

                  results(true);
                },
                (errorData) => {
                  reject(errorData);
                }
              );
            },
            (errorData) => {
              reject(errorData);
            }
          );
        }
      });
    });
  }

  updateSavedProjectRevision(
    projectSummary: ProjectSummary,
    revision: Revision,
    properties: Record<string, any>
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      this.loadSavedProject(projectSummary.id).then(
        (project) => {
          this.updateRevision(
            project,
            project.revisions.find((revItem) => revItem.id === revision.id),
            properties
          ).then(
            () => {
              this.saveProject(project).then(
                () => {
                  Object.keys(properties).forEach((key) => {
                    if (key in revision) revision[key] = properties[key];
                  });

                  projectSummary.lastSavedDate = project.lastSavedDate;

                  results(true);
                },
                (errorData) => {
                  reject(errorData);
                }
              );
            },
            (errorData) => {
              reject(errorData);
            }
          );
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getSharedProjectSummary(publicToken: string): Promise<ProjectSummary> {
    return new Promise<ProjectSummary>((results, reject) => {
      firstValueFrom(this.http.get(`project/shared/${publicToken}`)).then(
        (response) => {
          results(new ProjectSummary(response));
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  loadSharedProject(publicToken: string): Promise<Project> {
    return new Promise<Project>((results, reject) => {
      firstValueFrom(
        this.http.post(`project`, {
          projectPublicToken: publicToken,
          revision: 'active',
          type: 'shared',
        })
      ).then(
        async (project) => {
          const newProject = new Project(project);

          await this.inflateProject(newProject);

          results(newProject);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }
}
