<side-dialog-title>Release Notes</side-dialog-title>

<side-dialog-content>
    <div class="notesList">
      <div class="note" *ngFor="let note of releaseNotes">
        <span>{{ note.text }}</span>
        <ul>
          <li *ngFor="let item of note.notes">{{ item }}</li>
        </ul>
      </div>
    </div>
</side-dialog-content>

<side-dialog-actions>
    <button mat-raised-button color="primary" (click)="close()">Close</button>
</side-dialog-actions>