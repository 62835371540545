import { Injectable } from '@angular/core';
import { LaunchTypes } from '../models/launch-types.enum';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { VideoDialogComponent } from '../dialogs/video-dialog/video-dialog.component';
import { ImageDialogService } from './image-dialog.service';
import { IImageDialogData } from '../dialogs/image-dialog/models/i-image-dialog-data.interface';
import { RevitCommsService } from './revit-comms.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalCommunicationService {
  constructor(
    private dialog: MatDialog,
    private imageDialog: ImageDialogService,
    private revitComms: RevitCommsService
  ) {}

  launchExternal(
    url: string | IImageDialogData[],
    type: LaunchTypes = LaunchTypes.newWindow,
    title?: string
  ): Promise<void> {
    return new Promise<void>((respond, reject) => {
      switch (type) {
        case LaunchTypes.newWindow: {
          if (typeof url === 'string') {
            if (this.revitComms.isRevitApp) {
              this.revitComms.launchPopup(url).then(respond, reject);
            } else {
              window.open(url, '_blank');

              respond();
            }
          }

          break;
        }

        case LaunchTypes.imagePopup: {
          let data: IImageDialogData[];

          if (typeof url === 'string') {
            data = [
              {
                title: title,
                imageUrl: url
              }
            ];
          } else {
            data = url;
          }

          this.imageDialog.open({
            data: data
          });

          break;
        }

        case LaunchTypes.videoPopup: {
          if (typeof url === 'string') {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.data = {
              title: title,
              videoUrl: url
            };

            this.dialog.open(VideoDialogComponent, dialogConfig);
          }

          break;
        }
      }
    });
  }
}
