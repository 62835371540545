import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../../google/services/google-analytics.service';
import { Project } from '../../../projects/models/project.model';
import { ProjectsService } from '../../../projects/services/projects.service';
import { TradeOffAdvisorDialog } from '../../../selections/controllers/trade-off-advisor-dialog.controller';
import {
  Answer,
  Answer as LayoutConfigAnswer,
} from '../../../selections/models/layout-config/answer.model';
import { LayoutConfiguration } from '../../../selections/models/layout-config/layout-configuration.model';
import { Question as LayoutConfigQuestion } from '../../../selections/models/layout-config/question.model';
import { LayoutUpdate } from '../../../selections/models/layout-update/layout-update.model';
import { ValidationMetaData } from '../../../selections/models/validation-meta-data/validation-meta-data.model';
import { LayoutService } from '../../../selections/services/layout.service';
import { BathSpCalculator } from '../../controllers/bath-sp-calculator.controller';
import { EcapsCore } from '../../controllers/ecaps-core.controller';
import { IReturnData } from '../../dialogs/bath-sp-calculator-dialog/models/i-return-data.interface';
import { LocationUpdate } from '../../dialogs/elevation-dialog/models/location-update.model';
import { DisplayMaskPipe } from '../../pipes/custom-pipes.pipe';
import { HelpService } from '../../services/help.service';
import { IHelpItem } from '../../services/models/interfaces/i-help-item.interface';
import {
  minMaxNullValidator,
  numericValidator,
} from '../../validators/custom-validators.validator';
import { QuestionColorMap } from './models/color-question-map.const';
import { swatches } from './models/swatches.const';

@Component({
  selector: 'configuration-question',
  templateUrl: './configuration-question.component.html',
  styleUrls: ['./configuration-question.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigurationQuestionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input('layout') public layoutConfig: LayoutConfiguration;
  @Input('question') public question: LayoutConfigQuestion;
  @Input('autoFocus') public autoFocus: boolean;
  @Input('linear') public linear: boolean;

  @Output('answered') public answered = new EventEmitter<LayoutUpdate>();
  @Output('locationUpdated')
  public locationUpdated = new EventEmitter<LocationUpdate>();
  @Output('sizeChanged') public sizeChanged = new EventEmitter<void>();
  @Output('focus') public focus = new EventEmitter<void>();
  @Output('blur') public blur = new EventEmitter<void>();

  @Output('validChanged') public validChanged = new EventEmitter<boolean>();

  @ViewChild('inputField', { static: false }) private inputField: ElementRef;

  private updateSubscription: Subscription;

  public formGroup: UntypedFormGroup;

  public mainInput: UntypedFormControl;

  public localProject: Project;

  helpItem: IHelpItem;

  chromeVersion?: number;

  constructor(
    private layout: LayoutService,
    private core: EcapsCore,
    public element: ElementRef,
    private detector: ChangeDetectorRef,
    private projectsService: ProjectsService,
    private tradeOffAdvisorDialog: TradeOffAdvisorDialog,
    private bathSPCalc: BathSpCalculator,
    private helpService: HelpService,
    private analytics: GoogleAnalyticsService
  ) {
    this.mainInput = new UntypedFormControl();

    this.formGroup = new UntypedFormGroup({
      mainInput: this.mainInput,
    });

    this.projectsService.getLocalProject().then((project) => {
      this.localProject = project;
    });

    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    this.chromeVersion = raw ? parseInt(raw[2], 10) : -1;
  }

  ngOnInit() {
    this.setValidators();

    if (!this.question.enabled) {
      this.mainInput.disable();
    }

    this.setValue(this.question.value);

    this.updateSubscription = this.layoutConfig.updated.subscribe(() => {
      this.setValidators();

      this.setValue(this.question.value);
    });

    const helpKey =
      this.question.helpKey ||
      `${this.layoutConfig.productType}_LayoutConfig_${this.question.name}`;

    (this.element.nativeElement as HTMLElement).setAttribute(
      'help-key',
      helpKey
    );

    this.helpService
      .getHelp(helpKey)
      .then((helpItem) => (this.helpItem = helpItem));
  }

  ngAfterViewInit() {
    const self = this;

    if (this.autoFocus) {
      setTimeout(() => {
        self.inputField.nativeElement.focus();

        self.detector.detectChanges();
      });
    }
  }

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  inputFocus() {
    this.focus.next();
  }

  inputBlur() {
    this.questionUpdate();

    this.blur.next();
  }

  getQuestionAnswers(): Array<LayoutConfigAnswer> {
    let answers = new Array<LayoutConfigAnswer>();

    if (
      this.question &&
      this.question.answers &&
      this.question.answers.length > 0
    ) {
      answers = this.question.answers.filter(
        (answer) =>
          answer.visible && answer.state.toLowerCase().trim() !== 'blocked'
      );
    }

    return answers;
  }

  getQuestionAnswerText(): string {
    if (this.question.value === undefined || this.question.value === null) {
      return '';
    } else {
      return this.question.answers.find(
        (answer) => answer.name === this.question.value
      )?.text;
    }
  }

  questionUpdate(additionalQuestions?: Object) {
    if (this.question.value !== this.mainInput.value && this.mainInput.valid) {
      let updating = true;

      this.core.showLoadingGraphic('Updating...', function () {
        return updating;
      });

      let answers = {};

      answers[this.question.name] = this.mainInput.value;

      if (!!additionalQuestions) {
        answers = { ...answers, ...additionalQuestions };
      }

      let hasValidationData = false;

      this.layout
        .updateLayoutConfig(this.layoutConfig, answers, false)
        .then((resultData) => {
          if (!resultData.action) {
            this.setValue(this.question.value);

            const validationData = this.getValidationData(resultData);

            if (!!validationData) {
              hasValidationData = true;

              updating = false;

              this.core.hideLoadingGraphic();

              this.procValidationData(validationData).then(() => {
                this.layoutConfig.updated.next(undefined);

                this.answered.next(resultData);

                updating = false;

                this.core.hideLoadingGraphic();
              });
            } else {
              const questions: { name: string; value: string }[] = Object.keys(
                answers
              ).map((key) => ({
                name: key,
                value: answers[key] as string,
              }));

              this.layoutConfig.updated.next(questions);

              this.answered.next(resultData);
            }
          } else {
            this.tradeOffAdvisorDialog
              .show(resultData, this.layoutConfig)
              .then(() => {
                this.layoutConfig.updated.next(undefined);

                this.answered.next(resultData);
              });
          }

          if (!hasValidationData) {
            updating = false;

            this.core.hideLoadingGraphic();
          }

          if (
            this.layoutConfig.productType === 'Fan' &&
            [
              'MotorHorsePower',
              'MotorPhase',
              'Voltage',
              'VoltageCyclePhase',
            ].includes(this.question.name)
          ) {
            this.analytics.trackEvent_Old(
              EventCategories.configuration,
              'Question Answered',
              `${this.layoutConfig.productType} - ${this.question.name}`,
              this.mainInput.value
            );
          }
        });
    } else if (this.mainInput.touched) {
      this.validChanged.next(
        this.mainInput.disabled ? true : this.mainInput.valid
      );
    }
  }

  findElevation() {
    this.core.showElevationDialog().then((results) => {
      if (!!results) {
        this.setValue(results.elevation.toString());

        this.questionUpdate();

        this.locationUpdated.next(results);
      }
    });
  }

  private getValidationData(update: LayoutUpdate): ValidationMetaData {
    const baseQuestions = update.groups.find(
      (group) => group.name.toLowerCase().trim() === 'basequestions'
    );

    if (!!baseQuestions) {
      const validationDataQuestion = baseQuestions.questions.find(
        (question) =>
          question.name.toLowerCase().trim() === 'validationmetadata'
      );

      if (!!validationDataQuestion) {
        const rawJSON = validationDataQuestion.properties.find(
          (property) => property.name.toLowerCase().trim() === 'value'
        ).value;

        return new ValidationMetaData(JSON.parse(rawJSON));
      }
    }

    return null;
  }

  private procValidationData(
    validationData: ValidationMetaData
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      const updates: { [key: string]: any } = {};
      const tradeOffMessages: string[] = [];

      validationData.questions
        .filter(
          (item) =>
            item.question.toLowerCase().trim() ===
            this.question.name.toLowerCase().trim()
        )
        .forEach((item) => {
          let showTradeOff = false;

          item.actions.forEach((action) => {
            updates[action.question] = action.value;

            if (!!this.layoutConfig.getQuestion(action.question).value) {
              showTradeOff = true;
            }
          });

          if (showTradeOff) {
            tradeOffMessages.push(item.message);
          }
        });

      const resolveActions = () => {
        if (Object.keys(updates).length === 0) {
          results(true);

          return;
        }

        let updating = true;

        this.core.showLoadingGraphic('Updating...', () => updating);

        this.layout.updateLayoutConfig(this.layoutConfig, updates, false).then(
          () => {
            updating = false;

            this.core.hideLoadingGraphic();

            results(true);
          },
          (errorData) => {
            updating = false;

            this.core.hideLoadingGraphic();
            reject(errorData);
          }
        );
      };

      if (tradeOffMessages.length > 0) {
        this.tradeOffAdvisorDialog
          .show(tradeOffMessages.join('\n'))
          .then((result) => {
            if (result) {
              resolveActions.call(this);
            }
          });
      } else {
        resolveActions.call(this);
      }
    });
  }

  hasAnswers(): boolean {
    return this.question.answers && this.question.answers.length > 0;
  }

  showElevationHint(): boolean {
    if (
      !!this.localProject &&
      !!this.localProject.elevation &&
      this.question.name.toLowerCase().trim() === 'elevation'
    ) {
      return (
        !!this.question.value &&
        parseFloat(this.question.value) !== this.localProject.elevation &&
        this.localProject.elevation > 0
      );
    } else {
      return false;
    }
  }

  setValue(value: any) {
    if (!value) {
      value = this.question.value;
    }

    if (!!this.question.valueDisplayMask) {
      value = new DisplayMaskPipe().transform(
        value,
        this.question.valueDisplayMask
      );
    }

    this.mainInput.setValue(value);

    this.validChanged.next(
      this.mainInput.disabled || this.mainInput.untouched
        ? true
        : this.mainInput.valid
    );
  }

  showBathFanSPCalc(): boolean {
    if (!this.layoutConfig) {
      return false;
    }

    if (
      this.question.name === 'StaticPressure' &&
      !!this.layoutConfig.getQuestion('ModelGroup') &&
      this.layoutConfig.getQuestion('ModelGroup').value === 'CeilingAndCabinet'
    ) {
      return true;
    } else {
      return false;
    }
  }

  bathSPCalcClick() {
    this.bathSPCalc.show().then((result: IReturnData) => {
      if (result !== null && typeof result !== 'undefined') {
        this.setValue(result.staticPressure);

        this.questionUpdate(result.additionalUpdates);
      }
    });
  }

  helpIconClick() {
    this.helpService.showHelp(this.helpItem);
  }

  private setValidators() {
    this.mainInput.clearValidators();

    const validators = [];

    if (this.question.required) {
      validators.push(Validators.required);
    }

    if (!!this.question.validationFeatures) {
      let min = null;
      let max = null;

      if (!!this.question.validationFeatures['min']) {
        validators.push(
          Validators.min(this.question.validationFeatures['min'])
        );

        min = this.question.validationFeatures['min'];
      }

      if (!!this.question.validationFeatures['max']) {
        validators.push(
          Validators.max(this.question.validationFeatures['max'])
        );

        max = this.question.validationFeatures['max'];
      }

      if (min !== null || max !== null) {
        validators.push(minMaxNullValidator(min, max));
      }
    }

    if (this.question.type.toLowerCase().trim() === 'integer') {
      validators.push(numericValidator(false));
    } else if (this.question.type.toLowerCase().trim() === 'float') {
      validators.push(numericValidator(true));
    }

    this.mainInput.setValidators(validators);
  }

  isColorPicker(): boolean {
    return (
      QuestionColorMap.findIndex(
        (questionName) => questionName === this.question.name
      ) > -1
    );
  }

  getSwatchBG(name: string): string {
    let swatch = swatches.find((item) => item.name === name);

    if (!swatch) {
      swatch = {
        name: name,
        text: name,
        hex: '#00000000',
      };
    }

    return !!swatch.hex ? swatch.hex : `url(${swatch.image})`;
  }

  hasSwatch(name: string) {
    return swatches.findIndex((item) => item.name === name) > -1;
  }

  getAnswer(name: string): Answer {
    return this.question.answers.find((item) => item.name === name);
  }
}
