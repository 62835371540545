import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-theme-color-swatches',
  templateUrl: './theme-color-swatches.component.html',
  styleUrls: ['./theme-color-swatches.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ThemeColorSwatchesComponent {
  palettes: string[] = ['primary', 'accent', 'warn', 'error'];
}
