import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsService } from './services/documents.service';
import { DocumentSelectorComponent } from './components/document-selector/document-selector.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { DocumentSelector } from './controllers/document-selector.controller';

@NgModule({
    imports: [
        CommonModule,
        MatBottomSheetModule,
        MatListModule,
        MatDividerModule,
        MatIconModule
    ],
    declarations: [DocumentSelectorComponent],
    providers: [DocumentsService, DocumentSelector]
})
export class DocumentsModule {}
