export class Action {
    question: string;
    group: string;
    value: object;
    constructor(data: any) {
        this.question = data.question;
        this.group = data.group;
        this.value = data.value;
    }
}
