<side-dialog-title>Customize Grid</side-dialog-title>

<side-dialog-content>
  <div class="customize-grid-dialog">
    <mat-tab-group color="primary" [mat-stretch-tabs]="false">
      <mat-tab label="Select Columns">
        <div class="list-parent">
          <mat-selection-list
            #visibleColumnsList
            verticalWrap
            (selectionChange)="onSelectionChange($event)"
          >
            <mat-list-option
              *ngFor="let column of columnList"
              togglePosition="before"
              color="primary"
              [selected]="column.selected"
              [value]="column"
              >{{ column.text }}</mat-list-option
            >
          </mat-selection-list>
        </div>
      </mat-tab>
      <mat-tab label="Arrange Columns">
        <div
          cdkDropList
          class="column-order-list"
          (cdkDropListDropped)="columnDropped($event)"
        >
          <div
            class="column-order-list-item"
            *ngFor="let column of columnOrder"
            cdkDrag
          >
            <mat-icon>drag_indicator</mat-icon>
            <span>{{ column.text }}</span>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</side-dialog-content>

<side-dialog-actions>
  <button mat-raised-button color="primary" (click)="okClick()">OK</button>
  <button mat-raised-button color="primary" (click)="cancelClick()">
    Cancel
  </button>
  <button mat-raised-button color="primary" (click)="resetClick()">
    Restore Defaults
  </button>
</side-dialog-actions>
