<div
  class="inline-editor__container"
  [class.editing]="editText"
  [class.show-delete]="showDelete"
  [ngSwitch]="editText"
  [attr.contenteditable]="editText"
>
  <ng-container *ngSwitchCase="false">
    <button
      class="inline-editor__btn"
      mat-button
      color="primary"
      (click)="edit()"
      *ngIf="!text"
    >
      Click to Edit
    </button>
    <span
      class="inline-editor__text"
      role="link"
      (stopPropClick)="edit()"
      *ngIf="!!text"
      [overflowTooltip]="text"
      >{{ text }}</span
    >
  </ng-container>
  <ng-container *ngSwitchCase="true">
    <input
      type="text"
      #editor
      [(ngModel)]="newText"
      (keyup.enter)="commitChange()"
      (keyup.escape)="cancelChange()"
    />
    <mat-icon (stopPropClick)="delete()">delete</mat-icon>
  </ng-container>
</div>
