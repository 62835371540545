import {
  AfterContentChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from '@angular/core';
import { ObserverService } from '../services/observer.service';

@Directive({
  selector: '[verticalWrap]',
})
export class VerticalWrapDirective
  implements AfterViewInit, AfterContentChecked, OnDestroy
{
  @Input() verticalWrap: string;

  private _visibleTimer?: any;

  constructor(
    private _element: ElementRef,
    private observer: ObserverService
  ) {}

  ngAfterViewInit(): void {
    if (
      ['MAT-SELECTION-LIST', 'MAT-LIST'].includes(
        this._element.nativeElement.tagName
      )
    ) {
      this.init();
    }
  }

  ngAfterContentChecked(): void {
    if (
      ['MAT-SELECTION-LIST', 'MAT-LIST'].includes(
        this._element.nativeElement.tagName
      ) &&
      this._element.nativeElement.clientHeight > 0
    ) {
      this.updateColumns();
    }
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this._element.nativeElement, 'resize');
  }

  private init() {
    this.updateColumns();

    this.observer.observe(
      this._element.nativeElement,
      'resize',
      (element, observing) => {
        if (!observing) {
          return false;
        }

        this.updateColumns();

        return true;
      }
    );
  }

  private updateColumns() {
    const parentHeight = this._element.nativeElement.clientHeight;

    const itemList: HTMLElement[] =
      this._element.nativeElement.querySelectorAll('.mat-mdc-list-item');

    const itemCount = itemList.length;

    let maxItemHeight = 0;

    itemList.forEach((item) => {
      if (item.clientHeight > maxItemHeight) {
        maxItemHeight = item.clientHeight;
      }
    });

    if (parentHeight === 0 || maxItemHeight === 0 || itemCount === 0) {
      // Element is not visible yet, try again in 250ms

      if (!this._visibleTimer) {
        this._visibleTimer = setTimeout(() => {
          this._visibleTimer = undefined;

          this.updateColumns();
        }, 250);
      }

      return;
    }

    const maxItemsPerColumn = Math.floor(parentHeight / maxItemHeight);
    const columnCount = Math.ceil(itemCount / maxItemsPerColumn);

    if (this._element.nativeElement.style.display.toString() !== 'grid') {
      this._element.nativeElement.style.display = 'grid';
    }

    const gridDefinition = `auto-flow minmax(0, 1fr) / repeat(${columnCount}, ${
      this.verticalWrap || '1fr'
    })`;

    if (this._element.nativeElement.style.grid.toString() !== gridDefinition) {
      this._element.nativeElement.style.grid = gridDefinition;
    }

    itemList.forEach((item, index) => {
      const columnIndex = Math.floor(index / maxItemsPerColumn);
      const rowIndex = index % maxItemsPerColumn;

      const cellDefinition = `${rowIndex + 1} / ${columnIndex + 1} / ${
        rowIndex + 2
      } / ${columnIndex + 2}`;

      const currentDefinition = item.style.gridArea.toString();

      if (currentDefinition !== cellDefinition) {
        item.style.gridArea = cellDefinition;
      }
    });
  }
}
