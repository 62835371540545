import { Injectable } from '@angular/core';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { SizeChartDialogComponent } from '../dialogs/size-chart-dialog/size-chart-dialog.component';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';
import { ChartTypes } from '../charting/enums/chart-types.enum';

@Injectable({
    providedIn: 'root'
  })
export class SizeChartDialog {

    constructor(private sideDialogService: SideDialogService) { }

    show(size: ValidSize, defaultType: ChartTypes = ChartTypes.operating): Promise<boolean> {

        const promise = new Promise<boolean>((resolve, reject) => {

            const dialogRef = this.sideDialogService.open(SizeChartDialogComponent, {
                disableClose: false,
                autoFocus: true,
                data: {

                    size: size,
                    defaultType: defaultType

                }
            });

            const subscription = dialogRef.beforeClose.subscribe(() => {

                subscription.unsubscribe();

                resolve(true);

            });

        });

        return promise;

    }

}
