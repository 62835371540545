import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface HttpOptions {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
      [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}
export interface HttpRequestOptions {
  body?: any;
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  params?: HttpParams | {
      [param: string]: string | string[];
  };
  // observe?: 'body' | 'events' | 'response';
  reportProgress?: boolean;
  // responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
}

export interface IFallstreakHttpClient {
  readonly apiUrl: string;
  readonly apiKey: string;

  get<T = Object>(path: string, options?: HttpOptions): Observable<T>;
  post<T = Object>(path: string, body: any, options?: HttpOptions): Observable<T>;
  put<T = Object>(path: string, body: any, options?: HttpOptions): Observable<T>;
  delete<T = Object>(path: string, options?: HttpOptions): Observable<T>;
  request<T = Object>(method: string, path: string, options?: HttpRequestOptions): Observable<T>;
}
export class FallstreakHttpClient implements IFallstreakHttpClient {
    public readonly apiUrl: string;
    public readonly apiKey: string;

    private readonly http: HttpClient;

    public static FromEnvironment(http: HttpClient, env: { serviceRoot: string, defaultApiKey: string }) {
      return new FallstreakHttpClient(http, env.serviceRoot, env.defaultApiKey);
    }

    public constructor(http: HttpClient, apiUrl: string, apiKey: string) {
      this.http = http;

      this.apiUrl = apiUrl;
      this.apiKey = apiKey;
    }

    public get<T = Object>(path: string, options?: HttpOptions): Observable<T> {
      const requestUrl = this.formatReqestUrl(path);

      return this.http.get<T>(requestUrl, options);
    }
    public post<T = Object>(path: string, body: any, options?: HttpOptions): Observable<T> {
      const requestUrl = this.formatReqestUrl(path);

      return this.http.post<T>(requestUrl, body, options);
    }
    public put<T = Object>(path: string, body: any, options?: HttpOptions): Observable<T> {
      const requestUrl = this.formatReqestUrl(path);

      return this.http.put<T>(requestUrl, body, options);
    }
    public delete<T = Object>(path: string, options?: HttpOptions): Observable<T> {
      const requestUrl = this.formatReqestUrl(path);

      return this.http.delete<T>(requestUrl, options);
    }
    public request<T = Object>(method: string, path: string, options?: HttpRequestOptions): Observable<T> {
      const requestUrl = this.formatReqestUrl(path);

      return this.http.request<T>(method, requestUrl, options);
    }

    private formatReqestUrl(path: string, query: { [key: string]: string } = {}): string {
      const [ rawPath, pathQS ] = path.indexOf('?') >= 0 ? path.split('?') : [ path, undefined ];

      const qs = `api_key=${this.apiKey}${Object.keys(query).map(key => `&${key}=${query[key]}`).join()}${pathQS ? `&${pathQS}` : ''}`;
      const sanitizedApiUrl = this.apiUrl.endsWith('/') ? this.apiUrl.slice(0, -1) : this.apiUrl;
      const sanitizedPath = rawPath.startsWith('/') ? rawPath : `/${rawPath}`;

      return `${sanitizedApiUrl}${sanitizedPath}?${qs}`;
    }
}
