import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SelectionResults } from '../../../selections/models/selection-results/selection-results.model';
import { LayoutService } from '../../../selections/services/layout.service';

@Component({
  selector: 'app-invalid-sizes-dialog',
  templateUrl: './invalid-sizes-dialog.component.html',
  styleUrls: ['./invalid-sizes-dialog.component.scss'],
})
export class InvalidSizesDialogComponent implements OnInit {
  lastSelectionResults: SelectionResults;

  constructor(
    private layout: LayoutService,
    private dialogRef: MatDialogRef<InvalidSizesDialogComponent>
  ) {
    this.lastSelectionResults = this.layout.getLastSelectionResults();
  }

  ngOnInit() {}

  closeClick() {
    this.dialogRef.close();
  }
}
