import { FormatObjectPipe } from '../../../ecaps-core/pipes/custom-pipes.pipe';
import { ProductTypes } from '../../../products/enums/product-types.enum';
import { LayoutConfiguration } from '../layout-config/layout-configuration.model';
import { InvalidSize } from './invalid-size.model';
import { ValidSize } from './valid-size.model';

export class SelectionResults {
  key: string;
  inputs: Object;
  invalidSizes: Array<InvalidSize>;
  validSizes: Array<ValidSize>;

  constructor(
    private layoutConfig: LayoutConfiguration,
    private productType: ProductTypes,
    private resultData: any
  ) {
    const formatObject = new FormatObjectPipe();

    this.key = this.resultData.key;

    if (this.resultData && this.resultData.inputs) {
      this.inputs = formatObject.transform(this.resultData.inputs);
    } else {
      this.inputs = {};
    }

    this.invalidSizes = new Array<InvalidSize>();

    if (
      this.resultData &&
      this.resultData.invalidSizes &&
      this.resultData.invalidSizes.length
    ) {
      for (let i = 0; i < this.resultData.invalidSizes.length; i++) {
        this.invalidSizes.push(
          new InvalidSize(this.resultData.invalidSizes[i])
        );
      }
    }

    this.validSizes = new Array<ValidSize>();

    if (
      this.resultData &&
      this.resultData.validSizes &&
      this.resultData.validSizes.length
    ) {
      for (let i = 0; i < this.resultData.validSizes.length; i++) {
        this.validSizes.push(
          new ValidSize(
            this.layoutConfig,
            this.productType,
            this.key,
            this.resultData.validSizes[i]
          )
        );
      }
    }
  }

  toJSON(): object {
    const output = {
      key: this.key,
      inputs: this.inputs,
      invalidSizes: this.invalidSizes,
      validSizes: this.validSizes,
    };

    return output;
  }
}
