import { Injectable, EventEmitter } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserData } from '../models/user-data.model';
import { UserNotes } from '../models/user-notes.model';
import { INote } from '../models/note.interface';
import { HttpService } from '../../ecaps-core/services/http.service';
import { EcapsStateService } from '../../ecaps-core/handlers/services/ecaps-state.service';
import { AuthService } from '../../ecaps-core/services/auth.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private currentUser: UserData;

  private _userNotes: UserNotes;

  private _notesUpdating = false;

  public userNotesUpdated = new EventEmitter<UserNotes>();

  public currentUserUpdated = new EventEmitter<UserData>();

  constructor(
    private http: HttpService,
    public authServices: AuthService,
    private ecapsState: EcapsStateService
  ) {
    this.ecapsState.usersService = this;

    this.currentUser = new UserData();

    this.currentUser.authenticated = false;

    if (!this.currentUser.token) {
      this.currentUser.token = environment.defaultApiKey;
    } else {
      this.currentUser.token = null;
    }

    this.currentUserUpdated.next(this.currentUser);

    authServices.currentUserUpdated.subscribe((currentUser) => {
      const lastAuthState = this.currentUser.authenticated;

      this.currentUser = currentUser;

      if (this.currentUser.authenticated !== lastAuthState) {
        this.currentUserUpdated.next(this.currentUser);
      }
    });

    authServices.authTokenUpdated.subscribe((authToken) => {
      authServices.validateUser();
    });
  }

  login() {
    this.authServices.login();
  }

  logout() {
    this.authServices.logout();
  }

  getCurrentUser(loadPrevious = true): Promise<UserData> {
    return Promise.resolve(this.currentUser);
  }

  getNotes(reload: boolean = false): Promise<UserNotes> {
    const promise = new Promise<UserNotes>((results, reject) => {
      if (!!this._userNotes && !reload) {
        results(this._userNotes);
      } else if (!this._notesUpdating) {
        this._notesUpdating = true;

        const subscription = this.http
          .get(`/user/notes`, {
            headers: new HttpHeaders({
              'x-user-token':
                !!this.currentUser && !!this.currentUser.token
                  ? this.currentUser.token
                  : '',
            }),
          })
          .subscribe({
            next: (data) => {
              this._userNotes = new UserNotes(data);

              this.sortUserNotes();

              this.userNotesUpdated.next(this._userNotes);

              results(this._userNotes);
            },
            error: (errorData) => {
              reject(errorData);
            },
            complete: () => {
              subscription.unsubscribe();

              this._notesUpdating = false;
            },
          });
      } else {
        const subscription = this.userNotesUpdated.subscribe((notes) => {
          subscription.unsubscribe();

          results(notes);
        });
      }
    });

    return promise;
  }

  addNote(productType: string, note: string): Promise<INote> {
    return new Promise<INote>((results, reject) => {
      firstValueFrom(
        this.http.post(
          `/user/notes`,
          {
            content: note,
            productType: productType,
          },
          {
            headers: new HttpHeaders({
              'x-user-token': this.currentUser.token,
            }),
          }
        )
      ).then(
        (data) => {
          const newNote = <INote>data;

          this._userNotes.customNotes.push(newNote);

          this.sortUserNotes();

          this.userNotesUpdated.next(this._userNotes);

          results(newNote);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  updateNote(note: INote, content: string): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.post(
          `/user/notes/${note.id}`,
          {
            content: content.trim(),
            productType: note.productType,
          },
          {
            headers: new HttpHeaders({
              'x-user-token': this.currentUser.token,
            }),
          }
        )
      ).then(
        () => {
          note.content = content.trim();

          const customNote = this._userNotes.customNotes.find(
            (cNote) => cNote.id === note.id
          );

          if (!!customNote) {
            customNote.content = content.trim();
          }

          this.sortUserNotes();

          this.userNotesUpdated.next(this._userNotes);

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  deleteNote(note: INote): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      firstValueFrom(
        this.http.delete(`/user/notes/${note.id}`, {
          headers: new HttpHeaders({
            'x-user-token': this.currentUser.token,
          }),
        })
      ).then(
        () => {
          const index = this._userNotes.customNotes.findIndex(
            (cNote) => cNote.id === note.id
          );

          if (index > -1) {
            this._userNotes.customNotes.splice(index, 1);
          }

          this.sortUserNotes();

          this.userNotesUpdated.next(this._userNotes);

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  private sortUserNotes() {
    const sortMethod = (a, b) => {
      if (b.content < a.content) {
        return 1;
      } else if (b.content > a.content) {
        return -1;
      } else {
        return 0;
      }
    };

    this._userNotes.customNotes.sort(sortMethod);

    this._userNotes.defaultNotes.sort(sortMethod);
  }
}
