<h2 mat-dialog-title>User Feedback</h2>

<mat-dialog-content>
  <span
    >Please feel free to send us any questions, comments, concerns, or ideas you
    may have regarding this site. We value your opinions.</span
  >
  <form [formGroup]="formGroup" [class.validate]="validate">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input
        matInput
        type="text"
        formControlName="name"
        [readonly]="authenticated"
      />
      <mat-error *ngIf="!!name.errors">{{ name | fieldErrorToText }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Organization</mat-label>
      <input matInput type="text" formControlName="organization" />
      <mat-error *ngIf="!!organization.errors">{{
        organization | fieldErrorToText
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        [readonly]="authenticated"
      />
      <mat-error *ngIf="!!email.errors">{{
        email | fieldErrorToText
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Comments</mat-label>
      <textarea
        matInput
        formControlName="comments"
        autocomplete="off"
      ></textarea>
      <mat-error *ngIf="!!comments.errors">{{
        comments | fieldErrorToText
      }}</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="sendClick()">Send</button>
  <button mat-raised-button color="primary" (click)="cancelClick()">
    Cancel
  </button>
</mat-dialog-actions>
