import {
  animate,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { LachesisService } from '../../../analytics/services/lachesis.service';
import { RevitCommsService } from '../../../external-communication/services/revit-comms.service';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../../google/services/google-analytics.service';

@Component({
  selector: 'app-notification',
  templateUrl: './app-notification.component.html',
  styleUrls: ['./app-notification.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('pulse', [
      transition('* => show', [
        sequence([
          style({ color: 'white' }),
          animate(
            '0.5s ease',
            style({
              color: '#ffca28',
            })
          ),
          animate(
            '0.5s ease',
            style({
              color: 'white',
            })
          ),
          animate(
            '0.5s ease',
            style({
              color: '#ffca28',
            })
          ),
          animate(
            '0.5s ease',
            style({
              color: 'white',
            })
          ),
          animate(
            '0.5s ease',
            style({
              color: '#ffca28',
            })
          ),
          animate(
            '0.5s ease',
            style({
              color: 'white',
            })
          ),
        ]),
      ]),
    ]),
  ],
})
export class AppNotificationComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  notices: {
    text: string;
    icon: string;
    click: () => void;
  }[] = [];

  pulseIcon = false;

  constructor(
    private revitComms: RevitCommsService,
    private lachesisService: LachesisService,
    private analytics: GoogleAnalyticsService,
    private element: ElementRef
  ) {
    (this.element.nativeElement as HTMLElement).classList.add(
      'app-notification'
    );

    this.listNotices();

    this.revitComms.revitAppUpdated.subscribe(() => {
      this.listNotices();
    });
  }

  ngOnInit(): void {}

  private listNotices(): void {
    this.notices.splice(0);

    this.checkForRevitUpdates();

    if (this.notices.length === 0) {
      (this.element.nativeElement as HTMLElement).classList.add('hide');
    } else {
      (this.element.nativeElement as HTMLElement).classList.remove('hide');

      this.checkRevitPulse();
    }
  }

  private checkForRevitUpdates(): void {
    const newVersion = this.revitComms.getNewVersion();

    if (!newVersion) {
      return;
    } else {
      this.notices.push({
        text: newVersion.message || 'eCAPS Bridge update available!',
        icon: 'system_update_alt',
        click: () => {
          this.lachesisService.trackEvent({
            eventName: 'Plugin Update Notice',
            revitVersion: this.revitComms.revitVersion,
            revitPluginVersion: this.revitComms.revitPluginVersion,
          });

          this.analytics.trackEvent_Old(
            EventCategories.revitAddin,
            'Update Addon'
          );

          this.revitComms.launchPopup(
            'https://www.greenheck.com/resources/software/ecaps-bridge-for-revit'
          );
        },
      });
    }
  }

  private checkRevitPulse(): void {
    const LS_REVIT_VERSION_KEY = 'REVIT_APP_LAST_VERSION';
    const newVersion = this.revitComms.getNewVersion();

    if (!!newVersion) {
      if (
        window.localStorage.getItem(LS_REVIT_VERSION_KEY) !== newVersion.version
      ) {
        setTimeout(() => {
          this.menuTrigger.openMenu();

          this.pulseIcon = true;
        });

        window.localStorage.setItem(LS_REVIT_VERSION_KEY, newVersion.version);
      }
    }
  }
}
