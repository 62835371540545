import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersService } from './services/users.service';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EcapsCoreModule } from '../ecaps-core/ecaps-core.module';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { Users } from './controllers/users.controller';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        MatDialogModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        EcapsCoreModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatButtonModule
    ],
    declarations: [UserIconComponent],
    providers: [UsersService, Users],
    exports: [UserIconComponent]
})
export class UsersModule {}
