import { Injectable } from '@angular/core';

export interface ICookieOption {
  id: string;
  title: string;
  description?: string;
  required?: boolean;
  value: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CookiePolicyService {
  private _optionList: ICookieOption[] = [
    {
      id: 'required',
      title: 'Essential Website Cookies',
      description: `
        These cookies are strictly necessary to provide you with the services and features
        available through our site. Because these cookies are strictly necessary to
        deliver the site, you cannot refuse them without impacting how the site functions.`,
      required: true,
      value: true,
    },
    {
      id: 'performance',
      title: 'Performance and Functionality Cookies',
      description: `
        These cookies are used to enhance the functionality of the site. They help us to
        customize the site and application (where applicable) for you in order to enhance
        your experience. Although important to us, these cookies are non-essential to the
        use of the site. However, without these cookies, certain functionality may become
        unavailable.
        `,
      value: true,
    },
    {
      id: 'advertising',
      title: 'Advertising (Targeting) Cookies',
      description: `
        These cookies are used to make advertising messages more relevant to you and your
        interests. They also perform functions like preventing the same ad from
        continuously reappearing, ensuring that ads are properly displayed, and in some
        cases selecting advertisements that are based on your interests.
        `,
      value: true,
    },
    {
      id: 'analytics',
      title: 'Analytics and Customization Cookies',
      description: `
        These cookies collect information to help us understand how the site is being used
        or how effective our marketing campaigns are. They help us to know which pages are
        the most and least popular and to see how visitors move around the site. These
        cookies may be set by us or by third-party providers whose services we have added
        to our pages.
        `,
      value: true,
    },
  ];

  public get optionList(): ICookieOption[] {
    return this._optionList;
  }

  public get optionsSaved(): boolean {
    return !!window.localStorage.getItem('cookieOptions');
  }

  constructor() {
    this.loadOptions();
  }

  getOption(key: string): boolean {
    const option = this._optionList.find((opt) => opt.id === key);

    return option?.value || false;
  }

  setOption(key: string, value: boolean): void {
    const option = this._optionList.find((opt) => opt.id === key);

    if (!!option) {
      option.value = value;
    }
  }

  saveOptions(): void {
    window.localStorage.setItem(
      'cookieOptions',
      JSON.stringify(
        this.optionList.map((option) => ({
          name: option.id,
          value: option.value,
        }))
      )
    );
  }

  private loadOptions(): void {
    const choices: {
      name: string;
      value: Boolean;
    }[] = JSON.parse(window.localStorage.getItem('cookieOptions'));

    if (!!choices && choices.length > 0) {
      choices.forEach((choice) => {
        const option = this.optionList.find(
          (option) => option.id === choice.name
        );

        if (!!option && !option.required) {
          option.value = !!choice.value;
        }
      });
    }
  }
}
