import { Revision } from './project-revision.model';
import { EventEmitter } from '@angular/core';
import { ProjectUpdateTypes } from '../enums/project-update-types.enum';
import { IProjectEvent } from './i-project-event.interface';

export class Project {
  private _active: boolean;
  public get active(): boolean {
    return this._active;
  }
  public set active(value: boolean) {
    this._active = value;

    this._isDirty = true;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  private _creationDate: Date;
  public get creationDate(): Date {
    return this._creationDate;
  }

  private _deleted: boolean;
  public get deleted(): boolean {
    return this._deleted;
  }

  private _elevation: number;
  public get elevation(): number {
    return this._elevation;
  }
  public set elevation(value: number) {
    this._elevation = value;

    this._isDirty = true;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  private _id: string;
  public get id(): string {
    return this._id;
  }

  private _isNew: boolean;
  public get isNew(): boolean {
    return this._isNew;
  }

  private _isDirty = true;
  public get isDirty(): boolean {
    return this._isDirty;
  }
  public set isDirty(value: boolean) {
    this._isDirty = value;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  private _key: string;
  public get key(): string {
    return this._key;
  }

  private _lastSavedDate: Date;
  public get lastSavedDate(): Date {
    return this._lastSavedDate;
  }
  public set lastSavedDate(value: Date) {
    this._lastSavedDate = value;

    this._isDirty = true;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  private _location: string;
  public get location(): string {
    return this._location;
  }
  public set location(value: string) {
    this._location = value;

    this._isDirty = true;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;

    this._isDirty = true;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  private _publicToken: string;
  public get publicToken(): string {
    return this._publicToken;
  }

  private _size: number;
  public get size(): number {
    return this._size;
  }
  public set size(value: number) {
    this._size = value;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  revisions: Revision[] = [];

  private _warnings: string[];
  public get warnings(): string[] {
    return this._warnings;
  }
  public set warnings(value: string[]) {
    this._warnings = value;

    this.updated.next({
      source: this,
      type: ProjectUpdateTypes.propertiesUpdated
    });
  }

  updated = new EventEmitter<IProjectEvent>();

  constructor(projectData: any) {
    this._active = projectData.active;
    this._creationDate = new Date(projectData.creationDate);
    this._deleted = projectData.deleted;
    this._elevation = projectData.elevation;
    this._id = projectData.id;
    this._isNew = projectData.isNew;
    this._key = projectData.key;
    this._lastSavedDate = projectData.lastSavedDate ? new Date(projectData.lastSavedDate) : null;
    this._location = projectData.location;
    this._name = projectData.name;
    this._publicToken = projectData.publicToken;
    this._size = projectData.size;
    this._warnings = projectData.warnings || [];

    this.revisions.splice(0);

    if (projectData.revisions && projectData.revisions.length > 0) {
      projectData.revisions.forEach(revisionData => {
        this.addRevision(new Revision(revisionData));
      });
    }
  }

  toJSON() {
    return {
      active: this._active,
      creationDate: this._creationDate,
      deleted: this._deleted,
      elevation: this._elevation,
      id: this._id,
      isNew: this._isNew,
      isDirty: this._isDirty,
      key: this._key,
      lastSavedDate: this._lastSavedDate,
      location: this._location,
      name: this._name,
      publicToken: this._publicToken,
      size: this._size,
      revisions: this.revisions.map(revision => revision.toJSON()),
      warnings: this._warnings
    };
  }

  getActiveRevision(): Revision {
    if (this.revisions && this.revisions.length > 0) {
      for (let i = 0; i < this.revisions.length; i++) {
        if (this.revisions[i].active) {
          return this.revisions[i];
        }
      }
    }

    return null;
  }

  isActiveRevisionValid(): Boolean {
    const currentRev = this.getActiveRevision();

    for (let i = 0; i < currentRev.items.length; i++) {
      if (currentRev.items[i].status.toLocaleLowerCase() === 'invalid') {
        return false;
      }
    }

    return true;
  }

  getItemCount(): number {
    const revision = this.getActiveRevision();

    if (revision) {
      let count = 0;

      if (revision.items && revision.items.length) {
        for (let i = 0; i < revision.items.length; i++) {
          count += revision.items[i].quantity;
        }
      }

      return count;
    } else {
      return 0;
    }
  }

  addRevision(revision: Revision) {
    if (!this.revisions.find(rev => rev.id === revision.id)) {
      revision.project = this;

      this.revisions.push(revision);

      this._isDirty = true;

      this.updated.next({
        source: revision,
        type: ProjectUpdateTypes.revisionUpdated
      });
    }
  }

  deleteRevision(revision: Revision) {
    const index = this.revisions.findIndex(rev => rev.id === revision.id);

    if (index > -1) {
      this.revisions.splice(index, 1);

      this._isDirty = true;

      this.updated.next({
        source: revision,
        type: ProjectUpdateTypes.revisionUpdated
      });
    }
  }

  dispose() {
    this.revisions.forEach(revision => revision.dispose());

    this.revisions.splice(0);
  }
}
