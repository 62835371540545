<div
  class="job-quick-view"
  [@showHide]="isVisible ? 'show' : 'hide'"
  [style.top.px]="top"
  [style.left.px]="left"
>
  <div class="arrow"></div>

  <mat-list role="list">
    <mat-list-item
      role="listitem"
      (click)="itemClick()"
      (mouseover)="mouseover = true"
      (mouseout)="mouseout()"
    >
      <img
        matListItemAvatar
        *ngIf="!!newItem"
        [src]="newItem?.sizeData?.imagePath()"
      />
      <span matListItemTitle>{{ newItem?.name }}</span>
      <span matListItemLine class="quantity"
        >Qty: {{ newItem?.quantity | number : '1.0-0' }}</span
      >
    </mat-list-item>
  </mat-list>
</div>
