import { EventEmitter, Injectable } from '@angular/core';

export interface ICrumb {
  text: string;
  route: string | (() => void);
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  crumbsUpdated = new EventEmitter<void>();

  private _crumbs: ICrumb[] = [];
  public get crumbs(): ICrumb[] {
    return this._crumbs;
  }
  public set crumbs(value: ICrumb[]) {
    this._crumbs = value;

    this.crumbsUpdated.emit();
  }

  constructor() { }
}
