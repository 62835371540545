import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Injector,
} from '@angular/core';
import { ColumnList } from '../../constants/column-list.const';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { Subscription } from 'rxjs';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';

@Component({
  selector: 'diesel-generator-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DieselGeneratorGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  constructor(protected injector: Injector) {
    super(injector);

    this.gridSaveKeyOverride = 'FumeExhaust_DieselGenerator';
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'actualCFM',
      'budgetPrice',
      'systemBhp',
      'effectivePlume',
      'systemInletDba',
      'dimensionsLWH',
      'wbExitTemp',
      'amca',
      'inletDesignTemp',
      'operatingStaticPressure',
      'operatingCost',
      'bhp',
      'dilutionRatio',
      'inletSones',
      'inletDba',
      'fanRPM',
      'driveType',
      'weight',
      'wheelDiameter',
      'motorHP',
      'staticEfficiency',
      'feg',
      'fei',
      'totalEfficiency',
      'peakTotalEfficiency',
      'pointsWithinPeakTotalEfficiency',
      'hasVFD',
      'operatingFrequency',
      'speedNonAdjusted',
      'startupPower',
      'speedMax',
      'fanClassDescription',
      'driveLossPercentage',
      'tipSpeed',
      'nozzleSize',
      'nozzleOutletVelocity',
      'windbandArea',
      'inletOctaveBand63Hz',
      'inletOctaveBand125Hz',
      'inletOctaveBand250Hz',
      'inletOctaveBand500Hz',
      'inletOctaveBand1kHz',
      'inletOctaveBand2kHz',
      'inletOctaveBand4kHz',
      'inletOctaveBand8kHz',
      'inletLwa',
      'outletOctaveBand63Hz',
      'outletOctaveBand125Hz',
      'outletOctaveBand250Hz',
      'outletOctaveBand500Hz',
      'outletOctaveBand1kHz',
      'outletOctaveBand2kHz',
      'outletOctaveBand4kHz',
      'outletOctaveBand8kHz',
      'outletLwa',
      'outletSones',
      'outletDba'
    );

    const actualCFMCol = this.columnList.find(
      (item) => item.key === 'actualCFM'
    );

    actualCFMCol.text = 'Fan Inlet Vol (CFM)';

    const systemBhpColumn = this.columnList.find(
      (item) => item.key === 'systemBhp'
    );

    systemBhpColumn.text = 'System Bhp';

    this.setVisibleColumns([
      'actualCFM',
      'budgetPrice',
      'systemBhp',
      'fei',
      'effectivePlume',
      'systemInletDba',
      'dimensionsLWH',
      'wbExitTemp',
      'amca',
    ]);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();
  }

  private validSizeToDSRow(size: ValidSize) {
    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.name,
      actualCFM: size.outputs.operatingVolume,
      budgetPrice: size.pricing.price,
      systemBhp: size.outputs.systemOperatingPower,
      effectivePlume: size.outputs.plumeHeight,
      systemInletDba: size.outputs.systemInletDba,
      dimensionsLWH: `${size.outputs.overallLength} x ${size.outputs.overallWidth} x ${size.outputs.overallHeight}`,
      wbExitTemp: size.outputs.dischargeTemperature,
      amca: size.amcaRatings,
      inletDesignTemp: size.outputs.designTemperature,
      stable: size.stability.stable,
      operatingStaticPressure: size.outputs.operatingStaticPressure,
      operatingCost: size.outputs.operatingCost,
      bhp: size.outputs.operatingPower,
      dilutionRatio: size.outputs.dilutionRatio,
      inletSones: size.outputs.inletSound.sones,
      inletDba: size.outputs.inletSound.dba,
      fanRPM: size.outputs.speed,
      driveType: size.outputs.driveType,
      weight: size.pricing.weight,
      wheelDiameter: size.outputs.wheelDiameter,
      motorHP: size.outputs.minimumMotorSizeNema,
      outletVelocity: size.outputs.outletVelocity,
      staticEfficiency: size.outputs.staticEfficiency,
      feg: size.outputs.feg,
      fei: size.outputs.fei,
      totalEfficiency: size.outputs.totalEfficiency,
      peakTotalEfficiency: size.outputs.peakTotalEfficiency,
      pointsWithinPeakTotalEfficiency:
        size.outputs.pointsWithinPeakTotalEfficiency,
      hasVFD: size.outputs.hasVFD,
      operatingFrequency: size.outputs.operatingFrequency,
      speedNonAdjusted: size.outputs.speedNonAdjusted,
      startupPower: size.outputs.startupPower,
      speedMax: size.outputs.speedMax,
      fanClassDescription: size.outputs.fanClassDescription,
      driveLossPercentage: size.outputs.driveLossPercentage,
      tipSpeed: size.outputs.tipSpeed,
      nozzleSize: size.outputs.nozzleSize,
      nozzleOutletVelocity: size.outputs.nozzleOutletVelocity,
      windbandArea: size.outputs.windbandArea,
      sizeData: size,
    };

    if (!!size.outputs.inletSound) {
      rowData['inletSones'] = size.outputs.inletSound.sones;
      rowData['inletDba'] = size.outputs.inletSound.dba;
      rowData['inletOctaveBand63Hz'] = size.outputs.inletSound.octaveBand63Hz;
      rowData['inletOctaveBand125Hz'] = size.outputs.inletSound.octaveBand125Hz;
      rowData['inletOctaveBand250Hz'] = size.outputs.inletSound.octaveBand250Hz;
      rowData['inletOctaveBand500Hz'] = size.outputs.inletSound.octaveBand500Hz;
      rowData['inletOctaveBand1kHz'] = size.outputs.inletSound.octaveBand1kHz;
      rowData['inletOctaveBand2kHz'] = size.outputs.inletSound.octaveBand2kHz;
      rowData['inletOctaveBand4kHz'] = size.outputs.inletSound.octaveBand4kHz;
      rowData['inletOctaveBand8kHz'] = size.outputs.inletSound.octaveBand8kHz;
      rowData['inletLwa'] = size.outputs.inletSound.lwa;
    }

    if (!!size.outputs.outletSound) {
      rowData['outletOctaveBand63Hz'] = size.outputs.outletSound.octaveBand63Hz;
      rowData['outletOctaveBand125Hz'] =
        size.outputs.outletSound.octaveBand125Hz;
      rowData['outletOctaveBand250Hz'] =
        size.outputs.outletSound.octaveBand250Hz;
      rowData['outletOctaveBand500Hz'] =
        size.outputs.outletSound.octaveBand500Hz;
      rowData['outletOctaveBand1kHz'] = size.outputs.outletSound.octaveBand1kHz;
      rowData['outletOctaveBand2kHz'] = size.outputs.outletSound.octaveBand2kHz;
      rowData['outletOctaveBand4kHz'] = size.outputs.outletSound.octaveBand4kHz;
      rowData['outletOctaveBand8kHz'] = size.outputs.outletSound.octaveBand8kHz;
      rowData['outletSones'] = size.outputs.outletSound.sones;
      rowData['outletDba'] = size.outputs.outletSound.dba;
      rowData['outletLwa'] = size.outputs.outletSound.lwa;
    }

    return rowData;
  }
}
