import { Injectable } from '@angular/core';
import { Item } from '../../projects/models/project-item.model';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';
import { ProductInfoDialogComponent } from '../dialogs/product-info-dialog/product-info-dialog.component';
import { ProductInfoDialogData } from '../models/product-info-dialog-data.model';
import { ProductInfoDialogReturnTypes } from '../models/product-info-dialog-return-types.model';

@Injectable({
  providedIn: 'root',
})
export class ProductInfoDialog {
  constructor(private sideDialogService: SideDialogService) {}

  show(
    size: ValidSize,
    tab: string = 'information',
    revisionItem?: Item,
    tag?: string,
    quantity?: number
  ): Promise<ProductInfoDialogReturnTypes> {
    const promise = new Promise<ProductInfoDialogReturnTypes>((resolve) => {
      const dialogRef = this.sideDialogService.open(
        ProductInfoDialogComponent,
        {
          disableClose: false,
          autoFocus: true,
          data: new ProductInfoDialogData(
            size,
            tab,
            revisionItem,
            tag,
            quantity
          ),
        }
      );

      const subscription = dialogRef.beforeClose.subscribe(
        (results) => {
          resolve(results);
        },
        null,
        () => {
          subscription.unsubscribe();
        }
      );
    });

    return promise;
  }
}
