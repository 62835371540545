import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EcapsCoreModule } from '../ecaps-core/ecaps-core.module';
import { LayoutService } from './services/layout.service';
import { LayoutConfigAccordionComponent } from './components/layout-config-accordion/layout-config-accordion.component';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CompareDialogComponent } from './dialogs/compare-dialog/compare-dialog.component';
import { ErvCompareDialogComponent } from './dialogs/erv-compare-dialog/erv-compare-dialog.component';
import { ExhaustVolumeDialogComponent } from './dialogs/exhaust-volume-dialog/exhaust-volume-dialog.component';
import { ChartingModule } from '../products/charting/charting.module';
import { ReactiveFormsModule } from '@angular/forms';
import { Selections } from './controllers/selections.controller';
import { ItemReselectionDialog } from './controllers/item-reselection-dialog.controller';
import { FumeExhaustSvgComponent } from './components/fume-exhaust-svg/fume-exhaust-svg.component';
import { SelectionSvgComponent } from './components/selection-svg/selection-svg.component';
import { DoasSvgComponent } from './components/doas-svg/doas-svg.component';
import { FumeExhaustLabSvgComponent } from './components/fume-exhaust-lab-svg/fume-exhaust-lab-svg.component';
import { SideDialogModule } from '../side-dialog/side-dialog.module';
import { RoofExhaustGridComponent } from './grids/fans/roof-exhaust-grid/roof-exhaust-grid.component';
import { BaseGridComponent } from './grids/base-grid/base-grid.component';
import { CustomizeGridDialogComponent } from './dialogs/customize-grid-dialog/customize-grid-dialog.component';
import { SidewallMountedGridComponent } from './grids/fans/sidewall-mounted-grid/sidewall-mounted-grid.component';
import { CeilingCabinetGridComponent } from './grids/fans/ceiling-cabinet-grid/ceiling-cabinet-grid.component';
import { InlineFansGridComponent } from './grids/fans/inline-fans-grid/inline-fans-grid.component';
import { VaneAxialGridComponent } from './grids/fans/vane-axial-grid/vane-axial-grid.component';
import { GravityGridComponent } from './grids/fans/gravity-grid/gravity-grid.component';
import { PlugPlenumGridComponent } from './grids/fans/plug-plenum-grid/plug-plenum-grid.component';
import { StationaryGridComponent } from './grids/louvers/stationary-grid/stationary-grid.component';
// tslint:disable-next-line: max-line-length
import { WaterRejectionRatingInfoDialogComponent } from './dialogs/water-rejection-rating-info-dialog/water-rejection-rating-info-dialog.component';
import { CompareSnackBarComponent } from './components/compare-snack-bar/compare-snack-bar.component';
import { DoasRvGridComponent } from './grids/doas/doas-rv-grid/doas-rv-grid.component';
import { DoasRveGridComponent } from './grids/doas/doas-rve-grid/doas-rve-grid.component';
import { FumeExhaustGridComponent } from './grids/fans/fume-exhaust-grid/fume-exhaust-grid.component';
import { DieselGeneratorGridComponent } from './grids/fans/fume-exhaust-grid/diesel-generator-grid.component';
import { TradeOffAdvisorDialog } from './controllers/trade-off-advisor-dialog.controller';
import { CirculatorGridComponent } from './grids/fans/circulator-grid/circulator-grid.component';
import { CirculatorDDFGridComponent } from './grids/fans/circulator-grid/circulator-ddf-grid.component';
import { DsSvgComponent } from './components/ds-svg/ds-svg.component';
import { DsPerformanceSvgComponent } from './components/ds-performance-svg/ds-performance-svg.component';
import { MuaGridComponent } from './grids/mua/mua-grid/mua-grid.component';
import { MuaNoneGridComponent } from './grids/mua/mua-none-grid/mua-none-grid.component';
import { BlowersGridComponent } from './grids/fans/blowers-grid/blowers-grid.component';
import { AnalyticsModule } from '../analytics/analytics.module';
import { DsFanSpacingComponent } from './components/ds-fan-spacing/ds-fan-spacing.component';
import { ErvGridComponent } from './grids/preconditioners/erv-grid/erv-grid.component';
import {
  SvgViewerComponent,
  SvgOptionComponent,
} from './components/svg-viewer/svg-viewer.component';
import { ErvSpreadsheetDialogComponent } from './dialogs/erv-spreadsheet-dialog/erv-spreadsheet-dialog.component';
import { ErvLowLeakageDialogComponent } from './dialogs/erv-low-leakage-dialog/erv-low-leakage-dialog.component';
import { ErvFrostControlDialogComponent } from './dialogs/erv-frost-control-dialog/erv-frost-control-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    EcapsCoreModule,
    MatTableModule,
    ChartingModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatButtonModule,
    MatSortModule,
    MatTooltipModule,
    SideDialogModule,
    MatTabsModule,
    MatListModule,
    MatSnackBarModule,
    AnalyticsModule,
    DragDropModule,
  ],
  providers: [
    LayoutService,
    Selections,
    ItemReselectionDialog,
    TradeOffAdvisorDialog,
  ],
  declarations: [
    LayoutConfigAccordionComponent,
    CompareDialogComponent,
    ErvCompareDialogComponent,
    ExhaustVolumeDialogComponent,
    FumeExhaustSvgComponent,
    SelectionSvgComponent,
    DoasSvgComponent,
    FumeExhaustLabSvgComponent,
    RoofExhaustGridComponent,
    BaseGridComponent,
    CustomizeGridDialogComponent,
    SidewallMountedGridComponent,
    CeilingCabinetGridComponent,
    BlowersGridComponent,
    InlineFansGridComponent,
    VaneAxialGridComponent,
    GravityGridComponent,
    PlugPlenumGridComponent,
    StationaryGridComponent,
    WaterRejectionRatingInfoDialogComponent,
    FumeExhaustGridComponent,
    DieselGeneratorGridComponent,
    DoasRvGridComponent,
    DoasRveGridComponent,
    CompareSnackBarComponent,
    CirculatorGridComponent,
    CirculatorDDFGridComponent,
    DsSvgComponent,
    DsPerformanceSvgComponent,
    MuaGridComponent,
    MuaNoneGridComponent,
    BlowersGridComponent,
    DsFanSpacingComponent,
    ErvGridComponent,
    SvgViewerComponent,
    SvgOptionComponent,
    ErvSpreadsheetDialogComponent,
    ErvLowLeakageDialogComponent,
    ErvFrostControlDialogComponent,
  ],
  exports: [
    LayoutConfigAccordionComponent,
    FumeExhaustSvgComponent,
    SelectionSvgComponent,
    DoasSvgComponent,
    FumeExhaustLabSvgComponent,
    RoofExhaustGridComponent,
    SidewallMountedGridComponent,
    CeilingCabinetGridComponent,
    BlowersGridComponent,
    InlineFansGridComponent,
    VaneAxialGridComponent,
    GravityGridComponent,
    PlugPlenumGridComponent,
    StationaryGridComponent,
    FumeExhaustGridComponent,
    DieselGeneratorGridComponent,
    DoasRvGridComponent,
    DoasRveGridComponent,
    CirculatorGridComponent,
    CirculatorDDFGridComponent,
    DsSvgComponent,
    DsPerformanceSvgComponent,
    DsFanSpacingComponent,
    MuaGridComponent,
    MuaNoneGridComponent,
    ErvGridComponent,
    SvgViewerComponent,
    SvgOptionComponent,
    ErvCompareDialogComponent,
    ErvLowLeakageDialogComponent,
    ErvFrostControlDialogComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SelectionsModule {}
