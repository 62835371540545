import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideDialogDirective } from './directives/side-dialog.directive';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SideDialogService } from './services/side-dialog.service';
import { SideDialogTitleComponent } from './components/side-dialog-title/side-dialog-title.component';
import { SideDialogContentComponent } from './components/side-dialog-content/side-dialog-content.component';
import { SideDialogActionsComponent } from './components/side-dialog-actions/side-dialog-actions.component';

@NgModule({
  imports: [CommonModule, MatSidenavModule, MatToolbarModule],
  declarations: [
    SideDialogDirective,
    SideDialogTitleComponent,
    SideDialogContentComponent,
    SideDialogActionsComponent
  ],
  providers: [SideDialogService],
  exports: [
    SideDialogDirective,
    SideDialogTitleComponent,
    SideDialogContentComponent,
    SideDialogActionsComponent
  ]
})
export class SideDialogModule {}
