import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidSize } from '../../models/selection-results/valid-size.model';

@Component({
  selector: 'app-exhaust-volume-dialog',
  templateUrl: './exhaust-volume-dialog.component.html',
  styleUrls: ['./exhaust-volume-dialog.component.scss'],
})
export class ExhaustVolumeDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;

  exhaustVolume: UntypedFormControl;

  min: number;

  max: number;

  validate = false;

  constructor(
    private dialogRef: MatDialogRef<ExhaustVolumeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private size: ValidSize
  ) {
    const exhaustVolume =
      this.size.selectionLayoutConfig.getQuestion('ExhaustVolumeInput');

    this.exhaustVolume = new UntypedFormControl(exhaustVolume.value);

    const validators = [Validators.required];

    const outdoorAirVolume =
      this.size.selectionLayoutConfig.getQuestion('OutdoorAirVolume');

    if (!!outdoorAirVolume) {
      this.max = parseFloat(outdoorAirVolume.value);

      validators.push(Validators.max(this.max));
    }

    if (!!exhaustVolume.validationFeatures) {
      if (!!exhaustVolume.validationFeatures['min']) {
        this.min = parseFloat(exhaustVolume.validationFeatures['min']);

        validators.push(Validators.min(this.min));
      }

      if (!outdoorAirVolume && !!exhaustVolume.validationFeatures['max']) {
        this.max = parseFloat(exhaustVolume.validationFeatures['max']);

        validators.push(Validators.max(this.max));
      }
    }

    this.exhaustVolume.setValidators(validators);

    this.formGroup = new UntypedFormGroup({
      exhaustVolume: this.exhaustVolume,
    });
  }

  ngOnInit() {}

  okClick() {
    this.validate = true;

    if (this.exhaustVolume.valid) {
      this.dialogRef.close(this.exhaustVolume.value);
    }
  }
}
