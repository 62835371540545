export class SizeStability {
    stabilityMessages: Array<string>;
    stable: boolean;
    constructor(stabilityData: any) {
        this.stabilityMessages = new Array<string>();
        if (stabilityData && stabilityData.stabilityMessages && stabilityData.stabilityMessages.length > 0) {
            for (let i = 0; i < stabilityData.stabilityMessages.length; i++) {
                this.stabilityMessages.push(stabilityData.stabilityMessages[i]);
            }
        }
        this.stable = stabilityData.stable;
    }
}
