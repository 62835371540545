import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SideDialogComponent } from '../../../side-dialog/classes/side-dialog-component.interface';
import { DialogOptions } from './models/dialog-options.model';
import { IGridColumn } from '../../grids/models/grid-column.interface';
import {
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

interface ISelectionColumn extends IGridColumn {
  selected: boolean;
}

interface IColumnOrderItem {
  key: string;
  text: string;
}

@Component({
  selector: 'customize-grid-dialog',
  templateUrl: './customize-grid-dialog.component.html',
  styleUrls: ['./customize-grid-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomizeGridDialogComponent
  implements OnInit, SideDialogComponent
{
  @ViewChild('visibleColumnsList') visibleColumnsList: MatSelectionList;

  dialogData: DialogOptions;

  columnList: ISelectionColumn[] = [];
  columnOrder: IColumnOrderItem[] = [];

  constructor(private sideDialogService: SideDialogService) {}

  ngOnInit() {
    this.columnOrder = this.dialogData.visibleColumns
      .filter((key) => !['rank', 'compare', 'name'].includes(key))
      .map((key) => {
        return {
          key,
          text:
            this.dialogData.columnList.find((item) => item.key === key)?.text ||
            '',
        } as IColumnOrderItem;
      });

    this.columnList = [
      ...this.dialogData.columnList
        .sort((a: IGridColumn, b: IGridColumn) => a.text.localeCompare(b.text))
        .map((item) => {
          return {
            ...item,
            selected: this.dialogData.visibleColumns.indexOf(item.key) > -1,
          } as ISelectionColumn;
        }),
    ];
  }

  onSelectionChange(event: MatSelectionListChange) {
    const newOrder = this.visibleColumnsList.selectedOptions.selected.map(
      (item) => {
        return {
          key: (item.value as ISelectionColumn).key,
          text: (item.value as ISelectionColumn).text,
        } as IColumnOrderItem;
      }
    );

    this.matchSortOrder(newOrder, this.columnOrder, 'key');

    this.columnOrder = newOrder;
  }

  private matchSortOrder<T>(array: T[], order: T[], key?: string) {
    array.sort((a, b) => {
      const aIndex = order.findIndex(
        (element) => (!!key ? element[key] : element) === (!!key ? a[key] : a)
      );
      const bIndex = order.findIndex(
        (element) => (!!key ? element[key] : element) === (!!key ? b[key] : b)
      );

      if (aIndex === -1 && bIndex === -1) {
        return 0;
      } else if (aIndex === -1) {
        return 1;
      } else if (bIndex === -1) {
        return -1;
      } else {
        return aIndex - bIndex;
      }
    });
  }

  columnDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columnOrder, event.previousIndex, event.currentIndex);
  }

  okClick() {
    this.sideDialogService.close(this.columnOrder.map((item) => item.key));
  }

  cancelClick() {
    this.sideDialogService.close();
  }

  resetClick() {
    this.visibleColumnsList.selectedOptions.setSelection(
      ...this.visibleColumnsList.options.filter((item) =>
        this.dialogData.visibleColumnsReset.includes(
          (item.value as ISelectionColumn).key
        )
      )
    );

    const newOrder = this.columnList
      .filter((column) =>
        this.dialogData.visibleColumnsReset.includes(column.key)
      )
      .map((column) => {
        return {
          key: column.key,
          text: column.text,
        } as IColumnOrderItem;
      });

    this.matchSortOrder(
      newOrder,
      this.dialogData.visibleColumnsReset.map((key) => {
        return {
          key,
          text:
            this.columnList.find((column) => column.key === key)?.text || '',
        } as IColumnOrderItem;
      }),
      'key'
    );

    this.columnOrder = newOrder;
  }
}
