import { Question } from './question.model';

export class ValidationMetaData {
    questions: Array<Question>;

    constructor(data: any) {

        this.questions = new Array<Question>();

        if (!!data && !!data.questions) {
            for (let i = 0; i < data.questions.length; i++) {
                this.questions.push(new Question(data.questions[i]));
            }
        }

    }
}
