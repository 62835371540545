import {
  Component,
  OnInit,
  Input,
  AfterContentInit,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { VentabulaChartComponent } from '../ventabula-chart/ventabula-chart.component';
import { ValidSize } from '../../../../selections/models/selection-results/valid-size.model';
import { LayoutService } from '../../../../selections/services/layout.service';
import { EcapsCore } from '../../../../ecaps-core/controllers/ecaps-core.controller';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ChartTypes } from '../../enums/chart-types.enum';

@Component({
  selector: 'ventabula-valid-size-chart',
  templateUrl: './ventabula-valid-size-chart.component.html',
  styleUrls: ['./ventabula-valid-size-chart.component.scss'],
})
export class VentabulaValidSizeChartComponent
  implements OnInit, OnChanges, AfterContentInit
{
  @Input('size') private size: ValidSize;
  @Input('chartSpacing') chartSpacing: number;
  @Input('showElevation') showElevation = false;
  @Input('default-type') private defaultType = ChartTypes.operating;

  @ViewChild('chart', { static: true }) private chart: VentabulaChartComponent;

  chartTypes = [
    {
      value: 'operating',
      text: 'Operating Conditions',
    },
    {
      value: 'minMax',
      text: 'Min/Max Speed',
    },
    {
      value: 'series',
      text: 'RPM Family',
    },
  ];

  availableCharts: Array<{
    value: string;
    text: string;
  }>;

  private defaultTypeSet = false;

  formGroup: UntypedFormGroup;
  chartType: UntypedFormControl;

  constructor(private core: EcapsCore, private layout: LayoutService) {
    this.chartType = new UntypedFormControl('operating');

    this.formGroup = new UntypedFormGroup({
      chartType: this.chartType,
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    if (!!this.size && !!this.size.charts) {
      this.renderChart();
    }
  }

  ngAfterContentInit() {
    if (!this.size.charts) {
      setTimeout(() => {
        if (!!this.size.charts) {
          this.renderChart();
        } else {
          let loading = true;

          this.core.showLoadingGraphic('Loading...', function () {
            return loading;
          });

          this.layout.getValidSizeCharts(this.size).then(
            () => {
              this.renderChart();

              loading = false;

              this.core.hideLoadingGraphic();
            },
            () => {
              this.chart.showChartUnavailable();

              loading = false;

              this.core.hideLoadingGraphic();
            }
          );
        }
      });
    } else {
      this.renderChart();
    }
  }

  renderChart() {
    const chartTypeNames = Object.keys(this.size.charts);

    this.availableCharts = this.chartTypes.filter(
      (typeObject) => chartTypeNames.indexOf(typeObject.value) > -1
    );

    if (!this.defaultTypeSet) {
      this.chartType.setValue(this.defaultType || ChartTypes.operating);

      this.defaultTypeSet = true;
    }

    const chartData = this.size.charts[this.chartType.value];

    this.chart.renderChart(chartData);
  }
}
