<div class="selection-grid__container">
  <div class="customize-header" *ngIf="showCustomize === true">
    <button mat-icon-button matTooltip="Customize Grid" (click)="customize()">
      <mat-icon>settings</mat-icon>
    </button>
  </div>

  <div class="selection-grid-parent">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortKey"
      [matSortDirection]="sortDir"
    >
      <ng-container matColumnDef="rank">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          matTooltip="Greenheck’s best estimate of customer value ranking, based on price & performance."
        >
          Rank
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.rank | number : '1.0-0' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="rank_filter">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.top.px]="headerRowHeight()"
        ></th>
      </ng-container>

      <ng-container matColumnDef="compare">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            (change)="compareSelect($event, element.sizeData)"
            [checked]="isSelected(element.sizeData)"
            matTooltip="Click to compare"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="compare_filter">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.top.px]="headerRowHeight()"
        ></th>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ modelNameHeader }}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="product-name">
            <button
              mat-button
              class="product-name-button"
              color="primary"
              (click)="showProductInfo(element.sizeData)"
            >
              {{ element.name }}
            </button>

            <button
              *ngIf="!element.stable"
              mat-icon-button
              class="warn-button"
              matTooltip="Caution: Click icon for more information."
              (click)="showSizeWarning(element.sizeData)"
            >
              <mat-icon>warning</mat-icon>
            </button>

            <ng-container [ngSwitch]="isRevitPlugin">
              <button
                *ngSwitchCase="false"
                class="add-button"
                mat-icon-button
                color="primary"
                matTooltip="Add to job"
                (click)="showProductInfo(element.sizeData, 'customize')"
              >
                <mat-icon color="primary">add_circle</mat-icon>
              </button>

              <button
                *ngSwitchCase="true"
                class="add-button"
                mat-icon-button
                color="primary"
                matTooltip="Select Revit"
                (click)="showProductInfo(element.sizeData, 'revit')"
              >
                <mat-icon color="primary" svgIcon="revit"></mat-icon>
              </button>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name_filter">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.top.px]="headerRowHeight()"
        >
          <form [formGroup]="filterForm">
            <mat-form-field>
              <input
                matInput
                type="text"
                autocomplete="off"
                #filterField
                formControlName="name"
                (keyup)="filterColumn('name', 'CONTAINS', filterField)"
              />
              <mat-icon matPrefix>filter_list</mat-icon>
            </mat-form-field>
          </form>
        </th>
      </ng-container>

      <ng-container matColumnDef="ervOption">
        <th mat-header-cell *matHeaderCellDef>ERV Option</th>
        <td mat-cell *matCellDef="let element">
          <div
            [matTooltip]="
              enableERVCompare(element.sizeData)
                ? 'You have picked an option that is not offered on our Energy Recovery units.'
                : null
            "
          >
            <button
              mat-raised-button
              color="primary"
              [disabled]="enableERVCompare(element.sizeData)"
              (click)="ervCompare(element.sizeData)"
            >
              Compare
            </button>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="ervOption_filter">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.top.px]="headerRowHeight()"
        ></th>
      </ng-container>

      <ng-container matColumnDef="rainResistanceRanking">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>Water Rejection Rating</span>
          <button
            mat-icon-button
            matTooltip="Help"
            (stopPropClick)="
              helpIconClick('*_SelectionGrid_RainResistanceRanking')
            "
          >
            <mat-icon>help_outline</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="rain-resistance-ranking-text">
            <span>{{ element.rainResistanceRanking }}</span>

            <mat-icon
              [class.green]="element.rainResistanceRanking >= 7"
              [class.yellow]="
                element.rainResistanceRanking >= 4 &&
                element.rainResistanceRanking < 7
              "
              [class.red]="element.rainResistanceRanking < 4"
              svgIcon="droplet"
            ></mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="rainResistanceRanking_filter">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.top.px]="headerRowHeight()"
        >
          <form [formGroup]="filterForm">
            <mat-form-field>
              <input
                matInput
                type="text"
                autocomplete="off"
                #filterField
                formControlName="rainResistanceRanking"
                (keyup)="
                  filterColumn(
                    'rainResistanceRanking',
                    'GREATER_THAN',
                    filterField
                  )
                "
              />
              <mat-icon matPrefix>filter_list</mat-icon>
            </mat-form-field>
          </form>
        </th>
      </ng-container>

      <ng-container matColumnDef="amca">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span>AMCA</span>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [matTooltip]="
            getAMCAText(element.sizeData, element.driveLossPercentage)
          "
        >
          <ng-container [ngSwitch]="element.amca.toUpperCase().trim()">
            <ng-container *ngSwitchCase="'A'">
              <mat-icon svgIcon="fan" color="primary"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'AF'">
              <mat-icon svgIcon="fan" color="primary"></mat-icon>
              <mat-icon color="primary">power</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'C'">
              <mat-icon svgIcon="mfan" color="primary"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'I'">
              <mat-icon svgIcon="fan" color="primary"></mat-icon>
              <mat-icon color="primary">volume_up</mat-icon>
              <mat-icon svgIcon="induction" color="primary"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'IF'">
              <mat-icon svgIcon="fan" color="primary"></mat-icon>
              <mat-icon color="primary">volume_up</mat-icon>
              <mat-icon svgIcon="induction" color="primary"></mat-icon>
              <mat-icon color="primary">power</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'N'">
              <mat-icon svgIcon="dash" color="primary"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'S'">
              <mat-icon svgIcon="fan" color="primary"></mat-icon>
              <mat-icon color="primary">volume_up</mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'SF'">
              <mat-icon svgIcon="fan" color="primary"></mat-icon>
              <mat-icon color="primary">volume_up</mat-icon>
              <mat-icon color="primary">power</mat-icon>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="amca_filter">
        <th
          mat-header-cell
          *matHeaderCellDef
          [style.top.px]="headerRowHeight()"
        ></th>
      </ng-container>

      <ng-container *ngFor="let column of columnList">
        <ng-container
          *ngIf="column.render !== false"
          [matColumnDef]="column.key"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span [innerHtml]="column.text"></span>
            <ng-template [ngIf]="!!column.helpItem">
              <button
                mat-icon-button
                [matTooltip]="column.helpItem.toolTip || 'Help'"
                (stopPropClick)="helpIconClick(column)"
              >
                <ng-container
                  [ngSwitch]="
                    !!column.helpItem.icon
                      ? 1
                      : !!column.helpItem.svgIcon
                      ? 2
                      : 0
                  "
                >
                  <mat-icon
                    *ngSwitchCase="2"
                    [svgIcon]="column.helpItem.svgIcon"
                  ></mat-icon>
                  <mat-icon *ngSwitchCase="1">{{
                    column.helpItem.icon
                  }}</mat-icon>
                  <mat-icon *ngSwitchDefault>help_outline</mat-icon>
                </ng-container>
              </button>
            </ng-template>
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              column.valueFormat
                ? column.valueFormat(element[column.key])
                : element[column.key]
            }}
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let column of columnList">
        <ng-container
          *ngIf="column.render !== false"
          [matColumnDef]="column.key + '_filter'"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [style.top.px]="headerRowHeight()"
          >
            <form [formGroup]="filterForm">
              <mat-form-field>
                <input
                  matInput
                  type="text"
                  autocomplete="off"
                  #filterField
                  [formControlName]="column.key"
                  (keyup)="
                    filterColumn(column.key, column.filterType, filterField)
                  "
                />
                <mat-icon matPrefix>filter_list</mat-icon>
              </mat-form-field>
            </form>
          </th>
        </ng-container>
      </ng-container>

      <tr
        class="top-header-row"
        mat-header-row
        *matHeaderRowDef="visibleColumns; sticky: true"
      ></tr>

      <ng-container *ngIf="showFilter === true">
        <tr
          class="filter-header-row"
          mat-header-row
          *matHeaderRowDef="filterColumns; sticky: true"
        ></tr>
      </ng-container>

      <tr
        [class.reselection-row]="isReselectionRow(row.sizeData)"
        mat-row
        *matRowDef="let row; columns: visibleColumns"
      ></tr>
    </table>
  </div>

  <div class="footer" *ngIf="showFooter === true">
    {{ dataSource?.filteredData?.length | number : '1.0-0' }}
    {{ footerProductType }}
  </div>
</div>
