import { ValidSize } from '../../../models/selection-results/valid-size.model';

export class CompareConfig {

    items: Array<ValidSize>;

    constructor(items?: Array<ValidSize>) {

        this.items = items;

    }

}
