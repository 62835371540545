import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { EcapsStateService } from '../../ecaps-core/handlers/services/ecaps-state.service';
import { CamelCasePipe } from '../../ecaps-core/pipes/custom-pipes.pipe';
import { HttpService } from '../../ecaps-core/services/http.service';
import { IRevitState } from '../../external-communication/models/i-revit-state.inteface';
import { RevitStateLevels } from '../../external-communication/models/revit-state-levels.enum';
import { ChartingService } from '../../products/charting/services/charting.service';
import { BusinessUnits } from '../../products/enums/business-units.enum';
import { ProductEntities } from '../../products/enums/product-entities.enum';
import { ProductTypes } from '../../products/enums/product-types.enum';
import { CatalogService } from '../../products/services/catalog.service';
import { DescriptionService } from '../../products/services/description.service';
import { DrawingService } from '../../products/services/drawing.service';
import {
  ProductCategory,
  ProductGroup,
  ProductsService,
} from '../../products/services/products.service';
import { Item } from '../../projects/models/project-item.model';
import {
  Group,
  Group as LayoutConfigGroup,
} from '../models/layout-config/group.model';
import { LayoutConfiguration } from '../models/layout-config/layout-configuration.model';
import { LayoutUpdate } from '../models/layout-update/layout-update.model';
import { Property as LayoutUpdateProperty } from '../models/layout-update/property.model';
import { SelectionResults } from '../models/selection-results/selection-results.model';
import { ValidSizeInflateOptions } from '../models/selection-results/valid-size-inflate-options.model';
import { ValidSize } from '../models/selection-results/valid-size.model';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private LS_LAST_LAYOUT_KEY = 'lastLayoutKey';

  private lastLayoutConfig: LayoutConfiguration;

  public lastLayoutConfigUpdated = new EventEmitter<LayoutConfiguration>();

  private lastSelectionResults: SelectionResults;

  private reselectionSize: ValidSize;

  private reselectionItem: Item;

  constructor(
    private http: HttpService,
    private charting: ChartingService,
    private catalog: CatalogService,
    private drawing: DrawingService,
    private descriptions: DescriptionService,
    private products: ProductsService,
    private ecapsState: EcapsStateService,
    private router: Router
  ) {
    this.ecapsState.layoutService = this;

    this.restoreLastCreatedLayoutConfig().catch(() => {
      this.lastLayoutConfig = null;
    });
  }

  public getLastSelectionResults(): SelectionResults {
    return this.lastSelectionResults;
  }

  private applyLayoutUpdate(
    layoutGroup: LayoutConfigGroup,
    layoutUpdate: LayoutUpdate
  ) {
    const applyObjectProperties = function (
      data: any,
      properties: Array<LayoutUpdateProperty>
    ) {
      if (data && properties && properties.length > 0) {
        for (let i = 0; i < properties.length; i++) {
          if (properties[i].name.indexOf('.') > 0) {
            const parentKey = properties[i].name.substr(
              0,
              properties[i].name.indexOf('.')
            );
            const childKey = properties[i].name.substr(
              properties[i].name.indexOf('.') + 1
            );

            for (const key of Object.keys(data)) {
              if (key.toLowerCase().trim() === parentKey.toLowerCase().trim()) {
                applyObjectProperties(data[key], [
                  {
                    name: childKey,

                    value: properties[i].value,
                  },
                ]);

                break;
              }
            }
          } else {
            for (const key of Object.keys(data)) {
              if (
                key.toLowerCase().trim() ===
                properties[i].name.toLowerCase().trim()
              ) {
                let value = <any>properties[i].value;

                if (value !== null) {
                  if (typeof value === 'string') {
                    if (value.toLowerCase().trim() === 'null') {
                      value = null;
                    } else if (value.toLowerCase().trim() === 'true') {
                      value = true;
                    } else if (value.toLowerCase().trim() === 'false') {
                      value = false;
                    } else if (
                      typeof value === 'string' &&
                      !isNaN(value as any) &&
                      typeof data[key] === 'number'
                    ) {
                      value = Number(value);
                    }
                  }
                }

                data[key] = value;
              }
            }
          }
        }
      }
    };

    for (let i = 0; i < layoutUpdate.groups.length; i++) {
      const updateGroup = layoutUpdate.groups[i];

      const group = layoutGroup.getGroup(updateGroup.name);

      if (group) {
        applyObjectProperties(group, updateGroup.properties);

        if (updateGroup.questions && updateGroup.questions.length) {
          for (let k = 0; k < updateGroup.questions.length; k++) {
            const updateQuestion = updateGroup.questions[k];
            const question = group.getQuestion(updateQuestion.name);

            if (question) {
              applyObjectProperties(question, updateQuestion.properties);

              if (updateQuestion.answers && updateQuestion.answers.length > 0) {
                for (let l = 0; l < updateQuestion.answers.length; l++) {
                  const updateAnswer = updateQuestion.answers[l];
                  const answer = question.getAnswer(updateAnswer.name);

                  if (answer) {
                    applyObjectProperties(answer, updateAnswer.properties);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  createLayoutConfiguration(
    entity: string,
    bu: string,
    answers: Object
  ): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((results, reject) => {
      let defaultAnswers = [];

      if (answers) {
        if (Array.isArray(answers) && answers.length > 0 && !!answers[0].name) {
          defaultAnswers = [...answers];
        } else {
          for (const key of Object.keys(answers)) {
            defaultAnswers.push({
              name: key,
              value: answers[key],
              restrict: true,
            });
          }
        }
      }

      firstValueFrom(
        this.http.post<any>('/configuration/layout', {
          name: entity,
          businessUnit: bu,
          defaults: defaultAnswers,
        })
      ).then(
        (layoutConfig) => {
          this.lastLayoutConfig = new LayoutConfiguration(
            layoutConfig,
            this,
            null,
            bu
          );

          this.updateProductType(this.lastLayoutConfig);

          window.localStorage.setItem(
            this.LS_LAST_LAYOUT_KEY,
            this.lastLayoutConfig.key
          );

          this.reselectionSize = null;
          this.reselectionItem = null;

          results(this.lastLayoutConfig);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  copyLayoutConfiguration(
    layoutConfig: LayoutConfiguration,
    bu: string
  ): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((results, reject) => {
      firstValueFrom(
        this.http.post('/configuration/layout', {
          entityKey: layoutConfig.key,
          businessUnit: bu,
          type: 'copy',
        })
      ).then(
        (resultData) => {
          const newLayoutConfig = new LayoutConfiguration(resultData, this);

          this.updateProductType(newLayoutConfig);

          results(newLayoutConfig);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  copyLayoutSelection(
    layoutConfig: LayoutConfiguration,
    selectionKey: string
  ): Promise<SelectionResults> {
    return new Promise<SelectionResults>((results, reject) => {
      const selectionType =
        layoutConfig.productType === ProductTypes.makeUpAir ||
        layoutConfig.productType === ProductTypes.preconditioners
          ? new CamelCasePipe().transform(ProductTypes.temperedAirProduct)
          : new CamelCasePipe().transform(layoutConfig.productType);

      const request = {
        type: 'copy',
      };

      request[selectionType + 'SelectionKey'] = selectionKey;

      firstValueFrom(
        this.http.post(`/selection/${selectionType}/`, request)
      ).then(
        (resultData) => {
          results(
            new SelectionResults(
              layoutConfig,
              layoutConfig.productType,
              resultData
            )
          );
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getLayoutConfiguration(layoutKey: string): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((results, reject) => {
      firstValueFrom(this.http.get(`/configuration/layout/${layoutKey}`)).then(
        (resultData) => {
          const layoutConfig = new LayoutConfiguration(resultData, this);

          layoutConfig.key = layoutKey;

          this.updateProductType(layoutConfig);

          results(layoutConfig);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getSelectionValidSize(
    layoutConfig: LayoutConfiguration,
    selectionKey: string,
    sizeID: string
  ): Promise<ValidSize> {
    return new Promise<ValidSize>((results, reject) => {
      const selectionType =
        layoutConfig.productType === ProductTypes.makeUpAir ||
        layoutConfig.productType === ProductTypes.preconditioners
          ? new CamelCasePipe().transform(ProductTypes.temperedAirProduct)
          : new CamelCasePipe().transform(layoutConfig.productType);

      firstValueFrom(
        this.http.get(
          `/selection/${selectionType}/${selectionKey}/validSizes/${sizeID}`
        )
      ).then(
        (resultData) => {
          const size = new ValidSize(
            layoutConfig,
            layoutConfig.productType,
            selectionKey,
            resultData
          );

          if (!size.businessUnit) {
            const group = this.products.productGroups.find(
              (groupItem) =>
                !!groupItem.children.find(
                  (category) =>
                    'type' in category &&
                    category.type === layoutConfig.productType
                )
            );

            if (!!group) {
              size.businessUnit = (
                group.children.find(
                  (category) =>
                    'type' in category &&
                    category.type === layoutConfig.productType
                ) as ProductCategory
              ).bu;
            }
          }

          results(size);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  private restoreLastCreatedLayoutConfig(): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((result, reject) => {
      if (this.lastLayoutConfig) {
        this.lastLayoutConfigUpdated.next(this.lastLayoutConfig);

        result(this.lastLayoutConfig);
      } else {
        const layoutKey = window.localStorage.getItem(this.LS_LAST_LAYOUT_KEY);

        if (layoutKey && layoutKey.trim() !== '') {
          this.getLayoutConfig(layoutKey).then(
            (layoutConfig) => {
              this.lastLayoutConfig = new LayoutConfiguration(
                layoutConfig,
                this
              );

              this.updateProductType(this.lastLayoutConfig);

              this.reselectionSize = null;
              this.reselectionItem = null;

              this.lastLayoutConfigUpdated.next(this.lastLayoutConfig);

              result(this.lastLayoutConfig);
            },
            (errorData) => {
              window.localStorage.removeItem(this.LS_LAST_LAYOUT_KEY);

              this.lastLayoutConfigUpdated.error(errorData);

              reject(errorData);
            }
          );
        } else {
          this.lastLayoutConfigUpdated.next(null);

          result(null);
        }
      }
    });
  }

  getLastLayoutConfig(): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((result, reject) => {
      if (!!this.lastLayoutConfig) {
        result(this.lastLayoutConfig);
      } else {
        this.restoreLastCreatedLayoutConfig().then(
          (layoutConfig) => {
            result(layoutConfig);
          },
          (errorData) => {
            reject(errorData);
          }
        );
      }
    });
  }

  setLastLayoutConfig(layoutConfig: LayoutConfiguration) {
    this.lastLayoutConfig = layoutConfig;

    this.lastLayoutConfigUpdated.next(this.lastLayoutConfig);
  }

  getReselectionSize(): ValidSize {
    return this.reselectionSize;
  }

  getReselectionItem(): Item {
    return this.reselectionItem;
  }

  reselectSize(size: ValidSize, projectItem?: Item): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      this.copyLayoutConfiguration(
        size.selectionLayoutConfig,
        size.businessUnit
      ).then(
        (newLayoutConfig) => {
          this.lastLayoutConfig = newLayoutConfig;

          this.lastLayoutConfigUpdated.next(this.lastLayoutConfig);

          this.reselectionSize = size;

          this.reselectionItem = projectItem;

          results(true);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getLayoutConfig(key: string): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((results, reject) => {
      firstValueFrom(this.http.get('/configuration/layout/' + key)).then(
        (layoutResults) => {
          const layoutConfig = new LayoutConfiguration(layoutResults, this);

          layoutConfig.key = key;

          this.updateProductType(layoutConfig);

          results(layoutConfig);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  updateLayoutConfig(
    layoutConfig: LayoutConfiguration,
    answers: { [key: string]: any } | { name: string; value: string }[],
    notifyUpdate: boolean = true
  ): Promise<LayoutUpdate> {
    return new Promise<LayoutUpdate>((results, reject) => {
      const questions = [];

      if (Array.isArray(answers)) {
        questions.push(...answers);
      } else {
        for (const key of Object.keys(answers)) {
          questions.push({
            name: key,
            value: answers[key],
          });
        }
      }

      firstValueFrom(
        this.http.post<LayoutUpdate>(
          `/configuration/layout/${layoutConfig.key}`,
          {
            questions: questions,
            type: 'questions',
          }
        )
      ).then(
        (resultData) => {
          this.applyLayoutUpdate(layoutConfig.group, resultData);

          if (notifyUpdate) {
            layoutConfig.updated.next(questions);
          }

          results(resultData);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  undoLayoutUpdate(
    layoutConfig: LayoutConfiguration,
    steps: number
  ): Promise<LayoutUpdate> {
    return new Promise<LayoutUpdate>((results, reject) => {
      firstValueFrom(
        this.http.post<LayoutUpdate>(
          `/configuration/layout/${layoutConfig.key}/states`,
          {
            steps: steps,
          }
        )
      ).then(
        (resultData) => {
          this.applyLayoutUpdate(layoutConfig.group, resultData);

          layoutConfig.updated.next(undefined);

          results(resultData);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  processCalculation(size: ValidSize): Promise<any> {
    return new Promise<LayoutUpdate>((results, reject) => {
      const fanCalculationProductTypes = [
        ProductTypes.fan,
      ];
      const tapCalculationProductTypes = [
        ProductTypes.temperedAirProduct,
        ProductTypes.makeUpAir,
        ProductTypes.preconditioners,
      ];

      const layoutConfig = size.layoutConfig;

      if (fanCalculationProductTypes.indexOf(layoutConfig.productType) !== -1) {
        firstValueFrom(
          this.http.post<any>(
            `/selection/fan/${size.selectionKey}/validSizes/${size.id}/feiCalculation`,
            {
              type: 'layoutConfiguration',

              layoutKey: layoutConfig.key,
            }
          )
        ).then(
          (resultData) => {
            if (!!resultData.key) {
              firstValueFrom(
                this.http.post<LayoutUpdate>(
                  `/configuration/layout/${layoutConfig.key}`,
                  {
                    type: 'fanSelectionFeiCalculation',

                    fanSelectionFeiCalculationKey: resultData.key,
                  }
                )
              ).then(
                (postResultData) => {
                  this.applyLayoutUpdate(layoutConfig.group, postResultData);

                  layoutConfig.updated.next(undefined);

                  results(postResultData);
                },
                (errorData) => {
                  reject(errorData);
                }
              );
            } else {
              reject('Invalid server response.');
            }
          },
          (errorData) => {
            reject(errorData);
          }
        );
      }
      else if (tapCalculationProductTypes.indexOf(layoutConfig.productType) !== -1) {
        const question = layoutConfig.getQuestion(
          'CallTemperedProductCalculation'
        );

        if (!!question && question.value.toUpperCase().trim() === 'YES') {
          firstValueFrom(
            this.http.post<any>(
              `/configuration/layout/${layoutConfig.key}/calculation/temperedAirProduct`,
              null
            )
          ).then(
            (resultData) => {
              if (!!resultData.key) {
                firstValueFrom(
                  this.http.post<LayoutUpdate>(
                    `/configuration/layout/${layoutConfig.key}`,
                    {
                      type: 'temperedAirProductCalculation',

                      temperedAirProductCalculationKey: resultData.key,
                    }
                  )
                ).then(
                  (postResultData) => {
                    this.applyLayoutUpdate(layoutConfig.group, postResultData);

                    layoutConfig.updated.next(undefined);

                    results(postResultData);
                  },
                  (errorData) => {
                    reject(errorData);
                  }
                );
              } else {
                reject('Invalid server response.');
              }
            },
            (errorData) => {
              reject(errorData);
            }
          );
        } else {
          results(undefined);
        }
      }
      else {
        results(undefined);
      }
    });
  }

  getLayoutSelection(
    layoutConfig: LayoutConfiguration,
    productType: ProductTypes,
    postData?: Object,
    integrationVersion?: number
  ): Promise<SelectionResults> {
    return new Promise<SelectionResults>((results, reject) => {
      let selectionType;

      if (
        productType === ProductTypes.makeUpAir ||
        productType === ProductTypes.preconditioners
      ) {
        selectionType = new CamelCasePipe().transform(
          ProductTypes.temperedAirProduct
        );
      } else {
        selectionType = new CamelCasePipe().transform(productType);
      }

      postData = !postData ? {} : JSON.parse(JSON.stringify(postData));

      postData['layoutKey'] = layoutConfig.key;

      if (!!integrationVersion) {
        postData['integrationVersion'] = integrationVersion;
      } else if (
        Object.values(ProductTypes).includes(productType) &&
        !!this.reselectionSize
      ) {
        integrationVersion = this.parseIntegrationVersion(this.reselectionSize);

        if (integrationVersion !== null) {
          postData['integrationVersion'] = integrationVersion;
        }
      }

      let serviceURL =
        '/configuration/layout/' +
        layoutConfig.key +
        '/selection/' +
        selectionType;

      if (productType === ProductTypes.fan) {
        serviceURL += '/complete';
      }

      firstValueFrom(this.http.post(serviceURL, postData)).then(
        (responseData) => {
          this.lastSelectionResults = new SelectionResults(
            layoutConfig,
            productType,
            responseData
          );
          this.lastSelectionResults.validSizes.forEach((size) => {
            size.amcaRatings = this.getAMCARating(size);

            if (
              size.amcaRatings !== size.outputs.amcaCertId &&
              size.amcaRatings !== size.features.amcaCertId
            ) {
              console.debug(
                `[LayoutService] getLayoutSelection(%o) Calculated AMCA seal [%s] for size '%s' does not contain '%s': %o`,
                {
                  layoutConfig,
                  productType,
                  postData,
                  integrationVersion,
                },
                size.amcaRatings,
                size.name,
                size.outputs.amcaCertId,
                size
              );
            }
          });

          results(this.lastSelectionResults);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  updateProductType(layoutConfig: LayoutConfiguration) {
    const category = this.getCategory(layoutConfig) as ProductCategory;

    layoutConfig.productType = !!category ? category.type : null;
  }

  getCategory(layoutConfig: LayoutConfiguration): ProductGroup {
    if (!layoutConfig.modelGroup) {
      this.updateModelGroup(layoutConfig);
    }

    if (layoutConfig.modelGroup === 'MakeUpAir') {
      return this.products.findCategory(
        layoutConfig.getQuestion('HeatingType').value
      );
    } else if (layoutConfig.modelGroup === 'Preconditioner') {
      return this.products.findCategory(
        layoutConfig.getQuestion('UnitMounting').value
      );
    } else {
      return this.products.findCategory(layoutConfig.modelGroup);
    }
  }

  updateModelGroup(layoutConfig: LayoutConfiguration) {
    const modelGroup = layoutConfig.getQuestion('ModelGroup');

    if (
      !modelGroup ||
      !modelGroup.value ||
      modelGroup.value.toLowerCase().trim() === 'energyrecovery' ||
      modelGroup.value.toLowerCase().trim() === 'temperedairproduct'
    ) {
      layoutConfig.modelGroup =
        layoutConfig.getQuestion('Model').value === 'RV' ? 'RV' : 'RVE';
    } else {
      layoutConfig.modelGroup =
        !!modelGroup && !!modelGroup.value ? modelGroup.value : 'NA';
    }
  }

  getValidSizeLayoutConfig(
    size: ValidSize,
    sizeConfigKey?: string
  ): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((results, reject) => {
      if (!size.layoutConfig) {
        if (!!sizeConfigKey) {
          firstValueFrom(
            this.http.get(`/configuration/layout/${sizeConfigKey}`)
          ).then(
            (resultData) => {
              size.layoutConfig = new LayoutConfiguration(
                resultData,
                this,
                size.productType
              );

              size.layoutConfig.key = sizeConfigKey;

              results(size.layoutConfig);
            },
            (errorData) => {
              reject(errorData);
            }
          );
        } else {
          const selectionType =
            (size.productType === ProductTypes.makeUpAir ||
            size.productType === ProductTypes.preconditioners
              ? new CamelCasePipe().transform(ProductTypes.temperedAirProduct)
              : new CamelCasePipe().transform(size.productType)) + 'Selection';

          firstValueFrom(
            this.http.post('/configuration/layout', {
              type: selectionType,
              selection: size.selectionKey,
              size: size.id,
              defaults: [
                {
                  name: 'AMCA_CertificationID',
                  value: size.amcaRatings,
                  restrict: true,
                },
              ],
            })
          ).then(
            (resultData) => {
              size.layoutConfig = new LayoutConfiguration(
                resultData,
                this,
                size.productType
              );

              if (!!this.reselectionSize) {
                const visibleQuestions = (
                  group: Group,
                  list: { key: string; value: any }[]
                ) => {
                  if (!!group.groups) {
                    group.groups
                      .filter((subGroup) => subGroup.visible)
                      .forEach((subGroup) => {
                        visibleQuestions(subGroup, list);
                      });
                  }

                  if (!!group.questions) {
                    list.push(
                      ...group.questions
                        .filter(
                          (question) => question.visible && question.enabled
                        )
                        .map((question) => ({
                          key: question.name,
                          value: question.value,
                        }))
                    );
                  }
                };

                const reselectionQuestions: {
                  key: string;
                  value: any;
                }[] = [];
                const newQuestions: { key: string; value: any }[] = [];

                visibleQuestions(
                  this.reselectionSize.layoutConfig.group,
                  reselectionQuestions
                );
                visibleQuestions(size.layoutConfig.group, newQuestions);

                const questionUpdates: { [key: string]: any } = {};

                reselectionQuestions
                  .filter((item) => {
                    const newQuestion = newQuestions.find(
                      (newItem) => newItem.key === item.key
                    );

                    if (!!newQuestion && newQuestion.value !== item.value) {
                      const questionItem = size.layoutConfig.getQuestion(
                        newQuestion.key
                      );

                      if (
                        !!questionItem.answers &&
                        questionItem.answers.length > 0
                      ) {
                        return !!questionItem.answers
                          .filter((answer) => answer.visible)
                          .find((answer) => answer.name === item.value);
                      } else {
                        return true;
                      }
                    } else {
                      return false;
                    }

                    return !!newQuestion && newQuestion.value !== item.value;
                  })
                  .forEach((item) => {
                    questionUpdates[item.key] = item.value;
                  });

                if (Object.keys(questionUpdates).length > 0) {
                  this.updateLayoutConfig(
                    size.layoutConfig,
                    questionUpdates
                  ).then(() => {
                    results(size.layoutConfig);
                  });
                } else {
                  results(size.layoutConfig);
                }
              } else {
                results(size.layoutConfig);
              }
            },
            (errorData) => {
              reject(errorData);
            }
          );
        }
      } else {
        results(size.layoutConfig);
      }
    });
  }

  getValidSizeDimensions(
    size: ValidSize,
    sizeConfigKey?: string,
    forceUpdate: boolean = false
  ): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      if ((!size.dimensions && !size.files) || forceUpdate) {
        const self = this;

        const updateDimensions = function () {
          self.drawing.getSizeDimensions(size.layoutConfig.key).then(
            (resultData) => {
              size.dimensions = resultData.dimensions;
              size.files = resultData.files;

              results(true);
            },
            (errorData) => {
              reject(errorData);
            }
          );
        };

        if (!size.layoutConfig) {
          this.getValidSizeLayoutConfig(size, sizeConfigKey).then(
            (resultData) => {
              updateDimensions();
            },
            (errorData) => {
              reject(errorData);
            }
          );
        } else {
          updateDimensions();
        }
      } else {
        results(true);
      }
    });
  }

  getValidSizeAmcaDescriptions(size: ValidSize): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      if (
        !!size.amcaRatings &&
        size.amcaRatings !== '' &&
        size.amcaRatings !== 'N'
      ) {
        this.getValidSizeLayoutConfig(size).then(() => {
          this.descriptions
            .getDescription(
              size.layoutConfig.key,
              size.productType === ProductTypes.louver
                ? 'AMCA_Statement_Cert'
                : 'AMCA_Statement'
            )
            .then((resultData) => {
              size.amcaDescription = resultData;

              results(true);
            }, reject);
        }, reject);
      } else {
        results(true);
      }
    });
  }

  getValidSizeCharts(size: ValidSize): Promise<any> {
    return new Promise<any>((results, reject) => {
      if (!size.charts) {
        this.charting
          .getUnitCharts(size.productType, size.selectionKey, size.id)
          .then(
            (chartResults) => {
              size.charts = chartResults;

              results(size.charts);
            },
            (errorData) => {
              reject(errorData);
            }
          );
      } else {
        results(size.charts);
      }
    });
  }

  getValidSizeCatalogUpdate(size: ValidSize): Promise<boolean> {
    return new Promise<boolean>((results, reject) => {
      if (!size.description) {
        this.catalog.getModelDescription(size).then(
          (resultData) => {
            size.description = new Array<string>();

            if (!!resultData) {
              if (resultData.description && resultData.description.blocks) {
                for (let i = 0; i < resultData.description.blocks.length; i++) {
                  size.description.push(resultData.description.blocks[i].text);
                }
              }

              size.specifications = new Array<string>();

              if (
                resultData.specifications &&
                resultData.specifications.blocks
              ) {
                for (
                  let i = 0;
                  i < resultData.specifications.blocks.length;
                  i++
                ) {
                  size.specifications.push(
                    resultData.specifications.blocks[i].text
                  );
                }
              }

              size.features = !size.features ? size.features : {};

              if (resultData.features && resultData.features.length > 0) {
                for (let i = 0; i < resultData.features.length; i++) {
                  size.features[
                    new CamelCasePipe().transform(
                      resultData.features[i]['name']
                    )
                  ] = resultData.features[i]['value'];
                }
              }
            }

            if (size.productType !== ProductTypes.gravity) {
              this.getValidSizeAmcaDescriptions(size).then(() => {
                results(true);
              });
            } else {
              results(true);
            }
          },
          (errorData) => {
            reject(errorData);
          }
        );
      } else {
        results(true);
      }
    });
  }

  getValidSizeDrawingNotes(size: ValidSize): Promise<void> {
    return new Promise<void>(async (results) => {
      if (
        [
          ProductTypes.temperedAirProduct,
          ProductTypes.louver,
          ProductTypes.makeUpAir,
          ProductTypes.preconditioners,
        ].indexOf(size.productType) === -1
      ) {
        const notes = await this.descriptions.getDescription(
          size.layoutConfig.key,
          'eCapsDrawingNotes'
        );

        size.drawingNotes = notes;
      }

      results();
    });
  }

  async inflateValidSize(
    size: ValidSize,
    sizeConfigKey?: string,
    options?: ValidSizeInflateOptions
  ): Promise<boolean> {
    size.amcaRatings = this.getAMCARating(size);

    await this.getValidSizeLayoutConfig(size, sizeConfigKey);

    const tasks: Array<Promise<any>> = [];

    tasks.push(this.getValidSizeDimensions(size));

    tasks.push(this.getValidSizeDrawingNotes(size));

    if (!options || (!!options && options.charts !== false)) {
      tasks.push(this.getValidSizeCharts(size));
    }

    tasks.push(this.getValidSizeCatalogUpdate(size));

    return Promise.all(tasks).then(() => true);
  }

  canInflateValidSize(size: ValidSize): boolean {
    if (
      !size.layoutConfig ||
      !size.dimensions ||
      !size.files ||
      !size.amcaRatings ||
      size.amcaRatings === '' ||
      !size.charts ||
      !size.description
    ) {
      return true;
    } else {
      return false;
    }
  }

  getTAPCompare(
    size: ValidSize,
    exhaustVolume: number
  ): Promise<SelectionResults> {
    return new Promise<SelectionResults>((results, reject) => {
      const self = this;

      this.copyLayoutConfiguration(
        size.selectionLayoutConfig,
        size.businessUnit
      ).then(
        (newLayoutConfig) => {
          const updateModel = function () {
            self
              .updateLayoutConfig(newLayoutConfig, {
                HasEnergyRecovery: null,
              })
              .then(
                () => {
                  self
                    .updateLayoutConfig(newLayoutConfig, {
                      HasEnergyRecovery: 'Yes',
                    })
                    .then(
                      () => {
                        const category = self.products.findCategory(
                          'RVE'
                        ) as ProductCategory;

                        self
                          .getLayoutSelection(
                            newLayoutConfig,
                            category.type,
                            category.selectionRequestData
                          )
                          .then(
                            (selectionResults) => {
                              results(selectionResults);
                            },
                            (errorData) => {
                              reject(errorData);
                            }
                          );
                      },
                      (errorData) => {
                        reject(errorData);
                      }
                    );
                },
                (errorData) => {
                  reject(errorData);
                }
              );
          };
          this.updateLayoutConfig(newLayoutConfig, {
            PoweredReliefFan: 'Yes',
            ExhaustVolumeInput: exhaustVolume,
          }).then(
            () => {
              updateModel();
            },
            (errorData) => {
              reject(errorData);
            }
          );
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getAMCARating(size: ValidSize): string {
    const model = size.model.toUpperCase().trim();
    let hp = 0;
    const construction = !!size.outputs.constructionType
      ? Number(size.outputs.constructionType)
      : null;
    const fei = size.outputs.fei;
    const sizeNumber = Number(size.outputs.size);
    const performanceID = size.outputs.performanceId;

    switch (size.productType) {
      case ProductTypes.circulator: {
        if (
          [
            'DC-5-4',
            'DC-5-5',
            'DC-5-6',
            'DC-5-7',
            'DC-5-4-MV',
            'DC-5-5-MV',
            'DC-5-6-MV',
            'DC-5-7-MV',
            'DC-5-7-NL',
            'DC-5-10-NL',
            'DC-5-14-NL',
            'DC-5-16-NL',
            'DDF-10-VG',
            'DDF-12-VG',
            'DDF-14-VG',
            'DM-3-5',
          ].indexOf(size.outputs.selectionModelName) > -1
        ) {
          return 'N';
        } else {
          return 'C';
        }

        // hp = Number(size.outputs.motorSize);

        break;
      }
      case ProductTypes.makeUpAir: {
        hp = Number(size.outputs.fanModel.supplyMotorList[0].motorHorsePower);

        break;
      }
      default: {
        hp = Number(size.outputs.minimumMotorSizeNema);

        break;
      }
    }

    const ratings: string[] = [];

    if (size.productType === ProductTypes.louver) {
      switch (model) {
        case 'AFA-801':
        case 'AFJ-120':
        case 'AFJ-601':
        case 'AFJ-801':
        case 'AFS-120': {
          ratings.push('WaterSoundAir'); // Water, Sound, Air

          break;
        }
        case 'AFL-501':
        case 'EAC-401':
        case 'EAC-601':
        case 'EACA-601':
        case 'EACA-601D':
        case 'EAD-401':
        case 'EAD-601':
        case 'EAD-632W':
        case 'EAD-635':
        case 'EAH-401':
        case 'ECD-401':
        case 'ECD-601':
        case 'EDD-401':
        case 'EDD-601':
        case 'EDJ-401':
        case 'EDJ-601':
        case 'EDK-402':
        case 'EDK-430':
        case 'EHM-601':
        case 'ESD-202':
        case 'ESD-403':
        case 'ESD-435':
        case 'ESD-435X':
        case 'ESD-603':
        case 'ESD-635':
        case 'ESD-635D':
        case 'ESD-635DE':
        case 'ESD-635X':
        case 'ESD-635HP':
        case 'ESJ-202':
        case 'ESJ-401':
        case 'ESJ-602':
        case 'ESK-402':
        case 'ESS-502D':
        case 'FAD-402':
        case 'FAD-635':
        case 'FDS-402':
        case 'FDS-602':
        case 'FSJ-402':
        case 'SED-501': {
          ratings.push('WaterAir'); // Water, Air

          break;
        }
        case 'AFL-601':
        case 'EHH-401':
        case 'EHH-501':
        case 'EHH-501X':
        case 'EHH-601':
        case 'EHH-601D':
        case 'EHH-601DE':
        case 'EHH-701':
        case 'EHV-550':
        case 'EHV-550D':
        case 'EHV-901':
        case 'EHV-901D':
        case 'EVH-302':
        case 'EVH-302D':
        case 'EVH-501':
        case 'EVH-501D':
        case 'EVH-660D': {
          ratings.push('WaterAirWind'); // Water, Air, Wind

          break;
        }
        case 'FSL-401': {
          ratings.push('Air'); // Air

          break;
        }
      }
    } else {
      if (['AE', 'AS', 'SP', 'EQD'].indexOf(model) > -1) {
        if (
          [
            'SP-AP0511W',
            'SP-AP0511WL',
            'SP-LP0511H',
            'SP-LP0511HL',
            'SP-LP0810W',
            'SP-LP0810WL',
          ].indexOf(size.name) > -1
        ) {
          ratings.push('N');
        } else {
          ratings.push('S');
        }
      } else if (
        ['G', 'LD', 'LDP', 'CUE', 'AER', 'SE', 'SQ'].indexOf(model) > -1
      ) {
        if (fei === 0) {
          ratings.push('S');
        } else {
          ratings.push('S');
          ratings.push('F');
        }
      } else if (
        [
          'GB',
          'LB',
          'LBP',
          'CUBE',
          'USGF',
          'RBE',
          'RBCE',
          'RBU',
          'RBUMO',
          'TAUB',
          'TBI-FS',
          'QEI',
          'SBE',
          'SBCE',
          'SBS',
          'SBCS',
          'BDF',
          'BSQ',
          'EQB',
          'TCB',
          'QEID',
          'SAF',
          'RBS',
          'RBCS',
          'RBCF',
          'RBF',
          'APM',
          'APH',
          'APDB',
          'BIDW',
          'AFDW',
          'QEIDFJ',
          'KSQ',
        ].indexOf(model) > -1
      ) {
        if (fei === 0) {
          ratings.push('S');
        } else {
          ratings.push('S');
          ratings.push('F');
        }
      } else if (
        ['RE', 'RCE3', 'RDU', 'SS', 'SCE3', 'SCS3', 'RS', 'RCS3'].indexOf(
          model
        ) > -1
      ) {
        if (fei === 0) {
          ratings.push('S');
        } else {
          ratings.push('S');
          ratings.push('F');
        }
      } else if (model === 'CSP') {
        ratings.push('A');
      } else if (['TAUD'].indexOf(model) > -1) {
        if (fei === 0) {
          ratings.push('A');
        } else {
          ratings.push('A');
          ratings.push('F');
        }
      } else if (
        [
          'TAUB-CA',
          'BCF',
          'TDI',
          'VAD',
          'VAB',
          'RSF',
          'RSFP',
          'APF',
          'IP',
          'AX-SUPPLY',
        ].indexOf(model) > -1
      ) {
        if (fei === 0) {
          ratings.push('A');
        } else {
          ratings.push('A');
          ratings.push('F');
        }
      } else if (model === 'BCSW-FRP') {
        if (
          [15, 18, 22, 25, 30, 36, 44, 48, 54, 60, 73].indexOf(sizeNumber) > -1
        ) {
          if (fei === 0) {
            ratings.push('A');
          } else {
            ratings.push('A');
            ratings.push('F');
          }
        }
      } else if (model === 'BAER') {
        if ([48].indexOf(sizeNumber) > -1) {
          if (fei === 0) {
            ratings.push('A');
          } else {
            ratings.push('A');
            ratings.push('F');
          }
        } else if ([24, 30, 36, 42].indexOf(sizeNumber) > -1) {
          if (fei === 0) {
            ratings.push('S');
          } else {
            ratings.push('S');
            ratings.push('F');
          }
        }
      } else if (model === 'TBI-CA') {
        if (fei === 0 && construction === 3) {
          ratings.push('A');
        } else if (fei > 0 && construction === 3) {
          ratings.push('A');
          ratings.push('F');
        }
      } else if (model === 'AX') {
        if (fei === 0 && construction === 300) {
          ratings.push('A');
        } else if (fei > 0 && construction === 300) {
          ratings.push('A');
          ratings.push('F');
        } else if (
          fei === 0 &&
          (construction === 100 || construction === 200)
        ) {
          ratings.push('S');
        } else if (fei > 0 && (construction === 100 || construction === 200)) {
          ratings.push('S');
          ratings.push('F');
        }
      } else if (model === 'USFB') {
        switch (performanceID) {
          case 'B7': {
            if (fei === 0) {
              ratings.push('A');
            } else {
              ratings.push('A');
              ratings.push('F');
            }
            break;
          }
          case 'F3': {
            if (fei === 0) {
              ratings.push('A');
            } else {
              ratings.push('A');
              ratings.push('F');
            }

            break;
          }
          case 'A2': {
            if (fei === 0) {
              ratings.push('S');
            } else {
              ratings.push('S');
              ratings.push('F');
            }

            break;
          }

          case 'B6': {
            if (fei === 0) {
              ratings.push('S');
            } else {
              ratings.push('S');
              ratings.push('F');
            }

            break;
          }
        }
      } else if (model === 'USFA') {
        switch (construction) {
          case 100: {
            if (fei === 0) {
              ratings.push('A');
            } else {
              ratings.push('A');
              ratings.push('F');
            }

            break;
          }

          case 300: {
            if (
              [6, 7, 8, 10, 27, 30, 33, 36, 40, 49].indexOf(sizeNumber) > -1
            ) {
              if (fei === 0) {
                ratings.push('A');
              } else {
                ratings.push('A');
                ratings.push('F');
              }
            } else if (
              [12, 13, 15, 16, 18, 20, 22, 24].indexOf(sizeNumber) > -1
            ) {
              if (fei === 0) {
                ratings.push('S');
              } else {
                ratings.push('S');
                ratings.push('F');
              }
            }

            break;
          }

          case 400: {
            if (fei === 0) {
              ratings.push('S');
            } else {
              ratings.push('S');
              ratings.push('F');
            }

            break;
          }

          case 500: {
            if (fei === 0) {
              ratings.push('S');
            } else {
              ratings.push('S');
              ratings.push('F');
            }

            break;
          }
        }
      } else if (['VEKTOR-H', 'VEKTOR-MH', 'VEKTOR-CH'].indexOf(model) > -1) {
        if (fei > 0) {
          ratings.push('SF');
        } else {
          ratings.push('S');
        }
      } else if (['VEKTOR-MD', 'VEKTOR-CD'].indexOf(model) > -1) {
        if (fei > 0) {
          ratings.push('IF');
        } else {
          ratings.push('I');
        }
      } else if (['VEKTOR-CD-DIESEL'].indexOf(model) > -1) {
        if (fei > 0) {
          ratings.push('IF');
        } else {
          ratings.push('I');
        }
      } else if (model === 'FJC-3' && [6, 7, 8].indexOf(sizeNumber) > -1) {
        if (fei > 0) {
          ratings.push('AF');
        } else {
          ratings.push('A');
        }
      } else if (
        model === 'FJC-3' &&
        [12, 13, 14, 15].indexOf(sizeNumber) > -1
      ) {
        if (fei > 0) {
          ratings.push('SF');
        } else {
          ratings.push('S');
        }
      } else if (model === 'FJI' || model.substr(0, 4) === 'FJI-') {
        if (fei > 0) {
          ratings.push('SF');
        } else {
          ratings.push('S');
        }
      } else if (model === 'DS' && size.outputs.numberOfBlades === 6) {
        ratings.push('C');
      }
    }

    if (ratings.length === 0) {
      ratings.push('N');
    }

    let rating = ratings.join('').toUpperCase().trim();

    switch (rating) {
      case 'FA': {
        rating = 'AF';

        break;
      }
      case 'FI': {
        rating = 'IF';

        break;
      }
      case 'FS': {
        rating = 'SF';

        break;
      }
    }

    return rating;
  }

  reselectDerivedEntity(size: ValidSize, projectItem: Item): Promise<void> {
    return new Promise<void>((respond, reject) => {
      const category = this.getCategory(size.layoutConfig) as ProductCategory;

      firstValueFrom(
        this.http.post('/configuration/layout', {
          type: 'derived',
          entityKey: size.selectionLayoutConfig.key,
          name: category.entity,
          businessUnit: size.businessUnit,
          defaults: category.selectionRequestData || [],
        })
      ).then(
        (results) => {
          this.lastLayoutConfig = new LayoutConfiguration(results, this);

          this.updateModelGroup(this.lastLayoutConfig);

          this.lastLayoutConfigUpdated.next(this.lastLayoutConfig);

          this.reselectionSize = size;

          this.reselectionItem = projectItem;

          respond();
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  parseIntegrationVersion(size: ValidSize | string): number | null {
    const facets =
      typeof size === 'string' ? size?.split('.') : size?.id?.split('.') || [];

    if (facets.length === 2) {
      return Number(facets[0]);
    } else if (facets.length === 1) {
      return null;
    } else {
      throw new Error(
        `Invalid size ID [${typeof size === 'string' ? size : size?.id}]`
      );
    }
  }

  getStats(key: string) {
    return new Promise<any>((results, reject) => {
      firstValueFrom(this.http.get(`/configuration/layout/${key}/export`)).then(
        (resultData) => {
          results(resultData);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  importLayoutConfiguration(
    entity: ProductEntities | string,
    bu: BusinessUnits | string,
    values: { name: string; value: string }[]
  ): Promise<LayoutConfiguration> {
    return new Promise<LayoutConfiguration>((result, reject) => {
      firstValueFrom(
        this.http.post('/configuration/layout', {
          type: 'import',
          name: entity,
          businessUnit: bu,
          state: {
            businessUnit: bu,
            entityName: entity,
            values,
          },
        })
      ).then((resultData) => {
        const newConfig = new LayoutConfiguration(resultData, this);

        this.updateProductType(newConfig);

        result(newConfig);
      }, reject);
    });
  }

  revitReselection(states: IRevitState[]): Promise<void> {
    return new Promise<void>((results, reject) => {
      const selectorConfig = states.find(
        (item) => item.level === RevitStateLevels.selector
      );

      const productConfig = states.find(
        (item) => item.level === RevitStateLevels.product
      );

      if (!selectorConfig || !productConfig) {
        reject(
          new Error(
            'Unable to reselect size from the provided selection criteria.'
          )
        );
      }

      this.importLayoutConfiguration(
        selectorConfig.entityName,
        selectorConfig.businessUnit,
        selectorConfig.values.map((item) => ({
          name: item.key,
          value: item.value,
        }))
      ).then((selectionConfig) => {
        selectionConfig.businessUnit =
          selectionConfig.businessUnit || selectorConfig.businessUnit;

        const selectionSizeId = productConfig?.values.find((item) =>
          item.key.endsWith('SelectionSizeId')
        )?.value;

        if (!selectionSizeId) {
          reject(
            new Error(
              'Unable to reselect size from the provided selection criteria.'
            )
          );
        }

        const integrationVersion =
          this.parseIntegrationVersion(selectionSizeId);

        this.importLayoutConfiguration(
          productConfig.entityName,
          productConfig.businessUnit,
          productConfig.values.map((item) => ({
            name: item.key,
            value: item.value,
          }))
        ).then((productEntityConfig) => {
          productEntityConfig.businessUnit =
            productEntityConfig.businessUnit || productConfig.businessUnit;

          const group = this.products.productGroups.find((subGroup) =>
            subGroup.children.find(
              (subCategory) => subCategory.name === selectionConfig.modelGroup
            )
          );

          if (!group) {
            reject(
              new Error(
                'Unable to reselect size from the provided selection criteria.'
              )
            );
          }

          const category = group?.children.find(
            (subCategory) => subCategory.name === selectionConfig.modelGroup
          ) as ProductCategory;

          if (!category) {
            reject(
              new Error(
                'Unable to reselect size from the provided selection criteria.'
              )
            );
          }

          this.getLayoutSelection(
            selectionConfig,
            selectionConfig.productType,
            category.selectionRequestData,
            integrationVersion
          ).then((selectionResults) => {
            if (selectionResults.validSizes?.length > 0) {
              const matchingValidSize = selectionResults.validSizes.find(
                (size) =>
                  (size?.integrationId ? size.integrationId : size.id) ===
                  selectionSizeId
              );

              if (!!matchingValidSize) {
                matchingValidSize.layoutConfig = productEntityConfig;

                this.reselectSize(matchingValidSize).then(() => {
                  if (
                    !!category.customRoute &&
                    category.entity !== ProductEntities.fumeExhaust
                  ) {
                    this.router.navigateByUrl(category.customRoute);
                  } else if (
                    !!category.customRoute &&
                    category.entity === ProductEntities.fumeExhaust
                  ) {
                    this.router.navigateByUrl('/graphicalselection');
                  } else {
                    this.router.navigateByUrl('/selection');
                  }
                }, reject);
              } else {
                reject(
                  new Error(
                    'Unable to reselect size from the provided selection criteria.'
                  )
                );
              }
            } else {
              reject(
                new Error(
                  'There are no sizes available from the provided selection criteria.'
                )
              );
            }
          }, reject);
        }, reject);
      }, reject);
    });
  }
}
