export enum RevitEvents {
  version = 'VERSION',
  setAuth = 'SET_AUTH',
  logout = 'LOGOUT',
  reselect = 'RESELECT',
}

export enum PluginEvents {
  authenticated = 'AUTHENTICATED',
  download = 'DOWNLOAD',
  insert = 'INSERT',
  launchPopup = 'LAUNCH_POPUP',
}
