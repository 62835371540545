import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { environment } from '../../../environments/environment';
import { EcapsStateService } from '../../ecaps-core/handlers/services/ecaps-state.service';
import { CookiePolicyService } from '../../ecaps-core/services/cookie-policy.service';
import {
  ErrorPayload,
  EventPayload,
  FanSelectionPayload,
  LachesisClient,
  Payload,
} from './lachesisClient';

@Injectable({
  providedIn: 'root',
})
export class LachesisService {
  private lachesisClient: LachesisClient;

  private LS_USER_ID = 'user_guid';

  private _isRevit: boolean;
  public get isRevit(): boolean {
    return this._isRevit;
  }

  public set isRevit(value: boolean) {
    this._isRevit = value;
  }

  private _userId: string;
  public get userId(): string {
    return this._userId;
  }

  private _sessionId: string;
  public get sessionId(): string {
    return this._sessionId;
  }

  public isUserLoggedIn = false;

  private _isUserValidated = false;
  public get isUserValidated(): boolean {
    return this._isUserValidated;
  }
  public set isUserValidated(value: boolean) {
    this._isUserValidated = value;

    if (value) {
      if (this.trackEventQueue.length > 0) {
        this.trackEventQueue.forEach((event) => {
          this.trackEvent(event.payload).then(event.results, event.reject);
        });
        this.trackEventQueue = [];
      }

      if (this.trackUserErrorQueue.length > 0) {
        this.trackUserErrorQueue.forEach((event) => {
          this.trackUserError(
            event.errorID,
            event.errorData,
            event.email,
            event.comment
          ).then(event.results, event.reject);
        });
        this.trackUserErrorQueue = [];
      }

      if (this.trackFanSelectionQueue.length > 0) {
        this.trackFanSelectionQueue.forEach((event) => {
          this.trackFanSelection(event.payload).then(
            event.results,
            event.reject
          );
        });
        this.trackFanSelectionQueue = [];
      }

      if (this.trackErrorQueue.length > 0) {
        this.trackErrorQueue.forEach((event) => {
          globalThis
            .trackError(event.payload)
            .then(event.results, event.reject);
        });
        this.trackErrorQueue = [];
      }
    }
  }

  constructor(
    private ecapsState: EcapsStateService,
    private cookiePolicy: CookiePolicyService
  ) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    this.lachesisClient = new LachesisClient({
      apiUrl: environment.lachesis.url,
      authRoute: environment.lachesis.authPath,
      refreshRoute: environment.lachesis.refreshPath,
    });

    this._sessionId = uuid();

    this._userId = window.localStorage.getItem(this.LS_USER_ID);

    if (!this._userId) {
      this._userId = uuid();

      window.localStorage.setItem(this.LS_USER_ID, this._userId);
    }
  }

  private trackErrorQueue: {
    payload: any;
    results: () => void;
    reject: () => void;
  }[] = [];

  public async trackError(payload: ErrorPayload): Promise<void> {
    return new Promise<void>((results, reject) => {
      if (!this.cookiePolicy.getOption('analytics')) {
        results();

        return;
      }

      let fullPayload;

      if (Array.isArray(payload)) {
        fullPayload = [];

        payload.forEach((payloadItem) => {
          fullPayload.push({
            ...payloadItem,
            ...this.getGlobalProps(),
          });
        });
      } else {
        fullPayload = {
          ...payload,
          ...this.getGlobalProps(),
        };
      }

      if (this.isUserValidated) {
        this.lachesisClient.trackError(fullPayload).then(results, reject);
      } else {
        this.trackErrorQueue.push({
          payload: payload,
          results,
          reject,
        });
      }
    });
  }

  private trackFanSelectionQueue: {
    payload: any;
    results: () => void;
    reject: () => void;
  }[] = [];

  public async trackFanSelection(payload: FanSelectionPayload): Promise<void> {
    return new Promise<void>((results, reject) => {
      if (!this.cookiePolicy.getOption('analytics')) {
        results();

        return;
      }

      let fullPayload;

      if (Array.isArray(payload)) {
        fullPayload = [];

        payload.forEach((payloadItem) => {
          fullPayload.push({
            ...payloadItem,
            ...this.getGlobalProps(),
          });
        });
      } else {
        fullPayload = {
          ...payload,
          ...this.getGlobalProps(),
        };
      }

      if (this.isUserValidated) {
        this.lachesisClient
          .trackFanSelection(fullPayload)
          .then(results, reject);
      } else {
        this.trackFanSelectionQueue.push({
          payload: payload,
          results,
          reject,
        });
      }
    });
  }

  public async trackPage(pageUrl: string) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    return this.lachesisClient.trackPage(pageUrl);
  }

  private trackUserErrorQueue: {
    errorID: string;
    errorData: any;
    email: string;
    comment?: string;
    results: () => void;
    reject: () => void;
  }[] = [];

  public async trackUserError(
    errorID: string,
    errorData: any,
    email: string,
    comment?: string
  ): Promise<void> {
    return new Promise<void>(async (results, reject) => {
      if (!this.cookiePolicy.getOption('analytics')) {
        results();

        return;
      }

      if (this.isUserValidated) {
        this.lachesisClient
          .trackUserError({
            environment: environment.environment,
            version: environment.version,
            errorId: errorID,
            errorMessage:
              !!errorData.error && !!errorData.error.message
                ? errorData.error.message
                : errorData.message,
            stack:
              !!errorData.error && !!errorData.error.stack
                ? errorData.error.stack
                : errorData.stack,
            userEmail: email,
            userMessage: comment,
            state: await this.ecapsState.getCurrentState(),
            userId: this._userId,
            sessionId: this._sessionId,
            application: !this._isRevit ? 'eCAPS' : 'Revit Plugin',
            url: window.location.pathname,
            userLoggedIn: this.isUserLoggedIn,
          })
          .then(results, reject);
      } else {
        this.trackUserErrorQueue.push({
          errorID,
          errorData,
          email,
          comment,
          results,
          reject,
        });
      }
    });
  }

  private trackEventQueue: {
    payload: any;
    results: () => void;
    reject: () => void;
  }[] = [];

  public trackEvent(payload: EventPayload | EventPayload[]): Promise<void> {
    return new Promise<void>((results, reject) => {
      if (!this.cookiePolicy.getOption('analytics')) {
        results();

        return;
      }

      let fullPayload;

      if (Array.isArray(payload)) {
        fullPayload = [];

        payload.forEach((payloadItem) => {
          fullPayload.push({
            ...payloadItem,
            ...this.getGlobalProps(),
          });
        });
      } else {
        fullPayload = {
          ...payload,
          ...this.getGlobalProps(),
        };
      }

      if (this.isUserValidated) {
        this.lachesisClient.trackEvent(fullPayload).then(results, reject);
      } else {
        this.trackEventQueue.push({
          payload: payload,
          results,
          reject,
        });
      }
    });
  }

  private getGlobalProps(): Payload {
    return {
      environment: environment.environment,
      version: environment.version,
      userId: this._userId,
      sessionId: this._sessionId,
      application: !this._isRevit ? 'eCAPS' : 'Revit Plugin',
      url: window.location.pathname,
      userLoggedIn: this.isUserLoggedIn,
    };
  }
}
