import { Injectable, EventEmitter } from '@angular/core';
import { SideBarGroup } from '../models/side-bar-group.model';

@Injectable({
  providedIn: 'root'
})
export class SideBarService {
  sidebarItems: Array<SideBarGroup>;

  sidebarItemsUpdate: EventEmitter<Array<SideBarGroup>>;

  constructor() {
    this.sidebarItemsUpdate = new EventEmitter<Array<SideBarGroup>>();
  }

  setSidebarItems(groupList: Array<SideBarGroup>) {
    this.sidebarItems = groupList;

    this.sidebarItemsUpdate.next(this.sidebarItems);
  }
}
