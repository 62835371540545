import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-webservice-url-dialog',
  templateUrl: './webservice-url-dialog.component.html',
  styleUrls: ['./webservice-url-dialog.component.scss'],
})
export class WebserviceUrlDialogComponent implements OnInit {
  serviceList: Array<any>;

  formGroup: UntypedFormGroup;

  useCustom: UntypedFormControl;
  env: UntypedFormControl;
  customUrl: UntypedFormControl;

  constructor(private dialogRef: MatDialogRef<WebserviceUrlDialogComponent>) {
    this.useCustom = new UntypedFormControl(false);
    this.env = new UntypedFormControl(environment.serviceRoot);
    this.customUrl = new UntypedFormControl('', [Validators.required]);

    this.formGroup = new UntypedFormGroup({
      useCustom: this.useCustom,
      env: this.env,
      customUrl: this.customUrl,
    });

    this.customUrl.disable();

    this.serviceList = new Array<any>();

    for (const key of Object.keys(environment.serviceEnvironments)) {
      this.serviceList.push({
        name: key,
        value: environment.serviceEnvironments[key],
      });
    }
  }

  ngOnInit() {}

  useCustomClick() {
    if (this.useCustom.value === true) {
      this.env.disable();
      this.customUrl.enable();
    } else {
      this.env.enable();
      this.customUrl.disable();
    }
  }

  saveClick() {
    if (this.useCustom.value === true) {
      environment.serviceRoot =
        this.customUrl.value +
        (this.customUrl.value[this.customUrl.value.length - 1] !== '/'
          ? '/'
          : '');
    } else {
      environment.serviceRoot = this.env.value;
    }

    window.localStorage.setItem('serviceRoot', environment.serviceRoot);

    this.dialogRef.close();
  }

  cancelClick() {
    this.dialogRef.close();
  }
}
