<ng-template #informationTab>
  <!-- Templates Begin -->
  <ng-template #descriptionCard>
    <mat-card appearance="outlined" class="pic-description">
      <mat-card-content>
        <mat-toolbar *ngIf="!size?.stability.stable" class="warning">
          <mat-icon>warning</mat-icon>
          <span *ngIf="size?.stability.stabilityMessages.length === 1">{{
            (size?.stability.stabilityMessages)[0]
          }}</span>
          <ul *ngIf="size?.stability.stabilityMessages.length > 1">
            <li *ngFor="let message of size?.stability.stabilityMessages">
              {{ message }}
            </li>
          </ul>
        </mat-toolbar>

        <div class="model-image square">
          <img [src]="size?.imagePath()" alt="Product Image" />
        </div>

        <div class="body">
          <p *ngFor="let text of size?.description">{{ text }}</p>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #dimensionsCard>
    <mat-card
      appearance="outlined"
      class="pic-dimensions"
      *ngIf="!!size?.dimensions && size?.dimensions.length > 0"
    >
      <mat-card-header>
        <mat-card-title> Dimensions </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <div class="image-block">
          <div class="image">
            <img
              [src]="getDimensionImage()"
              alt="Dimensional Reference Image"
            />
          </div>

          <div
            class="image"
            *ngIf="
              size?.productType.toLowerCase().trim() === 'temperedairproduct' ||
              ['MakeUpAir', 'Preconditioner'].includes(
                size?.selectionLayoutConfig?.modelGroup
              )
            "
          >
            <img
              [src]="getDimensionImage('SVG')"
              alt="Dimensional Reference Image"
            />
          </div>
        </div>

        <table mat-table [dataSource]="dimensionsDS">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Label</th>
            <td mat-cell *matCellDef="let element">
              {{ element.displayName }}
            </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>
              Value
              {{
                size?.selectionLayoutConfig?.modelGroup !== 'Circulator'
                  ? '(in.)'
                  : ''
              }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{
                element.formattedValue || (element.value | zeroToDash : '1.0-3')
              }}
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element">
              {{ element.description }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="['name', 'value', 'description']"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['name', 'value', 'description']"
          ></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #airFlowsCard>
    <mat-card
      appearance="outlined"
      class="pic-air-flows"
      *ngIf="size?.selectionLayoutConfig?.modelGroup === 'FumeExhaust'"
    >
      <mat-card-header>
        <mat-card-title> Air Flows </mat-card-title>
      </mat-card-header>

      <mat-card-content #contentContainer>
        <mat-toolbar>
          <mat-icon>warning</mat-icon>
          <span
            >This is a schematic representation of the airflows, actual image
            may vary.</span
          >
        </mat-toolbar>

        <ng-container
          [ngSwitch]="
            size?.selectionLayoutConfig.getQuestion('FumeExhaustApplication')
              .value
          "
        >
          <fume-exhaust-lab-svg
            *ngSwitchCase="'Laboratory'"
            [layout-config]="size?.selectionLayoutConfig"
            [size]="size"
          >
          </fume-exhaust-lab-svg>
          <fume-exhaust-svg
            *ngSwitchDefault
            [layout-config]="size?.selectionLayoutConfig"
            [size]="size"
          ></fume-exhaust-svg>
        </ng-container>

        <table mat-table [dataSource]="getFumeExhaustTableDS()">
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef>Label</th>
            <td mat-cell *matCellDef="let element">
              {{ element.label }}
            </td>
            <td mat-footer-cell *matFooterCellDef>Total System Volume</td>
          </ng-container>

          <ng-container matColumnDef="volume">
            <th mat-header-cell *matHeaderCellDef>Value (CFM)</th>
            <td mat-cell *matCellDef="let element">
              {{ element.volume | number : '1.0-0' }}
            </td>
            <td mat-footer-cell *matFooterCellDef>
              {{ getFumeExhaustTableDSTotal() | number : '1.0-0' }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['label', 'volume']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['label', 'volume']"></tr>
          <tr mat-footer-row *matFooterRowDef="['label', 'volume']"></tr>
          <td>
            {{ size?.outputs?.entrainedVolume | number : '1.0-0' }}
          </td>
          <td>
            {{
              size?.outputs?.operatingVolume + size?.outputs?.entrainedVolume
                | number : '1.0-0'
            }}
          </td>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #circulatorPerformanceCard>
    <mat-card
      appearance="outlined"
      class="pic-circulator-performance"
      *ngIf="size?.selectionLayoutConfig?.modelGroup === 'Circulator'"
    >
      <mat-card-header>
        <mat-card-title> Coverage & Air Speed </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <ds-performance-svg [size]="size"></ds-performance-svg>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #circulatorSpacingCard>
    <mat-card
      appearance="outlined"
      class="pic-circulator-spacing"
      *ngIf="size?.selectionLayoutConfig?.modelGroup === 'Circulator'"
    >
      <mat-card-header>
        <mat-card-title> Fan Spacing </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <ds-fan-spacing [size]="size"></ds-fan-spacing>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #fanCurvesCard>
    <mat-card
      appearance="outlined"
      class="pic-fan-curves"
      *ngIf="showFanCurves()"
    >
      <mat-card-header>
        <mat-card-title> Fan Curves </mat-card-title>
      </mat-card-header>

      <mat-card-content [ngSwitch]="size?.productType">
        <div
          class="chart-groups"
          *ngSwitchCase="productTypes.temperedAirProduct"
        >
          <ng-container *ngIf="!!size?.charts && !!size?.charts.operating">
            <div class="group" *ngIf="!!size?.charts.operating.supplyFanChart">
              <div class="title">Supply Fan</div>
              <ventabula-chart
                [chartData]="size?.charts.operating.supplyFanChart"
              ></ventabula-chart>
            </div>

            <div class="group" *ngIf="!!size?.charts.operating.exhaustFanChart">
              <div class="title">Exhaust Fan</div>
              <ventabula-chart
                [chartData]="size?.charts.operating.exhaustFanChart"
              ></ventabula-chart>
            </div>
          </ng-container>
        </div>

        <div class="chart-groups" *ngSwitchCase="productTypes.makeUpAir">
          <ng-container *ngIf="!!size?.charts && !!size?.charts.operating">
            <div class="group" *ngIf="!!size?.charts.operating.supplyFanChart">
              <div class="title">Supply Fan</div>
              <ventabula-chart
                [chartData]="size?.charts.operating.supplyFanChart"
              ></ventabula-chart>
            </div>

            <div class="group" *ngIf="!!size?.charts.operating.exhaustFanChart">
              <div class="title">Exhaust Fan</div>
              <ventabula-chart
                [chartData]="size?.charts.operating.exhaustFanChart"
              ></ventabula-chart>
            </div>
          </ng-container>
        </div>

        <div class="chart-groups" *ngSwitchCase="productTypes.preconditioners">
          <ng-container *ngIf="!!size?.charts && !!size?.charts.operating">
            <div class="group" *ngIf="!!size?.charts.operating.supplyFanChart">
              <div class="title">Supply Fan</div>
              <ventabula-chart
                [chartData]="size?.charts.operating.supplyFanChart"
              ></ventabula-chart>
            </div>

            <div class="group" *ngIf="!!size?.charts.operating.exhaustFanChart">
              <div class="title">Exhaust Fan</div>
              <ventabula-chart
                [chartData]="size?.charts.operating.exhaustFanChart"
              ></ventabula-chart>
            </div>
          </ng-container>
        </div>

        <div class="single-chart" *ngSwitchCase="productTypes.fan">
          <ventabula-valid-size-chart
            [size]="size"
            [chartSpacing]="chartSpacing"
          >
            <div
              sp-table
              class="sp-corrections"
              #spCorrections
              *ngIf="
                showSPCorrections &&
                size?.productType.toLowerCase().trim().indexOf('fan') === 0
              "
            >
              <table mat-table [dataSource]="spCorrectionsDS">
                <ng-container matColumnDef="table-description">
                  <th colspan="2" mat-header-cell *matHeaderCellDef>
                    Static Pressure Calculations
                  </th>
                </ng-container>

                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.name }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef>Total External SP</td>
                </ng-container>

                <ng-container matColumnDef="value">
                  <th mat-header-cell *matHeaderCellDef>Value (in. wg)</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.value | number : '1.3-3' }}
                  </td>
                  <td mat-footer-cell *matFooterCellDef>
                    {{ totalStaticPressure | number : '1.3-3' }}
                  </td>
                </ng-container>

                <tr
                  class="top-header-row"
                  mat-header-row
                  *matHeaderRowDef="['table-description']"
                ></tr>
                <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: ['name', 'value']"
                ></tr>
                <tr mat-footer-row *matFooterRowDef="['name', 'value']"></tr>
              </table>
            </div>
          </ventabula-valid-size-chart>
        </div>

        <div class="single-chart" *ngSwitchCase="productTypes.circulator">
          <ventabula-valid-size-chart
            [size]="size"
            [chartSpacing]="chartSpacing"
          ></ventabula-valid-size-chart>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #circulatorInstallationClearancesCard>
    <mat-card
      appearance="outlined"
      class="pic-circ-install-clearance"
      *ngIf="size?.selectionLayoutConfig?.modelGroup === 'Circulator'"
    >
      <mat-card-header>
        <mat-card-title> Installation Clearances </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <table mat-table [dataSource]="circInstallClearDS">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element">
              {{ element.value }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #drawingsCard>
    <mat-card
      appearance="outlined"
      class="pic-drawings"
      *ngIf="!!size?.files && !isRevitPlugin"
    >
      <mat-card-header>
        <mat-card-title> Drawings </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <table mat-table [dataSource]="getDrawingFiles()">
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">{{ element.type }}</td>
          </ng-container>

          <ng-container matColumnDef="drawing">
            <th mat-header-cell *matHeaderCellDef>Drawing</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-button
                color="primary"
                (click)="drawingDownload(element)"
              >
                {{ element.name }}
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="footer">
            <td mat-footer-cell *matFooterCellDef colspan="2">
              <button
                mat-raised-button
                color="primary"
                (click)="emailAllDrawings()"
              >
                <mat-icon>mail_outline</mat-icon>
                <span>Email All</span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['type', 'drawing']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['type', 'drawing']"></tr>
          <tr mat-footer-row *matFooterRowDef="['footer']"></tr>
        </table>

        <div class="revit-addin-ad">
          <div>
            <div class="addin-name">
              <div
                class="svg"
                loadChild="/assets/images/icons/svg/ecaps_logo.svg"
              ></div>
              <mat-icon class="revit-arrow" color="accent"
                >arrow_forward</mat-icon
              >
              <span>Revit</span>
              <sup>&reg;</sup>
            </div>
            <div class="addin-ad-msg">
              Download the eCAPS<sup>&reg;</sup> Bridge add-in for Revit<sup
                >&reg;</sup
              >
              to make and insert selections directly into your projects without
              leaving Revit!
            </div>
          </div>
          <button
            mat-raised-button
            color="primary"
            (click)="downloadRevitAddin()"
          >
            <mat-icon>info_outline</mat-icon>
            <span>Learn More</span>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #energyRecoveryCard>
    <mat-card
      appearance="outlined"
      class="pic-energy-recovery"
      *ngIf="
        !!size?.model &&
        (size?.model.toLowerCase().trim() === 'rve' ||
          size?.model.toLowerCase().trim() === 'rvc')
      "
    >
      <mat-card-header>
        <mat-card-title> Energy Recovery </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <table mat-table [dataSource]="erTableDS">
          <ng-container matColumnDef="spacer">
            <th mat-header-cell *matHeaderCellDef></th>
          </ng-container>

          <ng-container matColumnDef="summer">
            <th colspan="2" mat-header-cell *matHeaderCellDef>Summer</th>
          </ng-container>

          <ng-container matColumnDef="winter">
            <th colspan="2" mat-header-cell *matHeaderCellDef>Winter</th>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td
              mat-cell
              *matCellDef="let element"
              [innerHtml]="element.name"
            ></td>
          </ng-container>

          <ng-container matColumnDef="summerEntering">
            <th mat-header-cell *matHeaderCellDef>Entering</th>
            <td mat-cell *matCellDef="let element">
              {{ element.summerEntering | zeroToDash : '1.0-1' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="summerLeaving">
            <th mat-header-cell *matHeaderCellDef>Leaving</th>
            <td mat-cell *matCellDef="let element">
              {{ element.summerLeaving | zeroToDash : '1.0-1' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="winterEntering">
            <th mat-header-cell *matHeaderCellDef>Entering</th>
            <td mat-cell *matCellDef="let element">
              {{ element.winterEntering | number : '1.0-1' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="winterLeaving">
            <th mat-header-cell *matHeaderCellDef>Leaving</th>
            <td mat-cell *matCellDef="let element">
              {{ element.winterLeaving | zeroToDash : '1.0-1' }}
            </td>
          </ng-container>

          <tr
            class="top-header-row"
            mat-header-row
            *matHeaderRowDef="['spacer', 'summer', 'winter']"
          ></tr>
          <tr
            mat-header-row
            *matHeaderRowDef="[
              'name',
              'summerEntering',
              'summerLeaving',
              'winterEntering',
              'winterLeaving'
            ]"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              columns: [
                'name',
                'summerEntering',
                'summerLeaving',
                'winterEntering',
                'winterLeaving'
              ]
            "
          ></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #coolingPerformanceCard>
    <mat-card
      appearance="outlined"
      class="pic-cooling-perf"
      *ngIf="
        !!size?.outputs.coolingModel &&
        size?.outputs.coolingModel.coolingModelType.toLowerCase() !== 'none'
      "
    >
      <mat-card-header>
        <mat-card-title> Cooling Performance </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <table mat-table [dataSource]="coolingDetailDS">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Cooling Detail</th>
            <td
              mat-cell
              *matCellDef="let element"
              [innerHtml]="element.name"
            ></td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element">
              {{ element.value }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #primaryHeatingPerformanceCard>
    <mat-card
      appearance="outlined"
      class="pic-primary-heating-perf"
      *ngIf="
        ((!!size?.outputs.heatingModel &&
          size?.outputs.heatingModel.selectedHeatingModelType.toLowerCase() !==
            'none') ||
          (!!size?.outputs.coolingModel &&
            size?.outputs.coolingModel.selectedCoolingModelType.toLowerCase() ===
              'airsourceheatpump')) &&
        size?.selectionLayoutConfig?.getQuestion('HeatingTypePrimary')?.visible
      "
    >
      <mat-card-header>
        <mat-card-title> Primary Heating Performance </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <table mat-table [dataSource]="primaryHeatingDetailDS">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Heating Detail</th>
            <td
              mat-cell
              *matCellDef="let element"
              [innerHtml]="element.name"
            ></td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element">
              {{ element.value }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #heatingPerformanceCard>
    <mat-card
      appearance="outlined"
      class="pic-heating-perf"
      *ngIf="
        !!size?.outputs.heatingModel &&
        size?.outputs.heatingModel.selectedHeatingModelType.toLowerCase() !==
          'none'
      "
    >
      <mat-card-header>
        <mat-card-title>
          {{
            size?.selectionLayoutConfig?.getQuestion('HeatingTypePrimary')
              ?.visible &&
            !!size?.selectionLayoutConfig?.getQuestion('HeatingType')?.value
              ? 'Secondary'
              : null
          }}
          Heating Performance
        </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <table mat-table [dataSource]="heatingDetailDS">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Heating Detail</th>
            <td
              mat-cell
              *matCellDef="let element"
              [innerHtml]="element.name"
            ></td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>Value</th>
            <td mat-cell *matCellDef="let element">
              {{ element.value }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['name', 'value']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['name', 'value']"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #amcaCard>
    <mat-card
      appearance="outlined"
      class="pic-amca"
      *ngIf="!!size && size?.amcaRatings !== '' && size?.amcaRatings !== 'N'"
    >
      <mat-card-header>
        <mat-card-title> AMCA </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <amca-seal [seal]="size?.amcaRatings"></amca-seal>

        <img class="qr square" src="/assets/images/faqs/qrGreenheck.png" />

        <div class="amca-text">
          <p *ngFor="let text of size?.amcaDescription">{{ text }}</p>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #sideBarNav>
    <ul
      class="sidebar-nav"
      role="navigation"
      aria-label="Product Information Navigation"
    >
      <li
        *ngFor="let item of scrollJumpItems"
        (click)="scrollJump(item.class)"
        [overflowTooltip]="item.title"
        [overflowElement]="title"
        matRipple
      >
        <mat-icon class="square" [svgIcon]="item.svgIcon">{{
          item.icon
        }}</mat-icon>

        <div #title>
          {{ item.title }}
        </div>
      </li>
    </ul>
  </ng-template>
  <!-- Templates End -->

  <div class="information-tab">
    <ng-template [ngTemplateOutlet]="sideBarNav"></ng-template>

    <div class="content" #infoTabContent>
      <ng-template [ngTemplateOutlet]="descriptionCard"></ng-template>

      <ng-template [ngTemplateOutlet]="airFlowsCard"></ng-template>

      <ng-template [ngTemplateOutlet]="circulatorPerformanceCard"></ng-template>

      <ng-template [ngTemplateOutlet]="circulatorSpacingCard"></ng-template>

      <ng-template [ngTemplateOutlet]="fanCurvesCard"></ng-template>

      <ng-template
        [ngTemplateOutlet]="circulatorInstallationClearancesCard"
      ></ng-template>

      <ng-template [ngTemplateOutlet]="dimensionsCard"></ng-template>

      <ng-template [ngTemplateOutlet]="drawingsCard"></ng-template>

      <ng-template [ngTemplateOutlet]="energyRecoveryCard"></ng-template>

      <ng-template [ngTemplateOutlet]="coolingPerformanceCard"></ng-template>

      <ng-template
        [ngTemplateOutlet]="primaryHeatingPerformanceCard"
      ></ng-template>

      <ng-template [ngTemplateOutlet]="heatingPerformanceCard"></ng-template>

      <ng-template [ngTemplateOutlet]="amcaCard"></ng-template>
    </div>
  </div>
</ng-template>

<ng-template #customizeTab>
  <div class="customize-tab">
    <form [formGroup]="formGroup" [class.validate]="validate">
      <mat-card appearance="outlined">
        <mat-card-header>
          <div mat-card-avatar class="number-icon">
            <div>1</div>
          </div>
          <mat-card-title> General Information </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <mat-form-field class="required">
            <mat-label>Quantity</mat-label>

            <input
              matInput
              formControlName="quantity"
              type="number"
              step="1"
              autocomplete="off"
            />
            <mat-error *ngIf="!!quantityField.errors">{{
              quantityField | fieldErrorToText
            }}</mat-error>
          </mat-form-field>
          <mat-form-field class="required">
            <mat-label>Tag</mat-label>

            <input
              matInput
              formControlName="tag"
              type="text"
              ghSpecialChars
              toUpperCase
              #tagInput
              autocomplete="off"
            />
            <mat-error *ngIf="!!tagField.errors">{{
              tagField | fieldErrorToText
            }}</mat-error>
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <mat-card appearance="outlined">
        <mat-card-header>
          <div mat-card-avatar class="number-icon">
            <div>2</div>
          </div>
          <mat-card-title> Geographic Information </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <mat-form-field>
            <mat-label>Location</mat-label>

            <input matInput formControlName="location" autocomplete="off" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Area Served</mat-label>

            <input matInput formControlName="area" autocomplete="off" />
          </mat-form-field>
        </mat-card-content>
      </mat-card>

      <mat-card
        appearance="outlined"
        *ngFor="let group of sizeConfigGroups(); let i = index"
      >
        <mat-card-header>
          <div mat-card-avatar class="number-icon">
            <div>{{ i + 3 }}</div>
          </div>
          <mat-card-title> {{ group.text }} </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <div *ngFor="let subGroup of getSubGroups(group)" class="sub-group">
            <div class="title">{{ subGroup.text }}</div>
            <div class="questions">
              <configuration-question
                *ngFor="let question of getQuestions(subGroup)"
                [question]="question"
                [layout]="size?.layoutConfig"
                (answered)="questionAnswered()"
              ></configuration-question>
            </div>
          </div>
          <ng-container
            *ngIf="getQuestions(group) && getQuestions(group).length > 0"
          >
            <configuration-question
              *ngFor="let question of getQuestions(group)"
              [question]="question"
              [layout]="size?.layoutConfig"
              (answered)="questionAnswered()"
            ></configuration-question>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </form>
    <mat-toolbar
      *ngIf="
        size?.drawingNotes?.length > 0 &&
        size?.selectionLayoutConfig?.modelGroup === 'ScrolledBlower'
      "
      class="note-bar"
    >
      <mat-icon color="primary">announcement</mat-icon>
      <span class="title">Note:</span>
      <span *ngIf="size?.drawingNotes.length === 1">{{
        (size?.drawingNotes)[0]
      }}</span>
      <ul *ngIf="size?.drawingNotes.length > 1">
        <li *ngFor="let message of size?.drawingNotes">{{ message }}</li>
      </ul>
    </mat-toolbar>
  </div>
</ng-template>

<ng-template #controlsSequenceTab>
  <div class="soo-content" [class.customize]="customizeSoo">
    <mat-card appearance="outlined" *ngIf="!!getSooApplication()">
      <mat-card-content>
        <mat-form-field>
          <mat-label>{{ getSooApplication().text }}</mat-label>

          <mat-select
            [(ngModel)]="getSooApplication().value"
            (selectionChange)="sooQuestionAnswered(getSooApplication())"
          >
            <mat-option
              *ngFor="let option of filterSooQuestions(getSooApplication())"
              [value]="option.name"
              >{{ option.text }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <div class="description">
          {{ sooGroupDesc(getSooApplication()) }}
        </div>
      </mat-card-content>
      <mat-card-footer>
        <button
          mat-raised-button
          color="primary"
          (click)="customizeSoo = !customizeSoo"
        >
          Customize
        </button>
      </mat-card-footer>
    </mat-card>
    <div *ngIf="customizeSoo === true">
      <mat-card
        appearance="outlined"
        *ngFor="let question of getSooQuestions()"
      >
        <mat-card-content>
          <div class="questions">
            <configuration-question
              [question]="question"
              [layout]="size?.layoutConfig"
              (answered)="sooQuestionAnswered(question)"
            ></configuration-question>
          </div>
          <div class="description">{{ sooGroupDesc(question) }}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ng-template>

<ng-template #lodTab let-lod="lod">
  <div class="revit-tab">
    <form [formGroup]="revitFormGroup">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title> LOD {{ lod }} </mat-card-title>
        </mat-card-header>

        <mat-card-content>
          <mat-card appearance="outlined" [ngSwitch]="lod">
            <span *ngSwitchCase="'200'">
              LOD 200 Revit families are typically intended for Schematic
              Design. The family contains: dimensional parameters only, duct
              connector(s) & clearances.
            </span>

            <span *ngSwitchCase="'300'">
              LOD 300 Revit families are typically intended for Design
              Development. The family contains performance data for this
              specific selection (as shared parameters), duct connector(s) &
              clearances.
            </span>
          </mat-card>

          <ng-container *ngIf="lod === '300'">
            <mat-card appearance="outlined">
              <mat-card-header>
                <div mat-card-avatar class="number-icon">
                  <div>1</div>
                </div>
                <mat-card-title> General Information </mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <mat-form-field class="required">
                  <mat-label>Tag</mat-label>

                  <input
                    matInput
                    formControlName="tag"
                    type="text"
                    ghSpecialChars
                    toUpperCase
                    #tagInput
                    autocomplete="off"
                  />
                  <mat-error *ngIf="!!tagField.errors">{{
                    tagField | fieldErrorToText
                  }}</mat-error>
                </mat-form-field>
              </mat-card-content>
            </mat-card>

            <mat-card
              appearance="outlined"
              *ngFor="let group of sizeConfigGroups(); let i = index"
            >
              <mat-card-header>
                <div mat-card-avatar class="number-icon">
                  <div>{{ i + 2 }}</div>
                </div>
                <mat-card-title> {{ group.text }} </mat-card-title>
              </mat-card-header>

              <mat-card-content>
                <div
                  *ngFor="let subGroup of getSubGroups(group)"
                  class="sub-group"
                >
                  <div class="title">{{ subGroup.text }}</div>
                  <div class="questions">
                    <configuration-question
                      *ngFor="let question of getQuestions(subGroup)"
                      [question]="question"
                      [layout]="size?.layoutConfig"
                    ></configuration-question>
                  </div>
                </div>
                <ng-container
                  *ngIf="getQuestions(group) && getQuestions(group).length > 0"
                >
                  <configuration-question
                    *ngFor="let question of getQuestions(group)"
                    [question]="question"
                    [layout]="size?.layoutConfig"
                    (answered)="questionAnswered()"
                  ></configuration-question>
                </ng-container>
              </mat-card-content>
            </mat-card>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </form>
  </div>
</ng-template>

<side-dialog-title class="product-info-dialog__title"
  >{{ size?.name }} Product Information
  <em
    *ngIf="
      size?.layoutConfig &&
      size?.layoutConfig.validationState !== 'Valid' &&
      !production
    "
    [ngStyle]="{ color: 'red', 'margin-left': '20px', cursor: 'pointer' }"
    (click)="logOutValidationErrors()"
  >
    {{ size?.layoutConfig.validationState }}</em
  >
</side-dialog-title>

<side-dialog-content class="product-info-dialog__content">
  <mat-tab-group
    color="primary"
    [mat-stretch-tabs]="false"
    (selectedTabChange)="tabChange($event)"
    (animationDone)="tabChangeDone()"
  >
    <mat-tab label="Information">
      <ng-template [ngTemplateOutlet]="informationTab"></ng-template>
    </mat-tab>

    <mat-tab label="Customize" *ngIf="!isRevitPlugin">
      <ng-template [ngTemplateOutlet]="customizeTab"></ng-template>
    </mat-tab>

    <mat-tab *ngIf="showSequenceTab()" label="Controls Sequence">
      <ng-template [ngTemplateOutlet]="controlsSequenceTab"></ng-template>
    </mat-tab>

    <ng-container *ngIf="isRevitPlugin">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon svgIcon="revit"></mat-icon>
          LOD 300
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="lodTab"
          [ngTemplateOutletContext]="{ lod: '300' }"
        ></ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon svgIcon="revit"></mat-icon>
          LOD 200
        </ng-template>

        <ng-template
          [ngTemplateOutlet]="lodTab"
          [ngTemplateOutletContext]="{ lod: '200' }"
        ></ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</side-dialog-content>

<side-dialog-actions class="product-info-dialog__buttons">
  <button
    mat-raised-button
    color="primary"
    (click)="reselectClick()"
    *ngIf="!!revisionItem"
  >
    Reselect
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="updateClick()"
    *ngIf="!!revisionItem"
  >
    Update
  </button>
  <button mat-raised-button color="primary" (click)="cutSheetClick()">
    <mat-icon>cloud_download</mat-icon>Cut Sheet
  </button>
  <button
    mat-raised-button
    color="primary"
    class="add-to-schedule"
    (click)="scheduleClick()"
    *ngIf="!revisionItem && !isRevitPlugin"
  >
    <mat-icon svgIcon="excel"></mat-icon>
    Add To Schedule
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="disableRevitLodInsertAndDownload()"
    *ngIf="!!isRevitPlugin"
    (click)="revitInsert()"
  >
    <mat-icon svgIcon="revit"></mat-icon> Insert
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="disableRevitLodInsertAndDownload()"
    *ngIf="!!isRevitPlugin"
    (click)="revitDownload()"
  >
    <mat-icon svgIcon="revit"></mat-icon> Download
  </button>
  <button mat-raised-button color="primary" (click)="closeClick()">
    Close
  </button>
</side-dialog-actions>
