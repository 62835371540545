import {
  Component,
  OnInit,
  Input,
  ElementRef,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ViewEncapsulation,
} from '@angular/core';

declare var $: any;

@Component({
  selector: 'ventabula-chart',
  templateUrl: './ventabula-chart.component.html',
  styleUrls: ['./ventabula-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VentabulaChartComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input('chartData') private _chartData: any;
  @Input('chartSpacing') chartSpacing: number;
  @Input('showElevation') showElevation = false;

  showUnavailable = false;

  private _rerender = false;

  private renderTimer: any;

  @ViewChild('chartContainer')
  private chartContainer: ElementRef;
  @ViewChild('chartLegend') private chartLegend: ElementRef;

  constructor(private element: ElementRef) {}

  ngOnInit() {
    if (!!this._chartData) {
      this.render();
    }
  }

  ngAfterViewInit() {
    if (this._rerender) {
      this.render();
    }
  }

  ngOnDestroy() {
    window.clearTimeout(this.renderTimer);
  }

  public renderChart(chartData: any) {
    this._chartData = chartData;

    this.render();
  }

  private render() {
    this.showUnavailable = false;

    if ($(this.element.nativeElement).is(':not(:visible)')) {
      const self = this;

      this.renderTimer = window.setTimeout(function () {
        self.render.call(self);
      }, 125);

      return;
    }

    const legend = this.chartLegend?.nativeElement;
    const chart = this.chartContainer?.nativeElement;

    if (!!legend && !!chart) {
      this._rerender = false;

      legend.innerHTML = '';
      chart.innerHTML = '';

      $.ventabula(chart, this._chartData, {
        legendElement: legend,
        width: chart.clientWidth,
        height: chart.clientWidth * 0.5,
      });

      legend.querySelector('svg').style.fontSize = '1em';
    } else {
      this._rerender = true;
    }
  }

  public showChartUnavailable() {
    this.showUnavailable = true;
  }
}
