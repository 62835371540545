<table mat-table [dataSource]="getActiveProjects()">
  <ng-container matColumnDef="jobName">
    <th mat-header-cell *matHeaderCellDef> Job Name </th>
    <td mat-cell *matCellDef="let element"> <button mat-button color="primary" (click)="projectInfo(element)">{{ element.name }}</button> </td>
  </ng-container>
  
  <ng-container matColumnDef="createdOn">
    <th mat-header-cell *matHeaderCellDef> Created On </th>
    <td mat-cell *matCellDef="let element"> {{ element.creationDate | date: 'M/d/yyyy' }} </td>
  </ng-container>
  
  <ng-container matColumnDef="lastSavedOn">
    <th mat-header-cell *matHeaderCellDef> Last Saved On </th>
    <td mat-cell *matCellDef="let element"> {{ element.lastSavedDate | date: 'M/d/yyyy' }} </td>
  </ng-container>
  
  <ng-container matColumnDef="city">
    <th mat-header-cell *matHeaderCellDef> City </th>
    <td mat-cell *matCellDef="let element"> {{ element.location }} </td>
  </ng-container>
  
  <ng-container matColumnDef="quantity">
    <th mat-header-cell *matHeaderCellDef> Quantity </th>
    <td mat-cell *matCellDef="let element"> {{ element.getActiveRevision()?.getQuantity() | number: '1.0-0' }} </td>
  </ng-container>
  
  <ng-container matColumnDef="currentVersion">
    <th mat-header-cell *matHeaderCellDef> Current Version </th>
    <td mat-cell *matCellDef="let element"> {{ element.getActiveRevision()?.name }} </td>
  </ng-container>
  
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> </th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button (click)="deleteProject(element)" matTooltip="Delete job">
        <mat-icon class="dp24">delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>
</table>