import { INote } from './note.interface';

export class UserNotes {
  defaultNotes: Array<INote>;
  customNotes: Array<INote>;

  constructor(data: any) {
    this.defaultNotes = new Array<INote>();

    if (!!data.defaultNotes) {
      for (let i = 0; i < data.defaultNotes.length; i++) {
        this.defaultNotes.push(<INote>data.defaultNotes[i]);
      }
    }

    this.customNotes = new Array<INote>();

    if (!!data.notes) {
      for (let i = 0; i < data.notes.length; i++) {
        this.customNotes.push(<INote>data.notes[i]);
      }
    }
  }
}
