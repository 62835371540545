import { Injectable } from '@angular/core';
import { UsersService } from '../services/users.service';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';

@Injectable({
  providedIn: 'root'
})
export class Users {
  constructor(private usersService: UsersService, private core: EcapsCore) {}

  showLoginRequiredDialog(disableClose: boolean = false): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.usersService.getCurrentUser().then(userData => {
        if (userData.authenticated) {
          resolve(true);
        } else {
          this.core
            .showMessageBox(
              {
                title: 'Login Required',
                message: 'You must be logged in before you can proceed.',
                icon: 'error_outline',
                buttons: [
                  {
                    title: 'Login / Register',
                    value: 'LOGIN'
                  },
                  {
                    title: 'Cancel'
                  }
                ]
              },
              disableClose
            )
            .then(result => {
              if (result === 'LOGIN') {
                this.usersService.login();
              } else {
                resolve(false);
              }
            });
        }
      });
    });
  }
}
