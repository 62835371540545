import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IVideoDialogData } from './models/i-video-dialog-data.interface';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent implements OnInit {
  title: string;

  videoUrl: SafeResourceUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IVideoDialogData,
    private sanitizer: DomSanitizer
  ) {
    this.title = this.data.title;

    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.videoUrl
    );
  }

  ngOnInit() {}
}
