import { Geometry } from './geometry.model';
import { AddressComponents } from './address-components.model';

export class GeocodedAddress {
    addressComponents: AddressComponents;
    formattedAddress: string;
    geometry: Geometry;
    placeID: string;
    types: Array<string>;

    constructor(data: any) {
        this.addressComponents = new AddressComponents(data.address_components);
        this.formattedAddress = data.formatted_address;
        this.geometry = new Geometry(data.geometry);
        this.placeID = data.place_id;
        this.types = data.types;
    }
}
