export const swatches: {
  name: string;
  text: string;
  hex?: string;
  image?: string;
}[] = [
  {
    name: 'BlackAnodize',
    text: 'Black Anodize',
    image: '/assets/images/swatches/black_anodize.png',
  },
  {
    name: 'BoneWhite',
    text: 'Bone White',
    hex: '#eceadb',
  },
  {
    name: 'ChampagneAnodize',
    text: 'Champagne Anodize',
    image: '/assets/images/swatches/champagne_anodize.png',
  },
  {
    name: 'CinnamonCherry',
    text: 'Cinnamon Cherry',
    image: '/assets/images/swatches/cinnamon_cherry.png',
  },
  {
    name: 'ClearAnodize',
    text: 'Clear Anodize',
    image: '/assets/images/swatches/clear_anodize.png',
  },
  {
    name: 'None',
    text: 'Mill',
    image: '/assets/images/swatches/clear_anodize.png',
  },
  {
    name: 'ConcreteGray',
    text: 'Concrete Gray',
    hex: '#7a8175',
  },
  {
    name: 'DarkOak',
    text: 'Dark Oak',
    image: '/assets/images/swatches/dark_oak.png',
  },
  {
    name: 'EquipmentGreen',
    text: 'Equipment Green',
    hex: '#2c5925',
  },
  {
    name: 'FlatBlack',
    text: 'Flat Black',
    hex: '#00000a',
  },
  {
    name: 'GoldenKnottyPine',
    text: 'Golden Knotty Pine',
    image: '/assets/images/swatches/golden_knotty_pine.png',
  },
  {
    name: 'HamptonBrown',
    text: 'Hampton Brown',
    hex: '#7a5c4e',
  },
  {
    name: 'HoneyKnottyPine',
    text: 'Honey Knotty Pine',
    image: '/assets/images/swatches/honey_knotty_pine.png',
  },
  {
    name: 'Ivory',
    text: 'Ivory',
    hex: '#fdf3cb',
  },
  {
    name: 'MediumBronze',
    text: 'Medium Bronze',
    hex: '#58494a',
  },
  {
    name: 'MediumBronzeAnodize',
    text: 'Medium Bronze Anodize',
    image: '/assets/images/swatches/medium_bronze_anodize.png',
  },
  {
    name: 'NaturalCherry',
    text: 'Natural Cherry',
    image: '/assets/images/swatches/natural_cherry.png',
  },
  {
    name: 'SafetyOrange',
    text: 'Safety Orange',
    hex: '#ea6a20',
  },
  {
    name: 'SafetyYellow',
    text: 'Safety Yellow',
    hex: '#ffda00',
  },
  {
    name: 'GreenheckBlue',
    text: 'Greenheck Blue',
    hex: '#005a9c',
  },
  {
    name: 'SignalViolet',
    text: 'Signal Violet',
    hex: '#933c91',
  },
  {
    name: 'Telegray',
    text: 'Telegray',
    hex: '#cdd0cf',
  },
  {
    name: 'UtilityRed',
    text: 'Utility Red',
    hex: '#c4161c',
  },
];
