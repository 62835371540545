export const releaseNotes = [
  {
    text: 'eCAPS version 3.8.1 - July 8th, 2024',
    notes: [
      'Obsoleted the models RV-110, RVE-180, and RVC-145',
    ],
  },
  {
    text: 'eCAPS version 3.8.0 - June 4th, 2024',
    notes: [
      'The FEI (Fan Energy Index) values update when user changes the phase',
      'Added new aluminum FEMA louver model AFL-601',
      'Removed low volume selling louver models; AFJ-801, EAH-401, EDD-401, EDD-601, EDJ-601, EDK-430, EHH-501X, EHH-701, EHM-601, ESD-435X, ESD-635X, ESJ-155, ESK-402, and ESU-154',
      'For louvers, increased the max pressure drop from 0.5 to 0.75 inches wg',
      'Obsoleted the following fans; roof supply fan model SAF (replaced by the KSQ), direct drive tubular upblast fan model TAUD, and tubular inline fan model TDI',
      'Expanded performance range on belt and direct drive wall mounted propeller fan models AER and BAER',
      'Added filtered wall housing option to belt driven wall mounted propeller fan model BAER',
      'Obsoleted ceiling exhaust fan model SP-A1410 and inline model CSP-A1410',
      'Obsoleted select sizes of wall mounted propeller models SBE-3, SBS-3, SBCE, SBCS, SE2, and SS2 (replaced by AER and BAER)',
    ],
  },
  {
    text: 'eCAPS version 3.7.0 - January 29th, 2024',
    notes: [
      'Added axial exhaust fan model AX-Upblast 200 series selections',
      'Expanded the blower model USF redesign to include the direct drive sizes 27 thru 40',
      'Added bathroom fan model SP-H sizes with humidity sensor',
      'Added bathroom fan model SP-LP sizes',
      'New High Volume Low Speed overhead fan model DM-3 for residential applications',
      'Updated Dedicated Outdoor Air Systems aluminum energy recovery wheel selections',
      'Updated Make-up Air PDX selections',
      'Updated the defaulted Make-up Air schedule columns and notes',
      'Fixed bugs with fan material on blowers',
      'Synced with CAPS v4.43',
    ],
  },
  {
    text: 'eCAPS version 3.6.0 - September 28th, 2023',
    notes: [
      'Moved selection guides from Toolbox to main selection buttons and added how to Schedule links and video',
      'Added Healthcare, Laboratory, and Cleanroom Products selection guide',
      'Added Directional Destratification Fans, model DDF',
      'Added Revit LOD-300 content for the Dedicated Outdoor Air Units (DOAS)',
      'Updated efficiency metrics for DOAS products including added ISMRE2 and ISCOP2',
      'DOAS energy recovery wheel simplification',
      'Allow fan quantity of 4 for Fume exhaust selections',
      'Plenum fan model APD redesign',
      'Wall mounted propeller fan model BAER, added new sizes of 42 and 48',
      'Expanded tube axial inline fan model AX to include series 100 and 200 selections',
      'Updated electrical operating costs from $0.10 to $0.12 per kw hour',
      'Obsoleted wall mounted propeller models SBE and SBS levels 1 and 2 aluminum propeller selections',
      'New direct drive options for make-up air models DGX and MSX housing H35',
    ],
  },
  {
    text: 'eCAPS version 3.5.0 - June 12th, 2023',
    notes: [
      'Added MCA and MOP amp values for most fans',
      'Added California Title 20 filtering option for fans',
      'Added additional sizes to the wall mounted propeller model: AER and BAER',
      'Discontinued a few sizes of the ceiling exhaust bath fan models SP and CSP',
      'Updated the centrifugal blower model USF',
      'Obsoleted the make-up air roof supply models KSFD and KSKB',
      'Introduced the new roof supply fan model KSQ',
      'Obsoleted the inline fan model BDF',
      'Added additional sizes to the mixed flow inline fan model SQ',
      'Obsoleted a few louver models; AFS-120, FAD-402, FAD-635, FDS-402, FDS-602, FSJ-402, FSL-401, SED-401',
      'Added FEI (Fan Energy Index) for make-up air and energy recovery selections',
      'New direct drive fan options for make-up air models DGX & MSX in housings H12 - H32',
      'Air flow enhancements to energy recovery models MiniVent and MiniCore',
      'Added hot water and chilled water coil options to the dedicated outdoor air systems (DOAS) model RV-10',
    ],
  },
  {
    text: 'eCAPS version 3.4.0 - January 30th, 2023',
    notes: [
      'DOAS - added model RVC with Fiber and Polymer energy recovery cores',
      'Preconditioner - renamed to Energy Recovery Ventilators (ERV)',
      'Preconditioner/ERV - expanded Polymer Core selections to include MinCore-5-P and 10-P',
      'Ceiling Fans - updated the lighted grille design and offering',
      'Inline fans - added mixed flow sizes 7 and 9 and added Vari-Green motor to size 33',
      'Roof Exhaust - discontinued the models LBP and TBCRU',
      'Roof Supply fans - added the model AX-Supply',
      'Roof Supply fans - discontinued the models RSFP and TCBRS',
      'Wall Mounted fans - added belt drive model BAER',
      'Wall Mounted fans - updated the AER offering',
      'Most fans - expanded the FEI (Fan Energy Index) to more Vari-Green selections',
      'HVLS - updated the voltage offering on the model DC, sizes 4 thru 7',
      'Louvers - discontinued models ESU-153S and ESU-154S',
      'Louvers - updated max heights on EVH-501',
    ],
  },
  {
    text: 'eCAPS version 3.3.0 - September 21st, 2022',
    notes: [
      'Added FumeJet models FJC and FJI to the Roof Exhaust fan grouping',
      'Moved the Vane Axial models into the Inline grouping',
      'Redesigned the RV-25/RVE-40 to meet new DOE efficiency mandates ',
      'Updated the stainless steel option for blowers',
      'Updated the duct and damper sizes for spun aluminum roof exhaust models G, GB, CUE, and CUBE',
      'Added FEI for some 1hp and larger Vari Green fan selections',
      'Cut sheets are now available on the Product Info slide out from the Schedule screen',
      'Added roof curbs to the Preconditioner and DOAS drawings',
      'Added the DC-5-7-NL to the HVLS products',
    ],
  },
  {
    text: 'eCAPS version 3.2.0 - May 31st, 2022',
    notes: [
      'Roof exhaust fan products - added direct drive upblast model QEID',
      'Discontinued bath fan model SP sizes: 80-VG, 80L-VG, 110-VG, C50, B70, B90',
      'Discontinued double wide blower models BIDW and AFDW',
      'Changed nomenclature for direct drive mixed flow model SQ, added sizes 12 and 15, other enhancements',
      'Added ability to apply VFD on tube axial model AX',
      'Changed nomenclature for wall mounted model AER and added sizes 20 and 24',
      'Discontinued 690 rpm, 7.5 and 10 hp selections for model AER',
      'High volume, low speed fans, added 208 and 230 voltage DC-5 sizes 4, 5, 6, and 7',
      'Discontinued louver models ESS-502D, EAH-401, and EDK-402',
      'Make up air products had control sequence enhancements',
      'Changed nomenclature for energy core model ECV from "FM" to "F"',
      'Added humidity design condition cautions for preconditioner products',
      'Outdoor air unit (DOAS) products can now have 575 volt with PDX inverter duty compressors',
      'Discontinued digital compressors and remote condensers for outdoor air units',
      'Updated damper selection guide',
    ],
  },
  {
    text: 'eCAPS version 3.1.0 - January 24th, 2022',
    notes: [
      'High Volume Low Speed fans model DC, added UV-C light option',
      'Dedicated Outdoor Air Units - Added radiated sound for RV-75 and RVE-150',
      'Preconditioners - Added dynamic Revit LOD-300 content ',
      'Preconditioners - Added polymer core selections',
      'Axial roof mounted fan models, RDU, RE, RS, RCE, and RCS, added more Vari Green (EC) motor selections',
      'Bath fan model SP, added Energy Star, added LP sizes, updated grille',
      'Mixed flow inline fan model QEI, series 100, added aluminum construction and Spark A construction options',
      'Centrifugal Inline model TCB has been discontinued',
      'Louver model EAD-601 has been discontinued',
    ],
  },
  {
    text: 'eCAPS version 3.0.0 - September 21st, 2021',
    notes: [
      'Main screen redesign - easier to find all the products',
      'Fans - Added Spark B construction for upblast roof exhaust models TAUB-CA and TBI-CA',
      'Fans - Discontinued fan models LD (fabra hood direct drive exhaust), LDP (louvered roof direct drive exhaust), LSF (louvered roof supply)',
      'Fans - Removed 277 motor voltage from many fan models',
      'Ceiling/Bath fans - Added ceiling radiation dampers (CRD) to more model SP fan sizes',
      'Blowers - Performance updates (removed class IV USF selections, removed belt drive BCSW-FRP sizes 30 and larger)',
      'DOAS - Added dynamic Revit LOD-200 content',
      'DOAS - Added radiated sound data',
      'Preconditioners - Added single phase VFD selections for ECV-20, 30, 40',
      'Preconditioners - Replaced mixed flow wheels with punker wheels',
      'HVLS - Added CFEI efficiency metric',
      'Louvers - Added model EAD-632W for warehouses',
    ],
  },
  {
    text: 'eCAPS version 2.9.0 - June 1st, 2021',
    notes: [
      'Added Vari-Green (EC) motors for mixed flow inline fan model QEID-100',
      'Added Reversible option for tubular inline fan model AX',
      'Increased max performance for the roof upblast propeller fan model RBUMO',
      'Added 7.5 and 10 hp Vari-Green selections for the wall mounted propeller fan model AER',
      'Discontinued the gravity roof upblast model RGU',
      'Increased max size of louver models EAD-601 and EAD-635',
      'For High Volume, Low Speed fan model DC, added sizes 4, 5, 6, and 7',
      'Added Make-up Air models TSU and TSF',
      'Diesel Generator FUME exhaust user interface layout changes',
      'Added AMCA FEI to FUME exhaust fan models Vektor-CD and MD',
      'Added radiated sound cut-sheet page for select preconditioner models/sizes',
      'Added Revit LOD-200 content for preconditioners',
      'Added Fan Power sizing grid column for preconditioners',
      'Fixed bug with reduced CFM shown on cut-sheet at higher elevations for preconditioners',
      'Updates to the housing size RV-75/RVE-150 for the dedicated outdoor air units',
      'Added RV-10 housing for the dedicated outdoor air units',
      'Added Wheel Material option (polymer and aluminum) for the dedicated outdoor air units',
      'Added optional return air intake positions for the dedicated outdoor air units',
    ],
  },
  {
    text: 'eCAPS version 2.8.0 - February 11th, 2021',
    notes: [
      'Inline fan model SQ now offers mixed flow wheel selections which are more energy efficient',
      'Expanded the Vari-Green (EC) motor offering including more models, sizes, horsepowers, and 3 phase',
      'Inline mixed flow fan model QEID now offers new fan series 100 selections, which are more price competitive. Renamed the existing QEID selections to fan series 300.',
      'Roof exhaust fan models G, GB, CUE, CUBE model name reset (ie. CUBE-141 => CUBE-140)',
      'Fume exhaust model Vektor-H added direct drive selections',
      'Updates to the Seismic Rated option for fans',
      'High volume, low speed (HVLS) fans added air cleaning option',
      'Added 6 inch deep extruded aluminum stationary louver model ESD-635HP',
      'Added Revit content for Louver products',
      'Replaced the outdoor air unit models RV/RVE-70/120 with RV/RVE-75/150',
      'Redesigned the energy recovery model ECV-20/30',
      'Added MERV-13 filters to energy recovery models MiniCores, MiniVents, and ERV-10',
    ],
  },
  {
    text: 'eCAPS version 2.7.1 - November 2nd, 2020',
    notes: [
      'Added Air Source Heat Pump option for Dedicated Outdoor Air Systems',
      'Added support for the Revit add-in second phase',
      'Fixed minor bugs',
    ],
  },
  {
    text: 'eCAPS version 2.7.0 - September 30th, 2020',
    notes: [
      'Added Preconditioner products (energy recovery ventilators)',
      'For fan products, expanded EC motor (Vari-Green) selections to more fan sizes and to include 3-phase in 3 and 5hp sizes',
      'Added Vari-Green selections to hooded roof exhaust fan model RCE3',
      'Added bathroom exhaust fan SP-B110ES with Energy Star',
      'Added cabinet exhaust fan CSP-3300-VG',
      'Discontinued the mixed flow inline fan model EQB',
      'Renamed the blower model IP fan series from 1, 2, 3, 4 to 100, 200, 300, 400',
      'Updated/expanded the Spark Resistant Construction option on many models/sizes',
      'Discontinued wall mounted models SCE3 and SCS3, replaced by AER',
      'For the High volume, low speeds fans (HVLS), added zone info and coatings info to on-screen and cut-sheets',
      'For Louver products, added AMCA 550 (High Velocity Wind Driven Rain) for the Hurricane Louver models ESD-635D and ESD-635DE',
      'Dedicated Outdoor Air Units (DOAS), added sound information and added Revit and CAD content for the larger sizes',
      'Make-up Air products, added sound information, added size P127-MF3, expanded the evaporative cooling option',
    ],
  },
  {
    text: 'eCAPS version 2.6.0 - July 1, 2020',
    notes: [
      'Mixed flow inline fan model QEI nomenclature change.',
      'Discontinued EQB sizes 24 thru 33.',
      'For Blowers, changed default discharge position from top horizontal (TH) to Upblast (UB) for model USF.',
      'For Blowers, added Inlet Box option to select sizes.',
      'For fans, removed the motor enclosure from fans.',
      'For the roof upblast exhaust model CUE, added Vari-Green Drive (VGD) to sizes 180 and 200.',
      'Added 3-phase Vari-Green (VG) motors in 1 and 2 horsepowers.',
      'For High Volume, Low Velocity fans (HVLS); Added air speed zones, visual coating swatches, and added light to model DC.',
      'Dedicated Outdoor Air System (DOAS) - added housing size (RV-110 and RVE-180), which increased capacity.',
      'Make-Up Air (MUA) - added unit weight information and added mixed flow selections to housing size H35.',
    ],
  },
  {
    text: 'eCAPS version 2.5.0 - February 11, 2020',
    notes: [
      'Added Make-up Air products.',
      'New bathroom fan sizes; SP-A390-VG, CSP-A390-VG, SP-A1300, and CSP-A1300.',
      'Whole House Ventilation option for bathroom fan model SP.',
      'Added Ceiling Radiation Damper option for bathroom fan model SP.',
      'New Duct Shape filtering option for inline fans (round vs rectangular duct).',
      'Added Spark B selections for the smaller blowers, model USF direct drive.',
      'Added Revit LOD 200 and LOD 300 content for Blowers.',
      'Discontinued the blower model USF, fan series 400 selections.',
      'DOAS products are DOE (Department of Energy) compliant.',
      'Lowered min air stream temperature limits for diesel generator fume exhaust fans.',
    ],
  },
  {
    text: 'eCAPS version 2.4.0 - November 15, 2019',
    notes: [
      'Addition of Fan Energy Index (FEI) AMCA certification for most fan selections 1hp and larger',
      'Additional bathroom exhaust fans, model SP, sizes SP-AP0511-W and SP-AP0511-WL (lighted)',
      'Renamed and consolidated Blower fan models USFD, USF and CSW to the USF',
      'Expanded the High Wind Rated option to larger roof mounted fan sizes',
      'High Volume Low Speed (HVLS) fan enhancements',
      'Added AMCA circulation fan certification for models DS-3 and DC-5 ',
      'Added 3 new sizes to the model DS-3 (sizes 8, 10, 12)',
      'Added cooling effect calculations',
      'New hurricane rated and wind driven rain louver models, EVH-302D, EHV-550, and  EHV-550D',
    ],
  },
  {
    text: 'eCAPS version 2.3.2 - July 23, 2019',
    notes: ['Application stability and user experience improvements.'],
  },
  {
    text: 'eCAPS version 2.3.1 - July 11, 2019',
    notes: ['Application stability and user experience improvements.'],
  },
  {
    text: 'eCAPS version 2.3.0 - June 26, 2019',
    notes: [
      'For the wall-mounted propeller model AER, added sizes 54 and 60.',
      'Added Fan Efficiency Index (FEI) metric for fans.',
      'Added voltage/phase filtering on the sizing screen for fans.',
      `Expanded the HVLS offering- added 3 blade model DS selections and added model DC,
            which is a commercial-duty, 5-bladed version.`,
      'Added louver model EVH-302, it is a 3-inch vertical wind-driven rain louver.',
      'Added louver EHV-901 to the Wind Driven Rain category.',
      'Expand Revit LOD 200/300 concept to the ceiling fans, wall-mounted fans, and plenum fans.',
      'Renamed and redesigned the blower model IPA to IP.',
    ],
  },
  {
    text: 'eCAPS version 2.2.0 - January 24, 2019',
    notes: [
      'Replaced wall mounted exhaust model CW & CWB with curb mounted CUE & CUBE.',
      'High Volume Low Speed, model DS can now be opened in CAPS.',
      'Added ceiling exhaust and cabinet sizes SP-A700-VG, SP-1050-VG, SP-A700-VG, & SP-1050-VG.',
      'Added blower sizes USFD-104-BI, USFD-106-BI, USFD-108-BI.',
      'Added VGD (Vari-Green Drive) selections for models; G, GB, CUE, CUBE, AER, SE2, SCE3.',
      'Replaced blower model USF-200 with the galvanized option on the model USF-300.',
      'Removed roof supply model LSF sizes 12 thru 22.',
      'Removed fume exhaust model FJC-200.',
      'For the dedicated outdoor air systems (DOAS), added a variable speed compressors for packaged DX cooling.',
      'Expand Revit LOD-300 content to square/rectangular Inline fan models SQ, BSQ, BCF, and BDF.',
    ],
  },
  {
    text: 'eCAPS version 2.1.1 - December 18, 2018',
    notes: ['Application stability and user experience improvements.'],
  },
  {
    text: 'eCAPS version 2.1.0 - December 6, 2018',
    notes: [
      'Added the High Volume, Low Speed circulation fans, model DS.',
      'Tubular inline fans now have Revit content available in LOD 200 and LOD 300.',
      'Allow download of the LOD 200 and LOD 300 Revit content from the Product Info slide out (for the models that support it).',
    ],
  },
  {
    text: 'eCAPS version 2.0.0 - November 1, 2018',
    notes: [
      'New user interface; faster, sleeker, and more animation to keep user involved.',
      'The Cut-sheet is available from the Product Info screen (no need to add product to Schedule first anymore)',
      'Expanded the max number of selections per job from 12 to 24',
      `Dynamic Revit content (Level Of Detail) LOD-200 and LOD 300 is available for Roof Mounted products.
                LOD 300 includes performance and electrical info/parameters for scheduling.`,
      'Added ability to download all the Revit content at once from the Schedule screen.',
      `Replaced Utility Blower models SFD/SFB and SWD/SWB with the models USF
                and USFD 100 series FC (forward curved) and BI (backward inclined) wheels.`,
      'Added axial propeller model AER-E sizes 42 and 48',
      'For DOAS (dedicated outdoor air systems), added 2-D AutoCAD drawings',
      'For DOAS products, added side return option',
      `Expanded roof exhaust model G and CUE to include sizes:
                CUE-200HP, CUE-240, CUE-240HP, CUE-300, CUE-300HP, and G-200HP, G-240, G-300.`,
    ],
  },
  {
    text: 'eCAPS version 1.9.0 - May 17, 2018',
    notes: [
      'Enhanced downloaded schedules to be MS Excel and to include double headers and borders for all products.',
      'Replaced Split DX cooling option with Split DX with a Remote Condenser for Outdoor Air.',
      'Added Schedules for Outdoor-Air units (MS Excel and Notes).',
      'Added the option of Indoor mounting for Outdoor Air products.',
      'Added CAD and RevIt for Outdoor Air products.',
      'Added wall mounted fan model AER 9 and 12 blade selections.',
      'Addition of ceiling exhaust fan model/sizes of SP-A50-90-VG and SP-A90-130-VG bathroom fan.',
      'Added direct drive blower model USFD, single phase has Vari-Green motor while the 3-phase selections are VFD.',
      'Removed the 65, 75, 85 sizes from G, LD, LDP, CUE, CW, SQ selections.',
      'Enabled Diesel Generator Exhaust in the Fume Exhaust group.',
    ],
  },
  {
    text: 'eCAPS version 1.8.1 - March 7, 2018',
    notes: ['Minor bug fixes and user experience improvements.'],
  },
  {
    text: 'eCAPS version 1.8.0 - February 14, 2018',
    notes: [
      `Addition of the Outdoor Air units, which includes Dedicated Outdoor Air Systems. Selections are simple, fast,
                and intuitive. A schematic adjusts to the user selections, which helps visualize the various components of the unit.
            A handful of the best selections with capacities, weights, and MCA/MOP's are available.`,
      'Addition of the new inline model EQD, it is a mixed flow direct drive inline fan with a Vari-Green (ECM) motor.',
      'Addition of the new wall mounted axial model AER, it is a direct drive aluminum axial wall mounted fan.',
    ],
  },
  {
    text: 'eCAPS version 1.7.0 - September 14, 2017',
    notes: [
      'New plenum fan, model APF - direct drive with axial flux motor',
      'Redesigned the Fume Exhaust products selection process to be application based',
      'Addition of FumeJet models to Fume Exhaust, models FJC and FJI',
      'Synced performance, pricing, and weights with CAPS v4.24',
    ],
  },
  {
    text: 'eCAPS version 1.6.1 - June 27, 2017',
    notes: [
      'Clarified terminology related to fan accessories with pressure drops.',
      'Changed the cut-sheet drawings to use renderings for fans and gravity products.',
      'Enhanced wall mounted fan cut-sheets to include wall housing and weatherhood drawings/dimensions when selected.',
      'Added fan inlet and outlet connection and roof/wall opening dimensions to cut-sheets.',
      'Added the ability to specify square louver sizing.',
      'Allow louver and gravity products to be imported from eCAPS to CAPS.',
      'Louver budget pricing now adjusts for some options.',
      'Discontinued the following Louvers models; ESJ-401RM, ESJ-602RM, ESK-402RM, ESK-602, ESK-602RM, EVH-401, SES-401.',
    ],
  },
  {
    text: 'eCAPS version 1.6.0 - May 1, 2017',
    notes: [
      'Synced performance with CAPS v4.23',
      'Added Fan Efficiency & Energy Regulations video',
      'Allow more fan selections to be imported to CAPS',
      'Minor bug fixes',
    ],
  },
  {
    text: 'eCAPS version 1.5.1 - March 13, 2017',
    notes: [
      `Added Spark Resistance Construction for most fan groupings. You can pick Spark A, B, or C under the Special
                Requirements accordion.`,
      'For Inline fans, we added a "For Outdoor Use" option to help you determine the models that can be used for outdoor use.',
      'For Inline fans, added a "Fan Material" option to guide you towards the models with various construction materials.',
      'For Inline fans, added a "Mounting Orientation" option. Some fans require special construction for vertical mounting.',
      'Enhanced fan curves. We added symbols and better scaling to make them easier to read.',
      'We save your modified grid columns (visibility and order) from your last selection.',
      'Renamed the Submittal to Cut Sheet since most engineers expect submittals to come from the local rep and/or contractor.',
    ],
  },
  {
    text: 'eCAPS version 1.5.0 - February 13, 2017',
    notes: [
      `Changed schedule screen to look more like a typical schedule. Also includes custom notes/remarks for fans,
                louvers, and gravity products.`,
      `Enhanced blower submittal drawings to represent the actual configuration (wheel rotation, discharge position,
                and arrangement).`,
      'Added a new direct drive plenum fan, model APD.',
      'Added FEG (Fan Efficiency Grade) optional grid column.',
      'Enhanced Louver selection sizing for better selections and can specify specific sizing increments of 2, 4, 6, or 8 inches.',
      'Updated the Thin Line Louver model offering (better models at lower price).',
      'Added detailed CAD drawings for all Louver models',
      'Added a Control damper selection for blowers.',
    ],
  },
  {
    text: 'eCAPS version 1.4.0 – November 1, 2016',
    notes: [
      'Added Louver products. eCAPS ranks 75 different models and guides you towards the best value louver for your application.',
      'Added new low profile ceiling exhaust sizes SP-L50 and SP-L80. As low as 3.5 inches.',
      'We added fan efficiency information, such as Peak TE, Operating TE, and Points within Peak TE.',
      'Extended the time out duration.',
      'Your selections are remembered from your last session (for a limited time).',
    ],
  },
  {
    text: 'eCAPS version 1.3.2 – September 26, 2016',
    notes: [
      'Schedule screen user interface adjustments to better represent a schedule (added lines, adjust columns, and title).',
    ],
  },
  {
    text: 'eCAPS version 1.3.1 – August 18, 2016',
    notes: [
      'Enhanced the process of adding items to the schedule to be more intuitive.',
      'Enhanced the fan schedules notes/remarks. You can quickly add notes/remarks to your custom schedule, saving you time.',
      'Added another caution icon for fan selections. We now caution the selection when the bhp is within 10% of the max motor size.',
    ],
  },
  {
    text: 'eCAPS version 1.3.0 – June 1, 2016',
    notes: [
      'Addition of the Gravity Ventilator products (9 models).',
      'Navigation enhancements (Easier to find and download submittals and schedules)',
      'Added Backdraft Damper pressure drop for Blower fan models.',
      'Addition of Inlet and Outlet Guards performance corrections for Blowers.',
    ],
  },
  {
    text: 'eCAPS version 1.2.0 – January 28, 2016',
    notes: [
      'Addition of the Fume Exhaust products (five Vektor models).',
      'Added the model CSP (inline ceiling exhaust cabinet fan) to the Inline grouping.',
      'Addition of the Full Load Amps to the Submittal and Customized Schedule.',
      'Enhanced the performance of the model SP (Ceiling Exhaust fan) for new Energy Star requirements.',
      'Updated models SP and CSP energy consumption (watts), so it reflects the actual operating point’s wattage.',
    ],
  },
  {
    text: 'eCAPS version 1.1.0 – September 22, 2015',
    notes: [
      'Additional schedule information, such as Remarks, Location, and Area Served',
      'Removed model AX & QEI selections that had similar performance - only return best selections now',
      'Added ability to view the submittal on an iPad',
    ],
  },
  {
    text: 'eCAPS version 1.0.3 - August 17, 2015',
    notes: [
      'Added Backdraft Damper pressure drop for Inline fan models.',
      'New Quick Delivery Catalog Toolbox item for quick access to products that can be shipped fast.',
      'Allow user based ranking - user can quickly change from Greenheck ranking to Price, Efficiency, or Sound.',
      'Some minor budget price adjustments.',
    ],
  },
  {
    text: 'eCAPS version 1.0.2 - June 24, 2015',
    notes: [
      'Added Backdraft Damper pressure drop to Roof Supply fans.',
      'Added Wall Housing, Weatherhood, and Damper pressures drops to Wall Mounted fans.',
      'Tightened volume (cfm) tolerances to provide better selections.',
    ],
  },
  {
    text: 'eCAPS version 1.0.1 - June 4, 2015',
    notes: [
      'Added weights for many of the blower models',
      'Added ability to reselect/change performance of a saved selection',
    ],
  },
  {
    text: 'eCAPS version 1.0.0 - April 2015',
    notes: [
      'Initial release',
      'Added ability to open an eCAPS job in CAPS',
      'Added the Vane Axial products',
      'Added the Plenum products',
    ],
  },
  {
    text: 'eCAPS version 0.9.0 - November 2014',
    notes: ['Beta release'],
  },
];
