<h2 mat-dialog-title>Webservice URL</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup">
    <mat-radio-group formControlName="useCustom" (change)="useCustomClick()">
      <div class="row">
        <mat-radio-button color="primary" [value]="false"
          >Environment:</mat-radio-button
        >
        <mat-select formControlName="env">
          <mat-option *ngFor="let item of serviceList" [value]="item.value">{{
            item.name
          }}</mat-option>
        </mat-select>
      </div>
      <div class="row">
        <mat-radio-button color="primary" [value]="true"
          >Custom:</mat-radio-button
        >
        <mat-form-field>
          <mat-label>Webservice URL</mat-label>
          <input
            matInput
            type="url"
            formControlName="customUrl"
            autocomplete="off"
          />
          <mat-error *ngIf="useCustom.value">Required</mat-error>
        </mat-form-field>
      </div>
    </mat-radio-group>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="saveClick()">Save</button>
  <button mat-raised-button color="primary" (click)="cancelClick()">
    Cancel
  </button>
</mat-dialog-actions>
