<ng-container [ngSwitch]="showUnavailable">
  <div *ngSwitchCase="true" class="unavailable">
    <mat-icon color="primary">info_outline</mat-icon>
    <span>This chart is currently unavailable</span>
  </div>
  <ng-container *ngSwitchDefault>
    <div class="chart-type-parent" [class.show-elevation]="showElevation">
      <ng-content select="[chart-type]"></ng-content>
    </div>
    <div class="legend-parent" [class.show-elevation]="showElevation">
      <div #chartLegend></div>
    </div>
    <div class="sp-table-parent" [class.showElevation]="showElevation">
      <ng-content selector="[sp-table]"></ng-content>
    </div>
    <div class="chart-parent" [class.show-elevation]="showElevation">
      <div #chartContainer [style.margin-top.px]="chartSpacing"></div>
    </div>
  </ng-container>
</ng-container>
