<h2 mat-dialog-title>Water Rejection Rating Help</h2>

<mat-dialog-content>
  <span class="heading-text">
    This Water Rejection Rating was developed by Greenheck to aid in determining
    how well each model keeps water out of the building. The rating is not AMCA
    certified.
  </span>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef>Rating</th>
      <td mat-cell *matCellDef="let element">{{ element.rating }}</td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="iconColor">
      <th mat-header-cell *matHeaderCellDef>Icon Color</th>
      <td mat-cell *matCellDef="let element">
        <div class="layout">
          <span>{{ element.iconColor }}</span>

          <mat-icon
            svgIcon="droplet"
            [class]="
              element.iconColor.toLowerCase() + ' db24 mat-icon material-icons'
            "
          ></mat-icon>
        </div>
      </td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="['rating', 'description', 'iconColor']"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['rating', 'description', 'iconColor']"
    ></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="closeClick()" color="primary">
    Close
  </button>
</mat-dialog-actions>
