import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpItemDialogComponent } from '../dialogs/help-item-dialog/help-item-dialog.component';
import { HelpService } from '../services/help.service';
import { HelpTypes } from '../services/models/help-types.enum';
import { IHelpItem } from '../services/models/interfaces/i-help-item.interface';

@Injectable({
  providedIn: 'root',
})
export class HelpItemDialog {
  constructor(private dialog: MatDialog) {}

  show(helpItem: IHelpItem, helpService: HelpService): Promise<void> {
    return new Promise<void>((result) => {
      let dialogRef: any = null;

      if (typeof helpItem.messageBox.content === 'string') {
        dialogRef = this.dialog.open(HelpItemDialogComponent, {
          disableClose: false,
          autoFocus: true,
          data: {
            helpItem: helpItem,
            helpService: helpService,
          },
          panelClass: 'help-item-dialog',
        });
      } else {
        dialogRef = this.dialog.open(helpItem.messageBox.content, {
          disableClose: false,
          autoFocus: true,
          data: {
            helpItem: helpItem,
            helpService: helpService,
          },
          panelClass: 'help-item-dialog',
        });
      }

      const subscription = dialogRef
        .afterClosed()
        .subscribe((showSideDialog) => {
          subscription.unsubscribe();

          if (showSideDialog) {
            helpService.showHelp(helpItem, HelpTypes.sideDialog).then(result);
          } else {
            result();
          }
        });
    });
  }
}
