import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../users/services/users.service';
import { Subscription } from 'rxjs';
import { UserData } from '../../users/models/user-data.model';
import { Router } from '@angular/router';
import { SideBarService } from '../../side-bar/services/side-bar.service';
import { MenuItemList } from '../../side-bar/services/constants/menu-item-list.const';

@Component({
  selector: 'app-job-management-page',
  templateUrl: './job-management-page.component.html',
  styleUrls: ['./job-management-page.component.scss'],
})
export class JobManagementPageComponent implements OnInit, OnDestroy {
  private currentUser: UserData;
  private userLoginSubscription: Subscription;

  constructor(
    private sidebarService: SideBarService,
    private menuItemList: MenuItemList,
    private users: UsersService,
    private router: Router
  ) {
    this.users.getCurrentUser().then((userData) => {
      this.currentUser = userData;

      if (!this.currentUser || !this.currentUser.authenticated) {
        this.router.navigateByUrl('/');
      }
    });

    this.userLoginSubscription = this.users.currentUserUpdated.subscribe(
      (newUserData) => {
        this.currentUser = newUserData;

        if (!this.currentUser || !this.currentUser.authenticated) {
          this.router.navigateByUrl('/');
        }
      }
    );
  }

  ngOnInit() {
    this.sidebarService.setSidebarItems([
      {
        menuItems: [
          this.menuItemList.equipmentSchedule,
          this.menuItemList.newJob,
        ],
      },
    ]);
  }

  ngOnDestroy() {
    this.userLoginSubscription.unsubscribe();
  }
}
