import { DecimalPipe, PercentPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml, SafeUrl } from '@angular/platform-browser';
import { sprintf } from 'sprintf-js';

@Pipe({
  name: 'attrFilter',
})
export class AttributeFilterPipe implements PipeTransform {
  transform(
    items: any[],
    attributes: Record<
      string,
      any | ((value: any, index: number, array: any[]) => boolean)
    >
  ): any[] {
    if (!items) {
      return [];
    }

    if (!attributes) {
      return items;
    }

    const keys = Object.keys(attributes);

    if (keys.length === 0) {
      return items;
    }

    return items.filter((singleItem, index, list) => {
      for (const key of keys) {
        if (typeof attributes[key] === 'function') {
          return attributes[key](singleItem[key], index, list);
        } else if (singleItem[key] !== attributes[key]) {
          return false;
        }
      }

      return true;
    });
  }
}

@Pipe({
  name: 'zeroToDash',
})
export class ZeroToDashPipe implements PipeTransform {
  transform(value?: any, digits?: string, uomToDisplay?: string): any {
    if (
      value === null ||
      value === undefined ||
      isNaN(value) ||
      value.toString() === '0' ||
      value.toString().trim() === 'NaN'
    ) {
      return '-';
    } else {
      if (typeof uomToDisplay === 'undefined') {
        uomToDisplay = '';
      }

      let formattedVal;

      !!digits
        ? (formattedVal = new DecimalPipe('en_us').transform(value, digits))
        : (formattedVal = value);

      return formattedVal + uomToDisplay;
    }
  }
}

@Pipe({
  name: 'camelCase',
})
export class CamelCasePipe implements PipeTransform {
  transform(value: string): string {
    if (value && value === value.toUpperCase() && value.indexOf('_') === -1) {
      return value.toLowerCase();
    } else {
      let parts = new Array<string>();

      if (value.indexOf('_') > -1) {
        parts = value.split(/_/g);
      } else {
        parts.push(value);
      }

      for (let i = 0; i < parts.length; i++) {
        if (parts[i] === parts[i].toUpperCase()) {
          parts[i] = parts[i].toLowerCase();
        } else {
          let length = 1;

          for (let k = 1; k < parts[i].length; k++) {
            if (parts[i][k] !== parts[i][k].toUpperCase()) {
              length = k - 1;

              break;
            }
          }

          length = length < 1 ? 1 : length;

          parts[i] =
            parts[i].substr(0, length).toLowerCase() + parts[i].substr(length);
        }
      }

      let output = parts[0];

      for (let i = 1; i < parts.length; i++) {
        output += parts[i][0].toUpperCase() + parts[i].substr(1);
      }

      return output;
    }
  }
}

@Pipe({
  name: 'fraction',
})
export class FractionPipe implements PipeTransform {
  transform(value?: number): string {
    if (value && !isNaN(value)) {
      value = parseFloat(value.toFixed(3));

      if (value.toString().indexOf('.') === -1) {
        return value.toString();
      }

      const numParts = value.toString().split('.');

      const wholeNumber = new DecimalPipe('en_us').transform(
        parseFloat(numParts[0]),
        '1.0'
      );

      let num = parseFloat(numParts[1]);
      let denom = Math.pow(10, numParts[1].length);

      if (num === 333) {
        return wholeNumber + ' 1/3';
      } else if (num === 167) {
        return wholeNumber + ' 1/6';
      } else if (num === 143) {
        return wholeNumber + ' 1/7';
      } else if (num === 111) {
        return wholeNumber + ' 1/9';
      }

      let mult = num;

      while ((num % mult !== 0 || denom % mult !== 0) && mult > 0) {
        mult--;
      }

      if (mult > 0) {
        num /= mult;
        denom /= mult;
      }

      return (
        (wholeNumber !== '0' ? wholeNumber + ' ' : '') +
        num.toString() +
        '/' +
        denom.toString()
      );
    } else {
      return '-';
    }
  }
}

@Pipe({
  name: 'hemisphericalSones',
})
export class HemisphericalSonesPipe implements PipeTransform {
  transform(value?: number): string {
    if (value !== null && value !== undefined && !isNaN(value)) {
      const decimalPipe = new DecimalPipe('en_us');

      if (value === 0) {
        return '-';
      } else if (value <= 0.3) {
        return '<0.3';
      } else if (value < 20) {
        return decimalPipe.transform(value, '1.1-1');
      } else {
        return decimalPipe.transform(value, '1.0-0');
      }
    } else {
      const zeroDashPipe = new ZeroToDashPipe();
      zeroDashPipe.transform(value, '1-0-0');

      return zeroDashPipe.transform(value, '1-0-0');
    }
  }
}

@Pipe({
  name: 'sphericalSones',
})
export class SphericalSonesPipe implements PipeTransform {
  transform(value?: number): string {
    if (value !== null && value !== undefined) {
      const decimalPipe = new DecimalPipe('en_us');

      if (value === 0) {
        return '-';
      } else if (value <= 0.3) {
        return '<0.3';
      } else if (value > 0.3 && value <= 1.5) {
        return decimalPipe.transform(value, '1.1-1');
      } else if (value > 1.5) {
        let a, b;

        const items = value.toFixed(1).toString().split('.');

        a = parseInt(items[0], 10);

        if (items.length === 2) {
          b = parseInt(items[1], 10);
        } else {
          b = 0;
        }

        if (b < 3) {
          b = 0;
        } else if (b >= 3 && b < 8) {
          b = 5;
        } else if (b >= 8) {
          a++;
          b = 0;
        }

        return decimalPipe.transform(a, '1.0-0') + '.' + b;
      }
    } else {
      return null;
    }
  }
}

@Pipe({
  name: 'breakHorsePower',
})
export class BreakHorsePowerPipe implements PipeTransform {
  transform(value?: number): string {
    if (value !== null && value !== undefined) {
      const zeroDashPipe = new ZeroToDashPipe();

      if (value < 2) {
        return zeroDashPipe.transform(value, '1.1-2');
      } else if (value >= 2 && value < 30) {
        return zeroDashPipe.transform(value, '1.1-1');
      } else {
        return zeroDashPipe.transform(value, '1.0-0');
      }
    } else {
      return null;
    }
  }
}

@Pipe({
  name: 'emptyStringToDash',
})
export class EmptyStringToDashPipe implements PipeTransform {
  transform(value: any): any {
    if (
      value === null ||
      value === undefined ||
      value.toString().trim() === 'NaN' ||
      value.toString().trim() === ''
    ) {
      return '-';
    } else {
      return value;
    }
  }
}

@Pipe({
  name: 'formatObject',
})
export class FormatObjectPipe implements PipeTransform {
  transform(value: any) {
    const isNameValuePair = function (data) {
      if (data === null || data === undefined) {
        return false;
      } else if (
        Array.isArray(data) &&
        data.length > 0 &&
        isNameValuePair(data[0])
      ) {
        return true;
      } else if (typeof data === 'object') {
        const keys = Object.keys(data);

        if (!keys.find((key) => key.toLowerCase().trim() === 'name')) {
          return false;
        }

        if (!keys.find((key) => key.toLowerCase().trim() === 'value')) {
          return false;
        }

        return true;
      } else {
        return false;
      }
    };

    const isComplexObject = function (data) {
      if (typeof data === 'object' && data !== null && !Array.isArray(data)) {
        const keys = Object.keys(data);

        if (
          keys.length === 2 &&
          keys.find((key) => key.toLowerCase().trim() === 'properties') &&
          keys.find((key) => key.toLowerCase().trim() === '__type')
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const camelCase = new CamelCasePipe();
    const formatObject = new FormatObjectPipe();

    if (typeof value === 'undefined' || value === null || value === '') {
      return value;
    } else if (Array.isArray(value)) {
      if (isNameValuePair(value)) {
        const output = {};

        for (let i = 0; i < value.length; i++) {
          output[camelCase.transform(value[i].name)] = formatObject.transform(
            value[i].value
          );
        }

        return output;
      } else {
        for (let i = 0; i < value.length; i++) {
          value[i] = formatObject.transform(value[i]);
        }

        return value;
      }
    } else if (typeof value === 'object') {
      if (isComplexObject(value)) {
        const output = {};

        for (let i = 0; i < value['properties'].length; i++) {
          if (value['properties'][i].name.toLowerCase().trim() !== 'name') {
            output[camelCase.transform(value['properties'][i].name)] =
              formatObject.transform(value['properties'][i].value);
          }
        }

        return output;
      } else {
        const output = {};

        for (const key of Object.keys(value)) {
          output[camelCase.transform(key)] = formatObject.transform(value[key]);
        }

        return output;
      }
    } else {
      if (typeof value === 'string') {
        if (value.toLowerCase().trim() === 'null') {
          return null;
        } else if (value.toLowerCase().trim() === 'false') {
          return false;
        } else if (value.toLowerCase().trim() === 'true') {
          return true;
        } else if (!isNaN(<any>value)) {
          return parseFloat(value);
        } else {
          return value;
        }
      } else {
        return value;
      }
    }
  }
}

@Pipe({
  name: 'boolToYesNo',
})
export class BoolToYesNoPipe implements PipeTransform {
  transform(value: boolean) {
    if (typeof value !== 'boolean') {
      return '-';
    } else if (value === true) {
      return 'Yes';
    } else {
      return 'No';
    }
  }
}

@Pipe({
  name: 'displayMask',
})
export class DisplayMaskPipe implements PipeTransform {
  transform(value: number, mask?: string) {
    if (!mask) {
      return value;
    } else {
      return sprintf(mask, value);
    }
  }
}

@Pipe({
  name: 'maxThroatVelocity',
})
export class MaxThroatVelocityPipe implements PipeTransform {
  transform(value: number, digits?: string) {
    if (!value || value < 1) {
      return '-';
    } else {
      return !!digits
        ? new DecimalPipe('en_us').transform(value, digits)
        : value;
    }
  }
}

@Pipe({
  name: 'percentZeroToDash',
})
export class PercentZeroToDash implements PipeTransform {
  transform(value?: any, digits?: string): any {
    if (
      value === null ||
      value === undefined ||
      isNaN(value) ||
      value.toString() === '0' ||
      value.toString().toLowerCase().trim() === 'nan'
    ) {
      return '-';
    } else {
      return !!digits
        ? new PercentPipe('en_us').transform(value, digits)
        : value;
    }
  }
}

@Pipe({
  name: 'camelToDisplay',
})
export class CamelToDisplayPipe implements PipeTransform {
  transform(value: string): string {
    if (value === null || value === undefined) {
      return '-';
    } else {
      const stringArray = value.split(/(?=[A-Z])/);
      let displayStr = '';

      for (let i = 0; i < stringArray.length; i++) {
        // Uppercase the first letter of each portion of the string.
        stringArray[i] = stringArray[i].replace(/^\w/, (c) => c.toUpperCase());
      }

      displayStr = stringArray.join(' ');

      return displayStr;
    }
  }
}

@Pipe({
  name: 'fieldErrorToText',
  pure: false,
})
export class FieldErrorToTextPipe implements PipeTransform {
  transform(field: UntypedFormControl) {
    const decimalPipe = new DecimalPipe('en_us');

    if (!!field.errors) {
      if (field.errors.required) {
        return 'Required';
      } else if (field.errors.min) {
        return `Must be greater than or equal to ${decimalPipe.transform(
          field.errors.min.min
        )}`;
      } else if (field.errors.max) {
        return `Must be less than or equal to ${decimalPipe.transform(
          field.errors.max.max
        )}`;
      } else if (field.errors.email) {
        return 'Invalid email';
      } else if (field.errors.errorMessage) {
        return field.errors.errorMessage;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'safeHTML',
})
export class SafeHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: 'safeURL',
})
export class SafeURLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
