<mat-accordion>
  <mat-expansion-panel
    #panel
    *ngFor="let group of accordionGroups; let i = index"
    [expanded]="i === 0"
    [class.show-warn]="showWarning(group)"
    (opened)="accordionClicked()"
    (closed)="accordionClicked()"
    [@.disabled]="chromeVersion > -1 && chromeVersion < 88"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <mat-icon color="warn" matTooltip="Invalid question values"
          >warning</mat-icon
        >
        <span>{{ group.text }}</span>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container *ngFor="let question of group.questions">
      <configuration-question
        [question]="question"
        *ngIf="question.visible"
        [layout]="layoutConfig"
        [autoFocus]="setAutoFocus(question)"
        (locationUpdated)="locationUpdatedEvent($event)"
        (answered)="questionAnswered()"
        (validChanged)="validChanged(question, $event)"
      ></configuration-question>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
