/** Mark = 2, Product = 0, Selector = 1
 ** Numeric values must match StateLevels enum for state export in eCAPS project service
 */
export enum RevitStateLevelsInt {
    mark = 2,
    product = 0,
    selector = 1
  }
  
  export enum RevitStateLevels {
    mark = "mark",
    product = "product",
    selector = "selector"
  }
