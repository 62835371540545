import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../ecaps-core/services/auth.service';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { RevitCommsService } from '../../external-communication/services/revit-comms.service';

@Component({
  selector: 'app-login-page',
  template: ` <div></div> `,
})
export class LoginPageComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private core: EcapsCore,
    private revitComms: RevitCommsService
  ) {}

  ngOnInit(): void {
    this.core.showLoadingGraphic('Authenticating...', () => true);

    this.authService.validateUser().then(
      (userData) => {
        if (userData.authenticated) {
          this.revitComms.authenticated();
        } else {
          this.authService.login();
        }
      },
      (errorData) => {
        console.error(errorData);

        this.authService.login();
      }
    );
  }
}
