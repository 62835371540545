import { IEmail } from './interfaces/i-email.interface';
import { IDrawing } from './interfaces/i-drawing';

export class DrawingRequest implements IEmail {

    toAddresses: string[];

    subject?: string;

    content: {

        toName: string;

        fromName: string;

        sizeName: string;

        drawings: IDrawing[];

        message: string;

    };

    constructor() {

        this.toAddresses = [];

        this.content = {

            toName: undefined,

            fromName: undefined,

            sizeName: undefined,

            drawings: [],

            message: undefined

        };

    }

}
