export class Item {
  areaServed: string;
  budgetPrice: number;
  engineeringData: string;
  id: string;
  location: string;
  name: string;
  quantity: number;
  tag: string;
  type: string;
  constructor(data: any) {
    this.areaServed = data.areaServed;
    this.budgetPrice = data.budgetPrice;
    this.engineeringData = data.engineeringData;
    this.id = data.id;
    this.location = data.location;
    this.name = data.name;
    this.quantity = data.quantity;
    this.tag = data.tag;
    this.type = data.type;
  }
}
