import { Component, OnInit } from '@angular/core';
import { SideDialogComponent } from '../../../side-dialog/classes/side-dialog-component.interface';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { ISizeChartData } from './models/i-size-chart-data.interface';

@Component({
  selector: 'app-size-chart-dialog',
  templateUrl: './size-chart-dialog.component.html',
  styleUrls: ['./size-chart-dialog.component.scss'],
})
export class SizeChartDialogComponent implements OnInit, SideDialogComponent {
  dialogData: ISizeChartData;

  constructor(private sideDialogService: SideDialogService) {}

  ngOnInit() {}

  closeClick() {
    this.sideDialogService.close();
  }
}
