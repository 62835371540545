import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidSize } from '../../../selections/models/selection-results/valid-size.model';
import { ChartTypes } from '../../charting/enums/chart-types.enum';
import { SizeChartDialog } from '../../controllers/size-chart-dialog.controller';

@Component({
  selector: 'app-valid-size-warning-dialog',
  templateUrl: './valid-size-warning-dialog.component.html',
  styleUrls: ['./valid-size-warning-dialog.component.scss'],
})
export class ValidSizeWarningDialogComponent implements OnInit {
  size: ValidSize;

  constructor(
    private dialogRef: MatDialogRef<ValidSizeWarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ValidSize,
    private sizeChartDialog: SizeChartDialog
  ) {
    this.size = this.data;
  }

  ngOnInit() {}

  showFanCurvesButton() {
    if (
      this.size.stability.stabilityMessages &&
      this.size.stability.stabilityMessages.length > 0
    ) {
      if (
        this.size.stability.stabilityMessages.filter(
          (message) =>
            message.toLowerCase().indexOf('speed') > -1 ||
            message.toLowerCase().indexOf('curve') > -1
        ).length > 0
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  showFanCurves() {
    let chartType = ChartTypes.operating;

    if (
      this.size.stability.stabilityMessages.length > -1 &&
      this.size.stability.stabilityMessages[0].indexOf(
        "within 5% of fan's maximum RPM"
      ) > -1
    ) {
      chartType = ChartTypes.minMax;
    }

    this.sizeChartDialog.show(this.size, chartType);

    this.dialogRef.close();
  }

  okClick() {
    this.dialogRef.close();
  }
}
