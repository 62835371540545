import { Component, OnInit } from '@angular/core';
import { LocationUpdate } from '../../ecaps-core/dialogs/elevation-dialog/models/location-update.model';
import { LayoutConfiguration } from '../../selections/models/layout-config/layout-configuration.model';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { LayoutService } from '../../selections/services/layout.service';
import { ProjectsService } from '../../projects/services/projects.service';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { SideBarService } from '../../side-bar/services/side-bar.service';
import { MenuItemList } from '../../side-bar/services/constants/menu-item-list.const';
import { ProductTypes } from '../../products/enums/product-types.enum';
import { BreadcrumbService, ICrumb } from '../../breadcrumb/breadcrumb.service';
import { Router } from '@angular/router';
import {
  ProductGroup,
  ProductsService,
} from '../../products/services/products.service';

@Component({
  selector: 'app-selection-page',
  templateUrl: './selection-page.component.html',
  styleUrls: ['./selection-page.component.scss'],
})
export class SelectionPageComponent implements OnInit {
  layoutConfig: LayoutConfiguration;

  reselectionSize: ValidSize;

  constructor(
    private layout: LayoutService,
    private sidebarService: SideBarService,
    private menuItemList: MenuItemList,
    private projectsService: ProjectsService,
    private core: EcapsCore,
    private breadcrumbs: BreadcrumbService,
    private router: Router,
    private products: ProductsService
  ) {
    layout.getLastLayoutConfig().then((layoutConfig) => {
      this.layoutConfig = layoutConfig;

      this.setCrumbs();

      this.reselectionSize = this.layout.getReselectionSize();

      this.addSidebarIcons();
    });
  }

  ngOnInit() {
    this.addSidebarIcons();
  }

  private addSidebarIcons() {
    const menuItems = [
      {
        menuItems: [
          this.menuItemList.equipmentSchedule,
          this.menuItemList.jobs,
        ],
      },
    ];

    const group = {
      menuItems: [this.menuItemList.newSelection],
    };

    if (!!this.layoutConfig) {
      if (this.layoutConfig.productType === ProductTypes.fan) {
        group.menuItems.push(this.menuItemList.ses);
      }
    }

    menuItems.push(group);

    this.sidebarService.setSidebarItems(menuItems);
  }

  locationUpdated($event: LocationUpdate) {
    this.projectsService.getLocalProject().then((project) => {
      const updates = {};

      if (project && !project.elevation) {
        updates['Elevation'] = $event.elevation;
      }

      if (project && !project.location) {
        updates['Location'] = $event.address;
      }

      if (Object.keys(updates).length > 0) {
        let updating = true;

        this.core.showLoadingGraphic('Updating...', function () {
          return updating;
        });

        this.projectsService.updateProject(project, updates).then(() => {
          updating = false;

          this.core.hideLoadingGraphic();
        });
      }
    });
  }

  private setCrumbs(): void {
    let category: ProductGroup;
    let modelGroup = this.layoutConfig.modelGroup;

    console.debug(
      '[SelectionPageComponent] setCrumbs(): %s %o',
      modelGroup,
      this.layoutConfig
    );

    if (modelGroup === 'MakeUpAir') {
      const heatingType = this.layoutConfig.group.getQuestion('HeatingType');

      if (!!heatingType) {
        modelGroup = heatingType.value;
      }
    }

    if (modelGroup === 'Preconditioner') {
      const unitMounting = this.layoutConfig.group.getQuestion('UnitMounting');

      if (!!unitMounting) {
        modelGroup = unitMounting.value;
      }
    }
    //TODO
    // if (modelGroup === 'Circulator') {
    //   const unitMounting = this.layoutConfig.group.getQuestion('UnitMounting');

    //   if (!!unitMounting) {
    //     modelGroup = unitMounting.value;
    //   }
    // }

    this.products.productGroups.forEach((group) => {
      if (!category && !!group.children) {
        const subSearch = (parentCategory: ProductGroup) => {
          if (parentCategory.name === modelGroup) {
            category = parentCategory;
          } else if (!!parentCategory.children) {
            parentCategory.children.forEach((subCat) => {
              subSearch(subCat);
            });
          }
        };

        group.children.forEach((cat) => {
          subSearch(cat);
        });
      }
    });

    const crumbs: ICrumb[] = [];

    const getParentPath = (groupItem: ProductGroup) => {
      if (!!groupItem.parent) {
        getParentPath(groupItem.parent);
      }

      crumbs.push({
        text: groupItem.text,
        route: () => {
          if (
            groupItem === category &&
            (!category.children || category.children.length === 0)
          ) {
            // Do Nothing
          } else {
            this.router.navigate([`group/${groupItem.name}`]);
          }
        },
      });
    };

    getParentPath(category);
    if (modelGroup === 'Circulator') {
      const selectionMethod =
        this.layoutConfig.group.getQuestion('SelectionMethod');

      if (selectionMethod.value === 'ByPerformance') {
        crumbs.push({
          text: 'Performance-Based Selection',
          route: () => {},
        });
      } else if (selectionMethod.value === 'ByFanSize') {
        crumbs.push({
          text: 'Size-Based Selection',
          route: () => {},
        });
      }
    }
    if (modelGroup === 'DestratFans') {
      const selectionMethod =
        this.layoutConfig.group.getQuestion('SelectionMethod');

      if (selectionMethod.value === 'ByPerformance') {
        crumbs.push({
          text: 'Performance-Based Selection',
          route: () => {},
        });
      } else if (selectionMethod.value === 'ByFanSize') {
        crumbs.push({
          text: 'Size-Based Selection',
          route: () => {},
        });
      }
    } else if (modelGroup === 'FumeExhaust') {
      const selectionMethod = this.layoutConfig.group.getQuestion(
        'FumeExhaustApplication'
      );

      if (selectionMethod.value === 'Laboratory') {
        crumbs.push({
          text: 'Laboratory/Pharmacy',
          route: () => {},
        });
      } else if (selectionMethod.value === 'Wastewater') {
        crumbs.push({
          text: 'Wastewater Treatment',
          route: () => {},
        });
      } else if (selectionMethod.value === 'DieselGenerator') {
        crumbs.push({
          text: 'Diesel Generator',
          route: () => {},
        });
      } else if (selectionMethod.value === 'IsolationRoom') {
        crumbs.push({
          text: 'Isolation Room',
          route: () => {},
        });
      } else if (selectionMethod.value === 'ManufacturingProcess') {
        crumbs.push({
          text: 'Manufacturing Process',
          route: () => {},
        });
      } else if (selectionMethod.value === 'AmmoniaRefrigeration') {
        crumbs.push({
          text: 'Ammonia Refrigeration',
          route: () => {},
        });
      }
    }

    this.breadcrumbs.crumbs = crumbs;
  }
}
