import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsService } from './services/google-maps.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    GoogleMapsService,
    GoogleAnalyticsService
  ]
})
export class GoogleModule { }
