import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { ColumnList } from '../constants/column-list.const';
import { ProductTypes } from '../../../../products/enums/product-types.enum';

@Component({
  selector: 'circulator-equipment-schedule-grid',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CirculatorEquipmentScheduleGridComponent
  extends BaseGridComponent
  implements OnInit
{
  constructor(protected injector: Injector) {
    super(injector);

    this.productType = ProductTypes.circulator;
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'quantity',
      'location',
      'areaServed',
      'model',
      'fanQuantity',
      'operatingVolume',
      'averageAirSpeed',
      'avgAirSpeedMax',
      'operatingFanRPM',
      'motorSize',
      'motorSizeHP',
      'vcp',
      'outletDba',
      'motorFLACalc',
      'dropLength',
      'weight',
      'maxRpm',
      'affectedArea',
      'watts',
      'standbyPower',
      'fanEfficiency',
      'minFanSpacing',
      'impellerDiameter',
      'directionOfOperation',
      'bladeCount',
      'cfei100Speed',
      'cfei40Speed',
      'mfg',
      'modelGroup',
      'mountingStructure',
      'ledLight',
      'coolingEffect'
    );

    this.setVisibleColumns([
      'quantity',
      'location',
      'areaServed',
      'model',
      'fanQuantity',
      'impellerDiameter',
      'operatingVolume',
      'averageAirSpeed',
      'coolingEffect',
      'operatingFanRPM',
      'motorSizeHP',
      'vcp',
      'outletDba',
      'motorFLACalc',
      'weight',
      'cfei40Speed',
      'cfei100Speed',
      'dropLength',
    ]);

    this.columnList.find((item) => item.key === 'weight').text =
      'WEIGHT PER FAN (LBS)';

    this.columnList.find((item) => item.key === 'outletDba').text =
      'TOTAL DBA PER FAN';

    this.columnList.find((item) => item.key === 'operatingVolume').text =
      'CFM PER FAN';

    this.columnList.find((item) => item.key === 'watts').text =
      'INPUT WATTS PER FAN';
  }
}
