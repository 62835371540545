export class Answer {

    text: string;
    enabled: boolean;
    name: string;
    state: string;
    value: string;
    visible: boolean;

    constructor(answerConfig: any) {
        this.text = answerConfig.displayName;
        this.enabled = answerConfig.enabled;
        if (answerConfig.name && typeof (answerConfig.name) === 'string' && answerConfig.name.toUpperCase().trim() === 'NULL') {
            this.name = null;
        } else {
            this.name = answerConfig.name;
        }
        this.state = answerConfig.state;
        if (answerConfig.value && typeof (answerConfig.value) === 'string' && answerConfig.value.toUpperCase().trim() === 'NULL') {
            this.value = null;
        } else {
            this.value = answerConfig.value;
        }
        this.visible = answerConfig.visible;
    }

    toJSON() {

        return {

            text: this.text,
            enabled: this.enabled,
            name: this.name,
            state: this.state,
            value: this.value,
            visible: this.visible

        };

    }

}
