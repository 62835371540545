import { environment } from '../../../../environments/environment';
import { ErvFrostControlDialogComponent } from './../../../selections/dialogs/erv-frost-control-dialog/erv-frost-control-dialog.component';
import { ErvLowLeakageDialogComponent } from './../../../selections/dialogs/erv-low-leakage-dialog/erv-low-leakage-dialog.component';
import { IHelpItem } from './interfaces/i-help-item.interface';
// tslint:disable-next-line: max-line-length
import { ErvSpreadsheetDialogComponent } from '../../../selections/dialogs/erv-spreadsheet-dialog/erv-spreadsheet-dialog.component';
import { WaterRejectionRatingInfoDialogComponent } from '../../../selections/dialogs/water-rejection-rating-info-dialog/water-rejection-rating-info-dialog.component';

export const HelpDictionary: Array<IHelpItem> = [
  {
    key: 'Fan_LayoutConfig_SparkRated',
    messageBox: {
      content: `In order to meet the requirements of spark resistant applications,
      Greenheck offers several options and accessories including varying levels of aluminum construction,
       EXP motor enclosures, shaft seals, and rub rings on most fan models.
       It is up to the discretion of the consulting engineer to approve of any such exceptions and make the final determination on which fan
       models are acceptable for spark resistant applications. It is also the responsibility of the consulting
       engineer to specify any options or accessories that are required to meet the intent of the desired level of spark
       resistant construction from AMCA 99-0401. For clarification or questions please refer to the AMCA 99-0401 construction guidelines.`,
    },
  },

  {
    key: 'Fan_LayoutConfig_RequiresCaliforniaTitle20Compliant',
    messageBox: {
      content: `California's Title 20 appliance regulation requires that commercial
      and industrial fans and blowers (CIFB) must provide energy-efficient operation
      as defined by their Fan Energy Index (FEI) value. CIFB products with fan shaft
      power equal to or greater than 1 hp, and less than or equal to 150 hp, must have
      a FEI value greater than or equal to 1.00 at the specified duty point. There are
      limited exemptions to this rule. `,
    },
  },

  {
    key: 'Fan_LayoutConfig_VFD',
    messageBox: {
      title: 'Apply VFD',
      content: `
      The "Apply VFD" question permits fan selections at the exact performance
      specified and for this information to be displayed correctly.
      <br>
      <br>
      <b>None</b>
      <br>
      Selecting "None" will provide the traditional direct drive fan
      selections with partial wheel widths running at the nominal
      motor RPM.
      <br>
      <b>Under Speed</b>
      <br>
      The "Under Speed" answer will display fan selections where the motor
      speed has been reduced below the local power supply
      (U.S. = 60 HZ, others 50.)
      <br>
      <b>Over Speed</b>
      <br>
      "Over Speed" will provide fan selections operating above the standard
      line frequency. The selection provides the operating frequency
      required from the variable frequency drive. Motor horsepower is
      limited to the nameplate value, as motors do not produce more torque
      when operating above their nameplate frequency. eCAPS limits maximum
      over speed frequency (90 or 120 HZ) by horsepower and poles based
      on limits provided by the motor manufacturer.
      <br>
      <b>Both</b>
      <br>
      Selecting both will enable the Under Speed and Over Speed
      functionalities.
      `,
    },
  },

  {
    key: 'Circulator_LayoutConfig_SizingMethod',
    messageBox: {
      content: `This is the primary application for HVLS fans in a space.
                <ul>
                    <li><b>General circulation</b> equally considers average air speed and fan efficiency.</li>
                    <li><b>Summer cooling</b> emphasizes air speed required for occupants to feel cooling.</li>
                    <li><b>Winter destratification</b> emphasizes fan efficiency and fast payback.</li>
                </ul>`,
    },
  },

  {
    key: 'Circulator_LayoutConfig_MinutesPerAirChange',
    messageBox: {
      content: `Rate of air circulation in a space. Typical values range from 1-6 mins per air rotation.
                For ceiling heights less than 20ft, 1 to 2 is recommended for comfort cooling, 2 to 4 for
                destratification, and 2 to 3 for both. For ceiling heights between 20 and 30ft, 2 to 3 is recommended
                for comfort cooling, 3 to 5 for destratification, and 3 to 4 for both. For ceiling heights over 30ft,
                3 to 5 is recommended for comfort cooling, 4 to 6 for destratification, and 4 to 5 for both.
                Recommendations are based on Greenheck performance testing in accordance with AMCA 230 and ASHRAE 55.`,
    },
  },

  {
    key: 'TemperedAirProduct_LayoutConfig_CondensingFanType',
    messageBox: {
      content: `If Lead is selected, the lead condenser fan receives an EC motor that modulates to maintain a
                head pressure setpoint. This option enhances unit efficiency and improves reheat capacity at part
                load conditions. If None is selected, the condenser fans will stage on/off based upon refrigerant
                pressure.`,
    },
  },

  {
    key: 'FumeExhaust_LayoutConfig_FumeExhaustNozzleType',
    messageBox: {
      content: `Nozzle selection changes plume rise and dilution of the exhaust effluent. Dilution nozzles and
                windbands entrain ambient air to mix with the lab exhaust air. This mixture dilutes the exhaust
                effluent and aids in creating a high overall effective plume rise. High plume nozzles are aerodynamic
                tapered cones providing maximum plume rise with the lowest level of energy consumption. No-Loss nozzle
                design keeps water out of the fan system and with lower plume height but without the use energy robbing
                elbows or discharge dampers.`,
    },
  },

  {
    key: 'FumeExhaust_LayoutConfig_FumeExhaustFanType',
    messageBox: {
      content: `Considerations for overall foot print, safety and efficiency in fan selection. Blower is a scroll
                centrifugal fan offering the highest efficiency but requires larger foot print. Inline is the most compact
                type with fan mounted on top of plenum and drive components sealed from the airstream. Bifurcated Inline
                is also compact while separating drive components from the contaminated airstream for safer inspection and
                maintenance. Additional losses by separating airstream are created lowering overall efficiency.`,
    },
  },

  {
    key: 'FumeExhaust_LayoutConfig_ASHRAE_LabLevel',
    messageBox: {
      content: `Classification of laboratory ventilation design levels are developed by an ASHRAE technical committee
                and categorizes the design and operation of laboratories into five laboratory ventilation design levels. Each
                level is associated with different degrees of protective capability by design features and operating
                characteristics.`,
    },
  },

  {
    key: 'FumeExhaust_LayoutConfig_FumeExhaustInletBoxOptions',
    messageBox: {
      content: `Inlet box is through the roof duct transition allowing a high efficiency centrifugal fan in a compact,
            curb mounted installation with minimal system effects. A plenum provides the option for both; additional outdoor
            air in VAV systems for constant plume height and a common box for redundant systems with one ducting location for
            multiple fans.`,
    },
  },
  {
    key: 'Fan_LayoutConfig_RedundancyOption',
    messageBox: {
      title: 'Redundancy',
      content: `On multiple fan systems the redundancy question refers to how the system will be controlled in
      normal (N) operation and in a situation where a fan is not operable. Answers to this question change the availability,
      sizing and performance of the fan selections.
      <br>
      <br>

        <b>None</b> – System designed to have the total air flow divided equally between the number of fans selected without any
        provision in the case a fan is not operable. System would need to operate at reduced system air flow in that situation.
        <br>
        <br>
        <b>N+1</b> – System designed to have one completely redundant fan. Not all the fans are ever operating together. Total air
        flow is divided by the quantity of fans minus one that would be on standby in case a fan becomes inoperable. When
        controlling this system, it is desired to keep equal run time on all fans, therefore the standby fan would not always
        be the same.
        <br>
        <br>
        <b>N-1</b> - System designed to normally operate with all the fans operating and dividing the airflow equally between them.
        But in the case where one fan is not operable, the fans are sized with the capacity to operate as 100% air flow with
        the remaining fans. Often seen in systems with three or more fans as a method to reduce energy costs and sound during
        normal operation.  `,
    },
  },

  {
    key: 'FumeExhaust_LayoutConfig_PlumeRiseCalc',
    messageBox: {
      content: `Two different means for calculating the plume rise from the discharge of the exhaust stack. Momentum Flux
                is the current method recommended by ASHRAE. The calculation includes building and site factors to more accurately
                model plume rise against downwind distance. Modified Briggs calculation is a legacy formula that does not include
                as many external elements that influence plume height. Note – Effective plume height includes the plume rise value
                and physical height of exhaust system from the roof deck.`,
    },
  },

  {
    key: 'Circulator_SelectionGrid_AvgAirSpeed',
    messageBox: {
      title: 'Average Air Speed Help',

      content: `Average speed of air moving across floor at selected fan RPM for space entered by user. Air
                speed is averaged across the floor area each fan covers in order to provide air movement throughout
                entire space.`,
    },
  },

  {
    key: 'Circulator_SelectionGrid_Watts',
    messageBox: {
      title: 'Input Watts Help',

      content: `For the DS model, the inputs watts are based on 208/60/3. For the DC model, the input watts are based on 115/60/1. `,
    },
  },

  {
    key: 'Circulator_SelectionGrid_CoolingEffect',
    messageBox: {
      title: 'Cooling Effect Help',

      content: `Cooling effect values are based on ASHRAE Standard 55 and are intended
           to quantify the perceived temperature change experienced by building occupants.`,
    },
  },

  {
    key: 'Circulator_SelectionGrid_AvgAirSpeedMax',
    messageBox: {
      title: 'Average Air Speed Max Help',

      content: `Average speed of air moving across floor at fan’s maximum RPM. Air speed is averaged across
                the floor area each fan covers in order to provide air movement throughout entire space entered by user.`,
    },
  },

  {
    key: 'Circulator_LayoutConfig_AirRotationsPerHour',
    messageBox: {
      content: `The rate of air circulation in the space due to HVLS fan(s).
      Recommended values are populated based on the selected application.`,
    },
  },

  {
    key: 'Circulator_LayoutConfig_MetabolicRateRequested',
    messageBox: {
      content: `The rate at which energy is produced by a person’s body based on their level of physical activity.
        These values are provided for common activity types as defined by ASHRAE Standard 55.`,
    },
  },

  {
    key: 'Circulator_LayoutConfig_ClothingLevelRequested',
    messageBox: {
      content: `The level of thermal insulation provided by a person’s clothing.
        These values are provided for common attire worn by people in commercial and
        industrial buildings as defined by ASHRAE Standard 55.`,
    },
  },

  {
    key: 'Circulator_LayoutConfig_AirCleaning',
    messageBox: {
      title: 'Air Disinfection Help',

      content: `
        <b>UV-C Light</b>
        <br>
        Optional Northern Light UV-C technology features non-ozone generating UV-C bulbs embedded in each fan blade to
        inactivate airborne pathogens like viruses and bacteria. UV-C bulbs are positioned near the tips of fan blades
        where the majority of air movement occurs to maximize pathogen exposure to UV-C light. The unique uplight design
        directs UV-C light away from occupants, minimizing UV radiation exposure so that fans can be safely utilized in
        occupied spaces.`,
    },
  },
  {
    key: 'Circulator_SelectionGrid_Cfei100Speed',
    messageBox: {
      title: 'Ceiling Fan Energy Index Help',

      content: `Ceiling Fan Energy Index (CFEI) is based on standard AMCA-208 and is intended to compare a large diameter
        (greater than 7 feet) ceiling fan's relative wire-to-air efficiency to a baseline efficiency. Values over 1.0 are
        more efficient than the baseline. For example, a fan with a CFEI of 1.1 is 10% more efficient than the baseline fan.
        Values must be greater than 1.00 at 100% of the fan’s max operating speed in order to meet Federal minimum efficiency
        levels per 10 CFR Part 430.`,
    },
  },
  {
    key: 'Circulator_SelectionGrid_Cfei40Speed',
    messageBox: {
      title: 'Ceiling Fan Energy Index Help',

      content: `Ceiling Fan Energy Index (CFEI) is based on standard AMCA-208 and is intended to compare
      a large diameter (greater than 7 feet) ceiling fan's relative wire-to-air efficiency to a baseline efficiency.
      Values over 1.0 are more efficient than the baseline. For example, a fan with a CFEI of 1.1 is 10% more efficient
      than the baseline fan. Values must be greater than 1.31 at 40% of the fan’s max operating speed in order to meet
      Federal minimum efficiency levels per 10 CFR Part 430.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_AMCA500LAir',
    messageBox: {
      content: `The AMCA 500-L Airflow Resistance test measures a louver's resistance to
      airflow under both intake and exhaust conditions. Pressure drop across the louver
      free area is measured and displayed as airflow resistance in units of inches water
      gauge at specific intake or exhaust velocity points. Nearly all Greenheck louvers
      have AMCA Certified Air Performance.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_AMCA500LSound',
    messageBox: {
      content: `The AMCA 500-L Sound test measures a louver's ability to absorb and reduce
      sound from behind the louver to the exterior. Airflow is not simulated during the
      test procedure. A noise generating station is positioned behind the louver test
      specimen while the sound decibel levels are measured directly in front of the louver
      test specimen. Louvers with AMCA Certified Sound Performance will boast published
      sound decibel reductions at varying octave band frequencies that indicate Free Field
      Noise Reduction. Transmission Loss is also published along with the overall Sound
      Transmission Class. All Greenheck acoustical louvers have AMCA Certified Sound
      performance.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_AMCA500LWater',
    messageBox: {
      content: `The AMCA 500-L Water Penetration test measures a louver's resistance to
      water penetration during normal operating conditions. A wetted wall and water
      manifold above and in front of the tested louver specimen simulate rain falling
      straight down in front of the louver at a rate of 4 in. per hour. Within a fully
      enclosed pressurized chamber behind the louver, a fan attempts to pull the water
      droplets through the louver blades. The louver is measured by the Beginning Point
      of Water Penetration, which is defined as the free area velocity from 0 - 1250 FPM
      where 0.01 oz of water per square foot of louver free area is measurable behind the
      louver. Nearly all Greenheck louvers have AMCA Certified Water Penetration
      performance.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_AMCA500LWDR',
    messageBox: {
      content: `The AMCA 500-L Wind Driven Rain test measures a louver's resistance to
      water penetration under more dynamic storm-like conditions than the AMCA 500-L Water
      Penetration test. A fan in front of the louver test specimen simulates external wind
      speeds of 29 and/or 50 MPH. Between the external fan and louver, spray nozzles
      simulate external rainfall at a rate(s) of 3 in. and/or 8 in. per hour (3 in. at 29
      MPH, 8 in. at 50 MPH). Within a fully enclosed pressurized chamber behind the louver,
      a fan attempts to pull the water droplets through the louver blades. The louver is
      measured by a grading system of Class A, B, C or D, which defines the water rejection
      percentage at specific escalating inlet velocity points (A = 99%, B = 95%-99%, C = 80%
      - 95%, D = <80%). Many Greenheck louvers have AMCA Certified Wind Driven Rain
      performance, but the test is reserved for Wind Driven Rain louvers.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_AMCA540Debris',
    messageBox: {
      content: `The AMCA-540 Test Method for Louvers Impacted by Wind Borne Debris is a
      requirement of the International Building Code and Florida Building code for all
      intake and exhaust louvers located within the Wind Borne Debris Region of the
      Hurricane Prone Region as defined by the International Building Code that are also
      installed within 30 ft. above grade. AMCA 540 simulates two different missile criteria.
      Missile Level D simulates a 9 lb. 2 x 4 traveling at 50 FPS (34 MPH). Missile level E
      simulates a 9 lb. 2 x 4 traveling at 80 FPS (55 MPH). Missile Level D is require for
      Basic Protection while Missile Level E is required for Enhanced Protection for all
      Essential Facilities. The AMCA 540 standard measures "failure" where the subject
      missile (2 x 4) is able to penetrate beyond the plane of the back of the louver or
      where louver components break free in entirety from the assembly. AMCA 540 requires that
      both the manufacturer's shortest and longest unsupported louver blade spans be tested
      along with mullion configurations if qualified as a multi-section assembly. Several
      Greenheck louvers are AMCA 540 Listed.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_AMCA550HVWDR',
    messageBox: {
      content: `The AMCA-550 High Velocity Wind Driven Rain test is a requirement of the
      International Mechanical Code and Florida Mechanical Code for all intake and exhaust
      louvers located within the Hurricane Prone Region as defined by the International
      Building Code and Florida Building Code. A fan in front of the louver test specimen
      simulates external wind speeds of 35, 70, 90 and 110 MPH. Spray nozzles simulate
      external rainfall at a rate of 8 in. per hour. The first three wind speeds (35, 70, 90
      MPH) are at a 15 minute duration each while the final wind speed (110 MPH) is a 5
      minute duration. Unlike the AMCA 500-L Water Penetration or Wind Driven Rain test(s)
      there is no simulated intake air being pulled through the louver. The AMCA 550 standard
      measures "failure" where greater than 1% of the overall sprayed water volume passes
      beyond the louver or louver and damper assembly during the entire 50 minute test
      duration. Several Greenheck louvers are AMCA 550 Listed.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_FEMA361Tornado',
    messageBox: {
      content: `FEMA-361 provides for the construction requirements for safe rooms for
      tornados and hurricane. To obtain FEMA funding design professionals must exhibit
      compliance with the FEMA 361 guidelines. Louvers that protect intake or exhaust
      ventilation ducts must undergo and pass the ICC 500 wind borne debris test standard,
      which simulates a 15 lb. 2 x 4 traveling up to 100 MPH. The subject missile (2 x 4)
      may penetrate the test specimen and remain lodged within the component(s), but may not
      perforate the witness paper located directly behind the test specimen. All openings
      for ventilation into and out of the safe room should be hardened to resist both high
      wind loads and debris impact. The Greenheck AFL-501 louver is a UL Classified Wind
      Storm rated assembly that has undergone and passed the ICC 500 debris impact standard
      while also being capable of withstanding extremely high wind loads.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_Florida',
    messageBox: {
      content: `Miami-Dade Notice of Acceptance (NOA) is a requirement in Southeast Florida,
      although it is not referenced as a requirement by the Florida Building Code. Miami-Dade
      NOA requires that louvers must undergo stringent structural testing in Florida test
      protocols TAS 202 (Uniform Static Air Pressure), TAS 203 (Cyclic Wind Loading) and
      TAS 201 (Large Missile Impact). The TAS 201 Large Missile Impact test is very similar in
      spirit to the AMCA 540 debris impact test, although the AMCA 540 standard is widely
      considered more stringent as Florida TAS 201 only tests the largest louver test specimen
      size using a 9 lb. 2 x 4 traveling at 50 FPS (34 MPH). Several Greenheck louvers have
      Miami-Dade NOA.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_FloridaTAS100A',
    messageBox: {
      content: `Florida (TAS 100A) is nearly identical to the AMCA 550 High Velocity Wind
      Driven rain test standard in that external wind speeds of 35, 70, 90 and 110 MPH are
      simulated with an external rainfall rate of 8.8 in. per hour. The test duration(s) are
      identical to the AMCA 550 standard. It should be noted that the International Mechanical
      Code, Florida Mechanical Code and Miami-Dade Notice of Acceptance only reference the AMCA
      550 High Velocity Wind Driven Rain standard. The difference between Florida TAS 100-A and
      AMCA 550 is the measure of pass or failure. TAS 100-A allows no water passage beyond the
      louver or louver and damper assembly at 35 or 70 MPH and only 0.05% passage of the overall
      sprayed water volume is allowed at 90 and 110 MPH combined. AMCA 550 allows up to 1% of the
      overall sprayed water volume during the entire test duration. Greenheck models EVH-660D and
      EHH-601D with VCD-40 damper are certified for Florida TAS 100A.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_MiamiDadeFlorida',
    messageBox: {
      content: `The minimum requirements to obtain Florida Product Approval is the Florida test
      protocol TAS 202 (Uniform Static Air Pressure). Florida Product Approval also recognizes
      Florida test protocol TAS 203 (Cyclic Wind Loading), AMCA 540 debris impact and AMCA 550
      High Velocity Wind Driven Rain as part of the Florida Product Approval process.`,
    },
  },

  {
    key: 'Louvers_LayoutConfig_Sand',
    messageBox: {
      content: `Sand louvers are highly effective in protecting the space behind the louvers from
      air-borne sand. Sand louver efficiency is measured by the percentage of sand removal in
      accordance with ASHRAE Standard Method 52.1-1992. Greenheck model FSL-401 offers greater
      than 90% sand removal efficiency up to 500 FPM free area velocity using a 150-300 micron
      dust medium.`,
    },
  },
  {
    key: 'TemperedAirProduct_SelectionGrid_ExhaustAirTransferRatio',
    messageBox: {
      content: `EATR refers to the exhaust air transfer ratio (i.e. cross leakage) of the energy recovery device.
      This ratio is the percentage of return air that is recirculated back into the supply airstream through the
      energy recovery device. The allowable percentage of EATR is based on air classification, which is spelled
      out in ASHRAE-62.1.
                <ul>
                    <li><b>Class 1:</b> 100% (e.g. classrooms, offices, hotel lobbies)</li>
                    <li><b>Class 2:</b> 10% (e.g. rest rooms, dining rooms, barbershops)</li>
                    <li><b>Class 3:</b> 5% (e.g. dry cleaners, beauty salons, pet shops)</li>
                    <li><b>Class 4:</b> 0% (e.g. laboratory fume exhaust, kitchen grease exhaust)</li>
                </ul>`,
    },
  },

  {
    key: 'TemperedAirProduct_SelectionGrid_Err',
    messageBox: {
      content: `ERR (enthalpy recovery ratio) is a metric used to determine the overall effectiveness of an energy
       recovery device. ERR is calculated using the ASHRAE-90.1 equation, looking at the change in enthalpy of the
       outdoor air to supply air compared to the difference of the outdoor air and return air enthalpies at design
       conditions. The calculation is shown below.
                <ul>
                    <li>ERR = (outdoor air enthalpy - supply air enthalpy) / (outdoor air enthalpy - return air enthalpy)</li>
                    <li>When energy recovery is required per ASHRAE-90.1 the energy recovery device must have at least 50% energy recovery effectiveness.</li>
                </ul>`,
    },
  },

  {
    key: 'MakeUpAir_LayoutConfig_Volume',
    messageBox: {
      content: `<ul>
          <li><b>Untempered:</b> 300 – 64000 CFM</li>
          <li><b>Direct Gas:</b> 800 – 64000 CFM</li>
          <li><b>Indirect Gas:</b> 800 – 16000 CFM</li>
          <li><b>Hot Water Heating:</b> 800 – 40000 CFM</li>
          <li><b>Steam Heating:</b> 800 – 14500 CFM</li>
          <li><b>Electric Heating:</b> 975 – 25000 CFM</li>
      </ul><br/>
      <ul>
          <li><b>No Cooling:</b> 300 – 64000 CFM</li>
          <li><b>Evaporative Cooling:</b> 800 – 60000 CFM</li>
          <li><b>Chilled Water Cooling:</b> 800 – 11700 CFM</li>
          <li><b>Split System Cooling:</b> 800 – 11700 CFM</li>
          <li><b>Packaged DX Cooling:</b> 800 – 8100 CFM</li>
      </ul>`,
    },
  },
  {
    key: 'MakeUpAir_LayoutConfig_RequiresCaliforniaTitle20Compliant',
    messageBox: {
      content: `California's Title 20 appliance regulation requires that commercial
      and industrial fans and blowers (CIFB) must provide energy-efficient operation
      as defined by their Fan Energy Index (FEI) value. CIFB products with fan shaft
      power equal to or greater than 1 hp, and less than or equal to 150 hp, must have
      a FEI value greater than or equal to 1.00 at the specified duty point. There are
      limited exemptions to this rule. `,
    },
  },

  {
    key: 'MakeUpAir_LayoutConfig_HeatingType',
    messageBox: {
      content: `Direct Gas can be used on locations where an exhausting process is taking place.
      Direct gas is not allowed on sleeping quarters per NFPA-54.`,
    },
  },

  {
    key: 'MakeUpAir_LayoutConfig_CoolingType',
    messageBox: {
      content: `Package direct expansion cooling on Make-Up Air equipment provides sensible cooling only,
       not for dehumidification processes. Stainless steel drain pans,
       double sloped per ASHRAE 62.1 are provided with all DX and chilled water coils.`,
    },
  },

  {
    key: 'MakeUpAir_LayoutConfig_CoolingCompressor',
    messageBox: {
      content: `Compressors are provided as standard or digital scroll configurations.
      Discharge temperature control capability for cooling is standard with the selection of a digital scroll.`,
    },
  },

  {
    key: 'MakeUpAir_LayoutConfig_SupplyDischargePosition',
    messageBox: {
      content: `Forward curved fans discharge configurations are limited
       to Bottom or End with some products configurable with top discharge.
       Direct drive configurations feature, bottom, end, or top discharge and
       most direct drive products will also offer side (Left) and side (Right) configurations.`,
    },
  },

  {
    key: 'MakeUpAir_SelectionGrid_DriveType',
    messageBox: {
      title: 'Fan Type Help',

      content: `Direct drive products will have either mixed flow supply fans or backward inclined supply fans.
       KSFD products do have direct drive forward curved fans wheels, however.
       All other products will have belt-driven forward curved fans.`,
    },
  },

  {
    key: 'CeilingAndCabinet_LayoutConfig_WholeHouseVentilation',
    messageBox: {
      title: 'Whole House Ventilation Help',

      content: `Whole House Ventilation allows the fan to run continuously at user defined low speed (typically 30 cfm)
             and turn up to the rated speed with the activation of a wall switch or integrated sensor.
             This feature helps comply with the minimum ventilation standards of ASHRAE Standard 62.2.`,
    },
  },

  {
    key: '*_SelectionGrid_BudgetPrice',
    messageBox: {
      title: 'Budget Price Help',

      content: `Budget pricing is for discussion and comparison purposes only, and does not include all
                possible accessories / options. For a complete and more accurate price, contact your local
                Greenheck representative.`,
    },
  },

  {
    key: '*_SelectionGrid_FanType',
    messageBox: {
      title: 'Fan Type Help',

      content: `Products offer both direct drive and belt drive forward curve supply fan options.
       Direct drive backward inclined supply fan options offer up to 4" of external static pressure capability.
       Mixed flow direct are available up to 15,000 CFM and operate at up to 50% of the required brake horsepower
       when compared to a comparable forward curved supply fan.`,
    },
  },

  {
    key: '*_SelectionGrid_OperatingCost',
    messageBox: {
      title: 'Operating Cost Per Year Help',

      content: `The annual operating cost assumes US$0.12 per kilowatt hour, 12 hours a day,
                5 days per week. It uses the operating bhp, the selected drive loss and motor efficiency.
                It assumes 3 phase for 3/4hp and larger and single phase for 1/2hp and smaller.`,
    },
  },

  {
    key: '*_SelectionGrid_totalCostOfOwnership',
    messageBox: {
      title: 'Total Cost Of Ownership Help',

      content: `Based on 24 hour, 5-day per week operation, 52 Weeks per year, for 10 years at $0.12/kWh electricity cost.
       Also includes, belt replacement every year and sheave replacement every 4 years.
       Calculations are based on supply fan operation only and does not calculate electrical or gas use for heating or cooling.`,
    },
  },

  {
    key: '*_SelectionGrid_MaxVelocity',
    messageBox: {
      title: 'Max Velocity Help',

      content: `Max velocity is based on the "Safety Factor Criteria (Max Velocity)"
                selected. The "Beginning Point of Water" is based on the AMCA 500-L
                (Water Penetration) test. The Class A, "29 mph / 3 in." and "50 mph / 8
                in." are based on the AMCA 500-L (Wind Driven Rain) test. For
                additional water penetration (AMCA 500-L) information, see specific
                model submittal on the website at
                <a href="${environment.greenheckUrls.root}/products/category/5" target="_blank">
                ${environment.greenheckUrls.root}/products/category/5</a>`,
    },
  },

  {
    key: '*_SelectionGrid_FEI',
    messageBox: {
      title: 'FEI Help',

      content: `Fan Energy Index (FEI) is based on standard AMCA-208 and is
       intended to compare a the fan's relative wire-to-air efficiency to a baseline efficiency.
      Values below 1.0 may not meet min efficiency levels depending on local codes.
      Values over 1.0 are more efficient than the baseline. Example, a fan with an FEI of 1.1 is 10% more efficient than the baseline fan.
      FEI numbers are based on NEMA Premium motor efficiency levels.`,

      video: {
        title: 'Fan Efficiencies and Energy Regulation',
        url: 'https://youtu.be/KQ9Fc5u23DM',
        showButton: true,
      },
    },
  },

  {
    key: '*_SelectionGrid_AMCA',
    icon: 'import_contacts',
    toolTip: 'AMCA Directory',
    link: {
      title: 'AMCA Directory',
      url: '/assets/docs/amcadirectory.pdf',
    },
  },

  {
    key: '*_SelectionGrid_RainResistanceRanking',
    messageBox: {
      content: WaterRejectionRatingInfoDialogComponent,
    },
  },

  {
    key: 'Preconditioner_LayoutConfig_SupplyFilter',
    messageBox: {
      content: ErvSpreadsheetDialogComponent,
    },
  },
  {
    key: 'Preconditioner_LayoutConfig_SupplyDamper',
    messageBox: {
      content: ErvLowLeakageDialogComponent,
    },
  },
  {
    key: 'Preconditioner_LayoutConfig_ExhaustFilter',
    messageBox: {
      content: ErvSpreadsheetDialogComponent,
    },
  },
  {
    key: 'Preconditioner_LayoutConfig_ReturnAirDamper',
    messageBox: {
      content: ErvLowLeakageDialogComponent,
    },
  },
  {
    key: 'Preconditioner_LayoutConfig_FrostControl',
    messageBox: {
      content: ErvFrostControlDialogComponent,
    },
  },
  {
    key: 'TemperedAirProduct_LayoutConfig_HeatingTypePrimary',
    messageBox: {
      title: 'Primary Heating Type Help',
      content: `The air-source heat pump is the primary heating source and will operate by itself whenever it is able to meet set point.  The heating capacity decreases as outdoor temperature decreases. In colder climates, a heat pump may not be able to satisfy the indoor temperature by itself. In this case, a secondary heating source is required to meet the set point. The secondary heat source is integral to the unit and activates when the heat pump cannot meet set point by itself.`,
    },
  },

  {
    key: 'TemperedAirProduct_LayoutConfig_HeatSizing',
    messageBox: {
      title: 'Primary Heat Sizing Help',
      content: `Heat sizing is the operational sequence of the secondary heating source.  Supplemental allows the secondary heat to operate at the same time as the air-source heat pump, and also when the air-source heat pump is locked out.  If Backup is selected, the secondary heat cannot operate at the same time as the air-source heat pump.  The backup heating source only becomes available when heat pump heating is unavailable.`,
    },
  },

  {
    key: 'Fans_ConfigLayout_ReversibleAirflow',
    messageBox: {
      title: 'Reversible Air Flow Help',
      content: `Fan capable of 100% air flow in either direction. Power reversed to motor causing impeller to rotate in opposite direction. Commonly used when design has normal and emergency modes of operation.`,
    },
  },

  {
    key: 'Fans_ConfigLayout_VaneAxialSection',
    messageBox: {
      title: 'Vane Axial Section Help',
      content: `Performance enhancing design utilizing contoured vanes downstream of the propeller to convert swirling airflow into laminar airflow. Straightening vanes increase fan performance capabilities and reduces energy consumption. This option only applies to the model AX. When No is selected, the AX model will get the "Long" section.  When Yes is selected, the AX model will get the extra Vane Axial section.`,
    },
  },
];
