<div class="faq-page">
  <div class="panels">
    <mat-toolbar class="title">Frequently Asked Questions</mat-toolbar>

    <mat-accordion>
      <mat-expansion-panel
        #panel
        *ngFor="let item of faqList; let i = index"
        [expanded]="i === 0"
      >
        <mat-expansion-panel-header>
          <mat-panel-title
            [innerHTML]="item.title | safeHTML"
          ></mat-panel-title>
        </mat-expansion-panel-header>

        <div class="content-parent">
          <div class="video" *ngIf="item.videoUrl">
            <iframe
              [src]="item.videoUrl | safeURL"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
            ></iframe>
          </div>
          <div
            class="content"
            [innerHtml]="item.html | safeHTML"
            *ngIf="item.html"
          ></div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-card appearance="outlined">
      <mat-card-title>Contact Us</mat-card-title>
      <mat-card-content>
        <span
          >For product questions or quotes, contact your local Greenheck
          Representative</span
        >
        <ul>
          <li>
            Find a Rep -
            <a [href]="greenheckRootUrl + '/sales'" target="_blank"
              >greenheck.com/sales</a
            >
          </li>
        </ul>
        <span>For technical IT support:</span>
        <ul>
          <li>
            <span>1.855.886.8324</span>
            <span> (toll free)</span>
          </li>
          <li>
            <a href="mailto:caps@greenheck.com" target="_blank"
              >caps&#64;greenheck.com</a
            >
          </li>
          <li>
            <a
              href="http://messenger.providesupport.com/messenger/greenheck.html"
              target="_blank"
            >
              <img
                src="http://image.providesupport.com/image/greenheck/online-816061172.gif"
                width="140"
                height="100"
                border="0"
              />
            </a>
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>
