<div class="maintenance-page">
  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title>
        <div class="title">
          <mat-icon svgIcon="ecaps_logo"></mat-icon>

          <span>eCAPS Maintenance</span>
        </div>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <p>eCAPS is currently undergoing maintenance. Please check back soon.</p>

      <p>Sorry for the inconvienience.</p>
    </mat-card-content>
  </mat-card>
</div>
