import { Component, OnInit } from '@angular/core';
import { SelectionResults } from '../../models/selection-results/selection-results.model';
import { ValidSize } from '../../models/selection-results/valid-size.model';
import { LayoutService } from '../../services/layout.service';
import { ErvCompareDialogData } from './models/erv-compare-dialog-data.model';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import { Products } from '../../../products/controllers/products.controller';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../../../google/services/google-analytics.service';
import { SideDialogComponent } from '../../../side-dialog/classes/side-dialog-component.interface';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { RevitCommsService } from '../../../external-communication/services/revit-comms.service';
import { LachesisService } from '../../../analytics/services/lachesis.service';

@Component({
  selector: 'app-erv-compare-dialog',
  templateUrl: './erv-compare-dialog.component.html',
  styleUrls: ['./erv-compare-dialog.component.scss'],
})
export class ErvCompareDialogComponent implements OnInit, SideDialogComponent {
  dialogData: ErvCompareDialogData;

  exhaustVolume: number;

  size: ValidSize;

  compareSelectionResults: SelectionResults;

  constructor(
    private sideDialogService: SideDialogService,
    private layout: LayoutService,
    private core: EcapsCore,
    private products: Products,
    private analytics: GoogleAnalyticsService,
    private revitComms: RevitCommsService,
    private lachesisService: LachesisService
  ) {
    if (this.revitComms.isRevitApp) {
      this.analytics.trackEvent_Old(
        EventCategories.revitAddin,
        'Performance',
        'Compare'
      );
    }
  }

  ngOnInit() {
    this.lachesisService.trackEvent({
      eventName: 'Comparison - DOAS',
      slideout: 'Energy Recovery Model Comparison',
      modelGroup: this.dialogData.size.selectionLayoutConfig.modelGroup,
      productType: this.dialogData.size.productType,
      model: this.dialogData.size.model,
    });

    this.analytics.viewItem('Comparison', {
      source: 'Energy Recovery Model Comparison Slideout',
      items: [this.dialogData.size.getGAModel()],
    });

    this.exhaustVolume = this.dialogData.exhaustVolume;

    this.size = this.dialogData.size;

    if (this.layout.canInflateValidSize(this.size)) {
      let loading = true;

      this.core.showLoadingGraphic('Loading...', function () {
        return loading;
      });

      this.layout.inflateValidSize(this.size).then(() => {
        this.layout
          .getTAPCompare(this.size, this.exhaustVolume)
          .then((selectionResults) => {
            this.compareSelectionResults = selectionResults;

            loading = false;

            this.core.hideLoadingGraphic();

            if (selectionResults.validSizes.length === 0) {
              this.closeClick();

              this.core.showMessageBox({
                title: 'Invalid ERV Selection',
                message: `Based on the inputs you've provided, we cannot locate a comparable Energy Recovery unit.`,
                icon: 'warning',
                iconClasses: 'orange',
              });
            }
          });
      });
    } else {
      // If size is already inflated.

      let loading = true;

      this.core.showLoadingGraphic('Loading...', function () {
        return loading;
      });

      this.layout
        .getTAPCompare(this.size, this.exhaustVolume)
        .then((selectionResults) => {
          this.compareSelectionResults = selectionResults;

          loading = false;

          this.core.hideLoadingGraphic();

          if (selectionResults.validSizes.length === 0) {
            this.closeClick();

            this.core.showMessageBox({
              title: 'Invalid ERV Selection',
              message: `Based on the inputs you've provided, we cannot locate a comparable Energy Recovery unit.`,
              icon: 'warning',
              iconClasses: 'warn',
            });
          }
        });
    }
  }

  addProduct(size: ValidSize) {
    this.analytics.trackEvent_Old(
      EventCategories.rvVsRveCompare,
      'Add Product',
      size.model
    );

    this.products.showProductInfo(size, 'customize');
  }

  cautionIconClick(size: ValidSize) {
    this.products.showValidSizeWarning(size);
  }

  closeClick() {
    this.sideDialogService.close();
  }
}
