import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import {
  MatSelectionList,
  MatSelectionListChange,
} from '@angular/material/list';

@Component({
  selector: 'app-clear-local-storage-dialog',
  templateUrl: './clear-local-storage-dialog.component.html',
  styleUrls: ['./clear-local-storage-dialog.component.scss'],
})
export class ClearLocalStorageDialogComponent implements OnInit {
  @ViewChild('keyListGroup', { static: true }) keyListGroup: MatSelectionList;

  keyList = Object.keys(window.localStorage);

  formGroup: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ClearLocalStorageDialogComponent>,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      selectAll: false,
    });
  }

  selectAllClick() {
    if (this.formGroup.get('selectAll').value === true) {
      this.keyListGroup.selectAll();
    } else if (
      this.keyListGroup.selectedOptions.selected.length === this.keyList.length
    ) {
      this.keyListGroup.deselectAll();
    }
  }

  checkSelectAll(event: MatSelectionListChange) {
    this.formGroup
      .get('selectAll')
      .setValue(
        this.keyListGroup.selectedOptions.selected.length ===
          this.keyList.length
      );
  }

  remove() {
    for (const key of this.keyListGroup.selectedOptions.selected) {
      window.localStorage.removeItem(key.value);
    }

    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }
}
