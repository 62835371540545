import { Injectable } from '@angular/core';
import { Item } from '../../projects/models/project-item.model';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../../google/services/google-analytics.service';
import { LayoutService } from '../services/layout.service';
import { ProductEntities } from '../../products/enums/product-entities.enum';
import { ProductsService } from '../../products/services/products.service';
import { Router } from '@angular/router';
import { ProjectsService } from '../../projects/services/projects.service';
import { LachesisService } from '../../analytics/services/lachesis.service';

@Injectable({
  providedIn: 'root',
})
export class ItemReselectionDialog {
  constructor(
    private core: EcapsCore,
    private analytics: GoogleAnalyticsService,
    private lachesisService: LachesisService,
    private layoutService: LayoutService,
    private productsService: ProductsService,
    private router: Router,
    private projectsService: ProjectsService
  ) {}

  show(revisionItem: Item): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.core
        .showMessageBox({
          title: 'Item Reselection',
          message:
            'You have activated a reselection. Do you want to remove the currently selected item from the job?',
          buttons: [
            {
              title: 'Remove',
              value: 'REMOVE',
            },
            {
              title: 'Keep',
              value: 'KEEP',
            },
            {
              title: 'Cancel',
            },
          ],
        })
        .then((result) => {
          switch (result) {
            case 'REMOVE': {
              this.reselectItem(revisionItem, true).then(() => {
                resolve(true);
              });
              break;
            }
            case 'KEEP': {
              this.reselectItem(revisionItem, false).then(() => {
                resolve(true);
              });
              break;
            }
            default: {
              resolve(false);
              break;
            }
          }
        });
    });
  }

  private reselectItem(revisionItem: Item, remove: boolean): Promise<void> {
    return new Promise<void>((resolve) => {
      this.analytics.trackEvent_Old(
        EventCategories.productReselection,
        'Click',
        !remove ? 'Keep' : 'Remove'
      );

      this.lachesisService.trackEvent({
        eventName: 'Reselect',
        eventInfo: !remove ? 'Keep' : 'Remove',
        modelGroup: revisionItem.sizeData.selectionLayoutConfig.modelGroup,
        productType: revisionItem.sizeData.productType,
        model: revisionItem.sizeData.model,
      });

      this.analytics.viewItem('Reselect', {
        source: 'Item Reselection Lightbox',
        action: !remove ? 'Keep' : 'Remove',
        items: [revisionItem.sizeData.getGAModel()],
      });

      const self = this;

      let reselecting = true;

      this.core.showLoadingGraphic('Reselecting...', function () {
        return reselecting;
      });

      const reselect = function () {
        self.layoutService
          .reselectSize(revisionItem.sizeData, revisionItem)
          .then(() => {
            let category;

            if (
              revisionItem.sizeData.selectionLayoutConfig.modelGroup
                .toLocaleLowerCase()
                .trim() === 'preconditioner'
            ) {
              category = self.productsService.findCategory(
                revisionItem.sizeData.selectionLayoutConfig.getQuestion(
                  'UnitMounting'
                ).value
              );
            } else if (
              revisionItem.sizeData.businessUnit.toLowerCase().trim() !== 'mua'
            ) {
              category = self.productsService.findCategory(
                revisionItem.sizeData.selectionLayoutConfig.modelGroup
              );
            } else {
              category = self.productsService.findCategory(
                revisionItem.sizeData.outputs.heatingModel
                  .selectedHeatingModelType
              );
            }

            if (
              !!category.customRoute &&
              category.entity !== ProductEntities.fumeExhaust
            ) {
              self.router.navigateByUrl(category.customRoute);
            } else if (
              !!category.customRoute &&
              category.entity === ProductEntities.fumeExhaust
            ) {
              self.router.navigateByUrl('/graphicalselection');
            } else {
              self.router.navigateByUrl('/selection');
            }

            reselecting = false;

            self.core.hideLoadingGraphic();

            resolve();
          });
      };

      if (remove) {
        this.projectsService.deleteRevisionItem(revisionItem).then(() => {
          reselect();
        });
      } else {
        reselect();
      }
    });
  }
}
