<div class="image-dialog">
  <div class="close-container">
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="image">
      <img [src]="data[selectedIndex].imageUrl" [alt]="data[selectedIndex].title" [title]="data[selectedIndex].title" />
      <div class="navigation">
        <button mat-icon-button (click)="previousClick()" [style.visibility]="(selectedIndex === 0 ? 'hidden' : 'visible')">
          <mat-icon>arrow_left</mat-icon>
        </button>
        <button mat-icon-button (click)="nextClick()" [style.visibility]="(selectedIndex < (data.length - 1) ? 'visible' : 'hidden')">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </div>
  </div>
  <div class="info-line">
    <span class="title">{{ data[selectedIndex].title }}</span>
    <span class="count">{{ (selectedIndex + 1) }} / {{ data?.length }}</span>
  </div>
</div>