import { DrawingTypes } from '../../documents/enums/drawing-types.enum';

export class FileInfo {
    name: string;
    type: DrawingTypes;
    constructor(data: any) {
        this.name = data.name;
        this.type = data.type;
    }
}
