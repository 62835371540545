<div class="selection-page-content">
  <div class="left-block">
    <mat-horizontal-stepper
      [linear]="true"
      #leftStepper
      (animationDone)="selectFirstField()"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>done</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="done">
        <mat-icon>done</mat-icon>
      </ng-template>
      <mat-step
        *ngFor="let group of layoutConfigGroups; let i = index"
        [label]="group.text"
        [completed]="stepCompleted(group)"
        [optional]="stepOptional(group)"
      >
        <div
          #stepContent
          class="step-content"
          [class.scrollable]="
            stepContent.scrollHeight > stepContent.clientHeight
          "
        >
          <div class="sub-group" *ngIf="!!getQuestions(group)">
            <div class="questions">
              <div
                class="question"
                *ngFor="let question of getQuestions(group)"
              >
                <configuration-question
                  #questionField
                  [layout]="layoutConfig"
                  [question]="question"
                  [linear]="true"
                  (answered)="questionAnswered()"
                  (focus)="questionFocus(questionField)"
                  (blur)="questionBlur(questionField)"
                ></configuration-question>
              </div>
            </div>
          </div>
          <div class="sub-group" *ngFor="let subGroup of getSubGroups(group)">
            <div class="title">{{ subGroup.text }}</div>
            <div class="questions">
              <div
                class="question"
                *ngFor="let question of getQuestions(subGroup)"
              >
                <configuration-question
                  #questionField
                  [layout]="layoutConfig"
                  [question]="question"
                  [linear]="true"
                  (answered)="questionAnswered()"
                  (focus)="questionFocus(questionField)"
                  (blur)="questionBlur(questionField)"
                ></configuration-question>
              </div>
            </div>
          </div>
        </div>
      </mat-step>
    </mat-horizontal-stepper>

    <div class="actions">
      <div class="buttons">
        <button
          mat-raised-button
          color="primary"
          (click)="backClick()"
          [style.display]="leftStepper.selectedIndex > 0 ? undefined : 'none'"
        >
          <mat-icon>arrow_left</mat-icon> {{ getPreviousText() }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="nextClick()"
          [disabled]="
            !layoutConfigGroups ||
            !stepCompleted(layoutConfigGroups[leftStepper.selectedIndex])
          "
          [style.display]="
            layoutConfigGroups &&
            leftStepper.selectedIndex < layoutConfigGroups.length - 1
              ? undefined
              : 'none'
          "
        >
          {{ getNextText() }}
          <mat-icon iconPositionEnd>arrow_right</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="svg-parent {{ getModelGroup() }}" [ngSwitch]="getModelGroup()">
    <fume-exhaust-svg
      *ngSwitchCase="'fumeexhaust'"
      [layout-config]="layoutConfig"
    ></fume-exhaust-svg>
    <doas-svg *ngSwitchCase="'rv'" [layout-config]="layoutConfig"></doas-svg>
    <doas-svg *ngSwitchCase="'rve'" [layout-config]="layoutConfig"></doas-svg>
    <ds-svg
      *ngSwitchCase="'circulator'"
      [layout-config]="layoutConfig"
    ></ds-svg>
    <selection-svg
      *ngSwitchDefault
      class="svgContent"
      [layout-config]="layoutConfig"
    ></selection-svg>
  </div>

  <div
    class="grid-content {{ modelGroup.toLowerCase().trim() }}"
    [ngSwitch]="modelGroup.toLowerCase().trim()"
  >
    <doas-rv-grid
      #sizeGrid
      *ngSwitchCase="'rv'"
      [layoutConfig]="layoutConfig"
      [showFooter]="false"
      [showCustomize]="true"
      [showFilter]="false"
      (sizeAdded)="doasPageReset()"
    ></doas-rv-grid>
    <doas-rve-grid
      #sizeGrid
      *ngSwitchCase="'rve'"
      [layoutConfig]="layoutConfig"
      [showFooter]="false"
      [showCustomize]="true"
      [showFilter]="false"
      (sizeAdded)="doasPageReset()"
    ></doas-rve-grid>
    <ng-container *ngSwitchCase="'fumeexhaust'">
      <ng-container
        [ngSwitch]="layoutConfig?.getQuestion('FumeExhaustApplication').value"
      >
        <fume-exhaust-grid
          *ngSwitchDefault
          #sizeGrid
          [layoutConfig]="layoutConfig"
          [showFooter]="true"
          [showCustomize]="true"
          [showFilter]="true"
        ></fume-exhaust-grid>

        <diesel-generator-grid
          *ngSwitchCase="'DieselGenerator'"
          #sizeGrid
          [layoutConfig]="layoutConfig"
          [showFooter]="true"
          [showCustomize]="true"
          [showFilter]="true"
        ></diesel-generator-grid>
      </ng-container>
    </ng-container>
    <circulator-grid
      #sizeGrid
      *ngSwitchCase="'circulator'"
      [layoutConfig]="layoutConfig"
      [showFooter]="true"
      [showCustomize]="true"
      [showFilter]="true"
    ></circulator-grid>
    <circulator-ddf-grid
      #sizeGrid
      *ngSwitchCase="'DestratFans'"
      [layoutConfig]="layoutConfig"
      [showFooter]="true"
      [showCustomize]="true"
      [showFilter]="true"
    ></circulator-ddf-grid>
  </div>
</div>
