import { DimensionInfo } from './dimension-info.model';
import { FileInfo } from './file-info.model';

export class DimensionData {
    dimensions: Array<DimensionInfo>;
    files: Array<FileInfo>;

    constructor(data: any) {
        this.dimensions = new Array<DimensionInfo>();

        if (data.dimensions && data.dimensions.length > 0) {
            for (let i = 0; i < data.dimensions.length; i++) {
                this.dimensions.push(new DimensionInfo(data.dimensions[i]));
            }
        }

        this.files = new Array<FileInfo>();

        if (data.files && data.files.length > 0) {
            for (let i = 0; i < data.files.length; i++) {
                this.files.push(new FileInfo(data.files[i]));
            }
        }
    }
}
