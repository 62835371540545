import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VentabulaChartComponent } from './components/ventabula-chart/ventabula-chart.component';
import { VentabulaCompareChartComponent } from './components/ventabula-compare-chart/ventabula-compare-chart.component';
import { VentabulaValidSizeChartComponent } from './components/ventabula-valid-size-chart/ventabula-valid-size-chart.component';
import { ChartingService } from './services/charting.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EcapsCoreModule } from '../../ecaps-core/ecaps-core.module';
import { UsersModule } from '../../users/users.module';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    EcapsCoreModule,
    UsersModule,
    FormsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule
  ],
  declarations: [
    VentabulaChartComponent,
    VentabulaCompareChartComponent,
    VentabulaValidSizeChartComponent
  ],
  providers: [ChartingService],
  exports: [
    VentabulaChartComponent,
    VentabulaCompareChartComponent,
    VentabulaValidSizeChartComponent
  ]
})
export class ChartingModule {}
