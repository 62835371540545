<div class="message">
  Our website stores cookies on your device and discloses information in
  accordance with our privacy statement. Choose "Customize Settings" to control
  cookies. We may collect certain aggregate and anonymized data from your
  browser independent of your cookie preferences.
  <a href="https://www.greenheck.com/about-us/privacy-statement" target="_blank"
    >Privacy Statement</a
  >
</div>

<div class="actions">
  <button mat-raised-button color="primary" (click)="acceptAll()">
    Accept All Cookies
  </button>
  <button mat-flat-button (click)="customize()">Customize Settings</button>
</div>

<ng-template #customizeDialog>
  <h2 mat-dialog-title>Cookie Customization</h2>

  <mat-dialog-content>
    <div class="cookie-policy-dialog__content">
      <h2>How eCAPS Uses Cookies</h2>
      <p>
        This website stores cookies on your computer. These cookies are used to
        collect information about how you interact with our website and allow us
        to remember you. We use this information in order to improve and
        customize your browsing experience and for analytics and metrics about
        our visitors both on this website and other media. To find out more
        about the cookies we use, see our
        <a
          href="https://www.greenheck.com/about-us/privacy-statement"
          target="_blank"
          >Privacy Policy</a
        >.
      </p>

      <p>
        If you decline, your information won’t be tracked when you visit this
        website. A single cookie will be used in your browser to remember your
        preference not to be tracked.
      </p>

      <h3>Manage Consent Preferences</h3>
      <form [formGroup]="formGroup">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let option of cookieOptions" hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>{{ option.title }}</mat-panel-title>
              <mat-panel-description>
                <span>{{ option.required ? 'Required' : '' }}</span>
                <mat-slide-toggle
                  [formControlName]="option.id"
                  color="primary"
                  (click)="toggleClick($event, option)"
                ></mat-slide-toggle>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <p>{{ option.description }}</p>
          </mat-expansion-panel>
        </mat-accordion>
      </form>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="confirmCustom()">
      Confirm My Choices
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #confirmRequiredDialog>
  <h2 mat-dialog-title>Required Cookie</h2>

  <mat-dialog-content>
    <div class="confirm-required-content">
      <mat-icon color="warn">warning</mat-icon>
      <div class="message">
        This cookie is required to use this site. If you choose not to accept
        this cookie you will not be able to use this site.
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]="false">Decline</button>

    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      Accept
    </button>
  </mat-dialog-actions>
</ng-template>
