import { DecimalPipe, formatNumber } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LachesisService } from '../../../analytics/services/lachesis.service';
import { DrawingTypes } from '../../../documents/enums/drawing-types.enum';
import { DocumentsService } from '../../../documents/services/documents.service';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import { EmailDialog } from '../../../ecaps-core/controllers/email-dialog.controller';
import {
  CamelCasePipe,
  CamelToDisplayPipe,
  ZeroToDashPipe,
} from '../../../ecaps-core/pipes/custom-pipes.pipe';
import { EmailService } from '../../../ecaps-core/services/email.service';
import { DrawingRequest } from '../../../ecaps-core/services/models/drawing-request.model';
import { numericValidator } from '../../../ecaps-core/validators/custom-validators.validator';
import { LaunchTypes } from '../../../external-communication/models/launch-types.enum';
import { RevitStateLevels } from '../../../external-communication/models/revit-state-levels.enum';
import { ExternalCommunicationService } from '../../../external-communication/services/external-communication.service';
import { RevitCommsService } from '../../../external-communication/services/revit-comms.service';
import {
  ContentTypes,
  EventCategories,
  GoogleAnalyticsService,
} from '../../../google/services/google-analytics.service';
import { Item } from '../../../projects/models/project-item.model';
import { ProjectsService } from '../../../projects/services/projects.service';
import { ItemReselectionDialog } from '../../../selections/controllers/item-reselection-dialog.controller';
import { Group as LayoutConfigGroup } from '../../../selections/models/layout-config/group.model';
import { Question as LayoutConfigQuestion } from '../../../selections/models/layout-config/question.model';
import { ValidSize } from '../../../selections/models/selection-results/valid-size.model';
import { LayoutService } from '../../../selections/services/layout.service';
import { SideDialogComponent } from '../../../side-dialog/classes/side-dialog-component.interface';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { Users } from '../../../users/controllers/users.controller';
import { UsersService } from '../../../users/services/users.service';
import { ProductTypes } from '../../enums/product-types.enum';
import { DimensionInfo } from '../../models/dimension-info.model';
import { FileInfo } from '../../models/file-info.model';
import { ProductInfoDialogReturnTypes } from '../../models/product-info-dialog-return-types.model';
import { ProductInfoDialogData } from './../../models/product-info-dialog-data.model';

interface scrollJumpItemBase {
  class: string;
  title: string;
}

interface scrollJumpItemStdIcon extends scrollJumpItemBase {
  icon: string;
}

interface scrollJumpItemSvgIcon extends scrollJumpItemBase {
  svgIcon: string;
}

@Component({
  selector: 'app-product-info-dialog',
  templateUrl: './product-info-dialog.component.html',
  styleUrls: ['./product-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductInfoDialogComponent
  implements OnInit, AfterViewChecked, OnDestroy, SideDialogComponent
{
  scrollJumpItems: (scrollJumpItemStdIcon | scrollJumpItemSvgIcon)[] = [];

  public dialogData: ProductInfoDialogData;
  public dialogResults: any;

  private _projectChangedSubscription: Subscription;

  public size: ValidSize;
  public selectedTab: string;
  public revisionItem: Item;

  public totalStaticPressure: number;

  public drawingBase: string;

  public formGroup: UntypedFormGroup;
  public quantityField: UntypedFormControl;
  public tagField: UntypedFormControl;
  public areaField: UntypedFormControl;
  public locationField: UntypedFormControl;

  public revitFormGroup: UntypedFormGroup;

  public fumeExhaustTableDS: Array<any>;

  public erTableDS: Array<any>;

  public coolingDetailDS: Array<any>;

  public heatingDetailDS: Array<any>;

  public primaryHeatingDetailDS: Array<any>;

  dimensionsDS = new MatTableDataSource<DimensionInfo>();

  public showSPCorrections: boolean;
  public spCorrectionsDS: Array<any>;
  public chartSpacing = 0;
  public circInstallClearDS: Array<any>;

  @ViewChild('spCorrections', { static: false })
  private spCorrectionsDiv: ElementRef;

  @ViewChild(MatTabGroup, { static: true }) private tabControl: MatTabGroup;

  @ViewChild('tagInput', { static: false }) private tagInputField: ElementRef;

  @ViewChild('infoTabContent', { static: false })
  private infoTabContent: ElementRef;

  public validate: boolean;

  private configUpdated = false;

  public customizeSoo = false;

  productTypes = ProductTypes;

  production = environment.production;

  isRevitPlugin = false;

  constructor(
    private core: EcapsCore,
    private externalComm: ExternalCommunicationService,
    private layout: LayoutService,
    private projectsService: ProjectsService,
    private itemReselectionDialog: ItemReselectionDialog,
    private analytics: GoogleAnalyticsService,
    private sideDialogService: SideDialogService,
    private documentsService: DocumentsService,
    private emailDialog: EmailDialog,
    private emailService: EmailService,
    private usersService: UsersService,
    private usersController: Users,
    private revitComms: RevitCommsService,
    private lachesisService: LachesisService
  ) {
    this.isRevitPlugin = this.revitComms.isRevitApp;

    this.drawingBase = environment.drawingContentBase;

    this._projectChangedSubscription =
      this.projectsService.localProjectUpdated.subscribe((project) => {
        if (!!this.revisionItem) {
          this.revisionItem = project
            .getActiveRevision()
            .items.find((item) => item.id === this.revisionItem.id);
          this.size = !!this.revisionItem ? this.revisionItem.sizeData : null;
        }
      });

    const self = this;

    const tagExists = async function (c: UntypedFormControl) {
      let result = null;

      await self.projectsService.getLocalProject().then(
        (project) => {
          const results = project
            .getActiveRevision()
            .items.filter(
              (item) =>
                item.tag.toUpperCase().trim() === c.value.toUpperCase().trim()
            );

          result =
            results.length === 0 ||
            (!!self.revisionItem &&
              self.revisionItem.tag.toUpperCase().trim() ===
                c.value.toUpperCase().trim())
              ? null
              : {
                  errorMessage: 'Tag already exists',
                };
        },
        (errorData) => {
          result = null;
        }
      );

      return result;
    };

    const reselectionItem = this.layout.getReselectionItem();
    if (this.isRevitPlugin) {
      const mark = this.revitComms.reselectStates?.find(
        (state) => state.level === RevitStateLevels.mark
      );
      const tag =
        reselectionItem?.tag ||
        mark?.values.find((value) => value.key === 'Tag')?.value;

      this.tagField = new UntypedFormControl(
        tag || '',
        [Validators.required],
        tagExists
      );

      this.revitFormGroup = new UntypedFormGroup({
        tag: this.tagField,
      });
    } else {
      this.quantityField = new UntypedFormControl(
        !!reselectionItem ? reselectionItem.quantity : 1,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(10000),
          numericValidator(false),
        ]
      );

      this.tagField = new UntypedFormControl(
        !!reselectionItem ? reselectionItem.tag : '',
        [Validators.required],
        tagExists
      );

      this.areaField = new UntypedFormControl(
        !!reselectionItem ? reselectionItem.areaServed : ''
      );

      this.locationField = new UntypedFormControl(
        !!reselectionItem ? reselectionItem.location : ''
      );

      this.formGroup = new UntypedFormGroup({
        quantity: this.quantityField,
        tag: this.tagField,
        area: this.areaField,
        location: this.locationField,
      });
    }

    this.validate = false;
  }

  ngOnInit() {
    if (!!this.dialogData.item) {
      this.revisionItem = this.dialogData.item;
      this.size = this.revisionItem.sizeData;
      this.tagField.setValue(this.revisionItem.tag);
      this.quantityField.setValue(this.revisionItem.quantity);
      this.locationField.setValue(this.revisionItem.location);
      this.areaField.setValue(this.revisionItem.areaServed);
    } else {
      this.size = this.dialogData.size;
    }

    this.setTab(this.dialogData.tab ? this.dialogData.tab : 'information');

    if (this.layout.canInflateValidSize(this.size)) {
      let loading = true;

      this.core.showLoadingGraphic('Loading...', function () {
        return loading;
      });

      this.layout.inflateValidSize(this.size).then(() => {
        loading = false;

        this.core.hideLoadingGraphic();

        this.calculateSPCorrections();

        this.getERTableDS();

        this.getCoolingDetailDS();

        this.getHeatingDetailDS();

        this.getCircInstallClearDS();

        this.getDimensionDS();

        this.tagFocus();

        this.fanSelectionFeiCalculation();

        this.TAPCalculation();
      });
    } else {
      this.calculateSPCorrections();

      this.getERTableDS();

      this.getCoolingDetailDS();

      this.getHeatingDetailDS();

      this.getCircInstallClearDS();

      this.getDimensionDS();

      this.tagFocus();
    }
  }

  ngAfterViewChecked(): void {
    this.updateScrollJumpItems();
  }

  ngOnDestroy() {
    this._projectChangedSubscription.unsubscribe();
  }

  private getDimensionDS() {
    if (!!this.size && !!this.size.dimensions) {
      this.dimensionsDS.data = this.size.dimensions
        .filter((dimension) => dimension.value > 0)
        .map((dimension) => {
          let value: string = formatNumber(dimension.value, 'en-US', '1.0-3');

          switch (this.size?.selectionLayoutConfig?.modelGroup) {
            case 'Circulator': {
              if (
                dimension.description.toLowerCase().indexOf('diameter') > -1
              ) {
                value = `${formatNumber(
                  dimension.value / 12,
                  'en-US',
                  '1.0-3'
                )} ft.`;
              } else {
                value = `${formatNumber(
                  dimension.value,
                  'en-US',
                  '1.0-3'
                )} in.`;
              }

              break;
            }
            case 'MakeUpAir':
            case 'Preconditioner': {
              value = dimension.value.toString();

              break;
            }
          }

          return {
            name: dimension.name,
            value: dimension.value,
            formattedValue: value,
            description: dimension.description,
            displayName: dimension.displayName,
          };
        });
    }
  }

  tabChange($event: MatTabChangeEvent) {
    switch ($event.tab.textLabel.toLowerCase().trim()) {
      case 'information': {
        this.setTab('information');

        break;
      }
      case 'customize': {
        this.setTab('customize');

        break;
      }
      case 'controls sequence': {
        this.setTab('soo');

        break;
      }
      case 'revit': {
        this.setTab('revit');

        break;
      }
      default: {
        if (this.revitComms.isRevitApp && $event.index === 1) {
          this.setTab('revit');
        }
        break;
      }
    }
  }

  tabChangeDone() {
    if (this.selectedTab === 'customize' || this.selectedTab === 'revit') {
      const self = this;

      setTimeout(() => {
        self.tagInputField.nativeElement.focus();
      }, 500);
    } else if (this.selectedTab === 'information') {
      this.calculateSPCorrections();

      this.getDimensionDS();
    }
  }

  setTab(name?: string) {
    this.selectedTab = name || this.selectedTab;

    switch (this.selectedTab.toLowerCase().trim()) {
      case 'information': {
        this.tabControl.selectedIndex = 0;

        break;
      }
      case 'customize': {
        this.tabControl.selectedIndex = 1;

        break;
      }
      case 'soo': {
        this.tabControl.selectedIndex = 3;

        break;
      }
      case 'revit': {
        this.tabControl.selectedIndex = 1;

        break;
      }
    }
  }

  getSooApplication(): LayoutConfigQuestion {
    if (
      !!this.size &&
      !!this.size.layoutConfig &&
      this.size.layoutConfig.getGroup('SequenceOfOperations')
    ) {
      return this.size.layoutConfig
        .getGroup('SequenceOfOperations')
        .questions.find(
          (group) =>
            group.enabled &&
            group.name.toLowerCase().trim() === 'sooapplication'
        );
    }
  }

  showSequenceTab(): boolean {
    if (!this.isRevitPlugin && this.size.businessUnit) {
      const bu = this.size.businessUnit.toLowerCase();

      if (['mua', 'erv'].indexOf(bu) > -1) {
        return true;
      }
    } else {
      return false;
    }
  }

  getSooQuestions(): Array<LayoutConfigQuestion> {
    if (
      !!this.size &&
      !!this.size.layoutConfig &&
      this.size.layoutConfig.getGroup('SequenceOfOperations')
    ) {
      return this.size.layoutConfig
        .getGroup('SequenceOfOperations')
        .questions.filter(
          (group) =>
            group.enabled &&
            group.name.toLocaleLowerCase().trim() !== 'sooapplication' &&
            group.visible
        );
    }
  }

  filterSooQuestions(question: LayoutConfigQuestion) {
    let answerArray = [];
    if (question.name.toLowerCase() === 'sooapplication') {
      answerArray = question.answers.filter(
        (answer) =>
          answer.visible &&
          answer.enabled &&
          answer.state.toLowerCase() !== 'blocked'
      );
    } else {
      const state = ['assignable', 'forceable'];
      answerArray = question.answers.filter(
        (answer) =>
          answer.visible &&
          answer.enabled &&
          state.indexOf(answer.state.toLowerCase()) > -1
      );
    }

    return answerArray;
  }

  sooGroupDesc(question: LayoutConfigQuestion) {
    const sooMetaData = JSON.parse(
      this.size.layoutConfig.getQuestion('SequenceOfOperationsMetaData').value
    );

    return sooMetaData[question.name];
  }

  sooQuestionAnswered(question: LayoutConfigQuestion) {
    let updating = true;

    this.core.showLoadingGraphic('Updating...', () => updating);

    const answers = {};

    answers[question.name] = question.value;

    this.layout
      .updateLayoutConfig(this.size.layoutConfig, answers)
      .then(async () => {
        if (!!this.revisionItem) {
          const localProject = await this.projectsService.getLocalProject();

          localProject.isDirty = true;
        }

        updating = false;

        this.core.hideLoadingGraphic();
      });
  }

  tagFocus() {
    const self = this;

    if (this.selectedTab === 'customize') {
      setTimeout(function () {
        (<HTMLElement>self.tagInputField.nativeElement).focus();
      });
    }
  }

  scheduleClick() {
    this.validate = false;

    const self = this;

    if (this.selectedTab !== 'customize') {
      this.setTab('customize');

      setTimeout(function () {
        (<HTMLElement>self.tagInputField.nativeElement).focus();
      });
    } else {
      this.validate = true;

      if (this.quantityField.valid && this.tagField.valid) {
        this.lachesisService.trackEvent({
          eventName: 'Schedule - Add',
          slideout: 'Product Info',
          slideoutTab: 'Customize',
          modelGroup: this.size.selectionLayoutConfig.modelGroup,
          productType: this.size.productType,
          model: this.size.model,
        });

        this.analytics.addToCart({
          source: 'Product Info Slideout',
          action: 'Schedule',
          items: [
            {
              model: this.size.model,
              modelName: this.size.name,
              quantity: this.quantityField.value,
            },
          ],
        });

        let adding = true;

        this.core.showLoadingGraphic('Adding...', () => adding);

        this.layout
          .copyLayoutConfiguration(
            this.size.selectionLayoutConfig,
            this.size.businessUnit
          )
          .then(
            (newLayoutConfig) => {
              this.layout
                .copyLayoutConfiguration(
                  this.size.layoutConfig,
                  this.size.businessUnit
                )
                .then(
                  (newSizeLayoutConfig) => {
                    this.layout
                      .copyLayoutSelection(
                        newLayoutConfig,
                        this.size.selectionKey
                      )
                      .then(
                        (newSelectionResults) => {
                          const productType = new CamelCasePipe().transform(
                            this.size.productType
                          );

                          const keys = {};

                          keys[productType + 'SelectionKey'] =
                            newSelectionResults.key;
                          keys[productType + 'SelectorLayoutEntityKey'] =
                            newLayoutConfig.key;
                          keys[productType + 'SelectionSizeId'] = this.size.id;
                          keys['productLayoutEntityKey'] =
                            newSizeLayoutConfig.key;

                          let result = ProductInfoDialogReturnTypes.Cancel;

                          const cloneSize = new ValidSize(
                            newLayoutConfig,
                            this.size.productType,
                            newSelectionResults.key,
                            this.size
                          );

                          this.projectsService.getLocalProject().then(
                            (project) => {
                              cloneSize.layoutConfig = newSizeLayoutConfig;

                              this.projectsService
                                .addItemToRevision(
                                  project,
                                  cloneSize.productType,
                                  cloneSize.name,
                                  this.quantityField.value,
                                  this.tagField.value,
                                  this.locationField.value,
                                  this.areaField.value,
                                  keys,
                                  cloneSize
                                )
                                .then(
                                  () => {
                                    result = ProductInfoDialogReturnTypes.Added;

                                    adding = false;

                                    this.core.hideLoadingGraphic();

                                    this.sideDialogService.close(result);
                                  },
                                  (errorData) => {
                                    result = ProductInfoDialogReturnTypes.Error;

                                    adding = false;

                                    this.core.hideLoadingGraphic();

                                    this.sideDialogService.close(result);

                                    if (
                                      errorData.message.indexOf(
                                        'There is a limit of'
                                      ) === 0
                                    ) {
                                      this.core.showMessageBox({
                                        title: 'Cannot Add To Project',
                                        message: errorData.message,
                                        icon: 'highlight_off',
                                        iconClasses: 'warn',
                                      });
                                    } else {
                                      throw errorData;
                                    }
                                  }
                                );
                            },
                            (errorData) => {
                              adding = false;

                              this.core.hideLoadingGraphic();

                              this.sideDialogService.close(
                                ProductInfoDialogReturnTypes.Error
                              );

                              throw errorData;
                            }
                          );
                        },
                        (errorData) => {
                          adding = false;

                          this.core.hideLoadingGraphic();

                          this.sideDialogService.close(
                            ProductInfoDialogReturnTypes.Error
                          );

                          throw errorData;
                        }
                      );
                  },
                  (errorData) => {
                    adding = false;

                    this.core.hideLoadingGraphic();

                    this.sideDialogService.close(
                      ProductInfoDialogReturnTypes.Error
                    );

                    throw errorData;
                  }
                );
            },
            (errorData) => {
              adding = false;

              this.core.hideLoadingGraphic();

              this.sideDialogService.close(ProductInfoDialogReturnTypes.Error);

              throw errorData;
            }
          );
      }
    }
  }

  cutSheetClick() {
    this.lachesisService.trackEvent({
      eventName: 'Cut-Sheet',
      slideout: 'Product Info',
      slideoutTab: 'Info',
      modelGroup: this.size.selectionLayoutConfig.modelGroup,
      productType: this.size.productType,
      model: this.size.model,
      retrievalMethod: 'Download',
      revitVersion: this.revitComms.isRevitApp
        ? this.revitComms.revitVersion
        : '',
      revitPluginVersion: this.revitComms.isRevitApp
        ? this.revitComms.revitPluginVersion
        : '',
    });

    if (this.isRevitPlugin) {
      this.analytics.trackEvent_Old(
        EventCategories.revitAddin,
        'Performance',
        'Cut-Sheet',
        this.size.model
      );
    } else {
      this.analytics.trackEvent_Old(
        EventCategories.eCAPS,
        'Cut-Sheet',
        this.size.selectionLayoutConfig.modelGroup,
        this.size.model
      );
    }

    let downloading = true;

    this.core.showLoadingGraphic('Generating...', () => downloading);

    this.analytics.trackEvent_Old(
      EventCategories.cutSheetProductDialog,
      'Downloads'
    );

    this.analytics.viewItem('Cut Sheet', {
      source: 'Product Info Slideout',
      action: 'Cut Sheet',
      deliveryMethod: 'Download',
      items: [this.size.getGAModel()],
    });

    this.documentsService.getSizeCutSheetPDF(this.size).then(
      () => {
        downloading = false;

        this.core.hideLoadingGraphic();
      },
      () => {
        downloading = false;

        this.core.hideLoadingGraphic();
      }
    );
  }

  closeClick() {
    this.sideDialogService.close(
      this.configUpdated
        ? ProductInfoDialogReturnTypes.Updated
        : ProductInfoDialogReturnTypes.Cancel
    );
  }

  scrollJump(className: string) {
    this.infoTabContent.nativeElement.scrollTop =
      this.infoTabContent.nativeElement.querySelector(`.${className}`)
        .offsetTop - 29;
  }

  sizeConfigGroups(): Array<LayoutConfigGroup> {
    if (
      !!this.size &&
      this.size.layoutConfig &&
      this.size.layoutConfig.group &&
      this.size.layoutConfig.group.groups
    ) {
      return this.size.layoutConfig.group.groups.filter(
        (subGroup) => subGroup.visible === true
      );
    } else {
      return null;
    }
  }

  private calculateSPCorrections() {
    if (this.size.productType.toLowerCase().trim().indexOf('fan') !== 0) {
      return;
    }

    const staticPressureQuestion =
      this.size.selectionLayoutConfig.getQuestion('StaticPressure');

    const staticPressure = !!staticPressureQuestion
      ? Number(staticPressureQuestion.value)
      : null;

    this.totalStaticPressure = staticPressure !== null ? staticPressure : 0;

    let directCorrection = 0;

    if (this.size.outputs['driveType'].toLowerCase().trim() === 'direct') {
      directCorrection =
        Number(this.size.outputs['operatingStaticPressure']) - staticPressure;
    }

    let totalCorrections = 0;

    if (!!this.size.outputs['staticPressureCorrections']) {
      for (const key of Object.keys(
        this.size.outputs['staticPressureCorrections']
      )) {
        if (
          this.size.outputs['staticPressureCorrections'][key] > 0 &&
          !isNaN(this.size.outputs['staticPressureCorrections'][key])
        ) {
          totalCorrections +=
            this.size.outputs['staticPressureCorrections'][key];
        }
      }
    }

    this.totalStaticPressure += totalCorrections;

    let directDriveAdjust = null;

    if (directCorrection !== 0) {
      directDriveAdjust = directCorrection - totalCorrections;

      this.totalStaticPressure =
        staticPressure + directDriveAdjust + totalCorrections;
    } else {
      directDriveAdjust = null;
    }

    this.spCorrectionsDS = [
      {
        name: 'External SP',
        value: staticPressure,
      },
    ];

    const camelToDisplay = new CamelToDisplayPipe();

    if (this.size.outputs['staticPressureCorrections']) {
      for (const key of Object.keys(
        this.size.outputs['staticPressureCorrections']
      )) {
        if (
          this.size.outputs['staticPressureCorrections'][key] > 0 &&
          !isNaN(this.size.outputs['staticPressureCorrections'][key])
        ) {
          this.spCorrectionsDS.push({
            name: camelToDisplay.transform(key),
            value: this.size.outputs['staticPressureCorrections'][key],
          });
        }
      }
    }

    if (directDriveAdjust !== null) {
      this.spCorrectionsDS.push({
        name: 'Direct Drive RPM Adjust.',
        value: directDriveAdjust,
      });
    }

    // Only show static pressure corrections if the difference from the entered SP is greater than the tolerance
    // If the number of decimal places shown in the UI changes, the tolerance needs to be adjusted to account for that (currently 3, as in 0.000)
    const tolerance = 0.0009;

    this.showSPCorrections =
      staticPressure !== null &&
      Math.abs(this.totalStaticPressure - staticPressure) > tolerance &&
      this.size.productType.toLowerCase().trim().indexOf('fan') === 0;

    const self = this;

    setTimeout(() => {
      self.chartSpacing =
        !!this.spCorrectionsDiv &&
        this.spCorrectionsDiv.nativeElement.clientHeight - 202 > 0
          ? this.spCorrectionsDiv.nativeElement.clientHeight - 202
          : 0;
    });
  }

  private getERTableDS() {
    if (
      !this.size.model ||
      (this.size.model.toLowerCase().trim() !== 'rve' &&
        this.size.model.toLowerCase().trim() !== 'rvc')
    ) {
      return;
    }

    const zeroDash = new ZeroToDashPipe();

    this.erTableDS = [
      {
        name: 'Dry Bulb (&deg;F)',
        summerEntering: this.size.selectionLayoutConfig.getQuestion(
          'summerDryBulbOutdoorAirEntering'
        ).value,
        summerLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .summerDryBulbOutdoorAirLeaving,
        winterEntering: this.size.selectionLayoutConfig.getQuestion(
          'winterDryBulbOutdoorAirEntering'
        ).value,
        winterLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterDryBulbOutdoorAirLeaving,
      },
      {
        name: 'Wet Bulb (&deg;F)',
        summerEntering: this.size.selectionLayoutConfig.getQuestion(
          'summerWetBulbOutdoorAirEntering'
        ).value,
        summerLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .summerWetBulbOutdoorAirLeaving,
        winterEntering:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterWetBulbOutdoorAirEntering,
        winterLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterWetBulbOutdoorAirLeaving,
      },
      {
        name: 'Spec. Humidity (gr/lb)',
        summerEntering: zeroDash.transform(
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .summerSpecificHumidityOutdoorAirEntering,
          '1.0-0'
        ),
        summerLeaving: zeroDash.transform(
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .summerSpecificHumidityOutdoorAirLeaving,
          '1.0-0'
        ),
        winterEntering: zeroDash.transform(
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterSpecificHumidityOutdoorAirEntering,
          '1.0-0'
        ),
        winterLeaving: zeroDash.transform(
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterSpecificHumidityOutdoorAirLeaving,
          '1.0-0'
        ),
      },
      {
        name: 'Enthalpy (BTU/lb)',
        summerEntering:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .summerEnthalpyOutdoorAirEntering,
        summerLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .summerEnthalpyOutdoorAirLeaving,
        winterEntering:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterEnthalpyOutdoorAirEntering,
        winterLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterEnthalpyOutdoorAirLeaving,
      },
      {
        name: 'Enthalpy Recovery Ratio (%)',
        summerEntering: 0,
        summerLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .totalOutdoorEfficiencySummer,
        winterEntering: 0,
        winterLeaving:
          this.size.outputs.housingWheelModel.energyRecoveryPerformance
            .totalOutdoorEfficiencyWinter,
      },
    ];
  }

  private getCoolingDetailDS() {
    if (
      !this.size.outputs.coolingModel ||
      this.size.outputs.coolingModel.coolingModelType.toLowerCase() === 'none'
    ) {
      return;
    }

    const zeroDash = new ZeroToDashPipe();

    const coolingTypeQuestion =
      this.size?.layoutConfig?.getQuestion('CoolingType');

    const coolingType = coolingTypeQuestion?.answers?.find(
      (answer) => answer.name === coolingTypeQuestion.value
    )?.text;

    this.coolingDetailDS = [
      {
        name: 'Cooling Type',
        value: coolingType,
      },
      {
        name: 'Coil Rows',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.coolCoilRows,
          '1.0-0'
        ),
      },
      {
        name: 'Coil Fins per Inch',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.coolCoilFinsPerInch,
          '1.0-0'
        ),
      },
      {
        name: 'Face Velocity (ft/min)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.coolingAirVelocityActual,
          '1.0-0'
        ),
      },
    ];

    if (
      this.size.outputs.coolingModel.selectedCoolingModelType
        .toLowerCase()
        .trim() === 'evaporative'
    ) {
      this.coolingDetailDS.splice(1, 3);

      this.coolingDetailDS.push({
        name: 'Face Velocity (ft/min)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.evapModel.directEvapFaceVelocity,
          '1.0-0'
        ),
      });

      this.coolingDetailDS.push({
        name: 'Flow Rate (GPM)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.evapModel.evapGpm,
          '1.1-1'
        ),
      });
    }

    this.coolingDetailDS.push({
      name: 'Entering Dry Bulb (&deg;F)',
      value: zeroDash.transform(
        this.size.outputs.coolingModel.enteringAirDryBulb,
        '1.1-1'
      ),
    });

    this.coolingDetailDS.push({
      name: 'Entering Wet Bulb (&deg;F)',
      value: zeroDash.transform(
        this.size.outputs.coolingModel.enteringAirWetBulb,
        '1.1-1'
      ),
    });

    this.coolingDetailDS.push({
      name: 'Leaving Dry Bulb (&deg;F)',
      value: zeroDash.transform(
        this.size.outputs.coolingModel.leavingAirDryBulb,
        '1.1-1'
      ),
    });

    this.coolingDetailDS.push({
      name: 'Leaving Wet Bulb (&deg;F)',
      value: zeroDash.transform(
        this.size.outputs.coolingModel.leavingAirWetBulb,
        '1.1-1'
      ),
    });

    if (
      this.size.outputs.coolingModel.coolingModelType.toLowerCase() ===
      'chilledwater'
    ) {
      this.coolingDetailDS.push({
        name: 'Entering Water (&deg;F)',
        value: zeroDash.transform(
          this.size.selectionLayoutConfig.getQuestion(
            'CoolCoilEnteringFluidTemperature'
          ).value,
          '1.1-1'
        ),
      });

      this.coolingDetailDS.push({
        name: 'Leaving Water (&deg;F)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.chilledWaterModel
            .coolCoilLeavingFluidTemperatureOut,
          '1.1-1'
        ),
      });

      this.coolingDetailDS.push({
        name: 'Flow Rate (GPM)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.chilledWaterModel
            .coolCoilFluidFlowRateOut,
          '1.1-1'
        ),
      });
    }

    if (
      this.size.outputs.coolingModel.selectedCoolingModelType
        .toLowerCase()
        .trim() !== 'evaporative'
    ) {
      this.coolingDetailDS.push({
        name: 'Coil PD (in. wg)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.coolingAirPressureDrop,
          '1.3-3'
        ),
      });
    } else {
      this.coolingDetailDS.push({
        name: 'Coil PD (in. wg)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.evapModel.directEvapAirPressureDrop,
          '1.3-3'
        ),
      });
    }

    if (
      !!this.size.outputs.fanModel.ismre &&
      this.size.outputs.fanModel.ismre !== 0
    ) {
      this.coolingDetailDS.push({
        name: 'ISMRE',
        value: zeroDash.transform(this.size.outputs.fanModel.ismre, '1.0-2'),
      });
    }
    if (
      !!this.size.outputs.fanModel.ismre2 &&
      this.size.outputs.fanModel.ismre2 !== 0
    ) {
      this.coolingDetailDS.push({
        name: 'ISMRE2',
        value: zeroDash.transform(this.size.outputs.fanModel.ismre2, '1.0-2'),
      });
    }

    if (
      this.size.outputs.coolingModel.coolingModelType.toLowerCase() ===
        'packageddx' &&
      this.size.selectionLayoutConfig
        .getQuestion('airFlowArrangement')
        .value.toLowerCase() === 'partialrecirc' &&
      !!this.size.outputs.coolingModel.pdxModel &&
      this.size.outputs.coolingModel.pdxModel.eerAhri !== 0
    ) {
      this.coolingDetailDS.push({
        name: 'AHRI EER',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.pdxModel.eerAhri,
          '1.1-1'
        ),
      });
    }

    if (
      this.size.outputs.coolingModel.coolingModelType.toLowerCase() ===
        'packageddx' &&
      this.size.selectionLayoutConfig
        .getQuestion('airFlowArrangement')
        .value.toLowerCase() === 'partialrecirc' &&
      !!this.size.outputs.coolingModel.pdxModel &&
      this.size.outputs.coolingModel.pdxModel.ieer !== 0
    ) {
      this.coolingDetailDS.push({
        name: 'AHRI IEER',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.pdxModel.ieer,
          '1.1-1'
        ),
      });
    }

    if (
      this.size.outputs.coolingModel.coolingModelType.toLowerCase() ===
        'packageddx' &&
      this.size.selectionLayoutConfig
        .getQuestion('hasReheat')
        .value.toLowerCase() === 'yes'
    ) {
      this.coolingDetailDS.push({
        name: 'Reheat Capacity (MBH)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.hotGasReheatCapacityMBH,
          '1.1-1'
        ),
      });

      this.coolingDetailDS.push({
        name: 'Reheat LAT (&deg;F)',
        value: zeroDash.transform(
          this.size.outputs.coolingModel.hotGasReheatCapacityLat,
          '1.1-1'
        ),
      });
    }
  }

  private getHeatingDetailDS() {
    this.getPrimaryHeatingDetailDS();

    if (
      !this.size.outputs.heatingModel ||
      this.size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() ===
        'none'
    ) {
      return;
    }

    const zeroDash = new ZeroToDashPipe();
    const camelToDisplay = new CamelToDisplayPipe();
    const decimalPipe = new DecimalPipe('en_us');

    this.heatingDetailDS = [
      {
        name: 'Heating Type',
        value: camelToDisplay.transform(
          this.size.outputs.heatingModel.selectedHeatingModelType
        ),
      },
    ];

    if (
      this.size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() ===
      'hotwater'
    ) {
      this.heatingDetailDS.push({
        name: 'Coil Rows',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.heatCoilRows,
          '1.0-0'
        ),
      });

      this.heatingDetailDS.push({
        name: 'Coil Fins per Inch',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.heatCoilFinsPerInch,
          '1.0-0'
        ),
      });

      this.heatingDetailDS.push({
        name: 'Face Velocity (ft/min)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.heatingAirVelocityActual,
          '1.0-0'
        ),
      });
    }

    this.heatingDetailDS.push({
      name: 'Entering Dry Bulb (&deg;F)',
      value: decimalPipe.transform(
        this.size.outputs.heatingModel.enteringDryBulb,
        '1.1-1'
      ),
    });

    this.heatingDetailDS.push({
      name: 'Leaving Dry Bulb (&deg;F)',
      value: zeroDash.transform(
        this.size.outputs.heatingModel.leavingDryBulb,
        '1.1-1'
      ),
    });

    if (
      this.size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() ===
      'hotwater'
    ) {
      this.heatingDetailDS.push({
        name: 'Entering Water (&deg;F)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.hotWaterModel.enteringFluidTemperature,
          '1.0-0'
        ),
      });

      this.heatingDetailDS.push({
        name: 'Leaving Water (&deg;F)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.hotWaterModel
            .leavingFluidTemperatureOut,
          '1.0-0'
        ),
      });

      this.heatingDetailDS.push({
        name: 'Flow Rate (GPM)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.hotWaterModel.heatCoilFluidFlowRateOut,
          '1.0-0'
        ),
      });

      this.heatingDetailDS.push({
        name: 'Capacity (MBH)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.heatingCapacity,
          '1.1-1'
        ),
      });
    } else if (
      this.size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() ===
      'indirectgas'
    ) {
      this.heatingDetailDS.push({
        name: 'Energy Input (MBH)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.indirectGasModel.inputMBH,
          '1.0-0'
        ),
      });

      this.heatingDetailDS.push({
        name: 'Energy Output (MBH)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.indirectGasModel.outputMBH,
          '1.1-1'
        ),
      });

      if (!!this.size.outputs.heatingModel.indirectGasModel.turndownRatio) {
        this.heatingDetailDS.push({
          name: 'Turndown Ratio',
          value: this.size.outputs.heatingModel.indirectGasModel.turndownRatio,
        });
      }

      if (
        !!this.size.outputs.heatingModel.indirectGasModel.minTemperatureRise
      ) {
        this.heatingDetailDS.push({
          name: 'Min Temp Rise (&deg;F)',
          value: zeroDash.transform(
            this.size.outputs.heatingModel.indirectGasModel.minTemperatureRise,
            '1.1-1'
          ),
        });
      }

      if (
        !!this.size.outputs.heatingModel.indirectGasModel.maxTemperatureRise
      ) {
        this.heatingDetailDS.push({
          name: 'Min Temp Rise (&deg;F)',
          value: zeroDash.transform(
            this.size.outputs.heatingModel.indirectGasModel.maxTemperatureRise,
            '1.1-1'
          ),
        });
      }
    } else if (
      this.size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() ===
      'electric'
    ) {
      this.heatingDetailDS.push({
        name: 'Heat Size (kW)',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.electricModel.electricHeatKw,
          '1.1-1'
        ),
      });
    }
    if (
      this.size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() ===
      'steam'
    ) {
      this.heatingDetailDS.push({
        name: 'Coil Rows',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.heatCoilRows,
          '1.0-0'
        ),
      });

      this.heatingDetailDS.push({
        name: 'Coil Fins per Inch',
        value: zeroDash.transform(
          this.size.outputs.heatingModel.heatCoilFinsPerInch,
          '1.0-0'
        ),
      });
    }
  }

  private getPrimaryHeatingDetailDS() {
    if (
      this.size?.selectionLayoutConfig?.getQuestion('HeatingTypePrimary')
        ?.visible
    ) {
      const decimalPipe = new DecimalPipe('en_us');

      this.primaryHeatingDetailDS = [
        {
          name: 'Heating Type',
          value:
            this.size.selectionLayoutConfig.getQuestion('HeatingTypePrimary')
              .value,
        },
        {
          name: 'Entering Dry Bulb (°F)',
          value: decimalPipe.transform(
            this.size?.outputs?.heatingModel?.enteringDryBulb,
            '1.0-1'
          ),
        },
        {
          name: 'Leaving Dry Bulb (°F)',
          value: decimalPipe.transform(
            this.size?.outputs?.coolingModel?.airSourceHeatPump
              ?.ashpDeratedLeavingAirDryBulbHeat,
            '1.0-1'
          ),
        },
        {
          name: 'Capacity (MBH)',
          value: decimalPipe.transform(
            this.size?.outputs?.coolingModel?.airSourceHeatPump
              ?.ashpDeratedHeatingCapacity,
            '1.0-1'
          ),
        },
      ];

      if (
        this.size.selectionLayoutConfig
          .getQuestion('airFlowArrangement')
          .value.toLowerCase() === 'partialrecirc'
      ) {
        this.primaryHeatingDetailDS.push({
          name: 'COP@17F',
          value: decimalPipe.transform(
            this.size?.outputs?.fanModel?.cop17,
            '1.0-2'
          ),
        });

        this.primaryHeatingDetailDS.push({
          name: 'COP@47F',
          value: decimalPipe.transform(
            this.size?.outputs?.fanModel?.cop47,
            '1.0-2'
          ),
        });
      } else if (
        this.size.selectionLayoutConfig
          .getQuestion('airFlowArrangement')
          .value.toLowerCase() === 'outdooraironly'
      ) {
        this.primaryHeatingDetailDS.push({
          name: 'ISCOP',
          value: decimalPipe.transform(
            this.size?.outputs?.fanModel?.iscop,
            '1.0-1'
          ),
        });

        this.primaryHeatingDetailDS.push({
          name: 'ISCOP2',
          value: decimalPipe.transform(
            this.size?.outputs?.fanModel?.iscop2,
            '1.0-2'
          ),
        });
      }
    }
  }

  private getCircInstallClearDS() {
    if (this.size.productType === ProductTypes.circulator) {
      this.circInstallClearDS = [
        {
          name: 'Minimum Fan Spacing (ft.)',
          value: this.size.outputs.minimumFanSpacing,
        },
        {
          name: 'Spacing to Wall (ft.)',
          value: this.size.layoutConfig.getQuestion('spacingToWall').value,
        },
        {
          name: 'Blade Tip Clearance (ft.)',
          value: this.size.layoutConfig.getQuestion('bladeTipClearance').value,
        },
        {
          name: 'Vertical Blade Clearance (ft.)',
          value: this.size.layoutConfig.getQuestion('verticalBladeClearance')
            .value,
        },
        {
          name: 'Standby Blade Deflection (in.)',
          value: this.size.layoutConfig.getQuestion('standbyBladeDeflection')
            .value,
        },
      ];
    }
  }

  showFanCurves(): boolean {
    if (
      !!this.size &&
      !!this.size.charts &&
      Object.keys(this.size.charts).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  getDimensionImage(type: DrawingTypes = DrawingTypes.image): string {
    const results = this.size.files.filter((file) => file.type === type);

    if (results && results.length > 0) {
      return environment.drawingContentBase + results[0].name;
    } else {
      return null;
    }
  }

  getChartGroups(): Array<string> {
    const output = [];

    for (const key of Object.keys(this.size.charts['operating'])) {
      if (!!this.size.charts['operating'][key]) {
        output.push(key);
      }
    }

    return output;
  }

  getDrawingFiles(): Array<FileInfo> {
    if (!this.isRevitPlugin) {
      return this.size && this.size.files
        ? this.size.files.filter((file) => file.type !== DrawingTypes.image)
        : null;
    } else {
      return this.size && this.size.files
        ? this.size.files.filter(
            (file) =>
              file.type !== DrawingTypes.image &&
              file.type !== DrawingTypes.revitLOD200 &&
              file.type !== DrawingTypes.revitLOD300
          )
        : null;
    }
  }

  getSubGroups(group: LayoutConfigGroup): Array<LayoutConfigGroup> {
    return group && group.groups
      ? group.groups.filter((subGroup) => subGroup.visible)
      : null;
  }

  getQuestions(group: LayoutConfigGroup): Array<LayoutConfigQuestion> {
    return group && group.questions
      ? group.questions.filter((question) => question.visible)
      : null;
  }

  questionAnswered() {
    this.configUpdated = true;

    let updating = true;

    this.core.showLoadingGraphic('Updating...', () => updating);

    this.layout.processCalculation(this.size).then(() => {
      this.layout
        .getValidSizeDimensions(this.size, this.size.layoutConfig.key, true)
        .then(() => {
          this.getDimensionDS();

          updating = false;

          this.core.hideLoadingGraphic();
        });
    });
  }

  fanSelectionFeiCalculation() {
    const allowProductTypes = [
      this.productTypes.fan,
    ];
    if (allowProductTypes.indexOf(this.size.layoutConfig.productType) != -1) {
      this.questionAnswered();
    }
  }

  TAPCalculation() {
    const allowProductTypes = [
      this.productTypes.temperedAirProduct,
      this.productTypes.makeUpAir,
      this.productTypes.preconditioners,
    ];
    if (allowProductTypes.indexOf(this.size.layoutConfig.productType) != -1) {
      this.questionAnswered();
    }
  }

  drawingDownload(dwg: FileInfo): boolean {
    const logAnalytics = () => {
      let eventType: ContentTypes = 'Image';

      switch (dwg.type) {
        case DrawingTypes.image: {
          eventType = 'Image';
          break;
        }
        case DrawingTypes.revitLOD200: {
          eventType = 'LOD 200';
          break;
        }
        case DrawingTypes.revitLOD300: {
          eventType = 'LOD 300';
          break;
        }
        case DrawingTypes.dwg2D: {
          eventType = '2D CAD';
          break;
        }
        case DrawingTypes.dwg3D: {
          eventType = '3D CAD';
          break;
        }
        case DrawingTypes.svg: {
          eventType = 'SVG';
          break;
        }
        case DrawingTypes.revitReadMe: {
          eventType = 'Read Me';
          break;
        }
      }

      this.analytics.trackEvent('Drawing', {
        model: this.size.model,
        modelName: this.size.name,
        action: 'Download',
        deliveryMethod: 'Download',
        contentType: eventType,
        source: 'Product Info Slideout',
      });
    };

    logAnalytics();

    if (dwg.type === DrawingTypes.revitLOD300) {
      this.lachesisService.trackEvent({
        eventName: 'Drawings',
        drawingType: DrawingTypes.revitLOD300,
        slideout: 'Product Info',
        slideoutTab: 'Info',
        modelGroup: this.size.selectionLayoutConfig.modelGroup,
        productType: this.size.productType,
        model: this.size.model,
        retrievalMethod: 'Download',
      });

      this.analytics.trackEvent_Old(
        this.revitComms.isRevitApp
          ? EventCategories.revitAddin
          : EventCategories.eCAPS,
        'Drawing Download',
        'Download 300',
        this.size.model
      );

      let generating = true;

      this.core.showLoadingGraphic('Generating...', () => generating);

      this.documentsService.getRevitLODFile(this.size).then(() => {
        generating = false;

        this.core.hideLoadingGraphic();
      });
    } else {
      this.lachesisService.trackEvent({
        eventName: 'Drawings',
        drawingType:
          dwg.type.toLowerCase() !== 'revit' ? dwg.type : dwg.type + ' Project',
        slideout: 'Product Info',
        slideoutTab: 'Info',
        modelGroup: this.size.selectionLayoutConfig.modelGroup,
        productType: this.size.productType,
        model: this.size.model,
        retrievalMethod: 'Download',
      });

      if (this.revitComms.isRevitApp) {
        this.revitComms.download(this.drawingBase + dwg.name, dwg.name);
      } else {
        this.externalComm.launchExternal(
          this.drawingBase + dwg.name,
          LaunchTypes.newWindow
        );
      }

      if (dwg.type === DrawingTypes.revitLOD200) {
        this.analytics.trackEvent_Old(
          this.revitComms.isRevitApp
            ? EventCategories.revitAddin
            : EventCategories.eCAPS,
          'Drawing Download',
          'Download 200',
          this.size.model
        );
      }

      this.analytics.trackEvent_Old(
        EventCategories.dimensions,
        'Downloads',
        dwg.type
      );
    }

    return true;
  }

  reselectClick() {
    this.itemReselectionDialog.show(this.revisionItem);
    this.sideDialogService.close();
  }

  updateClick() {
    if (this.selectedTab !== 'customize') {
      this.setTab('customize');
    } else {
      if (
        this.quantityField.value !== this.revisionItem.quantity ||
        this.tagField.value !== this.revisionItem.tag ||
        this.locationField.value !== this.revisionItem.location ||
        this.areaField.value !== this.revisionItem.areaServed
      ) {
        if (!this.tagField.valid) {
          return;
        }

        let updating = true;

        this.core.showLoadingGraphic('Updating...', function () {
          return updating;
        });

        this.projectsService
          .updateRevisionItem(this.revisionItem, {
            tag: this.tagField.value.toUpperCase().trim(),
            quantity: this.quantityField.value,
            location: this.locationField.value,
            areaServed: this.areaField.value,
          })
          .then(
            () => {
              updating = false;

              this.core.hideLoadingGraphic();

              this.sideDialogService.close(
                ProductInfoDialogReturnTypes.Updated
              );
            },
            (errorData) => {
              updating = false;

              this.core.hideLoadingGraphic();

              this.sideDialogService.close(
                ProductInfoDialogReturnTypes.Updated
              );

              throw errorData;
            }
          );
      } else {
        this.sideDialogService.close(
          this.configUpdated
            ? ProductInfoDialogReturnTypes.Updated
            : ProductInfoDialogReturnTypes.Cancel
        );
      }
    }
  }

  getFumeExhaustTableDS(): Array<any> {
    if (!this.fumeExhaustTableDS && !!this.size && !!this.size.outputs) {
      let systemInletVolume = this.size.outputs.operatingVolume;

      const fanCountName = this.size.selectionLayoutConfig.getQuestion(
        'ExhaustSystemFanQuantity'
      ).value;

      const fanCount =
        ['One', 'Two', 'Three', 'Four', 'Five'].findIndex(
          (element) =>
            element.toLowerCase().trim() === fanCountName.toLowerCase().trim()
        ) + 1;

      const redundantFanQuestion =
        this.size.selectionLayoutConfig.getQuestion('HasFanOnStandby');

      const redundantFan =
        !!redundantFanQuestion &&
        redundantFanQuestion.value.toLowerCase().trim() === 'yes'
          ? true
          : false;

      if (fanCount > 1) {
        systemInletVolume =
          this.size.outputs.operatingVolume *
          (fanCount - (redundantFan ? 1 : 0));
      }

      let entrainedVolume =
        this.size.outputs.entrainedVolume *
          (fanCount - (redundantFan ? 1 : 0)) -
        systemInletVolume;

      if (entrainedVolume < 0) {
        entrainedVolume = 0;
      }

      this.fumeExhaustTableDS = [
        {
          label: 'System Inlet Volume',
          volume: systemInletVolume,
        },
        {
          label: 'Bypass Air',
          volume: 0,
        },
        {
          label: 'Entrained Air',
          volume: entrainedVolume,
        },
      ];
    }

    return this.fumeExhaustTableDS;
  }

  getFumeExhaustTableDSTotal(): number {
    if (!!this.fumeExhaustTableDS) {
      let sum = 0;

      this.fumeExhaustTableDS.forEach((item) => {
        sum += item.volume;
      });

      return sum;
    } else {
      return 0;
    }
  }

  emailAllDrawings() {
    this.usersController.showLoginRequiredDialog(false).then((success) => {
      if (success) {
        this.usersService.getCurrentUser().then((userData) => {
          this.emailDialog
            .show('Email Model Drawing', `Model ${this.size.name} drawings`)
            .then((results) => {
              if (!!results && !!results.recipients) {
                const request = new DrawingRequest();

                results.recipients.forEach((recipient) => {
                  if (!!recipient.name && recipient.name.trim() !== '') {
                    request.toAddresses.push(
                      `"${recipient.name}" <${recipient.email}>`
                    );
                  } else {
                    request.toAddresses.push(recipient.email);
                  }
                });

                request.subject = results.subject;

                request.content.message = results.message;

                request.content.sizeName = this.size.name;

                request.content.fromName = `${userData.firstName} ${userData.lastName}`;

                request.content.toName =
                  !results.recipients[0].name ||
                  results.recipients[0].name.trim() === ''
                    ? results.recipients[0].email
                    : results.recipients[0].name;

                if (!!this.size && this.size.files) {
                  this.size.files
                    .filter(
                      (file) =>
                        [DrawingTypes.image, DrawingTypes.revitLOD300].indexOf(
                          file.type
                        ) === -1
                    )
                    .forEach((file) => {
                      request.content.drawings.push({
                        name: file.name,
                        url: `${this.drawingBase}${file.name}`,
                      });
                    });
                }

                let sending = true;

                this.core.showLoadingGraphic('Sending...', () => sending);

                this.lachesisService.trackEvent({
                  eventName: 'Drawings',
                  slideout: 'Product Info',
                  slideoutTab: 'Info',
                  modelGroup: this.size.selectionLayoutConfig.modelGroup,
                  productType: this.size.productType,
                  model: this.size.model,
                  drawingType: 'All',
                  retrievalMethod: 'Email',
                });

                this.analytics.trackEvent('Drawing', {
                  model: this.size.model,
                  modelName: this.size.name,
                  action: 'Email',
                  deliveryMethod: 'Email',
                  contentType: 'All',
                  source: 'Product Info Slideout',
                });

                this.emailService.sendDrawings(request).then(() => {
                  sending = false;

                  this.core.hideLoadingGraphic();
                });
              }
            });
        });
      }
    });
  }

  downloadRevitAddin() {
    window.open(
      'https://www.greenheck.com/my-account/download-ecaps-bridge-for-revit',
      '_blank'
    );
  }

  showAMCA(): boolean {
    if (
      !!this.size.outputs &&
      !!this.size.outputs.amcaCertId &&
      ['', 'n'].indexOf(this.size.outputs.amcaCertId.toLowerCase().trim()) ===
        -1
    ) {
      return true;
    } else if (
      !!this.size.features &&
      !!this.size.features.amcaCertId &&
      ['', 'n'].indexOf(this.size.features.amcaCertId.toLowerCase().trim()) ===
        -1
    ) {
      return true;
    } else {
      return false;
    }
  }

  disableRevitLodInsertAndDownload() {
    let result = false;

    if (!!this.size && !!this.size.files) {
      switch (this.tabControl.selectedIndex) {
        case 0: {
          // Information
          const revitLod200 = this.size.files.find(
            (item) => item.type === DrawingTypes.revitLOD200
          );
          const revitLod300 = this.size.files.find(
            (item) => item.type === DrawingTypes.revitLOD300
          );

          result = !!revitLod200 || !!revitLod300 ? false : true;
          break;
        }
        case 1: {
          // LOD 300
          const revitLod300 = this.size.files.find(
            (item) => item.type === DrawingTypes.revitLOD300
          );

          result = !!revitLod300 ? false : true;
          break;
        }
        case 2: {
          // LOD 200
          const revitLod200 = this.size.files.find(
            (item) => item.type === DrawingTypes.revitLOD200
          );

          result = !!revitLod200 ? false : true;
          break;
        }
      }
    }

    return result;
  }

  revitInsert() {
    switch (this.tabControl.selectedIndex) {
      case 0: {
        // Information
        this.tabControl.selectedIndex = 1;
        break;
      }
      case 1: {
        // LOD 300
        if (this.revitFormGroup.valid) {
          this.lachesisService.trackEvent({
            eventName: 'Drawings',
            drawingType: DrawingTypes.revitLOD300,
            slideout: 'Product Info',
            slideoutTab: 'Info',
            modelGroup: this.size.selectionLayoutConfig.modelGroup,
            productType: this.size.productType,
            model: this.size.model,
            retrievalMethod: 'Insert',
            revitPluginVersion: this.revitComms.revitPluginVersion,
            revitVersion: this.revitComms.revitVersion,
          });

          this.analytics.trackEvent_Old(
            EventCategories.revitAddin,
            'Performance',
            'Insert 300',
            this.size.model
          );

          this.analytics.trackEvent('Drawing', {
            model: this.size.model,
            modelName: this.size.name,
            action: 'Insert',
            deliveryMethod: 'Insert',
            contentType: 'LOD 300',
            source: 'Product Info Slideout',
          });

          let generating = true;

          this.core.showLoadingGraphic('Generating...', () => generating);

          this.documentsService
            .getRevitLODFile(
              this.size,
              this.tagField.value.toUpperCase().trim(),
              true
            )
            .then((data: Blob) => {
              const file = this.size.files.find(
                (item) => item.type === DrawingTypes.revitLOD300
              );
              this.revitComms.insertBlob(data, file.name);

              generating = false;

              this.core.hideLoadingGraphic();
            });
        }
        break;
      }
      case 2: {
        // LOD 200
        this.lachesisService.trackEvent({
          eventName: 'Drawings',
          drawingType: DrawingTypes.revitLOD200,
          slideout: 'Product Info',
          slideoutTab: 'Info',
          modelGroup: this.size.selectionLayoutConfig.modelGroup,
          productType: this.size.productType,
          model: this.size.model,
          retrievalMethod: 'Insert',
          revitPluginVersion: this.revitComms.revitPluginVersion,
          revitVersion: this.revitComms.revitVersion,
        });

        this.analytics.trackEvent_Old(
          EventCategories.revitAddin,
          'Performance',
          'Insert 200',
          this.size.model
        );

        this.analytics.trackEvent('Drawing', {
          model: this.size.model,
          modelName: this.size.name,
          action: 'Insert',
          deliveryMethod: 'Insert',
          contentType: 'LOD 200',
          source: 'Product Info Slideout',
        });

        const file = this.size.files.find(
          (item) => item.type === DrawingTypes.revitLOD200
        );
        this.revitComms.insert(this.drawingBase + file.name, file.name);
        break;
      }
    }
  }

  revitDownload() {
    switch (this.tabControl.selectedIndex) {
      case 0: {
        // Information
        this.tabControl.selectedIndex = 1;
        break;
      }
      case 1: {
        // LOD 300
        if (this.revitFormGroup.valid) {
          this.lachesisService.trackEvent({
            eventName: 'Drawings',
            drawingType: DrawingTypes.revitLOD300,
            slideout: 'Product Info',
            slideoutTab: 'Info',
            modelGroup: this.size.selectionLayoutConfig.modelGroup,
            productType: this.size.productType,
            model: this.size.model,
            retrievalMethod: 'Download',
            revitVersion: this.revitComms.revitVersion,
            revitPluginVersion: this.revitComms.revitPluginVersion,
          });

          this.analytics.trackEvent_Old(
            EventCategories.revitAddin,
            'Performance',
            'Download 300',
            this.size.model
          );

          this.analytics.trackEvent('Drawing', {
            model: this.size.model,
            modelName: this.size.name,
            action: 'Download',
            deliveryMethod: 'Download',
            contentType: 'LOD 300',
            source: 'Product Info Slideout',
          });

          let generating = true;

          this.core.showLoadingGraphic('Generating...', () => generating);

          this.documentsService
            .getRevitLODFile(
              this.size,
              this.tagField.value.toUpperCase().trim()
            )
            .then(() => {
              generating = false;

              this.core.hideLoadingGraphic();
            });
        }
        break;
      }
      case 2: {
        // LOD 200
        this.lachesisService.trackEvent({
          eventName: 'Drawings',
          drawingType: DrawingTypes.revitLOD200,
          slideout: 'Product Info',
          slideoutTab: 'Info',
          modelGroup: this.size.selectionLayoutConfig.modelGroup,
          productType: this.size.productType,
          model: this.size.model,
          retrievalMethod: 'Download',
          revitVersion: this.revitComms.revitVersion,
          revitPluginVersion: this.revitComms.revitPluginVersion,
        });

        this.analytics.trackEvent_Old(
          EventCategories.revitAddin,
          'Performance',
          'Download 200',
          this.size.model
        );

        this.analytics.trackEvent('Drawing', {
          model: this.size.model,
          modelName: this.size.name,
          action: 'Download',
          deliveryMethod: 'Download',
          contentType: 'LOD 200',
          source: 'Product Info Slideout',
        });

        const file = this.size.files.find(
          (item) => item.type === DrawingTypes.revitLOD200
        );
        this.revitComms.download(this.drawingBase + file.name, file.name);
        break;
      }
    }
  }

  // For DEV Purposes Only
  logOutValidationErrors() {
    this.size.layoutConfig.validationMessages.forEach((message) => {
      console.error(message.type + ': ' + message.message);
    });
  }

  private updateScrollJumpItems(): void {
    const availableScrollJumpItems: (
      | scrollJumpItemStdIcon
      | scrollJumpItemSvgIcon
    )[] = [
      {
        class: 'pic-description',
        icon: 'info_outline',
        title: 'Description',
      },
      {
        class: 'pic-air-flows',
        icon: 'call_merge',
        title: 'Air Flows',
      },
      {
        class: 'pic-circulator-performance',
        svgIcon: 'mfan',
        title: 'Coverage & Air Speed',
      },
      {
        class: 'pic-circulator-spacing',
        icon: 'linear_scale',
        title: 'Fan Spacing',
      },
      {
        class: 'pic-fan-curves',
        svgIcon: 'chart',
        title: 'Fan Curves',
      },
      {
        class: 'pic-circ-install-clear',
        icon: 'control_camera',
        title: 'Installation Clearances',
      },
      {
        class: 'pic-dimensions',
        svgIcon: 'dimensions',
        title: 'Dimensions',
      },
      {
        class: 'pic-drawings',
        svgIcon: 'drawing',
        title: 'Drawings',
      },
      {
        class: 'pic-energy-recovery',
        svgIcon: 'er_wheel',
        title: 'Energy Recovery',
      },
      {
        class: 'pic-cooling-perf',
        icon: 'ac_unit',
        title: 'Cooling Performance',
      },
      {
        class: 'pic-heating-perf',
        icon: 'whatshot',
        title: 'Heating Performance',
      },
      {
        class: 'pic-amca',
        svgIcon: 'amca_icon',
        title: 'AMCA',
      },
    ];

    const contentParent = this.infoTabContent?.nativeElement as HTMLDivElement;

    if (!!contentParent) {
      const newScrollJumpItems: (
        | scrollJumpItemStdIcon
        | scrollJumpItemSvgIcon
      )[] = [];

      contentParent.querySelectorAll(':scope > .mat-mdc-card').forEach((el) => {
        // Find classes that begin with 'pic-'

        const classList = el.classList;

        for (let i = 0; i < classList.length; i++) {
          const className = classList[i];

          if (className.indexOf('pic-') === 0) {
            const scrollJumpItem = availableScrollJumpItems.find(
              (item) => item.class === className
            );

            if (!!scrollJumpItem) {
              const { title, ...rest } = scrollJumpItem;
              const newTitle = (
                el.querySelector(
                  '.mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title'
                ) as HTMLDivElement
              )?.innerText.trim();

              newScrollJumpItems.push({ title: newTitle ?? title, ...rest });
            }

            break;
          }
        }
      });

      if (newScrollJumpItems.length === this.scrollJumpItems.length) {
        newScrollJumpItems.every((item, index) => {
          if (item.class !== this.scrollJumpItems[index].class) {
            this.scrollJumpItems = newScrollJumpItems;

            return false;
          } else {
            return true;
          }
        });
      } else {
        this.scrollJumpItems = newScrollJumpItems;
      }
    } else {
      this.scrollJumpItems = [];
    }
  }
}
