export class InvalidSize {
    id: string;
    message: string;
    name: string;
    constructor(sizeData: any) {
        this.id = sizeData.id;
        this.message = sizeData.message;
        this.name = sizeData.name;
    }
    toJSON(): object {
        const output = {
            id: this.id,
            message: this.message,
            name: this.name
        };
        return output;
    }
}
