import { Router, NavigationEnd } from '@angular/router';
import { LoadingLayer } from './models/loading-layer.model';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingGraphic {
  private layers = new Array<LoadingLayer>();

  public layersUpdated = new EventEmitter<void>();

  constructor(router: Router) {
    router.events.subscribe((eventData) => {
      if (eventData instanceof NavigationEnd && eventData.id !== 1) {
        this.hide(true);
      }
    });
  }

  public show(text: string, verifier: () => boolean) {
    this.layers.push(new LoadingLayer(text, verifier));

    this.layers = this.layers.filter((layer) => layer.verifier());

    this.layersUpdated.next();
  }

  public hide(all: boolean = false) {
    if (all === true) {
      this.layers = new Array<LoadingLayer>();
    } else {
      this.layers = this.layers.filter((layer) => layer.verifier());
    }

    this.layersUpdated.next();
  }

  getLayers(): Array<LoadingLayer> {
    return this.layers;
  }
}
