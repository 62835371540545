export class ChartAxis {
    label: string;
    ticks: Array<number>;
    constructor(axisData: any) {
        axisData = (axisData || {});
        this.label = axisData.label;
        this.ticks = new Array<number>();
        if (axisData.ticks && axisData.ticks.length > 0) {
            for (let i = 0; i < axisData.ticks.length; i++) {
                this.ticks.push(axisData.ticks[i]);
            }
        }
    }
}
