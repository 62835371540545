import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { ColumnList } from '../constants/column-list.const';
import { ProductTypes } from '../../../../products/enums/product-types.enum';

@Component({
  selector: 'erv-equipment-schedule-grid',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErvEquipmentScheduleGridComponent
  extends BaseGridComponent
  implements OnInit
{
  constructor(protected injector: Injector) {
    super(injector);

    this.productType = ProductTypes.preconditioners;
  }

  ngOnInit(): void {
    this.columnList = ColumnList.getColumns(
      'model',
      'summerDBWB',
      'winterDBWB',
      'winterASHRAE901',
      'mcaMop',
      'weight',
      'dimensions',
      'supplyTotalBhp',
      'supplySize',
      'exhaustBhp',
      'exhaustSize',
      'summerASHRAE901ERR',
      'supplyFanRPM',
      'supplyOutletdBA',
      'supplyOutletSones',
      'exhaustFRPM',
      'exhaustInletSoundDba',
      'exhaustFanInletSoundSones',
      'fanPower'
    );

    this.setVisibleColumns([
      'model',
      'summerASHRAE901ERR',
      'summerDBWB',
      'winterDBWB',
      'winterASHRAE901',
      'mcaMop',
      'weight',
      'dimensions',
    ]);
  }
}
