import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { DocumentTypes } from '../../enums/document-types.enum';

@Component({
  selector: 'app-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss'],
})
export class DocumentSelectorComponent implements OnInit {
  documentList = [
    {
      type: DocumentTypes.equipmentSchedule,

      text: 'Schedule',

      svgIcon: 'excel',
    },

    {
      type: DocumentTypes.cutSheet,

      text: 'Cut Sheet',

      svgIcon: 'pdf',
    },

    {
      type: DocumentTypes.revit,

      text: 'Revit',

      svgIcon: 'revit',
    },

    {
      type: DocumentTypes.capsExport,

      text: 'CAPS File Export',

      svgIcon: 'exportb',
    },
  ];

  constructor(
    private sheetRef: MatBottomSheetRef<DocumentSelectorComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private documentTypes: DocumentTypes[]
  ) {
    this.documentList.forEach((item) => {
      if (
        documentTypes.findIndex((typeName) => typeName === item.type) === -1
      ) {
        this.documentList.splice(this.documentList.indexOf(item), 1);
      }
    });
  }

  ngOnInit() {}

  downloadAll() {
    this.sheetRef.dismiss(this.documentTypes);
  }

  download(type: DocumentTypes) {
    this.sheetRef.dismiss([type]);
  }
}
