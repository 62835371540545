import { Answer } from './answer.model';
import { CamelCasePipe } from '../../../ecaps-core/pipes/custom-pipes.pipe';

export class Question {
    answers: Array<Answer>;
    text: string;
    enabled: boolean;
    helpKey: string;
    messages: Array<string>;
    name: string;
    nullable: boolean;
    required: boolean;
    state: string;
    type: string;
    unitOfMeasure: string;
    valid: boolean;
    validationFeatures: Object;
    value: string;
    valueDisplayMask: string;
    visible: boolean;

    constructor(questionConfig: any) {
        this.answers = new Array<Answer>();

        if (questionConfig.answers && questionConfig.answers.length) {
            for (let i = 0; i < questionConfig.answers.length; i++) {
                this.answers.push(new Answer(questionConfig.answers[i]));
            }
        }

        this.text = questionConfig.displayName;
        this.enabled = questionConfig.enabled;
        this.helpKey = questionConfig.helpKey;

        this.messages = new Array<string>();

        if (questionConfig.messages && questionConfig.messages.length > 0) {
            for (let i = 0; i < questionConfig.messages.length; i++) {
                this.messages.push(questionConfig.messages[i]);
            }
        }

        this.name = questionConfig.name;
        this.nullable = questionConfig.nullable;
        this.required = questionConfig.required;
        this.state = questionConfig.state;
        this.type = questionConfig.type;
        this.unitOfMeasure = questionConfig.unitOfMeasure;
        this.valid = questionConfig.valid;

        this.validationFeatures = {};

        const camelCase = new CamelCasePipe();

        if (questionConfig.validationFeatures && questionConfig.validationFeatures.length > 0) {
            for (let i = 0; i < questionConfig.validationFeatures.length; i++) {
                const newName = camelCase.transform(questionConfig.validationFeatures[i].name);

                this.validationFeatures[newName] = questionConfig.validationFeatures[i].value;
            }
        }

        if (questionConfig.value && typeof (questionConfig.value) === 'string' &&
            questionConfig.value.toUpperCase().trim() === 'NULL') {
            this.value = null;
        } else {
            this.value = questionConfig.value;
        }

        this.valueDisplayMask = questionConfig.valueDisplayMask;
        this.visible = questionConfig.visible;
    }

    getAnswer(name: string): Answer {

        if (this.answers && this.answers.length > 0) {
            for (let i = 0; i < this.answers.length; i++) {
                if ((this.answers[i].name === name) ||
                    (this.answers[i].name && this.answers[i].name.toLowerCase().trim() === name.toLowerCase().trim())) {
                    return this.answers[i];
                }
            }
        }

        return null;

    }

    toJSON() {

        return {

            answers: (!!this.answers ? this.answers.map(answer => answer.toJSON()) : null),
            text: this.text,
            enabled: this.enabled,
            helpKey: this.helpKey,
            messages: this.messages,
            name: this.name,
            nullable: this.nullable,
            required: this.required,
            state: this.state,
            type: this.type,
            unitOfMeasure: this.unitOfMeasure,
            valid: this.valid,
            validationFeatures: this.validationFeatures,
            value: this.value,
            valueDisplayMask: this.valueDisplayMask,
            visible: this.visible

        };

    }

}
