<side-dialog-title>Model Comparison</side-dialog-title>

<side-dialog-content>
  <mat-tab-group
    color="primary"
    [mat-stretch-tabs]="false"
    (selectedTabChange)="tabChanged($event)"
  >
    <mat-tab label="Information">
      <div
        class="information"
        [ngClass]="'compare-count__' + this.dialogData.length"
      >
        <div
          class="header-row"
          [style.paddingRight.px]="
            bodyContent.offsetWidth - bodyContent.clientWidth
          "
        >
          <div class="heading" *ngFor="let size of sizeList">
            <div class="title" [class.warning]="!size.stability.stable">
              <mat-icon
                *ngIf="!size.stability.stable"
                (click)="cautionClick(size)"
                class="dp20"
                title="CAUTION: Click icon for more information"
                >warning</mat-icon
              >{{ size.name }}
            </div>
            <div class="image square">
              <img
                [src]="size.imagePath()"
                [alt]="size.name + ' Model Image'"
              />
            </div>
            <button
              *ngIf="!isRevitPlugin"
              mat-raised-button
              color="primary"
              (click)="addSize(size)"
            >
              Add
            </button>
            <button
              *ngIf="isRevitPlugin"
              mat-raised-button
              color="primary"
              (click)="addSize(size)"
            >
              Select
            </button>
          </div>
        </div>
        <div #bodyContent class="body-content" [ngSwitch]="productType">
          <div class="body-row" *ngFor="let row of compareRows">
            <div class="title">{{ row.title }}</div>
            <div class="data-row" *ngFor="let dataRow of row.data">
              <div class="description">{{ dataRow.description }}</div>
              <div class="data" *ngFor="let value of dataRow.value">
                {{ value }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="productType === 'circulator' && subGroup === 'circulator'"
      label="Coverage & Spacing"
    >
      <div class="circulator-performance">
        <div class="images">
          <img src="/assets/images/svg/dscoveragetop.svg" />
          <img src="/assets/images/svg/dcfanspacing.svg" />
        </div>

        <table mat-table [dataSource]="dsTableDS">
          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef>Model</th>
            <td mat-cell *matCellDef="let element">{{ element.model }}</td>
          </ng-container>

          <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef>
              {{ dsCoverageAreaTitle }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.area | number : '1.0-0' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="radius">
            <th mat-header-cell *matHeaderCellDef>
              {{ dsCoverageRadTitle }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.radius | number : '1.0-0' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="spacing">
            <th mat-header-cell *matHeaderCellDef>Min. Fan Spacing (ft.)</th>
            <td mat-cell *matCellDef="let element">
              {{ element.spacing | number : '1.0-0' }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="['model', 'area', 'radius', 'spacing']"
          ></tr>
          <tr
            mat-row
            *matRowDef="
              let row;
              columns: ['model', 'area', 'radius', 'spacing']
            "
          ></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="
        productType !== 'louver' &&
        productType !== 'gravity' &&
        productType !== 'makeupair' &&
        productType !== 'preconditioner'
      "
      label="Fan Curves"
    >
      <div class="chart">
        <ventabula-compare-chart
          [chartType]="'operating'"
          [sizes]="sizeList"
          [showElevation]="true"
        ></ventabula-compare-chart>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="
        productType !== 'louver' &&
        productType !== 'gravity' &&
        productType !== 'circulator' &&
        productType !== 'makeupair' &&
        productType !== 'preconditioner'
      "
      label="Sound Chart"
    >
      <div class="chart">
        <ventabula-compare-chart
          [chartType]="'sound'"
          [sizes]="sizeList"
          [showElevation]="true"
        ></ventabula-compare-chart>
      </div>
    </mat-tab>
  </mat-tab-group>
</side-dialog-content>

<side-dialog-actions>
  <button mat-raised-button color="primary" (click)="closeClick()">
    Close
  </button>
</side-dialog-actions>
