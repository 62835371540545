import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { environment } from '../environments/environment';
import { AnalyticsModule } from './analytics/analytics.module';
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { DevModule } from './dev/dev.module';
import { FeedbackDialogComponent } from './dialogs/feedback-dialog/feedback-dialog.component';
import { ReleaseNotesDialogComponent } from './dialogs/release-notes-dialog/release-notes-dialog.component';
import { EcapsCoreModule } from './ecaps-core/ecaps-core.module';
import { GlobalErrorHandler } from './ecaps-core/handlers/global-error-handler.handler';
import { ExternalCommunicationModule } from './external-communication/external-communication.module';
import { EquipmentSchedulePageComponent } from './pages/equipment-schedule-page/equipment-schedule-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { GraphicalSelectionPageComponent } from './pages/graphical-selection-page/graphical-selection-page.component';
import { JobManagementPageComponent } from './pages/job-management-page/job-management-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
import { ModelSelectionPageComponent } from './pages/model-selection-page/model-selection-page.component';
import { NoopPageComponent } from './pages/noop-page/noop-page.component';
import { OutdoorAirAccessoriesPageComponent } from './pages/outdoor-air-accessories-page/outdoor-air-accessories-page.component';
import { PlenumRatingPageComponent } from './pages/plenum-rating-page/plenum-rating-page.component';
import { RevitJobMarksPageComponent } from './pages/revit-job-marks-page/revit-job-marks-page.component';
import { RevitJobsPageComponent } from './pages/revit-jobs-page/revit-jobs-page.component';
import { SelectionPageComponent } from './pages/selection-page/selection-page.component';
import { ChartingModule } from './products/charting/charting.module';
import { ProductsModule } from './products/products.module';
import { ProjectsModule } from './projects/projects.module';
import { SelectionsModule } from './selections/selections.module';
import { SideBarModule } from './side-bar/side-bar.module';
import { SideDialogModule } from './side-dialog/side-dialog.module';
import { UsersModule } from './users/users.module';

export function onAppInit(): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise<void>((resolve, reject) => {
      console.debug('[AppModule] onAppInit(): Application Initialize.');

      const serviceRoot = window.localStorage.getItem('serviceRoot');

      environment.serviceRoot = serviceRoot
        ? serviceRoot
        : environment.serviceRoot;

      resolve();
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    ReleaseNotesDialogComponent,
    SelectionPageComponent,
    FaqPageComponent,
    FeedbackDialogComponent,
    GraphicalSelectionPageComponent,
    EquipmentSchedulePageComponent,
    JobManagementPageComponent,
    OutdoorAirAccessoriesPageComponent,
    ModelSelectionPageComponent,
    MaintenancePageComponent,
    PlenumRatingPageComponent,
    LoginPageComponent,
    NoopPageComponent,
    RevitJobsPageComponent,
    RevitJobMarksPageComponent,
    BreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AnalyticsModule,
    HttpClientModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatIconModule,
    MatTableModule,
    MatSortModule,
    MatSlideToggleModule,
    EcapsCoreModule,
    UsersModule,
    DevModule,
    SideBarModule,
    ChartingModule,
    ProductsModule,
    SelectionsModule,
    ProjectsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatToolbarModule,
    MatRippleModule,
    MatButtonModule,
    MatSidenavModule,
    MatTooltipModule,
    MatTabsModule,
    MatExpansionModule,
    MatCardModule,
    SideDialogModule,
    ExternalCommunicationModule,
    MatGridListModule,
    MatListModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
        hideRequiredMarker: false,
        floatLabelType: 'always',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
