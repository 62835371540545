import { Component, OnInit } from '@angular/core';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../../../google/services/google-analytics.service';
import { IReturnData } from './models/i-return-data.interface';
import { ExternalCommunicationService } from '../../../external-communication/services/external-communication.service';
import { LaunchTypes } from '../../../external-communication/models/launch-types.enum';
import { LachesisService } from '../../../analytics/services/lachesis.service';
import { RevitCommsService } from '../../../external-communication/services/revit-comms.service';

@Component({
  selector: 'app-bath-sp-calculator-dialog',
  templateUrl: './bath-sp-calculator-dialog.component.html',
  styleUrls: ['./bath-sp-calculator-dialog.component.scss'],
})
export class BathSpCalculatorDialogComponent implements OnInit {
  dialogData: any;

  roomSize: UntypedFormGroup;

  ductInformation: UntypedFormGroup;

  calcData: UntypedFormGroup;

  assumedCFMByFixture: number;

  edl: number;

  isRevitPlugin = false;

  constructor(
    private sideDialogService: SideDialogService,
    private gaServices: GoogleAnalyticsService,
    private extComm: ExternalCommunicationService,
    private lachesisService: LachesisService,
    private revitComms: RevitCommsService
  ) {
    this.lachesisService.trackEvent({
      eventName: 'Static Pressure Calculator',
      slideout: 'Static Pressure Calculator',
      revitVersion: this.revitComms.isRevitApp
        ? this.revitComms.revitVersion
        : '',
      revitPluginVersion: this.revitComms.isRevitApp
        ? this.revitComms.revitPluginVersion
        : '',
    });

    this.gaServices.trackEvent('Static Pressure Calculator');

    this.roomSize = new UntypedFormGroup({
      length: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(12),
      ]),
      width: new UntypedFormControl(null, [
        Validators.required,
        Validators.min(1),
        Validators.max(12),
      ]),
      sqft: new UntypedFormControl(null, Validators.required),
      toiletQty: new UntypedFormControl(1, [
        Validators.required,
        Validators.min(0),
        Validators.max(2),
      ]),
      showerQty: new UntypedFormControl(1, [
        Validators.required,
        Validators.min(0),
        Validators.max(2),
      ]),
      bathtubQty: new UntypedFormControl(1, [
        Validators.required,
        Validators.min(0),
        Validators.max(2),
      ]),
      jettedTubQty: new UntypedFormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(2),
      ]),
    });

    this.ductInformation = new UntypedFormGroup({
      roundDuctDiameter: new UntypedFormControl(null, Validators.required),
      smoothMetal: new UntypedFormControl(0),
      flexAluminum: new UntypedFormControl(0),
      flexInsulated: new UntypedFormControl(0),
      terminationQty: new UntypedFormControl(1, [
        Validators.required,
        Validators.min(0),
        Validators.max(1),
      ]),
      elbowQty: new UntypedFormControl(0, [
        Validators.required,
        Validators.min(0),
        Validators.max(10),
      ]),
    });

    const self = this;

    const hasDuctLength = function (c: UntypedFormControl) {
      let result = null;

      if (
        self.ductInformation.controls['smoothMetal'].value <= 0 &&
        self.ductInformation.controls['flexAluminum'].value <= 0 &&
        self.ductInformation.controls['flexInsulated'].value <= 0
      ) {
        result = {
          errorMessage: 'At least one duct length is required.',
        };
      } else {
        const controlList = ['smoothMetal', 'flexAluminum', 'flexInsulated'];

        controlList.forEach((name) => {
          const field = self.ductInformation.controls[name];

          if (field !== c && field.invalid) {
            field.updateValueAndValidity();
          }
        });
      }

      return result;
    };

    const ductValidators = [
      Validators.required,
      Validators.min(0),
      Validators.max(50),
      hasDuctLength,
    ];

    this.ductInformation.controls['smoothMetal'].setValidators(ductValidators);
    this.ductInformation.controls['flexAluminum'].setValidators(ductValidators);
    this.ductInformation.controls['flexInsulated'].setValidators(
      ductValidators
    );

    this.calcData = new UntypedFormGroup({
      cfmRequirement: new UntypedFormControl(null, Validators.required),
      totalSP: new UntypedFormControl(null, Validators.required),
    });
  }

  ngOnInit() {}

  sqftTotal() {
    this.roomSize.controls['sqft'].setValue(
      this.roomSize.controls['length'].value *
        this.roomSize.controls['width'].value
    );

    this.fixtureTotal();
  }

  fixtureTotal() {
    this.assumedCFMByFixture =
      this.roomSize.controls['toiletQty'].value * 50 +
      this.roomSize.controls['showerQty'].value * 50 +
      this.roomSize.controls['bathtubQty'].value * 50 +
      this.roomSize.controls['jettedTubQty'].value * 100;

    this.cfmRequirement();
  }

  cfmRequirement() {
    let matchValue = this.roomSize.controls['sqft'].value;

    if (matchValue > 100) {
      matchValue = this.assumedCFMByFixture;
    }

    matchValue =
      matchValue > 300 ? 300 : Math.round(parseFloat(matchValue) / 10) * 10;

    this.calcData.controls['cfmRequirement'].setValue(matchValue);

    this.totalSP();
  }

  totalEDL() {
    let terminationQtyModifier = 0;
    let elbowQtyModifier = 0;

    if (this.ductInformation.controls['terminationQty'].value <= 4) {
      terminationQtyModifier = 30;
      elbowQtyModifier = 15;
    } else if (this.ductInformation.controls['terminationQty'].value === 5) {
      terminationQtyModifier = 35;
      elbowQtyModifier = 13.5;
    } else if (this.ductInformation.controls['terminationQty'].value > 5) {
      terminationQtyModifier = 40;
      elbowQtyModifier = 12;
    }

    this.edl =
      this.ductInformation.controls['smoothMetal'].value +
      this.ductInformation.controls['flexAluminum'].value * 2 +
      this.ductInformation.controls['flexInsulated'].value * 2.5 +
      this.ductInformation.controls['terminationQty'].value *
        terminationQtyModifier +
      this.ductInformation.controls['elbowQty'].value * elbowQtyModifier;

    this.edl = Math.round(this.edl);

    this.totalSP();
  }

  totalSP() {
    const ductDiameter =
      this.ductInformation.controls['roundDuctDiameter'].value;
    const cfm = this.calcData.controls['cfmRequirement'].value;

    this.calcData.controls['totalSP'].setValue(
      (
        ((0.109136 * Math.pow(cfm, 1.9)) / Math.pow(ductDiameter, 5.02) / 100) *
        this.edl
      ).toFixed(2)
    );
  }

  okClick() {
    this.gaServices.trackEvent_Old(
      EventCategories.staticPressureCalculator,
      'Used Calculated Values'
    );

    this.sideDialogService.close(<IReturnData>{
      staticPressure: this.calcData.controls['totalSP'].value,

      additionalUpdates: {
        Volume: this.calcData.controls['cfmRequirement'].value.toString(),
      },
    });
  }

  cancelClick() {
    this.sideDialogService.close();
  }

  smoothMetalHelpClick() {
    this.extComm.launchExternal(
      '/assets/images/help/ducts/smoothmetal.jpg',
      LaunchTypes.imagePopup,
      'Smooth Duct (PVC or Metal)'
    );
  }

  aluminumHelpClick() {
    this.extComm.launchExternal(
      '/assets/images/help/ducts/aluminum.jpg',
      LaunchTypes.imagePopup,
      'Flexible Aluminum Duct (Strung Tight)'
    );
  }

  flexInsulatedHelpClick() {
    this.extComm.launchExternal(
      '/assets/images/help/ducts/flexinsulated.jpg',
      LaunchTypes.imagePopup,
      'Insulated Flexible Duct (Loosely Laid)'
    );
  }
}
