import { Component, OnInit } from '@angular/core';
import { Certifications } from './constants/certifications.const';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';

@Component({
  selector: 'louver-certification-dialog',
  templateUrl: './louver-certification-dialog.component.html',
  styleUrls: ['./louver-certification-dialog.component.scss'],
})
export class LouverCertificationDialogComponent implements OnInit {
  dataSource = Certifications;

  constructor(private sideDialogService: SideDialogService) {}

  ngOnInit() {}

  closeClick() {
    this.sideDialogService.close();
  }
}
