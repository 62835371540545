import { Injectable } from '@angular/core';
import { IHelpItem } from './models/interfaces/i-help-item.interface';
import { HelpDictionary } from './models/help-dictionary.const';
import { HelpTypes } from './models/help-types.enum';
import { HelpItemDialog } from '../controllers/help-item-dialog.controller';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';
import { ExternalCommunicationService } from '../../external-communication/services/external-communication.service';
import { LaunchTypes } from '../../external-communication/models/launch-types.enum';

@Injectable({
  providedIn: 'root'
})
export class HelpService {

  constructor(private externalComm: ExternalCommunicationService,
    private helpItemDialog: HelpItemDialog,
    private sideDialog: SideDialogService) { }

  getHelp(key: string): Promise<IHelpItem> {

    return new Promise<IHelpItem>(result => {

      const helpItem = HelpDictionary
        .find(item => item.key.toLowerCase().trim() === (key || '').toLowerCase().trim());

      result(helpItem);

    });

  }

  showHelp(helpItem: IHelpItem | string, type?: HelpTypes): Promise<void> {

    return new Promise<void>(async result => {

      if (typeof (helpItem) === 'string') {

        helpItem = await this.getHelp(helpItem);

      }

      if (!!helpItem.messageBox && (type === HelpTypes.messageBox || !type)) {

        this.helpItemDialog.show(helpItem, this).then(result);

      } else if (!!helpItem.sideDialog && (type === HelpTypes.sideDialog || !type)) {

        const dialogRef = this.sideDialog.open(helpItem.sideDialog.dialogComponent, helpItem.sideDialog.options);

        const subscription = dialogRef.beforeClose.subscribe(() => {

          subscription.unsubscribe();

          result();

        });

      } else if (!!helpItem.link && (type === HelpTypes.link || !type)) {

        this.externalComm.launchExternal(helpItem.link.url, LaunchTypes.newWindow);

        result();

      } else {

        result();

      }

    });

  }

}
