import { EventEmitter, Injectable } from '@angular/core';
import { GeoLocation } from '../models/geo-location.model';
import { GeocodedAddress } from '../models/geocoded-address.model';

declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService {
  get canUse(): boolean {
    const version = this.getChromeVersion();

    return version >= 88 || version === -1;
  }

  private elevator: any;
  private geocoder: any;

  private _apiLoaded: boolean;
  apiLoaded: Promise<boolean>;

  onAPILoaded = new EventEmitter<boolean>();

  constructor() {
    this._apiLoaded = false;

    this.apiLoaded = new Promise<boolean>((result, reject) => {
      if (!this.canUse) {
        result(false);

        return;
      }

      if (this._apiLoaded) {
        result(true);
      } else {
        const subscription = this.onAPILoaded.subscribe((loaded) => {
          subscription.unsubscribe();

          if (loaded) {
            result(true);
          } else {
            reject();
          }
        });
      }
    });

    if (!this.canUse) {
      return;
    }

    const self = this;

    const googleMapAPILoad = function () {
      self.elevator = new google.maps.ElevationService();
      self.geocoder = new google.maps.Geocoder();

      self._apiLoaded = true;

      self.onAPILoaded.next(self._apiLoaded);
    };

    if (typeof google === 'undefined' || typeof google.maps === 'undefined') {
      const scriptTag = document.createElement('script');

      scriptTag.type = 'text/javascript';
      scriptTag.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyD-jY_VHUat9_YTfABgQssCjy5waIc03mQ&amp;sensor=false';

      if (!!(<any>scriptTag).readyState) {
        (<any>scriptTag).onreadystatechange = () => {
          const state = (<any>scriptTag).readyState;

          if (state === 'loaded' || state === 'complete') {
            (<any>scriptTag).onreadystatechange = null;

            googleMapAPILoad.call(self);
          }
        };
      } else {
        scriptTag.onload = () => {
          scriptTag.onload = null;

          googleMapAPILoad.call(self);
        };
      }

      (<any>document.body).appendChild(scriptTag);
    } else {
      googleMapAPILoad();
    }
  }

  createMap(container: HTMLElement, centerLatLng?: any, zoom?: number): any {
    if (!this.canUse) {
      return;
    }

    if (!centerLatLng) {
      centerLatLng = new google.maps.LatLng(40.58058466412761, -96.85546875);
    }

    if (typeof zoom === 'undefined') {
      zoom = 3;
    }

    const mapOptions = {
      zoom: zoom,
      center: centerLatLng,
      fullscreenControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      zoomControlOptions: {
        style: google.maps.ZoomControlStyle.SMALL,
      },
    };

    return new google.maps.Map(container, mapOptions);
  }

  geocodeAddress(address: string): Promise<GeocodedAddress> {
    return new Promise<GeocodedAddress>((result, reject) => {
      if (!this.canUse) {
        reject('Google Maps API not available');

        return;
      }

      this.geocoder.geocode({ address: address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          result(new GeocodedAddress(results[0]));
        } else {
          reject(status);
        }
      });
    });
  }

  geocodeLocation(location: GeoLocation): Promise<GeocodedAddress> {
    return new Promise<GeocodedAddress>((result, reject) => {
      if (!this.canUse) {
        reject('Google Maps API not available');

        return;
      }

      this.geocoder.geocode({ location: location }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          result(new GeocodedAddress(results[0]));
        } else {
          reject(status);
        }
      });
    });
  }

  getElevation(location: GeoLocation): Promise<number> {
    return new Promise<number>((result, reject) => {
      if (!this.canUse) {
        reject('Google Maps API not available');

        return;
      }

      this.elevator.getElevationForLocations(
        { locations: [location] },
        function (results, status) {
          if (status === google.maps.ElevationStatus.OK) {
            if (results[0]) {
              const elevation = Math.round(results[0].elevation * 3.28084);

              result(elevation);
            }
          } else {
            reject(status);
          }
        }
      );
    });
  }

  recenterMap(map: any, location: GeoLocation, zoom?: number) {
    if (!this.canUse) {
      return;
    }

    map.setCenter(location);

    if (typeof zoom !== 'undefined') {
      map.setZoom(zoom);
    }
  }

  addMapListener(map: any, type: string): EventEmitter<any> {
    const result = new EventEmitter<any>();

    if (this.canUse) {
      google.maps.event.addListener(map, type, function (event) {
        result.next(event);
      });
    }

    return result;
  }

  createPin(map: any, location: GeoLocation, draggable?: boolean) {
    if (!this.canUse) {
      return;
    }

    if (typeof draggable === 'undefined') {
      draggable = true;
    }

    return new google.maps.Marker({
      map: map,
      position: location,
      draggable: draggable,
    });
  }

  movePin(pin: any, location: GeoLocation) {
    pin.setPosition(location);
  }

  addPinListener(pin: any, type: string): EventEmitter<any> {
    const result = new EventEmitter<any>();

    if (this.canUse) {
      google.maps.event.addListener(pin, type, function (event) {
        result.next(event);
      });
    }

    return result;
  }

  private getChromeVersion(): number {
    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : -1;
  }
}
