import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import { HelpService } from '../../../ecaps-core/services/help.service';
import { RevitStateLevels } from '../../../external-communication/models/revit-state-levels.enum';
import { RevitCommsService } from '../../../external-communication/services/revit-comms.service';
import { Products } from '../../../products/controllers/products.controller';
import { ProductTypes } from '../../../products/enums/product-types.enum';
import { ProductInfoDialogReturnTypes } from '../../../products/models/product-info-dialog-return-types.model';
import { ProductCategory } from '../../../products/services/products.service';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { CompareSnackBarComponent } from '../../components/compare-snack-bar/compare-snack-bar.component';
import { Selections } from '../../controllers/selections.controller';
import { CustomizeGridDialogComponent } from '../../dialogs/customize-grid-dialog/customize-grid-dialog.component';
import { LayoutConfiguration } from '../../models/layout-config/layout-configuration.model';
import { SelectionResults } from '../../models/selection-results/selection-results.model';
import { ValidSize } from '../../models/selection-results/valid-size.model';
import { LayoutService } from '../../services/layout.service';
import { FilterTypes } from '../enums/filter-types.enum';
import { IGridColumn } from '../models/grid-column.interface';

class FilterItem {
  public key: string;
  public value: string;
  public filterType: string;
}

@Component({
  selector: 'app-base-grid',
  templateUrl: './base-grid.component.html',
  styleUrls: ['./base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseGridComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort, { static: true }) private matSort: MatSort;

  sortKey = 'rank';
  sortDir = 'asc';

  public gridSaveKeyOverride: string;

  @Input('showCustomize') public showCustomize: boolean;

  private _layoutConfig: LayoutConfiguration;
  @Input('layoutConfig')
  protected get layoutConfig(): LayoutConfiguration {
    return this._layoutConfig;
  }
  protected set layoutConfig(value: LayoutConfiguration) {
    this._layoutConfig = value;

    if (!!this.layoutConfig) {
      this.gridSaveKey = !this.gridSaveKey
        ? !!this.gridSaveKeyOverride
          ? this.gridSaveKeyOverride
          : this.layoutConfig.modelGroup
        : this.gridSaveKey;

      this.loadVisibleColumns();

      if (!!this.layoutConfigUpdated) {
        this.layoutConfigUpdated.unsubscribe();
      }

      this.layoutConfigUpdated = this.layoutConfig.updated.subscribe(() => {
        this.checkRequiredComplete();
      });

      this.checkRequiredComplete();
    } else if (!!this.layoutConfigUpdated) {
      this.layoutConfigUpdated.unsubscribe();

      this.layoutConfigUpdated = null;
    }
  }

  @Input('showFooter') public showFooter: boolean;
  @Input('showFilter') public showFilter: boolean;
  @Input('showSizeCompare') public showSizeCompare: boolean;
  @Input('showRank') public showRank: boolean;

  @Output('compareUpdate') private compareUpdate = new EventEmitter<
    Array<ValidSize>
  >();

  @Output('sizeAdded') protected sizeAdded = new EventEmitter<ValidSize>();

  private layoutConfigUpdated: Subscription;

  private showReselectWarn = true;

  protected selectionResultsReceived = new EventEmitter<SelectionResults>();

  columnList: Array<IGridColumn>;

  visibleColumns: Array<string>;

  visibleColumnsReset: Array<string>;

  private columnsRestored = false;

  filterColumns: Array<string>;

  dataSource = new MatTableDataSource<Array<any>>([]);

  protected _compareList = new Array<ValidSize>();

  filterForm: UntypedFormGroup;

  private filterValues = new Array<FilterItem>();

  private filterDelay: number;

  protected modelNameHeader = 'Model Name';

  protected footerProductType = 'Fan(s)';

  private snackBarRef: MatSnackBarRef<CompareSnackBarComponent>;

  protected gridSaveKey: string;

  isRevitPlugin = false;

  protected core: EcapsCore;
  private products: Products;
  private selections: Selections;
  private layoutService: LayoutService;
  private sideDialogService: SideDialogService;
  private snackBar: MatSnackBar;
  private helpService: HelpService;
  private revitComms: RevitCommsService;

  constructor(protected injector: Injector) {
    this.core = injector.get(EcapsCore);
    this.helpService = injector.get(HelpService);
    this.products = injector.get(Products);
    this.selections = injector.get(Selections);
    this.revitComms = injector.get(RevitCommsService);
    this.layoutService = injector.get(LayoutService);
    this.sideDialogService = injector.get(SideDialogService);
    this.snackBar = injector.get(MatSnackBar);
    this.helpService = injector.get(HelpService);

    this.isRevitPlugin = this.revitComms.isRevitApp;

    this.visibleColumns = ['rank', 'compare', 'name'];

    this.filterColumns = ['rank_filter', 'compare_filter', 'name_filter'];

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      if (!filter || filter.trim() === '') {
        return true;
      }

      const filterItems = <Array<FilterItem>>JSON.parse(filter);

      let missing = false;

      filterItems.forEach((value) => {
        if (
          typeof data[value.key] === 'undefined' ||
          data[value.key] === null
        ) {
          missing = true;
        } else {
          switch (value.filterType) {
            case FilterTypes.contains: {
              if (
                data[value.key]
                  .toString()
                  .toLowerCase()
                  .trim()
                  .indexOf(value.value.toString().toLowerCase().trim()) === -1
              ) {
                missing = true;
              }

              break;
            }
            case FilterTypes.greaterThan: {
              if (data[value.key] < parseFloat(value.value)) {
                missing = true;
              }

              break;
            }
            case FilterTypes.lessThan: {
              if (data[value.key] > parseFloat(value.value)) {
                missing = true;
              }

              break;
            }
            case FilterTypes.bool: {
              const tempValue = value.value.toLowerCase().trim();

              const boolValue =
                tempValue === 'true' || tempValue === 'y' || tempValue === 'yes'
                  ? true
                  : false;

              if (data[value.key] !== boolValue) {
                missing = true;
              }

              break;
            }
          }
        }
      });

      return !missing;
    };

    this.showCustomize = true;
    this.showFooter = true;
    this.showFilter = true;
    this.showSizeCompare = true;
    this.showRank = true;

    this.filterForm = new UntypedFormGroup({});
  }

  ngOnInit() {}

  ngOnChanges() {
    this.dataSource.sort = this.matSort;

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    if (!!this.layoutConfigUpdated) {
      this.layoutConfigUpdated.unsubscribe();
    }
  }

  clearGrid() {
    this.dataSource.data = [];
  }

  private checkRequiredComplete() {
    if (this.layoutConfig.requiredComplete()) {
      let selecting = true;

      this.core.showLoadingGraphic('Selecting...', () => selecting);

      this._compareList.splice(0, this._compareList.length);

      this.compareUpdate.next(this._compareList);

      this.showSnackBar();

      const category = this.layoutService.getCategory(
        this.layoutConfig
      ) as ProductCategory;

      const selfRef = this;

      let selectionRequestData: { [key: string]: any };

      const processSelRequest = (cat: ProductCategory) => {
        if (!selectionRequestData && !!cat.selectionRequestData) {
          selectionRequestData = cat.selectionRequestData;
        }

        if (!!cat.parent) {
          processSelRequest(cat.parent as ProductCategory);
        }
      };

      processSelRequest(category);

      this.layoutService
        .getLayoutSelection(
          this.layoutConfig,
          category.type,
          selectionRequestData
        )
        .then((selectionResults) => {
          this.dataSource.data = [];

          this.selectionResultsReceived.next(selectionResults);

          setTimeout(() => {
            const element = document.getElementsByClassName('reselection-row');

            if (!!element && element.length > 0) {
              const header =
                document.getElementsByClassName('top-header-row')[0]
                  .parentElement;

              const gridParent = document.getElementsByClassName(
                'selection-grid-parent'
              );

              gridParent[0].scrollTop =
                (<any>element[0]).offsetTop - header.clientHeight;
            }

            if (
              selectionResults.validSizes.length > 0 &&
              !!selfRef.layoutService.getReselectionSize()
            ) {
              if (element.length === 0 && selfRef.showReselectWarn) {
                this.core.showMessageBox({
                  title: 'Reselection Warning',
                  message:
                    'Based on the selection criteria you have provided the size you are reselecting is no longer valid.',
                  icon: 'warning',
                  iconClasses: 'warn',
                });

                selfRef.showReselectWarn = false;
              } else if (
                element.length > 0 &&
                selfRef.showReselectWarn === false
              ) {
                selfRef.showReselectWarn = true;
              }
            }
          });

          selecting = false;

          this.core.hideLoadingGraphic();

          if (selectionResults.validSizes.length === 0) {
            this.core.showMessageBox({
              title: 'Selecting Error',
              message:
                'No selections returned based on your current input. Please change your inputs.',
              icon: 'warning',
              iconClasses: 'warn',
            });
          }
        });
    } else {
      this.dataSource.data = [];
    }
  }
  showProductInfo(
    size: ValidSize,
    tabName?: string,
    tag?: string,
    quantity?: number
  ) {
    if (this.isRevitPlugin) {
      const mark = this.revitComms.reselectStates?.find(
        (state) => state.level === RevitStateLevels.mark
      );
      if (!!mark) {
        const tagValue = mark.values.find((value) => (value.key = 'Tag'));
        tag = !!tagValue ? tagValue.value : tag;
      }
    }
    this.products
      .showProductInfo(size, tabName, null, tag, quantity)
      .then((result: ProductInfoDialogReturnTypes) => {
        if (
          result === ProductInfoDialogReturnTypes.Added &&
          size.selectionLayoutConfig.modelGroup !== 'RV'
        ) {
          this.sizeAdded.next(size);
        }
      });
  }

  showSizeWarning(size: ValidSize) {
    this.products.showValidSizeWarning(size);
  }

  isSelected(size: ValidSize) {
    return this._compareList.indexOf(size) > -1;
  }

  compareSelect($event: MatCheckboxChange, size: ValidSize) {
    if ($event.checked) {
      if (!this.addCompareSize(size)) {
        $event.source.checked = false;
      }
    } else {
      this.removeCompareSize(size);
    }
  }

  private addCompareSize(size: ValidSize): boolean {
    if (this._compareList.length === 3) {
      this.core.showMessageBox(
        {
          title: 'Maximum Selection Exceeded',
          message: 'You may select a maximum of 3 items to compare.',
        },
        true
      );

      return false;
    } else {
      this._compareList.push(size);

      this.compareUpdate.next(this._compareList);

      this.showSnackBar();

      return true;
    }
  }

  private removeCompareSize(size: ValidSize) {
    this._compareList = this._compareList.filter(
      (compareSize) => compareSize.id !== size.id
    );

    this.compareUpdate.next(this._compareList);

    this.showSnackBar();
  }

  showSnackBar() {
    if (this._compareList.length > 0) {
      if (!this.snackBarRef) {
        this.snackBarRef = this.snackBar.openFromComponent(
          CompareSnackBarComponent,
          { data: { items: this._compareList } }
        );

        const subscription = this.snackBarRef.afterDismissed().subscribe(() => {
          subscription.unsubscribe();

          this.snackBarRef = null;
        });
      } else {
        this.snackBarRef.instance.setItems(this._compareList);
      }
    } else if (!!this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  protected setVisibleColumns(columns: Array<string>) {
    if (!this.columnsRestored) {
      this.visibleColumns = ['rank', 'compare', 'name'].concat(columns);
    }

    if (!this.showSizeCompare) {
      this.visibleColumns.splice(
        this.visibleColumns.findIndex((item) => item === 'compare'),
        1
      );
    }

    if (!this.showRank) {
      this.visibleColumns.splice(
        this.visibleColumns.findIndex((item) => item === 'rank'),
        1
      );
    }

    this.visibleColumnsReset = [].concat(columns);

    this.setFilterColumns();

    this.loadVisibleColumns();
  }

  protected loadVisibleColumns() {
    const rawJSON = window.localStorage.getItem(
      `${this.gridSaveKey}_SelectionGrid`
    );

    if (!!rawJSON && !!this.columnList && this.columnList.length > 0) {
      const savedColumns = <Array<string>>JSON.parse(rawJSON);

      const invalidColumns =
        savedColumns.findIndex(
          (key) => this.columnList.findIndex((item) => item.key === key) === -1
        ) !== -1;

      if (!invalidColumns) {
        this.visibleColumns = ['rank', 'compare', 'name'].concat(savedColumns);

        if (!this.showSizeCompare) {
          this.visibleColumns.splice(
            this.visibleColumns.findIndex((item) => item === 'compare'),
            1
          );
        }

        if (!this.showRank) {
          this.visibleColumns.splice(
            this.visibleColumns.findIndex((item) => item === 'rank'),
            1
          );
        }

        this.setFilterColumns();

        this.columnsRestored = true;
      } else {
        // Remove invalid saved column list.
        window.localStorage.removeItem(`${this.gridSaveKey}_SelectionGrid`);
      }
    }
  }

  protected saveVisibleColumns() {
    const newColumns = [].concat(this.visibleColumns);

    ['rank', 'compare', 'name'].forEach((key) => {
      const index = newColumns.findIndex((item) => item === key);

      if (index > -1) {
        newColumns.splice(index, 1);
      }
    });

    window.localStorage.setItem(
      `${this.gridSaveKey}_SelectionGrid`,
      JSON.stringify(newColumns)
    );
  }

  filterColumn(
    columnKey: string,
    filterType: string,
    filterField: HTMLInputElement
  ) {
    if (this.filterValues.findIndex((item) => item.key === columnKey) > -1) {
      if (filterField.value.trim() !== '') {
        this.filterValues.find((item) => item.key === columnKey).value =
          filterField.value;
      } else {
        this.filterValues = this.filterValues.filter(
          (item) => item.key !== columnKey
        );
      }
    } else if (filterField.value.trim() !== '') {
      this.filterValues.push({
        key: columnKey,
        value: filterField.value,
        filterType: filterType,
      });
    }

    window.clearTimeout(this.filterDelay);

    const self = this;

    this.filterDelay = window.setTimeout(() => {
      self.dataSource.filter =
        self.filterValues.length > 0 ? JSON.stringify(self.filterValues) : null;
    }, 500);
  }

  setColumnFilter(columnKey: string, filterType: FilterTypes, value: string) {
    if (this.filterValues.findIndex((item) => item.key === columnKey) > -1) {
      if (value.trim() !== '') {
        this.filterValues.find((item) => item.key === columnKey).value = value;
      } else {
        this.filterValues.splice(
          this.filterValues.findIndex((item) => item.key === columnKey)
        );
      }
    } else if (value.trim() !== '') {
      this.filterValues.push({
        key: columnKey,
        value: value,
        filterType: filterType,
      });
    }

    this.filterForm.controls[columnKey].setValue(value);

    this.dataSource.filter =
      this.filterValues.length > 0 ? JSON.stringify(this.filterValues) : null;
  }

  helpIconClick(column: IGridColumn | string) {
    if (typeof column !== 'string') {
      this.helpService.showHelp(column.helpItem);
    } else {
      this.helpService.showHelp(column);
    }
  }

  getAMCAText(size: ValidSize, driveLossPercentage: number): string {
    let text = '';

    switch (size.amcaRatings) {
      case 'A': {
        text = 'AMCA licensed for air performance.';

        break;
      }
      case 'AF': {
        text = 'AMCA licensed for air performance and FEI.';

        break;
      }
      case 'C': {
        text = 'AMCA Licensed for circulating fan performance.';

        break;
      }
      case 'I': {
        text = 'AMCA licensed for induced air flow, sound and air performance.';

        break;
      }
      case 'IF': {
        text =
          'AMCA licensed for induced air flow, sound and air performance, and FEI.';

        break;
      }
      case 'S': {
        text = 'AMCA licensed for sound and air performance.';

        break;
      }
      case 'SF': {
        text =
          'AMCA licensed for sound and air performance and fan energy index.';

        break;
      }
    }

    if (size.productType !== ProductTypes.circulator) {
      if (driveLossPercentage > 0) {
        text += ' Power includes transmission losses.';
      } else {
        text += ' Power excludes transmission losses.';
      }
    }

    return text;
  }

  customize() {
    const dialogRef = this.sideDialogService.open(
      CustomizeGridDialogComponent,
      {
        disableClose: false,
        autoFocus: true,
        data: {
          columnList: this.columnList,
          visibleColumns: this.visibleColumns,
          visibleColumnsReset: this.visibleColumnsReset,
        },
      }
    );

    const subscription = dialogRef.beforeClose.subscribe(
      (newVisible: Array<string>) => {
        subscription.unsubscribe();

        if (!!newVisible && newVisible.length > 0) {
          ['rank', 'compare', 'name'].forEach((key) => {
            const index = newVisible.findIndex((item) => item === key);

            if (index > -1) {
              newVisible.splice(index, 1);
            }
          });

          const initColumns = ['rank', 'compare', 'name'];

          if (!this.showSizeCompare) {
            initColumns.splice(
              initColumns.findIndex((item) => item === 'compare'),
              1
            );
          }

          if (!this.showRank) {
            initColumns.splice(
              initColumns.findIndex((item) => item === 'rank'),
              1
            );
          }

          this.visibleColumns = initColumns.concat(newVisible);
        }

        this.setFilterColumns();

        this.saveVisibleColumns();
      }
    );
  }

  setFilterColumns() {
    this.filterColumns = [];

    this.visibleColumns.forEach((column) => {
      this.filterColumns.push(column + '_filter');

      this.filterForm.addControl(column, new UntypedFormControl());
    });
  }

  ervCompare(size: ValidSize) {
    const self = this;

    const showCompare = function (exhaustVolume: number) {
      self.selections.showErvCompare(exhaustVolume, size);
    };

    const exhaustVolumeQuestion =
      size.selectionLayoutConfig.getQuestion('ExhaustVolumeInput');

    if (!exhaustVolumeQuestion.value) {
      this.selections.showExhaustVolume(size).then((result) => {
        if (result !== null && typeof result !== 'undefined') {
          showCompare(result);
        }
      });
    } else {
      showCompare(parseFloat(exhaustVolumeQuestion.value));
    }
  }

  enableERVCompare(size: ValidSize) {
    const supplyDischargePos = size.selectionLayoutConfig.getQuestion(
      'ReturnAirIntakePosition'
    );

    if (typeof supplyDischargePos !== 'undefined') {
      return supplyDischargePos.value.toLowerCase().trim() === 'end';
    } else {
      return true;
    }
  }

  isReselectionRow(size: ValidSize): boolean {
    const reselectionSize = this.layoutService.getReselectionSize();

    if (!!reselectionSize) {
      if (size.productType === ProductTypes.temperedAirProduct) {
        return (
          size.outputs.housingWheelModel.id ===
            reselectionSize.outputs.housingWheelModel.id &&
          size.outputs.coolingModel.id ===
            reselectionSize.outputs.coolingModel.id
        );
      } else if (
        size.productType === ProductTypes.makeUpAir ||
        size.productType === ProductTypes.preconditioners
      ) {
        return (
          size.outputs.housingWheelModel.id ===
            reselectionSize.outputs.housingWheelModel.id &&
          size.outputs.coolingModel.id ===
            reselectionSize.outputs.coolingModel.id &&
          size.outputs.heatingModel.id ===
            reselectionSize.outputs.heatingModel.id &&
          size.outputs.fanModel.id === reselectionSize.outputs.fanModel.id
        );
      } else if (size.productType === ProductTypes.circulator) {
        return (
          size.name === reselectionSize.name &&
          size.outputs.fanQuantity === reselectionSize.outputs.fanQuantity &&
          size.outputs.speed === reselectionSize.outputs.speed &&
          size.outputs.numberOfBlades === reselectionSize.outputs.numberOfBlades
        );
      } else if (size.productType === ProductTypes.fan) {
        return size.integrationId === reselectionSize.id;
      } else if (size.outputs.modelGroup === 'ScrolledBlower') {
        return (
          size.name === reselectionSize.name &&
          size.outputs.constructionType ===
            reselectionSize.outputs.constructionType &&
          size.outputs.minimumMotorSizeNema ===
            reselectionSize.outputs.minimumMotorSizeNema &&
          size.outputs.partialWheelWidth ===
            reselectionSize.outputs.partialWheelWidth
        );
      } else if (
        size.selectionLayoutConfig.modelGroup === 'PlugAndPlenum' ||
        size.selectionLayoutConfig.modelGroup === 'InlineFans'
      ) {
        return (
          size.name === reselectionSize.name &&
          size.outputs.speedNonAdjusted ===
            reselectionSize.outputs.speedNonAdjusted
        );
      } else {
        return size.name === reselectionSize.name;
      }
    } else {
      return false;
    }
  }

  updateColumnHelp() {
    if (
      !!this.layoutConfig &&
      !!this.layoutConfig.productType &&
      !!this.columnList
    ) {
      this.columnList.forEach(async (column) => {
        column.helpItem = await this.helpService.getHelp(
          `${this.layoutConfig.productType}_SelectionGrid_${column.key}`
        );

        if (!column.helpItem) {
          column.helpItem = await this.helpService.getHelp(
            `*_SelectionGrid_${column.key}`
          );
        }
      });
    }
  }

  headerRowHeight(): number {
    const headerRows = document.getElementsByClassName(
      'top-header-row mat-header-row'
    );

    if (headerRows.length > 0) {
      return (headerRows[0] as HTMLElement).offsetHeight;
    }
  }
}
