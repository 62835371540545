import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import { LoadingGraphic } from '../../controllers/loading-graphic.controller';
import { Subscription } from 'rxjs';

@Component({
  selector: 'loading-graphic',
  templateUrl: './loading-graphic.component.html',
  styleUrls: ['./loading-graphic.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingGraphicComponent implements OnInit, OnDestroy {
  show = false;
  text = '';

  useLegacy =
    ['trident', 'edge', 'firefox'].findIndex(
      (key) => navigator.userAgent.toLowerCase().indexOf(key) > -1
    ) > -1;

  private blockKeyboard = false;

  private updateSubscription: Subscription;

  constructor(private loadingGraphic: LoadingGraphic) {
    this.updateSubscription = this.loadingGraphic.layersUpdated.subscribe(
      () => {
        this.update();
      }
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.updateSubscription.unsubscribe();
  }

  @HostListener('window:keydown', ['$event'])
  keyDown($event: KeyboardEvent): boolean {
    if (!!this.blockKeyboard) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.stopImmediatePropagation();
      $event.cancelBubble = true;

      return false;
    } else {
      return true;
    }
  }

  update() {
    const layers = this.loadingGraphic
      .getLayers()
      .filter((layer) => layer.verifier());

    if (!layers || layers.length === 0) {
      this.show = false;
      this.text = '';
      this.blockKeyboard = false;
    } else if (layers.length > 0) {
      const self = this;

      const text = layers[layers.length - 1].text;

      if (this.text !== text) {
        setTimeout(() => {
          self.show = true;
          self.text = text;
          self.blockKeyboard = true;
          self.checkClose.call(self);
        });
      }
    }
  }

  private checkClose() {
    const self = this;

    this.update();

    if (this.show) {
      setTimeout(() => {
        self.checkClose.call(self);
      }, 250);
    }
  }
}
