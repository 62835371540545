import { Injectable, ErrorHandler, NgZone, Injector } from '@angular/core';
import { ErrorMessageDialog } from '../controllers/error-message-dialog.controller';
import { ErrorLoggingService } from '../services/error-logging.service';
import { MaintenanceMessageList } from './models/maintenance-message-list.const';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private errorLogging: ErrorLoggingService,
        private errorMessageDialog: ErrorMessageDialog,
        private ngZone: NgZone,
        private injector: Injector) { }

    async handleError(error: any) {

        const errorId = await this.errorLogging.logError(error);

        let errorMessage = '';

        if (!!error.error &&
            !!error.error.message) {

            errorMessage = error.error.message;

        } else if (!!error.rejection &&
            !!error.rejection.error &&
            !!error.rejection.error.message) {

            errorMessage = error.rejection.error.message;

        } else {

            errorMessage = error.message;

        }

        if (MaintenanceMessageList.filter(validator => validator(errorMessage)).length > 0) {

            this.ngZone.run(() => {

                const router = this.injector.get(Router);

                router.navigate(['/maintenance'], { replaceUrl: true });

            });

        } else if (!!errorId && errorId !== '') {

            this.ngZone.run(() => {

                this.errorMessageDialog.show(errorId, error);

            });

        }

    }

}
