import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IRep } from '../dialogs/rep-locator-dialog/models/i-rep.interface';
import { RepLocatorDialogComponent } from '../dialogs/rep-locator-dialog/rep-locator-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class RepLocatorDialog {
  constructor(private dialog: MatDialog) {}

  show(): Promise<IRep> {
    return new Promise<IRep>((results, reject) => {
      const dialogRef = this.dialog.open(RepLocatorDialogComponent, {
        panelClass: 'rep-locator-dialog',
      });

      const subscription = dialogRef.beforeClosed().subscribe(
        (result: IRep) => {
          results(result);
        },
        (errorData) => {
          reject(errorData);
        },
        () => {
          subscription.unsubscribe();
        }
      );
    });
  }
}
