import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';
import { LayoutConfiguration } from '../../models/layout-config/layout-configuration.model';
import { ValidSize } from '../../models/selection-results/valid-size.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fume-exhaust-lab-svg',
  templateUrl: './fume-exhaust-lab-svg.component.html',
  styleUrls: ['./fume-exhaust-lab-svg.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FumeExhaustLabSvgComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  fanWidth: number = 105;
  columns: string[] = [];
  fanCount: number = 1;
  hasFanOnStandby?: boolean;
  bypassAirPlenum?: boolean;
  fanType: string = 'Fan';

  private _layoutConfig: LayoutConfiguration;
  public get layoutConfig(): LayoutConfiguration {
    return this._layoutConfig;
  }
  @Input('layout-config') public set layoutConfig(value: LayoutConfiguration) {
    this._layoutConfig = value;

    if (!!this._layoutConfig) {
      this._subscriptions.push(
        this._layoutConfig.updated.subscribe(() => {
          this.updateValues();
        })
      );
    }

    this.updateValues();
  }

  private _size: ValidSize;
  public get size(): ValidSize {
    return this._size;
  }
  @Input('size') public set size(value: ValidSize) {
    this._size = value;

    if (!!this._size) {
      this._subscriptions.push(
        this._size.updated.subscribe(() => {
          this.updateValues();
        })
      );
    }

    this.updateValues();
  }

  constructor(private element: ElementRef) {}

  ngOnInit() {}

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private updateValues() {
    if (!this._layoutConfig || !this._size || !this._size.layoutConfig) {
      // Reset values

      this.columns = [];
      this.fanCount = 1;
      this.hasFanOnStandby = false;
      this.bypassAirPlenum = false;
      this.fanType = 'Fan';

      return;
    }

    const quantityName = (
      this._size.selectionLayoutConfig.getQuestion('ExhaustSystemFanQuantity')
        ?.value ?? ''
    )
      .toLowerCase()
      .trim();

    const quantityIndex = [
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'all',
    ].indexOf(quantityName);

    this.fanCount = quantityIndex + 1;

    this.columns = Array(this.fanCount).fill('');

    this.hasFanOnStandby =
      (
        this._size.selectionLayoutConfig.getQuestion('HasFanOnStandby')
          ?.value ?? 'No'
      )
        .toLowerCase()
        .trim() === 'yes';
    this.bypassAirPlenum =
      (
        this._size.selectionLayoutConfig.getQuestion('BypassAirPlenum')
          ?.value ?? 'No'
      )
        .toLowerCase()
        .trim() === 'yes';

    const wheelTypeActual =
      this._size.layoutConfig.getQuestion('WheelTypeActual')?.value ?? '';

    this.fanType = ['BI', 'AF'].includes(wheelTypeActual) ? 'Blower' : 'Fan';

    this.updateClasses();

    this.updatePolygons();

    this.scaleViewbox();
  }

  private updateClasses() {
    const svgElement = this.element.nativeElement.querySelector(
      'svg'
    ) as SVGElement;

    if (!svgElement) {
      return;
    }

    const removeList: string[] = [];
    svgElement.classList.forEach((className) => {
      if (className.startsWith('fan-count__') || className === 'standby') {
        removeList.push(className);
      }
    });
    removeList.forEach((className) => {
      svgElement.classList.remove(className);
    });

    svgElement.classList.add(`fan-count__${this.fanCount}`);

    if (this.hasFanOnStandby) {
      svgElement.classList.add('standby');
    }
  }

  private updatePolygons() {
    const svgElement = this.element.nativeElement.querySelector(
      'svg'
    ) as SVGElement;

    if (!svgElement) {
      return;
    }

    const getPoints = (polygon: SVGElement) => {
      const originalPoints = polygon.dataset.originalpoints;
      const points = (
        originalPoints ??
        polygon.getAttribute('points') ??
        ''
      ).trim();

      if (!originalPoints) {
        polygon.dataset.originalpoints = points;
      }

      return points.split(' ').map((point) => parseFloat(point));
    };

    const setPoints = (polygon: SVGElement, points: number[]) => {
      polygon.setAttribute('points', points.join(' '));
    };

    const getPolygons = (selector: string) => {
      return svgElement.querySelectorAll(
        `${selector}`
      ) as NodeListOf<SVGElement>;
    };

    [
      {
        selector: '[id="Base"] polygon',
        processor: (polygon: SVGElement, points: number[]): number[] => {
          [0, 2].forEach((index) => {
            if (points.length > index) {
              points[index] -= (this.fanCount - 1) * this.fanWidth;
            }
          });

          return points;
        },
      },
      {
        selector: '[id="Floor"] [id="Middle"] [id="Line"]',
        processor: (polygon: SVGElement, points: number[]): number[] => {
          let minX: number;
          let maxX: number;
          let minY: number;
          let maxY: number;
          let xGap: number;

          const viewBox = svgElement.getAttribute('viewBox') ?? '';
          const viewBoxValues = viewBox
            .split(' ')
            .map((value) => parseFloat(value));

          if (viewBoxValues.length !== 4) {
            return points;
          }

          maxX = viewBoxValues[2];

          points.forEach((point, index) => {
            if (index % 2 === 0) {
              if (minX === undefined || point < minX) {
                minX = point;
              }
            } else {
              if (minY === undefined || point < minY) {
                minY = point;
              }

              if (maxY === undefined || point > maxY) {
                maxY = point;
              }
            }
          });

          let startX = minX;
          let startY = minY;
          xGap = maxX / 10;

          const newPoints: number[] = [];

          newPoints.push(startX);
          newPoints.push(startY);

          do {
            startX += xGap;
            startY = startY === minY ? maxY : minY;

            newPoints.push(startX);
            newPoints.push(startY);
          } while (startX <= maxX);

          return newPoints;
        },
      },
    ].forEach(({ selector, processor }) => {
      const polygons = getPolygons(selector);

      if (!polygons) {
        return;
      }

      polygons.forEach((polygon) => {
        const points = getPoints(polygon);

        const newPoints = processor(polygon, points);

        setPoints(polygon, newPoints);
      });
    });
  }

  private scaleViewbox() {
    const svgElement = this.element.nativeElement.querySelector(
      'svg'
    ) as SVGElement;

    if (!svgElement) {
      return;
    }

    const viewBox =
      svgElement.dataset.originalviewbox ??
      svgElement.getAttribute('viewBox') ??
      '';

    svgElement.dataset.originalviewbox = viewBox;

    const viewBoxValues = viewBox.split(' ').map((value) => parseFloat(value));

    if (viewBoxValues.length !== 4) {
      return;
    }

    const width = viewBoxValues[2];

    const newWidth = width + (this.fanCount - 1) * this.fanWidth;

    svgElement.setAttribute(
      'viewBox',
      `${viewBoxValues[0]} ${viewBoxValues[1]} ${newWidth} ${viewBoxValues[3]}`
    );
  }
}
