<h2 mat-dialog-title>Exhaust Volume Required</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup" [class.validate]="validate">
    <div>
      Please enter the following for energy recovery performance calculations:
    </div>
    <mat-form-field>
      <mat-label>Exhaust Volume</mat-label>

      <input matInput type="number" formControlName="exhaustVolume" />
      <mat-error *ngIf="!!exhaustVolume.errors">{{
        exhaustVolume | fieldErrorToText
      }}</mat-error>
    </mat-form-field>
    <div>
      <b>Tip: </b>
      <span
        >Exhaust volume must be between {{ min | number : '1.0-0' }} and
        {{ max | number : '1.0-0' }}. We suggest at least
        {{ max * 0.6 | number : '1.0-0' }} (60% of Outdoor Air Volume).</span
      >
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="okClick()">OK</button>
  <button mat-raised-button color="primary" [mat-dialog-close]>Cancel</button>
</mat-dialog-actions>
