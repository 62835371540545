export class AddressComponents {
    streetNumber: string;
    streetName: string;
    city: string;
    county: string;
    state: string;
    country: string;
    zipCode: string;
    zipCodeSuffix: string;

    constructor(data: Array<any>) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].types.filter(item => item.toLowerCase().trim() === 'street_number').length > 0) {
                this.streetNumber = data[i].long_name;
            } else if (data[i].types.filter(item => item.toLowerCase().trim() === 'route').length > 0) {
                this.streetName = data[i].long_name;
            } else if (data[i].types.filter(item => item.toLowerCase().trim() === 'locality').length > 0) {
                this.city = data[i].long_name;
            } else if (data[i].types.filter(item => item.toLowerCase().trim() === 'administrative_area_level_2').length > 0) {
                this.county = data[i].long_name;
            } else if (data[i].types.filter(item => item.toLowerCase().trim() === 'administrative_area_level_1').length > 0) {
                this.state = data[i].long_name;
            } else if (data[i].types.filter(item => item.toLowerCase().trim() === 'country').length > 0) {
                this.country = data[i].long_name;
            } else if (data[i].types.filter(item => item.toLowerCase().trim() === 'postal_code').length > 0) {
                this.zipCode = data[i].long_name;
            } else if (data[i].types.filter(item => item.toLowerCase().trim() === 'postal_code_suffix').length > 0) {
                this.zipCodeSuffix = data[i].long_name;
            }
        }
    }
}
