import { LayoutUpdate } from '../models/layout-update/layout-update.model';
import { Injectable } from '@angular/core';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { LoadingGraphic } from '../../ecaps-core/controllers/loading-graphic.controller';
import { LayoutService } from '../services/layout.service';
import { LayoutConfiguration } from '../models/layout-config/layout-configuration.model';

@Injectable({
  providedIn: 'root',
})
export class TradeOffAdvisorDialog {
  constructor(
    private core: EcapsCore,
    private loadingGraphic: LoadingGraphic,
    private layout: LayoutService
  ) {}

  public show(
    layoutUpdate: LayoutUpdate | string,
    layoutConfig?: LayoutConfiguration
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let message = '';

      if (typeof layoutUpdate === 'string') {
        message = layoutUpdate;
      } else {
        message = layoutUpdate.message;
      }

      this.core
        .showMessageBox({
          title: 'Choice Conflict',
          message: `<p>${message.trim().replace(/\n/g, '</p><p>')}</p>`,
          buttons: [
            {
              title: 'Resolve',
            },
            {
              title: 'Undo',
              value: 'UNDO',
            },
          ],
        })
        .then((result) => {
          switch (result) {
            case 'UNDO': {
              let updating = true;

              this.loadingGraphic.show('Updating...', function () {
                return updating;
              });

              if (!layoutConfig) {
                this.layout.getLastLayoutConfig().then((lastLayoutConfig) => {
                  this.layout.undoLayoutUpdate(lastLayoutConfig, 1).then(() => {
                    resolve(false);

                    updating = false;

                    this.loadingGraphic.hide();
                  });
                });
              } else {
                this.layout.undoLayoutUpdate(layoutConfig, 1).then(() => {
                  resolve(false);

                  updating = false;

                  this.loadingGraphic.hide();
                });
              }

              break;
            }
            default: {
              resolve(true);

              break;
            }
          }
        });
    });
  }
}
