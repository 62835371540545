import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'amca-seal',
  templateUrl: './amca-seal.component.html',
  styleUrls: ['./amca-seal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AmcaSealComponent implements OnInit {
  private _seal = 'A';

  @Input() public get seal() {
    return this._seal;
  }
  public set seal(value) {
    this._seal = value.toUpperCase().trim();

    if (this._seal === 'FA') {
      this._seal = 'AF';
    } else if (this._seal === 'FS') {
      this._seal = 'SF';
    }

    const classList = (
      this.elementRef.nativeElement as HTMLElement
    ).className.split(/ /g);

    classList.forEach((className) => {
      if (className.indexOf('seal-') === 0) {
        (this.elementRef.nativeElement as HTMLElement).classList.remove(
          className
        );
      }
    });

    (this.elementRef.nativeElement as HTMLElement).classList.add(
      `seal-${this._seal.toLowerCase().trim()}`
    );
  }

  private _color = false;
  @Input() public get color() {
    return this._color;
  }
  public set color(value) {
    this._color = value;

    if (value) {
      (this.elementRef.nativeElement as HTMLElement).classList.add('color');
    } else {
      (this.elementRef.nativeElement as HTMLElement).classList.remove('color');
    }
  }

  constructor(private elementRef: ElementRef) {
    (this.elementRef.nativeElement as HTMLElement).classList.add('amca-seal');
  }

  ngOnInit() {}
}
