<div class="table-parent">
  <table mat-table [dataSource]="this.revision?.items">
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef>Model</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element">
        {{ element.quantity | number : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="tag">
      <th mat-header-cell *matHeaderCellDef>Tag</th>
      <td mat-cell *matCellDef="let element">{{ element.tag }}</td>
    </ng-container>

    <ng-container matColumnDef="budgetPrice">
      <th mat-header-cell *matHeaderCellDef>Budget Price</th>
      <td mat-cell *matCellDef="let element">
        ${{ element.budgetPrice | number : '1.0-0' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="engineeringData">
      <th mat-header-cell *matHeaderCellDef>Engineering Data</th>
      <td mat-cell *matCellDef="let element">{{ element.engineeringData }}</td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="currentVersionDisplayColumns; sticky: true"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: currentVersionDisplayColumns"
    ></tr>
  </table>
</div>
