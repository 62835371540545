import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Item } from '../../models/project-item.model';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-reselect-required-dialog',
  templateUrl: './reselect-required-dialog.component.html',
  styleUrls: ['./reselect-required-dialog.component.scss'],
})
export class ReselectRequiredDialogComponent implements OnInit {
  devMessages: string[];
  prodMessages: string[];
  production = environment.production;

  constructor(
    private dialogRef: MatDialogRef<ReselectRequiredDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public item: Item
  ) {
    if (environment.production) {
      this.prodMessages = item.statusMessages;
    } else {
      this.devMessages = item.internalStatusMessages;
      this.prodMessages = item.statusMessages;
    }
  }

  ngOnInit() {}

  reselectClick() {
    this.dialogRef.close(true);
  }
}
