import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  IMessageBoxData,
  IMessageBoxButton,
} from './models/i-message-box-data.interface';

@Component({
  selector: 'app-message-box-dialog',
  templateUrl: './message-box-dialog.component.html',
  styleUrls: ['./message-box-dialog.component.scss'],
})
export class MessageBoxDialogComponent implements OnInit {
  message: SafeHtml;

  constructor(
    private dialogRef: MatDialogRef<MessageBoxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: IMessageBoxData,
    private sanitizer: DomSanitizer
  ) {
    this.message = this.sanitizer.bypassSecurityTrustHtml(dialogData.message);
  }

  ngOnInit() {}

  buttonClick(button: IMessageBoxButton) {
    button.clickEvent(this.dialogRef);
  }
}
