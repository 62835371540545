import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectSummary } from '../../models/project-summary.model';
import { ProjectsService } from '../../services/projects.service';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import { ProjectInfoDialog } from '../../controllers/project-info-dialog.controller';
import { environment } from '../../../../environments/environment';
import { Project } from '../../models/project.model';

@Component({
  selector: 'job-management-grid',
  templateUrl: './job-management-grid.component.html',
  styleUrls: ['./job-management-grid.component.scss'],
})
export class JobManagementGridComponent implements OnInit, OnDestroy {
  displayColumns = [
    'jobName',
    'createdOn',
    'lastSavedOn',
    'city',
    'quantity',
    'currentVersion',
    'action',
  ];

  activeProjects: Array<ProjectSummary>;
  activeProjectSubscription: Subscription;

  constructor(
    private projectsService: ProjectsService,
    private changeDetector: ChangeDetectorRef,
    private core: EcapsCore,
    private projectInfoDialog: ProjectInfoDialog
  ) {
    this.activeProjects = this.projectsService.getActiveProjects();

    this.sortActiveProjects();

    this.activeProjectSubscription =
      this.projectsService.activeProjectsUpdated.subscribe((activeProjects) => {
        this.activeProjects = activeProjects;

        this.sortActiveProjects();
      });
  }

  ngOnInit() {
    if (!this.activeProjects) {
      let loading = true;

      this.core.showLoadingGraphic('Loading...', function () {
        return loading;
      });

      this.projectsService.refreshActiveProjects().then(() => {
        loading = false;

        this.core.hideLoadingGraphic();
      });
    }
  }

  ngOnDestroy() {
    this.activeProjectSubscription.unsubscribe();
  }

  getActiveProjects(): Array<ProjectSummary> {
    return this.activeProjects;
  }

  projectInfo(projectSummary: ProjectSummary, project?: Project) {
    this.projectInfoDialog.show(projectSummary, null, project).then(() => {
      this.projectsService.getLocalProject().then((localProject) => {
        const curSize = Math.ceil(
          (localProject.size / environment.maxProjectSize) * 100
        );

        if (curSize >= environment.maxProjectSizeTolerance) {
          this.core
            .showMessageBox(
              {
                title: 'Edit Required',
                message:
                  'You must reduce the size of this job before you are able to use it.',
                buttons: [
                  {
                    title: 'Edit Job',
                    value: 'EDIT',
                  },
                  {
                    title: 'Close Job',
                    value: 'CLEAR',
                  },
                ],
              },
              true
            )
            .then((result: string) => {
              if (result === 'EDIT') {
                this.projectInfo(projectSummary, localProject);
              } else {
                let creating = true;

                this.core.showLoadingGraphic('Loading...', () => creating);

                this.projectsService.createProject().then((newProject) => {
                  this.projectsService.setLocalProject(newProject);

                  creating = false;

                  this.core.hideLoadingGraphic();
                });
              }
            });
        }
      });
    });
  }

  deleteProject(project: ProjectSummary) {
    this.projectsService.getLocalProject().then((localProject) => {
      const isCurrentProject = localProject.id === project.id;

      const message = isCurrentProject
        ? `"${project.name}" is your currently active job.
          Deleting this job will erase your current equipment schedule.
          Are you sure you wish to delete this job?`
        : `Are you sure you want to delete the "${project.name}" job?`;

      this.core
        .showMessageBox({
          title: 'Delete Job',
          message,
          icon: 'help',
          buttons: [
            {
              title: 'Yes',
              value: true,
            },
            {
              title: 'No',
              value: false,
            },
          ],
        })
        .then(async (results) => {
          if (results === true) {
            let deleting = true;

            this.core.showLoadingGraphic('Deleting...', function () {
              return deleting;
            });

            await this.projectsService.deleteSavedProject(project.id);

            if (isCurrentProject) {
              const newProject = await this.projectsService.createProject();

              this.projectsService.setLocalProject(newProject);
            }

            this.changeDetector.detectChanges();

            deleting = false;

            this.core.hideLoadingGraphic();
          }
        });
    });
  }

  private sortActiveProjects() {
    if (!!this.activeProjects) {
      this.activeProjects.sort(
        (a, b) => b.lastSavedDate.getTime() - a.lastSavedDate.getTime()
      );
    }
  }
}
