import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '../analytics/analytics.module';
import { GoogleModule } from '../google/google.module';
import { SideDialogModule } from '../side-dialog/side-dialog.module';
import { AppNotificationComponent } from './components/app-notification/app-notification.component';
import { ConfigurationQuestionComponent } from './components/configuration-question/configuration-question.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { InlineEditorComponent } from './components/inline-editor/inline-editor.component';
import { LoadingGraphicComponent } from './components/loading-graphic/loading-graphic.component';
import { ThemeColorSwatchesComponent } from './components/theme-color-swatches/theme-color-swatches.component';
import { EcapsCore } from './controllers/ecaps-core.controller';
import { LoadingGraphic } from './controllers/loading-graphic.controller';
import { RepLocatorDialog } from './controllers/rep-locator-dialog.controller';
import { BathSpCalculatorDialogComponent } from './dialogs/bath-sp-calculator-dialog/bath-sp-calculator-dialog.component';
import { ElevationDialogComponent } from './dialogs/elevation-dialog/elevation-dialog.component';
import { EmailDialogComponent } from './dialogs/email-dialog/email-dialog.component';
import { ErrorMessageDialogComponent } from './dialogs/error-message-dialog/error-message-dialog.component';
import { ErrorReportingDialogComponent } from './dialogs/error-reporting-dialog/error-reporting-dialog.component';
import { HelpItemDialogComponent } from './dialogs/help-item-dialog/help-item-dialog.component';
import { MessageBoxDialogComponent } from './dialogs/message-box-dialog/message-box-dialog.component';
import { RepLocatorDialogComponent } from './dialogs/rep-locator-dialog/rep-locator-dialog.component';
import { GreenheckSpecialCharactersDirective } from './directives/greenheck-special-characters.directive';
import { InputUppercaseDirective } from './directives/input-uppercase.directive';
import { LoadChildDirective } from './directives/load-child.directive';
import { OverflowTooltipDirective } from './directives/overflow-tooltip.directive';
import { SquareDirective } from './directives/square.directive';
import { StopPropClickDirective } from './directives/stop-prop-click.directive';
import { VerticalWrapDirective } from './directives/vertical-wrap.directive';
import {
  AttributeFilterPipe,
  BoolToYesNoPipe,
  BreakHorsePowerPipe,
  CamelCasePipe,
  CamelToDisplayPipe,
  DisplayMaskPipe,
  EmptyStringToDashPipe,
  FieldErrorToTextPipe,
  FormatObjectPipe,
  FractionPipe,
  HemisphericalSonesPipe,
  MaxThroatVelocityPipe,
  PercentZeroToDash,
  SafeHTMLPipe,
  SafeURLPipe,
  SphericalSonesPipe,
  ZeroToDashPipe,
} from './pipes/custom-pipes.pipe';
import { CookiePolicyService } from './services/cookie-policy.service';
import { EmailService } from './services/email.service';
import { HttpService } from './services/http.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatIconModule,
    GoogleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    SideDialogModule,
    MatListModule,
    MatTooltipModule,
    MatToolbarModule,
    MatBadgeModule,
    MatMenuModule,
    HttpClientModule,
    MatCardModule,
    MatStepperModule,
    MatDividerModule,
    AnalyticsModule,
    RouterModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatRippleModule,
  ],
  declarations: [
    AttributeFilterPipe,
    CamelCasePipe,
    FractionPipe,
    HemisphericalSonesPipe,
    SphericalSonesPipe,
    BreakHorsePowerPipe,
    ZeroToDashPipe,
    EmptyStringToDashPipe,
    CamelToDisplayPipe,
    PercentZeroToDash,
    BoolToYesNoPipe,
    DisplayMaskPipe,
    FormatObjectPipe,
    MaxThroatVelocityPipe,
    FieldErrorToTextPipe,
    SafeHTMLPipe,
    SafeURLPipe,
    ConfigurationQuestionComponent,
    InlineEditorComponent,
    ElevationDialogComponent,
    MessageBoxDialogComponent,
    LoadChildDirective,
    LoadingGraphicComponent,
    ErrorMessageDialogComponent,
    EmailDialogComponent,
    RepLocatorDialogComponent,
    GreenheckSpecialCharactersDirective,
    InputUppercaseDirective,
    ErrorReportingDialogComponent,
    BathSpCalculatorDialogComponent,
    HelpItemDialogComponent,
    StopPropClickDirective,
    AppNotificationComponent,
    CookiePolicyComponent,
    SquareDirective,
    ThemeColorSwatchesComponent,
    OverflowTooltipDirective,
    VerticalWrapDirective,
  ],
  providers: [
    EcapsCore,
    LoadingGraphic,
    EmailService,
    RepLocatorDialog,
    HttpService,
    CookiePolicyService,
  ],
  exports: [
    AttributeFilterPipe,
    CamelCasePipe,
    FractionPipe,
    HemisphericalSonesPipe,
    SphericalSonesPipe,
    BreakHorsePowerPipe,
    ZeroToDashPipe,
    EmptyStringToDashPipe,
    CamelToDisplayPipe,
    PercentZeroToDash,
    BoolToYesNoPipe,
    DisplayMaskPipe,
    FormatObjectPipe,
    MaxThroatVelocityPipe,
    FieldErrorToTextPipe,
    SafeHTMLPipe,
    SafeURLPipe,
    ConfigurationQuestionComponent,
    InlineEditorComponent,
    LoadChildDirective,
    LoadingGraphicComponent,
    GreenheckSpecialCharactersDirective,
    InputUppercaseDirective,
    StopPropClickDirective,
    FieldErrorToTextPipe,
    AppNotificationComponent,
    CookiePolicyComponent,
    SquareDirective,
    ThemeColorSwatchesComponent,
    OverflowTooltipDirective,
    VerticalWrapDirective,
  ],
})
export class EcapsCoreModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon(
      'jobs',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/jobs.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'save_as',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/saveas.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'ses',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/ses.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'submittal',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/submittal.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'droplet',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/droplet.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'chart',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/chart.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'er_wheel',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/er_wheel.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'drawing',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/drawing.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'dimensions',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/dimensions.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'amca_icon',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/amca_icon.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'down_arrow',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/down_arrow.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'ecaps_logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/ecaps_logo.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'hurricane_testing',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/hurricane_testing.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'sure_aire',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/sure_aire.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'fan',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/fan.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'dash',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/dash.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'induction',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/induction.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'greenheck',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/greenheck.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'greenheck_font',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/greenheck_font.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'revit',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/revit.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'pdf',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/pdf.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'exporta',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/exporta.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'exportb',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/exportb.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'calculator',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/calculator.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'calculator_fx',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/calculator_fx.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'calculator_sum',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/calculator_sum.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'mfan',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/mfan.svg'
      )
    );
    this.iconRegistry.addSvgIcon(
      'excel',
      this.sanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/icons/svg/excel.svg'
      )
    );
  }
}
