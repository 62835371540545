<h2 mat-dialog-title>Clear Local Storage</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup">
    <mat-checkbox
      color="primary"
      formControlName="selectAll"
      (click)="selectAllClick()"
      >Select All</mat-checkbox
    >
  </form>

  <mat-selection-list
    #keyListGroup
    class="key-list"
    color="primary"
    (selectionChange)="checkSelectAll($event)"
  >
    <mat-list-option
      *ngFor="let key of keyList"
      togglePosition="before"
      [value]="key"
    >
      {{ key }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="remove()">Remove</button>
  <button mat-raised-button color="primary" (click)="cancel()">Cancel</button>
</mat-dialog-actions>
