import { DecimalPipe, PercentPipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import {
  BoolToYesNoPipe,
  BreakHorsePowerPipe,
  EmptyStringToDashPipe,
  HemisphericalSonesPipe,
  PercentZeroToDash,
  SphericalSonesPipe,
  ZeroToDashPipe,
} from '../../../ecaps-core/pipes/custom-pipes.pipe';
import { FilterTypes } from '../enums/filter-types.enum';
import { IGridColumn } from '../models/grid-column.interface';

export class ColumnListDef {
  iconClick: EventEmitter<string>;

  columns: Array<IGridColumn>;

  getColumn: (key: string) => IGridColumn;

  getColumns: (...keys: string[]) => IGridColumn[];
}

export const ColumnList: ColumnListDef = {
  iconClick: new EventEmitter<string>(),

  columns: [
    {
      key: 'actualCFM',
      text: 'Actual CFM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'externalSP',
      text: 'Total External SP (in. wg)',
      valueFormat: (value) =>
        new DecimalPipe('en_us').transform(value, '1.0-2'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'operatingCost',
      text: 'Operating Cost/yr (USD)',
      valueFormat: (value) =>
        '$' + new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'budgetPrice',
      text: 'Budget Price (USD)',
      valueFormat: (value) =>
        '$' + new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'bhp',
      text: 'Bhp',
      valueFormat: (value) => new BreakHorsePowerPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'totalBhp',
      text: 'Total Bhp',
      valueFormat: (value) => new BreakHorsePowerPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletSones',
      text: 'Inlet Sones',
      valueFormat: (value) => new HemisphericalSonesPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletDba',
      text: 'Inlet dBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'fanRPM',
      text: 'Fan RPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'fanPower',
      text: 'Fan Power (W/CFM)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },

    {
      key: 'fanType',
      text: 'Fan Type',
      filterType: FilterTypes.contains,
    },

    {
      key: 'driveType',
      text: 'Drive Type',
      filterType: FilterTypes.contains,
    },

    {
      key: 'weight',
      text: 'Weight (lbs)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'driveLossPercentage',
      text: 'Drive Loss (%)',
      valueFormat: (value) =>
        new PercentPipe('en_us').transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'amca',
      text: 'AMCA',
      filterType: null,
      render: false,
    },

    {
      key: 'wheelDiameter',
      text: 'Wheel Diameter (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'wheelType',
      text: 'Wheel Type',
      filterType: FilterTypes.contains,
    },

    {
      key: 'partialWheelWidth',
      text: 'Partial Wheel Width (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'motorHP',
      text: 'Motor Size (hp)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'motorHPWatts',
      text: 'Motor Size (W)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'motorQty',
      text: 'Motor Qty',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'fanSeries',
      text: 'Fan Series',
      valueFormat: (value) => new EmptyStringToDashPipe().transform(value),
      filterType: FilterTypes.contains,
    },

    {
      key: 'outletVelocity',
      text: 'Outlet Velocity (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'staticEfficiency',
      text: 'SE (%)',
      valueFormat: (value) =>
        new PercentPipe('en_us').transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'feg',
      text: 'FEG',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'fei',
      text: 'FEI',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.2-2'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'totalEfficiency',
      text: 'Operating TE (%)',
      valueFormat: (value) =>
        new PercentPipe('en_us').transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'peakTotalEfficiency',
      text: 'Peak TE (%)',
      valueFormat: (value) =>
        new PercentPipe('en_us').transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'pointsWithinPeakTotalEfficiency',
      text: 'Pts Within Peak TE',
      valueFormat: (value) => new PercentZeroToDash().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'hasVFD',
      text: 'VFD Applied',
      valueFormat: (value) => new BoolToYesNoPipe().transform(value),
      filterType: FilterTypes.bool,
    },

    {
      key: 'operatingFrequency',
      text: 'Operating Frequency (Hz)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'speedNonAdjusted',
      text: 'Non-Adjusted Motor Speed (RPM)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'hasSpeedController',
      text: 'Speed Controlled',
      valueFormat: (value) => new BoolToYesNoPipe().transform(value),
      filterType: FilterTypes.bool,
    },

    {
      key: 'hasBaffle',
      text: 'Baffle Applied',
      valueFormat: (value) => new BoolToYesNoPipe().transform(value),
      filterType: FilterTypes.bool,
    },

    {
      key: 'startupPower',
      text: 'Start-up Power (bhp)',
      valueFormat: (value) => new BreakHorsePowerPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'speedMax',
      text: 'Max Class Speed (RPM)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'tipSpeed',
      text: 'Tip Speed (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand63Hz',
      text: 'Inlet Octave Band 63Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand125Hz',
      text: 'Inlet Octave Band 125Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand250Hz',
      text: 'Inlet Octave Band 250Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand500Hz',
      text: 'Inlet Octave Band 500Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand1kHz',
      text: 'Inlet Octave Band 1kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand2kHz',
      text: 'Inlet Octave Band 2kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand4kHz',
      text: 'Inlet Octave Band 4kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletOctaveBand8kHz',
      text: 'Inlet Octave Band 8kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletLwa',
      text: 'Inlet Sound Power (Lwa)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand63Hz',
      text: 'Outlet Octave Band 63Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand125Hz',
      text: 'Outlet Octave Band 125Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand250Hz',
      text: 'Outlet Octave Band 250Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand500Hz',
      text: 'Outlet Octave Band 500Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand1kHz',
      text: 'Outlet Octave Band 1kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand2kHz',
      text: 'Outlet Octave Band 2kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand4kHz',
      text: 'Outlet Octave Band 4kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletOctaveBand8kHz',
      text: 'Outlet Octave Band 8kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletLwa',
      text: 'Outlet Sound Power (Lwa)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletSones',
      text: 'Outlet Sones',
      valueFormat: (value) => new HemisphericalSonesPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'outletDba',
      text: 'Outlet dBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'supplyOutletDba',
      text: 'Supply Outlet Sound (dBA)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'exhaustInletSoundDba',
      text: 'Exhaust Inlet Sound (dBA)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand63Hz',
      text: 'Radiated Octave Band 63Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand125Hz',
      text: 'Radiated Octave Band 125Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand250Hz',
      text: 'Radiated Octave Band 250Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand500Hz',
      text: 'Radiated Octave Band 500Hz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand1kHz',
      text: 'Radiated Octave Band 1kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand2kHz',
      text: 'Radiated Octave Band 2kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand4kHz',
      text: 'Radiated Octave Band 4kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedOctaveBand8kHz',
      text: 'Radiated Octave Band 8kHz',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedLwa',
      text: 'Radiated Sound Power (Lwa)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedSones',
      text: 'Radiated Sones',
      valueFormat: (value) => new HemisphericalSonesPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'radiatedDba',
      text: 'Radiated dBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'wallOpening',
      text: 'Wall Opening (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-3'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'roofOpeningSq',
      text: 'Roof Opening (in. x in.)',
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'watts',
      text: 'Watts',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'sphericalSones',
      text: 'Spherical Sones',
      valueFormat: (value) => new SphericalSonesPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'operatingStaticPressure',
      text: 'Total External SP (in. wg)',
      valueFormat: (value) =>
        new DecimalPipe('en_us').transform(value, '1.0-2'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'roofOpeningWL',
      text: 'Roof Opening (WxL) (in.)',
      filterType: FilterTypes.contains,
    },

    {
      key: 'dimensionsLWH',
      text: 'Dimensions (LxWxH) (in.)',
      filterType: FilterTypes.contains,
    },

    {
      key: 'actualThroatVelocity',
      text: 'Actual Throat Velocity (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'actualPressureDrop',
      text: 'Actual Pressure Drop (in. wg)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.3-3'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'shippedAssembled',
      text: 'Shipped Assembled',
      valueFormat: (value) => new BoolToYesNoPipe().transform(value),
      filterType: FilterTypes.bool,
    },

    {
      key: 'actualThroatArea',
      text: 'Actual Throat Area (ft<sup>2</sup>)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.3-3'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'louverFreeArea',
      text: 'Free Area (ft<sup>2</sup>)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'minimumThroatHeight',
      text: 'Min Louver Height (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'actualThroatWidth',
      text: 'Width (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.3-3'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'actualThroatLength',
      text: 'Length (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.3-3'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'model',
      text: 'Model',
      filterType: FilterTypes.contains,
    },

    {
      key: 'fanClassDescription',
      text: 'Fan Class',
      valueFormat: (value) => new EmptyStringToDashPipe().transform(value),
      filterType: FilterTypes.contains,
    },

    {
      key: 'actualDepth',
      text: 'Depth (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'rainResistanceRanking',
      text: 'Water Rejection Rating',
      filterType: null,
      render: false,
    },

    {
      key: 'actualFreeArea',
      text: 'Free Area (ft<sup>2</sup>)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: FilterTypes.greaterThan,
    },

    // Value comes though from Fan Selector already modified, do not use PercentPipe
    {
      key: 'freeAreaPercentage',
      text: 'Free Area (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'actualVelocity',
      text: 'Velocity (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'maxVelocity',
      text: 'Max Velocity (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'maxVelocityWithSafetyFactor',
      text: 'Max Velocity With SF',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'suggestedWidth',
      text: 'Width (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'suggestedHeight',
      text: 'Height (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'classA293Limit',
      text: 'Class A Limit (29mph at 3in.) (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'classA508Limit',
      text: 'Class A Limit (50mph at 8in.) (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'beginningPointOfWaterPenetration',
      text: 'BPWP (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'sectionsWide',
      text: 'Sections Wide',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'sectionsHigh',
      text: 'Sections High',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'actualToMaxVelocityPercentage',
      text: 'Percent Within Max Velocity',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'actualToMaxVelocityDelta',
      text: 'Velocity To Max Velocity Delta',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'systemBhp',
      text: 'System Bhp',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'effectivePlume',
      text: 'Effective Plume (ft)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'wbExitTemp',
      text: 'WB Exit Temp (F)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'inletDesignTemp',
      text: 'Inlet Design Temp (F)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'systemInletDba',
      text: 'System Inlet dBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'ervOption',
      text: 'ERV Option',
      render: false,
      filterType: null,
    },

    {
      key: 'relativeCost',
      text: 'Relative Cost',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.2-2'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'totalCostOfOwnership',
      text: 'Total Cost Of Ownership (USD)',
      valueFormat: (value) =>
        '$' + new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'coolingLat',
      text: 'Cooling LAT (DB/WB)',
      filterType: FilterTypes.contains,
    },

    {
      key: 'totalCoolingCap',
      text: 'Total Cooling CAP (MBH)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'coolingEffect',
      text: 'Cooling Effect (F)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'heatingLatDb',
      text: 'Heating LAT (DB)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'heatingCap',
      text: 'Heating CAP',
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'mcaMop',
      text: 'MCA/MOP (Amps)',
      filterType: FilterTypes.contains,
    },

    {
      key: 'dimensions',
      text: 'Dimensions (LxWxH) (in)',
      filterType: FilterTypes.contains,
    },

    {
      key: 'dilutionRatio',
      text: 'Dilution Ratio',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'nozzleSize',
      text: 'Nozzle Size (in.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'nozzleOutletVelocity',
      text: 'Nozzle OV (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'windbandArea',
      text: 'Windband Area (ft<sup>2</sup>)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'fanQuantity',
      text: 'Fan Qty',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'affectedArea',
      text: 'Req. Coverage Area per Fan (sq ft)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'minFanSpacing',
      text: 'Min Fan Spacing (ft.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'actualFanSpacing',
      text: 'Fan Spacing (ft.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'fanEfficiency',
      text: 'Integrated Efficiency (CFM/Watt)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'directionOfOperation',
      text: 'Direction of Operation',
      filterType: FilterTypes.contains,
    },

    {
      key: 'bladeCount',
      text: 'Blade Count',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'cfei40Speed',
      text: 'CFEI at 40% Speed',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.2-2'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'cfei100Speed',
      text: 'CFEI at 100% Speed',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.2-2'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'avgAirSpeed',
      text: 'Avg Air Speed (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'avgAirSpeedMax',
      text: 'Avg Air Speed Max (ft/min)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'maxCFM',
      text: 'Max. CFM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'erDevice',
      text: 'ER Device',
      filterType: FilterTypes.contains,
    },

    {
      key: 'erMedia',
      text: 'ER Media',
      filterType: FilterTypes.contains,
    },

    {
      key: 'summerASHRAE901ERR',
      text: 'Summer ASHRAE 90.1 ERR (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'summerLAT',
      text: 'Summer LAT (DB/WB)',
      filterType: FilterTypes.contains,
    },

    {
      key: 'winterASHRAE901ERR',
      text: 'Winter ASHRAE 90.1 ERR (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'winterLAT',
      text: 'Winter LAT (DB/WB)',
      filterType: FilterTypes.contains,
    },

    {
      key: 'sensibleEffectivenessSummer',
      text: 'Summer Sensible Effectiveness (%)',
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'sensibleEffectivenessWinter',
      text: 'Winter Sensible Effectiveness (%)',
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'totalExhaustEfficiencySummer',
      text: 'Summer Total Effectiveness (%) ',
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'totalExhaustEfficiencyWinter',
      text: 'Winter Total Effectiveness (%)',
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'exhaustAirTransferRatio',
      text: 'EATR (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'err',
      text: 'ERR (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'supplySizeHP',
      text: 'Supply Size (hp)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'supplyTotalBhp',
      text: 'Supply Total Bhp',
      valueFormat: (value) => new BreakHorsePowerPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'supplyFanRPM',
      text: 'Supply FRPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'supplyOutletSones',
      text: 'Supply Outlet Sones',
      valueFormat: (value) => new HemisphericalSonesPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'supplyFEI',
      text: 'Supply FEI',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'exhaustFEI',
      text: 'Exhaust FEI',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'exhaustSizeHP',
      text: 'Exhaust Size (hp)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'exhaustTotalBhp',
      text: 'Exhaust Total Bhp',
      valueFormat: (value) => new BreakHorsePowerPipe().transform(value),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'exhaustFRPM',
      text: 'Exhaust FRPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'supplyOutletdBA',
      text: 'Supply Outlet dBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },

    {
      key: 'exhaustInletdBA',
      text: 'Exhaust Inlet dBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },
    {
      key: 'ashpDeratedLeavingAirDryBulbHeat',
      text: 'Air Source Heat Pump Heating LAT (DB)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'ashpDeratedHeatingCapacity',
      text: 'Air Source Heat Pump Heating Cap (MBH)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'energyRecoveryTypeActual',
      text: 'ER Device',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
  ],

  getColumn(key: string): IGridColumn {
    const oldColumn = ColumnList.columns.find((column) => column.key === key);

    const newColumn: IGridColumn = {
      key: oldColumn.key,
      text: oldColumn.text,
      valueFormat: oldColumn.valueFormat,
      filterType: oldColumn.filterType,
      render: oldColumn.render,
    };

    if (!!oldColumn.icon) {
      newColumn.icon = {
        name: oldColumn.icon.name,
        svg: oldColumn.icon.svg,
        click: oldColumn.icon.click,
        tooltip: oldColumn.icon.tooltip,
      };
    }

    return newColumn;
  },

  getColumns(...keys: string[]): IGridColumn[] {
    const output: IGridColumn[] = [];

    for (const key of keys) {
      if (Array.isArray(key)) {
        for (const subKey of key) {
          output.push(ColumnList.getColumn(subKey));
        }
      } else {
        output.push(ColumnList.getColumn(key));
      }
    }

    return output;
  },
};
