import { EventEmitter } from '@angular/core';
import { Question } from './question.model';
import { Group } from './group.model';
import { ProductTypes } from '../../../products/enums/product-types.enum';
import { LayoutService } from '../../services/layout.service';
import { ProductCategory } from '../../../products/services/products.service';

export class LayoutConfiguration {
  hasSalesBill: boolean;
  key: string;
  group: Group;
  productType: ProductTypes;
  businessUnit: string;
  modelGroup: string;
  validationState: string;
  validationMessages: Array<{ type: string; message: string }>;
  updated = new EventEmitter<{ name: string; value: string }[] | undefined>();
  private _layoutService: LayoutService;

  constructor(
    config: any,
    layoutService: LayoutService,
    productType: ProductTypes = null,
    businessUnit?: string
  ) {
    if (config instanceof LayoutConfiguration) {
      return config;
    }

    this._layoutService = layoutService;

    this.hasSalesBill = config.hasSalesBill;
    this.key = config.key;

    this.businessUnit = businessUnit;

    this.group = new Group(config.group);

    this.productType = productType;

    this.validationState = config.validationState;
    this.validationMessages = (config.validationMessages || []).map(
      (validationMessage) => ({
        type: validationMessage.type,
        message: validationMessage.message,
      })
    );
  }

  toJSON() {
    return {
      hasSalesBill: this.hasSalesBill,
      key: this.key,
      group: this.group.toJSON(),
      productType: this.productType,
      modelGroup: this.modelGroup,
    };
  }

  getQuestion(name: string): Question {
    return this.group.getQuestion(name);
  }

  getGroup(name: string): Group {
    return this.group.getGroup(name);
  }

  requiredComplete(): boolean {
    if (this.group.groups && this.group.groups.length > 0) {
      const groups = this.group.groups.filter((subGroup) => subGroup.visible);

      for (let i = 0; i < groups.length; i++) {
        if (!groups[i].requiredComplete()) {
          return false;
        }
      }
    }

    if (this.group.questions && this.group.questions.length > 0) {
      const baseQuestions = this.group.questions.filter(
        (baseQuestion) =>
          baseQuestion.visible &&
          baseQuestion.required &&
          (baseQuestion.value === null ||
            baseQuestion.value === undefined ||
            baseQuestion.value === '')
      );

      if (baseQuestions.length > 0) {
        return false;
      }
    }

    return true;
  }

  getCategory(): ProductCategory {
    return this._layoutService.getCategory(this) as ProductCategory;
  }
}
