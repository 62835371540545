import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadcrumbService, ICrumb } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  crumbs: ICrumb[] = [];

  private crumbSub: Subscription;
  private routerSub: Subscription;

  constructor(private crumbService: BreadcrumbService, private router: Router) {
    this.crumbSub = this.crumbService.crumbsUpdated.subscribe(() => {
      this.crumbs = this.crumbService.crumbs;
    });

    this.routerSub = this.router.events.subscribe((eventData) => {
      if (eventData instanceof NavigationEnd && eventData.id !== 1) {
        const config =
          this.router.routerState.snapshot.root.firstChild.routeConfig;
        if (!!config.data && !!config.data.breadcrumbs) {
          crumbService.crumbs = config.data.breadcrumbs as ICrumb[];
        }
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.crumbSub.unsubscribe();
    this.routerSub.unsubscribe();
  }

  reset(): void {
    this.crumbService.crumbs = [];
    this.router.navigate(['']);
  }

  crumbClick(crumb: ICrumb): void {
    if (typeof crumb.route === 'function') {
      crumb.route();
    } else if (typeof crumb.route === 'string') {
      this.router.navigate([crumb.route]);
    }
  }
}
