import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IMessageBoxData } from '../dialogs/message-box-dialog/models/i-message-box-data.interface';
import { MessageBoxDialogComponent } from '../dialogs/message-box-dialog/message-box-dialog.component';
import { LocationUpdate } from '../dialogs/elevation-dialog/models/location-update.model';
import { ElevationDialogComponent } from '../dialogs/elevation-dialog/elevation-dialog.component';
import { LoadingGraphic } from './loading-graphic.controller';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class EcapsCore {
  constructor(
    private dialog: MatDialog,
    private loadingGraphic: LoadingGraphic,
    private sideDialogService: SideDialogService
  ) {}

  public showMessageBox(
    data: IMessageBoxData,
    disableClose?: boolean
  ): Promise<any> {
    const promise = new Promise<boolean>(resolve => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = !!disableClose;
      dialogConfig.autoFocus = true;
      dialogConfig.data = data;

      const dialogRef = this.dialog.open(
        MessageBoxDialogComponent,
        dialogConfig
      );

      const subscription = dialogRef.beforeClosed().subscribe(result => {
        resolve(result);

        subscription.unsubscribe();
      });
    });

    return promise;
  }

  public showElevationDialog(): Promise<LocationUpdate> {
    const promise = new Promise<LocationUpdate>((resolve, reject) => {
      const dialogRef = this.sideDialogService.open(ElevationDialogComponent, {
        disableClose: false,
        autoFocus: true
      });

      const subscription = dialogRef.beforeClose.subscribe(results => {
        resolve(results);

        subscription.unsubscribe();
      });
    });

    return promise;
  }

  public showLoadingGraphic(text: string, verifier: () => boolean) {
    this.loadingGraphic.show(text, verifier);
  }

  public hideLoadingGraphic(all: boolean = false) {
    this.loadingGraphic.hide(all);
  }
}
