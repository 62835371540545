import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../users/services/users.service';

@Injectable({
    providedIn: 'root'
})
export class JobManagementPageGuard {

    constructor(private router: Router,
        private usersService: UsersService) { }

    canActivate(): Promise<boolean> {

        return new Promise<boolean>((result, reject) => {

            this.usersService.getCurrentUser().then(userData => {

                if (!userData || !userData.authenticated) {

                    this.router.navigateByUrl('/');

                    result(false);

                } else {

                    result(true);

                }

            });

        });

    }

}
