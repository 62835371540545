import { Action } from './action.model';

export class Question {
    question: string;
    message: string;
    group: string;
    actions: Array<Action>;
    constructor(data: any) {
        this.question = data.question;
        this.message = data.message;
        this.group = data.group;
        this.actions = new Array<Action>();
        if (!!data.actions) {
            for (let i = 0; i < data.actions.length; i++) {
                this.actions.push(new Action(data.actions[i]));
            }
        }
    }
}
