import { Component, OnInit, Input } from '@angular/core';
import { Revision } from '../../models/project-summar-revision.model';

@Component({
  selector: 'project-revision-details',
  templateUrl: './project-revision-details.component.html',
  styleUrls: ['./project-revision-details.component.scss'],
})
export class ProjectRevisionDetailsComponent implements OnInit {
  @Input('project-summary-revision') revision: Revision;

  currentVersionDisplayColumns = ['model', 'quantity', 'tag'];

  constructor() {}

  ngOnInit() {}
}
