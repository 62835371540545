import { Note } from './project-note.model';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { EventEmitter } from '@angular/core';
import { IProjectEvent } from './i-project-event.interface';
import { ProjectItemUpdateTypes } from '../enums/project-item-update-types.enum';
import { Revision } from './project-revision.model';
import { Subscription } from 'rxjs';
import { IValidSizeEvent } from '../../selections/models/selection-results/i-valid-size-event.interface';
import { ValidSizeUpdateTypes } from '../../selections/models/selection-results/valid-size-update-types.enum';
import { ProjectItemStatus } from './project-item-status.enum';
import { IRevitInfo } from './i-revit-info.interface';

export class Item {
  private _areaServed: string;
  public get areaServed(): string {
    return this._areaServed;
  }
  public set areaServed(value: string) {
    this._areaServed = value;

    this.markDirty();

    this.fireUpdate({
      source: this,
      type: ProjectItemUpdateTypes.propertiesUpdated,
    });
  }

  private _characteristics: any;
  public get characteristics(): any {
    return this._characteristics;
  }

  private _details: any;
  public get details(): any {
    return this._details;
  }

  private _id: string;
  public get id(): string {
    return this._id;
  }

  private _location: string;
  public get location(): string {
    return this._location;
  }
  public set location(value: string) {
    this._location = value;

    this.markDirty();

    this.fireUpdate({
      source: this,
      type: ProjectItemUpdateTypes.propertiesUpdated,
    });
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }

  notes: Note[] = [];

  private _quantity: number;
  public get quantity(): number {
    return this._quantity;
  }
  public set quantity(value: number) {
    this._quantity = value;

    this.markDirty();

    this.fireUpdate({
      source: this,
      type: ProjectItemUpdateTypes.propertiesUpdated,
    });
  }

  private _tag: string;
  public get tag(): string {
    return this._tag;
  }
  public set tag(value: string) {
    this._tag = value;

    this.markDirty();

    this.fireUpdate({
      source: this,
      type: ProjectItemUpdateTypes.propertiesUpdated,
    });
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }

  private _version: any;
  public get version(): any {
    return this._version;
  }

  private sizeDataUpdateSubscription: Subscription;

  private _sizeData: ValidSize;
  public get sizeData(): ValidSize {
    return this._sizeData;
  }
  public set sizeData(value: ValidSize) {
    this._sizeData = value;

    if (!!this.sizeDataUpdateSubscription) {
      this.sizeDataUpdateSubscription.unsubscribe();

      this.sizeDataUpdateSubscription = null;
    }

    if (!!this._sizeData) {
      this.sizeDataUpdateSubscription = this._sizeData.updated.subscribe(
        (data: IValidSizeEvent) => {
          if (
            data.type === ValidSizeUpdateTypes.layoutConfigUpdated ||
            data.type === ValidSizeUpdateTypes.selectionLayoutConfigUpdated
          ) {
            this.markDirty();

            this.fireUpdate({
              source: this,
              type: ProjectItemUpdateTypes.sizeDataUpdated,
            });
          }
        }
      );
    }

    this.fireUpdate({
      source: this,
      type: ProjectItemUpdateTypes.propertiesUpdated,
    });
  }

  private _size: number;
  public get size(): number {
    return this._size;
  }
  public set size(value: number) {
    this._size = value;

    this.fireUpdate({
      source: this,
      type: ProjectItemUpdateTypes.propertiesUpdated,
    });
  }

  updated = new EventEmitter<IProjectEvent>();

  private _revision: Revision;
  public get revision(): Revision {
    return this._revision;
  }
  public set revision(value: Revision) {
    this._revision = value;
  }
  private _status: ProjectItemStatus;
  public get status(): ProjectItemStatus {
    return this._status;
  }

  private _revitInfo: IRevitInfo;
  public get revitInfo(): IRevitInfo {
    return this._revitInfo;
  }

  private _statusMessages: string[] = [];
  public get statusMessages(): string[] {
    return this._statusMessages;
  }

  private _internalStatusMessages: string[] = [];
  public get internalStatusMessages(): string[] {
    return this._internalStatusMessages;
  }
  constructor(itemData: any, sizeData?: ValidSize) {
    this._areaServed = itemData.areaServed;
    this._characteristics = itemData.characteristics;
    this._status = !itemData.status
      ? ProjectItemStatus.valid
      : itemData.status.toUpperCase().trim();
    this._statusMessages = itemData.statusMessages;
    this._internalStatusMessages = itemData.internalStatusMessages;
    this._revitInfo = itemData.revitInfo;

    this._details = {};

    if (itemData.details) {
      for (let i = 0; i < itemData.details.length; i++) {
        this._details[itemData.details[i].name] = itemData.details[i].value;
      }
    }

    this._id = itemData.id;
    this._location = itemData.location;
    this._name = itemData.name;

    if (!!itemData.notes) {
      itemData.notes.forEach((noteData) => {
        this.addNote(new Note(noteData));
      });
    }

    this._quantity = itemData.quantity;
    this._tag = itemData.tag;
    this._type = itemData.type;
    this._version = itemData.version;

    if (!!sizeData) {
      this.sizeData = sizeData;
    } else if (itemData.sizeData) {
      this.sizeData = itemData.sizeData;
    }

    this._size = itemData.size;
  }

  toJSON() {
    const output = {
      areaServed: this.areaServed,
      characteristics: this.characteristics,
      details: this.details,
      id: this.id,
      location: this.location,
      name: this.name,
      notes: this.notes,
      revitInfo: this.revitInfo,
      quantity: this.quantity,
      tag: this.tag,
      type: this.type,
      version: this.version,
      size: this.size,
      sizeData: !!this.sizeData ? this.sizeData.toJSON() : null,
    };

    return output;
  }

  addNote(note: Note) {
    if (!this.notes.find((subNote) => subNote.id === note.id)) {
      note.item = this;

      this.notes.push(note);

      this.markDirty();

      this.fireUpdate({
        source: note,
        type: ProjectItemUpdateTypes.notesUpdated,
      });
    }
  }

  removeNote(note: Note) {
    const index = this.notes.findIndex((noteItem) => noteItem.id === note.id);

    if (index > -1) {
      this.notes.splice(index, 1);

      this.markDirty();

      this.fireUpdate({
        source: note,
        type: ProjectItemUpdateTypes.notesUpdated,
      });
    }
  }

  private fireUpdate(data: IProjectEvent) {
    this.updated.next(data);

    if (!!this.revision) {
      this.revision.updated.next(data);

      if (!!this.revision.project) {
        this.revision.project.updated.next(data);
      }
    }
  }

  private markDirty() {
    if (!!this.revision && !!this.revision.project) {
      this.revision.project.isDirty = true;
    }
  }

  dispose() {
    if (!!this.sizeDataUpdateSubscription) {
      this.sizeDataUpdateSubscription.unsubscribe();
    }

    if (!!this._sizeData) {
      this._sizeData.dispose();
    }
  }
}
