<div class="revit-job-marks-page">
  <mat-toolbar color="primary">{{ selectedProject?.name }} Marks</mat-toolbar>

  <mat-toolbar>
    <mat-icon color="primary">info_outline</mat-icon>
    <span
      >Please note that to modify any marks or job properties, you will need to
      make these edits in the web based version of eCAPS.</span
    >
  </mat-toolbar>

  <mat-tab-group
    color="primary"
    [mat-stretch-tabs]="false"
    (selectedTabChange)="tabChange($event)"
    matSortActive="tag"
    matSortDirection="asc"
  >
    <mat-tab label="Insert">
      <div class="table-parent">
        <table
          #insertTableSort="matSort"
          mat-table
          [dataSource]="insertDS"
          matSort
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? insertMasterToggle() : null"
                [checked]="insertSelection.hasValue() && isInsertAllSelected()"
                [indeterminate]="
                  insertSelection.hasValue() && !isInsertAllSelected()
                "
                [aria-label]="insertCheckboxLabel()"
                color="primary"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" [ngSwitch]="row.type">
              <mat-checkbox
                *ngSwitchCase="0"
                (click)="$event.stopPropagation()"
                (change)="$event ? insertSelection.toggle(row) : null"
                [checked]="insertSelection.isSelected(row)"
                [aria-label]="insertCheckboxLabel(row)"
                color="primary"
              >
              </mat-checkbox>

              <mat-icon
                color="accent"
                matTooltip="This model does not currently have LOD300 content available."
                *ngSwitchDefault
                style="margin-left: -4px"
                >warning</mat-icon
              >
            </td>
          </ng-container>

          <ng-container matColumnDef="tag">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
            <td mat-cell *matCellDef="let element">{{ element.tag }}</td>
          </ng-container>

          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
            <td mat-cell *matCellDef="let element">{{ element.model }}</td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
            <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="insertDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: insertDisplayedColumns"
          ></tr>
        </table>
      </div>
    </mat-tab>

    <mat-tab label="Download">
      <div class="table-parent">
        <table
          #downloadTableSort="matSort"
          mat-table
          [dataSource]="downloadDS"
          matSort
        >
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? downloadMasterToggle() : null"
                [checked]="
                  downloadSelection.hasValue() && isDownloadAllSelected()
                "
                [indeterminate]="
                  downloadSelection.hasValue() && !isDownloadAllSelected()
                "
                [aria-label]="downloadCheckboxLabel()"
                color="primary"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row" [ngSwitch]="row.type">
              <mat-icon
                color="accent"
                matTooltip="This model does not currently have content available."
                *ngSwitchCase="2"
                style="margin-left: -4px"
                >warning</mat-icon
              >

              <mat-checkbox
                *ngSwitchDefault
                (click)="$event.stopPropagation()"
                (change)="$event ? downloadSelection.toggle(row) : null"
                [checked]="downloadSelection.isSelected(row)"
                [aria-label]="downloadCheckboxLabel(row)"
                color="primary"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="tag">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag</th>
            <td mat-cell *matCellDef="let element">{{ element.tag }}</td>
          </ng-container>

          <ng-container matColumnDef="model">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Model</th>
            <td mat-cell *matCellDef="let element">{{ element.model }}</td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let element" [ngSwitch]="element.type">
              <div class="download-type" *ngSwitchCase="0">
                <mat-icon svgIcon="revit" style="color: #0a0"></mat-icon>
                <span>LOD 300</span>
              </div>

              <div class="download-type" *ngSwitchCase="1">
                <mat-icon svgIcon="revit" color="accent"></mat-icon>
                <span>Revit Project</span>
              </div>

              <div class="download-type" *ngSwitchCase="2">
                <mat-icon style="color: #a00">not_interested</mat-icon>
                <span>Unavailable</span>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
            <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="downloadDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: downloadDisplayedColumns"
          ></tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div class="button-container">
    <button
      mat-raised-button
      color="primary"
      [routerLink]="['/revit/jobs', revitVersion, pluginVersion]"
    >
      <mat-icon>arrow_back</mat-icon>
      <span>Back</span>
    </button>

    <ng-container [ngSwitch]="insertDrawing">
      <button
        *ngSwitchCase="true"
        mat-raised-button
        color="primary"
        (click)="insertDownloadClick(insertDrawing)"
      >
        <mat-icon svgIcon="revit"></mat-icon>
        <span>Insert</span>
      </button>

      <button
        *ngSwitchCase="false"
        mat-raised-button
        color="primary"
        (click)="insertDownloadClick(insertDrawing)"
      >
        <mat-icon>cloud_download</mat-icon>
        <span>Download</span>
      </button>
    </ng-container>
  </div>
</div>
