import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LocationUpdate } from './models/location-update.model';
import { GoogleMapsService } from '../../../google/services/google-maps.service';
import { LoadingGraphic } from '../../controllers/loading-graphic.controller';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { GeoLocation } from '../../../google/models/geo-location.model';
import { SideDialogComponent } from '../../../side-dialog/classes/side-dialog-component.interface';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../../../google/services/google-analytics.service';

@Component({
  selector: 'app-elevation-dialog',
  templateUrl: './elevation-dialog.component.html',
  styleUrls: ['./elevation-dialog.component.scss'],
})
export class ElevationDialogComponent
  implements OnInit, OnDestroy, SideDialogComponent
{
  @ViewChild('map', { static: false }) private mapElement: ElementRef;

  public dialogData: any;

  elevation: number;

  private map: any;
  private mapPin: any;

  private mapClickSubscription: Subscription;
  private mapPinDragEndSubscription: Subscription;

  public formGroup: UntypedFormGroup;

  public addressSearch: UntypedFormControl;

  constructor(
    private sideDialogService: SideDialogService,
    private googleMaps: GoogleMapsService,
    private loadingGraphic: LoadingGraphic,
    private gaServices: GoogleAnalyticsService
  ) {
    this.elevation = 0;

    this.addressSearch = new UntypedFormControl('', [Validators.required]);

    this.formGroup = new UntypedFormGroup({
      addressSearch: this.addressSearch,
    });
  }

  ngOnInit() {
    this.googleMaps.apiLoaded.then(() => {
      this.renderMap();

      this.gaServices.trackEvent_Old(EventCategories.elevationDialog, 'Open');
    });
  }

  ngOnDestroy() {
    if (this.mapClickSubscription) {
      this.mapClickSubscription.unsubscribe();
    }

    if (this.mapPinDragEndSubscription) {
      this.mapPinDragEndSubscription.unsubscribe();
    }
  }

  searchClick() {
    let searching = true;

    this.loadingGraphic.show('Locating...', function () {
      return searching;
    });

    this.gaServices.trackEvent_Old(
      EventCategories.elevationDialog,
      'Searched Address'
    );

    this.googleMaps.geocodeAddress(this.addressSearch.value).then((results) => {
      this.googleMaps
        .getElevation(results.geometry.location)
        .then((elevation) => {
          this.addressSearch.setValue(results.formattedAddress);
          this.elevation = elevation;

          this.updatePinLocation(results.geometry.location, true);

          searching = false;

          this.loadingGraphic.hide();
        });
    });
  }

  currentLocationClick() {
    if (navigator.geolocation) {
      const self = this;

      this.gaServices.trackEvent_Old(
        EventCategories.elevationDialog,
        'Current Location Search'
      );

      navigator.geolocation.getCurrentPosition(function (loc) {
        self.geocodeMapLocation(
          { lat: loc.coords.latitude, lng: loc.coords.longitude },
          true
        );
      });
    }
  }

  cancelClick() {
    this.sideDialogService.close(null);
  }

  okClick() {
    this.gaServices.trackEvent_Old(
      EventCategories.elevationDialog,
      'Used Elevation'
    );

    this.sideDialogService.close(
      new LocationUpdate(this.addressSearch.value, this.elevation)
    );
  }

  addressKeyUp($event: KeyboardEvent) {
    const key = $event.key.toUpperCase().trim();

    if (
      key === 'ENTER' &&
      !!this.addressSearch.value &&
      this.addressSearch.value.trim() !== ''
    ) {
      $event.cancelBubble = true;
      $event.stopPropagation();
      $event.preventDefault();

      this.searchClick();
    }
  }

  private renderMap() {
    this.map = this.googleMaps.createMap(
      this.mapElement.nativeElement,
      { lat: 40.66765835357266, lng: -97.93289476055907 },
      4
    );

    this.mapClickSubscription = this.googleMaps
      .addMapListener(this.map, 'click')
      .subscribe((event) => {
        this.geocodeMapLocation(event.latLng);
      });
  }

  private updatePinLocation(location: GeoLocation, recenter?: boolean) {
    this.gaServices.trackEvent_Old(
      EventCategories.elevationDialog,
      'Map Click'
    );

    if (!this.mapPin) {
      this.mapPin = this.googleMaps.createPin(this.map, location);

      this.mapPinDragEndSubscription = this.googleMaps
        .addPinListener(this.mapPin, 'dragend')
        .subscribe((event) => {
          this.geocodeMapLocation(event.latLng);
        });
    } else {
      this.googleMaps.movePin(this.mapPin, location);
    }

    if (recenter) {
      this.googleMaps.recenterMap(this.map, location, 16);
    }
  }

  private geocodeMapLocation(location: any, recenter?: boolean) {
    let locating = true;

    this.loadingGraphic.show('Locating...', function () {
      return locating;
    });

    this.googleMaps.geocodeLocation(location).then((results) => {
      this.googleMaps
        .getElevation(results.geometry.location)
        .then((elevation) => {
          this.addressSearch.setValue(results.formattedAddress);
          this.elevation = elevation;

          this.updatePinLocation(results.geometry.location, recenter);

          locating = false;

          this.loadingGraphic.hide();
        });
    });
  }
}
