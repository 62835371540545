import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Injector,
} from '@angular/core';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { Subscription } from 'rxjs';
import { ColumnList } from '../../constants/column-list.const';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';

@Component({
  selector: 'ceiling-cabinet-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CeilingCabinetGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'actualCFM',
      'budgetPrice',
      'operatingCost',
      'watts',
      'sphericalSones',
      'bhp',
      'fei',
      'inletSones',
      'fanRPM',
      'weight',
      'amca',
      'operatingStaticPressure',
      'wheelDiameter',
      'outletVelocity',
      'staticEfficiency',
      'feg',
      'totalEfficiency',
      'peakTotalEfficiency',
      'pointsWithinPeakTotalEfficiency',
      'speedNonAdjusted',
      'hasSpeedController',
      'hasBaffle',
      'speedMax',
      'tipSpeed',
      'inletOctaveBand63Hz',
      'inletOctaveBand125Hz',
      'inletOctaveBand250Hz',
      'inletOctaveBand500Hz',
      'inletOctaveBand1kHz',
      'inletOctaveBand2kHz',
      'inletOctaveBand4kHz',
      'inletOctaveBand8kHz',
      'inletLwa'
    );

    this.setVisibleColumns([
      'actualCFM',
      'budgetPrice',
      'operatingCost',
      'watts',
      'sphericalSones',
      'fanRPM',
      'weight',
      'amca',
    ]);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();
  }

  private validSizeToDSRow(size: ValidSize) {
    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.name,
      actualCFM: size.outputs.operatingVolume,
      operatingCost: size.outputs.operatingCost,
      watts: size.outputs.watts,
      sphericalSones: size.outputs.inletSound.sphericalSones,
      budgetPrice: size.pricing.price,
      bhp: size.outputs.operatingPower,
      inletSones: size.outputs.inletSound.sones,
      fanRPM: size.outputs.speed,
      weight: size.pricing.weight,
      driveLossPercentage: size.outputs.driveLossPercentage,
      amca: size.amcaRatings,
      operatingStaticPressure: size.outputs.operatingStaticPressure,
      wheelDiameter: size.outputs.wheelDiameter,
      outletVelocity: size.outputs.outletVelocity,
      staticEfficiency: size.outputs.staticEfficiency,
      feg: size.outputs.feg,
      fei: size.outputs.fei,
      totalEfficiency: size.outputs.totalEfficiency,
      peakTotalEfficiency: size.outputs.peakTotalEfficiency,
      pointsWithinPeakTotalEfficiency:
        size.outputs.pointsWithinPeakTotalEfficiency,
      speedNonAdjusted: size.outputs.speedNonAdjusted,
      hasSpeedController: size.outputs.hasSpeedController,
      hasBaffle: size.outputs.hasBaffle,
      speedMax: size.outputs.speedMax,
      tipSpeed: size.outputs.tipSpeed,
      inletOctaveBand63Hz: size.outputs.inletSound.octaveBand63Hz,
      inletOctaveBand125Hz: size.outputs.inletSound.octaveBand125Hz,
      inletOctaveBand250Hz: size.outputs.inletSound.octaveBand250Hz,
      inletOctaveBand500Hz: size.outputs.inletSound.octaveBand500Hz,
      inletOctaveBand1kHz: size.outputs.inletSound.octaveBand1kHz,
      inletOctaveBand2kHz: size.outputs.inletSound.octaveBand2kHz,
      inletOctaveBand4kHz: size.outputs.inletSound.octaveBand4kHz,
      inletOctaveBand8kHz: size.outputs.inletSound.octaveBand8kHz,
      inletLwa: size.outputs.inletSound.lwa,
      stable: size.stability.stable,
      sizeData: size,
    };

    return rowData;
  }
}
