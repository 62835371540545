import { Component, OnInit } from '@angular/core';
import { releaseNotes } from '../../constants/release-notes.const';
import { SideDialogComponent } from '../../side-dialog/classes/side-dialog-component.interface';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';

@Component({
  selector: 'app-release-notes-dialog',
  templateUrl: './release-notes-dialog.component.html',
  styleUrls: ['./release-notes-dialog.component.scss'],
})
export class ReleaseNotesDialogComponent
  implements OnInit, SideDialogComponent
{
  public dialogData?: any;

  releaseNotes = releaseNotes;

  constructor(private sideDialogService: SideDialogService) {}

  ngOnInit() {}

  close() {
    this.sideDialogService.close();
  }
}
