import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { Project } from '../models/project.model';

@Injectable({
  providedIn: 'root',
})
export class LargeProjectDialog {
  constructor(private core: EcapsCore) {}

  show(project: Project): Promise<string> {
    return new Promise<string>((resolve) => {
      const curSize = Math.ceil(
        (project.size / environment.maxProjectSize) * 100
      );

      if (curSize < environment.maxProjectSizeTolerance) {
        resolve('OK');
      } else {
        this.core
          .showMessageBox(
            {
              title: 'Job Too Large',
              message: `The job &quot;${project.name}&quot; is too large to modify.
                    Please delete additional previous revisions before attempting to save.`,
              icon: 'warning',
              iconClasses: 'warn',
              buttons: [
                {
                  title: 'Edit Job',
                  value: 'EDIT',
                },
              ],
            },
            true
          )
          .then((result: string) => {
            resolve(result);
          });
      }
    });
  }
}
