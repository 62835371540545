import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CookiePolicyService,
  ICookieOption,
} from '../../services/cookie-policy.service';

@Component({
  selector: 'cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CookiePolicyComponent implements OnInit {
  @ViewChild('customizeDialog')
  customizeDialog?: TemplateRef<any>;

  @ViewChild('confirmRequiredDialog')
  confirmRequiredDialog?: TemplateRef<any>;

  cookieOptions!: ICookieOption[];

  formGroup: UntypedFormGroup;

  private customDialogRef: MatDialogRef<any>;

  constructor(
    private element: ElementRef,
    private dialog: MatDialog,
    private cookiePolicy: CookiePolicyService
  ) {
    this.cookieOptions = this.cookiePolicy.optionList;

    this.hide();

    if (!this.cookiePolicy.optionsSaved) {
      setTimeout(() => {
        this.show();
      }, 2000);
    } else {
      (this.element.nativeElement as HTMLElement).style.display = 'none';
    }

    this.formGroup = new UntypedFormGroup({});

    this.cookiePolicy.optionList.forEach((option) => {
      const control: UntypedFormControl = new UntypedFormControl(option.value);

      this.formGroup.addControl(option.id, control);
    });
  }

  ngOnInit(): void {}

  show(): void {
    const popup = this.element.nativeElement as HTMLElement;

    popup.classList.remove('hide');

    popup.classList.add('show');
  }

  hide(): void {
    const popup = this.element.nativeElement as HTMLElement;

    popup.classList.remove('show');

    popup.classList.add('hide');
  }

  acceptAll(): void {
    this.cookiePolicy.optionList.forEach((option) =>
      this.cookiePolicy.setOption(option.id, true)
    );

    this.cookiePolicy.saveOptions();

    this.close();
  }

  customize(): void {
    if (!!this.customizeDialog) {
      this.customDialogRef = this.dialog.open(this.customizeDialog, {
        autoFocus: true,
        disableClose: false,
        panelClass: 'cookie-policy-dialog',
      });
    }
  }

  toggleClick(event: MouseEvent, option: ICookieOption): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    const toggle = this.formGroup.get(option.id) as UntypedFormControl;

    if (option.required) {
      toggle.setValue(true);

      this.showConfirmRequired().then((accept: boolean) => {
        if (!accept) {
          location.href = 'https://www.greenheck.com/';
        }
      });
    }
  }

  confirmCustom(): void {
    this.customDialogRef?.close();

    this.cookiePolicy.optionList.forEach((option) => {
      option.value = (
        this.formGroup.get(option.id) as UntypedFormControl
      ).value;
    });

    this.cookiePolicy.saveOptions();

    this.close();
  }

  private close(): void {
    this.hide();

    setTimeout(() => {
      (this.element.nativeElement as HTMLElement).style.display = 'none';
    }, 3000);
  }

  private showConfirmRequired(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.dialog.open(this.confirmRequiredDialog!, {
        autoFocus: true,
        disableClose: true,
      });

      const subscription = dialogRef.afterClosed().subscribe({
        next: (value: boolean) => resolve(value),
        complete: () => subscription.unsubscribe(),
      });
    });
  }
}
