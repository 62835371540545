import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { ErrorReportingDialog } from '../../controllers/error-reporting-dialog.controller';
import { LoadingGraphic } from '../../controllers/loading-graphic.controller';
import { ErrorMessageData } from './models/error-message-data.model';

@Component({
  selector: 'error-message-dialog',
  templateUrl: './error-message-dialog.component.html',
  styleUrls: ['./error-message-dialog.component.scss'],
})
export class ErrorMessageDialogComponent implements OnInit {
  isProduction = environment.production;

  errorMessage = 'No Error Data';
  errorStack = '';

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ErrorMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ErrorMessageData,
    private loadingGraphic: LoadingGraphic,
    private sideDialog: SideDialogService,
    private errorReportingDialog: ErrorReportingDialog
  ) {
    this.loadingGraphic.hide(true);

    this.sideDialog.close();

    this.dialog.closeAll();

    if (!!data.errorData) {
      if (!!data.errorData.error && !!data.errorData.error.message) {
        this.errorMessage = data.errorData.error.message;
        this.errorStack = data.errorData.error.stack;
      } else if (
        !!data.errorData.rejection &&
        !!data.errorData.rejection.error &&
        !!data.errorData.rejection.error.message
      ) {
        this.errorMessage = data.errorData.rejection.error.message;
        this.errorStack = data.errorData.rejection.error.stack;
      } else {
        this.errorMessage = data.errorData.message;
        this.errorStack = data.errorData.stack;
      }
    }

    console.error(data.errorData);
  }

  ngOnInit() {}

  okClick() {
    this.dialogRef.close();
  }

  reportClick() {
    this.errorReportingDialog.show(this.data.errorID, this.data.errorData);

    this.dialogRef.close();
  }
}
