import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectsService } from '../../projects/services/projects.service';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { Item } from '../../projects/models/project-item.model';
import { SideBarService } from '../../side-bar/services/side-bar.service';
import { MenuItemList } from '../../side-bar/services/constants/menu-item-list.const';
import { DecimalPipe } from '@angular/common';
import { Project } from '../../projects/models/project.model';
import { ProjectUpdateTypes } from '../../projects/enums/project-update-types.enum';
import { ActivatedRoute } from '@angular/router';
import { IProjectEvent } from '../../projects/models/i-project-event.interface';
import { ProductTypes } from '../../products/enums/product-types.enum';
import { ProjectItemStatus } from '../../projects/models/project-item-status.enum';

@Component({
  selector: 'app-equipment-schedule-page',
  templateUrl: './equipment-schedule-page.component.html',
  styleUrls: ['./equipment-schedule-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EquipmentSchedulePageComponent implements OnInit, OnDestroy {
  localProjectName = 'Untitled Job';

  private _localProjectUpdated: Subscription;
  private _projectPropertyUpdated: Subscription;
  private _localProject: Project;

  selectedTab = 0;

  constructor(
    private sidebarService: SideBarService,
    private menuItemList: MenuItemList,
    private projectsService: ProjectsService,
    private core: EcapsCore,
    private activatedRoute: ActivatedRoute
  ) {
    this._localProjectUpdated = projectsService.localProjectUpdated.subscribe(
      (project) => {
        this.projectUpdated(project);
      }
    );

    projectsService.getLocalProject().then((project) => {
      this.projectUpdated(project);
    });
  }

  ngOnInit() {
    this.sidebarService.setSidebarItems([
      {
        menuItems: [this.menuItemList.jobs],
      },
      {
        menuItems: [this.menuItemList.save, this.menuItemList.saveAs],
      },
      {
        menuItems: [
          this.menuItemList.downloadDocuments,
          this.menuItemList.emailDocuments,
        ],
      },
      {
        menuItems: [this.menuItemList.share],
      },
    ]);
  }

  ngOnDestroy() {
    this._localProjectUpdated.unsubscribe();

    this._projectPropertyUpdated.unsubscribe();
  }

  private projectUpdated(project: Project) {
    this._localProject = project;

    if (!!this._projectPropertyUpdated) {
      this._projectPropertyUpdated.unsubscribe();
    }

    this.localProjectName = !!project.name
      ? project.name
      : this.localProjectName;

    this._projectPropertyUpdated = project.updated.subscribe(
      (event: IProjectEvent) => {
        if (event.type === ProjectUpdateTypes.propertiesUpdated) {
          this.localProjectName = project.name;
        }
      }
    );

    const productType = this.activatedRoute.snapshot.params.productType;

    const tabs = [
      'fan',
      'gravity',
      'louver',
      'temperedairproduct',
      'circulator',
      'makeupair',
      'preconditioner',
    ];

    if (!!productType && productType.trim() !== '') {
      this.selectedTab = tabs.findIndex(
        (tabName) => tabName === productType.toLowerCase().trim()
      );
    } else if (project.getActiveRevision().items.length > 0) {
      const lastItem =
        project.getActiveRevision().items[
          project.getActiveRevision().items.length - 1
        ];

      this.selectedTab = tabs.findIndex(
        (tabName) => tabName === lastItem.type.toLowerCase().trim()
      );
    } else {
      this.selectedTab = 0;
    }
  }

  deleteGridItems() {
    const itemList = new Array<Item>();

    if (this.projectsService.getSelectedProjectItems().length > 0) {
      this.projectsService.getSelectedProjectItems().forEach((item) => {
        itemList.push(item);
      });

      this.core
        .showMessageBox({
          title: 'Delete Item(s)',
          message: `Are you sure you want to delete these ${new DecimalPipe(
            'en_us'
          ).transform(itemList.length)} item(s)?`,
          icon: 'help_outline',
          buttons: [
            {
              title: 'Yes',
              value: true,
            },
            {
              title: 'No',
              value: false,
            },
          ],
        })
        .then((success) => {
          if (success) {
            let deleting = true;

            this.core.showLoadingGraphic('Deleting Items...', () => deleting);

            const self = this;

            const deleteNext = function () {
              if (itemList.length === 0) {
                deleting = false;

                self.core.hideLoadingGraphic();
              } else {
                const item = itemList.pop();

                self.projectsService.deleteRevisionItem(item).then(() => {
                  deleteNext();
                });
              }
            };

            deleteNext();
          }
        });
    } else {
      this.core.showMessageBox({
        title: 'Delete Item(s)',
        message: 'You must select at least one item to delete first.',
        icon: 'error_outline',
        iconClasses: 'warn',
      });
    }
  }

  showGroupWarning(productType: ProductTypes): boolean {
    if (!this._localProject) {
      return false;
    } else {
      return (
        this._localProject
          .getActiveRevision()
          .items.filter(
            (item) =>
              item.type.toUpperCase().trim() ===
                productType.toUpperCase().trim() &&
              item.status === ProjectItemStatus.invalid
          ).length > 0
      );
    }
  }
}
