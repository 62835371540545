import { Item } from './project-summary-item.model';

export class Revision {
  active: boolean;
  comment: string;
  creationDate: Date;
  id: string;
  items: Array<Item>;
  name: string;
  constructor(data: any) {
    this.active = data.active;
    this.comment = data.comment;
    this.creationDate = new Date(data.creationDate);
    this.id = data.id;
    this.items = new Array<Item>();
    if (!!data.items) {
      data.items.every(item => {
        this.items.push(new Item(item));
        return true;
      });
    }
    this.name = data.name;
  }
  getQuantity(): number {
    let quantity = 0;
    this.items.every(item => {
      quantity += item.quantity;
      return true;
    });
    return quantity;
  }
}
