<side-dialog-title>Fan Curves</side-dialog-title>

<side-dialog-content>
  <div class="chartParent">
    <ventabula-valid-size-chart [size]="dialogData.size" [default-type]="dialogData.defaultType"></ventabula-valid-size-chart>
  </div>
</side-dialog-content>

<side-dialog-actions>
    <button mat-raised-button color="primary" (click)="closeClick()">Close</button>
</side-dialog-actions>