<svg
  id="svg8"
  xmlns="http://www.w3.org/2000/svg"
  width="819.05"
  height="261.69"
  viewBox="0 0 819.05 261.69"
>
  <g id="layer1">
    <g id="g1023">
      <g id="g1016">
        <ellipse
          id="path815"
          cx="157.23"
          cy="149.93"
          rx="105.6"
          ry="110.76"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            stroke-dasharray: 6, 6;
          "
        />
        <g id="g1085">
          <path
            id="path838"
            d="M119.32,127l74.25,46.47"
            transform="translate(0 0)"
            style="fill: none; stroke: #000; stroke-width: 3px"
          />
          <path
            id="path838-1"
            d="M119.57,173.47l73.74-47"
            transform="translate(0 0)"
            style="fill: none; stroke: #000; stroke-width: 3px"
          />
          <path
            id="path838-2"
            d="M156.48,102.79l.51,93.44"
            transform="translate(0 0)"
            style="fill: none; stroke: #000; stroke-width: 3px"
          />
        </g>
      </g>
      <g id="text912">
        <text
          transform="translate(40.43 15.44)"
          style="
            isolation: isolate;
            font-size: 16px;
            font-family: Arial-BoldMT, Arial;
            font-weight: 700;
          "
        >
          {{ getDSCoverageAreaTitle() }}
        </text>
      </g>
      <g id="g1095">
        <g id="text934-8">
          <text
            transform="translate(200.59 204.67)"
            style="
              isolation: isolate;
              font-size: 16px;
              font-family: ArialMT, Arial;
            "
          >
            {{ getDSCoverageRadius() | number: &apos;1.0-0&apos; }} ft.
          </text>
        </g>
        <path
          id="path972-2"
          d="M213.85,243.21l-57.57-93.28"
          transform="translate(0 0)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
          "
        />
      </g>
      <g id="g1090">
        <g id="text934">
          <text
            transform="translate(0 50.44)"
            style="
              isolation: isolate;
              font-size: 16px;
              font-family: ArialMT, Arial;
            "
          >
            {{ getDSFanCoverageArea() | number: &apos;1.0-0&apos; }} sq. ft.
          </text>
        </g>
        <path
          id="path972"
          d="M90.86,102.58,58,63.3"
          transform="translate(0 0)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
          "
        />
      </g>
    </g>
  </g>
  <g id="Key">
    <g id="Rings">
      <circle
        id="Outer"
        cx="650.96"
        cy="137.75"
        r="102.5"
        style="fill: #46a4e8; stroke: #000; stroke-miterlimit: 10"
      />
      <circle
        id="Mid_2"
        data-name="Mid 2"
        cx="651.42"
        cy="138.2"
        r="79.72"
        style="fill: #0087e8; stroke: #000; stroke-miterlimit: 10"
      />
      <circle
        id="Mid_1"
        data-name="Mid 1"
        cx="651.42"
        cy="138.2"
        r="56.94"
        style="fill: #005b9c; stroke: #000; stroke-miterlimit: 10"
      />
      <circle
        id="Center"
        cx="651.42"
        cy="138.2"
        r="34.17"
        style="fill: #003d69; stroke: #000; stroke-miterlimit: 10"
      />
    </g>
    <g id="Fan">
      <line
        x1="651.03"
        y1="140.25"
        x2="665.15"
        y2="148.4"
        style="
          fill: none;
          stroke: #757575;
          stroke-miterlimit: 10;
          stroke-width: 3px;
        "
      />
      <line
        x1="652.65"
        y1="140.37"
        x2="638.54"
        y2="148.52"
        style="
          fill: none;
          stroke: #757575;
          stroke-miterlimit: 10;
          stroke-width: 3px;
        "
      />
      <line
        x1="652.18"
        y1="140.54"
        x2="652.18"
        y2="124.24"
        style="
          fill: none;
          stroke: #757575;
          stroke-miterlimit: 10;
          stroke-width: 3px;
        "
      />
    </g>
    <g>
      <g>
        <rect
          x="435.96"
          y="76.56"
          width="18"
          height="18"
          style="fill: #003d69; stroke: #000; stroke-miterlimit: 10"
        />
        <text
          transform="translate(459.73 89.83)"
          style="
            isolation: isolate;
            font-size: 16px;
            font-family: ArialMT, Arial;
          "
        >
          375+ FPM
        </text>
      </g>
      <g>
        <rect
          x="435.96"
          y="102.93"
          width="18"
          height="18"
          style="fill: #005b9c; stroke: #000; stroke-miterlimit: 10"
        />
        <text
          transform="translate(459.73 116.2)"
          style="
            isolation: isolate;
            font-size: 16px;
            font-family: ArialMT, Arial;
          "
        >
          230+ FPM
        </text>
      </g>
      <g>
        <rect
          x="435.96"
          y="128.43"
          width="18"
          height="18"
          style="fill: #0087e8; stroke: #000; stroke-miterlimit: 10"
        />
        <text
          transform="translate(459.73 141.7)"
          style="
            isolation: isolate;
            font-size: 16px;
            font-family: ArialMT, Arial;
          "
        >
          105+ FPM
        </text>
      </g>
      <g>
        <rect
          x="435.96"
          y="154.43"
          width="18"
          height="18"
          style="fill: #46a4e8; stroke: #000; stroke-miterlimit: 10"
        />
        <text
          transform="translate(459.73 167.7)"
          style="
            isolation: isolate;
            font-size: 16px;
            font-family: ArialMT, Arial;
          "
        >
          &lt;105 FPM
        </text>
      </g>
    </g>
    <g>
      <text
        transform="translate(783.47 138.52)"
        style="isolation: isolate; font-size: 16px; font-family: ArialMT, Arial"
      >
        {{ size.outputs.distanceFor230AirSpeed }} ft.
      </text>
      <line
        x1="708.07"
        y1="134.25"
        x2="779.96"
        y2="134.25"
        style="fill: none; stroke: #000; stroke-miterlimit: 10"
      />
    </g>
    <g>
      <line
        x1="671.07"
        y1="110.25"
        x2="779.96"
        y2="110.25"
        style="fill: none; stroke: #000; stroke-miterlimit: 10"
      />
      <text
        transform="translate(783.47 114.52)"
        style="isolation: isolate; font-size: 16px; font-family: ArialMT, Arial"
      >
        {{ size.outputs.distanceFor375AirSpeed }} ft.
      </text>
    </g>
    <g>
      <text
        transform="translate(783.47 162.52)"
        style="isolation: isolate; font-size: 16px; font-family: ArialMT, Arial"
      >
        {{ size.outputs.distanceFor105AirSpeed }} ft.
      </text>
      <line
        x1="728.6"
        y1="158.25"
        x2="779.96"
        y2="158.25"
        style="fill: none; stroke: #000; stroke-miterlimit: 10"
      />
    </g>
    <g>
      <text
        transform="translate(783.47 186.52)"
        style="isolation: isolate; font-size: 16px; font-family: ArialMT, Arial"
      >
        {{ size.outputs.distanceForLessThan105AirSpeed }} ft.
      </text>
      <line
        x1="743.64"
        y1="181.57"
        x2="779.96"
        y2="181.57"
        style="fill: none; stroke: #000; stroke-miterlimit: 10"
      />
    </g>
  </g>
  <g id="Label">
    <text
      transform="translate(571.59 15.44)"
      style="
        isolation: isolate;
        font-size: 18px;
        font-family: Arial-BoldMT, Arial;
        font-weight: 700;
      "
    >
      Air Speed Zones
    </text>
  </g>
</svg>
