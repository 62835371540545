import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import {
  EcapsStateService,
  methods,
} from '../../ecaps-core/handlers/services/ecaps-state.service';
import { CamelCasePipe } from '../../ecaps-core/pipes/custom-pipes.pipe';
import { RevitCommsService } from '../../external-communication/services/revit-comms.service';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../google/services/google-analytics.service';
import { ProductTypes } from '../../products/enums/product-types.enum';
import { EquipmentScheduleGridDefinition } from '../../projects/models/equipment-schedule-grid-definition.model';
import { Project } from '../../projects/models/project.model';
import { ProjectsService } from '../../projects/services/projects.service';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { UsersService } from '../../users/services/users.service';
import { DocumentTypes } from '../enums/document-types.enum';
import { DrawingTypes } from '../enums/drawing-types.enum';
import { GridField } from '../models/grid-field.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private http: HttpClient,
    private projectsService: ProjectsService,
    private usersService: UsersService,
    private gaServices: GoogleAnalyticsService,
    private ecapsState: EcapsStateService,
    private revitComms: RevitCommsService,
    private core: EcapsCore
  ) {}

  getSizeCutSheetPDF(size: ValidSize): Promise<boolean> {
    return new Promise<boolean>((result, reject) => {
      this.gaServices.trackEvent_Old(
        EventCategories.documentDownload,
        'Local',
        'Selection Size Cut Sheet'
      );

      const camelCase = new CamelCasePipe();

      const url = `${environment.serviceRoot}rendering/submittal`;
      const data = {
        project: {
          name: `${size.name} Cut Sheet`,
        },
        revision: {
          active: true,
          name: 'Rev. 1',
        },
        item: {
          name: 'CutSheetItem',
          tag: 'MK-1',
          productType: size.productType,
          quantity: 1,
          location: '',
          areaServed: '',
          details: [
            {
              name: `${camelCase.transform(size.productType)}SelectionKey`,
              value: size.selectionKey,
            },
            {
              name: `${camelCase.transform(
                size.productType
              )}SelectorLayoutEntityKey`,
              value: size.selectionLayoutConfig.key,
            },
            {
              name: `${camelCase.transform(size.productType)}SelectionSizeId`,
              value: size.id,
            },
            {
              name: 'productLayoutEntityKey',
              value: size.layoutConfig.key,
            },
          ],
        },
      };

      const stateKey = this.ecapsState.addPendingAsyncCall(
        url,
        methods.post,
        data
      );

      const subscription = this.http
        .post(url, data, {
          headers: new HttpHeaders({
            'api-key': environment.defaultApiKey,
          }),
          responseType: 'blob',
        })
        .subscribe({
          next: (resultData) => {
            this.downloadBlob(resultData, `${size.name}.pdf`);

            result(true);
          },
          error: (errorData) => {
            reject(errorData);
          },
          complete: () => {
            this.ecapsState.removePendingAsyncCall(stateKey);

            subscription.unsubscribe();
          },
        });
    });
  }

  getProjectDocuments(
    project: Project,
    documentTypes: DocumentTypes[],
    itemIds?: string[],
    gridFields?: GridField[],
    returnBlob = false
  ): Promise<{ data: Blob; filename: string }> {
    return new Promise<{ data: Blob; filename: string }>(
      async (result, reject) => {
        let activeRevision = project.getActiveRevision();

        if (activeRevision.size === 0 && activeRevision.items.length > 0) {
          project.isDirty = true;

          this.projectsService.saveProject(project).then(() => {
            activeRevision.size = 10;

            this.getProjectDocuments(
              project,
              documentTypes,
              itemIds,
              gridFields,
              returnBlob
            ).then(result, reject);
          }, reject);

          return;
        }

        if (
          !itemIds &&
          this.projectsService.getSelectedProjectItems().length > 0
        ) {
          itemIds = this.projectsService
            .getSelectedProjectItems()
            .map((item) => item.id);
        }

        const sortedList = this.projectsService.getSortedProjectItems();

        if (!itemIds && sortedList.length > 0) {
          itemIds = sortedList;
        } else if (sortedList.length > 0) {
          itemIds.sort(
            (a, b) =>
              sortedList.findIndex((item) => item === a) -
              sortedList.findIndex((item) => item === b)
          );
        }

        if (documentTypes.indexOf(DocumentTypes.cutSheet) > -1) {
          let itemList = this.projectsService.getSelectedProjectItems();

          if (!itemList || itemList.length === 0) {
            const localProject = await this.projectsService.getLocalProject();

            itemList = localProject.getActiveRevision().items;
          }

          itemList
            .filter((item) => itemIds.indexOf(item.id) > -1)
            .forEach((item) => {
              this.gaServices.trackEvent_Old(
                this.revitComms.isRevitApp
                  ? EventCategories.revitAddin
                  : EventCategories.eCAPS,
                'Cut-Sheet',
                !!item.sizeData && !!item.sizeData.selectionLayoutConfig
                  ? item.sizeData.selectionLayoutConfig.modelGroup
                  : 'Unknown',
                !!item.sizeData ? item.sizeData.model : 'Unknown'
              );
            });
        }

        if (!gridFields) {
          const gridDefs =
            this.projectsService.getEquipmentScheduleGridDefinitions();

          gridFields = new Array<GridField>();

          gridDefs
            .filter(
              (gridItem: EquipmentScheduleGridDefinition) =>
                gridItem.productType !== ProductTypes.temperedAirProduct &&
                gridItem.productType !== ProductTypes.makeUpAir &&
                gridItem.productType !== ProductTypes.preconditioners
            )
            .forEach((gridItem: EquipmentScheduleGridDefinition) => {
              gridItem.visibleColumns.forEach((key) => {
                gridFields.push({
                  productType: gridItem.productType,

                  fieldName: key,
                });
              });
            });
        }

        this.usersService.getCurrentUser().then((userData) => {
          const url = `${environment.serviceRoot}project/${
            project.key
          }/revisions/${project.getActiveRevision().id}/documents`;
          const data = {
            documentTypes: documentTypes,
            itemIds: itemIds,
            gridFields: gridFields,
            user: userData.email,
          };

          const stateKey = this.ecapsState.addPendingAsyncCall(
            url,
            methods.post,
            data
          );

          const subscription = this.http
            .post(url, data, {
              headers: new HttpHeaders({
                'api-key': environment.defaultApiKey,
              }),
              responseType: 'blob',
              observe: 'response',
            })
            .subscribe({
              next: (resp) => {
                this.gaServices.trackEvent_Old(
                  EventCategories.documentDownload,
                  'Local',
                  documentTypes.join(', ')
                );

                const contentDisposition = resp.headers.get(
                  'content-disposition'
                );

                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
                  contentDisposition
                );

                let fileName;

                if (!!matches && matches.length > 0) {
                  fileName = matches[1].replace(/['"]/g, '');
                }

                if (resp.body.size > 0) {
                  if (returnBlob) {
                    result({ data: resp.body, filename: fileName });
                  } else {
                    this.downloadBlob(resp.body, fileName);

                    result(null);
                  }
                } else {
                  this.core.showMessageBox({
                    title: 'Content Unavailable',
                    message:
                      'The content you have requested is unavailable. We apologize for the inconvenience.',
                    icon: 'warning',
                    iconClasses: 'warn',
                  });

                  result(null);
                }
              },
              error: (errorData) => {
                reject(errorData);
              },
              complete: () => {
                this.ecapsState.removePendingAsyncCall(stateKey);

                subscription.unsubscribe();
              },
            });
        });
      }
    );
  }

  getRevitLODFile(
    size: ValidSize,
    tag?: string,
    returnBlob: boolean = false
  ): Promise<any> {
    return new Promise<any>((result, reject) => {
      if (!!size && !!size.files) {
        const fileInfo = size.files.find(
          (file) => file.type === DrawingTypes.revitLOD300
        );

        if (!!fileInfo) {
          this.gaServices.trackEvent_Old(
            EventCategories.documentDownload,
            'Local',
            'Selection Size Revit LOD 300'
          );

          const camelCase = new CamelCasePipe();

          const url = `${environment.serviceRoot}revit/lod/300`;
          const data = {
            project: {
              name: `${size.name} LOD 300`,
            },
            revision: {
              active: true,
              name: 'Rev. 1',
            },
            item: {
              name: 'RevitLOD300Item',
              tag: tag || 'MK-1',
              productType: size.productType,
              quantity: 1,
              location: '',
              areaServed: '',
              details: [
                {
                  name: `${camelCase.transform(size.productType)}SelectionKey`,
                  value: size.selectionKey,
                },
                {
                  name: `${camelCase.transform(
                    size.productType
                  )}SelectorLayoutEntityKey`,
                  value: size.selectionLayoutConfig.key,
                },
                {
                  name: `${camelCase.transform(
                    size.productType
                  )}SelectionSizeId`,
                  value: size.id,
                },
                {
                  name: 'productLayoutEntityKey',
                  value: size.layoutConfig.key,
                },
              ],
            },
          };

          const stateKey = this.ecapsState.addPendingAsyncCall(
            url,
            methods.post,
            data
          );

          const subscription = this.http
            .post(url, data, {
              headers: new HttpHeaders({
                'api-key': environment.defaultApiKey,
              }),
              responseType: 'blob',
            })
            .subscribe({
              next: (resultData) => {
                if (returnBlob) {
                  result(resultData);
                } else {
                  this.downloadBlob(resultData, fileInfo.name);

                  result(true);
                }
              },
              error: (errorData) => {
                reject(errorData);
              },
              complete: () => {
                subscription.unsubscribe();

                this.ecapsState.removePendingAsyncCall(stateKey);
              },
            });
        } else {
          result(null);
        }
      } else {
        result(null);
      }
    });
  }

  downloadBlob(data: Blob, fileName: string) {
    if (this.revitComms.isRevitApp) {
      this.revitComms.downloadBlob(data, fileName);
    } else {
      if (
        (navigator.userAgent.toLowerCase().indexOf('trident') > -1 ||
          navigator.userAgent.toLowerCase().indexOf('edge') > -1) &&
        !!(navigator as any).msSaveOrOpenBlob
      ) {
        (navigator as any).msSaveOrOpenBlob(data, fileName);
      } else {
        const anchor = document.createElement('a');

        (<HTMLElement>anchor).style.display = 'none';
        anchor.download = fileName;
        anchor.href = window.URL.createObjectURL(data);

        document.body.appendChild(anchor);

        anchor.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(anchor.href);
          anchor.remove();
        }, 1000);
      }
    }
  }
}
