<side-dialog-title>
  <div class="title">
    <span
      >{{ !!publicToken ? 'Shared Job ' : '' }}{{ projectSummary.name }} Version
      Details</span
    >

    <ng-container [ngSwitch]="isEditing" *ngIf="!publicToken">
      <button
        *ngSwitchCase="false"
        mat-icon-button
        (click)="editJobClick()"
        matTooltip="Edit Job"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        *ngSwitchCase="true"
        mat-icon-button
        (click)="saveJobClick()"
        matTooltip="Save Changes"
      >
        <mat-icon>save</mat-icon>
      </button>
    </ng-container>
  </div>
</side-dialog-title>

<side-dialog-content>
  <div class="job-properties-container" [class.editing]="isEditing">
    <mat-card appearance="outlined" *ngIf="isEditing">
      <mat-card-content>
        <form [formGroup]="formGroup">
          <mat-form-field>
            <mat-label>Job Name</mat-label>

            <input
              matInput
              type="text"
              ghSpecialChars
              formControlName="jobName"
              autofocus
            />

            <mat-error *ngIf="!!jobName.errors">{{
              jobName | fieldErrorToText
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Location</mat-label>

            <input
              matInput
              type="text"
              formControlName="location"
              (blur)="validateAddress()"
            />
          </mat-form-field>
        </form>
      </mat-card-content>
    </mat-card>

    <mat-card appearance="outlined" *ngIf="isEditing">
      <mat-card-header>
        <mat-card-title>{{ progValue }}% of Max Job Size</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-progress-bar
          [color]="progColor"
          mode="determinate"
          [value]="progValue"
        ></mat-progress-bar>
      </mat-card-content>
    </mat-card>

    <mat-toolbar class="share-info" *ngIf="!!publicToken">
      <span><span class="title">Owner:</span>{{ projectSummary.owner }}</span>
    </mat-toolbar>
    <div class="verison-history">
      <project-version-history
        [project-summary]="projectSummary"
        [read-only]="!!publicToken"
        [active-only]="!!publicToken"
        [show-delete]="isEditing"
        (selectionChanged)="selectedRevisionChanged($event)"
        (revisionDeleted)="revisionDeleted($event)"
      ></project-version-history>
    </div>
  </div>
</side-dialog-content>

<side-dialog-actions>
  <ng-container [ngSwitch]="isEditing" *ngIf="!publicToken">
    <ng-container *ngSwitchCase="false">
      <button mat-raised-button color="primary" (click)="editJobClick()">
        Edit Job
      </button>

      <button mat-raised-button color="primary" (click)="loadClick()">
        Load
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="true">
      <button mat-raised-button color="primary" (click)="saveJobClick()">
        Save
      </button>

      <button mat-raised-button color="primary" (click)="saveLoadClick()">
        Save &amp; Load
      </button>
    </ng-container>
  </ng-container>

  <button
    *ngIf="publicToken"
    mat-raised-button
    color="primary"
    (click)="loadClick()"
  >
    Load
  </button>

  <button mat-raised-button color="primary" (click)="closeClick()">
    Close
  </button>
</side-dialog-actions>
