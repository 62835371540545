import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  IRep,
  IRepInfo,
} from '../../ecaps-core/dialogs/rep-locator-dialog/models/i-rep.interface';

interface IOfficeInfoResponse {
  Id: string;
  LastModified: string;
  PublicationDate: string;
  DateCreated: string;
  IncludeInSitemap: boolean;
  SystemSourceKey?: string;
  UrlName?: string;
  ItemDefaultUrl?: string;
  QuotePhone?: string;
  Website?: string;
  StockingRepInfo?: string;
  Phone?: string;
  repregions: string[];
  OfficeName?: string;
  Email?: string;
  Active: boolean;
  Assignment?: string;
  Phone1?: string;
  City?: string;
  OfficeNum?: string;
  DisplayOrder?: number;
  QuoteEmail?: string;
  RecordID?: string;
  Provider?: string;
}

interface IRepOfficeResponse {
  value: IOfficeInfoResponse[];
}

@Injectable({
  providedIn: 'root',
})
export class RepOfficeInfosService {
  private _repInfoList: IRepInfo[];
  public get repInfoList(): IRepInfo[] {
    return this._repInfoList;
  }

  constructor(private http: HttpClient) {}

  loadRepOfficeData(): Promise<IRepInfo[]> {
    return new Promise<IRepInfo[]>((resolve, reject) => {
      if (this._repInfoList?.length) {
        resolve(this._repInfoList);

        return;
      }

      firstValueFrom(
        this.http.get<IRepOfficeResponse>(
          `${
            environment.greenheckUrls.repOfficeInfos
          }?_=${new Date().getTime()}`
        )
      ).then((res) => {
        const repInfoList = res.value.map((rep) => {
          const record = rep.RecordID.split('_');

          const repInfo = {
            id: rep.Id,
            website: rep.Website,
            stockingRepInfo: rep.StockingRepInfo,
            phone: rep.Phone,
            phone1: rep.Phone1,
            repRegions: rep.repregions,
            officeName: rep.OfficeName,
            email: rep.Email,
            active: rep.Active,
            assignment: rep.Assignment,
            city: rep.City,
            officeNum: rep.OfficeNum,
            displayOrder: rep.DisplayOrder,
            recordID: rep.RecordID,
          } as IRep;

          return {
            region: record[1],
            assignment: record[2],
            repInfo,
          } as IRepInfo;
        });

        this._repInfoList = repInfoList;

        resolve(repInfoList);
      });
    });
  }

  loadRepOfficeByZipCode(zipCode: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firstValueFrom(
        this.http.get<any>(
          `${
            environment.greenheckUrls.repOfficeInfosByZipCode
          }/${zipCode}/greenheck?_=${new Date().getTime()}`
        )
      ).then((res) => {
        resolve(res[0].officeNum);
      }, reject);
    });
  }
}
