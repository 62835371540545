import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'side-dialog-title',
  templateUrl: './side-dialog-title.component.html',
  styleUrls: ['./side-dialog-title.component.scss'],
})
export class SideDialogTitleComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
