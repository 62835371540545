<h2 mat-dialog-title>Caution</h2>

<mat-dialog-content>
  <mat-icon class="dp32">warning</mat-icon>
  <span *ngIf="size.stability.stabilityMessages.length === 1">{{ size.stability.stabilityMessages[0] }}</span>
  <ul *ngIf="size.stability.stabilityMessages.length > 1">
    <li *ngFor="let message of size.stability.stabilityMessages">{{ message }}</li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" *ngIf="showFanCurvesButton()" (click)="showFanCurves()">View Fan Curves</button>
    <button mat-raised-button color="primary" (click)="okClick()">OK</button>
</mat-dialog-actions>