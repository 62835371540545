<h2 mat-dialog-title>Find a Greenheck Rep</h2>

<mat-dialog-content>
  <div class="content">
    <mat-form-field>
      <mat-label>Select a worldwide location</mat-label>

      <mat-select [(ngModel)]="selectedRegion">
        <mat-option
          *ngFor="let repRegion of regionGroups"
          [value]="repRegion"
          >{{
            repRegion.name === 'USA'
              ? repRegion.name
              : (repRegion.name | titlecase)
          }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="content">
    <mat-form-field>
      <mat-label>Select a country/state/province</mat-label>

      <mat-select [(ngModel)]="selectedState">
        <mat-option
          *ngFor="let state of selectedRegion?.assignments"
          [value]="state"
          >{{ state.name | titlecase }}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div
      style="margin-left: 20px; display: inline-block"
      *ngIf="selectedRegion?.name === 'USA'"
    >
      <span>OR</span>
      <mat-form-field style="margin-left: 20px">
        <mat-label>Enter postal code</mat-label>

        <input
          matInput
          [(ngModel)]="postalcode"
          (keyup)="repListByPostalCode()"
          autocomplete="off"
        />
        <mat-icon matSuffix (click)="repListByPostalCode()">search</mat-icon>
      </mat-form-field>
    </div>
  </div>
  <div class="rep-list">
    <ng-container *ngFor="let rep of repListFiltered">
      <div class="rep-item" matRipple (click)="selectRep(rep)">
        <div class="assignment" *ngIf="rep.assignment?.trim() !== ''">
          <span>{{ rep.assignment }}, </span>
          <span>{{ rep.city }}</span>
        </div>

        <div class="name">{{ rep.officeName }}</div>
        <div class="email">{{ rep.email }}</div>
        <div class="phone">Phone: {{ rep.phone }}</div>
        <div class="fax" *ngIf="rep.phone1?.trim() !== ''">
          Phone 2: {{ rep.phone1 }}
        </div>
        <div class="comments" *ngIf="rep.stockingRepInfo?.trim() !== ''">
          {{ rep.stockingRepInfo }}
        </div>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" matDialogClose>Cancel</button>
</mat-dialog-actions>
