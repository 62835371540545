import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'side-dialog-content',
  templateUrl: './side-dialog-content.component.html',
  styleUrls: ['./side-dialog-content.component.scss'],
})
export class SideDialogContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
