import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LachesisService } from '../../../analytics/services/lachesis.service';
import { DocumentSelector } from '../../../documents/controllers/document-selector.controller';
import { DocumentTypes } from '../../../documents/enums/document-types.enum';
import { GridField } from '../../../documents/models/grid-field.model';
import { DocumentsService } from '../../../documents/services/documents.service';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import { EmailDialog } from '../../../ecaps-core/controllers/email-dialog.controller';
import { LoadingGraphic } from '../../../ecaps-core/controllers/loading-graphic.controller';
import { EmailService } from '../../../ecaps-core/services/email.service';
import { ShareProjectRequest } from '../../../ecaps-core/services/models/share-project-request.model';
import { LaunchTypes } from '../../../external-communication/models/launch-types.enum';
import { ExternalCommunicationService } from '../../../external-communication/services/external-communication.service';
import {
  ActionTypes,
  ContentTypes,
  DeliveryMethodTypes,
  GoogleAnalyticsService,
  IEventItemModelsPayload,
  ViewTypes,
} from '../../../google/services/google-analytics.service';
import { ProductTypes } from '../../../products/enums/product-types.enum';
import { CreateNewProjectDialog } from '../../../projects/controllers/create-new-project-dialog.controller';
import { Projects } from '../../../projects/controllers/projects.controller';
import { EquipmentScheduleGridDefinition } from '../../../projects/models/equipment-schedule-grid-definition.model';
import { Item } from '../../../projects/models/project-item.model';
import { ProjectsService } from '../../../projects/services/projects.service';
import { Users } from '../../../users/controllers/users.controller';
import { UsersService } from '../../../users/services/users.service';
import { SideBarMenuItem } from '../../models/side-bar-menu-item.model';

@Injectable({
  providedIn: 'root',
})
export class MenuItemList {
  constructor(
    private externalComm: ExternalCommunicationService,
    private router: Router,
    private usersService: UsersService,
    private users: Users,
    private projects: Projects,
    private projectsService: ProjectsService,
    private createNewProjectDialog: CreateNewProjectDialog,
    private documentSelector: DocumentSelector,
    private loadingGraphic: LoadingGraphic,
    private emailService: EmailService,
    private documentsService: DocumentsService,
    private core: EcapsCore,
    private emailDialog: EmailDialog,
    private lachesisService: LachesisService,
    private analytics: GoogleAnalyticsService
  ) {}

  equipmentSchedule: SideBarMenuItem = {
    icon: 'list_alt',
    text: 'Equipment Schedule',
    cssClass: 'equip-sched-icon',
    revitEnabled: false,
    click: () => {
      this.router.navigateByUrl('/equipmentschedule');
    },
  };

  jobs: SideBarMenuItem = {
    icon: 'assignment',
    text: 'Jobs',
    revitEnabled: false,
    click: () => {
      this.usersService.getCurrentUser().then((userData) => {
        if (!!userData && userData.authenticated) {
          this.router.navigateByUrl('/jobmanagement');
        } else {
          this.users.showLoginRequiredDialog(false).then((success) => {
            if (success) {
              this.router.navigateByUrl('/jobmanagement');
            }
          });
        }
      });
    },
  };

  ses: SideBarMenuItem = {
    svgIcon: 'ses',
    text: 'System Effects Simulator',
    click: () => {
      this.externalComm.launchExternal(
        'https://ses.greenheck.com/',
        LaunchTypes.newWindow
      );
    },
  };

  newSelection: SideBarMenuItem = {
    icon: 'add_box',
    text: 'New Selection',
    click: () => {
      this.router.navigateByUrl('');
    },
  };

  save: SideBarMenuItem = {
    icon: 'save',
    text: 'Save',
    click: () => {
      this.projects.saveProject(true, 'Sidebar Menu', 'Save');
    },
  };

  saveAs: SideBarMenuItem = {
    icon: 'file_copy',
    text: 'Save As...',
    click: () => {
      this.projects.saveProjectAs();
    },
  };

  schedule: SideBarMenuItem = {
    icon: 'table_chart',
    text: 'Schedule',
    click: () => {
      console.error('Not Implemented!');
    },
  };

  clearSchedule: SideBarMenuItem = {
    icon: 'delete',
    text: 'Clear Schedule',
    click: () => {
      console.error('Not Implemented!');
    },
  };

  submittal: SideBarMenuItem = {
    icon: 'description',
    text: 'Submittal',
    click: () => {
      console.error('Not Implemented!');
    },
  };

  share: SideBarMenuItem = {
    icon: 'share',
    text: 'Share Project',
    click: () => {
      this.users.showLoginRequiredDialog(false).then(async (success) => {
        if (success) {
          await this.projects.saveProject();

          this.projectsService.getLocalProject().then((localProject) => {
            if (!!localProject.name && localProject.name.trim() !== '') {
              this.emailDialog.show('Share Project').then(async (settings) => {
                if (!!settings && !!settings.recipients) {
                  this.lachesisService.trackEvent({
                    eventName: 'Sharing',
                    retrievalMethod: 'Email',
                  });

                  this.analytics.viewItem('Sharing', {
                    source: 'Sidebar Menu',
                    action: 'Share Project',
                    items: localProject
                      .getActiveRevision()
                      .items.map((item) => item.sizeData?.getGAModel()),
                  });

                  const userData = await this.usersService.getCurrentUser();

                  let sending = true;

                  this.loadingGraphic.show('Sending...', () => sending);

                  const request = new ShareProjectRequest();

                  settings.recipients.forEach((recipient) => {
                    if (!!recipient.name && recipient.name.trim() !== '') {
                      request.toAddresses.push(
                        `"${recipient.name}" <${recipient.email}>`
                      );
                    } else {
                      request.toAddresses.push(recipient.email);
                    }
                  });

                  request.subject =
                    !!settings.subject && settings.subject.trim() !== ''
                      ? settings.subject
                      : null;

                  request.content.fromName = `${userData.firstName} ${userData.lastName}`;

                  request.content.message = settings.message;

                  request.content.projectName = localProject.name;

                  request.content.sharedProjectUrl = `${window.location.origin}/shared/${localProject.publicToken}`;

                  request.attachment.user = userData.email;

                  await this.emailService.sendShareProject(
                    localProject.key,
                    localProject.getActiveRevision().id,
                    request
                  );

                  sending = false;

                  this.loadingGraphic.hide();
                }
              });
            }
          });
        }
      });
    },
  };

  newJob: SideBarMenuItem = {
    icon: 'library_add',
    text: 'Create New Job',
    click: () => {
      this.projectsService.getLocalProject().then((project) => {
        if (!!project && project.getActiveRevision().items.length > 0) {
          this.createNewProjectDialog.show();
        } else {
          this.router.navigateByUrl('/');
        }
      });
    },
  };

  back: SideBarMenuItem = {
    icon: 'arrow_back',
    text: 'Back',
    click: () => {
      console.error('Not Implemented!');
    },
  };

  downloadDocuments: SideBarMenuItem = {
    icon: 'cloud_download',
    text: 'Download Documents',
    click: () => {
      this.users.showLoginRequiredDialog(false).then(async (success) => {
        if (success) {
          await this.projects.saveProject();

          this.projectsService.getLocalProject().then((localProject) => {
            if (localProject.getActiveRevision().items.length > 0) {
              if (!!localProject.name && localProject.name.trim() !== '') {
                this.documentSelector.show().then((documentTypes) => {
                  if (!!documentTypes) {
                    const payload = [];
                    let itemList =
                      this.projectsService.getSelectedProjectItems();

                    if (!itemList || itemList.length === 0) {
                      itemList = localProject.getActiveRevision().items;
                    }

                    if (documentTypes.length > 1) {
                      this.analytics.trackEvent('Document Selector', {
                        source: 'Document Selector',
                        action: 'All',
                        deliveryMethod: 'Download',
                        contentType: 'All',
                      });
                    }

                    if (
                      documentTypes.indexOf(DocumentTypes.equipmentSchedule) >
                      -1
                    ) {
                      payload.push(
                        ...itemList.map((item) => ({
                          eventName: 'Schedule - Generate',
                          modelGroup:
                            item.sizeData.selectionLayoutConfig.modelGroup,
                          productType: item.sizeData.productType,
                          model: item.sizeData.model,
                          retrievalMethod: 'Download',
                        }))
                      );

                      this.viewItemEvent(
                        'Schedule',
                        documentTypes.length === 1 ? 'Schedule' : 'All',
                        'Download',
                        documentTypes.length === 1 ? 'Excel' : 'All',
                        itemList
                      );
                    }

                    if (documentTypes.indexOf(DocumentTypes.capsExport) > -1) {
                      payload.push(
                        ...itemList.map((item) => ({
                          eventName: 'Export to CAPS',
                          modelGroup:
                            item.sizeData.selectionLayoutConfig.modelGroup,
                          productType: item.sizeData.productType,
                          model: item.sizeData.model,
                          retrievalMethod: 'Download',
                        }))
                      );

                      this.viewItemEvent(
                        'CAPS Export',
                        documentTypes.length === 1 ? 'CAPS Export' : 'All',
                        'Download',
                        documentTypes.length === 1 ? 'CAPS Export' : 'All',
                        itemList
                      );
                    }

                    if (documentTypes.indexOf(DocumentTypes.cutSheet) > -1) {
                      payload.push(
                        ...itemList.map((item) => ({
                          eventName: 'Cut-Sheet',
                          modelGroup:
                            item.sizeData.selectionLayoutConfig.modelGroup,
                          productType: item.sizeData.productType,
                          model: item.sizeData.model,
                          retrievalMethod: 'Download',
                        }))
                      );

                      this.viewItemEvent(
                        'Cut Sheet',
                        documentTypes.length === 1 ? 'Cut Sheet' : 'All',
                        'Download',
                        documentTypes.length === 1 ? 'PDF' : 'All',
                        itemList
                      );
                    }

                    if (documentTypes.indexOf(DocumentTypes.revit) > -1) {
                      payload.push(
                        ...itemList.map((item) => ({
                          eventName: 'Revit Drawings',
                          modelGroup:
                            item.sizeData.selectionLayoutConfig.modelGroup,
                          productType: item.sizeData.productType,
                          model: item.sizeData.model,
                          retrievalMethod: 'Download',
                        }))
                      );

                      this.viewItemEvent(
                        'Revit Drawings',
                        documentTypes.length === 1 ? 'Revit Drawings' : 'All',
                        'Download',
                        documentTypes.length === 1 ? 'Revit' : 'All',
                        itemList
                      );
                    }

                    if (payload.length > 0) {
                      this.lachesisService.trackEvent(payload as any[]);
                    }

                    this.usersService.getCurrentUser().then((userData) => {
                      let generating = true;

                      this.loadingGraphic.show(
                        'Generating...',
                        () => generating
                      );

                      this.documentsService
                        .getProjectDocuments(localProject, documentTypes)
                        .then(() => {
                          generating = false;

                          this.loadingGraphic.hide();
                        });
                    });
                  }
                });
              }
            } else {
              this.core.showMessageBox({
                title: 'Download Error',
                message: 'Please add an item to your schedule.',
                icon: 'warning',
                iconClasses: 'warn',
              });
            }
          });
        }
      });
    },
  };

  emailDocuments: SideBarMenuItem = {
    icon: 'mail_outline',
    text: 'Email Documents',
    click: () => {
      this.users.showLoginRequiredDialog(false).then(async (success) => {
        if (success) {
          await this.projects.saveProject();

          this.projectsService.getLocalProject().then((localProject) => {
            if (localProject.getActiveRevision().items.length > 0) {
              if (!!localProject.name && localProject.name.trim() !== '') {
                this.documentSelector
                  .show([
                    DocumentTypes.capsExport,
                    DocumentTypes.cutSheet,
                    DocumentTypes.equipmentSchedule,
                  ])
                  .then((documentTypes) => {
                    if (!!documentTypes) {
                      let itemList =
                        this.projectsService.getSelectedProjectItems();

                      if (!itemList || itemList.length === 0) {
                        itemList = localProject.getActiveRevision().items;
                      }

                      if (documentTypes.length > 1) {
                        this.analytics.trackEvent('Document Selector', {
                          source: 'Document Selector',
                          action: 'All',
                          deliveryMethod: 'Email',
                          contentType: 'All',
                        });
                      }

                      if (
                        documentTypes.indexOf(DocumentTypes.equipmentSchedule) >
                        -1
                      ) {
                        this.lachesisService.trackEvent({
                          eventName: 'Schedule - Generate',
                          retrievalMethod: 'Email',
                        });

                        this.viewItemEvent(
                          'Schedule',
                          documentTypes.length === 1 ? 'Schedule' : 'All',
                          'Email',
                          documentTypes.length === 1 ? 'Excel' : 'All',
                          itemList
                        );
                      } else if (
                        documentTypes.indexOf(DocumentTypes.capsExport) > -1
                      ) {
                        this.lachesisService.trackEvent({
                          eventName: 'Export to CAPS',
                          retrievalMethod: 'Email',
                        });

                        this.viewItemEvent(
                          'CAPS Export',
                          documentTypes.length === 1 ? 'CAPS Export' : 'All',
                          'Email',
                          documentTypes.length === 1 ? 'CAPS Export' : 'All',
                          itemList
                        );
                      } else if (
                        documentTypes.indexOf(DocumentTypes.cutSheet) > -1
                      ) {
                        this.lachesisService.trackEvent({
                          eventName: 'Cut-Sheet',
                          retrievalMethod: 'Email',
                        });

                        this.viewItemEvent(
                          'Cut Sheet',
                          documentTypes.length === 1 ? 'Cut Sheet' : 'All',
                          'Email',
                          documentTypes.length === 1 ? 'PDF' : 'All',
                          itemList
                        );
                      }

                      let itemIds = [];

                      if (
                        this.projectsService.getSelectedProjectItems().length >
                        0
                      ) {
                        itemList.forEach((item) => {
                          itemIds.push(item.id);
                        });
                      }

                      const sortedList =
                        this.projectsService.getSortedProjectItems();

                      if (
                        (!itemIds || itemIds.length === 0) &&
                        sortedList.length > 0
                      ) {
                        itemIds = sortedList;
                      } else if (sortedList.length > 0) {
                        itemIds.sort(
                          (a, b) =>
                            sortedList.findIndex((item) => item === a) -
                            sortedList.findIndex((item) => item === b)
                        );
                      }

                      const gridDefs =
                        this.projectsService.getEquipmentScheduleGridDefinitions();

                      const gridFields = new Array<GridField>();

                      gridDefs
                        .filter(
                          (gridItem: EquipmentScheduleGridDefinition) =>
                            gridItem.productType !==
                              ProductTypes.temperedAirProduct &&
                            gridItem.productType !== ProductTypes.makeUpAir &&
                            gridItem.productType !==
                              ProductTypes.preconditioners
                        )
                        .forEach(
                          (gridItem: EquipmentScheduleGridDefinition) => {
                            gridItem.visibleColumns.forEach((key) => {
                              gridFields.push({
                                productType: gridItem.productType,

                                fieldName: key,
                              });
                            });
                          }
                        );

                      this.usersService.getCurrentUser().then((userData) => {
                        let sending = true;

                        this.loadingGraphic.show('Sending...', () => sending);

                        this.emailService
                          .sendDocuments(
                            localProject.key,
                            localProject.getActiveRevision().id,
                            {
                              toAddresses: [userData.email],

                              content: {
                                fromName: `${userData.firstName} ${userData.lastName}`,

                                projectName: localProject.name,
                              },

                              attachment: {
                                user: userData.email,

                                documentTypes: documentTypes,

                                itemIds: itemIds,

                                gridFields: gridFields,
                              },
                            }
                          )
                          .then(() => {
                            sending = false;

                            this.loadingGraphic.hide();
                          });
                      });
                    }
                  });
              }
            } else {
              this.core.showMessageBox({
                title: 'Email Error',
                message: 'Please add an item to your schedule.',
                icon: 'warning',
                iconClasses: 'warn',
              });
            }
          });
        }
      });
    },
  };

  private viewItemEvent(
    type: ViewTypes,
    action: ActionTypes,
    deliveryMethod: DeliveryMethodTypes,
    contentType: ContentTypes,
    itemList: Item[]
  ): void {
    this.analytics.viewItem(type, {
      source: 'Document Selector',
      action,
      deliveryMethod,
      contentType,
      items: itemList.map((item) => {
        const payload: IEventItemModelsPayload = item.sizeData?.getGAModel();

        payload.quantity = item.quantity;

        return payload;
      }),
    });

    if (action !== 'All') {
      this.analytics.trackEvent('Document Selector', {
        source: 'Document Selector',
        action,
        deliveryMethod,
        contentType,
      });
    }
  }
}
