import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Injector,
} from '@angular/core';
import { ColumnList } from '../../constants/column-list.const';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { Subscription } from 'rxjs';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';
import { FilterTypes } from '../../enums/filter-types.enum';

@Component({
  selector: 'stationary-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StationaryGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  private _sizeAddedSubscription: Subscription;

  private _lastModelFilter: string;

  constructor(protected injector: Injector) {
    super(injector);

    this.modelNameHeader = 'Model - W x H';

    this.footerProductType = 'Louver(s)';

    this._sizeAddedSubscription = this.sizeAdded.subscribe((size) => {
      if (size.model !== this._lastModelFilter) {
        this.core
          .showMessageBox({
            title: 'Model Filter',
            message: `Would you like to make another ${size.model} selection?`,
            buttons: [
              {
                title: 'Yes',
                value: true,
              },
              {
                title: 'No',
                value: false,
              },
            ],
          })
          .then((results) => {
            if (results) {
              this.setColumnFilter('name', FilterTypes.contains, size.model);

              this._lastModelFilter = size.model;
            }
          });
      }
    });
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'actualDepth',
      'budgetPrice',
      'rainResistanceRanking',
      'actualCFM',
      'actualFreeArea',
      'freeAreaPercentage',
      'actualVelocity',
      'maxVelocity',
      'actualPressureDrop',
      'maxVelocityWithSafetyFactor',
      'suggestedWidth',
      'suggestedHeight',
      'classA293Limit',
      'classA508Limit',
      'beginningPointOfWaterPenetration',
      'sectionsWide',
      'sectionsHigh',
      'weight',
      'actualToMaxVelocityPercentage',
      'actualToMaxVelocityDelta'
    );

    this.setVisibleColumns([
      'actualDepth',
      'budgetPrice',
      'rainResistanceRanking',
      'actualCFM',
      'actualFreeArea',
      'freeAreaPercentage',
      'actualVelocity',
      'maxVelocity',
      'actualPressureDrop',
    ]);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();

    this._sizeAddedSubscription.unsubscribe();
  }

  private validSizeToDSRow(size: ValidSize) {
    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.name,
      actualDepth: size.outputs.actualDepth,
      budgetPrice: size.pricing.price,
      rainResistanceRanking: size.outputs.rainResistanceRanking,
      actualCFM: size.outputs.actualVolume,
      actualFreeArea: size.outputs.actualFreeArea,
      freeAreaPercentage: size.outputs.freeAreaPercentage,
      actualVelocity: size.outputs.actualVelocity,
      maxVelocity: size.outputs.maxVelocity,
      actualPressureDrop: size.outputs.actualPressureDrop,
      maxVelocityWithSafetyFactor: size.outputs.maxVelocityWithSafetyFactor,
      suggestedWidth: size.outputs.suggestedWidth,
      suggestedHeight: size.outputs.suggestedHeight,
      classA293Limit: size.outputs.classA293Limit,
      classA508Limit: size.outputs.classA508Limit,
      beginningPointOfWaterPenetration:
        size.outputs.beginningPointOfWaterPenetration,
      sectionsWide: size.outputs.sectionsWide,
      sectionsHigh: size.outputs.sectionsHigh,
      weight: size.pricing.weight,
      actualToMaxVelocityPercentage: size.outputs.actualToMaxVelocityPercentage,
      actualToMaxVelocityDelta: size.outputs.actualToMaxVelocityDelta,
      stable: size.stability.stable,
      sizeData: size,
    };

    return rowData;
  }
}
