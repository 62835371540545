<h2 mat-dialog-title>ERV Frost Control Guide</h2>
<span class="heading-text">
  The models below can have the following frost control options.
</span>
<span class="heading-text">
  *- Modulating Wheel is not available in ERV-10
</span>
<mat-dialog-content>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef>Model</th>
      <td mat-cell *matCellDef="let element">{{ element.model }}</td>
    </ng-container>

    <ng-container matColumnDef="timedExhaust">
      <th mat-header-cell *matHeaderCellDef>Timed Exhaust</th>
      <td mat-cell *matCellDef="let element">{{ element.timedExhaust }}</td>
    </ng-container>

    <ng-container matColumnDef="electricPreheat">
      <th mat-header-cell *matHeaderCellDef>Electric Preheat</th>
      <td mat-cell *matCellDef="let element">{{ element.electricPreheat }}</td>
    </ng-container>

    <ng-container matColumnDef="modWheel">
      <th mat-header-cell *matHeaderCellDef>Modulating Wheel</th>
      <td mat-cell *matCellDef="let element">{{ element.modWheel }}</td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="[
        'model',
        'timedExhaust',
        'electricPreheat',
        'modWheel'
      ]"
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let row;
        columns: ['model', 'timedExhaust', 'electricPreheat', 'modWheel']
      "
    ></tr>
  </table>

  <span class="heading-text">
    The following Unit Controls and Housing options are available in CAPS when
    ordered.
  </span>
  <table mat-table [dataSource]="dataSourceExtra">
    <ng-container matColumnDef="model">
      <th mat-header-cell *matHeaderCellDef>Model</th>
      <td mat-cell *matCellDef="let element">{{ element.model }}</td>
    </ng-container>

    <ng-container matColumnDef="terminalStrip">
      <th mat-header-cell *matHeaderCellDef>Terminal Strip</th>
      <td mat-cell *matCellDef="let element">{{ element.terminalStrip }}</td>
    </ng-container>

    <ng-container matColumnDef="microprocessor">
      <th mat-header-cell *matHeaderCellDef>Microprocessor</th>
      <td mat-cell *matCellDef="let element">{{ element.microprocessor }}</td>
    </ng-container>

    <ng-container matColumnDef="singleWall">
      <th mat-header-cell *matHeaderCellDef>Single Wall</th>
      <td mat-cell *matCellDef="let element">{{ element.singleWall }}</td>
    </ng-container>
    <ng-container matColumnDef="doubleWall">
      <th mat-header-cell *matHeaderCellDef>Double Wall</th>
      <td mat-cell *matCellDef="let element">{{ element.doubleWall }}</td>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="[
        'model',
        'terminalStrip',
        'microprocessor',
        'singleWall',
        'doubleWall'
      ]"
    ></tr>
    <tr
      mat-row
      *matRowDef="
        let row;
        columns: [
          'model',
          'terminalStrip',
          'microprocessor',
          'singleWall',
          'doubleWall'
        ]
      "
    ></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button (click)="closeClick()" color="primary">
    Close
  </button>
</mat-dialog-actions>
