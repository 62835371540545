import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { ColumnList } from '../constants/column-list.const';
import { ProductTypes } from '../../../../products/enums/product-types.enum';

@Component({
  selector: 'fans-equipment-schedule-grid',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FansEquipmentScheduleGridComponent
  extends BaseGridComponent
  implements OnInit
{
  constructor(protected injector: Injector) {
    super(injector);

    this.productType = ProductTypes.fan;
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'location',
      'areaServed',
      'model',
      'driveType',
      'operatingVolume',
      'totalStaticPressure',
      'rpm',
      'bhp',
      'motorHP',
      'vcp',
      'inletSones',
      'esp',
      'maxRpm',
      'quantity',
      'mfg',
      'modelGroup',
      'dischargePosition',
      'arrangement',
      'watts',
      'sphericalSones',
      'inletDba',
      'inletLwa',
      'feg',
      'fei',
      'peakTE',
      'operatingTE',
      'ptsPeakTE',
      'plumeHeight',
      'dilutionRatio',
      'nozzleOutletVelocity',
      'nozzleSize',
      'fullLoadAmps',
      'weight',
      'wheelDiameter',
      'wheelRotation',
      'outletVelocity',
      'roofOpeningLW',
      'wallOpening',
      'operatingFrequency',
      'speedNonAdjusted',
      'tipSpeed',
      'outletSones',
      'outletDba',
      'outletLwa',
      'radiatedDba',
      'radiatedLwa',
      'fanSeries',
      'mca',
      'mop'
    );

    this.setVisibleColumns([
      'location',
      'areaServed',
      'model',
      'driveType',
      'operatingVolume',
      'totalStaticPressure',
      'rpm',
      'bhp',
      'fei',
      'motorHP',
      'vcp',
      'inletSones',
    ]);
  }
}
