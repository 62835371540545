<div class="app-container" [class.revit]="isRevitPlugin">
  <mat-drawer-container>
    <mat-drawer
      mode="side"
      position="start"
      [disableClose]="true"
      [opened]="!isRevitPlugin"
      class="no-revit"
    >
      <button
        class="exp-btn"
        color="accent"
        mat-mini-fab
        (click)="toggleSidebar()"
        [matTooltip]="!sidebarExpanded ? 'Expand Menu' : null"
      >
        <mat-icon>{{
          sidebarExpanded ? 'chevron_left' : 'chevron_right'
        }}</mat-icon>
      </button>

      <div class="sidebar" [class.expanded]="sidebarExpanded">
        <button mat-button class="logo-btn" (click)="goHome()">
          <img
            class="ecaps-sidebar-logo"
            src="/assets/images/ecapslogo.png"
            matTooltip="eCAPS Home"
          />
        </button>

        <div
          class="menu-group"
          *ngFor="let menuGroup of sidebarGroups"
          (click)="closeSidebar()"
        >
          <ng-container *ngFor="let menuItem of menuGroup.menuItems">
            <div
              matRipple
              class="menu-item {{ menuItem.cssClass }}"
              *ngIf="showMenuItem(menuItem)"
              [matTooltip]="!sidebarExpanded ? menuItem.text : null"
              (click)="sidebarItemClick(menuItem)"
            >
              <mat-icon *ngIf="!!menuItem.icon">{{ menuItem.icon }}</mat-icon>
              <mat-icon
                *ngIf="!!menuItem.svgIcon"
                [svgIcon]="menuItem.svgIcon"
              ></mat-icon>
              <span>{{ menuItem.text }}</span>
              <div
                class="item-count"
                *ngIf="menuItem.text === 'Equipment Schedule'"
                [matBadge]="getItemCount()"
                matBadgeOverlap="true"
                matBadgeColor="accent"
              ></div>
            </div>
          </ng-container>
        </div>
        <div class="greenheck-logo">
          <div matTooltip="Greenheck" (click)="greenheckClick()">
            <img
              class="ecaps-greenheck-logo"
              src="/assets/images/greenhecklogo.png"
            />
          </div>
        </div>
      </div>
    </mat-drawer>
    <mat-drawer #sideDialog class="side-dialog" mode="over" position="end">
      <div class="dialog-container">
        <ng-template sideDialog></ng-template>
      </div>
    </mat-drawer>
    <mat-drawer-content
      (click)="closeSidebar()"
      class="app-drawer-body"
      [class.expanded]="sidebarExpanded"
      [class.collapsed]="!sidebarExpanded"
    >
      <div class="top-bar">
        <mat-toolbar>
          <button *ngIf="isRevitPlugin" mat-button class="logo">
            <div class="revit-logo-btn">
              <div
                class="svg"
                loadChild="/assets/images/icons/svg/ecaps_logo.svg"
              ></div>
              <mat-icon class="revit-arrow" color="accent"
                >arrow_forward</mat-icon
              >
              <div class="revit-label">
                <div class="text">Revit</div>
                <div class="tm">&reg;</div>
              </div>
            </div>
          </button>

          <app-breadcrumb *ngIf="!isRevitPlugin"></app-breadcrumb>

          <div class="icons">
            <div>
              <user-icon *ngIf="!isRevitPlugin"></user-icon>
            </div>
            <div>
              <app-notification></app-notification>
            </div>
            <div>
              <dev-icon *ngIf="!production"></dev-icon>
            </div>
            <div>
              <mat-icon
                class="feedback-logo"
                matTooltip="Feedback"
                (click)="feedbackClick()"
                >feedback</mat-icon
              >
            </div>
          </div>
        </mat-toolbar>
      </div>
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<job-quick-view></job-quick-view>

<loading-graphic></loading-graphic>

<cookie-policy></cookie-policy>
