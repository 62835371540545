import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RepLocatorDialog } from '../../controllers/rep-locator-dialog.controller';
import { EmailResponse } from './models/email-response.models';
import { EmailSettings } from './models/email-settings.models';

@Component({
  selector: 'app-email-dialog',
  templateUrl: './email-dialog.component.html',
  styleUrls: ['./email-dialog.component.scss'],
})
export class EmailDialogComponent implements OnInit {
  title: string;

  formGroup: UntypedFormGroup;

  recipientCount: UntypedFormControl;

  recipients: {
    name: UntypedFormControl;
    email: UntypedFormControl;
  }[];

  subject: UntypedFormControl;

  message: UntypedFormControl;

  constructor(
    private dialogRef: MatDialogRef<EmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private settings: EmailSettings,
    private repLocatorDialog: RepLocatorDialog
  ) {
    this.title = this.settings.title;

    this.recipientCount = new UntypedFormControl(1, [Validators.required]);

    this.recipients = [];

    this.recipients.push({
      name: new UntypedFormControl(''),

      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
    });

    this.subject = new UntypedFormControl(this.settings.subject, [
      Validators.required,
    ]);

    this.message = new UntypedFormControl(this.settings.message);

    this.formGroup = new UntypedFormGroup({
      recipientCount: this.recipientCount,

      recipients: new UntypedFormGroup({
        recipient0: new UntypedFormGroup({
          name: this.recipients[0].name,

          email: this.recipients[0].email,
        }),
      }),

      subject: this.subject,

      message: this.message,
    });
  }

  ngOnInit() {}

  recipientCountChanged() {
    if (this.recipientCount.value > this.recipients.length) {
      const addCount = this.recipientCount.value - this.recipients.length;

      for (let i = 0; i < addCount; i++) {
        const newRecipient = {
          name: new UntypedFormControl(''),

          email: new UntypedFormControl('', [
            Validators.required,
            Validators.email,
          ]),
        };

        (<UntypedFormGroup>this.formGroup.get('recipients')).addControl(
          `recipient${this.recipients.length.toString()}`,
          new UntypedFormGroup({
            name: newRecipient.name,

            email: newRecipient.email,
          })
        );

        this.recipients.push(newRecipient);
      }
    } else if (this.recipientCount.value < this.recipients.length) {
      const recipientsGroup = <UntypedFormGroup>(
        this.formGroup.get('recipients')
      );

      while (this.recipientCount.value < this.recipients.length) {
        recipientsGroup.removeControl(
          `recipient${(this.recipients.length - 1).toString()}`
        );

        this.recipients.pop();
      }
    }
  }

  getRecipientError(formControl: UntypedFormControl): string {
    if (!!formControl && formControl.invalid) {
      if (formControl.hasError('required')) {
        return 'Required';
      } else if (formControl.hasError('email')) {
        return 'Invalid Email Address';
      }
    }
  }

  sendClick() {
    if (this.formGroup.valid) {
      const emailResponse: EmailResponse = {
        recipients: [],

        subject: this.subject.value,

        message: this.message.value,
      };

      this.recipients.forEach((recipient) => {
        emailResponse.recipients.push({
          name: recipient.name.value,
          email: recipient.email.value,
        });
      });

      this.dialogRef.close(emailResponse);
    }
  }

  findRepClick() {
    this.repLocatorDialog.show().then((rep) => {
      if (!!rep && !!rep.email) {
        let lastRecipient = this.recipients[this.recipients.length - 1];

        if (
          lastRecipient.name.value === '' &&
          lastRecipient.email.value === ''
        ) {
          lastRecipient.name.setValue(rep.officeName);

          lastRecipient.email.setValue(rep.email);
        } else if (this.recipientCount.value < 5) {
          this.recipientCount.setValue(this.recipientCount.value + 1);

          this.recipientCountChanged();

          lastRecipient = this.recipients[this.recipients.length - 1];

          lastRecipient.name.setValue(rep.officeName);

          lastRecipient.email.setValue(rep.email);
        }
      }
    });
  }

  canAddRep(): boolean {
    if (this.recipientCount.value < 5) {
      return true;
    } else {
      const lastRecipient = this.recipients[this.recipients.length - 1];

      return (
        lastRecipient.name.value.trim() === '' &&
        lastRecipient.email.value.trim() === ''
      );
    }
  }
}
