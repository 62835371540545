<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 402.91 616.98">
  <defs>
    <style>
      .cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: none;
        stroke: #000;
        stroke-miterlimit: 10;
        stroke-width: 2px;
      }
    </style>
  </defs>
  <title></title>
  <g id="Bottom_Text" data-name="Bottom Text">
    <path d="M77.69,353.72,406.44,622.28H4.59q-.3-97.5-.59-195Z" transform="translate(-4 -5.3)"/>
    <g>
      <path class="cls-1" d="M27.64,465.3H26.13v4.45H20.5v-12a7.06,7.06,0,0,1,1.88-5.11,6.74,6.74,0,0,1,5.09-2,6,6,0,0,1,4.81,2.1,7.43,7.43,0,0,1,1.8,5v12H28.45V457.57a1.74,1.74,0,0,0-.29-.9.94.94,0,0,0-.8-.44,1,1,0,0,0-.92.46,1.82,1.82,0,0,0-.31,1.05v2.66h1.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M43.07,469.75H37.44V451.07h5.63Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M52.14,469.75H46.6V451.07h7.67a6.86,6.86,0,0,1,4.45,1.74,5.37,5.37,0,0,1,2,4.28,6.13,6.13,0,0,1-2.47,5l3.22,7.67H55.64l-2.69-6v-4.79a.28.28,0,0,0,.13,0h.15a3.52,3.52,0,0,0,1.35-.31,1.17,1.17,0,0,0,.61-1.2,1.09,1.09,0,0,0-.47-1,2.08,2.08,0,0,0-1.07-.34h-.14l-1.37,0Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M20.5,491.75a4.78,4.78,0,0,1,1.6-3.57,5.11,5.11,0,0,1,3.64-1.53,5.1,5.1,0,0,1,4.59,2.64,4.9,4.9,0,0,1,1.78-1.84,5.38,5.38,0,0,1,2.45-.8h.28a4.86,4.86,0,0,1,4,1.75,6.63,6.63,0,0,1,1.38,4.22v13.13H34.75V493.32a1.5,1.5,0,0,0-.19-.63.59.59,0,0,0-.56-.3.69.69,0,0,0-.62.3,1.59,1.59,0,0,0-.25.66v12.4H27.61V493.32a1.5,1.5,0,0,0-.19-.63.59.59,0,0,0-.56-.3.71.71,0,0,0-.65.34,1.42,1.42,0,0,0-.19.73v12.29H20.5Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M42.68,496.12a8.88,8.88,0,0,1,2.91-6.72,9.64,9.64,0,0,1,6.83-2.75,9.82,9.82,0,0,1,6.9,2.75,8.86,8.86,0,0,1,3,6.75,10,10,0,0,1-2.83,7.07,9.57,9.57,0,0,1-13.94,0,10,10,0,0,1-2.83-7.07Zm5.46.08a4.72,4.72,0,0,0,1.23,3.22,3.91,3.91,0,0,0,3.08,1.4,4,4,0,0,0,3.12-1.41,4.72,4.72,0,0,0,1.25-3.21,3.81,3.81,0,0,0-1.32-2.95,4.42,4.42,0,0,0-3-1.19,4.24,4.24,0,0,0-3,1.22,3.87,3.87,0,0,0-1.3,3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M70.45,506.81l-8-19.74h6.41l1.71,5.74,1.62-5.74h6.75Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M90.42,498.53H86.78l0,0h0a2.55,2.55,0,0,0-.88.16c-.27.1-.4.36-.4.77s.12.64.37.75a2.3,2.3,0,0,0,.83.2H87l3.72,0v5.35H86.19a10,10,0,0,1-2.31-.25,4.72,4.72,0,0,1-2.06-1.12,5.35,5.35,0,0,1-1.3-1.79,5.21,5.21,0,0,1-.46-2.16v-8.26a4.68,4.68,0,0,1,1.64-3.59,5.3,5.3,0,0,1,3.68-1.51l5.37,0v5.4H87l0,0h0a1.74,1.74,0,0,0-.82.19.76.76,0,0,0-.36.73.7.7,0,0,0,.34.68,2,2,0,0,0,.72.25h3.59Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M94.11,491.75a4.82,4.82,0,0,1,1.6-3.57,5.11,5.11,0,0,1,3.64-1.53,5.1,5.1,0,0,1,4.59,2.64,4.9,4.9,0,0,1,1.78-1.84,5.38,5.38,0,0,1,2.45-.8h.28a4.86,4.86,0,0,1,4,1.75,6.63,6.63,0,0,1,1.38,4.22v13.13h-5.48V493.32a1.51,1.51,0,0,0-.2-.63.59.59,0,0,0-.56-.3.69.69,0,0,0-.62.3,1.59,1.59,0,0,0-.25.66v12.4h-5.51V493.32a1.51,1.51,0,0,0-.2-.63.59.59,0,0,0-.56-.3.69.69,0,0,0-.64.34,1.34,1.34,0,0,0-.2.73v12.29H94.11Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M127.57,498.53h-3.64l0,0h0a2.5,2.5,0,0,0-.88.16c-.27.1-.41.36-.41.77a.73.73,0,0,0,.38.75,2.25,2.25,0,0,0,.83.2h.42l3.72,0v5.35h-4.56a9.92,9.92,0,0,1-2.31-.25,4.72,4.72,0,0,1-2.06-1.12,5.38,5.38,0,0,1-1.77-3.95v-8.26a4.72,4.72,0,0,1,1.64-3.59,5.32,5.32,0,0,1,3.68-1.51l5.38,0v5.4h-3.72l0,0h0a1.67,1.67,0,0,0-.81.19.76.76,0,0,0-.36.73.72.72,0,0,0,.33.68,2.11,2.11,0,0,0,.73.25h3.58Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M131.27,493.46a6.76,6.76,0,0,1,1.9-4.76,6.2,6.2,0,0,1,4.71-2.05,6.74,6.74,0,0,1,4.9,2,6.58,6.58,0,0,1,2,4.89v12.26h-5.57v-12.1a1.35,1.35,0,0,0-.32-.91,1.09,1.09,0,0,0-.88-.37,1.08,1.08,0,0,0-.88.39,1.39,1.39,0,0,0-.33.92v12.07h-5.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M156.64,505.75h-5.58V492.39h-4.25v-5.32h14v5.32h-4.2Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M27.64,537.3H26.13v4.45H20.5v-12a7.06,7.06,0,0,1,1.88-5.11,6.74,6.74,0,0,1,5.09-2,6,6,0,0,1,4.81,2.1,7.43,7.43,0,0,1,1.8,5v12H28.45V529.57a1.74,1.74,0,0,0-.29-.9.94.94,0,0,0-.8-.44,1,1,0,0,0-.92.46,1.82,1.82,0,0,0-.31,1v2.66h1.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M37.25,529.46a6.76,6.76,0,0,1,1.9-4.76,6.19,6.19,0,0,1,4.7-2.05,6.72,6.72,0,0,1,4.9,2,6.55,6.55,0,0,1,2,4.89v12.26H45.17v-12.1a1.4,1.4,0,0,0-.32-.91,1.22,1.22,0,0,0-1.77,0,1.43,1.43,0,0,0-.32.92v12.07H37.25Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M59.7,541.75H54.1V523.07h8.35q.81,0,1.62.12a6.49,6.49,0,0,1,1.6.36,9.77,9.77,0,0,1,4.49,3.34A8.44,8.44,0,0,1,72,532.16a9.39,9.39,0,0,1-2.75,6.94,9.56,9.56,0,0,1-6.93,2.76h-.44l-.43,0h-.87v-5.49H62a4.81,4.81,0,0,0,3-1,3.37,3.37,0,0,0,1.32-2.87,3.54,3.54,0,0,0-1.17-2.71,3.84,3.84,0,0,0-2.73-1.1l-2.77,0Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M100.81,532.79a18.59,18.59,0,0,1-.55,2.69,9.27,9.27,0,0,1-4.63,5.63A10.22,10.22,0,0,1,91,542.23a9.33,9.33,0,0,1-6.93-2.9,9.57,9.57,0,0,1-2.85-7,9.27,9.27,0,0,1,2.94-6.82,9.46,9.46,0,0,1,6.84-2.9,9.2,9.2,0,0,1,6.35,2.3,10.85,10.85,0,0,1,3.39,5.88l-5.94.36a5.22,5.22,0,0,0-1.49-1.94,3.9,3.9,0,0,0-5.11.47,3.88,3.88,0,0,0-1.15,2.76,3.67,3.67,0,0,0,1.19,2.75A3.86,3.86,0,0,0,91,536.37a3.59,3.59,0,0,0,2.74-1.06A4.74,4.74,0,0,0,95,532.65Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M102.49,532.12a8.88,8.88,0,0,1,2.91-6.72,9.64,9.64,0,0,1,6.83-2.75,9.82,9.82,0,0,1,6.9,2.75,8.85,8.85,0,0,1,3,6.75,10,10,0,0,1-2.82,7.07,9.59,9.59,0,0,1-14,0,10,10,0,0,1-2.82-7.07Zm5.46.08a4.72,4.72,0,0,0,1.23,3.22,3.91,3.91,0,0,0,3.08,1.4,4,4,0,0,0,3.12-1.41,4.72,4.72,0,0,0,1.25-3.21,3.81,3.81,0,0,0-1.32-3,4.44,4.44,0,0,0-3-1.19,4.24,4.24,0,0,0-3,1.22,3.87,3.87,0,0,0-1.3,3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M124.49,529.46a6.77,6.77,0,0,1,1.91-4.76,6.17,6.17,0,0,1,4.7-2.05,6.72,6.72,0,0,1,4.9,2,6.55,6.55,0,0,1,2,4.89v12.26h-5.57v-12.1a1.4,1.4,0,0,0-.32-.91,1.11,1.11,0,0,0-.89-.37,1.09,1.09,0,0,0-.88.39,1.43,1.43,0,0,0-.32.92v12.07h-5.52Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M149.86,541.75h-5.57V528.39H140v-5.32h14v5.32h-4.2Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M161.82,541.75h-5.55V523.07h7.67a6.88,6.88,0,0,1,4.46,1.74,5.39,5.39,0,0,1,2,4.28,6.26,6.26,0,0,1-.63,2.78,6.33,6.33,0,0,1-1.83,2.21l3.22,7.67h-5.85l-2.69-6v-4.79l.12,0h.16a3.51,3.51,0,0,0,1.34-.31,1.18,1.18,0,0,0,.62-1.2,1.07,1.07,0,0,0-.48-1,2,2,0,0,0-1.06-.34h-.14l-1.37,0Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M171.92,532.12a8.89,8.89,0,0,1,2.92-6.72,9.63,9.63,0,0,1,6.83-2.75,9.82,9.82,0,0,1,6.9,2.75,8.85,8.85,0,0,1,3,6.75,10,10,0,0,1-2.82,7.07,9.59,9.59,0,0,1-13.95,0,10,10,0,0,1-2.83-7.07Zm5.46.08a4.68,4.68,0,0,0,1.24,3.22,3.9,3.9,0,0,0,3.08,1.4,4,4,0,0,0,3.12-1.41,4.71,4.71,0,0,0,1.24-3.21,3.8,3.8,0,0,0-1.31-3,4.44,4.44,0,0,0-3.05-1.19,4.23,4.23,0,0,0-3,1.22,3.88,3.88,0,0,0-1.31,3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M199.42,523.07v11.48a1.46,1.46,0,0,0,.73,1.49,4,4,0,0,0,1.68.33v5.38h-1.71a5.77,5.77,0,0,1-4.51-1.86,6.63,6.63,0,0,1-1.68-4.61V523.07Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M27.64,573.3H26.13v4.45H20.5v-12a7.06,7.06,0,0,1,1.88-5.11,6.74,6.74,0,0,1,5.09-2,6,6,0,0,1,4.81,2.1,7.43,7.43,0,0,1,1.8,5v12H28.45V565.57a1.74,1.74,0,0,0-.29-.9.94.94,0,0,0-.8-.44,1,1,0,0,0-.92.46,1.82,1.82,0,0,0-.31,1v2.66h1.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M46.57,559v5.29l-.46-.13a2,2,0,0,0-.46-.07,1.46,1.46,0,0,0-1.1.49,1.6,1.6,0,0,0-.44,1.11,2.43,2.43,0,0,0,.32,1.11l.71,1.39a17.11,17.11,0,0,1,.72,1.67,5.52,5.52,0,0,1,.32,1.87,6,6,0,0,1-2,4.64,7,7,0,0,1-4.78,1.84,5.06,5.06,0,0,1-1.28-.17,8.79,8.79,0,0,1-1.22-.42v-5.45a3.2,3.2,0,0,0,.69.35,1.94,1.94,0,0,0,.71.13h.23a1.93,1.93,0,0,0,1-.48,1.23,1.23,0,0,0,.46-1,2.32,2.32,0,0,0-.31-1.05c-.2-.4-.44-.86-.7-1.36a15.42,15.42,0,0,1-.68-1.7,6.09,6.09,0,0,1-.32-2,6.27,6.27,0,0,1,1.86-4.51,6.06,6.06,0,0,1,4.49-1.91,7.46,7.46,0,0,1,1.16.1,6.71,6.71,0,0,1,1.11.3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M58.69,559v5.29l-.46-.13a1.9,1.9,0,0,0-.46-.07,1.44,1.44,0,0,0-1.09.49,1.61,1.61,0,0,0-.45,1.11,2.56,2.56,0,0,0,.32,1.11c.22.42.45.89.72,1.39a17.1,17.1,0,0,1,.71,1.67,5.52,5.52,0,0,1,.32,1.87,6,6,0,0,1-2,4.64,7,7,0,0,1-4.79,1.84,5,5,0,0,1-1.27-.17,8.36,8.36,0,0,1-1.22-.42v-5.45a2.93,2.93,0,0,0,.68.35,2,2,0,0,0,.72.13h.22a1.89,1.89,0,0,0,1-.48,1.24,1.24,0,0,0,.47-1,2.45,2.45,0,0,0-.31-1.05l-.7-1.36a15.37,15.37,0,0,1-.69-1.7,6.35,6.35,0,0,1-.32-2,6.27,6.27,0,0,1,1.86-4.51,6.06,6.06,0,0,1,4.5-1.91,7.57,7.57,0,0,1,1.16.1,6.6,6.6,0,0,1,1.1.3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M60.77,568.12a8.88,8.88,0,0,1,2.91-6.72,9.64,9.64,0,0,1,6.83-2.75,9.82,9.82,0,0,1,6.9,2.75,8.85,8.85,0,0,1,3,6.75,10,10,0,0,1-2.82,7.07,9.59,9.59,0,0,1-14,0,10,10,0,0,1-2.82-7.07Zm5.46.08a4.72,4.72,0,0,0,1.23,3.22,3.91,3.91,0,0,0,3.08,1.4,4,4,0,0,0,3.12-1.41,4.71,4.71,0,0,0,1.24-3.21,3.8,3.8,0,0,0-1.31-3,4.44,4.44,0,0,0-3-1.19,4.24,4.24,0,0,0-3,1.22,3.87,3.87,0,0,0-1.3,3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M101.59,568.79a17.34,17.34,0,0,1-.55,2.69,9.27,9.27,0,0,1-4.63,5.63,10.22,10.22,0,0,1-4.62,1.12,9.33,9.33,0,0,1-6.93-2.9,9.56,9.56,0,0,1-2.84-7A9.27,9.27,0,0,1,85,561.55a9.43,9.43,0,0,1,6.83-2.9,9.18,9.18,0,0,1,6.35,2.3,10.85,10.85,0,0,1,3.39,5.88l-5.93.36a5.35,5.35,0,0,0-1.5-1.94,3.9,3.9,0,0,0-5.11.47,3.88,3.88,0,0,0-1.15,2.76A3.7,3.7,0,0,0,89,571.23a3.86,3.86,0,0,0,2.76,1.14,3.58,3.58,0,0,0,2.74-1.06,4.67,4.67,0,0,0,1.23-2.66Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M109.82,577.75h-5.63V559.07h5.63Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M120.32,573.3h-1.51v4.45h-5.63v-12a7.06,7.06,0,0,1,1.88-5.11,6.74,6.74,0,0,1,5.09-2,6,6,0,0,1,4.8,2.1,7.39,7.39,0,0,1,1.81,5v12h-5.63V565.57a1.84,1.84,0,0,0-.29-.9.94.94,0,0,0-.8-.44,1,1,0,0,0-.92.46,1.82,1.82,0,0,0-.31,1v2.66h1.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M138.63,577.75h-5.57V564.39H128.8v-5.32h14v5.32h-4.2Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M150.7,577.75h-5.63V559.07h5.63Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M153.33,568.12a8.88,8.88,0,0,1,2.91-6.72,9.67,9.67,0,0,1,6.84-2.75,9.82,9.82,0,0,1,6.9,2.75,8.85,8.85,0,0,1,3,6.75,10,10,0,0,1-2.83,7.07,9.57,9.57,0,0,1-13.94,0,10,10,0,0,1-2.83-7.07Zm5.46.08a4.72,4.72,0,0,0,1.23,3.22,3.91,3.91,0,0,0,3.08,1.4,4,4,0,0,0,3.13-1.41,4.71,4.71,0,0,0,1.24-3.21,3.84,3.84,0,0,0-1.31-3,4.45,4.45,0,0,0-3.06-1.19,4.24,4.24,0,0,0-3,1.22,3.91,3.91,0,0,0-1.3,3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M175.34,565.46a6.76,6.76,0,0,1,1.9-4.76,6.2,6.2,0,0,1,4.71-2.05,6.74,6.74,0,0,1,4.9,2,6.59,6.59,0,0,1,2,4.89v12.26h-5.58v-12.1a1.35,1.35,0,0,0-.32-.91,1.09,1.09,0,0,0-.88-.37,1.08,1.08,0,0,0-.88.39,1.38,1.38,0,0,0-.32.92v12.07h-5.52Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M26.33,613.75H20.7V595.07h5.63Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M29.69,601.46a6.76,6.76,0,0,1,1.9-4.76,6.19,6.19,0,0,1,4.7-2.05,6.72,6.72,0,0,1,4.9,2,6.55,6.55,0,0,1,2,4.89v12.26H37.61v-12.1a1.4,1.4,0,0,0-.32-.91,1.22,1.22,0,0,0-1.77,0,1.43,1.43,0,0,0-.32.92v12.07H29.69Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M55.05,613.75H49.48V600.39H45.23v-5.32h14v5.32h-4.2Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M71.85,606.53H68.21l0,0h0a2.5,2.5,0,0,0-.88.16c-.27.1-.41.36-.41.77a.73.73,0,0,0,.38.75,2.25,2.25,0,0,0,.82.2h.42l3.73,0v5.35H67.62a10,10,0,0,1-2.31-.25,4.74,4.74,0,0,1-2.05-1.12,5.28,5.28,0,0,1-1.77-4v-8.26a4.72,4.72,0,0,1,1.64-3.59,5.32,5.32,0,0,1,3.68-1.51l5.38,0v5.4H68.46a.05.05,0,0,1,0,0h0a1.67,1.67,0,0,0-.81.19.77.77,0,0,0-.37.73.72.72,0,0,0,.34.68,2,2,0,0,0,.73.25h3.58Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M81.26,613.75H75.72V595.07h7.67a6.86,6.86,0,0,1,4.45,1.74,5.4,5.4,0,0,1,2,4.28,6.26,6.26,0,0,1-.63,2.78,6.36,6.36,0,0,1-1.84,2.21l3.22,7.67H84.76l-2.69-6v-4.79a.28.28,0,0,0,.13,0h.15a3.52,3.52,0,0,0,1.35-.31,1.17,1.17,0,0,0,.61-1.2,1.09,1.09,0,0,0-.47-1,2.12,2.12,0,0,0-1.07-.34h-.14l-1.37,0Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M92.1,601.46A6.76,6.76,0,0,1,94,596.7a6.19,6.19,0,0,1,4.7-2.05,6.7,6.7,0,0,1,4.9,2,6.55,6.55,0,0,1,2,4.89v12.26H100v-12.1a1.4,1.4,0,0,0-.32-.91,1.22,1.22,0,0,0-1.77,0,1.43,1.43,0,0,0-.32.92v12.07H92.1Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M115.9,609.3h-1.52v4.45h-5.62v-12a7.06,7.06,0,0,1,1.87-5.11,6.74,6.74,0,0,1,5.1-2,6,6,0,0,1,4.8,2.1,7.44,7.44,0,0,1,1.81,5v12h-5.63V601.57a1.86,1.86,0,0,0-.3-.9.92.92,0,0,0-.79-.44,1,1,0,0,0-.93.46,1.9,1.9,0,0,0-.31,1v2.66h1.52Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M134.21,613.75h-5.57V600.39h-4.26v-5.32h14v5.32h-4.2Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M146.28,613.75h-5.63V595.07h5.63Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M148.91,604.12a8.88,8.88,0,0,1,2.91-6.72,9.64,9.64,0,0,1,6.83-2.75,9.82,9.82,0,0,1,6.9,2.75,8.86,8.86,0,0,1,3,6.75,10,10,0,0,1-2.83,7.07,9.59,9.59,0,0,1-14,0,10,10,0,0,1-2.82-7.07Zm5.46.08a4.72,4.72,0,0,0,1.23,3.22,3.91,3.91,0,0,0,3.08,1.4,4,4,0,0,0,3.12-1.41,4.72,4.72,0,0,0,1.25-3.21,3.81,3.81,0,0,0-1.32-3,4.42,4.42,0,0,0-3-1.19,4.24,4.24,0,0,0-3,1.22,3.87,3.87,0,0,0-1.3,3Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M170.92,601.46a6.76,6.76,0,0,1,1.9-4.76,6.19,6.19,0,0,1,4.7-2.05,6.72,6.72,0,0,1,4.9,2,6.55,6.55,0,0,1,2,4.89v12.26h-5.57v-12.1a1.4,1.4,0,0,0-.32-.91,1.22,1.22,0,0,0-1.77,0,1.43,1.43,0,0,0-.32.92v12.07h-5.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M194.71,609.3H193.2v4.45h-5.63v-12a7.06,7.06,0,0,1,1.88-5.11,6.74,6.74,0,0,1,5.1-2,6,6,0,0,1,4.8,2.1,7.43,7.43,0,0,1,1.8,5v12h-5.62V601.57a1.86,1.86,0,0,0-.3-.9.94.94,0,0,0-.8-.44,1,1,0,0,0-.92.46,1.82,1.82,0,0,0-.31,1v2.66h1.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M209.81,595.07v11.48a1.46,1.46,0,0,0,.73,1.49,4,4,0,0,0,1.67.33v5.38h-1.7a5.77,5.77,0,0,1-4.51-1.86,6.63,6.63,0,0,1-1.68-4.61V595.07Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M214.06,610.89a3.2,3.2,0,0,1,.93-2.25,2.91,2.91,0,0,1,2.21-1,3.27,3.27,0,0,1,2.36,1,3.07,3.07,0,0,1,1,2.32,3,3,0,0,1-1,2.27,3.23,3.23,0,0,1-2.3.92,3.16,3.16,0,0,1-2.27-.93,3.08,3.08,0,0,1-.95-2.26Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M237.5,613.75h-5.63V595.07h5.63Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M240.86,601.46a6.76,6.76,0,0,1,1.9-4.76,6.2,6.2,0,0,1,4.71-2.05,6.74,6.74,0,0,1,4.9,2,6.58,6.58,0,0,1,2,4.89v12.26h-5.57v-12.1a1.4,1.4,0,0,0-.32-.91,1.09,1.09,0,0,0-.88-.37,1.06,1.06,0,0,0-.88.39,1.39,1.39,0,0,0-.33.92v12.07h-5.51Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M276.33,604.79a17.32,17.32,0,0,1-.54,2.69,8.61,8.61,0,0,1-1.14,2.46,8.72,8.72,0,0,1-3.5,3.17,10.16,10.16,0,0,1-4.62,1.12,9.36,9.36,0,0,1-6.93-2.9,9.6,9.6,0,0,1-2.84-7,9.27,9.27,0,0,1,2.94-6.82,9.45,9.45,0,0,1,6.83-2.9,9.23,9.23,0,0,1,6.36,2.3,10.85,10.85,0,0,1,3.39,5.88l-5.94.36a5.14,5.14,0,0,0-1.5-1.94,3.59,3.59,0,0,0-2.33-.74,3.68,3.68,0,0,0-2.78,1.21,3.91,3.91,0,0,0-1.14,2.76,3.67,3.67,0,0,0,1.19,2.75,3.85,3.85,0,0,0,2.75,1.14,3.6,3.6,0,0,0,2.75-1.06,4.74,4.74,0,0,0,1.23-2.66Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M279.11,610.89a3.19,3.19,0,0,1,.92-2.25,2.92,2.92,0,0,1,2.21-1,3.29,3.29,0,0,1,2.37,1,3.09,3.09,0,0,1,1,2.32,3,3,0,0,1-1,2.27,3.21,3.21,0,0,1-2.29.92,3.13,3.13,0,0,1-2.27-.93,3,3,0,0,1-.95-2.26Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M295,591.91a6.53,6.53,0,0,1,4.83,2.07,6.75,6.75,0,0,1,2,4.87,6.67,6.67,0,0,1-2,4.83,6.51,6.51,0,0,1-4.83,2,6.68,6.68,0,0,1-4.88-2,6.57,6.57,0,0,1-2-4.86,6.93,6.93,0,0,1,6.92-6.92Zm-3.66,3.19a5.36,5.36,0,0,0,0,7.42A4.87,4.87,0,0,0,295,604.1a4.68,4.68,0,0,0,3.6-1.59,5.38,5.38,0,0,0,0-7.38,4.7,4.7,0,0,0-3.59-1.6A4.89,4.89,0,0,0,291.38,595.1Zm3.94-.18a.41.41,0,0,0,.16,0h.14a3.8,3.8,0,0,1,1.73.52,1.67,1.67,0,0,1,.78,1.58,2.1,2.1,0,0,1-.3,1.11,2,2,0,0,1-.89.76,1.4,1.4,0,0,1,.85.88,6.39,6.39,0,0,1,.24,1.3s0,.08,0,.13a.61.61,0,0,1,0,.14c0,.2,0,.4.05.59a3.5,3.5,0,0,0,.09.52l-2.16,0a1.83,1.83,0,0,1-.14-.45l-.06-.54v-.2a3.81,3.81,0,0,0-.23-1.18c-.14-.35-.53-.52-1.19-.52v2.89h-2.28v-7.53Zm-.91,1.37v2a2.55,2.55,0,0,0,1.06-.14c.27-.1.4-.4.4-.89s-.13-.66-.39-.76a2.5,2.5,0,0,0-.88-.15h-.19Z" transform="translate(-4 -5.3)"/>
    </g>
    <g>
      <path class="cls-1" d="M187.48,453.57l-5.27,6.36c-.05.07-.11.13-.16.19a1.08,1.08,0,0,0-.11.18,2.24,2.24,0,0,0-.35.81.83.83,0,0,0,.37.81.74.74,0,0,0,.72.18,1.79,1.79,0,0,0,.67-.38l.22-.24a1.9,1.9,0,0,0,.2-.22l5.29-6.38,4.55,3.77L188.35,465l-.16.19a.58.58,0,0,0-.11.18,2.32,2.32,0,0,0-.37.8.79.79,0,0,0,.36.8.71.71,0,0,0,.72.18,1.79,1.79,0,0,0,.67-.38l.22-.24a1.88,1.88,0,0,0,.2-.21l5.29-6.39,4.53,3.75-6.4,7.72a6,6,0,0,1-4.16,2.21,6.13,6.13,0,0,1-6.17-3.46,8.52,8.52,0,0,1-.65-2.64,9,9,0,0,1-2.6-.11,5.12,5.12,0,0,1-2.34-1.14,5.55,5.55,0,0,1-2.29-4.61,7.91,7.91,0,0,1,1.94-4.84l5.85-7.06Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M206.34,469.19l-5.27,6.36-.16.19a.58.58,0,0,0-.11.18,1.94,1.94,0,0,0-.35.81.82.82,0,0,0,.36.81.74.74,0,0,0,.73.18,1.87,1.87,0,0,0,.67-.38l.22-.24.2-.21,5.29-6.39,4.55,3.77-5.27,6.36-.16.19a1.74,1.74,0,0,0-.11.18,2.3,2.3,0,0,0-.36.8.78.78,0,0,0,.35.8.74.74,0,0,0,.73.18,1.87,1.87,0,0,0,.67-.38l.22-.24c.08-.07.14-.15.2-.21l5.29-6.39,4.52,3.75L212.16,487a6,6,0,0,1-4.16,2.21,6.1,6.1,0,0,1-6.17-3.46,8.25,8.25,0,0,1-.65-2.64,9,9,0,0,1-2.6-.11,5.12,5.12,0,0,1-2.34-1.14,5.53,5.53,0,0,1-2.29-4.61,7.9,7.9,0,0,1,1.93-4.84l5.85-7.06Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M225.19,484.81l-5.27,6.36-.15.19a.58.58,0,0,0-.11.18,2.1,2.1,0,0,0-.36.81.83.83,0,0,0,.37.81.71.71,0,0,0,.72.18,1.79,1.79,0,0,0,.67-.38l.23-.24.2-.21,5.29-6.39,4.55,3.77-5.27,6.36-.16.19a.58.58,0,0,0-.11.18,2.32,2.32,0,0,0-.37.8.8.8,0,0,0,.36.8.71.71,0,0,0,.72.18,1.79,1.79,0,0,0,.67-.38l.22-.24a1.29,1.29,0,0,0,.2-.21l5.29-6.39,4.53,3.75L231,502.65a6,6,0,0,1-4.17,2.21,6.1,6.1,0,0,1-6.16-3.46,8.12,8.12,0,0,1-.66-2.64,8.94,8.94,0,0,1-2.59-.11,5.11,5.11,0,0,1-2.35-1.14,5.58,5.58,0,0,1-2.29-4.61,8,8,0,0,1,1.94-4.84l5.85-7.06Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M232.05,506.52a3.76,3.76,0,0,1,2.51-1.34,3.38,3.38,0,0,1,2.72.75,3.87,3.87,0,0,1,1.42,2.62,3.61,3.61,0,0,1-.84,2.82,3.47,3.47,0,0,1-2.56,1.31,3.72,3.72,0,0,1-2.75-.88,3.6,3.6,0,0,1-1.34-2.53,3.55,3.55,0,0,1,.82-2.73Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M248.9,517.08,245,521.81c-.23-.13-.45-.27-.67-.42s-.42-.31-.62-.48a7.53,7.53,0,0,1-2.84-5.68,8.5,8.5,0,0,1,2-6.06,7.46,7.46,0,0,1,5.55-2.79,8.21,8.21,0,0,1,5.93,1.87,8.11,8.11,0,0,1,3,5.6,7.81,7.81,0,0,1-1.81,6L250.39,526l-4.7-3.89,4.69-5.66a2.86,2.86,0,0,0,.66-1.69,1.93,1.93,0,0,0-.77-1.66,2.3,2.3,0,0,0-1.63-.52,2,2,0,0,0-1.52.75,2.19,2.19,0,0,0-.53,1.6,2.22,2.22,0,0,0,.73,1.53,2.54,2.54,0,0,0,.73.44,2.79,2.79,0,0,0,.83.18Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M264.65,537.84l5.24-6.33.13-.15a1.64,1.64,0,0,0,.14-.22,2.09,2.09,0,0,0,.36-.79.78.78,0,0,0-.34-.77l0,0a.89.89,0,0,0-.85-.12,2,2,0,0,0-.73.5.52.52,0,0,0-.16.14l-.14.16-5.21,6.29-4.55-3.77,5.25-6.33.11-.13.12-.15a2.17,2.17,0,0,0,.38-.85.77.77,0,0,0-.33-.8l0,0a.87.87,0,0,0-.84-.12,2,2,0,0,0-.68.47,1.45,1.45,0,0,0-.18.16c-.05.06-.1.13-.16.19L257,531.47l-4.52-3.75L258.8,520a6.07,6.07,0,0,1,4.16-2.18,5.76,5.76,0,0,1,4.5,1.3,5.48,5.48,0,0,1,1.66,2.16,9,9,0,0,1,.62,2.65,10,10,0,0,1,2.62.1,4.93,4.93,0,0,1,2.37,1.11A5.55,5.55,0,0,1,277,529.8a8.19,8.19,0,0,1-2,4.87l-5.79,7Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M288.2,547.44a8.43,8.43,0,0,1-5.62,2.74,8.06,8.06,0,0,1-5.92-2,7.59,7.59,0,0,1-2.84-5.64,8.33,8.33,0,0,1,1.93-6,8.32,8.32,0,0,1,11.45-1.07,8.42,8.42,0,0,1,2.9,4.8,7.6,7.6,0,0,1-.75,5.54l-4.76-3.72a2.82,2.82,0,0,0-.16-1.16,2.26,2.26,0,0,0-.71-1,2.21,2.21,0,0,0-1.72-.53,2.24,2.24,0,0,0-1.59.84,2.16,2.16,0,0,0-.48,1.68,2.26,2.26,0,0,0,.81,1.56,2.1,2.1,0,0,0,1.44.53,3.13,3.13,0,0,0,1.47-.41Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M295.31,555.53l-3.91,4.73c-.23-.13-.45-.27-.67-.42s-.42-.31-.62-.48a7.53,7.53,0,0,1-2.84-5.68,8.48,8.48,0,0,1,2-6.06,7.5,7.5,0,0,1,5.55-2.8,8.22,8.22,0,0,1,5.93,1.88,8.11,8.11,0,0,1,3,5.6,7.81,7.81,0,0,1-1.81,6.05l-5.08,6.13-4.7-3.89,4.69-5.66a2.87,2.87,0,0,0,.66-1.7,1.92,1.92,0,0,0-.77-1.65,2.26,2.26,0,0,0-1.63-.52,2,2,0,0,0-1.52.75,2.19,2.19,0,0,0-.53,1.6,2.27,2.27,0,0,0,.73,1.53,2.79,2.79,0,0,0,.73.44,2.86,2.86,0,0,0,.83.18Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M301.44,564a3.75,3.75,0,0,1,2.51-1.33,3.38,3.38,0,0,1,2.72.75,3.88,3.88,0,0,1,1.42,2.61,3.62,3.62,0,0,1-.84,2.83,3.5,3.5,0,0,1-2.56,1.31,3.81,3.81,0,0,1-2.75-.88,3.62,3.62,0,0,1-1.34-2.53,3.55,3.55,0,0,1,.82-2.73Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M312.11,566.73a8.24,8.24,0,0,1,11.49-1.07,8.26,8.26,0,0,1-10.54,12.72,7.72,7.72,0,0,1-2.86-5.61,8.17,8.17,0,0,1,1.89-6Zm4.69,3.85a2.43,2.43,0,0,0-.57,1.67,2,2,0,0,0,.73,1.52,2.23,2.23,0,0,0,1.63.52,2,2,0,0,0,1.5-.77,2.07,2.07,0,0,0,.49-1.66,2.34,2.34,0,0,0-.83-1.51,2.06,2.06,0,0,0-1.54-.47,2,2,0,0,0-1.43.73Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M338,577.76l-4.08,4.92a.2.2,0,0,1-.08-.1.17.17,0,0,0-.06-.09,1.22,1.22,0,0,0-.2-.34l-.29-.29a1.44,1.44,0,0,0-1.32-.33,2.34,2.34,0,0,0-1.17.77l-5.06,6.11L321,584.54l6.1-7.37a6.31,6.31,0,0,1,4.54-2.28,6.62,6.62,0,0,1,4.9,1.49q.38.32.72.66c.23.24.45.48.66.74Z" transform="translate(-4 -5.3)"/>
      <path class="cls-1" d="M339.18,591.07l-3.5,4.22a5.72,5.72,0,0,1-1.18-.49,6.45,6.45,0,0,1-1-.72,6,6,0,0,1-2.39-5,8.42,8.42,0,0,1,2-5.28,7.46,7.46,0,0,1,5.45-2.73,7.82,7.82,0,0,1,5.82,1.77,8.21,8.21,0,0,1,3,5.57,7.67,7.67,0,0,1-1.79,6l-4.14,5a8.52,8.52,0,0,1-6.06,3.12,8.65,8.65,0,0,1-6.52-2,6.15,6.15,0,0,1-1-1.07,13.53,13.53,0,0,1-.88-1.27l3.46-4.18a6.27,6.27,0,0,0,.51,1,3.23,3.23,0,0,0,.73.83,3.73,3.73,0,0,0,2.63.89,3.19,3.19,0,0,0,2.46-1.2l4.19-5.05a3.11,3.11,0,0,0,.71-1.66,1.84,1.84,0,0,0-.79-1.63,2,2,0,0,0-1.58-.44,2.07,2.07,0,0,0-1.47.75,2.13,2.13,0,0,0-.51,1.59,2,2,0,0,0,.77,1.47,1.35,1.35,0,0,0,.35.22l.36.15.2.07.23,0Z" transform="translate(-4 -5.3)"/>
    </g>
  </g>
  <g id="Right_Text" data-name="Right Text">
    <g *ngIf="seal === 'C'" id="CIRCULATING">
      <path d="M200.32,286.82a22.47,22.47,0,0,1-.68,3.36,11.48,11.48,0,0,1-1.42,3.08,10.84,10.84,0,0,1-4.37,3.95,12.7,12.7,0,0,1-5.78,1.4,11.68,11.68,0,0,1-8.66-3.62,12,12,0,0,1-3.55-8.7,11.57,11.57,0,0,1,3.67-8.52,11.78,11.78,0,0,1,8.54-3.62,11.49,11.49,0,0,1,8,2.87,13.62,13.62,0,0,1,4.23,7.35l-7.42.45a6.65,6.65,0,0,0-1.87-2.43,4.56,4.56,0,0,0-2.92-.93,4.64,4.64,0,0,0-3.47,1.51,4.88,4.88,0,0,0-1.43,3.46,4.59,4.59,0,0,0,1.48,3.43,4.8,4.8,0,0,0,3.45,1.44A4.52,4.52,0,0,0,191.5,290a5.93,5.93,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M211.56,298h-7V274.67h7Z" transform="translate(-4 -5.3)"/>
      <path d="M223.84,298h-6.93V274.67h9.59a8.64,8.64,0,0,1,5.57,2.17,6.72,6.72,0,0,1,2.51,5.36,7.83,7.83,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.31l-3.36-7.53v-6a.33.33,0,0,0,.15,0h.2a4.49,4.49,0,0,0,1.68-.38,1.5,1.5,0,0,0,.77-1.51,1.33,1.33,0,0,0-.6-1.22,2.58,2.58,0,0,0-1.33-.42h-.17l-1.72,0Z" transform="translate(-4 -5.3)"/>
      <path d="M261.85,286.82a22.47,22.47,0,0,1-.68,3.36,11.48,11.48,0,0,1-1.42,3.08,10.84,10.84,0,0,1-4.37,3.95,12.7,12.7,0,0,1-5.78,1.4,11.68,11.68,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.58,11.58,0,0,1,3.68-8.52,11.78,11.78,0,0,1,8.54-3.62,11.45,11.45,0,0,1,7.94,2.87,13.57,13.57,0,0,1,4.24,7.35l-7.42.45a6.65,6.65,0,0,0-1.87-2.43,4.57,4.57,0,0,0-2.93-.93A4.63,4.63,0,0,0,246.1,283a4.88,4.88,0,0,0-1.43,3.46,4.59,4.59,0,0,0,1.48,3.43,4.8,4.8,0,0,0,3.45,1.44A4.52,4.52,0,0,0,253,290a5.93,5.93,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M282.67,290.06a8.6,8.6,0,0,1-2.3,6.05,7.7,7.7,0,0,1-5.92,2.5,8.47,8.47,0,0,1-6.14-2.43,8.17,8.17,0,0,1-2.51-6.09V274.67h7v15.12a1.75,1.75,0,0,0,.42,1.19,1.39,1.39,0,0,0,1.12.49,1.27,1.27,0,0,0,1.08-.49,1.78,1.78,0,0,0,.39-1.15V274.67h6.89Z" transform="translate(-4 -5.3)"/>
      <path d="M294.43,274.67V289a1.84,1.84,0,0,0,.91,1.86,5,5,0,0,0,2.1.42V298h-2.13a7.23,7.23,0,0,1-5.64-2.33,8.28,8.28,0,0,1-2.1-5.76V274.67Z" transform="translate(-4 -5.3)"/>
      <path d="M309.17,292.45h-1.89V298h-7V283a8.83,8.83,0,0,1,2.35-6.39,8.44,8.44,0,0,1,6.37-2.43,7.5,7.5,0,0,1,6,2.62,9.23,9.23,0,0,1,2.26,6.23v15h-7V282.79a2.31,2.31,0,0,0-.36-1.12,1.19,1.19,0,0,0-1-.56,1.3,1.3,0,0,0-1.16.58,2.34,2.34,0,0,0-.38,1.31v3.33h1.89Z" transform="translate(-4 -5.3)"/>
      <path d="M333,298h-7v-16.7h-5.32v-6.65h17.53v6.65H333Z" transform="translate(-4 -5.3)"/>
      <path d="M349,298h-7V274.67h7Z" transform="translate(-4 -5.3)"/>
      <path d="M354.18,282.65a8.46,8.46,0,0,1,2.38-5.95,7.7,7.7,0,0,1,5.88-2.55,8.39,8.39,0,0,1,6.12,2.43,8.19,8.19,0,0,1,2.49,6.11V298h-7V282.9a1.67,1.67,0,0,0-.4-1.14,1.36,1.36,0,0,0-1.1-.47,1.32,1.32,0,0,0-1.1.49,1.74,1.74,0,0,0-.41,1.15V298h-6.89Z" transform="translate(-4 -5.3)"/>
      <path d="M392.92,285a12.22,12.22,0,0,1,1.51,2.56,7.3,7.3,0,0,1,.59,2.9,7.57,7.57,0,0,1-2.55,5.92,8.9,8.9,0,0,1-6.09,2.27,10,10,0,0,1-8.26-3.95,13.82,13.82,0,0,1-3.12-8.75,11.27,11.27,0,0,1,3.5-8.32,11.46,11.46,0,0,1,8.3-3.44,9.72,9.72,0,0,1,2,.19,16.55,16.55,0,0,1,1.89.51v6.86l-.23-.09-.23-.09a8.89,8.89,0,0,0-1.2-.4,4.93,4.93,0,0,0-1.28-.16,5.07,5.07,0,0,0-3.73,1.58,5.55,5.55,0,0,0-1.66,3.73,6.23,6.23,0,0,0,1.38,4,4.63,4.63,0,0,0,3.83,1.72h.23l.26,0a1.68,1.68,0,0,0,.72-.25.73.73,0,0,0,.33-.66.62.62,0,0,0-.35-.65,1.77,1.77,0,0,0-.77-.16h-1.64V285Z" transform="translate(-4 -5.3)"/>
      <path d="M314.63,340.23h-4.15V351H298.27V324.54a15.35,15.35,0,0,1,4.61-11.06A14.89,14.89,0,0,1,314,308.73a14.49,14.49,0,0,1,7,1.74,17.41,17.41,0,0,1,5.49,4.71l-10.11,7.32a5.19,5.19,0,0,0-1-1.62,2.29,2.29,0,0,0-1.71-.68,2.84,2.84,0,0,0-2.35,1,3.81,3.81,0,0,0-.81,2.48v5.46h4.15Z" transform="translate(-4 -5.3)"/>
      <path d="M343.15,341.16H339.8V351H327.34V324.36q0-7,4.16-11.32t11.28-4.31a13.31,13.31,0,0,1,10.63,4.65,16.36,16.36,0,0,1,4,11V351H345v-27a4.12,4.12,0,0,0-.65-2,2.08,2.08,0,0,0-1.77-1,2.28,2.28,0,0,0-2,1,4.09,4.09,0,0,0-.69,2.33v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M363.67,323.8a15,15,0,0,1,4.22-10.54,13.66,13.66,0,0,1,10.42-4.53A14.93,14.93,0,0,1,389.16,313a14.54,14.54,0,0,1,4.4,10.82V351H381.22V324.23a3,3,0,0,0-.71-2,2.44,2.44,0,0,0-2-.84,2.4,2.4,0,0,0-1.95.87,3.15,3.15,0,0,0-.71,2V351H363.67Z" transform="translate(-4 -5.3)"/>
      <path d="M160.88,371.84a2.62,2.62,0,0,0,1.43-.31,1.3,1.3,0,0,0,.63-1.23,1.72,1.72,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.49,2.49,0,0,0-.45,1.23V386h-7V371c0-.52,0-1,.07-1.56a5.5,5.5,0,0,1,.39-1.56,8.79,8.79,0,0,1,3.23-4.15,8.47,8.47,0,0,1,5-1.62,9.14,9.14,0,0,1,6.14,2.39,7.58,7.58,0,0,1,2.75,5.94,8,8,0,0,1-.7,3.29,7.07,7.07,0,0,1-2.1,2.7l-.26.19-.23.16a6.63,6.63,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M187.05,377H182.5l-.05,0h0a3.26,3.26,0,0,0-1.1.19c-.34.13-.51.45-.51,1a.92.92,0,0,0,.47.94,3.18,3.18,0,0,0,1,.25h.53l4.65,0V386h-5.7a12.67,12.67,0,0,1-2.89-.32,5.85,5.85,0,0,1-2.57-1.4,6.7,6.7,0,0,1-1.63-2.24,6.61,6.61,0,0,1-.58-2.69V369a5.94,5.94,0,0,1,2.05-4.49,6.66,6.66,0,0,1,4.6-1.88l6.72,0v6.75h-4.65s0,0,0,0h-.06a2.12,2.12,0,0,0-1,.24,1,1,0,0,0-.46.91.89.89,0,0,0,.42.85,2.65,2.65,0,0,0,.91.31h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M199.76,386h-6.93V362.67h9.59a8.61,8.61,0,0,1,5.56,2.17,6.73,6.73,0,0,1,2.52,5.36,7.83,7.83,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32l-3.36-7.53v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.48,1.48,0,0,0,.77-1.51,1.33,1.33,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M221.39,379.93H219V386h-6.89V371.07a8.68,8.68,0,0,1,2.61-6.25,8.42,8.42,0,0,1,6.24-2.67,8.15,8.15,0,0,1,4,1,9.69,9.69,0,0,1,3.09,2.66l-5.7,4.13a2.68,2.68,0,0,0-.58-.91,1.24,1.24,0,0,0-1-.39,1.62,1.62,0,0,0-1.33.56,2.16,2.16,0,0,0-.46,1.4v3.08h2.35Z" transform="translate(-4 -5.3)"/>
      <path d="M229,374a11.06,11.06,0,0,1,3.64-8.4,12,12,0,0,1,8.54-3.43,12.28,12.28,0,0,1,8.63,3.43,11,11,0,0,1,3.69,8.43,12.48,12.48,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0A12.51,12.51,0,0,1,229,374Zm6.82.1a6,6,0,0,0,1.54,4,5.16,5.16,0,0,0,7.76,0,5.93,5.93,0,0,0,1.55-4,4.75,4.75,0,0,0-1.64-3.69,5.54,5.54,0,0,0-3.82-1.49,5.37,5.37,0,0,0-3.76,1.52,4.91,4.91,0,0,0-1.63,3.7Z" transform="translate(-4 -5.3)"/>
      <path d="M264.61,386h-6.93V362.67h9.59a8.64,8.64,0,0,1,5.57,2.17,6.73,6.73,0,0,1,2.52,5.36,7.73,7.73,0,0,1-3.08,6.23l4,9.59H269l-3.36-7.53v-6a.38.38,0,0,0,.16,0H266a4.44,4.44,0,0,0,1.68-.38,1.5,1.5,0,0,0,.77-1.51,1.33,1.33,0,0,0-.6-1.22,2.55,2.55,0,0,0-1.33-.42h-.17l-1.72,0Z" transform="translate(-4 -5.3)"/>
      <path d="M279.1,368.52a6,6,0,0,1,2-4.47,6.41,6.41,0,0,1,4.55-1.9,6.34,6.34,0,0,1,5.74,3.29,6.11,6.11,0,0,1,2.22-2.3,6.66,6.66,0,0,1,3.06-1H297a6.06,6.06,0,0,1,5,2.18,8.27,8.27,0,0,1,1.73,5.27V386h-6.86V370.48a1.83,1.83,0,0,0-.25-.79.73.73,0,0,0-.7-.37.86.86,0,0,0-.77.37,2.09,2.09,0,0,0-.31.82V386H288V370.48a1.82,1.82,0,0,0-.24-.79.73.73,0,0,0-.7-.37.87.87,0,0,0-.81.42,1.82,1.82,0,0,0-.24.91V386h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M317.6,380.45h-1.89V386h-7V371a8.83,8.83,0,0,1,2.34-6.39,8.45,8.45,0,0,1,6.37-2.43,7.54,7.54,0,0,1,6,2.62,9.27,9.27,0,0,1,2.25,6.23v15h-7V370.79a2.44,2.44,0,0,0-.37-1.12,1.17,1.17,0,0,0-1-.56,1.27,1.27,0,0,0-1.15.58,2.27,2.27,0,0,0-.39,1.31v3.33h1.89Z" transform="translate(-4 -5.3)"/>
      <path d="M330.55,370.65a8.5,8.5,0,0,1,2.38-5.95,7.73,7.73,0,0,1,5.88-2.55,8.43,8.43,0,0,1,6.13,2.43,8.22,8.22,0,0,1,2.48,6.11V386h-7V370.9a1.72,1.72,0,0,0-.4-1.14,1.37,1.37,0,0,0-1.11-.47,1.35,1.35,0,0,0-1.1.49,1.73,1.73,0,0,0-.4,1.15V386h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M375.84,374.82a21.28,21.28,0,0,1-.68,3.36,11.18,11.18,0,0,1-1.42,3.08,10.84,10.84,0,0,1-4.37,3.95,12.7,12.7,0,0,1-5.78,1.4,11.7,11.7,0,0,1-8.66-3.62,12,12,0,0,1-3.55-8.7,11.57,11.57,0,0,1,3.67-8.52,11.78,11.78,0,0,1,8.54-3.62,11.49,11.49,0,0,1,8,2.87,13.56,13.56,0,0,1,4.23,7.35l-7.42.45a6.54,6.54,0,0,0-1.87-2.43,4.56,4.56,0,0,0-2.92-.93,4.64,4.64,0,0,0-3.47,1.51,4.93,4.93,0,0,0-1.43,3.46,4.56,4.56,0,0,0,1.49,3.43,4.8,4.8,0,0,0,3.44,1.44A4.52,4.52,0,0,0,367,378a5.87,5.87,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M393,377h-4.55l-.05,0h-.05a3.21,3.21,0,0,0-1.1.19c-.34.13-.51.45-.51,1a.92.92,0,0,0,.47.94,3.18,3.18,0,0,0,1,.25h.53l4.65,0V386h-5.7a12.67,12.67,0,0,1-2.89-.32,5.8,5.8,0,0,1-2.57-1.4,6.7,6.7,0,0,1-1.63-2.24,6.61,6.61,0,0,1-.58-2.69V369a5.94,5.94,0,0,1,2-4.49,6.66,6.66,0,0,1,4.6-1.88l6.72,0v6.75h-4.65l-.05,0h-.06a2.12,2.12,0,0,0-1,.24.93.93,0,0,0-.46.91.89.89,0,0,0,.42.85,2.65,2.65,0,0,0,.91.31H393Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'IF'" id="INDUCED_SOUND_AIR_FEI">
      <path d="M280.15,161.8v8.51l-.74-.2a2.43,2.43,0,0,0-.74-.12,2.32,2.32,0,0,0-1.76.79,2.58,2.58,0,0,0-.72,1.78,4,4,0,0,0,.52,1.78l1.15,2.24a26.33,26.33,0,0,1,1.15,2.68,8.84,8.84,0,0,1,.51,3,9.6,9.6,0,0,1-3.28,7.46,11.15,11.15,0,0,1-7.7,2.95,7.83,7.83,0,0,1-2-.27,14.13,14.13,0,0,1-2-.67V183a5,5,0,0,0,1.1.56,3.29,3.29,0,0,0,1.15.21h.36a3.23,3.23,0,0,0,1.64-.77,2,2,0,0,0,.74-1.58,3.83,3.83,0,0,0-.49-1.69c-.33-.65-.71-1.38-1.13-2.19a25.79,25.79,0,0,1-1.1-2.73,10.3,10.3,0,0,1,2.48-10.5,9.69,9.69,0,0,1,7.22-3.07,10.65,10.65,0,0,1,1.87.16,9.61,9.61,0,0,1,1.77.47Z" transform="translate(-4 -5.3)"/>
      <path d="M281.68,176.43a14.27,14.27,0,0,1,4.68-10.8,15.49,15.49,0,0,1,11-4.41,15.81,15.81,0,0,1,11.1,4.41,14.22,14.22,0,0,1,4.74,10.84,16.08,16.08,0,0,1-4.54,11.37,15.41,15.41,0,0,1-22.41,0,16.09,16.09,0,0,1-4.55-11.37Zm8.78.13a7.62,7.62,0,0,0,2,5.18,6.32,6.32,0,0,0,4.95,2.25,6.43,6.43,0,0,0,5-2.27,7.63,7.63,0,0,0,2-5.16,6.12,6.12,0,0,0-2.12-4.74,7.06,7.06,0,0,0-4.9-1.92,6.91,6.91,0,0,0-4.84,2,6.29,6.29,0,0,0-2.09,4.75Z" transform="translate(-4 -5.3)"/>
      <path d="M336.94,181.68a11.06,11.06,0,0,1-3,7.77,9.86,9.86,0,0,1-7.6,3.22,10.89,10.89,0,0,1-7.9-3.13,10.48,10.48,0,0,1-3.22-7.82V161.89h9v19.44a2.27,2.27,0,0,0,.54,1.53,1.8,1.8,0,0,0,1.44.63,1.66,1.66,0,0,0,1.39-.63,2.32,2.32,0,0,0,.5-1.48V161.89h8.86Z" transform="translate(-4 -5.3)"/>
      <path d="M340.23,172.15a10.89,10.89,0,0,1,3.06-7.65,9.92,9.92,0,0,1,7.56-3.28,10.79,10.79,0,0,1,7.87,3.13,10.52,10.52,0,0,1,3.2,7.85v19.71h-9V172.47a2.18,2.18,0,0,0-.51-1.46,1.94,1.94,0,0,0-2.84,0,2.28,2.28,0,0,0-.52,1.48v19.4h-8.86Z" transform="translate(-4 -5.3)"/>
      <path d="M374.52,191.91h-9v-30h13.41q1.31,0,2.61.18a10.92,10.92,0,0,1,2.56.59,15.65,15.65,0,0,1,7.22,5.36,13.57,13.57,0,0,1,2.95,8.48,15.06,15.06,0,0,1-4.43,11.15,15.34,15.34,0,0,1-11.14,4.44H378a5.6,5.6,0,0,1-.69-.05c-.24,0-.48,0-.7,0l-.7,0v-8.82h2.3a7.71,7.71,0,0,0,4.9-1.55,5.45,5.45,0,0,0,2.12-4.62,5.75,5.75,0,0,0-1.87-4.36,6.22,6.22,0,0,0-4.39-1.76l-4.45,0Z" transform="translate(-4 -5.3)"/>
      <path d="M363,205.73h-1.08v3.18h-4v-8.6a5,5,0,0,1,1.34-3.65,4.79,4.79,0,0,1,3.64-1.39,4.31,4.31,0,0,1,3.43,1.5,5.29,5.29,0,0,1,1.29,3.56v8.58h-4v-8.7a1.47,1.47,0,0,0-.21-.64.68.68,0,0,0-.57-.32.73.73,0,0,0-.66.33,1.3,1.3,0,0,0-.22.75v1.9H363Z" transform="translate(-4 -5.3)"/>
      <path d="M370.08,200.13a4.82,4.82,0,0,1,1.36-3.4,4.4,4.4,0,0,1,3.36-1.46,4.81,4.81,0,0,1,3.5,1.39,4.67,4.67,0,0,1,1.42,3.49v8.76h-4v-8.64a1,1,0,0,0-.23-.65.8.8,0,0,0-.63-.27.75.75,0,0,0-.63.28,1,1,0,0,0-.23.66v8.62h-3.94Z" transform="translate(-4 -5.3)"/>
      <path d="M386.32,208.91h-4V195.57h6c.38,0,.77,0,1.16.08a5.21,5.21,0,0,1,1.14.26,7,7,0,0,1,3.21,2.38,6.06,6.06,0,0,1,1.31,3.77,6.72,6.72,0,0,1-2,5,6.83,6.83,0,0,1-4.95,2h-.31l-.31,0h-.62V205h1a3.48,3.48,0,0,0,2.18-.69,2.43,2.43,0,0,0,.94-2.05,2.57,2.57,0,0,0-.83-1.94,2.78,2.78,0,0,0-1.95-.78l-2,0Z" transform="translate(-4 -5.3)"/>
      <path d="M342.43,237.75H340v7.16h-9V225.56a11.32,11.32,0,0,1,3-8.21,10.83,10.83,0,0,1,8.19-3.13,9.67,9.67,0,0,1,7.72,3.37,11.86,11.86,0,0,1,2.9,8v19.31h-9V225.33a3,3,0,0,0-.47-1.44,1.54,1.54,0,0,0-1.29-.72,1.65,1.65,0,0,0-1.48.75,2.94,2.94,0,0,0-.5,1.68v4.28h2.43Z" transform="translate(-4 -5.3)"/>
      <path d="M366.69,244.91h-9.05v-30h9.05Z" transform="translate(-4 -5.3)"/>
      <path d="M380.73,244.91h-8.91v-30h12.33a11,11,0,0,1,7.15,2.79,8.66,8.66,0,0,1,3.24,6.89,9.89,9.89,0,0,1-4,8l5.18,12.33h-9.41L382,235.23v-7.69a.46.46,0,0,0,.21,0h.24a5.69,5.69,0,0,0,2.16-.49,1.91,1.91,0,0,0,1-1.94,1.73,1.73,0,0,0-.76-1.57,3.39,3.39,0,0,0-1.71-.54h-.23l-2.2,0Z" transform="translate(-4 -5.3)"/>
      <path d="M273.74,252.81a1.57,1.57,0,0,0,.82-.18.73.73,0,0,0,.36-.7,1,1,0,0,0-.26-.67A.85.85,0,0,0,274,251a.74.74,0,0,0-.64.32,1.49,1.49,0,0,0-.26.7v8.92h-4v-8.56c0-.29,0-.59,0-.89a3.14,3.14,0,0,1,.22-.89,5,5,0,0,1,1.85-2.37,4.81,4.81,0,0,1,2.85-.93,5.23,5.23,0,0,1,3.51,1.37,4.3,4.3,0,0,1,1.57,3.39,4.54,4.54,0,0,1-.4,1.88,4.08,4.08,0,0,1-1.2,1.54l-.15.11-.13.09a3.69,3.69,0,0,1-1.32.54,7.91,7.91,0,0,1-1.42.12h-.8Z" transform="translate(-4 -5.3)"/>
      <path d="M287.48,255.75h-2.6l0,0h0a1.81,1.81,0,0,0-.63.11c-.19.07-.29.26-.29.55a.53.53,0,0,0,.27.54,1.65,1.65,0,0,0,.59.14h.3l2.66,0v3.82h-3.26a7.19,7.19,0,0,1-1.65-.18,3.45,3.45,0,0,1-1.47-.8,3.8,3.8,0,0,1-.93-1.28,3.74,3.74,0,0,1-.33-1.54v-5.9a3.35,3.35,0,0,1,1.17-2.56,3.79,3.79,0,0,1,2.63-1.08l3.84,0v3.86h-2.66l0,0h0a1.24,1.24,0,0,0-.58.14.54.54,0,0,0-.26.52.5.5,0,0,0,.24.48,1.34,1.34,0,0,0,.52.18h2.56Z" transform="translate(-4 -5.3)"/>
      <path d="M293.52,260.91h-4V247.57H295a4.9,4.9,0,0,1,3.18,1.24,3.84,3.84,0,0,1,1.44,3.06,4.43,4.43,0,0,1-.45,2,4.5,4.5,0,0,1-1.31,1.58l2.3,5.48H296l-1.92-4.3v-3.42l.09,0h.11a2.55,2.55,0,0,0,1-.22.85.85,0,0,0,.44-.86.79.79,0,0,0-.34-.7,1.45,1.45,0,0,0-.76-.24h-.1l-1,0Z" transform="translate(-4 -5.3)"/>
      <path d="M304.66,257.43h-1.34v3.48h-3.94v-8.54a5,5,0,0,1,1.49-3.57,4.8,4.8,0,0,1,3.57-1.53,4.71,4.71,0,0,1,2.27.56,5.48,5.48,0,0,1,1.77,1.52l-3.26,2.36a1.65,1.65,0,0,0-.33-.52.71.71,0,0,0-.55-.22.92.92,0,0,0-.76.32,1.24,1.24,0,0,0-.26.8v1.76h1.34Z" transform="translate(-4 -5.3)"/>
      <path d="M307.8,254a6.34,6.34,0,0,1,2.08-4.8,6.86,6.86,0,0,1,4.88-2,7,7,0,0,1,4.93,2,6.34,6.34,0,0,1,2.11,4.82,7.12,7.12,0,0,1-2,5.05,6.84,6.84,0,0,1-10,0,7.12,7.12,0,0,1-2-5.05Zm3.9.06a3.36,3.36,0,0,0,.88,2.3,2.8,2.8,0,0,0,2.2,1,2.86,2.86,0,0,0,2.23-1,3.39,3.39,0,0,0,.89-2.29A2.76,2.76,0,0,0,317,252a3.15,3.15,0,0,0-2.18-.85,3,3,0,0,0-2.15.87,2.78,2.78,0,0,0-.93,2.11Z" transform="translate(-4 -5.3)"/>
      <path d="M326.92,260.91h-4V247.57h5.48a4.9,4.9,0,0,1,3.18,1.24,3.84,3.84,0,0,1,1.44,3.06,4.43,4.43,0,0,1-.45,2,4.5,4.5,0,0,1-1.31,1.58l2.3,5.48h-4.18l-1.92-4.3v-3.42l.09,0h.11a2.55,2.55,0,0,0,1-.22.85.85,0,0,0,.44-.86.77.77,0,0,0-.34-.7,1.45,1.45,0,0,0-.76-.24h-.1l-1,0Z" transform="translate(-4 -5.3)"/>
      <path d="M334,250.91a3.43,3.43,0,0,1,1.14-2.55,3.65,3.65,0,0,1,2.6-1.09,3.76,3.76,0,0,1,1.91.48,3.84,3.84,0,0,1,1.37,1.4,3.46,3.46,0,0,1,1.27-1.31,3.74,3.74,0,0,1,1.75-.57h.2a3.46,3.46,0,0,1,2.87,1.25,4.7,4.7,0,0,1,1,3v9.38h-3.92V252a1.18,1.18,0,0,0-.14-.45.43.43,0,0,0-.4-.21.49.49,0,0,0-.44.21,1,1,0,0,0-.18.47v8.86h-3.94V252a1.18,1.18,0,0,0-.14-.45.42.42,0,0,0-.4-.21.48.48,0,0,0-.46.24,1,1,0,0,0-.14.52v8.78H334Z" transform="translate(-4 -5.3)"/>
      <path d="M354.76,257.73h-1.08v3.18h-4v-8.6a5,5,0,0,1,1.34-3.65,4.79,4.79,0,0,1,3.64-1.39,4.31,4.31,0,0,1,3.43,1.5,5.29,5.29,0,0,1,1.29,3.56v8.58h-4v-8.7a1.35,1.35,0,0,0-.21-.64.68.68,0,0,0-.57-.32.73.73,0,0,0-.66.33,1.3,1.3,0,0,0-.22.75v1.9h1.08Z" transform="translate(-4 -5.3)"/>
      <path d="M360.94,252.13a4.82,4.82,0,0,1,1.36-3.4,4.42,4.42,0,0,1,3.36-1.46,4.81,4.81,0,0,1,3.5,1.39,4.71,4.71,0,0,1,1.42,3.49v8.76h-4v-8.64a1,1,0,0,0-.23-.65.8.8,0,0,0-.63-.27.75.75,0,0,0-.63.28,1,1,0,0,0-.23.66v8.62h-3.94Z" transform="translate(-4 -5.3)"/>
      <path d="M385.6,254.51a12.57,12.57,0,0,1-.39,1.92,6.39,6.39,0,0,1-.81,1.76,6.27,6.27,0,0,1-2.5,2.26,7.3,7.3,0,0,1-3.3.8,6.66,6.66,0,0,1-5-2.07,6.82,6.82,0,0,1-2-5,6.61,6.61,0,0,1,2.1-4.87,6.73,6.73,0,0,1,4.88-2.07,6.6,6.6,0,0,1,4.54,1.64,7.8,7.8,0,0,1,2.42,4.2l-4.24.26a3.86,3.86,0,0,0-1.07-1.39,2.79,2.79,0,0,0-3.65.33,2.79,2.79,0,0,0-.82,2,2.62,2.62,0,0,0,.85,2,2.72,2.72,0,0,0,2,.82,2.57,2.57,0,0,0,2-.76,3.4,3.4,0,0,0,.88-1.9Z" transform="translate(-4 -5.3)"/>
      <path d="M394.18,255.75h-2.6l0,0h0a1.81,1.81,0,0,0-.63.11c-.2.07-.29.26-.29.55a.53.53,0,0,0,.27.54,1.6,1.6,0,0,0,.59.14h.3l2.66,0v3.82h-3.26a7.19,7.19,0,0,1-1.65-.18,3.34,3.34,0,0,1-1.47-.8,3.8,3.8,0,0,1-.93-1.28,3.74,3.74,0,0,1-.33-1.54v-5.9a3.35,3.35,0,0,1,1.17-2.56,3.77,3.77,0,0,1,2.63-1.08l3.84,0v3.86h-2.66l0,0h0a1.21,1.21,0,0,0-.58.14.53.53,0,0,0-.26.52.5.5,0,0,0,.24.48,1.34,1.34,0,0,0,.52.18h2.56Z" transform="translate(-4 -5.3)"/>
      <path d="M172.44,336.91H160.78V298.22h11.66Z" transform="translate(-4 -5.3)"/>
      <path d="M178.71,311.45a14,14,0,0,1,3.94-9.86,12.77,12.77,0,0,1,9.74-4.24,14,14,0,0,1,10.15,4,13.58,13.58,0,0,1,4.12,10.12v25.41H195.12V311.85a2.78,2.78,0,0,0-.67-1.88,2.26,2.26,0,0,0-1.82-.78,2.23,2.23,0,0,0-1.83.81,2.89,2.89,0,0,0-.67,1.91v25H178.71Z" transform="translate(-4 -5.3)"/>
      <path d="M224.53,336.91h-11.6V298.22h17.28q1.68.06,3.36.24a13.67,13.67,0,0,1,3.31.75,20.23,20.23,0,0,1,9.31,6.91A17.5,17.5,0,0,1,250,317a19.4,19.4,0,0,1-5.72,14.38,19.72,19.72,0,0,1-14.35,5.72H229a7,7,0,0,1-.9-.06q-.47,0-.9,0c-.29,0-.59,0-.9,0V325.66h3a9.91,9.91,0,0,0,6.32-2,7,7,0,0,0,2.73-6,7.4,7.4,0,0,0-2.41-5.63,8,8,0,0,0-5.65-2.26l-5.74-.05Z" transform="translate(-4 -5.3)"/>
      <path d="M282.24,323.72a14.27,14.27,0,0,1-3.83,10,12.73,12.73,0,0,1-9.8,4.16,14,14,0,0,1-10.18-4,13.47,13.47,0,0,1-4.15-10.08V298.22h11.54v25.06a2.9,2.9,0,0,0,.7,2,2.31,2.31,0,0,0,1.85.81,2.15,2.15,0,0,0,1.8-.81,3,3,0,0,0,.64-1.91V298.22h11.43Z" transform="translate(-4 -5.3)"/>
      <path d="M327.07,318.35a36.17,36.17,0,0,1-1.13,5.57,18.46,18.46,0,0,1-2.35,5.1,18.1,18.1,0,0,1-7.25,6.56,21,21,0,0,1-9.57,2.32,19.37,19.37,0,0,1-14.36-6,19.85,19.85,0,0,1-5.88-14.41,19.19,19.19,0,0,1,6.09-14.12,19.51,19.51,0,0,1,14.15-6,19,19,0,0,1,13.16,4.76,22.5,22.5,0,0,1,7,12.18l-12.29.75a11,11,0,0,0-3.11-4A8.08,8.08,0,0,0,301,312a8.07,8.07,0,0,0-2.38,5.73,7.61,7.61,0,0,0,2.47,5.69,8,8,0,0,0,5.71,2.37,7.43,7.43,0,0,0,5.68-2.2,9.78,9.78,0,0,0,2.55-5.51Z" transform="translate(-4 -5.3)"/>
      <path d="M353.23,322h-7.54a.21.21,0,0,1-.09.05h-.09a5.12,5.12,0,0,0-1.82.32c-.56.22-.85.75-.85,1.6a1.52,1.52,0,0,0,.79,1.56,5,5,0,0,0,1.71.41h.87l7.71-.06v11.08h-9.45a20.93,20.93,0,0,1-4.79-.52,9.78,9.78,0,0,1-4.26-2.32,11,11,0,0,1-3.65-8.18V308.78a9.77,9.77,0,0,1,3.39-7.44,11,11,0,0,1,7.63-3.12l11.13-.05v11.19h-7.71s-.07.06-.09.06H346a3.5,3.5,0,0,0-1.68.4,1.57,1.57,0,0,0-.75,1.51,1.48,1.48,0,0,0,.69,1.41,4.42,4.42,0,0,0,1.51.51h7.43Z" transform="translate(-4 -5.3)"/>
      <path d="M372.19,336.91h-11.6V298.22h17.29q1.68.06,3.36.24a13.78,13.78,0,0,1,3.31.75,20.21,20.21,0,0,1,9.3,6.91,17.44,17.44,0,0,1,3.8,10.92,19.39,19.39,0,0,1-5.71,14.38,19.74,19.74,0,0,1-14.35,5.72h-.9a7,7,0,0,1-.9-.06q-.46,0-.9,0c-.29,0-.59,0-.9,0V325.66h3a9.94,9.94,0,0,0,6.32-2,7,7,0,0,0,2.73-6,7.44,7.44,0,0,0-2.41-5.63,8,8,0,0,0-5.66-2.26l-5.74-.05Z" transform="translate(-4 -5.3)"/>
      <path d="M175.29,377.82H171.4v10.09H160V363.14a14.38,14.38,0,0,1,4.32-10.35,14,14,0,0,1,10.36-4.44,13.6,13.6,0,0,1,6.58,1.63,16.22,16.22,0,0,1,5.13,4.4l-9.45,6.85a4.64,4.64,0,0,0-1-1.51,2.12,2.12,0,0,0-1.59-.64,2.69,2.69,0,0,0-2.21.93,3.57,3.57,0,0,0-.75,2.32v5.11h3.89Z" transform="translate(-4 -5.3)"/>
      <path d="M198.54,349.22V373c0,1.59.5,2.61,1.51,3.08a8.31,8.31,0,0,0,3.48.69v11.14H200a12,12,0,0,1-9.34-3.86,13.71,13.71,0,0,1-3.48-9.54V349.22Z" transform="translate(-4 -5.3)"/>
      <path d="M204.4,368a18.39,18.39,0,0,1,6-13.92,20,20,0,0,1,14.15-5.69,20.34,20.34,0,0,1,14.3,5.69A18.33,18.33,0,0,1,245,368a20.68,20.68,0,0,1-5.86,14.64,19.83,19.83,0,0,1-28.88,0A20.68,20.68,0,0,1,204.4,368Zm11.31.17a9.79,9.79,0,0,0,2.55,6.67,8.13,8.13,0,0,0,6.38,2.9,8.24,8.24,0,0,0,6.47-2.93,9.76,9.76,0,0,0,2.58-6.64A7.93,7.93,0,0,0,231,362a9.13,9.13,0,0,0-6.32-2.46,8.85,8.85,0,0,0-6.23,2.52,8.06,8.06,0,0,0-2.7,6.12Z" transform="translate(-4 -5.3)"/>
      <path d="M260.72,349.22v25.52a3.08,3.08,0,0,0,.4,1.57,1.43,1.43,0,0,0,1.34.69,1.2,1.2,0,0,0,1.1-.55,2.65,2.65,0,0,0,.41-1.18.69.69,0,0,0,0-.24V349.22h11.43V375a3.4,3.4,0,0,0,.52,1.37,1.44,1.44,0,0,0,1.28.6,1.2,1.2,0,0,0,1.1-.55,3.16,3.16,0,0,0,.46-1.18v-26h11.37v27.3c0,.78,0,1.54-.06,2.3a9,9,0,0,1-.4,2.23,10.82,10.82,0,0,1-4.09,5.7A11.13,11.13,0,0,1,279,388.9c-.2,0-.38,0-.55,0a4.93,4.93,0,0,0-.55,0,11.28,11.28,0,0,1-4.76-1.74,10.3,10.3,0,0,1-3.48-3.6,10.75,10.75,0,0,1-4,4,11,11,0,0,1-5.54,1.37,10.61,10.61,0,0,1-7.54-3.17,9.93,9.93,0,0,1-3.31-7.41v-29.1Z" transform="translate(-4 -5.3)"/>
      <path d="M323.13,377.82h-3.89v10.09H307.81V363.14a14.38,14.38,0,0,1,4.32-10.35,13.94,13.94,0,0,1,10.36-4.44,13.6,13.6,0,0,1,6.58,1.63,16.22,16.22,0,0,1,5.13,4.4l-9.45,6.85a4.64,4.64,0,0,0-1-1.51,2.12,2.12,0,0,0-1.59-.64A2.69,2.69,0,0,0,320,360a3.57,3.57,0,0,0-.75,2.32v5.11h3.89Z" transform="translate(-4 -5.3)"/>
      <path d="M349.8,378.69h-3.13v9.22H335V363a14.63,14.63,0,0,1,3.89-10.59q3.89-4,10.56-4a12.46,12.46,0,0,1,9.94,4.35A15.29,15.29,0,0,1,363.14,363v24.88H351.49V362.68a3.84,3.84,0,0,0-.61-1.86,1.94,1.94,0,0,0-1.66-.92,2.14,2.14,0,0,0-1.91,1,3.8,3.8,0,0,0-.64,2.18v5.51h3.13Z" transform="translate(-4 -5.3)"/>
      <path d="M369,362.45a14,14,0,0,1,3.95-9.86,12.75,12.75,0,0,1,9.74-4.24,14,14,0,0,1,10.15,4A13.58,13.58,0,0,1,397,362.5v25.41H385.42V362.85a2.83,2.83,0,0,0-.67-1.88,2.28,2.28,0,0,0-1.83-.78,2.22,2.22,0,0,0-1.82.81,2.89,2.89,0,0,0-.67,1.91v25H369Z" transform="translate(-4 -5.3)"/>
      <path d="M351.22,445.08h-3v7.83h-8.86V433.69a11.17,11.17,0,0,1,3.35-8,10.82,10.82,0,0,1,8-3.44,10.51,10.51,0,0,1,5.11,1.26,12.51,12.51,0,0,1,4,3.42l-7.33,5.31a3.7,3.7,0,0,0-.74-1.17,1.65,1.65,0,0,0-1.24-.5,2.07,2.07,0,0,0-1.71.72,2.76,2.76,0,0,0-.59,1.8v4h3Z" transform="translate(-4 -5.3)"/>
      <path d="M378.26,441.3h-5.85s-.05,0-.07,0h-.06a4.07,4.07,0,0,0-1.42.25c-.44.17-.65.58-.65,1.24a1.18,1.18,0,0,0,.6,1.21,3.87,3.87,0,0,0,1.33.32h.68l6-.05v8.6h-7.33a16.37,16.37,0,0,1-3.72-.41,7.61,7.61,0,0,1-3.3-1.8,8.56,8.56,0,0,1-2.1-2.88,8.47,8.47,0,0,1-.74-3.46V431.08a7.55,7.55,0,0,1,2.64-5.76,8.48,8.48,0,0,1,5.91-2.43l8.64,0v8.68h-6l-.07.05h-.07a2.69,2.69,0,0,0-1.3.31,1.22,1.22,0,0,0-.59,1.17,1.16,1.16,0,0,0,.54,1.1,3.37,3.37,0,0,0,1.17.39h5.76Z" transform="translate(-4 -5.3)"/>
      <path d="M396.77,452.91H385.56v-30h11.21Z" transform="translate(-4 -5.3)"/>
      <path d="M368.35,478.25h-1.41v3.66H362.8v-9a5.22,5.22,0,0,1,1.57-3.75,5,5,0,0,1,3.75-1.6,4.9,4.9,0,0,1,2.38.58,6.05,6.05,0,0,1,1.86,1.6l-3.42,2.48a1.84,1.84,0,0,0-.35-.55.78.78,0,0,0-.58-.23,1,1,0,0,0-.8.34,1.28,1.28,0,0,0-.27.84v1.85h1.41Z" transform="translate(-4 -5.3)"/>
      <path d="M377.55,478.57h-1.14v3.34h-4.22v-9a5.28,5.28,0,0,1,1.41-3.83,5,5,0,0,1,3.82-1.46,4.5,4.5,0,0,1,3.6,1.57,5.53,5.53,0,0,1,1.36,3.74v9h-4.22v-9.14a1.52,1.52,0,0,0-.22-.67.71.71,0,0,0-.6-.33.79.79,0,0,0-.7.34,1.43,1.43,0,0,0-.23.79v2h1.14Z" transform="translate(-4 -5.3)"/>
      <path d="M384,472.69a5.07,5.07,0,0,1,1.42-3.57,4.65,4.65,0,0,1,3.53-1.53,5,5,0,0,1,3.68,1.46,4.91,4.91,0,0,1,1.49,3.66v9.2H390v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.83.83,0,0,0-.67.3,1,1,0,0,0-.24.69v9.05H384Z" transform="translate(-4 -5.3)"/>
      <path d="M337.84,505.49h-2.73l0,0h0a1.76,1.76,0,0,0-.66.12.55.55,0,0,0-.31.58.54.54,0,0,0,.29.56,1.57,1.57,0,0,0,.62.15h.31l2.79,0v4h-3.42a7.4,7.4,0,0,1-1.73-.19,3.62,3.62,0,0,1-1.54-.84,4,4,0,0,1-1-1.34,4,4,0,0,1-.35-1.62v-6.2A3.54,3.54,0,0,1,331.3,498a4,4,0,0,1,2.76-1.13l4,0v4.05H335.3l0,0h0a1.22,1.22,0,0,0-.61.14.57.57,0,0,0-.27.55.54.54,0,0,0,.25.51,1.72,1.72,0,0,0,.54.18h2.69Z" transform="translate(-4 -5.3)"/>
      <path d="M339.9,501.69a5.08,5.08,0,0,1,1.43-3.57,4.63,4.63,0,0,1,3.53-1.53,5,5,0,0,1,3.67,1.46,4.88,4.88,0,0,1,1.49,3.66v9.2h-4.18v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.8.8,0,0,0-.66.3,1,1,0,0,0-.24.69v9.05H339.9Z" transform="translate(-4 -5.3)"/>
      <path d="M359.6,505.49h-2.73l0,0h0a1.84,1.84,0,0,0-.66.12c-.2.08-.3.27-.3.58s.09.48.28.56a1.61,1.61,0,0,0,.62.15h.32l2.79,0v4h-3.42a7.42,7.42,0,0,1-1.74-.19,3.68,3.68,0,0,1-1.54-.84,4.18,4.18,0,0,1-1-1.34,4,4,0,0,1-.34-1.62v-6.2a3.54,3.54,0,0,1,1.23-2.69,4,4,0,0,1,2.76-1.13l4,0v4.05h-2.79l0,0h0a1.27,1.27,0,0,0-.61.14.57.57,0,0,0-.27.55.56.56,0,0,0,.25.51,1.69,1.69,0,0,0,.55.18h2.69Z" transform="translate(-4 -5.3)"/>
      <path d="M365.94,510.91h-4.16v-14h5.76a5.22,5.22,0,0,1,3.34,1.3,4.08,4.08,0,0,1,1.51,3.22,4.76,4.76,0,0,1-.47,2.08,4.85,4.85,0,0,1-1.38,1.66l2.41,5.75h-4.38l-2-4.52V502.8a.15.15,0,0,0,.09,0h.12a2.62,2.62,0,0,0,1-.23.88.88,0,0,0,.46-.9.79.79,0,0,0-.36-.73,1.58,1.58,0,0,0-.8-.26H367l-1,0Z" transform="translate(-4 -5.3)"/>
      <path d="M383.54,503.08a7.32,7.32,0,0,1,.9,1.53,4.3,4.3,0,0,1,.36,1.74,4.51,4.51,0,0,1-1.54,3.55,5.29,5.29,0,0,1-3.65,1.37,6,6,0,0,1-4.95-2.38,8.26,8.26,0,0,1-1.87-5.25,6.74,6.74,0,0,1,2.1-5,6.83,6.83,0,0,1,5-2.07,6.47,6.47,0,0,1,1.18.11,9.5,9.5,0,0,1,1.13.31v4.11l-.13-.05-.14-.05a5.07,5.07,0,0,0-.72-.24,2.86,2.86,0,0,0-.77-.1,3,3,0,0,0-2.24.95,3.38,3.38,0,0,0-1,2.24,3.78,3.78,0,0,0,.83,2.42,2.82,2.82,0,0,0,2.3,1h.14l.16,0a1.11,1.11,0,0,0,.43-.15.44.44,0,0,0,.2-.4.4.4,0,0,0-.21-.39,1.18,1.18,0,0,0-.46-.09h-1v-3.19Z" transform="translate(-4 -5.3)"/>
      <path d="M392,503.14v7.77H387.8v-7.77l-4-6.24H388l1.85,2.9,2.1-2.9h4.14Z" transform="translate(-4 -5.3)"/>
      <path d="M347.82,539.91H343.6v-14h4.22Z" transform="translate(-4 -5.3)"/>
      <path d="M349.62,530.69a5.08,5.08,0,0,1,1.43-3.57,4.63,4.63,0,0,1,3.53-1.53,5,5,0,0,1,3.67,1.46,4.88,4.88,0,0,1,1.49,3.66v9.2h-4.17v-9.07a1,1,0,0,0-.25-.69.84.84,0,0,0-.66-.28.8.8,0,0,0-.66.3,1,1,0,0,0-.24.69v9h-4.14Z" transform="translate(-4 -5.3)"/>
      <path d="M365.75,539.91h-4.2v-14h6.26c.4,0,.81,0,1.22.09a5,5,0,0,1,1.19.27,7.31,7.31,0,0,1,3.37,2.5,6.32,6.32,0,0,1,1.38,4,7,7,0,0,1-2.07,5.2,7.13,7.13,0,0,1-5.2,2.07h-.32a1.83,1.83,0,0,1-.33,0h-.65v-4.12h1.07a3.54,3.54,0,0,0,2.29-.72,2.53,2.53,0,0,0,1-2.15,2.7,2.7,0,0,0-.87-2,3,3,0,0,0-2.05-.82l-2.08,0Z" transform="translate(-4 -5.3)"/>
      <path d="M384,534.49h-2.73l0,0h0a1.84,1.84,0,0,0-.66.12c-.2.08-.3.27-.3.58s.09.48.28.56a1.61,1.61,0,0,0,.62.15h.32l2.79,0v4h-3.42a7.42,7.42,0,0,1-1.74-.19,3.68,3.68,0,0,1-1.54-.84,4.18,4.18,0,0,1-1-1.34,4,4,0,0,1-.34-1.62v-6.2a3.54,3.54,0,0,1,1.23-2.69,4,4,0,0,1,2.76-1.13l4,0v4h-2.79l0,0h0a1.27,1.27,0,0,0-.61.14.57.57,0,0,0-.27.55.56.56,0,0,0,.25.51,1.69,1.69,0,0,0,.55.18H384Z" transform="translate(-4 -5.3)"/>
      <path d="M392.46,532.71l3.76,7.2h-4.39l-1.7-3.4-1.47,3.4h-4.3l3.84-7.08-3.63-6.93h4.32l1.64,3.19,1.3-3.19h4.26Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'I'" id="INDUCTION">
      <path d="M184.3,299H173.44V263H184.3Z" transform="translate(-4 -5.3)"/>
      <path d="M190.13,275.31a13,13,0,0,1,3.67-9.18,11.89,11.89,0,0,1,9.07-3.94,13,13,0,0,1,9.45,3.75,12.62,12.62,0,0,1,3.84,9.42V299H205.41V275.69a2.66,2.66,0,0,0-.62-1.76,2.14,2.14,0,0,0-1.7-.73,2.09,2.09,0,0,0-1.7.76,2.7,2.7,0,0,0-.62,1.78V299H190.13Z" transform="translate(-4 -5.3)"/>
      <path d="M232.79,299H222V263h16.09c1,0,2.09.11,3.13.21a13.35,13.35,0,0,1,3.08.71,18.8,18.8,0,0,1,8.67,6.43,16.35,16.35,0,0,1,3.53,10.17,18.05,18.05,0,0,1-5.32,13.39,18.37,18.37,0,0,1-13.36,5.32H237a8.21,8.21,0,0,1-.84-.05c-.28,0-.56,0-.83,0s-.55,0-.84,0V288.54h2.75a9.19,9.19,0,0,0,5.89-1.86,6.54,6.54,0,0,0,2.54-5.54,6.92,6.92,0,0,0-2.24-5.24,7.46,7.46,0,0,0-5.27-2.1l-5.34-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M286.52,286.74a13.27,13.27,0,0,1-3.57,9.33,11.83,11.83,0,0,1-9.12,3.86,13.07,13.07,0,0,1-9.48-3.75,12.59,12.59,0,0,1-3.86-9.39V263h10.74v23.33a2.71,2.71,0,0,0,.65,1.83,2.15,2.15,0,0,0,1.73.76,2,2,0,0,0,1.68-.76,2.83,2.83,0,0,0,.59-1.78V263h10.64Z" transform="translate(-4 -5.3)"/>
      <path d="M328.26,281.74a34.7,34.7,0,0,1-1.05,5.18,17.37,17.37,0,0,1-2.19,4.75,16.79,16.79,0,0,1-6.75,6.1,19.51,19.51,0,0,1-8.91,2.16A18,18,0,0,1,296,294.35a18.51,18.51,0,0,1-5.48-13.42,17.86,17.86,0,0,1,5.67-13.15,18.18,18.18,0,0,1,13.18-5.59,17.78,17.78,0,0,1,12.26,4.43A20.94,20.94,0,0,1,328.15,278l-11.45.7a10,10,0,0,0-2.89-3.75,7,7,0,0,0-4.51-1.44,7.08,7.08,0,0,0-5.34,2.34,7.51,7.51,0,0,0-2.22,5.34,7.07,7.07,0,0,0,2.3,5.28,7.42,7.42,0,0,0,5.32,2.22,7,7,0,0,0,5.29-2,9.09,9.09,0,0,0,2.38-5.13Z" transform="translate(-4 -5.3)"/>
      <path d="M352.61,285.08h-7s-.06.06-.08.06h-.08a4.71,4.71,0,0,0-1.7.3c-.52.19-.78.69-.78,1.48a1.42,1.42,0,0,0,.73,1.46,4.76,4.76,0,0,0,1.59.38h.81l7.18-.06V299h-8.8a19.79,19.79,0,0,1-4.46-.49,9.08,9.08,0,0,1-4-2.16,10.26,10.26,0,0,1-3.4-7.61V272.83a9.13,9.13,0,0,1,3.16-6.93,10.25,10.25,0,0,1,7.1-2.9l10.37-.06v10.43h-7.18l-.08.05h-.08a3.28,3.28,0,0,0-1.57.38,1.44,1.44,0,0,0-.7,1.4,1.36,1.36,0,0,0,.65,1.31,3.63,3.63,0,0,0,1.4.47h6.91Z" transform="translate(-4 -5.3)"/>
      <path d="M370.27,299h-10.8V263h16.09c1.05,0,2.09.11,3.13.21a13.23,13.23,0,0,1,3.08.71,18.8,18.8,0,0,1,8.67,6.43A16.3,16.3,0,0,1,394,280.52a18.09,18.09,0,0,1-5.32,13.39,18.39,18.39,0,0,1-13.37,5.32h-.83a8,8,0,0,1-.84-.05q-.44,0-.84,0c-.27,0-.55,0-.84,0V288.54h2.76a9.18,9.18,0,0,0,5.88-1.86,6.51,6.51,0,0,0,2.54-5.54,6.92,6.92,0,0,0-2.24-5.24,7.44,7.44,0,0,0-5.26-2.1l-5.35-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M182.41,332.62H178.8V342H168.16V319a13.39,13.39,0,0,1,4-9.64,13,13,0,0,1,9.64-4.13A12.6,12.6,0,0,1,188,306.7a15.23,15.23,0,0,1,4.78,4.1l-8.8,6.38a4.63,4.63,0,0,0-.89-1.41,2,2,0,0,0-1.49-.59,2.5,2.5,0,0,0-2.05.86,3.32,3.32,0,0,0-.7,2.16V323h3.61Z" transform="translate(-4 -5.3)"/>
      <path d="M204.07,306v22.14c0,1.48.47,2.43,1.4,2.86a7.71,7.71,0,0,0,3.24.65V342h-3.29a11.15,11.15,0,0,1-8.7-3.59,12.8,12.8,0,0,1-3.24-8.89V306Z" transform="translate(-4 -5.3)"/>
      <path d="M209.52,323.44a17.11,17.11,0,0,1,5.62-13,18.56,18.56,0,0,1,13.17-5.29,19,19,0,0,1,13.32,5.29,17.1,17.1,0,0,1,5.69,13,19.3,19.3,0,0,1-5.45,13.64,18.48,18.48,0,0,1-26.89,0,19.27,19.27,0,0,1-5.46-13.64Zm10.53.16a9.1,9.1,0,0,0,2.38,6.21,7.57,7.57,0,0,0,5.94,2.7,7.71,7.71,0,0,0,6-2.72,9.11,9.11,0,0,0,2.4-6.19,7.33,7.33,0,0,0-2.54-5.69,8.5,8.5,0,0,0-5.88-2.3,8.24,8.24,0,0,0-5.81,2.35,7.53,7.53,0,0,0-2.51,5.7Z" transform="translate(-4 -5.3)"/>
      <path d="M262,306v23.76a2.88,2.88,0,0,0,.37,1.46,1.33,1.33,0,0,0,1.25.64,1.12,1.12,0,0,0,1-.51,2.45,2.45,0,0,0,.38-1.11.41.41,0,0,0,0-.21V306h10.64v24a3.12,3.12,0,0,0,.49,1.27,1.32,1.32,0,0,0,1.19.56,1.12,1.12,0,0,0,1-.51,3,3,0,0,0,.43-1.11V306h10.59v25.42q0,1.08-.06,2.13a8.19,8.19,0,0,1-.37,2.08,10.07,10.07,0,0,1-3.81,5.3,10.32,10.32,0,0,1-6.18,2,4.43,4.43,0,0,1-.52,0,4.12,4.12,0,0,0-.51,0,10.37,10.37,0,0,1-4.43-1.62,9.59,9.59,0,0,1-3.24-3.35,10,10,0,0,1-3.7,3.76,10.32,10.32,0,0,1-5.15,1.26,9.88,9.88,0,0,1-7-2.94,9.25,9.25,0,0,1-3.08-6.9V306Z" transform="translate(-4 -5.3)"/>
      <path d="M329.62,332.62H326V342H315.36V319a13.39,13.39,0,0,1,4-9.64,13,13,0,0,1,9.64-4.13,12.6,12.6,0,0,1,6.13,1.51,15.12,15.12,0,0,1,4.78,4.1l-8.8,6.38a4.45,4.45,0,0,0-.89-1.41,2,2,0,0,0-1.49-.59,2.5,2.5,0,0,0-2.05.86,3.32,3.32,0,0,0-.7,2.16V323h3.62Z" transform="translate(-4 -5.3)"/>
      <path d="M354.46,333.43h-2.92V342H340.69V318.8a13.61,13.61,0,0,1,3.61-9.86q3.63-3.75,9.83-3.75a11.6,11.6,0,0,1,9.26,4.05,14.22,14.22,0,0,1,3.49,9.61V342H356V318.53a3.54,3.54,0,0,0-.56-1.73,1.86,1.86,0,0,0-1.54-.87,2,2,0,0,0-1.79.9,3.58,3.58,0,0,0-.59,2V324h2.92Z" transform="translate(-4 -5.3)"/>
      <path d="M372.33,318.31a13,13,0,0,1,3.67-9.18,11.89,11.89,0,0,1,9.07-3.94,13,13,0,0,1,9.45,3.75,12.62,12.62,0,0,1,3.84,9.42V342H387.61V318.69a2.59,2.59,0,0,0-.62-1.76,2.12,2.12,0,0,0-1.7-.72,2.08,2.08,0,0,0-1.7.75,2.74,2.74,0,0,0-.62,1.78V342H372.33Z" transform="translate(-4 -5.3)"/>
      <path d="M177,375.45h-1.89V381h-7V366a8.79,8.79,0,0,1,2.35-6.39,8.42,8.42,0,0,1,6.37-2.43,7.53,7.53,0,0,1,6,2.62,9.28,9.28,0,0,1,2.26,6.23v15h-7V365.79a2.33,2.33,0,0,0-.37-1.12,1.16,1.16,0,0,0-1-.56,1.29,1.29,0,0,0-1.16.58,2.26,2.26,0,0,0-.38,1.31v3.33H177Z" transform="translate(-4 -5.3)"/>
      <path d="M195,381h-7V357.67h7Z" transform="translate(-4 -5.3)"/>
      <path d="M205,381h-6.93V357.67h9.59a8.61,8.61,0,0,1,5.56,2.17,6.73,6.73,0,0,1,2.52,5.36,7.83,7.83,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.31L206,373.49v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.48,1.48,0,0,0,.77-1.51,1.33,1.33,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42h-.18L205,364Z" transform="translate(-4 -5.3)"/>
      <path d="M234.29,375.45H232.4V381h-7V366a8.83,8.83,0,0,1,2.34-6.39,8.45,8.45,0,0,1,6.37-2.43,7.54,7.54,0,0,1,6,2.62,9.27,9.27,0,0,1,2.25,6.23v15h-7V365.79a2.33,2.33,0,0,0-.37-1.12,1.17,1.17,0,0,0-1-.56,1.29,1.29,0,0,0-1.15.58,2.27,2.27,0,0,0-.39,1.31v3.33h1.89Z" transform="translate(-4 -5.3)"/>
      <path d="M245,365.65a8.46,8.46,0,0,1,2.38-5.95,7.73,7.73,0,0,1,5.88-2.55,8.39,8.39,0,0,1,6.12,2.43,8.19,8.19,0,0,1,2.49,6.11V381h-7V365.9a1.67,1.67,0,0,0-.4-1.14,1.36,1.36,0,0,0-1.1-.47,1.32,1.32,0,0,0-1.1.49,1.74,1.74,0,0,0-.41,1.15V381H245Z" transform="translate(-4 -5.3)"/>
      <path d="M271.71,381h-7V357.67h10.43c.68,0,1.35.07,2,.14a8.2,8.2,0,0,1,2,.46,12.13,12.13,0,0,1,5.62,4.17,10.52,10.52,0,0,1,2.29,6.59,11.71,11.71,0,0,1-3.44,8.67,12,12,0,0,1-8.67,3.46h-.54a5.29,5.29,0,0,1-.54,0,4.7,4.7,0,0,1-.54,0h-.55v-6.86h1.79a6,6,0,0,0,3.81-1.21,4.22,4.22,0,0,0,1.65-3.59,4.49,4.49,0,0,0-1.45-3.39,4.84,4.84,0,0,0-3.42-1.37l-3.46,0Z" transform="translate(-4 -5.3)"/>
      <path d="M308.56,357.6v6.62l-.57-.16a2,2,0,0,0-.58-.09,1.82,1.82,0,0,0-1.37.61,2,2,0,0,0-.56,1.39,3.16,3.16,0,0,0,.41,1.39c.26.52.56,1.1.89,1.73a19.89,19.89,0,0,1,.89,2.09,6.71,6.71,0,0,1,.4,2.33,7.46,7.46,0,0,1-2.55,5.8,8.63,8.63,0,0,1-6,2.3,6.2,6.2,0,0,1-1.59-.21,11.34,11.34,0,0,1-1.52-.52v-6.81a3.5,3.5,0,0,0,.86.44,2.5,2.5,0,0,0,.89.16h.28a2.52,2.52,0,0,0,1.28-.6,1.56,1.56,0,0,0,.57-1.23,2.91,2.91,0,0,0-.38-1.31l-.88-1.7a18.7,18.7,0,0,1-.85-2.12,8,8,0,0,1,1.92-8.18,7.57,7.57,0,0,1,5.62-2.38,8.78,8.78,0,0,1,1.45.12,7,7,0,0,1,1.38.37Z" transform="translate(-4 -5.3)"/>
      <path d="M309.82,369a11.1,11.1,0,0,1,3.64-8.4,12.05,12.05,0,0,1,8.54-3.43,12.26,12.26,0,0,1,8.63,3.43,11,11,0,0,1,3.69,8.43,12.51,12.51,0,0,1-3.53,8.84,12,12,0,0,1-17.43,0,12.48,12.48,0,0,1-3.54-8.84Zm6.83.1a5.91,5.91,0,0,0,1.54,4,4.93,4.93,0,0,0,3.85,1.75,5,5,0,0,0,3.9-1.77,5.89,5.89,0,0,0,1.56-4,4.76,4.76,0,0,0-1.65-3.69A5.51,5.51,0,0,0,322,363.9a5.34,5.34,0,0,0-3.76,1.52,4.87,4.87,0,0,0-1.63,3.7Z" transform="translate(-4 -5.3)"/>
      <path d="M352.87,373.06a8.61,8.61,0,0,1-2.31,6.05,7.68,7.68,0,0,1-5.91,2.5,8.46,8.46,0,0,1-6.14-2.43,8.17,8.17,0,0,1-2.51-6.09V357.67h7v15.12a1.75,1.75,0,0,0,.42,1.19,1.39,1.39,0,0,0,1.12.49,1.28,1.28,0,0,0,1.08-.49,1.78,1.78,0,0,0,.39-1.15V357.67h6.89Z" transform="translate(-4 -5.3)"/>
      <path d="M355.5,365.65a8.46,8.46,0,0,1,2.38-5.95,7.7,7.7,0,0,1,5.88-2.55,8.41,8.41,0,0,1,6.12,2.43,8.19,8.19,0,0,1,2.49,6.11V381h-7V365.9a1.67,1.67,0,0,0-.4-1.14,1.51,1.51,0,0,0-2.21,0,1.79,1.79,0,0,0-.4,1.15V381H355.5Z" transform="translate(-4 -5.3)"/>
      <path d="M382.24,381h-7V357.67h10.43c.67,0,1.35.07,2,.14a8.2,8.2,0,0,1,2,.46,12.13,12.13,0,0,1,5.62,4.17A10.52,10.52,0,0,1,397.6,369a11.71,11.71,0,0,1-3.44,8.67,12,12,0,0,1-8.67,3.46H385a4.9,4.9,0,0,1-.54,0,4.7,4.7,0,0,1-.54,0h-.55v-6.86h1.79a6,6,0,0,0,3.81-1.21,4.22,4.22,0,0,0,1.65-3.59,4.49,4.49,0,0,0-1.45-3.39,4.84,4.84,0,0,0-3.42-1.37l-3.46,0Z" transform="translate(-4 -5.3)"/>
      <path d="M186.63,399.84a2.62,2.62,0,0,0,1.43-.31,1.3,1.3,0,0,0,.63-1.23,1.72,1.72,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.49,2.49,0,0,0-.45,1.23V414h-7V399c0-.52,0-1,.07-1.56a5.5,5.5,0,0,1,.39-1.56,8.79,8.79,0,0,1,3.23-4.15,8.47,8.47,0,0,1,5-1.62,9.14,9.14,0,0,1,6.14,2.39,7.58,7.58,0,0,1,2.75,5.94,8,8,0,0,1-.7,3.29,7.07,7.07,0,0,1-2.1,2.7l-.26.19-.23.16a6.63,6.63,0,0,1-2.31.94A13.89,13.89,0,0,1,188,406h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M210.53,405H206l0,0h-.05a3.31,3.31,0,0,0-1.11.19c-.34.13-.51.45-.51,1a.91.91,0,0,0,.48.94,3.11,3.11,0,0,0,1,.25h.52l4.66,0V414h-5.71a12.64,12.64,0,0,1-2.88-.32,5.88,5.88,0,0,1-2.58-1.4,6.54,6.54,0,0,1-1.62-2.24,6.45,6.45,0,0,1-.58-2.69V397a5.9,5.9,0,0,1,2-4.49,6.64,6.64,0,0,1,4.6-1.88l6.72,0v6.75h-4.66s0,0,0,0h-.05a2.19,2.19,0,0,0-1,.24.94.94,0,0,0-.45.91.89.89,0,0,0,.42.85,2.58,2.58,0,0,0,.91.31h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M221,414H214V390.67h9.59a8.61,8.61,0,0,1,5.56,2.17,6.73,6.73,0,0,1,2.52,5.36,7.83,7.83,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32L222,406.49v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.48,1.48,0,0,0,.77-1.51,1.33,1.33,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42h-.18L221,397Z" transform="translate(-4 -5.3)"/>
      <path d="M240.31,407.93H238V414h-6.9V399.07a8.68,8.68,0,0,1,2.61-6.25,8.43,8.43,0,0,1,6.25-2.67,8.08,8.08,0,0,1,4,1,9.73,9.73,0,0,1,3.1,2.66l-5.71,4.13a2.81,2.81,0,0,0-.57-.91,1.28,1.28,0,0,0-1-.39,1.64,1.64,0,0,0-1.33.56,2.16,2.16,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M245.67,402a11.06,11.06,0,0,1,3.64-8.4,12,12,0,0,1,8.54-3.43,12.28,12.28,0,0,1,8.63,3.43,11,11,0,0,1,3.69,8.43,12.48,12.48,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0,12.51,12.51,0,0,1-3.53-8.84Zm6.82.1a6,6,0,0,0,1.54,4,5.16,5.16,0,0,0,7.76,0,5.93,5.93,0,0,0,1.55-4,4.79,4.79,0,0,0-1.64-3.69,5.54,5.54,0,0,0-3.82-1.49,5.37,5.37,0,0,0-3.76,1.52,4.91,4.91,0,0,0-1.63,3.7Z" transform="translate(-4 -5.3)"/>
      <path d="M279,414h-6.93V390.67h9.59a8.61,8.61,0,0,1,5.56,2.17,6.73,6.73,0,0,1,2.52,5.36,7.83,7.83,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32L280,406.49v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.48,1.48,0,0,0,.77-1.51,1.33,1.33,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42h-.18L279,397Z" transform="translate(-4 -5.3)"/>
      <path d="M291.2,396.52a6,6,0,0,1,2-4.47,6.39,6.39,0,0,1,4.55-1.9,6.34,6.34,0,0,1,5.74,3.29,6.11,6.11,0,0,1,2.22-2.3,6.66,6.66,0,0,1,3.06-1h.35a6.06,6.06,0,0,1,5,2.18,8.27,8.27,0,0,1,1.73,5.27V414H309V398.48a1.83,1.83,0,0,0-.25-.79.73.73,0,0,0-.7-.37.86.86,0,0,0-.77.37,2.09,2.09,0,0,0-.31.82V414h-6.9V398.48a1.82,1.82,0,0,0-.24-.79.73.73,0,0,0-.7-.37.88.88,0,0,0-.81.42,1.82,1.82,0,0,0-.24.91V414h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M327.43,408.45h-1.89V414h-7V399a8.83,8.83,0,0,1,2.35-6.39,8.44,8.44,0,0,1,6.37-2.43,7.52,7.52,0,0,1,6,2.62,9.23,9.23,0,0,1,2.26,6.23v15h-7V398.79a2.31,2.31,0,0,0-.36-1.12,1.19,1.19,0,0,0-1-.56,1.3,1.3,0,0,0-1.16.58,2.34,2.34,0,0,0-.38,1.31v3.33h1.89Z" transform="translate(-4 -5.3)"/>
      <path d="M338.1,398.65a8.5,8.5,0,0,1,2.38-5.95,7.73,7.73,0,0,1,5.88-2.55,8.43,8.43,0,0,1,6.13,2.43,8.22,8.22,0,0,1,2.48,6.11V414h-7V398.9a1.72,1.72,0,0,0-.4-1.14,1.37,1.37,0,0,0-1.11-.47,1.35,1.35,0,0,0-1.1.49,1.73,1.73,0,0,0-.4,1.15V414h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M381.12,402.82a22.47,22.47,0,0,1-.68,3.36,11.48,11.48,0,0,1-1.42,3.08,10.87,10.87,0,0,1-4.38,3.95,12.66,12.66,0,0,1-5.77,1.4,11.67,11.67,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.58,11.58,0,0,1,3.68-8.52,11.76,11.76,0,0,1,8.54-3.62,11.45,11.45,0,0,1,7.94,2.87,13.57,13.57,0,0,1,4.24,7.35l-7.42.45a6.57,6.57,0,0,0-1.88-2.43,4.54,4.54,0,0,0-2.92-.93,4.61,4.61,0,0,0-3.46,1.51,4.89,4.89,0,0,0-1.44,3.46,4.6,4.6,0,0,0,1.49,3.43,4.8,4.8,0,0,0,3.45,1.44A4.53,4.53,0,0,0,372.3,406a5.93,5.93,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M396,405h-4.55l-.05,0h-.05a3.21,3.21,0,0,0-1.1.19c-.34.13-.51.45-.51,1a.92.92,0,0,0,.47.94,3.18,3.18,0,0,0,1,.25h.53l4.65,0V414h-5.7a12.67,12.67,0,0,1-2.89-.32,5.8,5.8,0,0,1-2.57-1.4,6.7,6.7,0,0,1-1.63-2.24,6.61,6.61,0,0,1-.58-2.69V397a5.94,5.94,0,0,1,2-4.49,6.66,6.66,0,0,1,4.6-1.88l6.72,0v6.75h-4.65l-.05,0h-.06a2.12,2.12,0,0,0-1,.24.93.93,0,0,0-.46.91.89.89,0,0,0,.42.85,2.65,2.65,0,0,0,.91.31H396Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'WATERSOUNDAIR'" id="WATER_SOUND_AIR">
      <path d="M250.62,199.51v24.2a2.94,2.94,0,0,0,.38,1.48,1.36,1.36,0,0,0,1.27.66,1.16,1.16,0,0,0,1-.52,2.57,2.57,0,0,0,.38-1.13.42.42,0,0,0,.05-.22V199.51h10.84V224a3.15,3.15,0,0,0,.49,1.29,1.36,1.36,0,0,0,1.21.58,1.14,1.14,0,0,0,1-.52,3.13,3.13,0,0,0,.44-1.13V199.51h10.78v25.88c0,.74,0,1.46-.06,2.18a8.15,8.15,0,0,1-.38,2.12,10.24,10.24,0,0,1-3.88,5.4,10.53,10.53,0,0,1-6.29,2,4.45,4.45,0,0,1-.53,0,4.28,4.28,0,0,0-.52,0,10.44,10.44,0,0,1-4.51-1.65,9.76,9.76,0,0,1-3.3-3.41,10.17,10.17,0,0,1-3.77,3.82,10.48,10.48,0,0,1-5.25,1.3,10,10,0,0,1-7.15-3,9.42,9.42,0,0,1-3.13-7V199.51Z" transform="translate(-4 -5.3)"/>
      <path d="M296.6,227.45h-3v8.74H282.58V212.54a13.85,13.85,0,0,1,3.68-10q3.69-3.81,10-3.82a11.82,11.82,0,0,1,9.43,4.13,14.5,14.5,0,0,1,3.55,9.79v23.59H298.2V212.27a3.64,3.64,0,0,0-.58-1.76,1.84,1.84,0,0,0-1.57-.88,2,2,0,0,0-1.81.9,3.62,3.62,0,0,0-.61,2.07v5.22h3Z" transform="translate(-4 -5.3)"/>
      <path d="M330.37,236.19H319.42V210h-8.36V199.51h27.56V210h-8.25Z" transform="translate(-4 -5.3)"/>
      <path d="M361.17,222H354a.21.21,0,0,1-.08.06h-.09a4.87,4.87,0,0,0-1.73.3c-.53.2-.8.7-.8,1.51a1.45,1.45,0,0,0,.75,1.49,4.69,4.69,0,0,0,1.62.38h.82l7.32-.05v10.5h-9a19.85,19.85,0,0,1-4.53-.49,9.44,9.44,0,0,1-4.05-2.2,10.52,10.52,0,0,1-3.46-7.76V209.52a9.27,9.27,0,0,1,3.22-7.06,10.4,10.4,0,0,1,7.23-3l10.56-.06v10.62h-7.32a.15.15,0,0,1-.08.05h-.08a3.3,3.3,0,0,0-1.6.39,1.47,1.47,0,0,0-.71,1.43,1.39,1.39,0,0,0,.66,1.33,4,4,0,0,0,1.43.48h7Z" transform="translate(-4 -5.3)"/>
      <path d="M377.45,236.19H366.56V199.51h15.07a13.41,13.41,0,0,1,8.74,3.41,10.56,10.56,0,0,1,4,8.41,12,12,0,0,1-4.84,9.79l6.33,15.07h-11.5L379,224.37V215a.54.54,0,0,0,.25.06h.3a6.91,6.91,0,0,0,2.64-.61,2.31,2.31,0,0,0,1.21-2.36,2.13,2.13,0,0,0-.93-1.93,4.23,4.23,0,0,0-2.09-.66h-.28l-2.69-.05Z" transform="translate(-4 -5.3)"/>
      <path d="M211.18,247.64a2.41,2.41,0,0,0,1.27-.28,1.15,1.15,0,0,0,.56-1.09,1.56,1.56,0,0,0-.4-1,1.32,1.32,0,0,0-1-.45,1.14,1.14,0,0,0-1,.5,2.26,2.26,0,0,0-.4,1.08v13.83H204V246.92q0-.67.06-1.38a5,5,0,0,1,.34-1.38,7.85,7.85,0,0,1,2.87-3.67,7.48,7.48,0,0,1,4.42-1.44,8.11,8.11,0,0,1,5.44,2.12,6.72,6.72,0,0,1,2.43,5.26,7.14,7.14,0,0,1-.62,2.91,6.09,6.09,0,0,1-1.86,2.39,2.68,2.68,0,0,1-.23.17,1.86,1.86,0,0,1-.2.14,5.75,5.75,0,0,1-2,.84,12.64,12.64,0,0,1-2.2.18h-1.24Z" transform="translate(-4 -5.3)"/>
      <path d="M233.84,252.19h-4l-.05,0h0a2.84,2.84,0,0,0-1,.17c-.3.12-.45.4-.45.86a.8.8,0,0,0,.42.83,2.49,2.49,0,0,0,.92.22h.46l4.12,0v5.92h-5.05a11,11,0,0,1-2.56-.28,5.26,5.26,0,0,1-2.28-1.24,5.89,5.89,0,0,1-1.95-4.37v-9.14a5.25,5.25,0,0,1,1.81-4,5.89,5.89,0,0,1,4.08-1.67l6,0v6h-4.12l-.05,0h0a1.93,1.93,0,0,0-.9.21.85.85,0,0,0-.41.81.79.79,0,0,0,.38.75,2.17,2.17,0,0,0,.8.27h4Z" transform="translate(-4 -5.3)"/>
      <path d="M238.24,246.58a7.48,7.48,0,0,1,2.11-5.27,6.82,6.82,0,0,1,5.21-2.26A7.45,7.45,0,0,1,251,241.2a7.25,7.25,0,0,1,2.2,5.41v13.58H247V246.8a1.5,1.5,0,0,0-.35-1,1.21,1.21,0,0,0-1-.42,1.18,1.18,0,0,0-1,.44,1.55,1.55,0,0,0-.36,1v13.36h-6.11Z" transform="translate(-4 -5.3)"/>
      <path d="M268.68,252.19h-4l0,0h-.05a2.84,2.84,0,0,0-1,.17c-.3.12-.45.4-.45.86a.8.8,0,0,0,.42.83,2.54,2.54,0,0,0,.92.22h.46l4.13,0v5.92H264a10.89,10.89,0,0,1-2.55-.28,5.15,5.15,0,0,1-2.28-1.24,5.87,5.87,0,0,1-1.44-2,5.69,5.69,0,0,1-.52-2.39v-9.14a5.23,5.23,0,0,1,1.82-4,5.87,5.87,0,0,1,4.07-1.67l6,0v6h-4.13l0,0h-.05a1.93,1.93,0,0,0-.9.21.84.84,0,0,0-.4.81.78.78,0,0,0,.37.75,2.28,2.28,0,0,0,.81.27h4Z" transform="translate(-4 -5.3)"/>
      <path d="M282.73,260.19h-6.17V245.4h-4.71v-5.89h15.53v5.89h-4.65Z" transform="translate(-4 -5.3)"/>
      <path d="M296.27,260.19h-6.14V239.51h8.5a7.6,7.6,0,0,1,4.93,1.93,6,6,0,0,1,2.23,4.74,6.83,6.83,0,0,1-2.73,5.52l3.57,8.49h-6.48l-3-6.66v-5.3a.32.32,0,0,0,.14,0h.17a3.84,3.84,0,0,0,1.49-.35,1.3,1.3,0,0,0,.68-1.33,1.19,1.19,0,0,0-.52-1.08,2.46,2.46,0,0,0-1.18-.38h-.16l-1.52,0Z" transform="translate(-4 -5.3)"/>
      <path d="M316.49,255.26h-1.68v4.93h-6.23V246.86a7.81,7.81,0,0,1,2.08-5.66,7.47,7.47,0,0,1,5.64-2.15,6.68,6.68,0,0,1,5.32,2.32,8.21,8.21,0,0,1,2,5.52v13.3h-6.24V246.71a2.11,2.11,0,0,0-.32-1,1,1,0,0,0-.89-.49,1.15,1.15,0,0,0-1,.51,2,2,0,0,0-.34,1.16v3h1.68Z" transform="translate(-4 -5.3)"/>
      <path d="M337.07,260.19H330.9V245.4h-4.71v-5.89h15.53v5.89h-4.65Z" transform="translate(-4 -5.3)"/>
      <path d="M350.74,260.19h-6.23V239.51h6.23Z" transform="translate(-4 -5.3)"/>
      <path d="M354,249.53a9.82,9.82,0,0,1,3.23-7.44,10.65,10.65,0,0,1,7.56-3,10.84,10.84,0,0,1,7.64,3,9.78,9.78,0,0,1,3.27,7.47,11.06,11.06,0,0,1-3.13,7.83,10.61,10.61,0,0,1-15.44,0,11.11,11.11,0,0,1-3.13-7.83Zm6.05.09a5.26,5.26,0,0,0,1.36,3.57,4.36,4.36,0,0,0,3.41,1.54,4.42,4.42,0,0,0,3.46-1.56,5.22,5.22,0,0,0,1.38-3.55,4.24,4.24,0,0,0-1.46-3.27,4.88,4.88,0,0,0-3.38-1.32,4.72,4.72,0,0,0-3.33,1.35,4.32,4.32,0,0,0-1.44,3.27Z" transform="translate(-4 -5.3)"/>
      <path d="M378.64,246.58a7.48,7.48,0,0,1,2.11-5.27,6.82,6.82,0,0,1,5.21-2.26,7.48,7.48,0,0,1,5.42,2.15,7.25,7.25,0,0,1,2.2,5.41v13.58h-6.17V246.8a1.55,1.55,0,0,0-.35-1,1.23,1.23,0,0,0-1-.42,1.21,1.21,0,0,0-1,.44,1.54,1.54,0,0,0-.35,1v13.36h-6.11Z" transform="translate(-4 -5.3)"/>
      <path d="M236.54,270.71v11.72c-.33-.08-.67-.17-1-.28a3.71,3.71,0,0,0-1-.15,3.15,3.15,0,0,0-2.42,1.08,3.58,3.58,0,0,0-1,2.46,5.48,5.48,0,0,0,.71,2.45c.47.93,1,2,1.58,3.08a34.4,34.4,0,0,1,1.58,3.69,11.93,11.93,0,0,1,.71,4.14,13.23,13.23,0,0,1-4.52,10.28,15.36,15.36,0,0,1-10.6,4.06,10.76,10.76,0,0,1-2.82-.37,20.46,20.46,0,0,1-2.7-.93V299.89a6.59,6.59,0,0,0,1.52.77,4.5,4.5,0,0,0,1.58.28h.5a4.43,4.43,0,0,0,2.26-1.05,2.75,2.75,0,0,0,1-2.18,5.35,5.35,0,0,0-.68-2.33l-1.55-3a33,33,0,0,1-1.52-3.76,14.26,14.26,0,0,1,3.41-14.47,13.38,13.38,0,0,1,10-4.23,15.64,15.64,0,0,1,2.57.21,14.94,14.94,0,0,1,2.45.65Z" transform="translate(-4 -5.3)"/>
      <path d="M238.65,290.86A19.65,19.65,0,0,1,245.1,276a21.35,21.35,0,0,1,15.13-6.07A21.75,21.75,0,0,1,275.51,276a19.6,19.6,0,0,1,6.54,14.95,22.13,22.13,0,0,1-6.26,15.65,21.23,21.23,0,0,1-30.88,0,22.13,22.13,0,0,1-6.26-15.65Zm12.09.19a10.47,10.47,0,0,0,2.73,7.13,8.7,8.7,0,0,0,6.82,3.1,8.81,8.81,0,0,0,6.91-3.13,10.46,10.46,0,0,0,2.76-7.1,8.46,8.46,0,0,0-2.91-6.54,9.78,9.78,0,0,0-6.76-2.64,9.47,9.47,0,0,0-6.67,2.7,8.64,8.64,0,0,0-2.88,6.54Z" transform="translate(-4 -5.3)"/>
      <path d="M314.79,298.09a15.25,15.25,0,0,1-4.1,10.72,13.59,13.59,0,0,1-10.47,4.43,15,15,0,0,1-10.89-4.31,14.44,14.44,0,0,1-4.43-10.77V270.84h12.34v26.78a3.16,3.16,0,0,0,.74,2.11,2.49,2.49,0,0,0,2,.87,2.31,2.31,0,0,0,1.92-.87,3.24,3.24,0,0,0,.68-2.05V270.84h12.22Z" transform="translate(-4 -5.3)"/>
      <path d="M319.31,285a15,15,0,0,1,4.22-10.54,13.68,13.68,0,0,1,10.41-4.52,14.87,14.87,0,0,1,10.85,4.31A14.47,14.47,0,0,1,349.2,285v27.16H336.86V285.41a3,3,0,0,0-.72-2,2.4,2.4,0,0,0-1.95-.83,2.36,2.36,0,0,0-1.95.86,3.1,3.1,0,0,0-.71,2.05v26.72H319.31Z" transform="translate(-4 -5.3)"/>
      <path d="M366.55,312.19h-12.4V270.84h18.48q1.8.06,3.6.24a15.69,15.69,0,0,1,3.53.81,21.6,21.6,0,0,1,9.95,7.39A18.68,18.68,0,0,1,393.77,291a20.72,20.72,0,0,1-6.1,15.37,21.12,21.12,0,0,1-15.35,6.12h-1a8,8,0,0,1-1-.06q-.5,0-1,0c-.31,0-.63,0-1,0V300.16h3.16A10.62,10.62,0,0,0,378.4,298a7.46,7.46,0,0,0,2.91-6.35,8,8,0,0,0-2.57-6,8.55,8.55,0,0,0-6.05-2.41l-6.14-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M351.62,334.9h-1.46v4.29h-5.43V327.58a6.85,6.85,0,0,1,1.81-4.93,6.53,6.53,0,0,1,4.92-1.87,5.8,5.8,0,0,1,4.63,2,7.13,7.13,0,0,1,1.74,4.81v11.58H352.4V327.45a1.82,1.82,0,0,0-.28-.87.92.92,0,0,0-.77-.43,1,1,0,0,0-.89.45,1.74,1.74,0,0,0-.3,1v2.56h1.46Z" transform="translate(-4 -5.3)"/>
      <path d="M361.15,327.34a6.49,6.49,0,0,1,1.84-4.59,5.91,5.91,0,0,1,4.53-2,6.51,6.51,0,0,1,4.73,1.87,6.34,6.34,0,0,1,1.91,4.72v11.82h-5.37V327.53a1.3,1.3,0,0,0-.31-.88,1.07,1.07,0,0,0-.85-.36,1,1,0,0,0-.85.37,1.36,1.36,0,0,0-.31.89v11.64h-5.32Z" transform="translate(-4 -5.3)"/>
      <path d="M383.07,339.19h-5.4v-18h8.05c.52,0,1,.06,1.57.11a6.47,6.47,0,0,1,1.54.35,9.4,9.4,0,0,1,4.33,3.22,8.14,8.14,0,0,1,1.77,5.08,9,9,0,0,1-2.66,6.7,9.25,9.25,0,0,1-6.69,2.66h-.41a3,3,0,0,1-.42,0h-.84V334h1.38a4.61,4.61,0,0,0,2.94-.93,3.25,3.25,0,0,0,1.27-2.77,3.42,3.42,0,0,0-1.12-2.61,3.7,3.7,0,0,0-2.63-1.06l-2.68,0Z" transform="translate(-4 -5.3)"/>
      <path d="M322.35,380.33H319v9.86H306.54V363.53q0-7,4.15-11.31T322,347.91a13.33,13.33,0,0,1,10.63,4.65,16.35,16.35,0,0,1,4,11v26.6H324.15v-27a4.12,4.12,0,0,0-.65-2,2.11,2.11,0,0,0-1.77-1,2.31,2.31,0,0,0-2.05,1,4.13,4.13,0,0,0-.68,2.32v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M355.77,390.19H343.3V348.84h12.47Z" transform="translate(-4 -5.3)"/>
      <path d="M375.11,390.19H362.83V348.84h17a15.17,15.17,0,0,1,9.86,3.84,11.91,11.91,0,0,1,4.46,9.49,13.82,13.82,0,0,1-1.39,6.14,13.66,13.66,0,0,1-4.06,4.89l7.13,17h-13l-5.95-13.33v-10.6a.61.61,0,0,0,.28.06h.34a7.83,7.83,0,0,0,3-.68,2.62,2.62,0,0,0,1.37-2.67,2.39,2.39,0,0,0-1.06-2.17,4.66,4.66,0,0,0-2.35-.74h-.31l-3-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M182.29,405a2.72,2.72,0,0,0,1.43-.32,1.27,1.27,0,0,0,.63-1.22,1.72,1.72,0,0,0-.45-1.18,1.52,1.52,0,0,0-1.16-.5,1.26,1.26,0,0,0-1.12.56,2.49,2.49,0,0,0-.45,1.22v15.61h-7v-15c0-.51,0-1,.07-1.56a5.43,5.43,0,0,1,.39-1.55,8.75,8.75,0,0,1,3.23-4.15,8.41,8.41,0,0,1,5-1.63,9.1,9.1,0,0,1,6.14,2.4,7.56,7.56,0,0,1,2.75,5.93,8,8,0,0,1-.7,3.29,7.07,7.07,0,0,1-2.1,2.7l-.26.19-.23.16a6.45,6.45,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M206.33,410.16h-4.55l-.05,0h0a3.08,3.08,0,0,0-1.11.19q-.51.19-.51,1a.92.92,0,0,0,.48.95,2.88,2.88,0,0,0,1,.24h.53l4.65,0v6.68H201a12.65,12.65,0,0,1-2.88-.31,6,6,0,0,1-2.58-1.4,6.67,6.67,0,0,1-1.62-2.24,6.53,6.53,0,0,1-.58-2.7V402.22a5.89,5.89,0,0,1,2.05-4.49,6.6,6.6,0,0,1,4.6-1.88l6.72,0v6.76H202.1l-.06,0h0a2,2,0,0,0-1,.25.92.92,0,0,0-.46.91.9.9,0,0,0,.42.85,2.57,2.57,0,0,0,.91.3h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M216.9,419.19H210V395.85h9.59a8.53,8.53,0,0,1,5.57,2.17,6.73,6.73,0,0,1,2.51,5.35,7.87,7.87,0,0,1-.78,3.47,7.78,7.78,0,0,1-2.3,2.76l4,9.59h-7.31l-3.36-7.52v-6a.24.24,0,0,0,.15,0h.19a4.7,4.7,0,0,0,1.69-.39,1.47,1.47,0,0,0,.76-1.5,1.35,1.35,0,0,0-.59-1.23,2.68,2.68,0,0,0-1.33-.42h-.17l-1.72,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.39,413.1h-2.34v6.09h-6.9V404.25a8.67,8.67,0,0,1,2.61-6.25,8.4,8.4,0,0,1,6.25-2.68,8.19,8.19,0,0,1,4,1,9.86,9.86,0,0,1,3.1,2.66l-5.7,4.13a3.15,3.15,0,0,0-.58-.91,1.27,1.27,0,0,0-1-.38,1.61,1.61,0,0,0-1.33.56,2.16,2.16,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M241.89,407.15a11.1,11.1,0,0,1,3.64-8.4,12,12,0,0,1,8.54-3.43,12.26,12.26,0,0,1,8.63,3.43,11.08,11.08,0,0,1,3.69,8.44,12.45,12.45,0,0,1-3.54,8.83,12,12,0,0,1-17.43,0,12.49,12.49,0,0,1-3.53-8.83Zm6.82.11a5.93,5.93,0,0,0,1.54,4A4.91,4.91,0,0,0,254.1,413a5,5,0,0,0,3.91-1.77,5.9,5.9,0,0,0,1.55-4,4.79,4.79,0,0,0-1.64-3.7,5.53,5.53,0,0,0-3.82-1.48,5.33,5.33,0,0,0-3.76,1.52,4.87,4.87,0,0,0-1.63,3.69Z" transform="translate(-4 -5.3)"/>
      <path d="M275.35,419.19h-6.93V395.85H278a8.51,8.51,0,0,1,5.56,2.17,6.71,6.71,0,0,1,2.52,5.35,7.87,7.87,0,0,1-.78,3.47,7.78,7.78,0,0,1-2.3,2.76l4,9.59h-7.32l-3.36-7.52v-6a.29.29,0,0,0,.16,0h.19a4.6,4.6,0,0,0,1.68-.39,1.46,1.46,0,0,0,.78-1.5,1.36,1.36,0,0,0-.6-1.23,2.68,2.68,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M287.7,401.69a6,6,0,0,1,2-4.46,6.42,6.42,0,0,1,4.55-1.91,6.55,6.55,0,0,1,3.34.84,6.64,6.64,0,0,1,2.4,2.45,6.09,6.09,0,0,1,2.22-2.29,6.56,6.56,0,0,1,3.06-1h.35a6,6,0,0,1,5,2.19,8.25,8.25,0,0,1,1.73,5.27v16.41h-6.86V403.65a1.88,1.88,0,0,0-.25-.79.74.74,0,0,0-.7-.36.87.87,0,0,0-.77.36,2.13,2.13,0,0,0-.31.83v15.5h-6.9V403.65a1.86,1.86,0,0,0-.24-.79.74.74,0,0,0-.7-.36.88.88,0,0,0-.81.42,1.79,1.79,0,0,0-.24.91v15.36h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M324.07,413.63h-1.89v5.56h-7V404.14a8.85,8.85,0,0,1,2.35-6.39,8.44,8.44,0,0,1,6.37-2.43,7.51,7.51,0,0,1,6,2.63,9.21,9.21,0,0,1,2.26,6.23v15h-7V404a2.28,2.28,0,0,0-.36-1.12,1.18,1.18,0,0,0-1-.56,1.32,1.32,0,0,0-1.16.57,2.4,2.4,0,0,0-.38,1.32v3.32h1.89Z" transform="translate(-4 -5.3)"/>
      <path d="M334.88,403.83a8.47,8.47,0,0,1,2.38-5.95,7.71,7.71,0,0,1,5.88-2.56,8.43,8.43,0,0,1,6.13,2.43,8.19,8.19,0,0,1,2.48,6.11v15.33h-7V404.07a1.76,1.76,0,0,0-.4-1.14,1.4,1.4,0,0,0-1.11-.47,1.38,1.38,0,0,0-1.1.49,1.76,1.76,0,0,0-.4,1.16v15.08h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M378,408a22.47,22.47,0,0,1-.68,3.36,11.34,11.34,0,0,1-1.42,3.08,10.9,10.9,0,0,1-4.38,4,12.66,12.66,0,0,1-5.77,1.4,11.64,11.64,0,0,1-8.66-3.63,12,12,0,0,1-3.56-8.69,11.61,11.61,0,0,1,3.68-8.53,11.8,11.8,0,0,1,8.54-3.62,11.5,11.5,0,0,1,7.94,2.87,13.57,13.57,0,0,1,4.24,7.35l-7.42.46a6.6,6.6,0,0,0-1.87-2.44,4.89,4.89,0,0,0-6.39.59,4.88,4.88,0,0,0-1.44,3.46,4.61,4.61,0,0,0,1.49,3.43,4.84,4.84,0,0,0,3.45,1.43,4.48,4.48,0,0,0,3.43-1.33,5.9,5.9,0,0,0,1.54-3.32Z" transform="translate(-4 -5.3)"/>
      <path d="M393.05,410.16H388.5l-.05,0h-.05a3,3,0,0,0-1.1.19q-.51.19-.51,1a.93.93,0,0,0,.47.95,2.93,2.93,0,0,0,1,.24h.53l4.65,0v6.68h-5.7a12.68,12.68,0,0,1-2.89-.31,6,6,0,0,1-2.57-1.4,6.67,6.67,0,0,1-2.21-4.94V402.22a5.92,5.92,0,0,1,2-4.49,6.61,6.61,0,0,1,4.6-1.88l6.72,0v6.76h-4.65l-.05,0h-.06a2,2,0,0,0-1,.25.94.94,0,0,0-.46.91.9.9,0,0,0,.42.85,2.64,2.64,0,0,0,.91.3h4.48Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'SF'" id="SOUND_AIR_FEI">
      <path d="M236.48,209.43v11.72c-.33-.08-.67-.18-1-.28a3.71,3.71,0,0,0-1-.15A3.16,3.16,0,0,0,232,221.8a3.58,3.58,0,0,0-1,2.46,5.48,5.48,0,0,0,.71,2.45c.47.93,1,2,1.58,3.08a34.4,34.4,0,0,1,1.58,3.69,11.88,11.88,0,0,1,.71,4.13,13.21,13.21,0,0,1-4.52,10.28,15.32,15.32,0,0,1-10.6,4.07,10.81,10.81,0,0,1-2.82-.37,20.46,20.46,0,0,1-2.7-.93v-12a6.59,6.59,0,0,0,1.52.77,4.5,4.5,0,0,0,1.58.28h.49a4.43,4.43,0,0,0,2.27-1,2.75,2.75,0,0,0,1-2.18,5.35,5.35,0,0,0-.68-2.33l-1.55-3a33,33,0,0,1-1.52-3.76,14.28,14.28,0,0,1,3.41-14.48,13.39,13.39,0,0,1,10-4.22,15.64,15.64,0,0,1,2.57.21,14,14,0,0,1,2.45.65Z" transform="translate(-4 -5.3)"/>
      <path d="M238.59,229.58A19.65,19.65,0,0,1,245,214.7a21.35,21.35,0,0,1,15.13-6.07,21.75,21.75,0,0,1,15.28,6.07A19.59,19.59,0,0,1,282,229.64a22.13,22.13,0,0,1-6.26,15.66,21.23,21.23,0,0,1-30.88,0,22.13,22.13,0,0,1-6.26-15.66Zm12.09.19a10.49,10.49,0,0,0,2.73,7.13,8.73,8.73,0,0,0,6.82,3.1,8.85,8.85,0,0,0,6.91-3.13,10.46,10.46,0,0,0,2.76-7.1,8.45,8.45,0,0,0-2.91-6.54,9.78,9.78,0,0,0-6.76-2.64,9.47,9.47,0,0,0-6.67,2.7,8.64,8.64,0,0,0-2.88,6.54Z" transform="translate(-4 -5.3)"/>
      <path d="M314.73,236.81a15.24,15.24,0,0,1-4.1,10.71A13.6,13.6,0,0,1,300.16,252a15,15,0,0,1-10.89-4.31,14.44,14.44,0,0,1-4.43-10.77V209.56h12.34v26.78a3.16,3.16,0,0,0,.74,2.11,2.49,2.49,0,0,0,2,.87,2.31,2.31,0,0,0,1.92-.87,3.24,3.24,0,0,0,.68-2V209.56h12.22Z" transform="translate(-4 -5.3)"/>
      <path d="M319.25,223.69a15,15,0,0,1,4.22-10.54,13.64,13.64,0,0,1,10.41-4.52,14.9,14.9,0,0,1,10.85,4.3,14.5,14.5,0,0,1,4.4,10.82v27.16H336.8V224.13a3,3,0,0,0-.72-2,2.41,2.41,0,0,0-1.95-.84,2.37,2.37,0,0,0-1.95.87,3.11,3.11,0,0,0-.72,2.05v26.72H319.25Z" transform="translate(-4 -5.3)"/>
      <path d="M366.49,250.91h-12.4V209.56h18.48q1.8.06,3.6.24a15.69,15.69,0,0,1,3.53.81,21.6,21.6,0,0,1,9.95,7.39,18.68,18.68,0,0,1,4.06,11.67,20.72,20.72,0,0,1-6.1,15.37,21.12,21.12,0,0,1-15.35,6.12h-1a6.39,6.39,0,0,1-1-.07q-.49,0-1,0t-1,0V238.88h3.16a10.62,10.62,0,0,0,6.76-2.14,7.46,7.46,0,0,0,2.91-6.35,8,8,0,0,0-2.57-6,8.55,8.55,0,0,0-6.05-2.41l-6.14-.07Z" transform="translate(-4 -5.3)"/>
      <path d="M351.56,273.62H350.1v4.29h-5.43V266.3a6.84,6.84,0,0,1,1.81-4.93,6.53,6.53,0,0,1,4.92-1.87,5.8,5.8,0,0,1,4.63,2,7.13,7.13,0,0,1,1.74,4.81v11.58h-5.43V266.16a1.82,1.82,0,0,0-.28-.86.92.92,0,0,0-.77-.43,1,1,0,0,0-.89.44,1.8,1.8,0,0,0-.3,1v2.56h1.46Z" transform="translate(-4 -5.3)"/>
      <path d="M361.09,266.06a6.49,6.49,0,0,1,1.84-4.59,5.91,5.91,0,0,1,4.53-2,6.51,6.51,0,0,1,4.73,1.87,6.34,6.34,0,0,1,1.91,4.71v11.83h-5.37V266.25a1.28,1.28,0,0,0-.31-.88,1.07,1.07,0,0,0-.85-.37,1.05,1.05,0,0,0-.85.38,1.34,1.34,0,0,0-.31.89v11.64h-5.32Z" transform="translate(-4 -5.3)"/>
      <path d="M383,277.91h-5.4v-18h8.05c.52,0,1,.05,1.57.11a6.28,6.28,0,0,1,1.53.35,9.36,9.36,0,0,1,4.34,3.22,8.12,8.12,0,0,1,1.77,5.08,9,9,0,0,1-2.66,6.69,9.21,9.21,0,0,1-6.68,2.67h-.42l-.42,0h-.42l-.42,0v-5.29h1.38a4.61,4.61,0,0,0,2.94-.93,3.25,3.25,0,0,0,1.27-2.77,3.46,3.46,0,0,0-1.12-2.62,3.74,3.74,0,0,0-2.63-1.05l-2.68,0Z" transform="translate(-4 -5.3)"/>
      <path d="M322.29,319.05h-3.35v9.86H306.48V302.25q0-7,4.15-11.32t11.29-4.3a13.3,13.3,0,0,1,10.63,4.65,16.34,16.34,0,0,1,4,11v26.6H324.09v-27a4.12,4.12,0,0,0-.65-2,2.1,2.1,0,0,0-1.77-1,2.3,2.3,0,0,0-2.05,1,4.1,4.1,0,0,0-.68,2.32v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M355.71,328.91H343.25V287.56h12.46Z" transform="translate(-4 -5.3)"/>
      <path d="M375.05,328.91H362.77V287.56h17a15.19,15.19,0,0,1,9.86,3.84,11.89,11.89,0,0,1,4.46,9.48,13.61,13.61,0,0,1-5.45,11l7.13,17h-13l-5.95-13.33V305a.61.61,0,0,0,.28.06h.34a7.93,7.93,0,0,0,3-.68,2.63,2.63,0,0,0,1.36-2.67,2.39,2.39,0,0,0-1.06-2.17,4.66,4.66,0,0,0-2.35-.74h-.31l-3-.07Z" transform="translate(-4 -5.3)"/>
      <path d="M182.23,343.73a2.62,2.62,0,0,0,1.43-.31,1.28,1.28,0,0,0,.63-1.23,1.72,1.72,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.56,2.56,0,0,0-.45,1.23v15.61h-7v-15c0-.51,0-1,.07-1.56a5.58,5.58,0,0,1,.39-1.56,8.72,8.72,0,0,1,3.23-4.14,8.41,8.41,0,0,1,5-1.63,9.1,9.1,0,0,1,6.14,2.4,7.56,7.56,0,0,1,2.75,5.93,8,8,0,0,1-.7,3.29,7.07,7.07,0,0,1-2.1,2.7l-.26.19-.23.16a6.45,6.45,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M206.27,348.88h-4.55l-.05,0h0a3.08,3.08,0,0,0-1.11.2q-.51.19-.51,1a.91.91,0,0,0,.48.94,2.89,2.89,0,0,0,1,.25H202l4.65,0v6.69H201a12.64,12.64,0,0,1-2.88-.32,5.88,5.88,0,0,1-2.58-1.4A6.57,6.57,0,0,1,193.9,354a6.45,6.45,0,0,1-.58-2.69V340.93a5.87,5.87,0,0,1,2.05-4.48,6.61,6.61,0,0,1,4.6-1.89l6.72,0v6.75H202s0,0-.06,0h0a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.58,2.58,0,0,0,.91.31h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M216.84,357.91h-6.93V334.56h9.59a8.61,8.61,0,0,1,5.56,2.17,6.71,6.71,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32l-3.36-7.53v-6a.41.41,0,0,0,.16,0h.2a4.39,4.39,0,0,0,1.67-.38,1.48,1.48,0,0,0,.78-1.51,1.34,1.34,0,0,0-.6-1.22,2.58,2.58,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.33,351.82H234v6.09h-6.9V343a8.65,8.65,0,0,1,2.61-6.24A8.4,8.4,0,0,1,236,334a8.19,8.19,0,0,1,4,1,9.73,9.73,0,0,1,3.1,2.66l-5.71,4.13a2.92,2.92,0,0,0-.57-.91,1.28,1.28,0,0,0-1-.39,1.64,1.64,0,0,0-1.33.56,2.18,2.18,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M241.83,345.87a11.1,11.1,0,0,1,3.64-8.4A12,12,0,0,1,254,334a12.28,12.28,0,0,1,8.63,3.43,11.07,11.07,0,0,1,3.69,8.43,12.48,12.48,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0,12.51,12.51,0,0,1-3.53-8.84Zm6.82.1a6,6,0,0,0,1.54,4,4.91,4.91,0,0,0,3.85,1.75A5,5,0,0,0,258,350a5.88,5.88,0,0,0,1.55-4,4.75,4.75,0,0,0-1.64-3.69,5.54,5.54,0,0,0-3.82-1.49,5.34,5.34,0,0,0-3.76,1.53,4.87,4.87,0,0,0-1.63,3.69Z" transform="translate(-4 -5.3)"/>
      <path d="M275.29,357.91h-6.93V334.56H278a8.61,8.61,0,0,1,5.56,2.17,6.74,6.74,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.31l-3.37-7.53v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.47,1.47,0,0,0,.77-1.51,1.34,1.34,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42H277l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M287.64,340.41a6,6,0,0,1,2-4.46,6.38,6.38,0,0,1,4.55-1.91,6.55,6.55,0,0,1,3.34.84,6.64,6.64,0,0,1,2.4,2.45,6.09,6.09,0,0,1,2.22-2.29,6.56,6.56,0,0,1,3.06-1h.35a6,6,0,0,1,5,2.19,8.24,8.24,0,0,1,1.73,5.26v16.42h-6.86V342.37a1.88,1.88,0,0,0-.25-.79.75.75,0,0,0-.7-.37.88.88,0,0,0-.77.37,2.15,2.15,0,0,0-.31.82v15.51h-6.9V342.37a1.86,1.86,0,0,0-.24-.79.75.75,0,0,0-.7-.37.88.88,0,0,0-.81.42,1.82,1.82,0,0,0-.24.91v15.37h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M324,352.34h-1.89v5.57h-7V342.86a8.83,8.83,0,0,1,2.35-6.39A8.44,8.44,0,0,1,323.8,334a7.5,7.5,0,0,1,6,2.62,9.23,9.23,0,0,1,2.26,6.23v15h-7V342.68a2.31,2.31,0,0,0-.36-1.12,1.22,1.22,0,0,0-1-.56,1.32,1.32,0,0,0-1.16.58,2.36,2.36,0,0,0-.38,1.31v3.33H324Z" transform="translate(-4 -5.3)"/>
      <path d="M334.82,342.54a8.5,8.5,0,0,1,2.38-6,7.73,7.73,0,0,1,5.88-2.55,8.43,8.43,0,0,1,6.13,2.43,8.18,8.18,0,0,1,2.48,6.11v15.33h-7V342.79a1.76,1.76,0,0,0-.4-1.14,1.4,1.4,0,0,0-1.11-.47,1.35,1.35,0,0,0-1.1.49,1.75,1.75,0,0,0-.4,1.15v15.09h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M378,346.71a22.47,22.47,0,0,1-.68,3.36,11.34,11.34,0,0,1-1.42,3.08,10.87,10.87,0,0,1-4.38,4,12.5,12.5,0,0,1-5.77,1.4,11.63,11.63,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.6,11.6,0,0,1,3.68-8.52,11.82,11.82,0,0,1,8.54-3.62,11.5,11.5,0,0,1,7.94,2.87,13.57,13.57,0,0,1,4.24,7.35l-7.42.45a6.65,6.65,0,0,0-1.87-2.43,4.86,4.86,0,0,0-6.39.59,4.88,4.88,0,0,0-1.44,3.46,4.59,4.59,0,0,0,1.49,3.42,4.8,4.8,0,0,0,3.45,1.44,4.5,4.5,0,0,0,3.43-1.33,5.93,5.93,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M393,348.88h-4.55l-.05,0h-.05a3,3,0,0,0-1.1.2q-.51.19-.51,1a.92.92,0,0,0,.47.94,2.94,2.94,0,0,0,1,.25h.53l4.65,0v6.69h-5.7a12.67,12.67,0,0,1-2.89-.32,5.85,5.85,0,0,1-2.57-1.4,6.67,6.67,0,0,1-2.21-4.93V340.93a5.9,5.9,0,0,1,2-4.48,6.62,6.62,0,0,1,4.6-1.89l6.72,0v6.75h-4.65s0,0-.05,0h-.06a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.65,2.65,0,0,0,.91.31H393Z" transform="translate(-4 -5.3)"/>
      <path d="M336.47,398.12h-4.16v10.79H320.1V382.44a15.36,15.36,0,0,1,4.62-11.07,14.89,14.89,0,0,1,11.06-4.74,14.45,14.45,0,0,1,7,1.73,17.41,17.41,0,0,1,5.49,4.71l-10.11,7.32a5,5,0,0,0-1-1.61,2.23,2.23,0,0,0-1.71-.69,2.85,2.85,0,0,0-2.35,1,3.79,3.79,0,0,0-.81,2.48V387h4.16Z" transform="translate(-4 -5.3)"/>
      <path d="M372.55,392.91h-8.06s-.07.07-.09.07h-.1a5.4,5.4,0,0,0-1.95.34c-.6.22-.9.79-.9,1.7a1.64,1.64,0,0,0,.84,1.68,5.45,5.45,0,0,0,1.83.43h.93l8.24-.06v11.84h-10.1a22.57,22.57,0,0,1-5.12-.56,10.42,10.42,0,0,1-4.55-2.48,11.78,11.78,0,0,1-3.91-8.74V378.84a10.44,10.44,0,0,1,3.63-7.95,11.73,11.73,0,0,1,8.15-3.33l11.9-.07v12h-8.24a.18.18,0,0,1-.1.06h-.09a3.75,3.75,0,0,0-1.8.44,1.67,1.67,0,0,0-.8,1.61,1.56,1.56,0,0,0,.74,1.5,4.51,4.51,0,0,0,1.61.54h7.94Z" transform="translate(-4 -5.3)"/>
      <path d="M392.88,408.91H380.42V367.56h12.46Z" transform="translate(-4 -5.3)"/>
      <path d="M240.19,434.25h-1.41v3.66h-4.14v-9a5.22,5.22,0,0,1,1.57-3.75,5,5,0,0,1,3.75-1.6,4.9,4.9,0,0,1,2.38.58,6.05,6.05,0,0,1,1.86,1.6l-3.42,2.48a1.84,1.84,0,0,0-.35-.55.78.78,0,0,0-.58-.23,1,1,0,0,0-.8.34,1.28,1.28,0,0,0-.27.84v1.85h1.41Z" transform="translate(-4 -5.3)"/>
      <path d="M249.39,434.57h-1.14v3.34H244v-9a5.28,5.28,0,0,1,1.41-3.83,5,5,0,0,1,3.82-1.46,4.5,4.5,0,0,1,3.6,1.57,5.53,5.53,0,0,1,1.36,3.74v9H250v-9.14a1.52,1.52,0,0,0-.22-.67.71.71,0,0,0-.6-.33.79.79,0,0,0-.7.34,1.43,1.43,0,0,0-.23.79v2h1.14Z" transform="translate(-4 -5.3)"/>
      <path d="M255.88,428.69a5.07,5.07,0,0,1,1.42-3.57,4.63,4.63,0,0,1,3.53-1.53,5,5,0,0,1,3.68,1.46,4.91,4.91,0,0,1,1.49,3.66v9.2h-4.18v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.83.83,0,0,0-.67.3,1,1,0,0,0-.24.69v9.05h-4.13Z" transform="translate(-4 -5.3)"/>
      <path d="M282.25,432.49h-2.73l0,0h0a1.76,1.76,0,0,0-.66.12c-.21.08-.31.27-.31.58a.54.54,0,0,0,.29.56,1.52,1.52,0,0,0,.61.15h.32l2.79,0v4h-3.42a7.34,7.34,0,0,1-1.73-.19,3.65,3.65,0,0,1-1.55-.84,4,4,0,0,1-1.32-3v-6.2a3.54,3.54,0,0,1,1.23-2.69,4,4,0,0,1,2.76-1.13l4,0v4.05h-2.79l0,0h0a1.22,1.22,0,0,0-.61.14.58.58,0,0,0-.28.55.55.55,0,0,0,.26.51,1.64,1.64,0,0,0,.54.18h2.69Z" transform="translate(-4 -5.3)"/>
      <path d="M284.31,428.69a5.08,5.08,0,0,1,1.43-3.57,4.63,4.63,0,0,1,3.52-1.53,5,5,0,0,1,3.68,1.46,4.91,4.91,0,0,1,1.49,3.66v9.2h-4.18v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.8.8,0,0,0-.66.3,1,1,0,0,0-.24.69v9.05h-4.14Z" transform="translate(-4 -5.3)"/>
      <path d="M304,432.49h-2.73l0,0h0a1.8,1.8,0,0,0-.66.12c-.2.08-.3.27-.3.58s.09.48.28.56a1.61,1.61,0,0,0,.62.15h.32l2.79,0v4h-3.42a7.42,7.42,0,0,1-1.74-.19,3.62,3.62,0,0,1-1.54-.84,4,4,0,0,1-1-1.34,4,4,0,0,1-.34-1.62v-6.2a3.54,3.54,0,0,1,1.23-2.69,4,4,0,0,1,2.76-1.13l4,0v4.05h-2.79l0,0h0a1.27,1.27,0,0,0-.61.14.57.57,0,0,0-.27.55.54.54,0,0,0,.25.51,1.69,1.69,0,0,0,.55.18H304Z" transform="translate(-4 -5.3)"/>
      <path d="M310.35,437.91h-4.16v-14h5.75a5.18,5.18,0,0,1,3.34,1.3,4.06,4.06,0,0,1,1.52,3.22,4.64,4.64,0,0,1-.48,2.08,4.71,4.71,0,0,1-1.37,1.66l2.41,5.75H313l-2-4.52V429.8a.15.15,0,0,0,.09,0h.12a2.62,2.62,0,0,0,1-.23.89.89,0,0,0,.46-.9.79.79,0,0,0-.36-.73,1.58,1.58,0,0,0-.8-.26h-.1l-1,0Z" transform="translate(-4 -5.3)"/>
      <path d="M328,430.08a7.32,7.32,0,0,1,.9,1.53,4.3,4.3,0,0,1,.36,1.74,4.51,4.51,0,0,1-1.54,3.55,5.31,5.31,0,0,1-3.65,1.37,6,6,0,0,1-5-2.38,8.31,8.31,0,0,1-1.87-5.25,6.74,6.74,0,0,1,2.1-5,6.85,6.85,0,0,1,5-2.07,6.47,6.47,0,0,1,1.18.11,9.5,9.5,0,0,1,1.13.31v4.11l-.14-.05-.13-.05a4.79,4.79,0,0,0-.73-.24,2.73,2.73,0,0,0-.76-.1,3,3,0,0,0-2.24.95,3.38,3.38,0,0,0-1,2.24,3.78,3.78,0,0,0,.83,2.42,2.82,2.82,0,0,0,2.3,1h.14l.16,0a1.16,1.16,0,0,0,.43-.15.44.44,0,0,0,.2-.4.4.4,0,0,0-.21-.39,1.19,1.19,0,0,0-.47-.09h-1v-3.19Z" transform="translate(-4 -5.3)"/>
      <path d="M336.43,430.14v7.77h-4.22v-7.77l-4-6.24h4.22l1.85,2.9,2.1-2.9h4.14Z" transform="translate(-4 -5.3)"/>
      <path d="M349.7,437.91h-4.22v-14h4.22Z" transform="translate(-4 -5.3)"/>
      <path d="M351.51,428.69a5,5,0,0,1,1.43-3.57,4.61,4.61,0,0,1,3.52-1.53,5,5,0,0,1,3.68,1.46,4.91,4.91,0,0,1,1.49,3.66v9.2h-4.18v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.8.8,0,0,0-.66.3,1,1,0,0,0-.25.69v9.05h-4.13Z" transform="translate(-4 -5.3)"/>
      <path d="M367.64,437.91h-4.2v-14h6.25c.41,0,.82,0,1.22.09a5,5,0,0,1,1.2.27,7.36,7.36,0,0,1,3.37,2.5,6.32,6.32,0,0,1,1.38,4,7,7,0,0,1-2.07,5.2,7.14,7.14,0,0,1-5.2,2.07h-.33a1.77,1.77,0,0,1-.32,0h-.65v-4.12h1.07a3.54,3.54,0,0,0,2.29-.72,2.55,2.55,0,0,0,1-2.15,2.7,2.7,0,0,0-.87-2,2.91,2.91,0,0,0-2.05-.82l-2.07,0Z" transform="translate(-4 -5.3)"/>
      <path d="M385.86,432.49h-2.73l0,0h0a1.76,1.76,0,0,0-.66.12.55.55,0,0,0-.31.58.54.54,0,0,0,.29.56,1.57,1.57,0,0,0,.62.15h.31l2.8,0v4h-3.43a7.4,7.4,0,0,1-1.73-.19,3.62,3.62,0,0,1-1.54-.84,4,4,0,0,1-1-1.34,4,4,0,0,1-.35-1.62v-6.2a3.54,3.54,0,0,1,1.23-2.69,4,4,0,0,1,2.76-1.13l4,0v4.05h-2.8l0,0h0a1.25,1.25,0,0,0-.61.14.57.57,0,0,0-.27.55.54.54,0,0,0,.25.51,1.69,1.69,0,0,0,.55.18h2.68Z" transform="translate(-4 -5.3)"/>
      <path d="M394.35,430.71l3.76,7.2h-4.39l-1.7-3.4-1.47,3.4h-4.31l3.84-7.08-3.63-6.93h4.33l1.64,3.19,1.3-3.19H398Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'S'" id="SOUND_AIR">
      <path d="M236.48,241.43v11.72c-.33-.08-.67-.18-1-.28a3.71,3.71,0,0,0-1-.15A3.15,3.15,0,0,0,232,253.8a3.58,3.58,0,0,0-1,2.46,5.48,5.48,0,0,0,.71,2.45c.47.93,1,2,1.58,3.08a34.4,34.4,0,0,1,1.58,3.69,11.88,11.88,0,0,1,.71,4.13,13.24,13.24,0,0,1-4.52,10.28,15.32,15.32,0,0,1-10.6,4.07,10.7,10.7,0,0,1-2.82-.37,20.46,20.46,0,0,1-2.7-.93V270.61a6.59,6.59,0,0,0,1.52.77,4.5,4.5,0,0,0,1.58.28h.49a4.47,4.47,0,0,0,2.27-1.05,2.75,2.75,0,0,0,1-2.18,5.35,5.35,0,0,0-.68-2.33l-1.55-3a33,33,0,0,1-1.52-3.76,14.28,14.28,0,0,1,3.41-14.48,13.37,13.37,0,0,1,10-4.22,15.64,15.64,0,0,1,2.57.21,14,14,0,0,1,2.45.65Z" transform="translate(-4 -5.3)"/>
      <path d="M238.59,261.58A19.65,19.65,0,0,1,245,246.7a21.35,21.35,0,0,1,15.13-6.07,21.75,21.75,0,0,1,15.28,6.07A19.59,19.59,0,0,1,282,261.64a22.13,22.13,0,0,1-6.26,15.66,21.23,21.23,0,0,1-30.88,0,22.13,22.13,0,0,1-6.26-15.66Zm12.09.19a10.49,10.49,0,0,0,2.73,7.13,8.73,8.73,0,0,0,6.82,3.1,8.85,8.85,0,0,0,6.91-3.13,10.46,10.46,0,0,0,2.76-7.1,8.45,8.45,0,0,0-2.91-6.54,9.78,9.78,0,0,0-6.76-2.64,9.47,9.47,0,0,0-6.67,2.7,8.64,8.64,0,0,0-2.88,6.54Z" transform="translate(-4 -5.3)"/>
      <path d="M314.72,268.81a15.23,15.23,0,0,1-4.09,10.71A13.6,13.6,0,0,1,300.16,284a15,15,0,0,1-10.89-4.31,14.44,14.44,0,0,1-4.43-10.77V241.56h12.34v26.78a3.16,3.16,0,0,0,.74,2.11,2.49,2.49,0,0,0,2,.87,2.31,2.31,0,0,0,1.92-.87,3.24,3.24,0,0,0,.68-2.05V241.56h12.21Z" transform="translate(-4 -5.3)"/>
      <path d="M319.25,255.69a15,15,0,0,1,4.22-10.54,13.64,13.64,0,0,1,10.41-4.52,14.9,14.9,0,0,1,10.85,4.3,14.5,14.5,0,0,1,4.4,10.82v27.16H336.8V256.13a3,3,0,0,0-.72-2,2.41,2.41,0,0,0-1.95-.84,2.37,2.37,0,0,0-1.95.87,3.11,3.11,0,0,0-.72,2.05v26.72H319.25Z" transform="translate(-4 -5.3)"/>
      <path d="M366.49,282.91h-12.4V241.56h18.48q1.8.06,3.6.24a15.69,15.69,0,0,1,3.53.81,21.6,21.6,0,0,1,9.95,7.39,18.68,18.68,0,0,1,4.06,11.67A20.69,20.69,0,0,1,387.6,277a21.09,21.09,0,0,1-15.34,6.12h-1a6.39,6.39,0,0,1-1-.07q-.49,0-1,0t-1,0V270.88h3.16a10.62,10.62,0,0,0,6.76-2.14,7.46,7.46,0,0,0,2.91-6.35,8,8,0,0,0-2.57-6,8.55,8.55,0,0,0-6.05-2.41l-6.14-.07Z" transform="translate(-4 -5.3)"/>
      <path d="M351.56,305.62H350.1v4.29h-5.43V298.3a6.84,6.84,0,0,1,1.81-4.93,6.53,6.53,0,0,1,4.92-1.87,5.8,5.8,0,0,1,4.63,2,7.13,7.13,0,0,1,1.74,4.81v11.58h-5.43V298.16a1.82,1.82,0,0,0-.28-.86.92.92,0,0,0-.77-.43,1,1,0,0,0-.89.44,1.8,1.8,0,0,0-.3,1v2.56h1.46Z" transform="translate(-4 -5.3)"/>
      <path d="M361.09,298.06a6.49,6.49,0,0,1,1.84-4.59,5.91,5.91,0,0,1,4.53-2,6.51,6.51,0,0,1,4.73,1.87,6.34,6.34,0,0,1,1.91,4.71v11.83h-5.37V298.25a1.28,1.28,0,0,0-.31-.88,1.07,1.07,0,0,0-.85-.37,1.05,1.05,0,0,0-.85.38,1.34,1.34,0,0,0-.31.89v11.64h-5.32Z" transform="translate(-4 -5.3)"/>
      <path d="M383,309.91h-5.4v-18h8.05c.52,0,1,.05,1.57.11a6.28,6.28,0,0,1,1.53.35,9.36,9.36,0,0,1,4.34,3.22,8.12,8.12,0,0,1,1.77,5.08,9,9,0,0,1-2.66,6.69,9.22,9.22,0,0,1-6.69,2.67h-.41l-.42,0h-.42l-.42,0v-5.29h1.38a4.61,4.61,0,0,0,2.94-.93,3.25,3.25,0,0,0,1.27-2.77,3.46,3.46,0,0,0-1.12-2.62,3.74,3.74,0,0,0-2.63-1.05l-2.68,0Z" transform="translate(-4 -5.3)"/>
      <path d="M322.29,351.05h-3.35v9.86H306.48V334.25q0-7,4.15-11.32t11.29-4.3a13.3,13.3,0,0,1,10.63,4.65,16.34,16.34,0,0,1,4,11v26.6H324.09v-27a4.22,4.22,0,0,0-.65-2,2.1,2.1,0,0,0-1.77-1,2.3,2.3,0,0,0-2.05,1,4.1,4.1,0,0,0-.68,2.32v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M355.71,360.91H343.24V319.56h12.47Z" transform="translate(-4 -5.3)"/>
      <path d="M375.05,360.91H362.77V319.56h17a15.19,15.19,0,0,1,9.86,3.84,11.89,11.89,0,0,1,4.46,9.48,13.61,13.61,0,0,1-5.45,11l7.13,17h-13l-5.95-13.33V337a.61.61,0,0,0,.28.06h.34a7.83,7.83,0,0,0,3-.68,2.62,2.62,0,0,0,1.37-2.67,2.39,2.39,0,0,0-1.06-2.17,4.66,4.66,0,0,0-2.35-.74h-.31l-3-.07Z" transform="translate(-4 -5.3)"/>
      <path d="M182.23,375.73a2.62,2.62,0,0,0,1.43-.31,1.28,1.28,0,0,0,.63-1.23,1.72,1.72,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.56,2.56,0,0,0-.45,1.23v15.61h-7v-15c0-.51,0-1,.07-1.56a5.58,5.58,0,0,1,.39-1.56,8.72,8.72,0,0,1,3.23-4.14,8.41,8.41,0,0,1,5-1.63,9.1,9.1,0,0,1,6.14,2.4,7.56,7.56,0,0,1,2.75,5.93,8,8,0,0,1-.7,3.29,7.07,7.07,0,0,1-2.1,2.7l-.26.19-.23.16a6.45,6.45,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M206.27,380.88h-4.55l-.05,0h0a3.08,3.08,0,0,0-1.11.2q-.51.19-.51,1a.91.91,0,0,0,.48.94,2.89,2.89,0,0,0,1,.25H202l4.65,0v6.69H201a12.64,12.64,0,0,1-2.88-.32,5.88,5.88,0,0,1-2.58-1.4A6.57,6.57,0,0,1,193.9,386a6.45,6.45,0,0,1-.58-2.69V372.93a5.87,5.87,0,0,1,2.05-4.48,6.61,6.61,0,0,1,4.6-1.89l6.72,0v6.75H202s0,0-.06,0h0a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.58,2.58,0,0,0,.91.31h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M216.84,389.91h-6.93V366.56h9.59a8.61,8.61,0,0,1,5.56,2.17,6.74,6.74,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32l-3.36-7.53v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.47,1.47,0,0,0,.77-1.51,1.34,1.34,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.33,383.82H234v6.09h-6.9V375a8.65,8.65,0,0,1,2.61-6.24A8.4,8.4,0,0,1,236,366a8.19,8.19,0,0,1,4,1,9.73,9.73,0,0,1,3.1,2.66l-5.71,4.13a2.92,2.92,0,0,0-.57-.91,1.28,1.28,0,0,0-1-.39,1.64,1.64,0,0,0-1.33.56,2.18,2.18,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M241.83,377.87a11.1,11.1,0,0,1,3.64-8.4A12,12,0,0,1,254,366a12.28,12.28,0,0,1,8.63,3.43,11.07,11.07,0,0,1,3.69,8.43,12.48,12.48,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0,12.51,12.51,0,0,1-3.53-8.84Zm6.82.1a6,6,0,0,0,1.54,4,4.91,4.91,0,0,0,3.85,1.75A5,5,0,0,0,258,382a5.93,5.93,0,0,0,1.55-4,4.75,4.75,0,0,0-1.64-3.69,5.54,5.54,0,0,0-3.82-1.49,5.34,5.34,0,0,0-3.76,1.53,4.87,4.87,0,0,0-1.63,3.69Z" transform="translate(-4 -5.3)"/>
      <path d="M275.29,389.91h-6.93V366.56H278a8.61,8.61,0,0,1,5.56,2.17,6.74,6.74,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32l-3.36-7.53v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.47,1.47,0,0,0,.77-1.51,1.34,1.34,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42H277l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M287.64,372.41a6,6,0,0,1,2-4.46,6.38,6.38,0,0,1,4.55-1.91,6.55,6.55,0,0,1,3.34.84,6.64,6.64,0,0,1,2.4,2.45,6.09,6.09,0,0,1,2.22-2.29,6.56,6.56,0,0,1,3.06-1h.35a6,6,0,0,1,5,2.19,8.24,8.24,0,0,1,1.73,5.26v16.42h-6.86V374.37a1.88,1.88,0,0,0-.25-.79.75.75,0,0,0-.7-.37.88.88,0,0,0-.77.37,2.15,2.15,0,0,0-.31.82v15.51h-6.9V374.37a1.86,1.86,0,0,0-.24-.79.75.75,0,0,0-.7-.37.87.87,0,0,0-.81.42,1.82,1.82,0,0,0-.24.91v15.37h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M324,384.34h-1.89v5.57h-7V374.86a8.83,8.83,0,0,1,2.35-6.39A8.44,8.44,0,0,1,323.8,366a7.52,7.52,0,0,1,6,2.62,9.23,9.23,0,0,1,2.26,6.23v15h-7V374.68a2.31,2.31,0,0,0-.36-1.12,1.22,1.22,0,0,0-1-.56,1.32,1.32,0,0,0-1.16.58,2.36,2.36,0,0,0-.38,1.31v3.33H324Z" transform="translate(-4 -5.3)"/>
      <path d="M334.82,374.54a8.5,8.5,0,0,1,2.38-6,7.73,7.73,0,0,1,5.88-2.55,8.43,8.43,0,0,1,6.13,2.43,8.18,8.18,0,0,1,2.48,6.11v15.33h-7V374.79a1.76,1.76,0,0,0-.4-1.14,1.4,1.4,0,0,0-1.11-.47,1.35,1.35,0,0,0-1.1.49,1.75,1.75,0,0,0-.4,1.15v15.09h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M378,378.71a22.47,22.47,0,0,1-.68,3.36,11.34,11.34,0,0,1-1.42,3.08,10.87,10.87,0,0,1-4.38,4,12.53,12.53,0,0,1-5.77,1.4,11.63,11.63,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.6,11.6,0,0,1,3.68-8.52,11.8,11.8,0,0,1,8.54-3.62,11.5,11.5,0,0,1,7.94,2.87,13.57,13.57,0,0,1,4.24,7.35l-7.42.45a6.65,6.65,0,0,0-1.87-2.43,4.86,4.86,0,0,0-6.39.59,4.88,4.88,0,0,0-1.44,3.46,4.59,4.59,0,0,0,1.49,3.42,4.8,4.8,0,0,0,3.45,1.44,4.5,4.5,0,0,0,3.43-1.33,5.93,5.93,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M393,380.88h-4.55l-.05,0h-.05a3,3,0,0,0-1.1.2q-.51.19-.51,1a.92.92,0,0,0,.47.94,2.94,2.94,0,0,0,1,.25h.53l4.65,0v6.69h-5.7a12.67,12.67,0,0,1-2.89-.32,5.8,5.8,0,0,1-2.57-1.4,6.67,6.67,0,0,1-2.21-4.93V372.93a5.9,5.9,0,0,1,2-4.48,6.62,6.62,0,0,1,4.6-1.89l6.72,0v6.75h-4.65l-.05,0h-.06a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.65,2.65,0,0,0,.91.31H393Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'WATERAIRWIND'" id="WATER_AIR_WIND">
      <path d="M250.62,217.09v24.2a2.94,2.94,0,0,0,.38,1.48,1.36,1.36,0,0,0,1.27.66,1.16,1.16,0,0,0,1-.52,2.53,2.53,0,0,0,.38-1.13.42.42,0,0,0,.05-.22V217.09h10.84v24.47a3.25,3.25,0,0,0,.49,1.3,1.37,1.37,0,0,0,1.21.57,1.14,1.14,0,0,0,1-.52,3.06,3.06,0,0,0,.44-1.13V217.09h10.78V243c0,.73,0,1.46-.06,2.17a8.08,8.08,0,0,1-.38,2.12,10.24,10.24,0,0,1-3.88,5.4,10.53,10.53,0,0,1-6.29,2,4.45,4.45,0,0,1-.53,0,4.28,4.28,0,0,0-.52,0,10.44,10.44,0,0,1-4.51-1.65,9.68,9.68,0,0,1-3.3-3.41,10.2,10.2,0,0,1-3.77,3.83,10.48,10.48,0,0,1-5.25,1.29,10.05,10.05,0,0,1-7.15-3,9.42,9.42,0,0,1-3.13-7V217.09Z" transform="translate(-4 -5.3)"/>
      <path d="M296.6,245h-3v8.74H282.58V230.12a13.83,13.83,0,0,1,3.68-10q3.69-3.83,10-3.83a11.82,11.82,0,0,1,9.43,4.13,14.5,14.5,0,0,1,3.55,9.79v23.59H298.2V229.85a3.68,3.68,0,0,0-.58-1.76,1.87,1.87,0,0,0-1.57-.88,2,2,0,0,0-1.81.91,3.57,3.57,0,0,0-.61,2.06v5.22h3Z" transform="translate(-4 -5.3)"/>
      <path d="M330.37,253.77H319.42V227.54h-8.36V217.09h27.56v10.45h-8.25Z" transform="translate(-4 -5.3)"/>
      <path d="M361.17,239.58H354a.21.21,0,0,1-.08.06h-.09a4.87,4.87,0,0,0-1.73.3c-.53.2-.8.71-.8,1.51a1.45,1.45,0,0,0,.75,1.49,4.48,4.48,0,0,0,1.62.38h.82l7.32,0v10.5h-9a19.85,19.85,0,0,1-4.53-.49,9.33,9.33,0,0,1-4.05-2.2,10.52,10.52,0,0,1-3.46-7.76V227.1a9.24,9.24,0,0,1,3.22-7,10.41,10.41,0,0,1,7.23-3l10.56-.06v10.62h-7.32a.1.1,0,0,1-.08,0h-.08a3.3,3.3,0,0,0-1.6.39,1.48,1.48,0,0,0-.71,1.43,1.39,1.39,0,0,0,.66,1.33,4,4,0,0,0,1.43.48h7Z" transform="translate(-4 -5.3)"/>
      <path d="M377.45,253.77H366.56V217.09h15.07a13.46,13.46,0,0,1,8.74,3.41,10.57,10.57,0,0,1,4,8.41,12,12,0,0,1-4.84,9.79l6.33,15.07h-11.5L379,242v-9.41a.54.54,0,0,0,.25.06h.3a6.91,6.91,0,0,0,2.64-.61,2.3,2.3,0,0,0,1.21-2.36,2.14,2.14,0,0,0-.93-1.93,4.23,4.23,0,0,0-2.09-.66h-.28l-2.69,0Z" transform="translate(-4 -5.3)"/>
      <path d="M211.18,265.22a2.31,2.31,0,0,0,1.27-.28,1.15,1.15,0,0,0,.56-1.09,1.53,1.53,0,0,0-.4-1,1.32,1.32,0,0,0-1-.45,1.13,1.13,0,0,0-1,.49,2.29,2.29,0,0,0-.4,1.09v13.82H204V264.51q0-.69.06-1.38a4.81,4.81,0,0,1,.34-1.38,7.83,7.83,0,0,1,2.87-3.68,7.48,7.48,0,0,1,4.42-1.44,8.08,8.08,0,0,1,5.44,2.13,6.68,6.68,0,0,1,2.43,5.25,7.22,7.22,0,0,1-.62,2.92,6.24,6.24,0,0,1-1.86,2.39l-.23.17-.2.14a5.72,5.72,0,0,1-2,.83,12.64,12.64,0,0,1-2.2.18h-1.24Z" transform="translate(-4 -5.3)"/>
      <path d="M233.84,269.78h-4l-.05,0h0a2.62,2.62,0,0,0-1,.17c-.3.11-.45.4-.45.85a.83.83,0,0,0,.42.84,2.7,2.7,0,0,0,.92.21h.46l4.12,0v5.92h-5.05a11,11,0,0,1-2.56-.28,5.23,5.23,0,0,1-2.28-1.23,5.93,5.93,0,0,1-1.95-4.38v-9.14a5.27,5.27,0,0,1,1.81-4,5.88,5.88,0,0,1,4.08-1.66l6,0v6h-4.12l-.05,0h0a1.82,1.82,0,0,0-.9.22.82.82,0,0,0-.41.8.79.79,0,0,0,.38.75,2,2,0,0,0,.8.28h4Z" transform="translate(-4 -5.3)"/>
      <path d="M238.24,264.16a7.44,7.44,0,0,1,2.11-5.26,6.83,6.83,0,0,1,5.21-2.27,7.42,7.42,0,0,1,5.42,2.16,7.24,7.24,0,0,1,2.2,5.41v13.57H247V264.38a1.5,1.5,0,0,0-.35-1,1.24,1.24,0,0,0-1-.41,1.17,1.17,0,0,0-1,.43,1.55,1.55,0,0,0-.36,1v13.36h-6.11Z" transform="translate(-4 -5.3)"/>
      <path d="M268.68,269.78h-4l0,0h-.05a2.62,2.62,0,0,0-1,.17c-.3.11-.45.4-.45.85a.83.83,0,0,0,.42.84,2.75,2.75,0,0,0,.92.21h.46l4.13,0v5.92H264a10.89,10.89,0,0,1-2.55-.28,5.13,5.13,0,0,1-2.28-1.23,5.81,5.81,0,0,1-1.44-2,5.65,5.65,0,0,1-.52-2.39v-9.14a5.24,5.24,0,0,1,1.82-4,5.85,5.85,0,0,1,4.07-1.66l6,0v6h-4.13l0,0h-.05a1.82,1.82,0,0,0-.9.22.82.82,0,0,0-.4.8.78.78,0,0,0,.37.75,2.11,2.11,0,0,0,.81.28h4Z" transform="translate(-4 -5.3)"/>
      <path d="M282.73,277.77h-6.17V263h-4.71V257.1h15.53V263h-4.65Z" transform="translate(-4 -5.3)"/>
      <path d="M296.27,277.77h-6.14V257.1h8.5a7.59,7.59,0,0,1,4.93,1.92,6,6,0,0,1,2.23,4.74,6.83,6.83,0,0,1-2.73,5.52l3.57,8.49h-6.48l-3-6.66v-5.3a.32.32,0,0,0,.14,0h.17a4,4,0,0,0,1.49-.34,1.32,1.32,0,0,0,.68-1.34,1.2,1.2,0,0,0-.52-1.08,2.34,2.34,0,0,0-1.18-.37h-.16l-1.52,0Z" transform="translate(-4 -5.3)"/>
      <path d="M316.49,272.85h-1.68v4.92h-6.23V264.44a7.77,7.77,0,0,1,2.08-5.65,7.44,7.44,0,0,1,5.64-2.16,6.69,6.69,0,0,1,5.32,2.33,8.21,8.21,0,0,1,2,5.52v13.29h-6.24V264.29a2.11,2.11,0,0,0-.32-1,1.06,1.06,0,0,0-.89-.5,1.15,1.15,0,0,0-1,.51,2,2,0,0,0-.34,1.17v2.94h1.68Z" transform="translate(-4 -5.3)"/>
      <path d="M337.07,277.77H330.9V263h-4.71V257.1h15.53V263h-4.65Z" transform="translate(-4 -5.3)"/>
      <path d="M350.74,277.77h-6.23V257.1h6.23Z" transform="translate(-4 -5.3)"/>
      <path d="M354,267.11a9.85,9.85,0,0,1,3.23-7.44,10.7,10.7,0,0,1,7.56-3,10.88,10.88,0,0,1,7.64,3,9.81,9.81,0,0,1,3.27,7.47,11,11,0,0,1-3.13,7.83,10.61,10.61,0,0,1-15.44,0,11.09,11.09,0,0,1-3.13-7.83Zm6.05.09a5.24,5.24,0,0,0,1.36,3.57,4.56,4.56,0,0,0,6.87,0,5.2,5.2,0,0,0,1.38-3.55,4.24,4.24,0,0,0-1.46-3.27,4.88,4.88,0,0,0-3.38-1.32,4.72,4.72,0,0,0-3.33,1.35,4.32,4.32,0,0,0-1.44,3.27Z" transform="translate(-4 -5.3)"/>
      <path d="M378.64,264.16a7.44,7.44,0,0,1,2.11-5.26,6.83,6.83,0,0,1,5.21-2.27,7.45,7.45,0,0,1,5.42,2.16,7.24,7.24,0,0,1,2.2,5.41v13.57h-6.17V264.38a1.55,1.55,0,0,0-.35-1,1.25,1.25,0,0,0-1-.41,1.21,1.21,0,0,0-1,.43,1.54,1.54,0,0,0-.35,1v13.36h-6.11Z" transform="translate(-4 -5.3)"/>
      <path d="M322.35,322.92H319v9.85H306.54V306.11q0-7,4.15-11.31T322,290.49a13.33,13.33,0,0,1,10.63,4.65,16.36,16.36,0,0,1,4,11v26.59H324.15v-27a4.12,4.12,0,0,0-.65-2,2.11,2.11,0,0,0-1.77-1,2.31,2.31,0,0,0-2.05,1,4.14,4.14,0,0,0-.68,2.33v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M355.77,332.77H343.3V291.42h12.47Z" transform="translate(-4 -5.3)"/>
      <path d="M375.11,332.77H362.83V291.42h17a15.17,15.17,0,0,1,9.86,3.84,11.93,11.93,0,0,1,4.46,9.49,13.61,13.61,0,0,1-5.45,11l7.13,17h-13l-5.95-13.33v-10.6a.61.61,0,0,0,.28.06h.34a7.65,7.65,0,0,0,3-.68,2.61,2.61,0,0,0,1.37-2.66,2.37,2.37,0,0,0-1.06-2.17,4.57,4.57,0,0,0-2.35-.75h-.31l-3-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M182.29,347.6a2.63,2.63,0,0,0,1.43-.32,1.27,1.27,0,0,0,.63-1.22,1.7,1.7,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.49,2.49,0,0,0-.45,1.22v15.61h-7v-15c0-.51,0-1,.07-1.55a5.41,5.41,0,0,1,.39-1.56,8.86,8.86,0,0,1,3.23-4.15,8.48,8.48,0,0,1,5-1.63,9.1,9.1,0,0,1,6.14,2.4,7.58,7.58,0,0,1,2.75,5.93,8.05,8.05,0,0,1-.7,3.3,7.23,7.23,0,0,1-2.1,2.7l-.26.19-.23.15a6.27,6.27,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M206.33,352.74h-4.55s0,0-.05,0h0a3.08,3.08,0,0,0-1.11.19c-.34.13-.5.45-.5,1s.15.81.47.95a2.88,2.88,0,0,0,1,.24h.53l4.65,0v6.68h-5.7a12.68,12.68,0,0,1-2.89-.31,5.9,5.9,0,0,1-2.57-1.4,6.57,6.57,0,0,1-1.63-2.24,6.46,6.46,0,0,1-.58-2.7V344.8a5.89,5.89,0,0,1,2.05-4.49,6.64,6.64,0,0,1,4.6-1.88l6.72,0v6.76H202.1l-.06,0h0a2.13,2.13,0,0,0-1,.25.93.93,0,0,0-.46.91.89.89,0,0,0,.42.85,2.39,2.39,0,0,0,.91.3h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M216.9,361.77H210V338.43h9.59a8.58,8.58,0,0,1,5.57,2.17,6.74,6.74,0,0,1,2.51,5.35,7.62,7.62,0,0,1-3.08,6.23l4,9.59h-7.31l-3.36-7.52v-6a.24.24,0,0,0,.15,0h.19a4.32,4.32,0,0,0,1.69-.39,1.47,1.47,0,0,0,.76-1.5,1.35,1.35,0,0,0-.59-1.23,2.78,2.78,0,0,0-1.33-.42h-.17l-1.72,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.39,355.68h-2.34v6.09h-6.9V346.83a8.68,8.68,0,0,1,2.61-6.25A8.44,8.44,0,0,1,236,337.9a8.19,8.19,0,0,1,4,1,9.86,9.86,0,0,1,3.1,2.66l-5.7,4.13a3.15,3.15,0,0,0-.58-.91,1.31,1.31,0,0,0-1-.38,1.64,1.64,0,0,0-1.33.56,2.16,2.16,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M241.89,349.73a11.1,11.1,0,0,1,3.64-8.4,12.08,12.08,0,0,1,8.54-3.43,12.31,12.31,0,0,1,8.63,3.43,11.08,11.08,0,0,1,3.69,8.44,12.49,12.49,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0,12.53,12.53,0,0,1-3.53-8.84Zm6.82.11a5.92,5.92,0,0,0,1.54,4,4.91,4.91,0,0,0,3.85,1.75,5,5,0,0,0,3.91-1.76,5.94,5.94,0,0,0,1.55-4,4.75,4.75,0,0,0-1.64-3.69,5.49,5.49,0,0,0-3.82-1.49,5.37,5.37,0,0,0-3.76,1.52,4.9,4.9,0,0,0-1.63,3.69Z" transform="translate(-4 -5.3)"/>
      <path d="M275.35,361.77h-6.93V338.43H278a8.56,8.56,0,0,1,5.56,2.17,6.72,6.72,0,0,1,2.52,5.35,7.62,7.62,0,0,1-3.08,6.23l4,9.59h-7.32l-3.36-7.52v-6a.29.29,0,0,0,.16,0h.19a4.23,4.23,0,0,0,1.68-.39,1.46,1.46,0,0,0,.78-1.5,1.36,1.36,0,0,0-.6-1.23,2.78,2.78,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M287.7,344.27a6,6,0,0,1,2-4.46,6.42,6.42,0,0,1,4.55-1.91,6.55,6.55,0,0,1,3.34.84,6.64,6.64,0,0,1,2.4,2.45,6.16,6.16,0,0,1,2.22-2.29,6.67,6.67,0,0,1,3.06-1h.35a6,6,0,0,1,5,2.19,8.27,8.27,0,0,1,1.73,5.27v16.41h-6.86V346.23a1.79,1.79,0,0,0-.25-.78.73.73,0,0,0-.7-.37.86.86,0,0,0-.77.37,2.09,2.09,0,0,0-.31.82v15.5h-6.9V346.23a1.78,1.78,0,0,0-.24-.78.73.73,0,0,0-.7-.37.88.88,0,0,0-.81.42,1.79,1.79,0,0,0-.24.91v15.36h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M324.07,356.21h-1.89v5.56h-7v-15a8.82,8.82,0,0,1,2.35-6.38,8.41,8.41,0,0,1,6.37-2.44,7.51,7.51,0,0,1,6,2.63,9.23,9.23,0,0,1,2.26,6.23v15h-7V346.55a2.28,2.28,0,0,0-.36-1.12,1.18,1.18,0,0,0-1-.56,1.3,1.3,0,0,0-1.16.58,2.34,2.34,0,0,0-.38,1.31v3.32h1.89Z" transform="translate(-4 -5.3)"/>
      <path d="M334.88,346.41a8.49,8.49,0,0,1,2.38-6,7.74,7.74,0,0,1,5.88-2.56,8.4,8.4,0,0,1,6.13,2.44,8.16,8.16,0,0,1,2.48,6.1v15.33h-7V346.65a1.71,1.71,0,0,0-.4-1.13,1.5,1.5,0,0,0-2.21,0,1.78,1.78,0,0,0-.4,1.16v15.08h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M378,350.57a22,22,0,0,1-.68,3.36,11.2,11.2,0,0,1-1.42,3.08,10.76,10.76,0,0,1-4.38,4,12.66,12.66,0,0,1-5.77,1.4,11.67,11.67,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.57,11.57,0,0,1,3.68-8.52,11.77,11.77,0,0,1,8.54-3.63,11.5,11.5,0,0,1,7.94,2.87,13.65,13.65,0,0,1,4.24,7.35l-7.42.46a6.57,6.57,0,0,0-1.87-2.43,4.87,4.87,0,0,0-6.39.58,4.88,4.88,0,0,0-1.44,3.46,4.61,4.61,0,0,0,1.49,3.43,4.79,4.79,0,0,0,3.45,1.43,4.48,4.48,0,0,0,3.43-1.33,5.87,5.87,0,0,0,1.54-3.32Z" transform="translate(-4 -5.3)"/>
      <path d="M393.05,352.74H388.5s0,0-.05,0h-.05a3,3,0,0,0-1.1.19q-.51.19-.51,1a.93.93,0,0,0,.47.95,2.93,2.93,0,0,0,1,.24h.53l4.65,0v6.68h-5.7a12.68,12.68,0,0,1-2.89-.31,6,6,0,0,1-2.57-1.4,6.7,6.7,0,0,1-1.63-2.24,6.62,6.62,0,0,1-.58-2.7V344.8a5.92,5.92,0,0,1,2-4.49,6.66,6.66,0,0,1,4.6-1.88l6.72,0v6.76h-4.65l-.05,0h-.06a2.13,2.13,0,0,0-1,.25,1,1,0,0,0-.46.91.89.89,0,0,0,.42.85,2.44,2.44,0,0,0,.91.3h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M284.28,382.76V405.2a2.62,2.62,0,0,0,.36,1.37,1.26,1.26,0,0,0,1.17.62,1.07,1.07,0,0,0,1-.49,2.31,2.31,0,0,0,.36-1,.54.54,0,0,0,.05-.21V382.76h10.05v22.69a2.84,2.84,0,0,0,.46,1.2,1.24,1.24,0,0,0,1.12.54,1.08,1.08,0,0,0,1-.49,2.81,2.81,0,0,0,.4-1v-22.9h10v24c0,.68,0,1.36-.05,2a7.51,7.51,0,0,1-.36,2,9.45,9.45,0,0,1-3.59,5,9.77,9.77,0,0,1-5.84,1.89l-.49,0a3.64,3.64,0,0,0-.48,0,9.74,9.74,0,0,1-4.18-1.53,9,9,0,0,1-3.06-3.16,9.52,9.52,0,0,1-3.5,3.54,9.69,9.69,0,0,1-4.87,1.2,9.35,9.35,0,0,1-6.63-2.78,8.74,8.74,0,0,1-2.9-6.52V382.76Z" transform="translate(-4 -5.3)"/>
      <path d="M326,416.77H315.7v-34H326Z" transform="translate(-4 -5.3)"/>
      <path d="M331.46,394.38a12.35,12.35,0,0,1,3.46-8.67,11.26,11.26,0,0,1,8.57-3.72,12.26,12.26,0,0,1,8.93,3.55,12,12,0,0,1,3.62,8.9v22.33H345.89v-22a2.5,2.5,0,0,0-.59-1.66,2,2,0,0,0-1.6-.68,2,2,0,0,0-1.61.71,2.59,2.59,0,0,0-.59,1.68v22h-10Z" transform="translate(-4 -5.3)"/>
      <path d="M371.75,416.77h-10.2v-34h15.2c1,0,2,.1,2.95.2a12.26,12.26,0,0,1,2.91.66,17.76,17.76,0,0,1,8.18,6.08,15.39,15.39,0,0,1,3.35,9.6,17.07,17.07,0,0,1-5,12.65,17.38,17.38,0,0,1-12.62,5h-.79a7.13,7.13,0,0,1-.79-.05c-.27,0-.54,0-.79,0s-.52,0-.79,0v-10h2.6a8.73,8.73,0,0,0,5.56-1.76,6.17,6.17,0,0,0,2.39-5.23,6.49,6.49,0,0,0-2.11-4.94,7,7,0,0,0-5-2l-5.05,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.79,445.77h-6.2V425.1h9.23q.9,0,1.8.12a7.81,7.81,0,0,1,1.77.4,10.91,10.91,0,0,1,5,3.7,9.36,9.36,0,0,1,2,5.84,10.35,10.35,0,0,1-3.06,7.68,10.53,10.53,0,0,1-7.67,3.06h-.48l-.48,0-.48,0-.48,0v-6.07h1.58a5.33,5.33,0,0,0,3.38-1.07,3.73,3.73,0,0,0,1.45-3.18,3.92,3.92,0,0,0-1.28-3,4.26,4.26,0,0,0-3-1.21l-3.07,0Z" transform="translate(-4 -5.3)"/>
      <path d="M258.33,445.77h-6.14V425.1h8.5a7.59,7.59,0,0,1,4.93,1.92,6,6,0,0,1,2.23,4.74,6.83,6.83,0,0,1-2.73,5.52l3.57,8.49h-6.48l-3-6.66v-5.3a.32.32,0,0,0,.14,0h.17a4.06,4.06,0,0,0,1.49-.34,1.32,1.32,0,0,0,.68-1.34,1.18,1.18,0,0,0-.53-1.08,2.28,2.28,0,0,0-1.17-.37h-.16l-1.52,0Z" transform="translate(-4 -5.3)"/>
      <path d="M275.72,445.77h-6.23V425.1h6.23Z" transform="translate(-4 -5.3)"/>
      <path d="M284.77,447l-8.86-21.85H283l1.89,6.35,1.8-6.35h7.47Z" transform="translate(-4 -5.3)"/>
      <path d="M305.82,437.78h-4l0,0h-.05a2.62,2.62,0,0,0-1,.17c-.3.11-.45.4-.45.85a.83.83,0,0,0,.42.84,2.75,2.75,0,0,0,.92.21h.46l4.12,0v5.92h-5.05a11,11,0,0,1-2.56-.28,5.07,5.07,0,0,1-2.27-1.24,5.85,5.85,0,0,1-2-4.37v-9.14a5.24,5.24,0,0,1,1.82-4,5.85,5.85,0,0,1,4.07-1.66l5.95,0v6h-4.12l0,0h0a1.82,1.82,0,0,0-.9.22.82.82,0,0,0-.4.8.78.78,0,0,0,.37.75,2.13,2.13,0,0,0,.8.28h4Z" transform="translate(-4 -5.3)"/>
      <path d="M308.86,432.16a7.48,7.48,0,0,1,2.11-5.27,6.85,6.85,0,0,1,5.21-2.26,7.44,7.44,0,0,1,5.42,2.16,7.24,7.24,0,0,1,2.2,5.41v13.57h-6.17V432.38a1.55,1.55,0,0,0-.35-1,1.22,1.22,0,0,0-1-.41,1.21,1.21,0,0,0-1,.43,1.6,1.6,0,0,0-.35,1v13.36h-6.11Z" transform="translate(-4 -5.3)"/>
      <path d="M342.42,445.77h-6.14V425.1h8.5a7.61,7.61,0,0,1,4.93,1.92,6,6,0,0,1,2.23,4.74,6.83,6.83,0,0,1-2.73,5.52l3.56,8.49h-6.48l-3-6.66v-5.3a.32.32,0,0,0,.14,0h.17a4.06,4.06,0,0,0,1.49-.34,1.32,1.32,0,0,0,.68-1.34,1.2,1.2,0,0,0-.53-1.08,2.28,2.28,0,0,0-1.18-.37h-.15l-1.52,0Z" transform="translate(-4 -5.3)"/>
      <path d="M361.27,440.84h-1.68v4.93h-6.23V432.44a7.81,7.81,0,0,1,2.08-5.65,7.46,7.46,0,0,1,5.64-2.16A6.67,6.67,0,0,1,366.4,427a8.2,8.2,0,0,1,2,5.51v13.3h-6.23V432.29a2,2,0,0,0-.33-1,1.05,1.05,0,0,0-.88-.5,1.13,1.13,0,0,0-1,.51,2,2,0,0,0-.35,1.16v2.95h1.68Z" transform="translate(-4 -5.3)"/>
      <path d="M377.29,445.77h-6.23V425.1h6.23Z" transform="translate(-4 -5.3)"/>
      <path d="M380,432.16a7.48,7.48,0,0,1,2.11-5.27,6.85,6.85,0,0,1,5.21-2.26,7.44,7.44,0,0,1,5.42,2.16,7.24,7.24,0,0,1,2.2,5.41v13.57h-6.17V432.38a1.55,1.55,0,0,0-.35-1,1.22,1.22,0,0,0-1-.41,1.21,1.21,0,0,0-1,.43,1.6,1.6,0,0,0-.35,1v13.36H380Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'WATERAIR'" id="WATER_AIR">
      <path d="M250.62,217.09v24.2a2.94,2.94,0,0,0,.38,1.48,1.36,1.36,0,0,0,1.27.66,1.16,1.16,0,0,0,1-.52,2.53,2.53,0,0,0,.38-1.13.42.42,0,0,0,.05-.22V217.09h10.84v24.47a3.25,3.25,0,0,0,.49,1.3,1.37,1.37,0,0,0,1.21.57,1.14,1.14,0,0,0,1-.52,3.06,3.06,0,0,0,.44-1.13V217.09h10.78V243c0,.73,0,1.46-.06,2.17a8.08,8.08,0,0,1-.38,2.12,10.24,10.24,0,0,1-3.88,5.4,10.53,10.53,0,0,1-6.29,2,4.45,4.45,0,0,1-.53,0,4.28,4.28,0,0,0-.52,0,10.44,10.44,0,0,1-4.51-1.65,9.68,9.68,0,0,1-3.3-3.41,10.2,10.2,0,0,1-3.77,3.83,10.48,10.48,0,0,1-5.25,1.29,10.05,10.05,0,0,1-7.15-3,9.42,9.42,0,0,1-3.13-7V217.09Z" transform="translate(-4 -5.3)"/>
      <path d="M296.6,245h-3v8.74H282.58V230.12a13.83,13.83,0,0,1,3.68-10q3.69-3.83,10-3.83a11.82,11.82,0,0,1,9.43,4.13,14.5,14.5,0,0,1,3.55,9.79v23.59H298.2V229.85a3.68,3.68,0,0,0-.58-1.76,1.87,1.87,0,0,0-1.57-.88,2,2,0,0,0-1.81.91,3.57,3.57,0,0,0-.61,2.06v5.22h3Z" transform="translate(-4 -5.3)"/>
      <path d="M330.37,253.77H319.42V227.54h-8.36V217.09h27.56v10.45h-8.25Z" transform="translate(-4 -5.3)"/>
      <path d="M361.17,239.58H354a.21.21,0,0,1-.08.06h-.09a4.87,4.87,0,0,0-1.73.3c-.53.2-.8.71-.8,1.51a1.45,1.45,0,0,0,.75,1.49,4.48,4.48,0,0,0,1.62.38h.82l7.32,0v10.5h-9a19.85,19.85,0,0,1-4.53-.49,9.33,9.33,0,0,1-4.05-2.2,10.52,10.52,0,0,1-3.46-7.76V227.1a9.24,9.24,0,0,1,3.22-7,10.41,10.41,0,0,1,7.23-3l10.56-.06v10.62h-7.32a.1.1,0,0,1-.08,0h-.08a3.3,3.3,0,0,0-1.6.39,1.48,1.48,0,0,0-.71,1.43,1.39,1.39,0,0,0,.66,1.33,4,4,0,0,0,1.43.48h7Z" transform="translate(-4 -5.3)"/>
      <path d="M377.45,253.77H366.56V217.09h15.07a13.46,13.46,0,0,1,8.74,3.41,10.57,10.57,0,0,1,4,8.41,12,12,0,0,1-4.84,9.79l6.33,15.07h-11.5L379,242v-9.41a.54.54,0,0,0,.25.06h.3a6.91,6.91,0,0,0,2.64-.61,2.3,2.3,0,0,0,1.21-2.36,2.14,2.14,0,0,0-.93-1.93,4.23,4.23,0,0,0-2.09-.66h-.28l-2.69,0Z" transform="translate(-4 -5.3)"/>
      <path d="M211.18,265.22a2.31,2.31,0,0,0,1.27-.28,1.15,1.15,0,0,0,.56-1.09,1.53,1.53,0,0,0-.4-1,1.32,1.32,0,0,0-1-.45,1.13,1.13,0,0,0-1,.49,2.29,2.29,0,0,0-.4,1.09v13.82H204V264.51q0-.69.06-1.38a4.81,4.81,0,0,1,.34-1.38,7.83,7.83,0,0,1,2.87-3.68,7.48,7.48,0,0,1,4.42-1.44,8.08,8.08,0,0,1,5.44,2.13,6.68,6.68,0,0,1,2.43,5.25,7.22,7.22,0,0,1-.62,2.92,6.24,6.24,0,0,1-1.86,2.39l-.23.17-.2.14a5.72,5.72,0,0,1-2,.83,12.64,12.64,0,0,1-2.2.18h-1.24Z" transform="translate(-4 -5.3)"/>
      <path d="M233.84,269.78h-4l-.05,0h0a2.62,2.62,0,0,0-1,.17c-.3.11-.45.4-.45.85a.83.83,0,0,0,.42.84,2.7,2.7,0,0,0,.92.21h.46l4.12,0v5.92h-5.05a11,11,0,0,1-2.56-.28,5.23,5.23,0,0,1-2.28-1.23,5.93,5.93,0,0,1-1.95-4.38v-9.14a5.27,5.27,0,0,1,1.81-4,5.88,5.88,0,0,1,4.08-1.66l6,0v6h-4.12l-.05,0h0a1.82,1.82,0,0,0-.9.22.82.82,0,0,0-.41.8.79.79,0,0,0,.38.75,2,2,0,0,0,.8.28h4Z" transform="translate(-4 -5.3)"/>
      <path d="M238.24,264.16a7.44,7.44,0,0,1,2.11-5.26,6.83,6.83,0,0,1,5.21-2.27,7.42,7.42,0,0,1,5.42,2.16,7.24,7.24,0,0,1,2.2,5.41v13.57H247V264.38a1.5,1.5,0,0,0-.35-1,1.24,1.24,0,0,0-1-.41,1.17,1.17,0,0,0-1,.43,1.55,1.55,0,0,0-.36,1v13.36h-6.11Z" transform="translate(-4 -5.3)"/>
      <path d="M268.68,269.78h-4l0,0h-.05a2.62,2.62,0,0,0-1,.17c-.3.11-.45.4-.45.85a.83.83,0,0,0,.42.84,2.75,2.75,0,0,0,.92.21h.46l4.13,0v5.92H264a10.89,10.89,0,0,1-2.55-.28,5.13,5.13,0,0,1-2.28-1.23,5.81,5.81,0,0,1-1.44-2,5.65,5.65,0,0,1-.52-2.39v-9.14a5.24,5.24,0,0,1,1.82-4,5.85,5.85,0,0,1,4.07-1.66l6,0v6h-4.13l0,0h-.05a1.82,1.82,0,0,0-.9.22.82.82,0,0,0-.4.8.78.78,0,0,0,.37.75,2.11,2.11,0,0,0,.81.28h4Z" transform="translate(-4 -5.3)"/>
      <path d="M282.73,277.77h-6.17V263h-4.71V257.1h15.53V263h-4.65Z" transform="translate(-4 -5.3)"/>
      <path d="M296.27,277.77h-6.14V257.1h8.5a7.59,7.59,0,0,1,4.93,1.92,6,6,0,0,1,2.23,4.74,6.83,6.83,0,0,1-2.73,5.52l3.57,8.49h-6.48l-3-6.66v-5.3a.32.32,0,0,0,.14,0h.17a4,4,0,0,0,1.49-.34,1.32,1.32,0,0,0,.68-1.34,1.2,1.2,0,0,0-.52-1.08,2.34,2.34,0,0,0-1.18-.37h-.16l-1.52,0Z" transform="translate(-4 -5.3)"/>
      <path d="M316.49,272.85h-1.68v4.92h-6.23V264.44a7.77,7.77,0,0,1,2.08-5.65,7.44,7.44,0,0,1,5.64-2.16,6.69,6.69,0,0,1,5.32,2.33,8.21,8.21,0,0,1,2,5.52v13.29h-6.24V264.29a2.11,2.11,0,0,0-.32-1,1.06,1.06,0,0,0-.89-.5,1.15,1.15,0,0,0-1,.51,2,2,0,0,0-.34,1.17v2.94h1.68Z" transform="translate(-4 -5.3)"/>
      <path d="M337.07,277.77H330.9V263h-4.71V257.1h15.53V263h-4.65Z" transform="translate(-4 -5.3)"/>
      <path d="M350.74,277.77h-6.23V257.1h6.23Z" transform="translate(-4 -5.3)"/>
      <path d="M354,267.11a9.85,9.85,0,0,1,3.23-7.44,10.7,10.7,0,0,1,7.56-3,10.88,10.88,0,0,1,7.64,3,9.81,9.81,0,0,1,3.27,7.47,11,11,0,0,1-3.13,7.83,10.61,10.61,0,0,1-15.44,0,11.09,11.09,0,0,1-3.13-7.83Zm6.05.09a5.24,5.24,0,0,0,1.36,3.57,4.56,4.56,0,0,0,6.87,0,5.2,5.2,0,0,0,1.38-3.55,4.24,4.24,0,0,0-1.46-3.27,4.88,4.88,0,0,0-3.38-1.32,4.72,4.72,0,0,0-3.33,1.35,4.32,4.32,0,0,0-1.44,3.27Z" transform="translate(-4 -5.3)"/>
      <path d="M378.64,264.16a7.44,7.44,0,0,1,2.11-5.26,6.83,6.83,0,0,1,5.21-2.27,7.45,7.45,0,0,1,5.42,2.16,7.24,7.24,0,0,1,2.2,5.41v13.57h-6.17V264.38a1.55,1.55,0,0,0-.35-1,1.25,1.25,0,0,0-1-.41,1.21,1.21,0,0,0-1,.43,1.54,1.54,0,0,0-.35,1v13.36h-6.11Z" transform="translate(-4 -5.3)"/>
      <path d="M322.35,322.92H319v9.85H306.54V306.11q0-7,4.15-11.31T322,290.49a13.33,13.33,0,0,1,10.63,4.65,16.36,16.36,0,0,1,4,11v26.59H324.15v-27a4.12,4.12,0,0,0-.65-2,2.11,2.11,0,0,0-1.77-1,2.31,2.31,0,0,0-2.05,1,4.14,4.14,0,0,0-.68,2.33v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M355.77,332.77H343.3V291.42h12.47Z" transform="translate(-4 -5.3)"/>
      <path d="M375.11,332.77H362.83V291.42h17a15.17,15.17,0,0,1,9.86,3.84,11.93,11.93,0,0,1,4.46,9.49,13.61,13.61,0,0,1-5.45,11l7.13,17h-13l-5.95-13.33v-10.6a.61.61,0,0,0,.28.06h.34a7.65,7.65,0,0,0,3-.68,2.61,2.61,0,0,0,1.37-2.66,2.37,2.37,0,0,0-1.06-2.17,4.57,4.57,0,0,0-2.35-.75h-.31l-3-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M182.29,347.6a2.63,2.63,0,0,0,1.43-.32,1.27,1.27,0,0,0,.63-1.22,1.7,1.7,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.49,2.49,0,0,0-.45,1.22v15.61h-7v-15c0-.51,0-1,.07-1.55a5.41,5.41,0,0,1,.39-1.56,8.79,8.79,0,0,1,3.23-4.15,8.48,8.48,0,0,1,5-1.63,9.1,9.1,0,0,1,6.14,2.4,7.58,7.58,0,0,1,2.75,5.93,8.05,8.05,0,0,1-.7,3.3,7.23,7.23,0,0,1-2.1,2.7l-.26.19-.23.15a6.27,6.27,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M206.33,352.74h-4.55s0,0-.05,0h0a3.08,3.08,0,0,0-1.11.19q-.51.19-.51,1a.92.92,0,0,0,.48.95,2.88,2.88,0,0,0,1,.24h.53l4.65,0v6.68H201a12.65,12.65,0,0,1-2.88-.31,6,6,0,0,1-2.58-1.4,6.67,6.67,0,0,1-1.62-2.24,6.46,6.46,0,0,1-.58-2.7V344.8a5.89,5.89,0,0,1,2.05-4.49,6.64,6.64,0,0,1,4.6-1.88l6.72,0v6.76H202.1l-.06,0h0a2.13,2.13,0,0,0-1,.25.93.93,0,0,0-.46.91.89.89,0,0,0,.42.85,2.39,2.39,0,0,0,.91.3h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M216.9,361.77H210V338.43h9.59a8.58,8.58,0,0,1,5.57,2.17,6.74,6.74,0,0,1,2.51,5.35,7.62,7.62,0,0,1-3.08,6.23l4,9.59h-7.31l-3.36-7.52v-6a.24.24,0,0,0,.15,0h.19a4.32,4.32,0,0,0,1.69-.39,1.47,1.47,0,0,0,.76-1.5,1.35,1.35,0,0,0-.59-1.23,2.78,2.78,0,0,0-1.33-.42h-.17l-1.72,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.39,355.68h-2.34v6.09h-6.9V346.83a8.68,8.68,0,0,1,2.61-6.25A8.44,8.44,0,0,1,236,337.9a8.19,8.19,0,0,1,4,1,9.86,9.86,0,0,1,3.1,2.66l-5.7,4.13a3.15,3.15,0,0,0-.58-.91,1.31,1.31,0,0,0-1-.38,1.64,1.64,0,0,0-1.33.56,2.16,2.16,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M241.89,349.73a11.1,11.1,0,0,1,3.64-8.4,12.08,12.08,0,0,1,8.54-3.43,12.31,12.31,0,0,1,8.63,3.43,11.08,11.08,0,0,1,3.69,8.44,12.49,12.49,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0,12.53,12.53,0,0,1-3.53-8.84Zm6.82.11a5.92,5.92,0,0,0,1.54,4,4.91,4.91,0,0,0,3.85,1.75,5,5,0,0,0,3.91-1.76,5.94,5.94,0,0,0,1.55-4,4.75,4.75,0,0,0-1.64-3.69,5.49,5.49,0,0,0-3.82-1.49,5.37,5.37,0,0,0-3.76,1.52,4.9,4.9,0,0,0-1.63,3.69Z" transform="translate(-4 -5.3)"/>
      <path d="M275.35,361.77h-6.93V338.43H278a8.56,8.56,0,0,1,5.56,2.17,6.72,6.72,0,0,1,2.52,5.35,7.62,7.62,0,0,1-3.08,6.23l4,9.59h-7.32l-3.36-7.52v-6a.29.29,0,0,0,.16,0h.19a4.23,4.23,0,0,0,1.68-.39,1.46,1.46,0,0,0,.78-1.5,1.36,1.36,0,0,0-.6-1.23,2.78,2.78,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M287.7,344.27a6,6,0,0,1,2-4.46,6.42,6.42,0,0,1,4.55-1.91,6.55,6.55,0,0,1,3.34.84,6.64,6.64,0,0,1,2.4,2.45,6.16,6.16,0,0,1,2.22-2.29,6.67,6.67,0,0,1,3.06-1h.35a6,6,0,0,1,5,2.19,8.27,8.27,0,0,1,1.73,5.27v16.41h-6.86V346.23a1.79,1.79,0,0,0-.25-.78.73.73,0,0,0-.7-.37.86.86,0,0,0-.77.37,2.09,2.09,0,0,0-.31.82v15.5h-6.9V346.23a1.78,1.78,0,0,0-.24-.78.73.73,0,0,0-.7-.37.88.88,0,0,0-.81.42,1.79,1.79,0,0,0-.24.91v15.36h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M324.07,356.21h-1.89v5.56h-7v-15a8.82,8.82,0,0,1,2.35-6.38,8.41,8.41,0,0,1,6.37-2.44,7.51,7.51,0,0,1,6,2.63,9.23,9.23,0,0,1,2.26,6.23v15h-7V346.55a2.28,2.28,0,0,0-.36-1.12,1.18,1.18,0,0,0-1-.56,1.3,1.3,0,0,0-1.16.58,2.34,2.34,0,0,0-.38,1.31v3.32h1.89Z" transform="translate(-4 -5.3)"/>
      <path d="M334.88,346.41a8.49,8.49,0,0,1,2.38-6,7.74,7.74,0,0,1,5.88-2.56,8.4,8.4,0,0,1,6.13,2.44,8.16,8.16,0,0,1,2.48,6.1v15.33h-7V346.65a1.71,1.71,0,0,0-.4-1.13,1.5,1.5,0,0,0-2.21,0,1.78,1.78,0,0,0-.4,1.16v15.08h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M378,350.57a22,22,0,0,1-.68,3.36,11.2,11.2,0,0,1-1.42,3.08,10.76,10.76,0,0,1-4.38,4,12.66,12.66,0,0,1-5.77,1.4,11.67,11.67,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.57,11.57,0,0,1,3.68-8.52,11.77,11.77,0,0,1,8.54-3.63,11.5,11.5,0,0,1,7.94,2.87,13.65,13.65,0,0,1,4.24,7.35l-7.42.46a6.57,6.57,0,0,0-1.87-2.43,4.87,4.87,0,0,0-6.39.58,4.88,4.88,0,0,0-1.44,3.46,4.61,4.61,0,0,0,1.49,3.43,4.79,4.79,0,0,0,3.45,1.43,4.48,4.48,0,0,0,3.43-1.33,5.87,5.87,0,0,0,1.54-3.32Z" transform="translate(-4 -5.3)"/>
      <path d="M393.05,352.74H388.5s0,0-.05,0h-.05a3,3,0,0,0-1.1.19q-.51.19-.51,1a.93.93,0,0,0,.47.95,2.93,2.93,0,0,0,1,.24h.53l4.65,0v6.68h-5.7a12.68,12.68,0,0,1-2.89-.31,6,6,0,0,1-2.57-1.4,6.7,6.7,0,0,1-1.63-2.24,6.62,6.62,0,0,1-.58-2.7V344.8a5.92,5.92,0,0,1,2-4.49,6.66,6.66,0,0,1,4.6-1.88l6.72,0v6.76h-4.65l-.05,0h-.06a2.13,2.13,0,0,0-1,.25,1,1,0,0,0-.46.91.89.89,0,0,0,.42.85,2.44,2.44,0,0,0,.91.3h4.48Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'A' || seal === 'AIR'" id="AIR">
      <path d="M322.29,314.05h-3.35v9.86H306.48V297.25q0-7,4.15-11.32t11.29-4.3a13.33,13.33,0,0,1,10.63,4.65,16.34,16.34,0,0,1,4,11v26.6H324.09v-27a4.12,4.12,0,0,0-.65-2,2.1,2.1,0,0,0-1.77-1,2.3,2.3,0,0,0-2.05,1,4.1,4.1,0,0,0-.68,2.32v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M355.71,323.91H343.25V282.56h12.46Z" transform="translate(-4 -5.3)"/>
      <path d="M375.05,323.91H362.77V282.56h17a15.19,15.19,0,0,1,9.86,3.84,11.9,11.9,0,0,1,4.46,9.49,13.61,13.61,0,0,1-5.45,11l7.13,17h-13l-5.95-13.33V300a.61.61,0,0,0,.28.06h.34a7.93,7.93,0,0,0,3-.68,2.63,2.63,0,0,0,1.36-2.67,2.39,2.39,0,0,0-1.06-2.17,4.66,4.66,0,0,0-2.35-.74h-.31l-3-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M182.23,338.73a2.62,2.62,0,0,0,1.43-.31,1.28,1.28,0,0,0,.63-1.23,1.72,1.72,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.56,2.56,0,0,0-.45,1.23v15.61h-7v-15c0-.51,0-1,.07-1.56a5.58,5.58,0,0,1,.39-1.56,8.72,8.72,0,0,1,3.23-4.14,8.41,8.41,0,0,1,5-1.63,9.1,9.1,0,0,1,6.14,2.4,7.56,7.56,0,0,1,2.75,5.93,8,8,0,0,1-.7,3.29,7.07,7.07,0,0,1-2.1,2.7l-.26.19-.23.16a6.45,6.45,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M206.27,343.88h-4.55l-.05,0h0a3.08,3.08,0,0,0-1.11.2q-.51.19-.51,1a.91.91,0,0,0,.48.94,2.89,2.89,0,0,0,1,.25H202l4.65,0v6.69H201a12.64,12.64,0,0,1-2.88-.32,5.88,5.88,0,0,1-2.58-1.4A6.57,6.57,0,0,1,193.9,349a6.45,6.45,0,0,1-.58-2.69V335.93a5.87,5.87,0,0,1,2.05-4.48,6.61,6.61,0,0,1,4.6-1.89l6.72,0v6.75H202s0,0-.06,0h0a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.58,2.58,0,0,0,.91.31h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M216.84,352.91h-6.93V329.56h9.59a8.61,8.61,0,0,1,5.56,2.17,6.71,6.71,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32l-3.36-7.53v-6a.41.41,0,0,0,.16,0h.2a4.39,4.39,0,0,0,1.67-.38,1.48,1.48,0,0,0,.78-1.51,1.34,1.34,0,0,0-.6-1.22,2.58,2.58,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.33,346.82H234v6.09h-6.9V338a8.65,8.65,0,0,1,2.61-6.24A8.4,8.4,0,0,1,236,329a8.19,8.19,0,0,1,4,1,9.73,9.73,0,0,1,3.1,2.66l-5.71,4.13a2.92,2.92,0,0,0-.57-.91,1.28,1.28,0,0,0-1-.39,1.64,1.64,0,0,0-1.33.56,2.18,2.18,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M241.83,340.87a11.1,11.1,0,0,1,3.64-8.4A12,12,0,0,1,254,329a12.28,12.28,0,0,1,8.63,3.43,11.07,11.07,0,0,1,3.69,8.43,12.48,12.48,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0,12.51,12.51,0,0,1-3.53-8.84Zm6.82.1a6,6,0,0,0,1.54,4,4.91,4.91,0,0,0,3.85,1.75A5,5,0,0,0,258,345a5.88,5.88,0,0,0,1.55-4,4.75,4.75,0,0,0-1.64-3.69,5.54,5.54,0,0,0-3.82-1.49,5.34,5.34,0,0,0-3.76,1.53,4.87,4.87,0,0,0-1.63,3.69Z" transform="translate(-4 -5.3)"/>
      <path d="M275.29,352.91h-6.93V329.56H278a8.61,8.61,0,0,1,5.56,2.17,6.74,6.74,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.31l-3.37-7.53v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.47,1.47,0,0,0,.77-1.51,1.34,1.34,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42H277l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M287.64,335.41a6,6,0,0,1,2-4.46,6.38,6.38,0,0,1,4.55-1.91,6.55,6.55,0,0,1,3.34.84,6.64,6.64,0,0,1,2.4,2.45,6.09,6.09,0,0,1,2.22-2.29,6.56,6.56,0,0,1,3.06-1h.35a6,6,0,0,1,5,2.19,8.24,8.24,0,0,1,1.73,5.26v16.42h-6.86V337.37a1.88,1.88,0,0,0-.25-.79.75.75,0,0,0-.7-.37.88.88,0,0,0-.77.37,2.15,2.15,0,0,0-.31.82v15.51h-6.9V337.37a1.86,1.86,0,0,0-.24-.79.75.75,0,0,0-.7-.37.88.88,0,0,0-.81.42,1.82,1.82,0,0,0-.24.91v15.37h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M324,347.34h-1.89v5.57h-7V337.86a8.83,8.83,0,0,1,2.35-6.39A8.44,8.44,0,0,1,323.8,329a7.5,7.5,0,0,1,6,2.62,9.23,9.23,0,0,1,2.26,6.23v15h-7V337.68a2.31,2.31,0,0,0-.36-1.12,1.22,1.22,0,0,0-1-.56,1.32,1.32,0,0,0-1.16.58,2.36,2.36,0,0,0-.38,1.31v3.33H324Z" transform="translate(-4 -5.3)"/>
      <path d="M334.82,337.54a8.5,8.5,0,0,1,2.38-6,7.73,7.73,0,0,1,5.88-2.55,8.43,8.43,0,0,1,6.13,2.43,8.18,8.18,0,0,1,2.48,6.11v15.33h-7V337.79a1.76,1.76,0,0,0-.4-1.14,1.4,1.4,0,0,0-1.11-.47,1.35,1.35,0,0,0-1.1.49,1.75,1.75,0,0,0-.4,1.15v15.09h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M378,341.71a22.47,22.47,0,0,1-.68,3.36,11.34,11.34,0,0,1-1.42,3.08,10.87,10.87,0,0,1-4.38,4,12.5,12.5,0,0,1-5.77,1.4,11.63,11.63,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.6,11.6,0,0,1,3.68-8.52,11.82,11.82,0,0,1,8.54-3.62,11.5,11.5,0,0,1,7.94,2.87,13.57,13.57,0,0,1,4.24,7.35l-7.42.45a6.65,6.65,0,0,0-1.87-2.43,4.86,4.86,0,0,0-6.39.59,4.88,4.88,0,0,0-1.44,3.46,4.59,4.59,0,0,0,1.49,3.42,4.8,4.8,0,0,0,3.45,1.44,4.5,4.5,0,0,0,3.43-1.33,5.93,5.93,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M393,343.88h-4.55l-.05,0h-.05a3,3,0,0,0-1.1.2q-.51.19-.51,1a.92.92,0,0,0,.47.94,2.94,2.94,0,0,0,1,.25h.53l4.65,0v6.69h-5.7a12.67,12.67,0,0,1-2.89-.32,5.85,5.85,0,0,1-2.57-1.4,6.67,6.67,0,0,1-2.21-4.93V335.93a5.9,5.9,0,0,1,2-4.48,6.62,6.62,0,0,1,4.6-1.89l6.72,0v6.75h-4.65s0,0-.05,0h-.06a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.65,2.65,0,0,0,.91.31H393Z" transform="translate(-4 -5.3)"/>
    </g>
    <g *ngIf="seal === 'AF'" id="AIR_FEI">
      <path d="M322.29,314.05h-3.35v9.86H306.48V297.25q0-7,4.15-11.32t11.29-4.3a13.33,13.33,0,0,1,10.63,4.65,16.34,16.34,0,0,1,4,11v26.6H324.09v-27a4.22,4.22,0,0,0-.65-2,2.1,2.1,0,0,0-1.77-1,2.3,2.3,0,0,0-2.05,1,4.1,4.1,0,0,0-.68,2.32v5.89h3.35Z" transform="translate(-4 -5.3)"/>
      <path d="M355.71,323.91H343.24V282.56h12.47Z" transform="translate(-4 -5.3)"/>
      <path d="M375.05,323.91H362.77V282.56h17a15.19,15.19,0,0,1,9.86,3.84,11.9,11.9,0,0,1,4.46,9.49,13.61,13.61,0,0,1-5.45,11l7.13,17h-13l-5.95-13.33V300a.61.61,0,0,0,.28.06h.34a7.83,7.83,0,0,0,3-.68,2.62,2.62,0,0,0,1.37-2.67,2.39,2.39,0,0,0-1.06-2.17,4.66,4.66,0,0,0-2.35-.74h-.31l-3-.06Z" transform="translate(-4 -5.3)"/>
      <path d="M182.23,338.73a2.62,2.62,0,0,0,1.43-.31,1.28,1.28,0,0,0,.63-1.23,1.72,1.72,0,0,0-.45-1.17,1.5,1.5,0,0,0-1.16-.51,1.29,1.29,0,0,0-1.12.56,2.56,2.56,0,0,0-.45,1.23v15.61h-7v-15c0-.51,0-1,.07-1.56a5.58,5.58,0,0,1,.39-1.56,8.72,8.72,0,0,1,3.23-4.14,8.41,8.41,0,0,1,5-1.63,9.1,9.1,0,0,1,6.14,2.4,7.56,7.56,0,0,1,2.75,5.93,8,8,0,0,1-.7,3.29,7.07,7.07,0,0,1-2.1,2.7l-.26.19-.23.16a6.45,6.45,0,0,1-2.31.94,13.89,13.89,0,0,1-2.48.21h-1.4Z" transform="translate(-4 -5.3)"/>
      <path d="M206.27,343.88h-4.55l-.05,0h0a3.08,3.08,0,0,0-1.11.2q-.51.19-.51,1a.91.91,0,0,0,.48.94,2.89,2.89,0,0,0,1,.25H202l4.65,0v6.69H201a12.64,12.64,0,0,1-2.88-.32,5.88,5.88,0,0,1-2.58-1.4A6.57,6.57,0,0,1,193.9,349a6.45,6.45,0,0,1-.58-2.69V335.93a5.87,5.87,0,0,1,2.05-4.48,6.61,6.61,0,0,1,4.6-1.89l6.72,0v6.75H202s0,0-.06,0h0a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.58,2.58,0,0,0,.91.31h4.48Z" transform="translate(-4 -5.3)"/>
      <path d="M216.84,352.91h-6.93V329.56h9.59a8.61,8.61,0,0,1,5.56,2.17,6.74,6.74,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32l-3.36-7.53v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.47,1.47,0,0,0,.77-1.51,1.34,1.34,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42h-.18l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M236.33,346.82H234v6.09h-6.9V338a8.65,8.65,0,0,1,2.61-6.24A8.4,8.4,0,0,1,236,329a8.19,8.19,0,0,1,4,1,9.73,9.73,0,0,1,3.1,2.66l-5.71,4.13a2.92,2.92,0,0,0-.57-.91,1.28,1.28,0,0,0-1-.39,1.64,1.64,0,0,0-1.33.56,2.18,2.18,0,0,0-.45,1.4v3.08h2.34Z" transform="translate(-4 -5.3)"/>
      <path d="M241.83,340.87a11.1,11.1,0,0,1,3.64-8.4A12,12,0,0,1,254,329a12.28,12.28,0,0,1,8.63,3.43,11.07,11.07,0,0,1,3.69,8.43,12.48,12.48,0,0,1-3.54,8.84,12,12,0,0,1-17.43,0,12.51,12.51,0,0,1-3.53-8.84Zm6.82.1a6,6,0,0,0,1.54,4,4.91,4.91,0,0,0,3.85,1.75A5,5,0,0,0,258,345a5.93,5.93,0,0,0,1.55-4,4.75,4.75,0,0,0-1.64-3.69,5.54,5.54,0,0,0-3.82-1.49,5.34,5.34,0,0,0-3.76,1.53,4.87,4.87,0,0,0-1.63,3.69Z" transform="translate(-4 -5.3)"/>
      <path d="M275.29,352.91h-6.93V329.56H278a8.61,8.61,0,0,1,5.56,2.17,6.74,6.74,0,0,1,2.52,5.36,7.8,7.8,0,0,1-.78,3.46,7.72,7.72,0,0,1-2.3,2.77l4,9.59h-7.32l-3.36-7.53v-6a.41.41,0,0,0,.16,0h.19a4.4,4.4,0,0,0,1.68-.38,1.47,1.47,0,0,0,.77-1.51,1.34,1.34,0,0,0-.59-1.22,2.58,2.58,0,0,0-1.33-.42H277l-1.71,0Z" transform="translate(-4 -5.3)"/>
      <path d="M287.64,335.41a6,6,0,0,1,2-4.46,6.38,6.38,0,0,1,4.55-1.91,6.55,6.55,0,0,1,3.34.84,6.64,6.64,0,0,1,2.4,2.45,6.09,6.09,0,0,1,2.22-2.29,6.56,6.56,0,0,1,3.06-1h.35a6,6,0,0,1,5,2.19,8.24,8.24,0,0,1,1.73,5.26v16.42h-6.86V337.37a1.88,1.88,0,0,0-.25-.79.75.75,0,0,0-.7-.37.88.88,0,0,0-.77.37,2.15,2.15,0,0,0-.31.82v15.51h-6.9V337.37a1.86,1.86,0,0,0-.24-.79.75.75,0,0,0-.7-.37.87.87,0,0,0-.81.42,1.82,1.82,0,0,0-.24.91v15.37h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M324,347.34h-1.89v5.57h-7V337.86a8.83,8.83,0,0,1,2.35-6.39A8.44,8.44,0,0,1,323.8,329a7.52,7.52,0,0,1,6,2.62,9.23,9.23,0,0,1,2.26,6.23v15h-7V337.68a2.31,2.31,0,0,0-.36-1.12,1.22,1.22,0,0,0-1-.56,1.32,1.32,0,0,0-1.16.58,2.36,2.36,0,0,0-.38,1.31v3.33H324Z" transform="translate(-4 -5.3)"/>
      <path d="M334.82,337.54a8.5,8.5,0,0,1,2.38-6,7.73,7.73,0,0,1,5.88-2.55,8.43,8.43,0,0,1,6.13,2.43,8.18,8.18,0,0,1,2.48,6.11v15.33h-7V337.79a1.76,1.76,0,0,0-.4-1.14,1.4,1.4,0,0,0-1.11-.47,1.35,1.35,0,0,0-1.1.49,1.75,1.75,0,0,0-.4,1.15v15.09h-6.9Z" transform="translate(-4 -5.3)"/>
      <path d="M378,341.71a22.47,22.47,0,0,1-.68,3.36,11.34,11.34,0,0,1-1.42,3.08,10.87,10.87,0,0,1-4.38,4,12.53,12.53,0,0,1-5.77,1.4,11.63,11.63,0,0,1-8.66-3.62,12,12,0,0,1-3.56-8.7,11.6,11.6,0,0,1,3.68-8.52,11.8,11.8,0,0,1,8.54-3.62,11.5,11.5,0,0,1,7.94,2.87,13.57,13.57,0,0,1,4.24,7.35l-7.42.45a6.65,6.65,0,0,0-1.87-2.43,4.86,4.86,0,0,0-6.39.59,4.88,4.88,0,0,0-1.44,3.46,4.59,4.59,0,0,0,1.49,3.42,4.8,4.8,0,0,0,3.45,1.44,4.5,4.5,0,0,0,3.43-1.33,5.93,5.93,0,0,0,1.54-3.33Z" transform="translate(-4 -5.3)"/>
      <path d="M393,343.88h-4.55l-.05,0h-.05a3,3,0,0,0-1.1.2q-.51.19-.51,1a.92.92,0,0,0,.47.94,2.94,2.94,0,0,0,1,.25h.53l4.65,0v6.69h-5.7a12.67,12.67,0,0,1-2.89-.32,5.8,5.8,0,0,1-2.57-1.4,6.67,6.67,0,0,1-2.21-4.93V335.93a5.9,5.9,0,0,1,2-4.48,6.62,6.62,0,0,1,4.6-1.89l6.72,0v6.75h-4.65l-.05,0h-.06a2.12,2.12,0,0,0-1,.24.94.94,0,0,0-.46.91.88.88,0,0,0,.42.85,2.65,2.65,0,0,0,.91.31H393Z" transform="translate(-4 -5.3)"/>
      <path d="M336.47,393.12h-4.16v10.79H320.1V377.44a15.36,15.36,0,0,1,4.62-11.07,14.88,14.88,0,0,1,11.06-4.74,14.45,14.45,0,0,1,7,1.73,17.41,17.41,0,0,1,5.49,4.71l-10.11,7.32a5,5,0,0,0-1-1.61,2.25,2.25,0,0,0-1.71-.69,2.85,2.85,0,0,0-2.35,1,3.79,3.79,0,0,0-.81,2.48V382h4.16Z" transform="translate(-4 -5.3)"/>
      <path d="M372.55,387.91h-8.06s-.07.07-.09.07h-.1a5.4,5.4,0,0,0-1.95.34c-.6.22-.9.79-.9,1.7a1.64,1.64,0,0,0,.84,1.68,5.45,5.45,0,0,0,1.83.43h.93l8.24-.06v11.84h-10.1a22.57,22.57,0,0,1-5.12-.56,10.51,10.51,0,0,1-4.56-2.48,11.82,11.82,0,0,1-3.9-8.74V373.84a10.44,10.44,0,0,1,3.63-7.95,11.73,11.73,0,0,1,8.15-3.33l11.9-.07v12h-8.24c-.05,0-.08.06-.1.06h-.09a3.75,3.75,0,0,0-1.8.44,1.67,1.67,0,0,0-.8,1.61,1.56,1.56,0,0,0,.74,1.5,4.51,4.51,0,0,0,1.61.54h7.94Z" transform="translate(-4 -5.3)"/>
      <path d="M392.88,403.91H380.42V362.56h12.46Z" transform="translate(-4 -5.3)"/>
      <path d="M240.19,429.25h-1.41v3.66h-4.14v-9a5.22,5.22,0,0,1,1.57-3.75,5,5,0,0,1,3.75-1.6,4.9,4.9,0,0,1,2.38.58,6.05,6.05,0,0,1,1.86,1.6l-3.42,2.48a1.84,1.84,0,0,0-.35-.55.78.78,0,0,0-.58-.23,1,1,0,0,0-.8.34,1.28,1.28,0,0,0-.27.84v1.85h1.41Z" transform="translate(-4 -5.3)"/>
      <path d="M249.39,429.57h-1.14v3.34H244v-9a5.28,5.28,0,0,1,1.41-3.83,5,5,0,0,1,3.82-1.46,4.5,4.5,0,0,1,3.6,1.57,5.57,5.57,0,0,1,1.36,3.74v9H250v-9.14a1.42,1.42,0,0,0-.23-.67.68.68,0,0,0-.59-.33.79.79,0,0,0-.7.34,1.43,1.43,0,0,0-.23.79v2h1.14Z" transform="translate(-4 -5.3)"/>
      <path d="M255.88,423.69a5.07,5.07,0,0,1,1.42-3.57,4.63,4.63,0,0,1,3.53-1.53,5,5,0,0,1,3.68,1.46,4.91,4.91,0,0,1,1.49,3.66v9.2h-4.18v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.81.81,0,0,0-.67.3,1,1,0,0,0-.24.69v9.05h-4.13Z" transform="translate(-4 -5.3)"/>
      <path d="M282.25,427.49h-2.73l0,0h0a1.76,1.76,0,0,0-.66.12c-.21.08-.31.27-.31.58a.54.54,0,0,0,.29.56,1.52,1.52,0,0,0,.61.15h.32l2.79,0v4h-3.42a7.34,7.34,0,0,1-1.73-.19,3.65,3.65,0,0,1-1.55-.84,4,4,0,0,1-1.32-3v-6.2a3.54,3.54,0,0,1,1.23-2.69,4,4,0,0,1,2.76-1.13l4,0v4.05h-2.79l0,0h0a1.22,1.22,0,0,0-.61.14.58.58,0,0,0-.28.55.55.55,0,0,0,.26.51,1.64,1.64,0,0,0,.54.18h2.69Z" transform="translate(-4 -5.3)"/>
      <path d="M284.31,423.69a5,5,0,0,1,1.43-3.57,4.61,4.61,0,0,1,3.52-1.53,5,5,0,0,1,3.68,1.46,4.91,4.91,0,0,1,1.49,3.66v9.2h-4.18v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.8.8,0,0,0-.66.3,1,1,0,0,0-.24.69v9.05h-4.14Z" transform="translate(-4 -5.3)"/>
      <path d="M304,427.49h-2.73l0,0h0a1.8,1.8,0,0,0-.66.12c-.2.08-.3.27-.3.58s.09.48.28.56a1.61,1.61,0,0,0,.62.15h.32l2.79,0v4h-3.43a7.4,7.4,0,0,1-1.73-.19,3.62,3.62,0,0,1-1.54-.84,4,4,0,0,1-1-1.34,4,4,0,0,1-.34-1.62v-6.2a3.57,3.57,0,0,1,1.22-2.69,4,4,0,0,1,2.77-1.13l4,0v4.05h-2.79l0,0h0a1.27,1.27,0,0,0-.61.14.57.57,0,0,0-.27.55.54.54,0,0,0,.25.51,1.69,1.69,0,0,0,.55.18H304Z" transform="translate(-4 -5.3)"/>
      <path d="M310.35,432.91h-4.16v-14h5.75a5.18,5.18,0,0,1,3.34,1.3,4,4,0,0,1,1.51,3.22,4.63,4.63,0,0,1-.47,2.08,4.71,4.71,0,0,1-1.37,1.66l2.41,5.75H313l-2-4.52V424.8a.13.13,0,0,0,.09,0h.12a2.53,2.53,0,0,0,1-.23.88.88,0,0,0,.47-.9.79.79,0,0,0-.36-.73,1.58,1.58,0,0,0-.8-.26h-.1l-1,0Z" transform="translate(-4 -5.3)"/>
      <path d="M328,425.08a7.32,7.32,0,0,1,.9,1.53,4.3,4.3,0,0,1,.36,1.74,4.54,4.54,0,0,1-1.54,3.55,5.31,5.31,0,0,1-3.65,1.37,6,6,0,0,1-5-2.38,8.31,8.31,0,0,1-1.87-5.25,6.74,6.74,0,0,1,2.1-5,6.85,6.85,0,0,1,5-2.07,6.47,6.47,0,0,1,1.18.11,9.5,9.5,0,0,1,1.13.31v4.11l-.14-.05-.13-.05a4.79,4.79,0,0,0-.73-.24,2.73,2.73,0,0,0-.76-.1,3,3,0,0,0-2.24.95,3.38,3.38,0,0,0-1,2.24,3.78,3.78,0,0,0,.83,2.42,2.8,2.8,0,0,0,2.3,1h.14l.16,0a1.16,1.16,0,0,0,.43-.15.44.44,0,0,0,.2-.4.4.4,0,0,0-.21-.39,1.19,1.19,0,0,0-.47-.09h-1v-3.19Z" transform="translate(-4 -5.3)"/>
      <path d="M336.43,425.14v7.77h-4.22v-7.77l-4-6.24h4.22l1.85,2.9,2.1-2.9h4.13Z" transform="translate(-4 -5.3)"/>
      <path d="M349.7,432.91h-4.22v-14h4.22Z" transform="translate(-4 -5.3)"/>
      <path d="M351.51,423.69a5,5,0,0,1,1.43-3.57,4.61,4.61,0,0,1,3.52-1.53,5,5,0,0,1,3.68,1.46,4.91,4.91,0,0,1,1.49,3.66v9.2h-4.18v-9.07a1,1,0,0,0-.24-.69.84.84,0,0,0-.66-.28.8.8,0,0,0-.66.3,1,1,0,0,0-.25.69v9.05h-4.13Z" transform="translate(-4 -5.3)"/>
      <path d="M367.64,432.91h-4.2v-14h6.25c.41,0,.81,0,1.22.09a5,5,0,0,1,1.2.27,7.36,7.36,0,0,1,3.37,2.5,6.31,6.31,0,0,1,1.37,4,7,7,0,0,1-2.06,5.2,7.14,7.14,0,0,1-5.2,2.07h-.33a1.77,1.77,0,0,1-.32,0h-.65v-4.12h1.07a3.54,3.54,0,0,0,2.29-.72,2.55,2.55,0,0,0,1-2.15,2.7,2.7,0,0,0-.87-2,2.91,2.91,0,0,0-2.05-.82l-2.07,0Z" transform="translate(-4 -5.3)"/>
      <path d="M385.86,427.49h-2.73l0,0h0a1.76,1.76,0,0,0-.66.12.55.55,0,0,0-.31.58.54.54,0,0,0,.29.56,1.57,1.57,0,0,0,.62.15h.31l2.8,0v4h-3.43a7.4,7.4,0,0,1-1.73-.19,3.62,3.62,0,0,1-1.54-.84,4,4,0,0,1-1-1.34,4,4,0,0,1-.35-1.62v-6.2a3.54,3.54,0,0,1,1.23-2.69,4,4,0,0,1,2.76-1.13l4,0v4.05h-2.8l0,0h0a1.25,1.25,0,0,0-.61.14.57.57,0,0,0-.27.55.54.54,0,0,0,.25.51,1.72,1.72,0,0,0,.54.18h2.69Z" transform="translate(-4 -5.3)"/>
      <path d="M394.35,425.71l3.76,7.2h-4.39l-1.7-3.4-1.47,3.4h-4.31l3.84-7.08-3.63-6.93h4.33l1.64,3.19,1.3-3.19H398Z" transform="translate(-4 -5.3)"/>
    </g>
    <path class="cls-2" d="M404.52,17.11,87.43,347.74l317.09,262.8Z" transform="translate(-4 -5.3)"/>
  </g>
  <path id="Shape" class="cls-2" d="M5.35,293.92l65.31,54.27L5.35,415.48Z" transform="translate(-4 -5.3)"/>
  <g id="AMCA">
    <g>
      <path d="M24.84,90.33V111a2.44,2.44,0,0,0,.32,1.26,1.16,1.16,0,0,0,1.09.57,1,1,0,0,0,.89-.45,2.12,2.12,0,0,0,.33-1,.43.43,0,0,0,0-.19V90.33h9.26v20.92a2.73,2.73,0,0,0,.43,1.1,1.15,1.15,0,0,0,1,.5,1,1,0,0,0,.89-.45,2.65,2.65,0,0,0,.38-1V90.33h9.21v22.13c0,.63,0,1.25-.05,1.86a7.56,7.56,0,0,1-.32,1.81A8.74,8.74,0,0,1,45,120.74a9,9,0,0,1-5.38,1.74,3.24,3.24,0,0,1-.45,0,3.17,3.17,0,0,0-.44,0A9.14,9.14,0,0,1,34.89,121a8.44,8.44,0,0,1-2.82-2.92,8.72,8.72,0,0,1-3.22,3.27,9,9,0,0,1-4.48,1.1,8.57,8.57,0,0,1-6.11-2.56,8,8,0,0,1-2.68-6V90.33Z" transform="translate(-4 -5.3)"/>
      <path d="M53.51,105.52a14.89,14.89,0,0,1,4.88-11.28,16.19,16.19,0,0,1,11.47-4.61,16.51,16.51,0,0,1,11.59,4.61,14.86,14.86,0,0,1,5,11.32,16.79,16.79,0,0,1-4.75,11.87,16.09,16.09,0,0,1-23.41,0,16.78,16.78,0,0,1-4.74-11.87Zm9.16.14a7.93,7.93,0,0,0,2.07,5.4,6.59,6.59,0,0,0,5.17,2.35A6.7,6.7,0,0,0,75.15,111a7.94,7.94,0,0,0,2.09-5.38,6.42,6.42,0,0,0-2.21-5,7.41,7.41,0,0,0-5.12-2,7.13,7.13,0,0,0-5,2,6.52,6.52,0,0,0-2.19,5Z" transform="translate(-4 -5.3)"/>
      <path d="M100.74,121.68H91.43V90.33h12.88a11.59,11.59,0,0,1,7.48,2.92,9.05,9.05,0,0,1,3.38,7.19,10.36,10.36,0,0,1-4.14,8.37l5.41,12.87h-9.82l-4.52-10.1v-8a.54.54,0,0,0,.21,0h.26a5.81,5.81,0,0,0,2.26-.52,2,2,0,0,0,1-2,1.79,1.79,0,0,0-.8-1.64,3.45,3.45,0,0,0-1.78-.57H103l-2.3,0Z" transform="translate(-4 -5.3)"/>
      <path d="M128.85,90.33V109.6a2.45,2.45,0,0,0,1.22,2.5,6.73,6.73,0,0,0,2.82.56v9H130a9.71,9.71,0,0,1-7.57-3.12,11.11,11.11,0,0,1-2.82-7.73V90.33Z" transform="translate(-4 -5.3)"/>
      <path d="M145.81,121.68h-9.4V90.33h14c.91,0,1.82.1,2.72.19a11.83,11.83,0,0,1,2.68.61,16.43,16.43,0,0,1,7.55,5.61,14.15,14.15,0,0,1,3.08,8.84,15.76,15.76,0,0,1-4.63,11.66,16,16,0,0,1-11.64,4.63h-.72a6.06,6.06,0,0,1-.73,0c-.25,0-.5,0-.73,0l-.73,0v-9.21h2.4a8,8,0,0,0,5.12-1.63,5.66,5.66,0,0,0,2.21-4.81,6,6,0,0,0-1.95-4.56,6.49,6.49,0,0,0-4.58-1.84l-4.66,0Z" transform="translate(-4 -5.3)"/>
      <path d="M180.45,90.33V111a2.44,2.44,0,0,0,.33,1.26,1.15,1.15,0,0,0,1.08.57,1,1,0,0,0,.89-.45,2.12,2.12,0,0,0,.33-1,.45.45,0,0,0,0-.19V90.33h9.26v20.92a2.58,2.58,0,0,0,.42,1.1,1.17,1.17,0,0,0,1,.5,1,1,0,0,0,.89-.45,2.84,2.84,0,0,0,.38-1V90.33h9.21v22.13c0,.63,0,1.25-.05,1.86a7.12,7.12,0,0,1-.33,1.81,8.72,8.72,0,0,1-3.31,4.61,9,9,0,0,1-5.38,1.74,3.24,3.24,0,0,1-.45,0,3.24,3.24,0,0,0-.45,0,9.1,9.1,0,0,1-3.85-1.41,8.44,8.44,0,0,1-2.82-2.92,8.79,8.79,0,0,1-3.22,3.27,9,9,0,0,1-4.49,1.1,8.59,8.59,0,0,1-6.11-2.56,8.08,8.08,0,0,1-2.68-6V90.33Z" transform="translate(-4 -5.3)"/>
      <path d="M220.12,121.68h-9.45V90.33h9.45Z" transform="translate(-4 -5.3)"/>
      <path d="M236.19,121.68h-9.4V90.33h14c.91,0,1.82.1,2.72.19a11.83,11.83,0,0,1,2.68.61,16.43,16.43,0,0,1,7.55,5.61,14.15,14.15,0,0,1,3.08,8.84,15.76,15.76,0,0,1-4.63,11.66,16,16,0,0,1-11.64,4.63h-.73a6,6,0,0,1-.72,0c-.25,0-.5,0-.73,0l-.73,0v-9.21h2.4a8,8,0,0,0,5.12-1.63,5.66,5.66,0,0,0,2.21-4.81,6,6,0,0,0-1.95-4.56,6.49,6.49,0,0,0-4.58-1.84l-4.66,0Z" transform="translate(-4 -5.3)"/>
      <path d="M279.29,109.56h-6.11s-.05,0-.07,0H273a4.18,4.18,0,0,0-1.48.26c-.45.18-.68.61-.68,1.3a1.25,1.25,0,0,0,.63,1.27,4.22,4.22,0,0,0,1.39.32h.7l6.26,0v9H272.2a17.09,17.09,0,0,1-3.88-.42,8,8,0,0,1-3.46-1.88,9,9,0,0,1-3-6.63V98.89a7.94,7.94,0,0,1,2.75-6,8.92,8.92,0,0,1,6.18-2.53l9,0v9.07H273.6s-.05,0-.07,0h-.07a2.93,2.93,0,0,0-1.36.32,1.27,1.27,0,0,0-.61,1.23,1.22,1.22,0,0,0,.56,1.14,3.5,3.5,0,0,0,1.23.41h6Z" transform="translate(-4 -5.3)"/>
      <path d="M47.16,146.64a28.74,28.74,0,0,1-.92,4.52,14.74,14.74,0,0,1-1.9,4.13,14.56,14.56,0,0,1-5.87,5.31,16.92,16.92,0,0,1-7.76,1.88,15.68,15.68,0,0,1-11.63-4.86,16.09,16.09,0,0,1-4.77-11.68,15.57,15.57,0,0,1,4.93-11.45,15.84,15.84,0,0,1,11.47-4.86,15.49,15.49,0,0,1,10.67,3.85,18.28,18.28,0,0,1,5.69,9.87l-10,.61a8.77,8.77,0,0,0-2.51-3.26,6.54,6.54,0,0,0-8.58.78,6.61,6.61,0,0,0-1.93,4.65,6.18,6.18,0,0,0,2,4.6,6.45,6.45,0,0,0,4.63,1.93,6,6,0,0,0,4.61-1.79,7.91,7.91,0,0,0,2.06-4.46Z" transform="translate(-4 -5.3)"/>
      <path d="M69.63,149.56H63.52l-.07,0h-.07a4.14,4.14,0,0,0-1.48.26c-.46.18-.69.61-.69,1.3a1.24,1.24,0,0,0,.64,1.27,4.1,4.1,0,0,0,1.38.32h.71l6.25,0v9H62.53a17.16,17.16,0,0,1-3.88-.42,7.92,7.92,0,0,1-3.45-1.88,8.91,8.91,0,0,1-3-6.63V138.89a7.91,7.91,0,0,1,2.75-6,8.92,8.92,0,0,1,6.18-2.53l9,0v9.07H63.94l-.07,0H63.8a2.9,2.9,0,0,0-1.36.32,1.27,1.27,0,0,0-.62,1.23,1.21,1.21,0,0,0,.57,1.14,3.39,3.39,0,0,0,1.22.41h6Z" transform="translate(-4 -5.3)"/>
      <path d="M86.12,161.68h-9.3V130.33H89.69a11.59,11.59,0,0,1,7.48,2.92,9.05,9.05,0,0,1,3.38,7.19,10.37,10.37,0,0,1-1.06,4.65,10.5,10.5,0,0,1-3.07,3.72l5.4,12.87H92l-4.51-10.1v-8a.46.46,0,0,0,.21.05H88a5.8,5.8,0,0,0,2.25-.52,2,2,0,0,0,1-2,1.81,1.81,0,0,0-.8-1.64,3.51,3.51,0,0,0-1.79-.57h-.23l-2.31-.05Z" transform="translate(-4 -5.3)"/>
      <path d="M119.63,161.68h-9.35V139.27h-7.14v-8.94h23.54v8.94h-7.05Z" transform="translate(-4 -5.3)"/>
      <path d="M140.6,161.68h-9.45V130.33h9.45Z" transform="translate(-4 -5.3)"/>
      <path d="M156.53,153.51h-3.15v8.17h-9.26V141.62a11.65,11.65,0,0,1,3.5-8.39,11.3,11.3,0,0,1,8.39-3.6,11,11,0,0,1,5.34,1.32,12.87,12.87,0,0,1,4.15,3.57l-7.66,5.54a3.67,3.67,0,0,0-.77-1.22,1.72,1.72,0,0,0-1.29-.51,2.17,2.17,0,0,0-1.79.75,2.87,2.87,0,0,0-.61,1.88v4.13h3.15Z" transform="translate(-4 -5.3)"/>
      <path d="M177.21,161.68h-9.45V130.33h9.45Z" transform="translate(-4 -5.3)"/>
      <path d="M201.27,149.56h-6.11s0,0-.07,0H195a4.18,4.18,0,0,0-1.48.26c-.46.18-.68.61-.68,1.3a1.25,1.25,0,0,0,.63,1.27,4.22,4.22,0,0,0,1.39.32h.7l6.25,0v9h-7.66a17,17,0,0,1-3.87-.42,8,8,0,0,1-3.46-1.88,9,9,0,0,1-3-6.63V138.89a7.94,7.94,0,0,1,2.75-6,8.92,8.92,0,0,1,6.18-2.53l9,0v9.07h-6.25s-.05,0-.07,0h-.07a2.93,2.93,0,0,0-1.36.32,1.27,1.27,0,0,0-.61,1.23,1.22,1.22,0,0,0,.56,1.14,3.52,3.52,0,0,0,1.22.41h6Z" transform="translate(-4 -5.3)"/>
      <path d="M217.91,161.68h-9.4V130.33h14c.91,0,1.82.1,2.73.19a12,12,0,0,1,2.68.61,16.35,16.35,0,0,1,7.54,5.61,14.15,14.15,0,0,1,3.08,8.84,15.72,15.72,0,0,1-4.63,11.66,16,16,0,0,1-11.63,4.63h-.73a6.18,6.18,0,0,1-.73,0c-.25,0-.49,0-.73,0l-.72,0v-9.21h2.39a8,8,0,0,0,5.13-1.63,5.65,5.65,0,0,0,2.2-4.81,6,6,0,0,0-2-4.56,6.47,6.47,0,0,0-4.58-1.84l-4.65,0Z" transform="translate(-4 -5.3)"/>
      <path d="M25.16,201.68h-9.3V170.33H28.74a11.58,11.58,0,0,1,7.47,2.92,9,9,0,0,1,3.38,7.19,10.49,10.49,0,0,1-1.05,4.65,10.64,10.64,0,0,1-3.08,3.72l5.4,12.87H31l-4.51-10.1v-8a.46.46,0,0,0,.21.05H27a5.8,5.8,0,0,0,2.25-.52,2,2,0,0,0,1-2,1.81,1.81,0,0,0-.8-1.64,3.48,3.48,0,0,0-1.79-.57h-.23l-2.31-.05Z" transform="translate(-4 -5.3)"/>
      <path d="M56,194.21H53.5v7.47H44.06V181.47a11.81,11.81,0,0,1,3.15-8.57,11.32,11.32,0,0,1,8.55-3.27,10.11,10.11,0,0,1,8.06,3.53,12.38,12.38,0,0,1,3,8.36v20.16H57.41V181.24a3.26,3.26,0,0,0-.5-1.51,1.6,1.6,0,0,0-1.34-.75,1.74,1.74,0,0,0-1.55.78,3.14,3.14,0,0,0-.52,1.76V186H56Z" transform="translate(-4 -5.3)"/>
      <path d="M87.49,201.68H78.13V179.27H71v-8.94H94.54v8.94H87.49Z" transform="translate(-4 -5.3)"/>
      <path d="M108.45,201.68H99V170.33h9.45Z" transform="translate(-4 -5.3)"/>
      <path d="M114.79,181.05a11.37,11.37,0,0,1,3.2-8,10.34,10.34,0,0,1,7.89-3.43,11.31,11.31,0,0,1,8.23,3.27,11,11,0,0,1,3.34,8.2v20.58h-9.36v-20.3a2.31,2.31,0,0,0-.54-1.53,1.84,1.84,0,0,0-1.48-.63,1.8,1.8,0,0,0-1.48.66,2.36,2.36,0,0,0-.54,1.55v20.25h-9.26Z" transform="translate(-4 -5.3)"/>
      <path d="M166.26,184.15a17.16,17.16,0,0,1,2,3.43,9.76,9.76,0,0,1,.8,3.9,10.18,10.18,0,0,1-3.43,8,11.88,11.88,0,0,1-8.18,3,13.4,13.4,0,0,1-11.09-5.31,18.53,18.53,0,0,1-4.19-11.75,15.12,15.12,0,0,1,4.7-11.16A15.32,15.32,0,0,1,158,169.63a13.25,13.25,0,0,1,2.63.26,21.71,21.71,0,0,1,2.54.68v9.21a2.34,2.34,0,0,1-.3-.12l-.31-.11A11.84,11.84,0,0,0,161,179a6.93,6.93,0,0,0-1.72-.22,6.75,6.75,0,0,0-5,2.13,7.38,7.38,0,0,0-2.23,5,8.38,8.38,0,0,0,1.85,5.42,6.24,6.24,0,0,0,5.15,2.31h.3a1.63,1.63,0,0,0,.36-.05,2.12,2.12,0,0,0,1-.33,1,1,0,0,0,.45-.89.86.86,0,0,0-.47-.87,2.54,2.54,0,0,0-1-.21h-2.21v-7.15Z" transform="translate(-4 -5.3)"/>
      <path d="M188.91,170.24v8.88l-.78-.21a2.63,2.63,0,0,0-.77-.12,2.39,2.39,0,0,0-1.83.83,2.66,2.66,0,0,0-.76,1.86,4.24,4.24,0,0,0,.54,1.86c.36.71.76,1.48,1.2,2.33a26.48,26.48,0,0,1,1.2,2.8,9.11,9.11,0,0,1,.54,3.13,10,10,0,0,1-3.43,7.8,11.63,11.63,0,0,1-8,3.08,8.09,8.09,0,0,1-2.13-.28,15.45,15.45,0,0,1-2.05-.7v-9.14a5.45,5.45,0,0,0,1.15.59,3.53,3.53,0,0,0,1.2.21h.38a3.42,3.42,0,0,0,1.71-.8,2.07,2.07,0,0,0,.78-1.65,4,4,0,0,0-.52-1.77c-.34-.67-.74-1.43-1.17-2.28a25.91,25.91,0,0,1-1.16-2.85,10.79,10.79,0,0,1,2.59-11,10.14,10.14,0,0,1,7.54-3.21,12.62,12.62,0,0,1,2,.16,10.39,10.39,0,0,1,1.86.5Z" transform="translate(-4 -5.3)"/>
    </g>
    <g id="AMCA-2" data-name="AMCA">
      <path d="M100,80.91H93V57.55a34.58,34.58,0,0,0-.07-5c-.51-3.3-2.55-5.6-5.89-5.43S82,49.66,82,53.19c0,9,0,18,0,27.4H69.08c0-11.1-1-22.46.28-33.57,1.15-10.43,13.55-14.74,22.32-8.81,1.62,1.1,3.2,2.26,4.21,2.11,4.7-1.77,9.28-4.57,14.11-5.08,7.63-.82,14,6.12,14,13.8V80.71H112V58.63a36.23,36.23,0,0,0,0-5c-.49-3.35-1.27-6.56-5.71-6.67-4.66-.12-6,3-6.17,6.67-.25,7.33-.08,14.66-.08,22Z" transform="translate(-4 -5.3)"/>
      <path d="M205,81V71.7c-2,1.89-3.27,3.23-4.67,4.44-8.37,7.23-21.26,7.47-28.37-3-1.44-2.11-.58-5.72-1-8.6-.94-6.5,1.53-11.19,6.37-15.62,5-4.58,9.94-8.89,16.16-11.85,11.79-5.61,21-.88,24.26,10.48,2,6.93,1.9,14.55,2.13,21.88.36,11.7.14,11.7-11.42,11.54C207.49,81,206.52,81,205,81Zm0-22.71c-.08-6.18-4.87-11.37-10.41-11.29-6.34.09-12.76,5.88-12.59,11.33.19,5.86,6.14,11.79,11.75,11.7A11.45,11.45,0,0,0,205,58.29Z" transform="translate(-4 -5.3)"/>
      <path d="M64,80.81H50.22c-.12-2.74-.24-5.34-.4-9-2.16,2.11-3.62,4.07-5.55,5.32-9.39,6.14-21.07,6.8-27.66-4.67-2.3-4-2.43-11.53-.13-15.43,6.23-10.52,15-19,27.79-21.74a14.75,14.75,0,0,1,14.84,6.05,26.24,26.24,0,0,1,4.92,16C63.9,65,64,72.64,64,80.81ZM27,59.08c0,6.29,4.66,11,10.76,10.92S49.81,64.33,50,58.72,43.81,46.85,38.31,47,27,53.27,27,59.08Z" transform="translate(-4 -5.3)"/>
      <path d="M166,81c-8.76,0-16.9.2-25-.07-4.95-.17-10.83-7.43-10.95-13-.11-5,0-10,0-15C130,42.29,136.34,36,147.07,36c5,0,10,.16,15,0,4.64-.19,4,3,3.9,5.73s1.37,6.46-3.78,6.33-10.34-.24-15.49,0c-4.74.26-9,2.75-10.39,7.08C135,59.06,135.16,64,139.45,66.8a13.27,13.27,0,0,0,6.3,2.1,145.34,145.34,0,0,0,15.48,0c3.79-.19,5.16,1.26,4.81,4.93C165.83,76,166,78.16,166,81Z" transform="translate(-4 -5.3)"/>
    </g>
    <path class="cls-2" d="M5.35,6.3H404.52L78.24,338.75,5.48,277.24Z" transform="translate(-4 -5.3)"/>
  </g>
</svg>
