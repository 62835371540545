import { Injectable, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IComms } from '../models/i-comms.interface';
import {
  EventHandlerPromise,
  IEventHandler,
} from '../models/i-event-handler.interface';
import { TestComms } from '../models/test-comms.model';

/**
 * External communcations service
 */
@Injectable({
  providedIn: 'root',
})
export class CommunicationsService {
  private _comms: IComms;

  /** Gets the communications object instance */
  public get comms(): IComms {
    return this._comms;
  }

  private _eventsList: IEventHandler[] = [];

  /** Gets the current list of registered events */
  public get eventsList(): IEventHandler[] {
    return this._eventsList;
  }

  constructor(private ngZone: NgZone) {
    this._comms = (window as any).comms;

    if (!this.comms && !environment.production) {
      this._comms = new TestComms();

      (window as any).comms = this.comms;
    } else if (!this.comms) {
      console.warn('window.comms object not found.');
    }

    if (!!this.comms) {
      this.comms.appToWeb = (
        eventID: string,
        eventName: string,
        payload: string
      ) => {
        payload = !!payload ? JSON.parse(payload) : payload;

        this.ngZone.run(() => {
          const event = this._eventsList.find(
            (eventItem) =>
              eventItem.eventName.toUpperCase().trim() ===
              eventName.toUpperCase().trim()
          );

          if (!!event) {
            event.handler.call(event.scope || this, payload).then(
              (data) => {
                this.comms.eventResponse(
                  eventID,
                  eventName,
                  JSON.stringify(data)
                );
              },
              (errorData: Error) => {
                this.comms.eventReject(
                  eventID,
                  eventName,
                  JSON.stringify(errorData.message)
                );
              }
            );
          } else {
            this.comms.eventReject(
              eventID,
              eventName,
              JSON.stringify(`Unregistered Event ${eventName}`)
            );
          }
        });
      };

      if (!this.comms.initCallback) {
        console.warn('MessageRouting: No window.comms.initCallback() found!');
      } else {
        this.comms.initCallback(this.comms.appToWeb);
      }
    }
  }

  /**
   * Registers an event with the communications handler
   * @param eventName Name of the event to register
   * @param handler Event handler
   * @param scope Optional scope for the event
   */
  addEvent(eventName: string, handler: EventHandlerPromise, scope?: any) {
    // If the event name has already been registered, remove it
    this.removeEvent(eventName);

    // Add the event to the events list
    this._eventsList.push({
      eventName: eventName,
      handler,
      scope,
    });
  }

  /**
   * Unregisters an event from the communications handler
   * @param eventName Name of the event to unregister
   */
  removeEvent(eventName: string) {
    const index = this._eventsList.findIndex(
      (event) =>
        event.eventName.toUpperCase().trim() === eventName.toUpperCase().trim()
    );

    if (index > -1) {
      this._eventsList.splice(index, 1);
    }
  }

  /**
   * Sends a message from the web to the app
   * @param eventName The name of the event being sent
   * @param payload Optional payload data
   * @returns Promise
   */
  webToApp(eventName: string, payload?: any): Promise<any> {
    return new Promise<any>((response, reject) => {
      this.comms.webToApp(eventName, JSON.stringify(payload), response, reject);
    });
  }
}
