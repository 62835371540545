<svg-viewer src="/assets/images/svg/doas.svg">
  <svg-option name="ModelName" [text]="returnERVType()"></svg-option>
  <svg-option
    name="Energy_Recovery_Wheel"
    [visible]="returnERVType() === 'RVE'"
  ></svg-option>
  <svg-option name="RVC" [visible]="returnERVType() === 'RVC'"></svg-option>
  <svg-option name="EA_Top" [visible]="returnERVType() === 'RVC'"></svg-option>
  <svg-option
    name="EA_Bottom"
    [visible]="returnERVType() !== 'RVC'"
  ></svg-option>
  <svg-option name="EAM_Fan" [visible]="exhaustFan()"></svg-option>
  <svg-option name="EAM_Vent" [visible]="outdoorHood()"></svg-option>
  <svg-option
    name="OA_Bottom"
    [visible]="returnERVType() === 'RVC'"
  ></svg-option>
  <svg-option name="OA_Top" [visible]="returnERVType() !== 'RVC'"></svg-option>
  <svg-option name="Reheat_Coil" [visible]="reheat()"></svg-option>
  <svg-option name="Cooling_Coil" [visible]="coolingCoil()"></svg-option>
  <svg-option name="Outdoor_Air" [visible]="outdoorHood()"></svg-option>
  <svg-option
    name="Recirculation_Damper"
    [visible]="recircDamper()"
  ></svg-option>
  <svg-option
    name="RA_Right"
    [visible]="returnAirPosition() === 'End'"
  ></svg-option>
  <svg-option
    name="RA_Side"
    [visible]="
      returnAirPosition() === 'Side' || returnAirPosition() === 'SideNA'
    "
  ></svg-option>
  <svg-option
    name="RAS_NA"
    [visible]="returnAirPosition() === 'SideNA'"
  ></svg-option>
  <svg-option
    name="RA_Bottom"
    [visible]="returnAirPosition() === 'Bottom'"
  ></svg-option>
  <svg-option name="RAR_Damper" [visible]="returnAirDamper()"></svg-option>
  <svg-option name="RAB_Damper" [visible]="returnAirDamper()"></svg-option>
  <svg-option name="RAS_Damper" [visible]="returnAirDamper()"></svg-option>
  <svg-option
    name="HC_Hot_Water"
    [visible]="heatingType() === 'HotWater'"
  ></svg-option>
  <svg-option
    name="HC_Electric"
    [visible]="heatingType() === 'Electric'"
  ></svg-option>
  <svg-option
    name="HC_Gas"
    [visible]="heatingType() === 'IndirectGas'"
  ></svg-option>
  <svg-option
    name="SA_Top"
    [visible]="supplyAirPosition() === 'Side'"
  ></svg-option>
  <svg-option
    name="SA_Bottom"
    [visible]="supplyAirPosition() === 'Bottom'"
  ></svg-option>
</svg-viewer>
