export class DimensionInfo {
  description: string;
  displayName: string;
  name: string;
  value: number;
  formattedValue?: string;
  constructor(data: any) {
    this.description = data.description;
    this.displayName = data.displayName;
    this.name = data.name;
    this.value = data.value;
  }
}
