import { Injectable } from '@angular/core';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { IMessageBoxButton } from '../../ecaps-core/dialogs/message-box-dialog/models/i-message-box-data.interface';
import { ProjectsService } from '../services/projects.service';
import { Projects } from './projects.controller';

@Injectable({
  providedIn: 'root',
})
export class JobConflictDialog {
  constructor(
    private core: EcapsCore,
    private projectsService: ProjectsService,
    private projects: Projects
  ) {}

  show(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.projectsService.getLocalProject().then((project) => {
        const buttons: IMessageBoxButton[] = [];

        if (project.isDirty) {
          buttons.push({
            title: 'Save Changes & Open',
            value: 'SAVE_OPEN',
          });
        }

        buttons.push({
          title: project.isDirty ? 'Open Without Saving' : 'Open Selected Job',
          value: 'OPEN',
        });

        buttons.push({
          title: 'Cancel',
        });

        this.core
          .showMessageBox({
            title: 'Job Opening Notice',
            message:
              'Opening this job will close your current job. All unsaved changes will be lost.',
            icon: 'warning',
            iconClasses: 'warn',
            buttons,
          })
          .then((result: string) => {
            switch (result) {
              case 'SAVE_OPEN': {
                this.projects
                  .saveProject(
                    undefined,
                    'Job Opening Notice Lightbox',
                    'Save and Open'
                  )
                  .then((completed) => {
                    resolve(completed);
                  });

                break;
              }
              case 'OPEN': {
                resolve(true);

                break;
              }
              default: {
                resolve(false);

                break;
              }
            }
          });
      });
    });
  }
}
