import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { IImageDialogData } from '../dialogs/image-dialog/models/i-image-dialog-data.interface';
import { ImageDialogRef } from '../dialogs/image-dialog/models/image-dialog-ref.dialogref';

interface ImageDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  data?: IImageDialogData[];
}

const DEFAULT_CONFIG: ImageDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'cdk-overlay-dark-backdrop',
  panelClass: '',
};

@Injectable({
  providedIn: 'root',
})
export class ImageDialogService {
  constructor(private overlay: Overlay) {}

  open(config: ImageDialogConfig = {}): ImageDialogRef {
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    const overlayRef = this.createOverlay(dialogConfig);

    const dialogRef = new ImageDialogRef(overlayRef);

    const clickSubscription = overlayRef.backdropClick().subscribe((_) => {
      clickSubscription.unsubscribe();

      dialogRef.close();
    });

    const keySubscription = overlayRef
      .keydownEvents()
      .subscribe((event: KeyboardEvent) => {
        if (event.key.toLowerCase() === 'escape') {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();
          event.cancelBubble = true;

          dialogRef.close();

          keySubscription.unsubscribe();
        }
      });

    return dialogRef;
  }

  private getOverlayConfig(config: ImageDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: positionStrategy,
      disposeOnNavigation: true,
    });

    return overlayConfig;
  }

  private createOverlay(config: ImageDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }
}
