<div #tableContainer class="table-container">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef> Version </th>
      <td mat-cell *matCellDef="let element">
        <mat-icon color="primary" matTooltip="Current Revision" *ngIf="element === this.projectSummary.getActiveRevision()">star_rate</mat-icon>
        <button mat-button color="primary" (click)="revisionClick(element)">{{ element.name }}</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="creationDate">
      <th mat-header-cell *matHeaderCellDef> Created On </th>
      <td mat-cell *matCellDef="let element"> {{ element.creationDate | date: 'M/dd/yyyy' }} </td>
    </ng-container>

    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef> Comment </th>
      <td mat-cell *matCellDef="let element" [ngSwitch]="readOnly" [style.max-width.px]="tableContainer.clientWidth - 268">
        <span *ngSwitchCase="true">{{ element.comment }}</span>
        <inline-editor *ngSwitchDefault [text]="element.comment" [showDelete]="false" (textChanged)="commentChanged($event, element)"></inline-editor>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>  </th>
      <td mat-cell *matCellDef="let element">
        
        <ng-container [ngSwitch]="!isDeleted(element)">

          <button *ngSwitchCase="true" mat-icon-button matTooltip="Delete Revision" (click)="deleteRevision(element)">
            <mat-icon>delete</mat-icon>
          </button>

          <button *ngSwitchCase="false" mat-icon-button matTooltip="Undo Delete Revision" (click)="undoDeleteRevision(element)">
            <mat-icon>undo</mat-icon>
          </button>

        </ng-container>

      </td>
    </ng-container>

    <ng-container matColumnDef="revisionDetails">
      <td mat-cell *matCellDef="let element" [attr.colspan]="4">
        <div class="revision-details-table" [@detailExpand]="expandedElement === element ? 'expanded' : 'collapsed'">
          <project-revision-details [project-summary-revision]="element"></project-revision-details>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="versionHistoryDisplayColumns; sticky: true;"></tr>
    <tr mat-row *matRowDef="let element; columns: versionHistoryDisplayColumns;" class="revision-row" [class.deleted]="isDeleted(element)"></tr>
    <tr mat-row *matRowDef="let row; columns: [ 'revisionDetails' ];" class="revision-details-row"></tr>
  </table>
</div>