export class ChartLayerBounds {
    maxX: number;
    maxY: number;
    minX: number;
    minY: number;
    constructor(boundData: any) {
        boundData = (boundData || {});
        this.maxX = boundData.maxX;
        this.maxY = boundData.maxY;
        this.minX = boundData.minX;
        this.minY = boundData.minY;
    }
}
