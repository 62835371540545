<button class="breadcrumb-button" mat-button color="primary" (click)="reset()">
  <span class="breadcrumb-text">eCAPS</span>
</button>

<ng-container *ngFor="let crumb of crumbs">
  <span class="material-icons arrow-icon"> arrow_right </span>

  <button class="breadcrumb-button" mat-button color="primary" (click)="crumbClick(crumb)">
    <span class="breadcrumb-text">{{ crumb.text }}</span>
  </button>
</ng-container>
