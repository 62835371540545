<svg
  id="Layer_2"
  data-name="Layer 2"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 479.44 501.33"
>
  <defs>
    <style>
      .cls-1,
      .cls-2,
      .cls-3,
      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-8,
      .cls-9,
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-19,
      .cls-20,
      .cls-21,
      .cls-22,
      .cls-23,
      .cls-24,
      .cls-25,
      .cls-26,
      .cls-27,
      .cls-28,
      .cls-29,
      .cls-30,
      .cls-31,
      .cls-32,
      .cls-33,
      .cls-34,
      .cls-35,
      .cls-36,
      .cls-37,
      .cls-38,
      .cls-39,
      .cls-40,
      .cls-41,
      .cls-42,
      .cls-43,
      .cls-44,
      .cls-45,
      .cls-46,
      .cls-47,
      .cls-48,
      .cls-49,
      .cls-50,
      .cls-51,
      .cls-52,
      .cls-53,
      .cls-54,
      .cls-55,
      .cls-56,
      .cls-57,
      .cls-58,
      .cls-59,
      .cls-60 {
        stroke-linejoin: round;
      }

      .cls-1,
      .cls-2,
      .cls-3,
      .cls-6,
      .cls-61,
      .cls-7,
      .cls-8,
      .cls-10,
      .cls-11,
      .cls-12,
      .cls-13,
      .cls-14,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-19,
      .cls-20,
      .cls-21,
      .cls-22,
      .cls-23,
      .cls-24,
      .cls-62,
      .cls-25,
      .cls-26,
      .cls-27,
      .cls-28,
      .cls-63,
      .cls-31,
      .cls-32,
      .cls-33,
      .cls-34,
      .cls-35,
      .cls-36,
      .cls-37,
      .cls-38,
      .cls-39,
      .cls-40,
      .cls-41,
      .cls-43,
      .cls-44,
      .cls-45,
      .cls-46,
      .cls-47,
      .cls-48,
      .cls-49,
      .cls-64,
      .cls-65,
      .cls-50,
      .cls-51,
      .cls-52,
      .cls-53,
      .cls-66,
      .cls-54,
      .cls-55,
      .cls-57,
      .cls-58 {
        stroke: #000;
      }

      .cls-1,
      .cls-4,
      .cls-5,
      .cls-67,
      .cls-7,
      .cls-9,
      .cls-11,
      .cls-13,
      .cls-15,
      .cls-16,
      .cls-17,
      .cls-18,
      .cls-23,
      .cls-25,
      .cls-26,
      .cls-27,
      .cls-28,
      .cls-30,
      .cls-63,
      .cls-31,
      .cls-34,
      .cls-36,
      .cls-40,
      .cls-42,
      .cls-44,
      .cls-68,
      .cls-46,
      .cls-49,
      .cls-65,
      .cls-69,
      .cls-50,
      .cls-52,
      .cls-53,
      .cls-55,
      .cls-56,
      .cls-70,
      .cls-59,
      .cls-60 {
        fill-rule: evenodd;
      }

      .cls-1,
      .cls-67,
      .cls-9,
      .cls-13,
      .cls-16,
      .cls-35,
      .cls-39,
      .cls-42,
      .cls-47 {
        fill: #b5b9b5;
      }

      .cls-2 {
        fill: url(#linear-gradient-9);
      }

      .cls-2,
      .cls-10,
      .cls-12,
      .cls-13,
      .cls-19,
      .cls-20,
      .cls-21,
      .cls-22,
      .cls-24,
      .cls-33,
      .cls-37,
      .cls-38,
      .cls-43,
      .cls-45,
      .cls-51,
      .cls-54 {
        stroke-width: 0.5px;
      }

      .cls-3 {
        fill: url(#linear-gradient);
      }

      .cls-3,
      .cls-4,
      .cls-5,
      .cls-61,
      .cls-7,
      .cls-8,
      .cls-9,
      .cls-14,
      .cls-16,
      .cls-18,
      .cls-62,
      .cls-27,
      .cls-29,
      .cls-63,
      .cls-32,
      .cls-39,
      .cls-46,
      .cls-47,
      .cls-48,
      .cls-64,
      .cls-65,
      .cls-52,
      .cls-56,
      .cls-59,
      .cls-60 {
        stroke-width: 0.25px;
      }

      .cls-4,
      .cls-5,
      .cls-9,
      .cls-29,
      .cls-30,
      .cls-42,
      .cls-56,
      .cls-59,
      .cls-60 {
        stroke: #171d2a;
      }

      .cls-4,
      .cls-27,
      .cls-29,
      .cls-30,
      .cls-49 {
        fill: #828582;
      }

      .cls-5 {
        fill: url(#linear-gradient-20);
      }

      .cls-6,
      .cls-18,
      .cls-62,
      .cls-71,
      .cls-53,
      .cls-66,
      .cls-56,
      .cls-58 {
        fill: none;
      }

      .cls-61,
      .cls-7,
      .cls-8,
      .cls-63,
      .cls-44,
      .cls-68,
      .cls-48,
      .cls-57,
      .cls-59 {
        fill: #929592;
      }

      .cls-61,
      .cls-62,
      .cls-63,
      .cls-64,
      .cls-65,
      .cls-66 {
        stroke-miterlimit: 10;
      }

      .cls-67,
      .cls-72,
      .cls-73,
      .cls-68,
      .cls-69,
      .cls-74,
      .cls-70 {
        stroke-width: 0px;
      }

      .cls-8,
      .cls-39 {
        isolation: isolate;
      }

      .cls-10 {
        fill: url(#linear-gradient-17);
      }

      .cls-11 {
        fill: url(#linear-gradient-23);
      }

      .cls-72 {
        fill: url(#Metal_Gradient-3);
      }

      .cls-12 {
        fill: url(#linear-gradient-7);
      }

      .cls-73 {
        fill: url(#Metal_Gradient-2);
      }

      .cls-14 {
        fill: #404040;
      }

      .cls-15 {
        fill: url(#linear-gradient-25);
      }

      .cls-17 {
        fill: url(#linear-gradient-32);
      }

      .cls-19 {
        fill: url(#linear-gradient-10);
      }

      .cls-75 {
        font-size: 12px;
      }

      .cls-75,
      .cls-76,
      .cls-77 {
        font-family: ArialMT, Arial;
      }

      .cls-20 {
        fill: url(#linear-gradient-14);
      }

      .cls-21 {
        fill: url(#linear-gradient-11);
      }

      .cls-22 {
        fill: url(#linear-gradient-4);
      }

      .cls-23 {
        fill: url(#linear-gradient-19);
      }

      .cls-24 {
        fill: #262626;
      }

      .cls-25 {
        fill: url(#linear-gradient-31);
      }

      .cls-26 {
        fill: url(#linear-gradient-26);
      }

      .cls-28 {
        fill: url(#linear-gradient-30);
      }

      .cls-30,
      .cls-42 {
        stroke-width: 0.19px;
      }

      .cls-31 {
        fill: url(#linear-gradient-24);
      }

      .cls-32 {
        fill: url(#linear-gradient-2);
      }

      .cls-33 {
        fill: url(#linear-gradient-13);
      }

      .cls-34 {
        fill: url(#linear-gradient-18);
      }

      .cls-36 {
        fill: url(#linear-gradient-29);
      }

      .cls-37 {
        fill: url(#linear-gradient-12);
      }

      .cls-38 {
        fill: url(#linear-gradient-8);
      }

      .cls-40 {
        fill: url(#linear-gradient-33);
      }

      .cls-41 {
        fill: url(#radial-gradient);
      }

      .cls-43 {
        fill: url(#linear-gradient-15);
      }

      .cls-71 {
        stroke: #0060a3;
      }

      .cls-71,
      .cls-53 {
        stroke-width: 2px;
      }

      .cls-71,
      .cls-53,
      .cls-66 {
        stroke-linecap: round;
      }

      .cls-76 {
        font-size: 14px;
      }

      .cls-45 {
        fill: url(#linear-gradient-16);
      }

      .cls-46 {
        fill: url(#linear-gradient-3);
      }

      .cls-64 {
        fill: #4d4d4d;
      }

      .cls-65 {
        fill: url(#linear-gradient-34);
      }

      .cls-69 {
        fill: url(#linear-gradient-22);
      }

      .cls-50 {
        fill: url(#linear-gradient-27);
      }

      .cls-74 {
        fill: #d5efc4;
      }

      .cls-51 {
        fill: url(#linear-gradient-6);
      }

      .cls-52 {
        fill: #004d00;
      }

      .cls-66 {
        stroke-width: 3px;
      }

      .cls-54 {
        fill: url(#linear-gradient-5);
      }

      .cls-55 {
        fill: url(#linear-gradient-28);
      }

      .cls-77 {
        font-size: 16px;
      }

      .cls-58 {
        stroke-dasharray: 0 0 11.92 11.92;
      }

      .cls-70 {
        fill: url(#linear-gradient-21);
      }

      .cls-60 {
        fill: url(#Metal_Gradient);
      }
    </style>
    <symbol id="Arrow" viewBox="0 0 20.23 20.65">
      <g>
        <polyline
          id="Arrow_Bottom"
          data-name="Arrow Bottom"
          class="cls-71"
          points="1 19.65 10.12 10.53 19.23 19.65"
        />
        <polyline
          id="Arrow_Middle"
          data-name="Arrow Middle"
          class="cls-71"
          points="1 15.09 10.12 5.97 19.23 15.09"
        />
        <polyline
          id="Arrow_Top"
          data-name="Arrow Top"
          class="cls-71"
          points="1 10.53 10.12 1.41 19.23 10.53"
        />
      </g>
    </symbol>
    <symbol id="Background" viewBox="0 0 168 168">
      <g id="C1_Plenum_BG" data-name="C1 Plenum BG">
        <rect class="cls-74" x=".5" y=".5" width="167" height="167" />
        <g>
          <polyline
            class="cls-6"
            points="167.5 161.5 167.5 167.5 161.5 167.5"
          />
          <line class="cls-58" x1="149.58" y1="167.5" x2="12.46" y2="167.5" />
          <polyline class="cls-6" points="6.5 167.5 .5 167.5 .5 161.5" />
          <line class="cls-58" x1=".5" y1="149.58" x2=".5" y2="12.46" />
          <polyline class="cls-6" points=".5 6.5 .5 .5 6.5 .5" />
          <line class="cls-58" x1="18.42" y1=".5" x2="155.54" y2=".5" />
          <polyline class="cls-6" points="161.5 .5 167.5 .5 167.5 6.5" />
          <line class="cls-58" x1="167.5" y1="18.42" x2="167.5" y2="155.54" />
        </g>
      </g>
    </symbol>
    <linearGradient
      id="linear-gradient"
      x1="6.5"
      y1="46.45"
      x2="40.71"
      y2="46.45"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#1a1a1a" />
      <stop offset=".18" stop-color="#1c1c1c" />
      <stop offset=".29" stop-color="#242424" />
      <stop offset=".37" stop-color="#313231" />
      <stop offset=".45" stop-color="#454645" />
      <stop offset=".51" stop-color="#5e605e" />
      <stop offset=".58" stop-color="#7c7f7c" />
      <stop offset=".59" stop-color="#828582" />
      <stop offset=".66" stop-color="#707270" />
      <stop offset=".79" stop-color="#565756" />
      <stop offset=".91" stop-color="#454645" />
      <stop offset="1" stop-color="#404040" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-2"
      x1="26.59"
      y1="33.84"
      x2="39.11"
      y2="33.84"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#004d00" />
      <stop offset=".05" stop-color="#006200" />
      <stop offset=".11" stop-color="#007200" />
      <stop offset=".17" stop-color="#007b00" />
      <stop offset=".26" stop-color="#007f00" />
      <stop offset=".75" stop-color="#004d00" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-3"
      x1="2.81"
      y1="56.86"
      x2="70.74"
      y2="56.86"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#929592" />
      <stop offset=".25" stop-color="#b5b9b5" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <symbol
      id="Bifurcated_Fan"
      data-name="Bifurcated Fan"
      viewBox="0 0 74.22 107.63"
    >
      <g>
        <ellipse class="cls-47" cx="36.78" cy="102.23" rx="34.26" ry="5.27" />
        <rect class="cls-3" x="6.5" y="11.28" width="34.21" height="70.33" />
        <rect class="cls-32" x="26.59" y="13.11" width="12.52" height="41.46" />
        <polygon
          class="cls-52"
          points="26.59 42.03 21.49 41.77 21.54 34.91 26.59 34.62 26.59 42.03"
        />
        <polygon
          class="cls-27"
          points="42.45 57.31 7.45 54.77 7.78 50.31 42.77 52.85 42.45 57.31"
        />
        <path
          class="cls-46"
          d="m69.38,98.71V8.41H4.41v90.17c-1.04.49-1.6,1.02-1.6,1.57,0,2.85,15.21,5.16,33.97,5.16s33.97-2.31,33.97-5.16c0-.5-.48-.99-1.36-1.44Zm-45.97-19.31c-7.38,0-13.36-14.84-13.36-33.15S16.03,13.11,23.41,13.11s13.36,14.84,13.36,33.15-5.98,33.15-13.36,33.15Z"
        />
        <path class="cls-18" d="m4.67,98.46c15.33,6.18,55.73,4.59,64.72.25" />
        <ellipse class="cls-47" cx="37.11" cy="6.49" rx="36.98" ry="6.36" />
        <ellipse class="cls-14" cx="37.11" cy="6.3" rx="33.91" ry="5.24" />
      </g>
    </symbol>
    <symbol
      id="Bypass_Air_Label"
      data-name="Bypass Air Label"
      viewBox="0 0 257.3 18.93"
    >
      <g>
        <text class="cls-77" transform="translate(0 13.73)">
          <tspan x="0" y="0">Bypass Air</tspan>
        </text>
        <text
          *ngIf="size?.outputs?.operatingVolume > 0"
          id="bypassAir"
          class="cls-77"
          transform="translate(178.16 13.73)"
        >
          <tspan x="0" y="0">0 CFM</tspan>
        </text>
      </g>
    </symbol>
    <linearGradient
      id="linear-gradient-4"
      x1="72.2"
      y1="64.38"
      x2="80.69"
      y2="98.81"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#000" />
      <stop offset="1" stop-color="#404040" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-5"
      x1="16.93"
      y1="77.14"
      x2="87.45"
      y2="88.32"
      xlink:href="#linear-gradient-4"
    />
    <linearGradient
      id="linear-gradient-6"
      x1="137.65"
      y1="73.79"
      x2="89.85"
      y2="88.47"
      xlink:href="#linear-gradient-4"
    />
    <linearGradient
      id="linear-gradient-7"
      x1="15.99"
      y1="70.94"
      x2="85.85"
      y2="33.43"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#929592" />
      <stop offset="1" stop-color="#b5b9b5" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-8"
      x1="138.72"
      y1="68.33"
      x2="90.1"
      y2="32.11"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#929592" />
      <stop offset="1" stop-color="#b5b9b5" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-9"
      x1="71.15"
      y1="15.56"
      x2="87.93"
      y2="28.72"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#929592" />
      <stop offset="1" stop-color="#b5b9b5" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-10"
      x1="31.83"
      y1="12.35"
      x2="86.63"
      y2="16.94"
      xlink:href="#linear-gradient-9"
    />
    <linearGradient
      id="linear-gradient-11"
      x1="121.98"
      y1="11.94"
      x2="87.83"
      y2="16.71"
      xlink:href="#linear-gradient-9"
    />
    <linearGradient
      id="linear-gradient-12"
      x1="71.34"
      y1="18.44"
      x2="83.34"
      y2="18.44"
      xlink:href="#linear-gradient-9"
    />
    <linearGradient
      id="linear-gradient-13"
      x1="22.09"
      y1="14.84"
      x2="69.62"
      y2="19.12"
      xlink:href="#linear-gradient-9"
    />
    <linearGradient
      id="linear-gradient-14"
      x1="33.91"
      y1="8.03"
      x2="70.27"
      y2="13.41"
      xlink:href="#linear-gradient-9"
    />
    <linearGradient
      id="linear-gradient-15"
      x1="49.92"
      y1="54.16"
      x2="75.34"
      y2="54.16"
      xlink:href="#linear-gradient-9"
    />
    <linearGradient
      id="linear-gradient-16"
      x1="15.45"
      y1="29.42"
      x2="62.13"
      y2="34.52"
      xlink:href="#linear-gradient-9"
    />
    <linearGradient
      id="linear-gradient-17"
      x1="8.1"
      y1="51.98"
      x2="53.87"
      y2="54.46"
      xlink:href="#linear-gradient-9"
    />
    <symbol
      id="Bypass_Air_Plenum"
      data-name="Bypass Air Plenum"
      viewBox="0 0 150.03 98.2"
    >
      <g>
        <polygon
          class="cls-22"
          points="67.34 67.46 149.78 78.52 90.84 97.95 2.87 83.68 67.34 67.46"
        />
        <polygon
          class="cls-54"
          points="15.34 82.46 89.34 94.79 89.34 82.46 15.34 71.15 15.34 82.46"
        />
        <polygon
          class="cls-51"
          points="139.34 79.03 89.34 94.79 89.34 82.46 139.34 68.04 139.34 79.03"
        />
        <polygon
          class="cls-12"
          points="13.34 21.46 89.34 28.5 89.34 82.46 13.34 70.85 13.34 21.46"
        />
        <polygon
          class="cls-38"
          points="141.34 67.46 89.34 82.45 89.34 28.46 141.34 19.38 141.34 67.46"
        />
        <polygon
          class="cls-2"
          points="68.67 13.75 141.34 19.38 89.9 28.54 13.34 21.46 68.67 13.75"
        />
        <polygon
          class="cls-19"
          points="31.34 20.46 86.34 25.38 86.34 8.46 31.34 4.59 31.34 20.46"
        />
        <polygon
          class="cls-21"
          points="123.34 19.17 86.34 25.38 86.34 8.46 123.34 3.59 123.34 19.17"
        />
        <polygon
          class="cls-37"
          points="83.34 23.14 71.34 25.08 71.34 13.46 83.34 11.81 83.34 23.14"
        />
        <polygon
          class="cls-33"
          points="20.34 20.46 71.34 25.08 71.34 13.46 20.34 9.52 20.34 20.46"
        />
        <polygon
          class="cls-20"
          points="32.68 8.07 83.19 11.83 71.34 13.46 20.34 9.52 32.68 8.07"
        />
        <polygon
          class="cls-24"
          points="70.16 .25 123.34 3.59 86.34 8.46 31.34 4.59 70.16 .25"
        />
        <polygon
          class="cls-43"
          points="49.92 36.01 75.34 32.82 75.34 72.46 61.2 75.5 49.92 36.01"
        />
        <polygon
          class="cls-45"
          points=".5 30.93 49.92 36.01 75.34 32.82 25.92 27.74 .5 30.93"
        />
        <polygon
          class="cls-10"
          points="61.2 75.5 11.78 70.43 .5 30.93 49.92 36.01 61.2 75.5"
        />
        <polygon
          class="cls-24"
          points="57.67 72.56 36.53 70.22 27.07 37.14 47.93 38.72 57.67 72.56"
        />
        <polygon
          class="cls-24"
          points="34.45 69.96 13.32 67.63 3.85 34.55 24.85 36.54 34.45 69.96"
        />
      </g>
    </symbol>
    <symbol
      id="Bypass_Air_Plenum_Arrows"
      data-name="Bypass Air Plenum Arrows"
      viewBox="0 0 150.03 98.2"
    >
      <g>
        <use width="150.03" height="98.2" xlink:href="#Bypass_Air_Plenum" />
        <use
          width="20.23"
          height="20.65"
          transform="translate(60.19 66) rotate(-89.79)"
          xlink:href="#Arrow"
        />
      </g>
    </symbol>
    <linearGradient
      id="linear-gradient-18"
      x1="79.74"
      y1="85.24"
      x2="51.1"
      y2="10.88"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#000" />
      <stop offset=".11" stop-color="#343634" />
      <stop offset=".22" stop-color="#626462" />
      <stop offset=".32" stop-color="#868986" />
      <stop offset=".42" stop-color="#a0a3a0" />
      <stop offset=".5" stop-color="#afb3af" />
      <stop offset=".57" stop-color="#b5b9b5" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-19"
      x1="6.36"
      y1="51.5"
      x2="80.51"
      y2="36.91"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#828582" />
      <stop offset=".17" stop-color="#8c8f8c" />
      <stop offset=".7" stop-color="#a9ada9" />
      <stop offset="1" stop-color="#b5b9b5" />
    </linearGradient>
    <symbol
      id="Centrifugal_Fan"
      data-name="Centrifugal Fan"
      viewBox="0 0 101.57 110.16"
    >
      <g>
        <g id="Side_Wall" data-name="Side Wall">
          <polygon
            class="cls-1"
            points="26.48 87.51 21.8 86.66 21.8 51.3 26.48 52.15 26.48 87.51"
          />
          <polygon
            class="cls-44"
            points="29.81 49.89 26.48 52.15 26.48 87.51 29.81 85.25 29.81 49.89"
          />
          <polygon
            class="cls-49"
            points="84.6 92.67 26.48 82.38 26.48 87.51 84.6 97.8 84.6 92.67"
          />
          <polygon
            class="cls-1"
            points="88.2 89.83 30.07 79.54 26.48 82.38 84.6 92.67 88.2 89.83"
          />
          <polygon
            class="cls-1"
            points="89.29 98.62 84.6 97.8 84.6 62.44 89.29 63.26 89.29 98.62"
          />
          <line class="cls-35" x1="89.29" y1="98.62" x2="89.29" y2="63.26" />
          <polygon
            class="cls-44"
            points="92.61 60.8 89.29 63.06 89.29 98.62 92.61 96.36 92.61 60.8"
          />
          <polygon
            class="cls-1"
            points="89.29 63.26 21.8 51.3 21.8 41.46 89.29 53.42 89.29 63.26"
          />
          <polygon
            class="cls-44"
            points="92.61 60.8 89.29 63.26 89.29 53.42 92.61 50.96 92.61 60.8"
          />
          <polygon
            class="cls-1"
            points="92.61 50.96 25.12 39 21.8 41.46 89.29 53.42 92.61 50.96"
          />
          <g id="Bearing">
            <path
              class="cls-13"
              d="m67.23,45.63c.3-.38.53-.82.62-1.33.28-1.6-.79-3.12-2.39-3.4s-3.12.79-3.4,2.39c-.09.51-.03,1,.13,1.46l-1.96-.34-.26,1.5,8.95,1.56.26-1.5-1.95-.34Zm-2.52-.44c-.77-.13-1.29-.87-1.15-1.64s.87-1.29,1.64-1.15,1.29.87,1.15,1.64-.87,1.29-1.64,1.15Z"
            />
            <polygon
              class="cls-13"
              points="68.09 46.74 66.14 46.4 67.23 45.63 69.18 45.97 68.09 46.74"
            />
            <polygon
              class="cls-13"
              points="68.92 47.47 67.82 48.24 68.09 46.74 69.18 45.97 68.92 47.47"
            />
            <polygon
              class="cls-13"
              points="61.09 45.52 59.14 45.18 60.23 44.41 62.19 44.75 61.09 45.52"
            />
            <path
              class="cls-13"
              d="m66.14,46.4c.3-.38.53-.82.62-1.33.28-1.6-.79-3.12-2.39-3.4s-3.12.79-3.4,2.39c-.09.51-.03,1,.13,1.46l-1.96-.34-.26,1.5,8.95,1.56.26-1.5-1.95-.34Zm-2.52-.44c-.77-.13-1.29-.87-1.15-1.64s.87-1.29,1.64-1.15,1.29.87,1.15,1.64-.87,1.29-1.64,1.15Z"
            />
          </g>
        </g>
        <polygon
          class="cls-44"
          points="89.29 93.47 75.06 101.19 75.06 107.28 89.29 98.62 89.29 93.47"
        />
        <polygon
          class="cls-35"
          points="70.38 100.39 84.6 92.67 89.29 93.47 75.06 101.19 70.38 100.39"
        />
        <polygon
          class="cls-57"
          points="9.21 89.57 26.48 82.38 26.48 87.51 9.21 94.7 9.21 89.57"
        />
        <polygon
          class="cls-35"
          points="4.53 88.72 21.8 81.53 26.48 82.38 9.21 89.57 4.53 88.72"
        />
        <g id="Fan_Body" data-name="Fan Body">
          <path
            class="cls-34"
            d="m67.01,8.16v-3.33L30.2.5l.36,40.92,2.6.31c.68,17.25,4.92,47.99,11,47.65,44.67-2.49,56.91-27.92,56.91-45.77S84.46,8.81,67.01,8.16Z"
          />
          <path
            class="cls-23"
            d="m47.31,14.36v-3.87L4.55,5.46l.42,47.53,3.02.36c.79,20.04,17.44,36.04,37.87,36.04s37.91-16.8,37.91-37.53S67.57,15.11,47.31,14.36Z"
          />
          <polygon
            class="cls-44"
            points="67.01 8.16 47.31 14.36 47.31 10.49 67.01 4.83 67.01 8.16"
          />
          <polygon
            class="cls-49"
            points="47.31 10.49 4.55 5.46 30.2 .5 67.01 4.86 47.31 10.49"
          />
        </g>
        <g id="Side_Wall-2" data-name="Side Wall">
          <polygon
            class="cls-1"
            points="5.43 97.96 .5 97.07 .5 59.83 5.43 60.73 5.43 97.96"
          />
          <polygon
            class="cls-44"
            points="8.93 58.35 5.43 60.73 5.43 97.96 8.93 95.59 8.93 58.35"
          />
          <polygon
            class="cls-1"
            points="66.63 103.39 5.43 92.56 5.43 97.96 66.63 108.8 66.63 103.39"
          />
          <polygon
            class="cls-1"
            points="70.42 100.4 9.21 89.57 5.43 92.56 66.63 103.39 70.42 100.4"
          />
          <polygon
            class="cls-1"
            points="71.56 109.66 66.63 108.8 66.63 71.56 71.56 72.42 71.56 109.66"
          />
          <line class="cls-35" x1="71.56" y1="109.66" x2="71.56" y2="72.42" />
          <polygon
            class="cls-44"
            points="75.06 69.83 71.56 72.21 71.56 109.66 75.06 107.28 75.06 69.83"
          />
          <polygon
            class="cls-1"
            points="71.56 72.42 .5 59.83 .5 49.48 71.56 62.07 71.56 72.42"
          />
          <polygon
            class="cls-44"
            points="75.06 69.83 71.56 72.42 71.56 62.07 75.06 59.48 75.06 69.83"
          />
          <polygon
            class="cls-1"
            points="75.06 59.48 4 46.89 .5 49.48 71.56 62.07 75.06 59.48"
          />
          <g id="Bearing-2" data-name="Bearing">
            <path
              class="cls-13"
              d="m48.34,53.86c.32-.4.56-.86.65-1.4.29-1.68-.83-3.29-2.52-3.58s-3.29.83-3.58,2.52c-.09.54-.03,1.06.14,1.54l-2.06-.36-.28,1.58,9.42,1.64.28-1.58-2.05-.36Zm-2.66-.46c-.81-.14-1.35-.91-1.21-1.73s.91-1.35,1.73-1.21,1.35.91,1.21,1.73-.91,1.35-1.73,1.21Z"
            />
            <polygon
              class="cls-13"
              points="49.24 55.03 47.19 54.68 48.34 53.86 50.39 54.22 49.24 55.03"
            />
            <polygon
              class="cls-13"
              points="50.12 55.8 48.96 56.61 49.24 55.03 50.39 54.22 50.12 55.8"
            />
            <polygon
              class="cls-13"
              points="41.88 53.75 39.81 53.39 40.97 52.58 43.03 52.94 41.88 53.75"
            />
            <path
              class="cls-13"
              d="m47.19,54.68c.32-.4.56-.86.65-1.4.29-1.68-.83-3.29-2.52-3.58s-3.29.83-3.58,2.52c-.09.54-.03,1.06.14,1.54l-2.06-.36-.28,1.58,9.42,1.64.28-1.58-2.05-.36Zm-2.66-.46c-.81-.14-1.35-.91-1.21-1.73s.91-1.35,1.73-1.21,1.35.91,1.21,1.73-.91,1.35-1.73,1.21Z"
            />
          </g>
        </g>
      </g>
    </symbol>
    <linearGradient
      id="linear-gradient-20"
      x1="6.44"
      y1="32.93"
      x2="74.09"
      y2="27.7"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#929592" />
      <stop offset=".56" stop-color="#b2b3b2" />
      <stop offset=".67" stop-color="#bababa" />
      <stop offset="1" stop-color="#b5b9b5" />
    </linearGradient>
    <symbol
      id="Dilution_Nozzle"
      data-name="Dilution Nozzle"
      viewBox="0 0 80.81 91.75"
    >
      <g>
        <polygon
          class="cls-59"
          points="53.48 91.62 10.56 85.81 10.43 71.28 52.88 75.58 53.48 91.62"
        />
        <polygon
          class="cls-9"
          points="71.31 84.89 53.48 91.62 52.88 75.58 71.24 70.49 71.31 84.89"
        />
        <polygon
          class="cls-59"
          points="52.88 75.58 10.43 71.28 10.43 58.01 52.68 60.11 52.88 75.58"
        />
        <polygon
          class="cls-9"
          points="10.43 71.28 5.74 56.83 10.43 58.01 10.43 71.28"
        />
        <polygon
          class="cls-9"
          points="71.24 70.49 52.88 75.58 52.68 60.11 71.24 57.91 71.24 70.49"
        />
        <polygon
          class="cls-59"
          points="71.24 57.91 78.54 55.45 71.24 70.49 71.24 57.91"
        />
        <polygon
          class="cls-4"
          points="52.68 60.11 56.59 59.84 52.88 75.58 52.68 60.11"
        />
        <path
          class="cls-5"
          d="m77.24,49.87h.01l-5.36-42.48h0c1.97-.65,3.07-1.37,3.07-2.13C74.96,2.42,59.72.12,40.91.12S6.85,2.42,6.85,5.26c0,.75,1.08,1.47,3.01,2.11h-.03l-5.18,42.08c-2.88,1.04-4.52,2.22-4.52,3.47,0,4.17,18.03,7.54,40.28,7.54s40.28-3.38,40.28-7.54c0-1.09-1.24-2.12-3.44-3.05Z"
        />
        <ellipse class="cls-29" cx="40.65" cy="5.08" rx="31.23" ry="3.89" />
        <path class="cls-56" d="m4.65,49.45c3.1,7.89,65.5,8.56,72.61.42" />
        <path class="cls-56" d="m9.86,7.37c16.73,4.06,44.77,4.14,62.03.02" />
      </g>
    </symbol>
    <symbol
      id="Dilution_Nozzle_Arrows"
      data-name="Dilution Nozzle Arrows"
      viewBox="0 0 94.48 91.75"
    >
      <g>
        <use width="80.81" height="91.75" xlink:href="#Dilution_Nozzle" />
        <use
          width="20.23"
          height="20.65"
          transform="translate(65.99 67.28) rotate(-44.88)"
          xlink:href="#Arrow"
        />
      </g>
    </symbol>
    <symbol
      id="Entrained_Air_Label"
      data-name="Entrained Air Label"
      viewBox="0 0 257.57 18.93"
    >
      <g>
        <text class="cls-77" transform="translate(0 13.73)">
          <tspan x="0" y="0">Single Fan Entrained Air</tspan>
        </text>
        <text
          *ngIf="size?.outputs?.operatingVolume > 0"
          id="entrainedVolume"
          class="cls-77"
          transform="translate(178.43 13.73)"
        >
          <tspan x="0" y="0">
            {{ entrainedVolume | number : '1.0-0' }} CFM
          </tspan>
        </text>
      </g>
    </symbol>
    <linearGradient
      id="Metal_Gradient"
      data-name="Metal Gradient"
      x1=".12"
      y1="58.63"
      x2="90.43"
      y2="58.63"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="gray" />
      <stop offset="1" stop-color="#ddd" />
    </linearGradient>
    <symbol id="Generic_Fan" data-name="Generic Fan" viewBox="0 0 90.55 117.26">
      <g>
        <path
          class="cls-60"
          d="m76.93,49.26V.12H12.65v50.01C4.9,57.25.12,66.88.12,77.5c0,21.88,20.21,39.63,45.15,39.63s45.15-17.74,45.15-39.63c0-11.06-5.17-21.06-13.5-28.25Z"
        />
        <text class="cls-76" transform="translate(19.98 75.62)">
          <tspan x="0" y="0">Exhaust</tspan>
          <tspan x="13.23" y="16.8">Fan</tspan>
        </text>
      </g>
    </symbol>
    <linearGradient
      id="Metal_Gradient-2"
      data-name="Metal Gradient"
      x1="-3.2"
      y1="113.55"
      x2="76.28"
      y2="64.16"
      xlink:href="#Metal_Gradient"
    />
    <linearGradient
      id="Metal_Gradient-3"
      data-name="Metal Gradient"
      x1="-2.07"
      y1="54.23"
      x2="71.4"
      y2="8.57"
      xlink:href="#Metal_Gradient"
    />
    <symbol
      id="Generic_Nozzle"
      data-name="Generic Nozzle"
      viewBox="0 0 62.46 122.88"
    >
      <g>
        <rect class="cls-73" x="0" y="61.44" width="62.46" height="61.44" />
        <polygon
          class="cls-72"
          points="0 61.44 9.07 0 54.86 0 62.46 61.44 0 61.44"
        />
        <text class="cls-75" transform="translate(11.17 54.03)">
          <tspan x="0" y="0">Nozzle</tspan>
        </text>
      </g>
    </symbol>
    <symbol
      id="Generic_Nozzle_Arrows"
      data-name="Generic Nozzle Arrows"
      viewBox="0 0 85.76 122.88"
    >
      <g>
        <use width="62.46" height="122.88" xlink:href="#Generic_Nozzle" />
        <use
          width="20.23"
          height="20.65"
          transform="translate(57.38 69.28) rotate(-44.34)"
          xlink:href="#Arrow"
        />
      </g>
    </symbol>
    <symbol
      id="Generic_Plenum"
      data-name="Generic Plenum"
      viewBox="0 0 102.41 74.4"
    >
      <g>
        <rect class="cls-35" x=".5" y=".5" width="81.93" height="73.4" />
        <polygon
          class="cls-1"
          points="98.47 39.32 82.43 50.85 82.43 .5 101.91 .5 98.47 39.32"
        />
        <text class="cls-76" transform="translate(32.66 30.99)">
          <tspan x="0" y="0">Air</tspan>
          <tspan x="-15.18" y="16.8">Plenum</tspan>
        </text>
      </g>
    </symbol>
    <symbol
      id="Generic_Plenum_Arrows"
      data-name="Generic Plenum Arrows"
      viewBox="0 0 122.51 74.4"
    >
      <g>
        <use width="102.41" height="74.4" xlink:href="#Generic_Plenum" />
        <use
          width="20.23"
          height="20.65"
          transform="translate(94.02 36.98) rotate(-45.03)"
          xlink:href="#Arrow"
        />
      </g>
    </symbol>
    <linearGradient
      id="linear-gradient-21"
      x1="42.34"
      y1="40.09"
      x2="68.88"
      y2="33.01"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#929592" />
      <stop offset=".42" stop-color="#b2b3b2" />
      <stop offset=".5" stop-color="#bababa" />
      <stop offset="1" stop-color="#b5b9b5" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-22"
      x1="12.14"
      y1="30.77"
      x2="17.91"
      y2="33.55"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#828582" />
      <stop offset="1" stop-color="#929592" />
    </linearGradient>
    <symbol
      id="High_Plume_Nozzle"
      data-name="High Plume Nozzle"
      viewBox="0 0 93.67 106.31"
    >
      <g>
        <polygon
          class="cls-4"
          points="65.26 106.19 .28 94.17 4.8 91.83 65.07 103.22 65.26 106.19"
        />
        <polygon
          class="cls-4"
          points="93.23 90.11 65.26 106.19 65.07 103.26 89.65 89.49 93.23 90.11"
        />
        <polygon
          class="cls-59"
          points="65.07 103.22 4.8 91.83 4.21 62.63 64.38 73.58 65.07 103.22"
        />
        <polygon
          class="cls-9"
          points="89.65 89.49 65.07 103.22 64.38 73.58 89.8 60.12 89.65 89.49"
        />
        <polygon
          class="cls-67"
          points="89.49 57.05 64.23 69.22 62.56 8.39 71.09 4.8 89.49 57.05"
        />
        <polygon
          class="cls-68"
          points="6.52 59.55 64.23 69.22 30.69 8.23 6.52 59.55"
        />
        <polygon
          class="cls-70"
          points="30.69 8.23 62.56 8.39 64.23 69.22 30.69 8.23"
        />
        <polygon
          class="cls-69"
          points="23.36 4.8 30.69 8.23 6.52 59.55 23.36 4.8"
        />
        <ellipse class="cls-29" cx="47.23" cy="4.8" rx="23.86" ry="4.68" />
        <line class="cls-62" x1="6.52" y1="59.55" x2="23.36" y2="4.8" />
        <line class="cls-62" x1="71.09" y1="4.8" x2="89.49" y2="57.05" />
        <polygon
          class="cls-4"
          points="64.38 73.58 .12 61.88 6.52 59.55 64.23 69.22 64.38 73.58"
        />
        <polygon
          class="cls-4"
          points="93.55 58.14 64.38 73.58 64.23 69.22 89.49 57.05 93.55 58.14"
        />
      </g>
    </symbol>
    <radialGradient
      id="radial-gradient"
      cx="27.3"
      cy="22.64"
      fx="27.3"
      fy="22.64"
      r="22.79"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#000" />
      <stop offset=".12" stop-color="#171817" />
      <stop offset=".55" stop-color="#6b6e6b" />
      <stop offset=".85" stop-color="#a0a4a0" />
      <stop offset="1" stop-color="#b5b9b5" />
    </radialGradient>
    <linearGradient
      id="linear-gradient-23"
      x1="14.96"
      y1="100.82"
      x2="30.25"
      y2="80.08"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset=".17" stop-color="#aaabaa" />
      <stop offset=".37" stop-color="#777877" />
      <stop offset=".57" stop-color="#505050" />
      <stop offset=".74" stop-color="#333" />
      <stop offset=".89" stop-color="#222" />
      <stop offset="1" stop-color="#1c1c1c" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-24"
      x1="71.54"
      y1="100.42"
      x2="31.71"
      y2="86.96"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset=".28" stop-color="#a9aaa9" />
      <stop offset=".9" stop-color="#2f2f2f" />
      <stop offset="1" stop-color="#1c1c1c" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-25"
      x1="16.89"
      y1="89.45"
      x2="15.33"
      y2="96.91"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-26"
      x1="39.22"
      y1="87.16"
      x2="63.74"
      y2="89.97"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-27"
      x1="36.48"
      y1="40.04"
      x2="3.12"
      y2="79.61"
      xlink:href="#linear-gradient-25"
    />
    <linearGradient
      id="linear-gradient-28"
      x1="31.65"
      y1="38.22"
      x2="65.8"
      y2="71.66"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-29"
      x1="31.08"
      y1="34.54"
      x2="9.27"
      y2="15.36"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-30"
      x1="41.99"
      y1="36.29"
      x2="52.25"
      y2="2.72"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-31"
      x1="39.91"
      y1="44.93"
      x2="70.94"
      y2="79.45"
      xlink:href="#linear-gradient-28"
    />
    <linearGradient
      id="linear-gradient-32"
      x1="40.15"
      y1="41.54"
      x2="70.68"
      y2="37.89"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#dadcda" />
      <stop offset=".23" stop-color="#d6d8d6" />
      <stop offset=".44" stop-color="#cbcdcb" />
      <stop offset=".64" stop-color="#b8bbb8" />
      <stop offset=".83" stop-color="#9fa19f" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <linearGradient
      id="linear-gradient-33"
      x1="38.12"
      y1="43.55"
      x2="37.97"
      y2="84.77"
      xlink:href="#linear-gradient-32"
    />
    <symbol id="Inlet_Box" data-name="Inlet Box" viewBox="0 0 79.66 101.86">
      <g>
        <ellipse class="cls-41" cx="27.3" cy="22.64" rx="23.43" ry="22.14" />
        <polygon
          class="cls-11"
          points="32.69 101.36 5.16 98.15 5.16 89.53 32.69 92.74 32.69 101.36"
        />
        <polygon
          class="cls-31"
          points="74.73 96.04 32.69 101.36 32.69 92.74 74.73 87.42 74.73 96.04"
        />
        <polygon
          class="cls-15"
          points="32.69 92.74 .5 88.99 5.16 86.99 32.69 90.2 32.69 92.74"
        />
        <polygon
          class="cls-26"
          points="74.73 84.89 32.69 90.22 32.69 92.74 79.16 86.86 74.73 84.89"
        />
        <polygon
          class="cls-50"
          points="32.69 34.74 5.16 31.53 5.16 86.99 32.69 90.2 32.69 34.74"
        />
        <polygon
          class="cls-55"
          points="32.69 34.74 74.73 29.43 74.73 84.89 32.69 90.2 32.69 34.74"
        />
        <polygon
          class="cls-36"
          points="5.16 31.53 20.72 5.64 32.69 34.74 5.16 31.53"
        />
        <polygon
          class="cls-28"
          points="74.73 29.43 32.69 34.74 20.72 5.64 35.94 3.72 74.73 29.43"
        />
        <polygon
          class="cls-25"
          points="39.15 42.74 72.7 38.5 72.7 82.75 39.15 86.99 39.15 42.74"
        />
        <polygon
          class="cls-17"
          points="36.94 41.07 70.48 36.84 72.7 38.5 39.15 42.74 36.94 41.07"
        />
        <polygon
          class="cls-40"
          points="36.94 85.32 39.15 86.99 39.15 42.74 36.94 41.07 36.94 85.32"
        />
      </g>
    </symbol>
    <linearGradient
      id="linear-gradient-34"
      x1="36.73"
      y1="51.72"
      x2="96.09"
      y2="51.72"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0" stop-color="#828582" />
      <stop offset=".54" stop-color="#b5b9b5" />
      <stop offset="1" stop-color="#828582" />
    </linearGradient>
    <symbol id="Inline_Fan" data-name="Inline Fan" viewBox="0 0 109.45 95.92">
      <g>
        <path
          class="cls-65"
          d="m36.73,13.34v68.44h.02c.4,4.61,13.52,8.32,29.66,8.32s29.26-3.71,29.66-8.32h.02V13.34h-59.36Z"
        />
        <polygon
          class="cls-27"
          points="68.98 20.28 37.84 27.66 .5 20.56 32.91 13.1 68.98 20.28"
        />
        <ellipse class="cls-61" cx="66.44" cy="9.06" rx="33.53" ry="8.93" />
        <ellipse class="cls-64" cx="66.39" cy="8.74" rx="28.9" ry="6.48" />
        <polygon
          class="cls-63"
          points="96.09 73.96 109.32 80.56 109.32 82 96.09 82 96.09 73.96"
        />
        <polygon
          class="cls-7"
          points="98.69 55.77 91.57 58.33 91.57 41.88 98.92 39.51 98.69 55.77"
        />
        <polygon
          class="cls-27"
          points="98.92 39.51 91.57 41.88 85.37 39.72 93.6 37.5 98.92 39.51"
        />
        <polygon
          class="cls-16"
          points="91.57 58.33 85.62 56.49 85.37 39.72 91.57 41.88 91.57 58.33"
        />
        <polygon
          class="cls-16"
          points="38.22 95.8 .12 86.84 .5 20.56 37.84 27.66 38.22 95.8"
        />
        <polygon
          class="cls-7"
          points="68.58 84.2 38.22 95.8 37.84 27.66 68.98 20.28 68.58 84.2"
        />
        <path
          class="cls-53"
          d="m88.5,48.43c-5.61.64-5.97,5.81-9.6,9.9-4.47,5.03-6.55,5.89-13.55,8.42"
        />
      </g>
    </symbol>
    <symbol
      id="No_Loss_Nozzle"
      data-name="No Loss Nozzle"
      viewBox="0 0 51.04 106.57"
    >
      <g>
        <polygon
          class="cls-39"
          points="25.54 106.45 3.34 106.45 3.34 91.45 25.54 93.42 25.54 106.45"
        />
        <polygon
          class="cls-8"
          points="47.74 106.45 25.54 106.45 25.54 93.42 47.74 91.45 47.74 106.45"
        />
        <path
          class="cls-42"
          d="m47.63,4.12c-1.74,0-3.16.92-3.16,2.05s1.41,2.05,3.16,2.05,3.16-.92,3.16-2.05-1.41-2.05-3.16-2.05Zm.03,3.42c-.75,0-1.36-.61-1.36-1.36s.61-1.36,1.36-1.36,1.36.61,1.36,1.36-.61,1.36-1.36,1.36Z"
        />
        <path
          class="cls-42"
          d="m3.51,4.12c-1.74,0-3.16.92-3.16,2.05s1.41,2.05,3.16,2.05,3.16-.92,3.16-2.05-1.41-2.05-3.16-2.05Zm.03,3.42c-.75,0-1.36-.61-1.36-1.36s.61-1.36,1.36-1.36,1.36.61,1.36,1.36-.61,1.36-1.36,1.36Z"
        />
        <polygon
          class="cls-48"
          points="50.92 91.15 25.54 93.42 25.78 4.47 46.63 2.88 50.92 91.15"
        />
        <polygon
          class="cls-47"
          points=".12 91.15 25.51 93.42 25.78 4.47 4.41 2.88 .12 91.15"
        />
        <polygon
          class="cls-30"
          points="4.41 2.88 25.85 .09 46.63 2.88 25.78 4.47 4.41 2.88"
        />
      </g>
    </symbol>
  </defs>
  <g id="Columns">
    <g
      *ngFor="let col of columns; let i = index"
      id="Column_{{ i + 1 }}"
      data-name="Column {{ i + 1 }}"
      [style.transform]="'translateX(' + i * -167 + 'px)'"
    >
      <g id="Background-2" data-name="Background">
        <use
          id="C{{ i + 1 }}_Nozzle_BG"
          data-name="C{{ i + 1 }} Nozzle BG"
          width="168"
          height="168"
          xlink:href="#Background"
        />
        <use
          id="C{{ i + 1 }}_Fan_BG"
          data-name="C{{ i + 1 }} Fan BG"
          width="168"
          height="168"
          transform="translate(0 167.15)"
          xlink:href="#Background"
        />
        <use
          id="C{{ i + 1 }}_Plenum_BG-2"
          data-name="C{{ i + 1 }}_Plenum_BG"
          width="168"
          height="168"
          transform="translate(0 333.33)"
          xlink:href="#Background"
        />
      </g>
      <g id="Plenum" [ngSwitch]="plenumType">
        <use
          width="20.23"
          height="20.65"
          transform="translate(70.55 470.58)"
          xlink:href="#Arrow"
        />

        <ng-container *ngSwitchCase="'Plenum'">
          <use
            id="C{{ i + 1 }}_Bypass_Air_Plenum"
            data-name="C{{ i + 1 }} Bypass Air Plenum"
            width="150.03"
            height="98.2"
            transform="translate(6.52 369.67)"
            xlink:href="#Bypass_Air_Plenum"
          />
          <use
            id="C{{ i + 1 }}_Bypass_Air_Plenum_Arrows"
            width="150.03"
            height="98.2"
            transform="translate(6.52 369.67)"
            xlink:href="#Bypass_Air_Plenum_Arrows"
          />
        </ng-container>

        <use
          *ngSwitchCase="'InletBox'"
          id="C{{ i + 1 }}_Inlet_Box"
          data-name="C{{ i + 1 }} Inlet Box"
          width="79.66"
          height="101.86"
          transform="translate(40.76 366.26)"
          xlink:href="#Inlet_Box"
        />

        <ng-container *ngSwitchDefault="">
          <use
            id="C{{ i + 1 }}_Generic_Plenum"
            data-name="C{{ i + 1 }} Generic Plenum"
            width="102.41"
            height="74.4"
            transform="translate(30.33 380.13)"
            xlink:href="#Generic_Plenum"
          />
          <use
            id="C{{ i + 1 }}_Generic_Plenum_Arrows"
            data-name="C{{ i + 1 }} Generic Plenum Arrows"
            width="122.51"
            height="74.4"
            transform="translate(30.33 380.13)"
            xlink:href="#Generic_Plenum_Arrows"
          />
        </ng-container>
      </g>
      <g id="Fan" [ngSwitch]="fanType">
        <use
          *ngSwitchCase="'Blower'"
          id="C{{ i + 1 }}_Centrifugal_Fan"
          data-name="C{{ i + 1 }} Centrifugal Fan"
          width="101.57"
          height="110.16"
          transform="translate(30.46 188.18)"
          xlink:href="#Centrifugal_Fan"
        />
        <use
          *ngSwitchCase="'Bifurcated'"
          id="C{{ i + 1 }}_Bifurcated_Fan"
          data-name="C{{ i + 1 }} Bifurcated Fan"
          width="74.22"
          height="107.63"
          transform="translate(40.45 196.86)"
          xlink:href="#Bifurcated_Fan"
        />
        <use
          *ngSwitchCase="'StraightThrough'"
          id="C{{ i + 1 }}_Inline_Fan"
          data-name="C{{ i + 1 }} Inline Fan"
          width="109.45"
          height="95.92"
          transform="translate(26.89 202.72)"
          xlink:href="#Inline_Fan"
        />
        <use
          *ngSwitchDefault=""
          id="C{{ i + 1 }}_Generic_Fan"
          data-name="C{{ i + 1 }} Generic Fan"
          width="90.55"
          height="117.26"
          transform="translate(36.27 192.04)"
          xlink:href="#Generic_Fan"
        />
      </g>
      <g id="Nozzle" [ngSwitch]="nozzleType">
        <ng-container *ngSwitchCase="'DilutionNozzle'">
          <use
            id="C{{ i + 1 }}_Dilution_Nozzle"
            data-name="C{{ i + 1 }} Dilution Nozzle"
            width="80.81"
            height="91.75"
            transform="translate(41.13 39.98)"
            xlink:href="#Dilution_Nozzle"
          />
          <use
            id="C{{ i + 1 }}_Dilution_Nozzle_Arrows"
            data-name="C{{ i + 1 }} Dilution Nozzle Arrows"
            width="94.48"
            height="91.75"
            transform="translate(41.13 39.98)"
            xlink:href="#Dilution_Nozzle_Arrows"
          />
        </ng-container>

        <use
          *ngSwitchCase="'HighPlume'"
          id="C{{ i + 1 }}_High_Plume_Nozzle"
          data-name="C{{ i + 1 }} High Plume Nozzle"
          width="93.67"
          height="106.31"
          transform="translate(34.73 30.77)"
          xlink:href="#High_Plume_Nozzle"
        />
        <use
          *ngSwitchCase="'NoLossStack'"
          id="C{{ i + 1 }}_No_Loss_Nozzle"
          data-name="C{{ i + 1 }} No Loss Nozzle"
          width="51.04"
          height="106.57"
          transform="translate(56.87 30.73)"
          xlink:href="#No_Loss_Nozzle"
        />

        <ng-container *ngSwitchDefault="">
          <use
            id="C{{ i + 1 }}_Generic_Nozzle"
            data-name="C{{ i + 1 }} Generic Nozzle"
            width="62.46"
            height="122.88"
            transform="translate(50.28 22.56)"
            xlink:href="#Generic_Nozzle"
          />
          <use
            id="C{{ i + 1 }}_Generic_Nozzle_Arrows"
            data-name="C{{ i + 1 }} Generic Nozzle Arrows"
            width="85.76"
            height="122.88"
            transform="translate(50.28 22.56)"
            xlink:href="#Generic_Nozzle_Arrows"
          />
        </ng-container>

        <use
          width="20.23"
          height="20.65"
          transform="translate(72.91 1.06)"
          xlink:href="#Arrow"
        />
      </g>
    </g>
    <g id="Column_Labels" data-name="Column Labels">
      <g id="Plenum_Labels" data-name="Plenum Labels">
        <g
          *ngIf="plenumType === 'Plenum'"
          id="C1_Bypass_Air_Plenum_Label"
          data-name="C1 Bypass Air Plenum Label"
        >
          <use
            width="257.3"
            height="18.93"
            transform="translate(222.13 384.48)"
            xlink:href="#Bypass_Air_Label"
          />
          <polyline
            class="cls-66"
            points="212.24 393.94 197.3 393.94 158.36 424.52 91.12 424.52"
          />
        </g>
        <g
          *ngIf="plenumType === 'None'"
          id="C1_Generic_Plenum_Label"
          data-name="C1 Generic Plenum Label"
        >
          <use
            width="257.3"
            height="18.93"
            transform="translate(222.13 384.48)"
            xlink:href="#Bypass_Air_Label"
          />
          <polyline
            class="cls-66"
            points="212.24 393.94 197.3 393.94 179.05 420.58 161.59 420.58"
          />
        </g>
        <g id="Inlet_Volume_Label" data-name="Inlet Volume Label">
          <text class="cls-77" transform="translate(221.4 425.38)">
            <tspan x="0" y="0">Single Fan Inlet Volume</tspan>
          </text>
          <text
            *ngIf="size?.outputs?.operatingVolume > 0"
            id="inletVolume"
            class="cls-77"
            transform="translate(399.83 425.69)"
          >
            <tspan x="0" y="0">
              {{ size?.outputs?.operatingVolume | number : '1.0-0' }} CFM
            </tspan>
          </text>
          <polyline
            class="cls-66"
            points="212.24 418.75 195.51 418.75 158.37 481.11 100 481.11"
          />
        </g>
      </g>
      <g id="Nozzle_Labels" data-name="Nozzle Labels">
        <g
          *ngIf="nozzleType === 'DilutionNozzle'"
          id="C1_Dilution_Nozzle_Label"
          data-name="C1 Dilution Nozzle Label"
        >
          <use
            width="257.57"
            height="18.93"
            transform="translate(221.4 74.98)"
            xlink:href="#Entrained_Air_Label"
          />
          <polyline
            class="cls-66"
            points="212.24 84 187.81 84 161.34 110.47 145.86 110.47"
          />
        </g>
        <g
          *ngIf="nozzleType === 'Any'"
          id="C1_Generic_Nozzle_Label"
          data-name="C1 Generic Nozzle Label"
        >
          <use
            width="257.57"
            height="18.93"
            transform="translate(221.4 74.98)"
            xlink:href="#Entrained_Air_Label"
          />
          <polyline
            class="cls-66"
            points="212.24 84 187.81 84 161.34 95.47 145.86 95.47"
          />
        </g>
        <g id="Fan_Volume_Label" data-name="Fan Volume Label">
          <text class="cls-77" transform="translate(221.4 64.51)">
            <tspan x="0" y="0">Total Single Fan Volume</tspan>
          </text>
          <text
            *ngIf="size?.outputs?.operatingVolume > 0"
            id="totalSingleFanVolume"
            class="cls-77"
            transform="translate(399.61 64.51)"
          >
            <tspan x="0" y="0">
              {{ totalSingleFanVolume | number : '1.0-0' }} CFM
            </tspan>
          </text>
          <polyline
            class="cls-66"
            points="212.24 59.39 187.54 59.39 147.86 11.59 104.5 11.59"
          />
        </g>
      </g>
    </g>
  </g>
</svg>
