import { Question } from './question.model';

export class Group {
    text: string;
    enabled: boolean;
    groups: Array<Group>;
    questions: Array<Question>;
    name: string;
    visible: boolean;

    constructor(groupConfig: any) {
        this.text = groupConfig.displayName;
        this.enabled = groupConfig.enabled;
        this.name = groupConfig.name;
        this.visible = groupConfig.visible;
        this.groups = new Array<Group>();
        this.questions = new Array<Question>();

        if (groupConfig.items && groupConfig.items.length > 0) {
            for (let i = 0; i < groupConfig.items.length; i++) {
                if (groupConfig.items[i].group) {
                    this.groups.push(new Group(groupConfig.items[i].group));
                } else if (groupConfig.items[i].question) {
                    this.questions.push(new Question(groupConfig.items[i].question));
                }
            }
        }
    }

    getQuestion(name: string): Question {

        if (this.questions && this.questions.length) {
            for (let i = 0; i < this.questions.length; i++) {
                if (this.questions[i].name.toLowerCase().trim() === name.toLowerCase().trim()) {
                    return this.questions[i];
                }
            }
        }

        if (this.groups && this.groups.length > 0) {
            for (let i = 0; i < this.groups.length; i++) {
                const question = this.groups[i].getQuestion(name);

                if (question) {
                    return question;
                }
            }
        }

        return null;

    }

    getGroup(name: string): Group {

        if (this.name.toLowerCase().trim() === name.toLowerCase().trim()) {
            return this;
        }

        if (this.groups && this.groups.length > 0) {
            for (let i = 0; i < this.groups.length; i++) {
                if (this.groups[i].name.toLowerCase().trim() === name.toLowerCase().trim()) {
                    return this.groups[i];
                } else {
                    const group = this.groups[i].getGroup(name);

                    if (group) {
                        return group;
                    }
                }
            }
        }

        return null;

    }

    requiredComplete(): boolean {

        if (this.groups && this.groups.length > 0) {
            const groups = this.groups.filter(subGroup => subGroup.visible);

            for (let i = 0; i < groups.length; i++) {

                if (!groups[i].requiredComplete()) {
                    return false;
                }

            }
        }

        if (this.questions && this.questions.length > 0) {
            const questions = this.questions.filter(question => question.visible &&
                question.required && (question.value === null || question.value === undefined || question.value === ''));

            if (questions.length > 0) {
                return false;
            }

        }

        return true;

    }

    toJSON() {

        return {

            text: this.text,
            enabled: this.enabled,
            groups: (!!this.groups ? this.groups.map(group => group.toJSON()) : null),
            questions: (!!this.questions ? this.questions.map(question => question.toJSON()) : null),
            name: this.name,
            visible: this.visible

        };

    }

}
