import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { ImageDialogRef } from './models/image-dialog-ref.dialogref';
import { IMATE_DIALOG_DATA } from './models/image-dialog-overlay.tokens';
import { IImageDialogData } from './models/i-image-dialog-data.interface';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent implements OnInit {
  selectedIndex: number;

  constructor(
    private dialogRef: ImageDialogRef,
    @Inject(IMATE_DIALOG_DATA) public data: IImageDialogData[]
  ) {
    this.selectedIndex = 0;
  }

  ngOnInit() {}

  @HostListener('window:keyup', ['$event'])
  keyUp(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'arrowleft') {
      this.previousClick();
    } else if (event.key.toLowerCase() === 'arrowright') {
      this.nextClick();
    }
  }

  previousClick() {
    this.selectedIndex = this.selectedIndex > 0 ? this.selectedIndex - 1 : 0;
  }

  nextClick() {
    this.selectedIndex =
      this.selectedIndex < this.data.length - 1
        ? this.selectedIndex + 1
        : this.data.length - 1;
  }

  close() {
    this.dialogRef.close();
  }
}
