import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Injector,
} from '@angular/core';
import { ColumnList } from '../../constants/column-list.const';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { Subscription } from 'rxjs';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';

@Component({
  selector: 'gravity-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GravityGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  constructor(protected injector: Injector) {
    super(injector);

    this.footerProductType = 'Gravity(s)';
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'roofOpeningWL',
      'budgetPrice',
      'actualCFM',
      'actualThroatVelocity',
      'actualPressureDrop',
      'weight',
      'shippedAssembled',
      'actualThroatArea',
      'louverFreeArea',
      'minimumThroatHeight',
      'actualThroatWidth',
      'actualThroatLength',
      'model'
    );

    this.setVisibleColumns([
      'roofOpeningWL',
      'budgetPrice',
      'actualCFM',
      'actualThroatVelocity',
      'actualPressureDrop',
      'weight',
      'shippedAssembled',
    ]);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();
  }

  private validSizeToDSRow(size: ValidSize) {
    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.name,
      roofOpeningWL: `${size.outputs.roofOpeningWidth} x ${size.outputs.roofOpeningLength}`,
      budgetPrice: size.pricing.price,
      actualCFM: size.outputs.actualVolume,
      actualThroatVelocity: size.outputs.actualThroatVelocity,
      actualPressureDrop: size.outputs.actualPressureDrop,
      weight: size.pricing.weight,
      shippedAssembled: size.outputs.shippedAssembled,
      actualThroatArea: size.outputs.actualThroatArea,
      louverFreeArea: size.outputs.louverFreeArea,
      minimumThroatHeight: size.outputs.minimumThroatHeight,
      actualThroatWidth: size.outputs.actualThroatWidth,
      actualThroatLength: size.outputs.actualThroatLength,
      stable: size.stability.stable,
      model: size.model,
      sizeData: size,
    };

    return rowData;
  }
}
