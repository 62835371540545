import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-erv-frost-control-dialog',
  templateUrl: './erv-frost-control-dialog.component.html',
  styleUrls: ['./erv-frost-control-dialog.component.scss'],
})
export class ErvFrostControlDialogComponent implements OnInit {
  dataSource = [
    {
      model: 'MiniVent',
      timedExhaust: 'X',
      electricPreheat: '',
      modWheel: '',
    },
    {
      model: 'MiniCore',
      timedExhaust: 'X',
      electricPreheat: '',
      modWheel: '',
    },
    {
      model: 'ECV',
      timedExhaust: 'X',
      electricPreheat: 'X',
      modWheel: 'X',
    },
    {
      model: 'ERV',
      timedExhaust: 'X',
      electricPreheat: 'X',
      modWheel: 'X *',
    },
    {
      model: 'ERVe',
      timedExhaust: 'X',
      electricPreheat: 'X',
      modWheel: 'X',
    },
  ];
  dataSourceExtra = [
    {
      model: 'MiniVent',
      terminalStrip: 'X',
      microprocessor: '',
      singleWall: '',
      doubleWall: 'X',
    },
    {
      model: 'MiniCore',
      terminalStrip: 'X',
      microprocessor: '',
      singleWall: 'X',
      doubleWall: '',
    },
    {
      model: 'ECV',
      terminalStrip: 'X',
      microprocessor: 'X',
      singleWall: 'X',
      doubleWall: 'X',
    },
    {
      model: 'ERV',
      terminalStrip: 'X',
      microprocessor: 'X',
      singleWall: 'X',
      doubleWall: 'X',
    },
    {
      model: 'ERVe',
      terminalStrip: 'X',
      microprocessor: 'X',
      singleWall: '',
      doubleWall: 'X',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<ErvFrostControlDialogComponent>
  ) {}

  ngOnInit() {}

  closeClick() {
    this.dialogRef.close();
  }
}
