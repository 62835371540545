<h2 mat-dialog-title>{{ helpItem.messageBox.title || 'Help' }}</h2>

<mat-dialog-content>
  <ng-container [ngSwitch]="!!helpItem.icon ? 1 : !!helpItem.svgIcon ? 2 : 0">
    <mat-icon
      class="mat-icon material-icons dp32"
      *ngSwitchCase="2"
      [svgIcon]="helpItem.svgIcon"
    ></mat-icon>
    <mat-icon class="mat-icon material-icons dp32" *ngSwitchCase="1">{{
      helpItem.icon
    }}</mat-icon>
    <mat-icon class="mat-icon material-icons dp32" *ngSwitchDefault
      >help_outline</mat-icon
    >
  </ng-container>
  <div class="message" [innerHTML]="content"></div>

  <div
    *ngIf="!!helpItem.messageBox.video"
    class="video"
    [ngSwitch]="helpItem.messageBox.video.showButton"
  >
    <ng-container *ngSwitchCase="true">
      <button mat-raised-button color="primary" (click)="videoClick()">
        <mat-icon>play_circle_outline</mat-icon>
        Video: {{ helpItem.messageBox.video.title }}
      </button>
    </ng-container>

    <ng-container *ngSwitchCase="false">
      <iframe
        width="560"
        height="315"
        [src]="videoEmbedUrl"
        [title]="helpItem.messageBox.video.title"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    *ngIf="!!helpItem.link"
    mat-raised-button
    color="primary"
    (click)="linkClick()"
  >
    {{ helpItem.link.title }}
  </button>
  <button
    *ngIf="!!helpItem.sideDialog"
    mat-raised-button
    color="primary"
    (click)="sideDialogClick()"
  >
    More Info
  </button>
  <button mat-raised-button mat-dialog-close color="primary">Close</button>
</mat-dialog-actions>
