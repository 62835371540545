import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ValidSize } from '../models/selection-results/valid-size.model';
import { CompareDialogComponent } from '../dialogs/compare-dialog/compare-dialog.component';
import { ErvCompareDialogData } from '../dialogs/erv-compare-dialog/models/erv-compare-dialog-data.model';
import { ErvCompareDialogComponent } from '../dialogs/erv-compare-dialog/erv-compare-dialog.component';
import { ExhaustVolumeDialogComponent } from '../dialogs/exhaust-volume-dialog/exhaust-volume-dialog.component';
import { ItemReselectionDialog } from './item-reselection-dialog.controller';
import { Item } from '../../projects/models/project-item.model';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../../google/services/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class Selections {
  constructor(
    private dialog: MatDialog,
    private itemReselectionDialog: ItemReselectionDialog,
    private sideDialogService: SideDialogService,
    private analytics: GoogleAnalyticsService
  ) {}

  showCompare(sizeList: Array<ValidSize>): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.sideDialogService.open(CompareDialogComponent, {
        disableClose: false,
        autoFocus: true,
        data: sizeList,
      });

      const subscription = dialogRef.beforeClose.subscribe(() => {
        subscription.unsubscribe();

        resolve(true);
      });
    });

    return promise;
  }

  showExhaustVolume(size: ValidSize): Promise<number> {
    const promise = new Promise<number>((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = size;

      const dialogRef = this.dialog.open(
        ExhaustVolumeDialogComponent,
        dialogConfig
      );

      const subscription = dialogRef.beforeClosed().subscribe((result) => {
        subscription.unsubscribe();

        resolve(result);
      });
    });

    return promise;
  }

  showErvCompare(exhaustVolume: number, size: ValidSize): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.analytics.trackEvent_Old(
        EventCategories.rvVsRveCompare,
        'Compare Pop-Up'
      );

      const dialogRef = this.sideDialogService.open(ErvCompareDialogComponent, {
        disableClose: false,
        autoFocus: true,
        data: new ErvCompareDialogData(exhaustVolume, size),
      });

      const subscription = dialogRef.beforeClose.subscribe(() => {
        subscription.unsubscribe();

        resolve(true);
      });
    });

    return promise;
  }

  showItemReselection(revisionItem: Item): Promise<boolean> {
    return this.itemReselectionDialog.show(revisionItem);
  }
}
