export class UserData {
    authenticated: boolean;
    firstName: string;
    lastName: string;
    salutation: string;
    suffix: string;
    phone: string;
    email: string;
    lastLogin: Date;
    id: number;
    token: string;
    message: string;
}
