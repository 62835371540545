<div
  class="trigger-icon"
  [matMenuTriggerFor]="notifications"
  [matBadge]="notices.length"
  matBadgePosition="before"
  matBadgeColor="accent"
  matBadgeSize="medium"
  #menuTrigger="matMenuTrigger"
>
  <mat-icon class="dp40 announce-icon" [@pulse]="pulseIcon ? 'show' : null"
    >circle_notifications</mat-icon
  >
</div>
<mat-menu #notifications class="notification-menu">
  <button *ngFor="let notice of notices" (click)="notice.click()" mat-menu-item>
    <mat-icon class="dp32 notice-icon">{{ notice.icon }}</mat-icon>
    {{ notice.text }}
  </button>
</mat-menu>
