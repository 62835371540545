import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LachesisService } from '../../../analytics/services/lachesis.service';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import {
  EventCategories,
  GoogleAnalyticsService,
  IEventItemModelsPayload,
} from '../../../google/services/google-analytics.service';
import { GoogleMapsService } from '../../../google/services/google-maps.service';
import { SideDialogComponent } from '../../../side-dialog/classes/side-dialog-component.interface';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { JobConflictDialog } from '../../controllers/job-conflict-dialog.controller';
import { LargeProjectDialog } from '../../controllers/large-project-dialog.controller';
import { Revision } from '../../models/project-summar-revision.model';
import { ProjectSummary } from '../../models/project-summary.model';
import { Project } from '../../models/project.model';
import { ProjectsService } from '../../services/projects.service';
import { ProjectInfoDialogData } from './models/project-info-dialog-data.model';

@Component({
  selector: 'app-project-info-dialog',
  templateUrl: './project-info-dialog.component.html',
  styleUrls: ['./project-info-dialog.component.scss'],
})
export class ProjectInfoDialogComponent implements OnInit, SideDialogComponent {
  dialogData: ProjectInfoDialogData;

  projectSummary: ProjectSummary;

  selectedRevision: Revision;

  publicToken: string;

  isEditing = false;

  revisionDeleteList: string[] = [];

  formGroup: UntypedFormGroup;
  jobName: UntypedFormControl;
  location: UntypedFormControl;

  progColor = 'primary';
  progValue = 0;

  private loadedProject: Project;

  private createRevision = false;

  constructor(
    private sideDialogService: SideDialogService,
    private core: EcapsCore,
    private projectsService: ProjectsService,
    private jobConflictDialog: JobConflictDialog,
    private router: Router,
    private analytics: GoogleAnalyticsService,
    private googleMaps: GoogleMapsService,
    private largeProjectDialog: LargeProjectDialog,
    private lachesisService: LachesisService
  ) {
    this.jobName = new UntypedFormControl('', [Validators.required]);
    this.location = new UntypedFormControl('');

    this.formGroup = new UntypedFormGroup({
      jobName: this.jobName,
      location: this.location,
    });
  }

  ngOnInit() {
    this.publicToken = this.dialogData.publicToken;

    if (!!this.publicToken) {
      this.lachesisService.trackEvent({
        eventName: 'Sharing - Opened',
      });

      this.analytics.viewItem('Sharing', {
        source: 'Shared Project',
        action: 'Open Shared Project',
        items: this.dialogData.projectSummary
          .getActiveRevision()
          .items.map((item) => {
            return {
              model: item.name,
              modelName: item.name,
            } as IEventItemModelsPayload;
          }),
      });
    }

    this.projectSummary = this.dialogData.projectSummary;

    this.selectedRevision = !!this.projectSummary
      ? this.projectSummary.getActiveRevision()
      : null;

    this.jobName.setValue(this.projectSummary.name);
    this.location.setValue(this.projectSummary.location);

    if (!!this.dialogData.project) {
      this.setLoadedProject(this.dialogData.project);

      this.isEditing = true;
    }
  }

  selectedRevisionChanged(revision: Revision) {
    this.selectedRevision = revision;
  }

  revisionDeleted(idList: string[]) {
    this.revisionDeleteList = idList;

    this.progValue = Math.ceil(
      (this.getJobSize() / environment.maxProjectSize) * 100
    );
    this.progColor =
      this.progValue >= environment.maxProjectSizeTolerance
        ? 'warn'
        : 'primary';
  }

  private saveJob(): Promise<boolean> {
    return new Promise<boolean>((result, reject) => {
      this.progValue = Math.ceil(
        (this.getJobSize() / environment.maxProjectSize) * 100
      );
      this.progColor =
        this.progValue >= environment.maxProjectSizeTolerance
          ? 'warn'
          : 'primary';

      if (this.progValue >= environment.maxProjectSizeTolerance) {
        this.core.showMessageBox({
          title: 'Job Too Large',
          message:
            'This job is too large to save. Please delete additional previous revisions before proceeding.',
          icon: 'warning',
          iconClasses: 'warn',
        });

        result(false);
      } else if (
        this.revisionDeleteList.length === this.loadedProject.revisions.length
      ) {
        this.core.showMessageBox({
          title: 'All Revisions Staged For Deletion',
          message:
            'You have staged all your revisions for deletion. Please leave at least one revision on the job.',
          icon: 'warning',
          iconClasses: 'warn',
        });
      } else {
        let saving = true;

        this.core.showLoadingGraphic('Saving...', () => saving);

        this.projectsService
          .deleteRevisions(this.loadedProject, this.revisionDeleteList)
          .then(
            () => {
              this.revisionDeleteList.forEach((revID) => {
                const revIndex = this.projectSummary.revisions.findIndex(
                  (revision) => revision.id === revID
                );

                if (revIndex > -1) {
                  this.projectSummary.revisions.splice(revIndex, 1);
                }
              });

              this.loadedProject.revisions.forEach((rev) => {
                const sumRev = this.projectSummary.revisions.find(
                  (item) => item.id === rev.id
                );

                if (!!sumRev) {
                  sumRev.active = rev.active;
                }
              });

              this.projectsService
                .updateProject(this.loadedProject, {
                  name: this.jobName.value,
                  location: this.location.value,
                })
                .then(
                  () => {
                    this.projectSummary.name = this.jobName.value;
                    this.projectSummary.location = this.location.value;

                    this.projectsService.saveProject(this.loadedProject).then(
                      () => {
                        saving = false;

                        this.core.hideLoadingGraphic();

                        result(true);
                      },
                      (errorData) => {
                        saving = false;

                        this.core.hideLoadingGraphic();

                        reject(errorData);
                      }
                    );
                  },
                  (errorData) => {
                    saving = false;

                    this.core.hideLoadingGraphic();

                    reject(errorData);
                  }
                );
            },
            (errorData) => {
              saving = false;

              this.core.hideLoadingGraphic();

              reject(errorData);
            }
          );
      }
    });
  }

  private getJobSize(): number {
    let delSize = 0;
    let fallbackJobSize = 0;

    if (this.loadedProject.size === 0) {
      for (let i = 0; i < this.loadedProject.revisions.length; i++) {
        fallbackJobSize += this.loadedProject.revisions[i].size;
      }

      fallbackJobSize = fallbackJobSize * 1.07;
    }

    this.revisionDeleteList.forEach((id) => {
      const rev = this.loadedProject.revisions.find(
        (revision) => revision.id === id
      );

      if (!!rev) {
        delSize += rev.size;
      }
    });

    return this.loadedProject.size > 0
      ? this.loadedProject.size - delSize
      : fallbackJobSize - delSize;
  }

  private loadJob() {
    if (!!this.loadedProject) {
      if (this.createRevision) {
        let loading = true;

        this.core.showLoadingGraphic('Loading...', () => loading);

        this.projectsService
          .copyRevision(
            this.loadedProject,
            this.loadedProject.getActiveRevision(),
            true
          )
          .then(() => {
            this.isEditing = false;

            this.projectsService.setLocalProject(this.loadedProject);

            this.router.navigateByUrl('/equipmentschedule');

            this.sideDialogService.close();

            loading = false;

            this.core.hideLoadingGraphic();
          });
      } else {
        this.isEditing = false;

        this.projectsService.setLocalProject(this.loadedProject);

        this.router.navigateByUrl('/equipmentschedule');

        this.sideDialogService.close();
      }
    } else {
      let loading = true;

      this.core.showLoadingGraphic('Loading...', function () {
        return loading;
      });

      if (this.publicToken) {
        this.projectsService
          .loadSharedProject(this.publicToken)
          .then((project) => {
            this.analytics.trackEvent_Old(
              EventCategories.sharing,
              'Shared Project Opened'
            );

            this.projectsService.setLocalProject(project);

            this.router.navigateByUrl('/equipmentschedule');

            loading = false;

            this.core.hideLoadingGraphic();

            this.sideDialogService.close();
          });
      } else {
        this.projectsService
          .loadSavedProject(this.projectSummary.id, true, this.selectedRevision)
          .then((project) => {
            this.projectsService.setLocalProject(project);

            loading = false;

            this.core.hideLoadingGraphic();

            this.largeProjectDialog.show(project).then((result) => {
              if (result === 'OK') {
                this.router.navigateByUrl('/equipmentschedule');

                this.sideDialogService.close();
              } else {
                this.setLoadedProject(project);

                this.isEditing = true;
              }
            });
          });
      }
    }
  }

  editJobClick() {
    this.projectsService.getLocalProject().then((localProject) => {
      if (localProject.id === this.projectSummary.id) {
        this.setLoadedProject(localProject);

        this.isEditing = true;
      } else if (
        !!this.loadedProject &&
        this.loadedProject.id === this.projectSummary.id
      ) {
        this.isEditing = true;
      } else {
        let loading = true;

        this.core.showLoadingGraphic('Loading...', () => loading);

        this.projectsService
          .loadSavedProject(this.projectSummary.id, false, null, false)
          .then((project) => {
            this.createRevision = true;

            this.setLoadedProject(project);

            this.isEditing = true;

            loading = false;

            this.core.hideLoadingGraphic();
          });
      }
    });
  }

  saveJobClick() {
    this.saveJob().then((result) => {
      if (result) {
        this.isEditing = false;
      }
    });
  }

  saveLoadClick() {
    this.saveJob().then((result) => {
      if (result) {
        this.loadJob();
      }
    });
  }

  loadClick() {
    if (!!this.publicToken) {
      this.lachesisService.trackEvent({
        eventName: 'Sharing - Loaded',
      });
    }

    this.projectsService.getLocalProject().then((project) => {
      if (
        project.id === this.projectSummary.id &&
        project.getActiveRevision().id === this.selectedRevision.id
      ) {
        this.router.navigateByUrl('/equipmentschedule');

        this.sideDialogService.close();
      } else {
        if (!!project && project.getActiveRevision().items.length > 0) {
          this.jobConflictDialog.show().then((result) => {
            if (result === true) {
              this.loadJob();
            }
          });
        } else {
          this.loadJob();
        }
      }
    });
  }

  closeClick() {
    this.sideDialogService.close();
  }

  setLoadedProject(project: Project) {
    this.loadedProject = project;
    let fallbackJobSize = 0;

    if (project.size === 0) {
      if (this.loadedProject.size === 0) {
        for (let i = 0; i < this.loadedProject.revisions.length; i++) {
          fallbackJobSize += this.loadedProject.revisions[i].size;
        }
      }
    }

    this.progValue =
      project.size > 0
        ? Math.ceil((project.size / environment.maxProjectSize) * 100)
        : Math.ceil((fallbackJobSize / environment.maxProjectSize) * 100) + 5;

    this.progColor =
      this.progValue >= environment.maxProjectSizeTolerance
        ? 'warn'
        : 'primary';
  }

  validateAddress(): Promise<boolean> {
    return new Promise<boolean>((result, reject) => {
      // TODO: Remove when Google Maps API is fixed
      result(true);
      return;

      if (
        !!this.location.value &&
        (<string>this.location.value).trim() !== ''
      ) {
        this.googleMaps.apiLoaded.then(() => {
          this.googleMaps.geocodeAddress(this.location.value).then(
            (address) => {
              this.location.setValue(address.formattedAddress);

              result(true);
            },
            () => {
              this.core
                .showMessageBox({
                  title: 'Location Error',
                  message:
                    'The location you have input does not appear to be valid. Please try again.',
                  icon: 'warning',
                  iconClasses: 'warn',
                })
                .then(() => {
                  this.location.setValue('');

                  result(false);
                });
            }
          );
        });
      } else {
        this.location.setValue('');

        result(true);
      }
    });
  }
}
