<svg
  id="Layer_1"
  data-name="Layer 1"
  xmlns="http://www.w3.org/2000/svg"
  width="479.9"
  height="269.64"
  viewBox="0 0 479.9 269.64"
>
  <g id="g1011">
    <g id="g873">
      <g id="g1055">
        <path
          id="path869"
          d="M241.73,85.35l116.79.71"
          transform="translate(-9.64 -10.05)"
          style="fill: none; stroke: #000; stroke-width: 2px;"
        />
        <path
          id="path871"
          d="M297.8,63.38l3.22.18.17,11.07,1.25,1.79V82l.81.8v4.91l-.76.76h-5.22l-.93-.53V82.67l1.09-1.09Z"
          transform="translate(-9.64 -10.05)"
          style="stroke: #000;"
        />
      </g>
      <g id="g1060">
        <ellipse
          id="path819"
          cx="289.45"
          cy="208.05"
          rx="95.53"
          ry="21.02"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            stroke-dasharray: 8, 8;
          "
        />
        <path
          id="path875-2-6"
          d="M358.46,88.78l35.72,125.35"
          transform="translate(-9.64 -10.05)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
            stroke-dasharray: 8, 8;
          "
        />
        <path
          id="path875-7"
          d="M240.25,88.78,204.18,214.13"
          transform="translate(-9.64 -10.05)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
            stroke-dasharray: 8, 8;
          "
        />
        <g id="g1164">
          <path
            id="path1160"
            d="M292.68,210.86l13.84,13.84"
            transform="translate(-9.64 -10.05)"
            style="fill: none; stroke: #000;"
          />
          <path
            id="path1162"
            d="M306.52,210.86,292.68,224.7"
            transform="translate(-9.64 -10.05)"
            style="fill: none; stroke: #000;"
          />
        </g>
      </g>
    </g>
    <g id="g867">
      <g id="g1064">
        <path
          id="path861"
          d="M47.78,85.35l116.79.71"
          transform="translate(-9.64 -10.05)"
          style="fill: none; stroke: #000; stroke-width: 2px;"
        />
        <path
          id="path863"
          d="M103.85,63.38l3.22.18.17,11.07,1.25,1.79V82l.81.8v4.91l-.76.76h-5.22l-.93-.53V82.67l1.09-1.09Z"
          transform="translate(-9.64 -10.05)"
          style="stroke: #000;"
        />
      </g>
      <g id="g1069">
        <ellipse
          id="path817"
          cx="96.78"
          cy="208.38"
          rx="95.44"
          ry="21.23"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 2px;
            stroke-dasharray: 8, 8;
          "
        />
        <path
          id="path875"
          d="M46.71,88.56,10.64,213.92"
          transform="translate(-9.64 -10.05)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
            stroke-dasharray: 8, 8;
          "
        />
        <path
          id="path875-2"
          d="M164.92,88.56l35.72,125.36"
          transform="translate(-9.64 -10.05)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
            stroke-dasharray: 8, 8;
          "
        />
        <g id="g1155">
          <path
            id="path1142"
            d="M100,210.86l13.84,13.84"
            transform="translate(-9.64 -10.05)"
            style="fill: none; stroke: #000;"
          />
          <path
            id="path1144"
            d="M113.82,210.86,100,224.7"
            transform="translate(-9.64 -10.05)"
            style="fill: none; stroke: #000;"
          />
        </g>
      </g>
    </g>
    <g id="g1075">
      <g id="text934-8-3" style="isolation: isolate;">
        <text
          transform="translate(172.57 264.45)"
          style="
            isolation: isolate;
            font-size: 16px;
            font-family: ArialMT, Arial;
          "
        >
          {{ getDSFanSpacing() | number: &apos;1.0-0&apos; }} ft.
        </text>
      </g>
      <g id="g1116">
        <path
          id="path1112"
          d="M299.09,253.45l-192.23.11"
          transform="translate(-9.64 -10.05)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
          "
        />
        <path
          id="path1118"
          d="M106.89,247.14v13.24"
          transform="translate(-9.64 -10.05)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
          "
        />
        <path
          id="path1118-0"
          d="M299.59,246.67V259.9"
          transform="translate(-9.64 -10.05)"
          style="
            fill: none;
            stroke: #000;
            stroke-linecap: round;
            stroke-width: 2px;
          "
        />
      </g>
    </g>
    <g id="text912-8" style="isolation: isolate;">
      <text
        transform="translate(96.68 13.73)"
        style="
          isolation: isolate;
          font-size: 16px;
          font-family: Arial-BoldMT, Arial;
          font-weight: 700;
        "
      >
        {{ getFanSpacingTitle() }}
      </text>
    </g>
  </g>
</svg>
