import { SideDialogService } from '../../side-dialog/services/side-dialog.service';
import { LouverCertificationDialogComponent } from '../dialogs/louver-certification-dialog/louver-certification-dialog.component';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })
export class LouverCertificationDialog {

    constructor(private sideDialogService: SideDialogService) { }

    show(): Promise<boolean> {

        return new Promise<boolean>((resolve, reject) => {

            const dialogRef = this.sideDialogService.open(LouverCertificationDialogComponent, {
                disableClose: false,
                autoFocus: true
            });

            const subscription = dialogRef.beforeClose.subscribe(() => {

                resolve(true);

            }, null, () => {

                subscription.unsubscribe();

            });

        });

    }

}
