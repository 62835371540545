import { ICompetitorCompItem } from './i-competitor-item.interface';

export const competitorCompareList: ICompetitorCompItem[] = [
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'Celebrity1',
  },
  {
    ghModel: 'RV',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'RN',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'H2-B',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'H2-C',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'H2-D',
  },
  {
    ghModel: 'MPX',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'RN',
  },
  {
    ghModel: 'RV',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'RN',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'V2-A',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'V2-B',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'V2-C',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'Aaon',
    compModel: 'V2-D',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'AA',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'AA',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'I',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'I',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'I',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'V',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'V',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'AbsoluteAir',
    compModel: 'V',
  },
  {
    ghModel: 'IP',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '400',
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '2100',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCBRU',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '2100',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'BIDW-21',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '3000',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-41',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '3000',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '3000',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '3800',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'AFSW-21',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '7100',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '8100',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '8100',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '8100',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '8800',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '400A',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '400M',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '400MB',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: '8800 STAR',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'RSFP',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'AFSL',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RSF',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'AFSN',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'AVAB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'AVAD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'AVB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'AVD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DCH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DCH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DCK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DCK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DCK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DCK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DM',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DM',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'DMS',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'RBCE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'EC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'EC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'EC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'EC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'ECH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'ECH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'ECH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBF',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'ECH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'ECH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'FHI',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'EV ',
  },
  {
    ghModel: 'SFB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FCF',
  },
  {
    ghModel: 'SCE3',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FN',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS3',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FN',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FN',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FN',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FQ',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FQ',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FQ',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'FQ',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'HA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'HA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'HDD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'FHI',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'IV',
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'K',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'K',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'K',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'K',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'AS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'LQ',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GRSI',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'LQV',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PDU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PDU-W',
  },
  {
    ghModel: 'LB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PL',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PNN',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PNU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PNU-W',
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PRN',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'PV',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'QBMX',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'QBR',
  },
  {
    ghModel: 'SWB-AF',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'QBR',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'SILENTVANE PLUG',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'TIB-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBG',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB-CA',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUD',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH-SE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH-SE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH-UF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB-L/H',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH-UF',
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UBH-UF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RDU',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUD',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'UD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'VQ',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'VQL',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'BSQ',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'XB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'Acme',
    compModel: 'XD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'IMO-310',
    trademark: ['Greenheck'],
    compName: 'ActionAir',
    compModel: 'A60',
  },
  {
    ghModel: 'IMO-311',
    trademark: ['Greenheck'],
    compName: 'ActionAir',
    compModel: 'A60',
  },
  {
    ghModel: 'SSIMO-310',
    trademark: ['Greenheck'],
    compName: 'ActionAir',
    compModel: 'A60',
  },
  {
    ghModel: 'SSIMO-311',
    trademark: ['Greenheck'],
    compName: 'ActionAir',
    compModel: 'A60',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck'],
    compName: 'ActionAir',
    compModel: 'CB',
  },
  {
    ghModel: 'HTD-630',
    trademark: ['Greenheck'],
    compName: 'ActionAir',
    compModel: 'TD30AF2',
  },
  {
    ghModel: 'HTD-636',
    trademark: ['Greenheck'],
    compName: 'ActionAir',
    compModel: 'TD30AF2',
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'ADB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'ADD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'AMX',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'AMX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'AMXR',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'AMXSH',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'AMXSH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'ATA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'ATABD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'AVAB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'AVAD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TAUB-CA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'BD40C',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'BIUB',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'BIUBU2',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'BIUBU5',
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'BSTA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'BSTAB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CA DWDI',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CA DWDI',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CA SWSI',
  },
  {
    ghModel: 'MCY',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CAC-CH',
  },
  {
    ghModel: 'MCB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CAC-FH',
  },
  {
    ghModel: 'MCP',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CAC-M',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CAF DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CAF DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CBA-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CBA-SW',
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CBD',
  },
  {
    ghModel: 'BIDW-21',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CB-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-41',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CB-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CBF-BI',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CB-SW',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CCP-A',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CCP-F',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CF ',
  },
  {
    ghModel: 'LSF',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CFS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CIC',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'CPN-BIA',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'EDB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'EDD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'FTFA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'MCP',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'M39',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'MH',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'MHA',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'MH-AW',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'MHB',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'MH-BW',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'MH-OW',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'PLC',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'PLCD',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'MCY',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'PUM',
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'QMX',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'QMXD-HP',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'QMX-HP',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'QMXU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'QMXU-HP',
  },
  {
    ghModel: 'BCSW-FRP',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'SWCBF',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TCN-B',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TCNH-B',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCBRS',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TCNHS-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRU',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TCNHU-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRS',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TCNS-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRU',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TCNU-N',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TIB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TID',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'TSBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'UCIC',
  },
  {
    ghModel: 'MCB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'UM',
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VBBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VJ',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VJBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VPBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VPBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VSBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VTBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VW ',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Aerovent',
    compModel: 'VWBD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A200',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A258',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A281',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A320',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A328',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A424',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A435',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A445A',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A454C',
  },
  {
    ghModel: 'K8204E',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A454CM',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A455',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A455A',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A455E',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A455I',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A465',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A481',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A485',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A488A',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A490',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A500',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A500',
  },
  {
    ghModel: 'K6746MD',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A520',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A590',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A591',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A634',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A635',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A635A',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A635AF',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A650',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A655',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A665C',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A680',
  },
  {
    ghModel: 'K8206E',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A681CM',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'A750',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AAC-482-12-6',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AAC-482-6-6',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AAC-482-8-8',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AATL30',
  },
  {
    ghModel: 'AC153S',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AATL30',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AATL42',
  },
  {
    ghModel: 'AC154S',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AATL42',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AEA662HSL',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'AIS4',
  },
  {
    ghModel: 'AFG501',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'ALAF5',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'BVF',
  },
  {
    ghModel: 'ASL401',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'G461',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'S400',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'S445',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'S445A',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'S645',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'S645A',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'AirBalance',
    compModel: 'S655',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '119',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '289',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '291',
  },
  {
    ghModel: 'CRD-1LP',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '293',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '295',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '297',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '319',
  },
  {
    ghModel: 'OFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '119AV',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '119F',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '119SS',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '119X',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '119X',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '119X',
  },
  {
    ghModel: 'SSFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: '319SS',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A150VCD',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A1770',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A182',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A182',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A182',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A182',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A200',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A200-VCRD',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A258',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A281',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A320',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A328',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A335',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A395',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A395',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A424',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A435',
  },
  {
    ghModel: 'EDK-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A440',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A445A',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A454C',
  },
  {
    ghModel: 'EACC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A454CM',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A455',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A455A',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A455E',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A455I',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A465',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A481',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A485',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A488A',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A490',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A500',
  },
  {
    ghModel: 'ESD-635D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A520',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A590',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A591',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A634',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A635',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A635A',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A635AF',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A650',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A655',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A665C',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A680',
  },
  {
    ghModel: 'EACC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A681CM',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A70',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A750',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'A80',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AAC-482-12-6',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AAC-482-6-6',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AAC-482-8-8',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AATL30',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AATL30',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AATL42',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AATL42',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AB1',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-1',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-1',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-1',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-1',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-111',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-112',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-18',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-19',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-2',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-2',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-2',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-2',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-411',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-411',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-51',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-51',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-515',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-516',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-52',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-52',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-525',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-526',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-530',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-580',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AC-581',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AEA662HSL',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'AIS4',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ALAF5',
  },
  {
    ghModel: 'PEV-400',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ASEP45S',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BID4',
  },
  {
    ghModel: 'HB-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BID9',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS50',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS55',
  },
  {
    ghModel: 'WD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-320',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-400',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-410',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-420',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'WD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BS66',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BSL',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'BVF',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'D19',
  },
  {
    ghModel: 'ODFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'D19AV',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'D19X',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'D19X',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'D19X',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'D39',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA1',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA1F',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA1G',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA1H250',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA2',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA2F',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA2G',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA2H250',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FA2HE250',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FR1',
  },
  {
    ghModel: 'FSD-211M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FR1',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FR1H250',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FR2',
  },
  {
    ghModel: 'FSD-212M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FR2',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FR2H250',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS1',
  },
  {
    ghModel: 'FSD-211M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS1',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS1GF',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS1H250',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS1HE250',
  },
  {
    ghModel: 'SSFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS1-SS',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2',
  },
  {
    ghModel: 'FSD-212M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2C',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2C',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2C',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2GF',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2H250',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FS2HE250',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FSA-100',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FSA-101',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FT1',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'FT1H250',
  },
  {
    ghModel: 'FSL-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'G461',
  },
  {
    ghModel: 'HCD-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID30',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID41',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID42',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID43',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID50',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID50',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID51',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID54',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'ID55',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'MA19',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'MA19CH',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'MD19',
  },
  {
    ghModel: 'SSDFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'MD19SS',
  },
  {
    ghModel: 'DFD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'MD39',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'P465M',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'R19',
  },
  {
    ghModel: 'FDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'R19',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'RC',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'RF',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'RS',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'RS250',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S1',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S1(SS)',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S1H250',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S1H250',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S1H250SS',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S2',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S400',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S445',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S445A',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S645',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S645A',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'S655',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SA1',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SA2',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SR1',
  },
  {
    ghModel: 'SMD-201M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SR1',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SR1H250',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SR2',
  },
  {
    ghModel: 'SMD-202M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SR2',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'SR2H250',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'TA1',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'TA1H250',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'TB-58',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AirBalance',
    compModel: 'TB-59',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'K609',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'K6096',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'K6744',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'K6746',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'K6772',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'K8204',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'K8206A',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'SCH501',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'SCV501',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'T608',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'T6482',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'T6784',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Airolite',
    compModel: 'T6796',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '609',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '6096',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '6774',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '6776',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '6784',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '6786',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '9106',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '9108',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '9112',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AAC-482-12-6',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '9208',
  },
  {
    ghModel: 'AFS-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: '9612',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC153',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC153S',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC154',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC154S',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC155',
  },
  {
    ghModel: 'ESU-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC420',
  },
  {
    ghModel: 'ESU-150S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC420S',
  },
  {
    ghModel: 'ESU-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC460',
  },
  {
    ghModel: 'ESU-130S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'AC460S',
  },
  {
    ghModel: 'FSL-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'ASL401',
  },
  {
    ghModel: 'BVC',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'BVC',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'BVF',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'ENCB609',
  },
  {
    ghModel: 'SED-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K601D',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K605',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K605D',
  },
  {
    ghModel: 'ESS-502D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K605MD',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6096HP',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K609HP',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K638',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K638HP',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6744X',
  },
  {
    ghModel: 'ESD-635D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6746MD',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6746MDE',
  },
  {
    ghModel: 'ESD-635PD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6746PD',
  },
  {
    ghModel: 'ESD-634X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6746X',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6746X',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6774',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6776',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6844',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6846',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K6856',
  },
  {
    ghModel: 'EACC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K8204E',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K8206',
  },
  {
    ghModel: 'EACA-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K8206AMD',
  },
  {
    ghModel: 'EACC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K8206E',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K8504',
  },
  {
    ghModel: 'ECD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'K8506',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'KN827',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'KX827',
  },
  {
    ghModel: 'EHV-550',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCC550',
  },
  {
    ghModel: 'EHV-550D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCC550MD',
  },
  {
    ghModel: 'EHV-901',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCC901',
  },
  {
    ghModel: 'EHV-901D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCC901MD',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH201',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH401',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH501X',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH601',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH601MD',
  },
  {
    ghModel: 'EHH-601DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH601MDE',
  },
  {
    ghModel: 'EHH-601PD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH601PD',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH7',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCH701',
  },
  {
    ghModel: 'EVH-302D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCV302MD',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCV501MD',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCV602',
  },
  {
    ghModel: 'EVH-602D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'SCV602MD',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T5832',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T63815',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T645',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T6636',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T6786',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T9106',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T9108',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T9112',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T9208',
  },
  {
    ghModel: 'AFS-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Airolite',
    compModel: 'T9612',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'K609',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'K6096',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'K6744',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'K6746',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'K6772',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'K8204',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'K8206A',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'SCH501',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'SCV501',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'T608',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'T6482',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'T6784',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'Airolite',
    compModel: 'T6796',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: '4D375',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: '4DB4',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: '4Z375',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: '4ZB4',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: '5WDRL',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: '6D375',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: '6Z375',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: 'BV15FL',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'AirPerformance',
    compModel: 'BV4',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: '4D375',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: '4DB4',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: '4Z375',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: '4ZB4',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: '5WDRL',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: '6D375',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: '6Z375',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: 'BV15FL',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck'],
    compName: 'AirPerformance',
    compModel: 'BV4',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'AirPerformance',
    compModel: '5WDRL',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'AirPerformance',
    compModel: '6D375',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-FEA-II',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-FSA-II',
  },
  {
    ghModel: 'ABD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-HP-SE-II',
  },
  {
    ghModel: 'ABD-GM',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-HP-SS-II',
  },
  {
    ghModel: 'ABD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-II',
  },
  {
    ghModel: 'ABD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-IIA',
  },
  {
    ghModel: 'ABD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-II-HP',
  },
  {
    ghModel: 'ABD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-SE-II',
  },
  {
    ghModel: 'ABD-GM',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CAR-SS-II',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CER-FEA-II',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CER-FEA-II',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CER-FEA-IIR',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CER-FSA-II',
  },
  {
    ghModel: 'ABD-GM',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CER-R-II',
  },
  {
    ghModel: 'ABD-GM',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CER-S-II',
  },
  {
    ghModel: 'ABD-GM',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CER-SII-R',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CSR-FSA-II',
  },
  {
    ghModel: 'ABD-FD',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CSR-FSA-IIR',
  },
  {
    ghModel: 'ABD-GM',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CSR-R-II',
  },
  {
    ghModel: 'ABD-GM',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'CSR-S-II',
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'LW',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'LW',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'SE1000',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'SE2000',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'SE3000',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'SE4000',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ABD-Z1',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'ZRT-1',
  },
  {
    ghModel: 'ABD-Z1',
    trademark: ['Greenheck'],
    compName: 'Aldes',
    compModel: 'ZRT-S-1',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EAA-845',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EAJ-637',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EBE-445',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EBI-445',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EBV-145',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EBV-445',
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECD-245',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECD-245',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECD-445',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'SCH601MD',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECD-545-MD',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECD-635',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECV-545',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ECV-645-MD',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EDD-445',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EDD-637',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFD-245',
  },
  {
    ghModel: 'K6744X',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFD-435',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFD-437',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFD-445',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFD-637',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFD-637',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFD-637-MD',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFJ-245',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFJ-445',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFJ-645',
  },
  {
    ghModel: 'SCC901',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFJ-937',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFY-245',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EFY-445',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EOD-445',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EOD-637',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EOJ-445',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EOJ-637',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'ERD-645',
  },
  {
    ghModel: 'ASL401',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EVS-422',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EXA-645',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EXD-437',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'EXD-645',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'HJ445',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'SFD-445',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'SFD-635',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'SFJ-445',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'SFJ-645',
  },
  {
    ghModel: 'AFG501',
    trademark: ['Airolite'],
    compName: 'All_Lite',
    compModel: 'XAV-545',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EAA-845',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EAJ-637',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EBE-445',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EBI-445',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EBV-145',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EBV-445',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECD-245',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECD-245',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECD-445',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECD-545-MD',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECD-635',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECV-545',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ECV-645-MD',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EDD-445',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EDD-637',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFD-245',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFD-435',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFD-437',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFD-445',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFD-637',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFD-637',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFD-637-MD',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFJ-245',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFJ-445',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFJ-645',
  },
  {
    ghModel: 'EHV-901',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFJ-937',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFK-437',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFY-245',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EFY-445',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EOD-445',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EOD-637',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EOJ-445',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EOJ-637',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'ERD-645',
  },
  {
    ghModel: 'FSL-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EVS-422',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EXA-645',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EXD-437',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'EXD-645',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'HJ445',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'SFD-445',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'SFD-635',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'SFJ-445',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'SFJ-645',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck'],
    compName: 'All_Lite',
    compModel: 'XAV-545',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'ECV-545',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EFD-245',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EFD-437',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EFD-637',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EFJ-245',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EFJ-445',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EFJ-645',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EOD-445',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EOD-637',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EXA-645',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'All_Lite',
    compModel: 'EXD-437',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LAA-129',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LAA-66',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LAA-86',
  },
  {
    ghModel: 'AC155',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-15',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-21A',
  },
  {
    ghModel: 'K6744X',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-22',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-23',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-24',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-29',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-31',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-31A',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-32',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-32',
  },
  {
    ghModel: 'K6746MD',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-32C',
  },
  {
    ghModel: 'T6786',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-33A',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-42',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-44',
  },
  {
    ghModel: 'K8504',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-45C',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-47',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-47A',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-48',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-52',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-52',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-53',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-54',
  },
  {
    ghModel: 'SCH601MD',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-54',
  },
  {
    ghModel: 'SCH601MDE',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-54',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-57',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-58',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-63',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-63A',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-64BD',
  },
  {
    ghModel: 'K8506',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-65C',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-68',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-69',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-73',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-81H',
  },
  {
    ghModel: 'AFG501',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LE-F5',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LF-21',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LF-21A',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LF-31A',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LF-33',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'LF-47',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'TL-30',
  },
  {
    ghModel: 'AC153S',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'TL-30',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'TL-42',
  },
  {
    ghModel: 'AC154S',
    trademark: ['Airolite'],
    compName: 'AmericanWarming',
    compModel: 'TL-42',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-21A',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-22',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-23',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-31A',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-53',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-54',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-54',
  },
  {
    ghModel: 'EHH-601DE',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-54',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-57',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'LE-58',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'PE-47M',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'AmericanWarming',
    compModel: 'PE-47M',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-10',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-10',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-10',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-12',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-12',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-12',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-16',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-16',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-16',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-17',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-17',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-17',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-18',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-18',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-18',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-20',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-20',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-20',
  },
  {
    ghModel: 'WD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-20',
  },
  {
    ghModel: 'WD-200',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-20',
  },
  {
    ghModel: 'WD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-20',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-22',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-22',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-22',
  },
  {
    ghModel: 'WD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-22',
  },
  {
    ghModel: 'WD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-22',
  },
  {
    ghModel: 'WD-320',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-22',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-23',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-23',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-23',
  },
  {
    ghModel: 'WD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-23',
  },
  {
    ghModel: 'WD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-23',
  },
  {
    ghModel: 'WD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-23',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-30',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-30',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-30',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-31',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-31',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-31',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-32',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-32',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-32',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-33',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-33',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-33',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-34',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-34',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-34',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-35',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-35',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-35',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-36',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-36',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-36',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-37',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-37',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-37',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-38',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-38',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-38',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-50',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-50',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-51',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-52',
  },
  {
    ghModel: 'HB-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-53',
  },
  {
    ghModel: 'HBR-050',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-561',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-72',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-72',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-72',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-73',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-73',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BD-73',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'BVF',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'CR-58',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'CR-59',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'E- 31',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LAA-1215',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LAA-129',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LAA-66',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LAA-86',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-15',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-24',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-27',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-29',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-31A',
  },
  {
    ghModel: 'ESD-603D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-32',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-32',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-32',
  },
  {
    ghModel: 'ESD-635D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-32C',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-33',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-33A',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-34',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-42',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-44',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-45C',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-47',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-47A',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-48',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-48A',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-52',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-63',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-63A',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-64BD',
  },
  {
    ghModel: 'ECD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-65C',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-68',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-69',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-73',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-81H',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-88A',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-F5',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LF-21',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LF-21A',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LF-31A',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LF-33',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LF-46',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'LF-47',
  },
  {
    ghModel: 'IMO-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'MD-411',
  },
  {
    ghModel: 'IMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'MD-411',
  },
  {
    ghModel: 'ESD-635PD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'PE-32',
  },
  {
    ghModel: 'BR-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'PR-10',
  },
  {
    ghModel: 'BR-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'PR-10',
  },
  {
    ghModel: 'BR-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'PR-10',
  },
  {
    ghModel: 'HPR-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'PR-11',
  },
  {
    ghModel: 'HPR-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'PR-12',
  },
  {
    ghModel: 'HPR-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'PR-13',
  },
  {
    ghModel: 'HBTR-451',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'SL100BT-1',
  },
  {
    ghModel: 'HTD-630',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'TFD-75',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'TL-30',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'TL-30',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'TL-42',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'TL-42',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-1',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-1',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-1',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-1',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-10',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-10',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-10',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-10',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-140',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-140',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-140',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-140',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-18',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-19',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-2',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-2',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-2',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-2',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-20',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-20',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-20',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-20',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-22',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-22',
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-24',
  },
  {
    ghModel: 'VCDRM-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-24',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-25',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-26',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-27',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-28',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-29',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-30',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-31',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-411',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-412',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-421',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-422',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-423',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-51-1',
  },
  {
    ghModel: 'HCDR-050',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-561',
  },
  {
    ghModel: 'HCDR-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-561',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-562',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-563',
  },
  {
    ghModel: 'HCDR-351',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-56-ISO',
  },
  {
    ghModel: 'HCD-140',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-621',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-8',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-822',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'AmericanWarming',
    compModel: 'VC-9',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-21A',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-23',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-31',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-31A',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-57',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-58',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'AmericanWarming',
    compModel: 'LE-59',
  },
  {
    ghModel: 'XG-H4002',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '10-L-AL ',
  },
  {
    ghModel: 'XG-H4002SS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '10-L-SS',
  },
  {
    ghModel: 'XG-H4002S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '10-L-ST',
  },
  {
    ghModel: 'XG-V4002',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '10-S-AL',
  },
  {
    ghModel: 'XG-V4002SS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '10-S-SS',
  },
  {
    ghModel: 'XG-V4002S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '10-S-ST',
  },
  {
    ghModel: 'XG-L',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-1L',
  },
  {
    ghModel: 'XG-S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-1S',
  },
  {
    ghModel: 'XG-LT',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-2L',
  },
  {
    ghModel: 'XG-ST',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-2S',
  },
  {
    ghModel: 'XG-LTC',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-2W/2E',
  },
  {
    ghModel: 'XG-LTC3',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-3L',
  },
  {
    ghModel: 'XG-LS3',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-3S',
  },
  {
    ghModel: 'XG-LS4',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1C-4',
  },
  {
    ghModel: 'XG-LS3M',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1CL-DA',
  },
  {
    ghModel: 'XG-LS4M',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '1CL-DA',
  },
  {
    ghModel: 'XG-H4004',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '20-L-AL',
  },
  {
    ghModel: 'XG-H4004SS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '20-L-SS',
  },
  {
    ghModel: 'XG-H4004S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '20-L-ST',
  },
  {
    ghModel: 'XG-V4004',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '20-S-AL',
  },
  {
    ghModel: 'XG-V4004SS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '20-S-SS',
  },
  {
    ghModel: 'XG-V4004S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '20-S-ST',
  },
  {
    ghModel: 'XG-H4002R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-0 AL',
  },
  {
    ghModel: 'XG-V4002R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-0 AL',
  },
  {
    ghModel: 'XG-H4002RS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-0 ST',
  },
  {
    ghModel: 'XG-V4002RS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-0 ST',
  },
  {
    ghModel: 'XG-H4002RSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-0-SS',
  },
  {
    ghModel: 'XG-V4002RSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-0-SS',
  },
  {
    ghModel: 'XG-RH',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-45 AL',
  },
  {
    ghModel: 'XG-RHE',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-45 AL',
  },
  {
    ghModel: 'XG-SRH',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-45 ST',
  },
  {
    ghModel: 'XG-RHSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '30-45-SS',
  },
  {
    ghModel: 'XG-RP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3P AL',
  },
  {
    ghModel: 'XG-SRP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3P ST',
  },
  {
    ghModel: 'XG-7500R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3PD',
  },
  {
    ghModel: 'XG-7550R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3PD',
  },
  {
    ghModel: 'XG-7600R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3PD',
  },
  {
    ghModel: 'XG-7650R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3PD',
  },
  {
    ghModel: 'XG-RPSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3P-SS',
  },
  {
    ghModel: 'XG-PRTB',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3PU',
  },
  {
    ghModel: 'XG-SPRTB',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: '3PU',
  },
  {
    ghModel: 'XG-UP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'AD',
  },
  {
    ghModel: 'XG-UPI',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'AD w/ Insulation',
  },
  {
    ghModel: 'XG-SGDB',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ADBG',
  },
  {
    ghModel: 'XG-2100',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'AL Border 7',
  },
  {
    ghModel: 'XG-2000',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'AL Border 9',
  },
  {
    ghModel: 'XG-2200',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'AL Border FG',
  },
  {
    ghModel: 'XG-SGWM',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'AMSG',
  },
  {
    ghModel: 'XG-SGSP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'AMXS',
  },
  {
    ghModel: 'XG-Periflow',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'Aorta',
  },
  {
    ghModel: 'XG-SGLF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ASFP',
  },
  {
    ghModel: 'XG-SGRP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ASSG',
  },
  {
    ghModel: 'XG-RSD',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'BTD',
  },
  {
    ghModel: 'XG-3000',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'C-24',
  },
  {
    ghModel: 'XG-7500',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'CG',
  },
  {
    ghModel: 'XG-7550',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'CG',
  },
  {
    ghModel: 'XG-DGCO',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'CHDL',
  },
  {
    ghModel: 'XG-DGSF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'CHDL-1F',
  },
  {
    ghModel: 'XG-DGDF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'CHDL-2F',
  },
  {
    ghModel: 'XG-DGDFSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'CHDL-2F-SS',
  },
  {
    ghModel: 'XG-R5750',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'CM-1P',
  },
  {
    ghModel: 'XG5500',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'D',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'D',
  },
  {
    ghModel: 'XG-JA',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'DJ',
  },
  {
    ghModel: 'XG-DGLP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'DRDL',
  },
  {
    ghModel: 'XG-5500SS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'D-SS',
  },
  {
    ghModel: 'XG-4002PCF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ECO10 AL',
  },
  {
    ghModel: 'XG-4004PCF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ECO20 AL',
  },
  {
    ghModel: 'XG-4000PCFPFR',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ECO-P AL',
  },
  {
    ghModel: 'XG-G3',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ED',
  },
  {
    ghModel: 'XG-L9',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ED',
  },
  {
    ghModel: 'XG-RL-SP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'EJ  ',
  },
  {
    ghModel: 'XG-RL-SP-DF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'EJ-D',
  },
  {
    ghModel: 'XG-RPF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'F3P AL',
  },
  {
    ghModel: 'XG-SRPF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'F3P ST',
  },
  {
    ghModel: 'XG-RPFSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'F3P-SS',
  },
  {
    ghModel: 'XG-PHCS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FD',
  },
  {
    ghModel: 'XG-PHCR-DB',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FDB',
  },
  {
    ghModel: 'XG-RHEF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FE30 AL',
  },
  {
    ghModel: 'XG-RHF ',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FE30 AL',
  },
  {
    ghModel: 'XG-SRHF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FE30 ST',
  },
  {
    ghModel: 'XG-RHFSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FE30-SS',
  },
  {
    ghModel: 'XG-AFL-10',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FF/PJ-100',
  },
  {
    ghModel: 'XG-AFL-15',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FF/PJ-150',
  },
  {
    ghModel: 'XG-AFL-20',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FF/PJ-200',
  },
  {
    ghModel: 'XG-AFL-25',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FF/PJ-250',
  },
  {
    ghModel: 'XG-AFL-30',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FF/PJ-300',
  },
  {
    ghModel: 'XG-AFLC-10',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FFCM/PJCM-100',
  },
  {
    ghModel: 'XG-AFLC-15',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FFCM/PJCM-150',
  },
  {
    ghModel: 'XG-AFLC-20',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FFCM/PJCM-200',
  },
  {
    ghModel: 'XG-AFLC-25',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FFCM/PJCM-250',
  },
  {
    ghModel: 'XG-AFLC-30',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FFCM/PJMC-300',
  },
  {
    ghModel: 'XG-CC5F',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FGC5',
  },
  {
    ghModel: 'XG-CC5FSS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FGC5-SS',
  },
  {
    ghModel: 'XG-PHCR',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'FSR',
  },
  {
    ghModel: 'XG-CC5',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'GC5',
  },
  {
    ghModel: 'XG-CC15',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'GC51',
  },
  {
    ghModel: 'XG-CC5SS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'GC5-SS',
  },
  {
    ghModel: 'XG-CC45',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'GS5NS',
  },
  {
    ghModel: 'XG-HRD-CL',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HCR',
  },
  {
    ghModel: 'XG-HRD-GS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HCRF',
  },
  {
    ghModel: 'XG-4502S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HD15',
  },
  {
    ghModel: 'XG-4504S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HD25',
  },
  {
    ghModel: 'XG-4500S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HD35 0  degree',
  },
  {
    ghModel: 'XG-4538S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HD35 40 degree',
  },
  {
    ghModel: 'XG-SGRH',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HD35-MS',
  },
  {
    ghModel: 'XG-MRDD',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HLO',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HPX',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'HPX',
  },
  {
    ghModel: 'XG-RL',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'IJ',
  },
  {
    ghModel: 'XG-RL-DF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'IJ-D',
  },
  {
    ghModel: 'XG-HPL-CA',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'MV-3',
  },
  {
    ghModel: 'XG-HPL-GSJ',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'MV-HEP',
  },
  {
    ghModel: 'XG-OBD',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'OB-1',
  },
  {
    ghModel: 'XG-OBDA',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'OB-1 AL',
  },
  {
    ghModel: 'XG-7600',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'PAC',
  },
  {
    ghModel: 'XG-7650',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'PAC',
  },
  {
    ghModel: 'XG-5750',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'PG Paragon',
  },
  {
    ghModel: 'XG-5750RP',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'PGR',
  },
  {
    ghModel: 'XG-5750T',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'PHV',
  },
  {
    ghModel: 'XG-MSE-HC-S',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'PvV-D',
  },
  {
    ghModel: 'XG-MSE-HC-M',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'PvV-HC',
  },
  {
    ghModel: 'XG-9000',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'QC',
  },
  {
    ghModel: 'XG-7900',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'QCP',
  },
  {
    ghModel: 'XG-7950',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'QCP',
  },
  {
    ghModel: 'XG-6600SQR',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'QL-R',
  },
  {
    ghModel: 'XG-6600SQ',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'QL-S',
  },
  {
    ghModel: 'XG-PF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'RC',
  },
  {
    ghModel: 'XG-NDRH',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'RSA',
  },
  {
    ghModel: 'XG-PHPS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SCD ',
  },
  {
    ghModel: 'XG-PHPSI',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SCD-Insulated',
  },
  {
    ghModel: 'XG-PHPR ',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SCR',
  },
  {
    ghModel: 'XG-PHPRI',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SCR-Insulated',
  },
  {
    ghModel: 'XG-SG2000',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SGAL',
  },
  {
    ghModel: 'XG-SG5500',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SGSDF',
  },
  {
    ghModel: 'XG-6610',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAD-100',
  },
  {
    ghModel: 'XG-6615',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAD-150',
  },
  {
    ghModel: 'XG-6650',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAD-50',
  },
  {
    ghModel: 'XG-6675',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAD-75',
  },
  {
    ghModel: 'XG-6610R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAR-100',
  },
  {
    ghModel: 'XG-6615R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAR-150',
  },
  {
    ghModel: 'XG-6650R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAR-50',
  },
  {
    ghModel: 'XG-6675R',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLAR-75',
  },
  {
    ghModel: 'XG-PHSP-J',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLSD',
  },
  {
    ghModel: 'XG-PHSPI-J',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SLSD-Insulated',
  },
  {
    ghModel: 'XG-MPK',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SPT',
  },
  {
    ghModel: 'XG-TR',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SRA',
  },
  {
    ghModel: 'XG-TRDeep',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'SRA',
  },
  {
    ghModel: 'XG-6675RTZ',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TAZR-R-75',
  },
  {
    ghModel: 'XG-6675TZ',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TAZR-S-75',
  },
  {
    ghModel: 'XG-STBPF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TBF',
  },
  {
    ghModel: 'XG-TBPF',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TBF',
  },
  {
    ghModel: 'XG-SS-LT',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TDEB',
  },
  {
    ghModel: 'XG-2400',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TL Border 7',
  },
  {
    ghModel: 'XG-2600',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TL Border 7',
  },
  {
    ghModel: 'XG-2300',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TL Border 9',
  },
  {
    ghModel: 'XG-2500',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TL Border 9',
  },
  {
    ghModel: 'XG-2700',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TL Border FG',
  },
  {
    ghModel: 'XG-2800',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TL Border FG',
  },
  {
    ghModel: 'XG-5500DD',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'TM',
  },
  {
    ghModel: 'XG-BDS',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'VD',
  },
  {
    ghModel: 'XG-H4254',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'XXWH',
  },
  {
    ghModel: 'XG-V4254',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'XXWV',
  },
  {
    ghModel: 'XG-5700',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ZPL',
  },
  {
    ghModel: 'XG-5700A',
    trademark: ['Greenheck'],
    compName: 'Anemostat',
    compModel: 'ZPLA',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'DFC',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'DFC',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'DFC',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'DFL',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'DFL',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'DFL',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'DFL',
  },
  {
    ghModel: 'MSX ',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'HECD',
  },
  {
    ghModel: 'TSF',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'HECD',
    filter: {
      category: 'MakeUpAir'
    }
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'IFL',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'IFL',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'IFL',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'IFR',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'IFR',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'IFR',
  },
  {
    ghModel: 'MSX ',
    trademark: ['Greenheck'],
    compName: 'AppliedAir',
    compModel: 'LECS',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E2DS',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E2WV',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4DP',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4DS',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4JS',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4WS',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E6DP',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'EX',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'FL',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'ArchitecturalLouvers',
    compModel: 'V4JS',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E2DS',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E2WV',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4DP',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4DS',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4JS',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4WS',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E6DP',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'EX',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'FL',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck'],
    compName: 'ArchitecturalLouvers',
    compModel: 'V4JS',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E2DS',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4DP',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E4WS',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'ArchitecturalLouvers',
    compModel: 'E6DP',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: '377',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: '377',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'AC-482-12-6',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'AC-482-6-6',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'AC-482-8-8',
  },
  {
    ghModel: 'AFG501',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'ALUF5',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'BVF',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'C-33/Y-27',
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-210-D',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-215-D',
  },
  {
    ghModel: 'K6744X',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-22',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-32',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-32/EA-64',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-331-VSL',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-400',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-405',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-415-D',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-425-DD',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-444',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-444-70',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-450-D',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-475-DH',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-480-D',
  },
  {
    ghModel: 'K8204E',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-480-DM',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-52',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-520-D',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-521-V',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-522D',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-522D',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-605',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-615-D',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-625-DD',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-640-DR',
  },
  {
    ghModel: 'K6746MD',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-64C',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-651-D',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-662-HSL',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-680-D',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-680-DAF',
  },
  {
    ghModel: 'K8206E',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-681-M',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-725-D',
  },
  {
    ghModel: 'SCC550',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-731-D',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-30',
  },
  {
    ghModel: 'AC153S',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-30',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-42',
  },
  {
    ghModel: 'AC154S',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-42',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'EAV-66',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'ESH-4',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'FS271',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'FS-400',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'FS-400-6',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'FS406D',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'FS446D',
  },
  {
    ghModel: 'ASL401',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'FS461',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'FS606D',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'ArrowUnited',
    compModel: 'Y-45',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'AC-482-6-6',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'AC-482-8-8',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'ALUF5',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-215-D',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-22',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-32',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-32',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-331-VSL',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-415-D',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-450-D',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-480-D',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-52',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-64',
  },
  {
    ghModel: 'ESD-635D',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-64C',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-651-D',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-662-HSL',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-680-DAF',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-725-D',
  },
  {
    ghModel: 'EHV-550',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-731-D',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-30',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-30',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-42',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'EA-TL-42',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'ESH-4',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'FS606D',
  },
  {
    ghModel: 'PEV-400',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'SEP-45-S',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'ArrowUnited',
    compModel: 'SPH20F',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '56',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '70',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '80',
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '85',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '182',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '182',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '182',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '182',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '300',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '303',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '304',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '317',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '335',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '377',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '377',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '388',
  },
  {
    ghModel: 'SSWDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '390',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '395',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '395',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '400',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '421',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '422',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '423',
  },
  {
    ghModel: 'BR-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '500',
  },
  {
    ghModel: 'BR-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '502',
  },
  {
    ghModel: 'BR-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '502',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '530',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '530',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '531',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '540',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '655',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '850',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '900',
  },
  {
    ghModel: 'HB-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '900',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '1770',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '1770',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117A',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117B',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117C',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117F',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XA',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XA',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XA',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XB',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XB',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XB',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XC',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XC',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '117XC',
  },
  {
    ghModel: 'MBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '150VCD',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '150VCD',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '200VCRD',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '250SRD',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '317A',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '317B',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '317C',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '580-R',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: '581-R',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A1',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A1(SS)',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A1H250',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A1H250SS',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A1Y 250',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A1Y 350',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A2',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A2H250',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A2Y 250',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A2Y 350',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A89',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A91',
  },
  {
    ghModel: 'CRD-1LP',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A93',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A95',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'A97',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AA1',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AA1 350',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AA1H250',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AA2',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AA2 350',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AA2H250',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AC-482-12-6',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'ADD',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'ADG',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AFD-20',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AFDT125LT',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AFDTI-25',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AR1',
  },
  {
    ghModel: 'FSD-211M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AR1',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AR1H250',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AR2',
  },
  {
    ghModel: 'FSD-212M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AR2',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AR2H250',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1 350',
  },
  {
    ghModel: 'SSFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1(SS)',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1G/F',
  },
  {
    ghModel: 'CFSD-211-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1G/F 250/350',
  },
  {
    ghModel: 'CFSD-211-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1G/F 250/350',
  },
  {
    ghModel: 'CFSD-211-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1G/F 250/350',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1H250',
  },
  {
    ghModel: 'SSFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1H250SS',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS1VF250',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2 350',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2G/F',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2G/F 250/350',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2G/F 250/350',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2G/F 250/350',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2H250',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AS2VF250',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'ASC2C',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'ASC2C250',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AT1',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AT1 350',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'AT1H250',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'BVF',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'C-33',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'CR',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17B',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17C',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XA',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XA',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XA',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XB',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XB',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XB',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XC',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XC',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D17XC',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D37',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D37B',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'D37C',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-150',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-210-D',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-331-VSL',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-400',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-405',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-425-DD',
  },
  {
    ghModel: 'EDK-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-430',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-444',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-444-70',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-475-DH',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-520-D',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-521-V',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-522-D',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-605',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-615-D',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-625-DD',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-633',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-640-DR',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-680-D',
  },
  {
    ghModel: 'EACC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-681-M',
  },
  {
    ghModel: 'EHV-550',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-731-D',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'EAV-66',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FR',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FS271',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FS400',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FS400-6',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FS406D',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FS446D',
  },
  {
    ghModel: 'FSL-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FS461',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'FS615-D',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'ID-200',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'MA17',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'MD17',
  },
  {
    ghModel: 'DFD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'MD37',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'OBDAF-207',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'PBDAF-206',
  },
  {
    ghModel: 'ESD-635PD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'PH-64',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA1',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA1 350',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA1G/F',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA1H250',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA1VG250',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA2',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA2 350',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA2G/F',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA2H250',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UA2VS250',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UR1',
  },
  {
    ghModel: 'SMD-201M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UR1',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UR1H250',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UR2',
  },
  {
    ghModel: 'SMD-202M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UR2',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'UR2H250',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'US',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'VA1',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'VA1H250',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'Y-27',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ArrowUnited',
    compModel: 'Y-45',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-215-D',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-415-D',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-450-D',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-480-D',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-605',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-651-D',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'ArrowUnited',
    compModel: 'EA-680-DAF',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BigAssFans',
    compModel: 'Basic 6',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'BigAssFans',
    compModel: 'Essence',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'BigAssFans',
    compModel: 'Isis',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BigAssFans',
    compModel: 'Powerfoil 8',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BigAssFans',
    compModel: 'Powerfoil D',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BigAssFans',
    compModel: 'Powerfoil X3.0',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BigAssFans',
    compModel: 'Basic 6',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'BigAssFans',
    compModel: 'Essence',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'BigAssFans',
    compModel: 'Isis',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BigAssFans',
    compModel: 'Powerfoil 8',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BigAssFans',
    compModel: 'Powerfoil D',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BigAssFans',
    compModel: 'Powerfoil X3.0',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BlueGiant',
    compModel: 'Eagle VI',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BlueGiant',
    compModel: 'Falcon III',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BlueGiant',
    compModel: 'Hawk V',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BlueGiant',
    compModel: 'Swift III',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'BlueGiant',
    compModel: 'Swift VI',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'BlueGiant',
    compModel: 'Swift VIII',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BlueGiant',
    compModel: 'Eagle VI',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BlueGiant',
    compModel: 'Falcon III',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BlueGiant',
    compModel: 'Hawk V',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BlueGiant',
    compModel: 'Swift III',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'BlueGiant',
    compModel: 'Swift VI',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'BlueGiant',
    compModel: 'Swift VIII',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'CambridgeEngineering',
    compModel: 'M',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'CambridgeEngineering',
    compModel: 'M',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'CambridgeEngineering',
    compModel: 'M',
  },
  {
    ghModel: 'Greenheat',
    trademark: ['Greenheck'],
    compName: 'CambridgeEngineering',
    compModel: 'S',
  },
  {
    ghModel: 'KSF',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'A',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'AD',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'AD',
  },
  {
    ghModel: 'MSX ',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'AE',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'AI',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'AI',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'AI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'BD',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'BD-100',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'BD-BR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'BD-BR-100',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'BI-CA ',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'BI-CARM ',
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'C200',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CA-HVLS',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CB-BIRM ',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CBD',
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CEPRSM',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SP-A',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CFA-CA ',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CFRD',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CFRP',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CND',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CND',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CNDI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CNFR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CNFR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CNFRI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CPR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CPR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CPRI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CSND',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'CSND',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'D76',
  },
  {
    ghModel: 'AE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'DDAR-FA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'DD-FA ',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'AS',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'DMUA-FA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'DR-HFA ',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'DU-HFA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GRS',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'EMUA-FA',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'FRD',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'FRD',
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'G',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'HRE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'IBT',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'IBT',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'IBT',
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'INLINE',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SQB',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ISQB',
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ISQD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NCA-FA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NCA-HPFA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-100',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-BB',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-BR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-BR-100',
  },
  {
    ghModel: 'NONE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-BR-PSP',
  },
  {
    ghModel: 'NONE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-BR-PSP-100',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NDI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-PSP',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'ND-PSP-100',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NFR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NFR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NFRI',
  },
  {
    ghModel: 'KSF',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NSA',
  },
  {
    ghModel: 'KSFB',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NSA',
  },
  {
    ghModel: 'KSFD',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NSA',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'NSA',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'PR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'PR',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'PRI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'R',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'R',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'RI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SCA',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SCA',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SCA-FP',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SCA-FP',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SCA-FP',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SCA-FP',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SND',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SND',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SWI',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'SWI',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'USI',
  },
  {
    ghModel: 'HOODTYPE2',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'V1',
  },
  {
    ghModel: 'HOODTYPE2',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'VHC',
  },
  {
    ghModel: 'HOODTYPE2',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'VHC',
  },
  {
    ghModel: 'HOODTYPE2',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'VHC',
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'WPC-CA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'WPH-CA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'CaptiveAire',
    compModel: 'WPK-CA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'CaptiveAire',
    compModel: 'CA-HVLS',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FFAC',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FFJC',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FFKC',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FFPC',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FFWC',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FJAC',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FJJC',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FJKC',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FJPC',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FJWC',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FKDC',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FKDC',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FKGC',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FLDC',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FLDC',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FLGC',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPAB',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPBB',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPCB',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPEB',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPFB',
  },
  {
    ghModel: 'K8506',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPGB',
  },
  {
    ghModel: 'K8504',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPJB',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPMB',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPNB',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPPB',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FPQB',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FRVB',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FRWC',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FRWC',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FSVB',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FSWC',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FSWC',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTAB',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTBB',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTCB',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTEB',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTFB',
  },
  {
    ghModel: 'K8506',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTGB',
  },
  {
    ghModel: 'K8504',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTJB',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTMB',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTNB',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTPB',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FTQB',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FVBB',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FVCB',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FWBB',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FWCB',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FYKB',
  },
  {
    ghModel: 'K601D',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FYYB',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FZKB',
  },
  {
    ghModel: 'K601D',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'FZYB',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'VXCCB 1.5"',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'Carnes',
    compModel: 'VXCCB 4"',
  },
  {
    ghModel: 'XG-6610',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Channelaire CH',
  },
  {
    ghModel: 'XG-6650',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Channelaire CH',
  },
  {
    ghModel: 'XG-6675',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Channelaire CH',
  },
  {
    ghModel: 'XG-6610R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Channelaire CR',
  },
  {
    ghModel: 'XG-6650R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Channelaire CR',
  },
  {
    ghModel: 'XG-6675R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Channelaire CR',
  },
  {
    ghModel: 'XG-Periflow',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'CROR',
  },
  {
    ghModel: 'XG-2000',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Curtainaire',
  },
  {
    ghModel: 'XG-2100',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Curtainaire',
  },
  {
    ghModel: 'XG-2200',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Curtainaire',
  },
  {
    ghModel: 'XG-UP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'CXPC',
  },
  {
    ghModel: 'XG-UPI',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'CXPC w/ Insulation',
  },
  {
    ghModel: 'XG-PHPR ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DAHC-N',
  },
  {
    ghModel: 'XG-PHPRI',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DAHC-R',
  },
  {
    ghModel: 'XG-PHPS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DASD-N',
  },
  {
    ghModel: 'XG-PHPSI',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DASD-R',
  },
  {
    ghModel: 'XG-PHPS-IT',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DASF/DARF-N',
  },
  {
    ghModel: 'XG-PHPSI-IT',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DASF/DARF-R',
  },
  {
    ghModel: 'XG-HPL-GSJ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DBAC',
  },
  {
    ghModel: 'XG-PHCS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DFCA',
  },
  {
    ghModel: 'XG-PHCR',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DFDA',
  },
  {
    ghModel: 'XG-PHCR-DB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DFDA-D',
  },
  {
    ghModel: 'XG-PHSP-J',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DFSB-N',
  },
  {
    ghModel: 'XG-PHSPI-J',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DFSB-R',
  },
  {
    ghModel: 'XG-HPL-CA',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DLAC',
  },
  {
    ghModel: 'XG-HRD-CL',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'DRAC',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FFAC',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FFJC',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FFKC',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FFPC',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FFWC',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FJAC',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FJJC',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FJKC',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FJPC',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FJWC',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FKDC',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FKDC',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FKGC',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FLDC',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FLDC',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FLGC',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPAB',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPBB',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPCB',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPEB',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPFB',
  },
  {
    ghModel: 'ECD-601',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPGB',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPJB',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPMB',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPNB',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPPB',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FPQB',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FRVB',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FRWC',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FRWC',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FSVB',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FSWC',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FSWC',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTAB',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTBB',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTCB',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTEB',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTFB',
  },
  {
    ghModel: 'ECD-601',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTGB',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTJB',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTMB',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTNB',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTPB',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FTQB',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FVBB',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FVCB',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FWBB',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FWCB',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FYKB',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FYYB',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FZKB',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'FZYB',
  },
  {
    ghModel: 'FHR',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'GE',
  },
  {
    ghModel: 'FHI',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'GI',
  },
  {
    ghModel: 'WH',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'GL',
  },
  {
    ghModel: 'GRS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'GS',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'GRS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'GSAA',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Industrial Airflow',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Industrial Airflow',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Industrial Airflow',
  },
  {
    ghModel: 'XG-L9',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'KXEAS',
  },
  {
    ghModel: 'XG-STBPF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'KXFAS/KXFAA',
  },
  {
    ghModel: 'XG-TBPF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'KXFAS/KXFAA',
  },
  {
    ghModel: 'XG-BDS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'KXMC',
  },
  {
    ghModel: 'XG-RSD',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'KXNA',
  },
  {
    ghModel: 'XG-G3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'KXYA',
  },
  {
    ghModel: 'AS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LESA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LFBA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LFDA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LGBA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LGBA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RE2',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LGDA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RS2',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LGDA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LIBA',
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LIDA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SBE-Level 3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LJDB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS-Level 3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LJDB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE-Level 3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LKDB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS-Level 3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LKDB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LMBK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LMBK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LNBA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LNBA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LNDA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LRBA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LRBA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCE3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LRDA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LTBA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LTBA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RE2',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LTDA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RS2',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LTDA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LUBA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RDU',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LUDA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LUKA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LWBK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LWBK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LYDK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LYDK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LZDK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'LZDK',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'PFD',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'PFD',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'PFD',
  },
  {
    ghModel: 'XG-RHE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAAM',
  },
  {
    ghModel: 'XG-RH',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAAM ',
  },
  {
    ghModel: 'XG-RHEF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAAM-Filter',
  },
  {
    ghModel: 'XG-RHF ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAAM-Filter',
  },
  {
    ghModel: 'XG-LS4',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RACAF',
  },
  {
    ghModel: 'XG-L',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RACAH',
  },
  {
    ghModel: 'XG-LTC',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RACAL/AR',
  },
  {
    ghModel: 'XG-ST',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RACAM',
  },
  {
    ghModel: 'XG-LT',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RACAN',
  },
  {
    ghModel: 'XG-LS3',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RACAT',
  },
  {
    ghModel: 'XG-S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RACAV',
  },
  {
    ghModel: 'XG-H4004',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RADMH',
  },
  {
    ghModel: 'XG-V4004',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RADMV',
  },
  {
    ghModel: 'XG-CC1',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAEA',
  },
  {
    ghModel: 'XG-RP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAFM',
  },
  {
    ghModel: 'XG-RPF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAFM-Filter',
  },
  {
    ghModel: 'XG-CC45',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAKA',
  },
  {
    ghModel: 'XG-CC5',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAPA',
  },
  {
    ghModel: 'XG-CC5F',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAPMF',
  },
  {
    ghModel: 'XG-H4002R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RARM',
  },
  {
    ghModel: 'XG-V4002R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RARM',
  },
  {
    ghModel: 'XG-H4002',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RASMH',
  },
  {
    ghModel: 'XG-V4002',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RASMV',
  },
  {
    ghModel: 'XG-CC15',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RATA',
  },
  {
    ghModel: 'XG-RL',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAWA',
  },
  {
    ghModel: 'XG-RL-DF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RAWA-S',
  },
  {
    ghModel: 'XG-H4252',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RBFA',
  },
  {
    ghModel: 'XG-V4252',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RBFA',
  },
  {
    ghModel: 'XG-H4254',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RBGA',
  },
  {
    ghModel: 'XG-V4254',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RBGA',
  },
  {
    ghModel: 'XG-H4352',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RCKA',
  },
  {
    ghModel: 'XG-V4352',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RCKA',
  },
  {
    ghModel: 'XG-H4354',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RCLA',
  },
  {
    ghModel: 'XG-V4354',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RCLA',
  },
  {
    ghModel: 'XG-6610SP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RDCM',
  },
  {
    ghModel: 'XG-4004PCF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RDDM',
  },
  {
    ghModel: 'XG-4000PCFPFR',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RDPM',
  },
  {
    ghModel: 'XG-4000PCFPFS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RDPM AS',
  },
  {
    ghModel: 'XG-4002PCF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RDSM',
  },
  {
    ghModel: 'XG-DGDF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RGCAD',
  },
  {
    ghModel: 'XG-DGCO',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RGCAH',
  },
  {
    ghModel: 'XG-DGSF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RGCAS',
  },
  {
    ghModel: 'XG-RHFSS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLAB-Filter',
  },
  {
    ghModel: 'XG-RHSS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLABH',
  },
  {
    ghModel: 'XG-H4004SS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLDBH',
  },
  {
    ghModel: 'XG-V4004SS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLDBV',
  },
  {
    ghModel: 'XG-RPSS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLFA',
  },
  {
    ghModel: 'XG-CC5SS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLPAF',
  },
  {
    ghModel: 'XG-H4002RSS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLRBH/V',
  },
  {
    ghModel: 'XG-V4002RSS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLRBH/V',
  },
  {
    ghModel: 'XG-H4002SS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLSBH',
  },
  {
    ghModel: 'XG-V4002SS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RLSBV',
  },
  {
    ghModel: 'XG-SRH',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSAB',
  },
  {
    ghModel: 'XG-SRHF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSAB-Filter',
  },
  {
    ghModel: 'XG-H4004S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSDBH',
  },
  {
    ghModel: 'XG-V4004S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSDBV',
  },
  {
    ghModel: 'XG-4500S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSEA',
  },
  {
    ghModel: 'XG-SRP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSFA',
  },
  {
    ghModel: 'XG-SRPF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSFA-Filter',
  },
  {
    ghModel: 'XG-4538S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSHA',
  },
  {
    ghModel: 'XG-SGLF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSPA06',
  },
  {
    ghModel: 'XG-SGDB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSPA09',
  },
  {
    ghModel: 'XG-SGSP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSPA17',
  },
  {
    ghModel: 'XG-SG2000',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSPA29',
  },
  {
    ghModel: 'XG-SGRH',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSPA29',
  },
  {
    ghModel: 'XG-SGRP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSPA51',
  },
  {
    ghModel: 'XG-H4002RS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSRB',
  },
  {
    ghModel: 'XG-V4002RS',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSRB',
  },
  {
    ghModel: 'XG-H4002S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSSBH',
  },
  {
    ghModel: 'XG-V4002S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RSSBV',
  },
  {
    ghModel: 'XG-OBD',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RXEA',
  },
  {
    ghModel: 'XG-OBDA',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RXFC',
  },
  {
    ghModel: 'XG-PF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'RXSA',
  },
  {
    ghModel: 'XG5500',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SE ',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SE w/Option V',
  },
  {
    ghModel: 'XG-5750T',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SFHA',
  },
  {
    ghModel: 'XG-5750',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SFPA',
  },
  {
    ghModel: 'XG-MSCVH',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SFPV/SHPV',
  },
  {
    ghModel: 'XG-5800',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SFTB',
  },
  {
    ghModel: 'XG-6600SQR',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SJRM',
  },
  {
    ghModel: 'XG-6600SQ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SJSM',
  },
  {
    ghModel: 'XG-5800A',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SJTB',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SK ',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SK w/Option V',
  },
  {
    ghModel: 'XG-5500DD',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SKPB',
  },
  {
    ghModel: 'XG-PRTB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SLHB',
  },
  {
    ghModel: 'XG-9000',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SM',
  },
  {
    ghModel: 'XG-7500',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPAB',
  },
  {
    ghModel: 'XG-7550',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPDB',
  },
  {
    ghModel: 'XG-7600',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPFC',
  },
  {
    ghModel: 'XG-7650',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPGC',
  },
  {
    ghModel: 'XG-SPRTB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPHB',
  },
  {
    ghModel: 'XG-7550R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPJB',
  },
  {
    ghModel: 'XG-7650R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPJB',
  },
  {
    ghModel: 'XG-7900',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPMB',
  },
  {
    ghModel: 'XG-7950',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPMB',
  },
  {
    ghModel: 'XG-7500R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPRB',
  },
  {
    ghModel: 'XG-7600R',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SPRB',
  },
  {
    ghModel: 'XG-3200',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SSDB',
  },
  {
    ghModel: 'XG-3100S',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SSEA',
  },
  {
    ghModel: 'XG-3100',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SSFA',
  },
  {
    ghModel: 'XG-3100S-A',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SSMA',
  },
  {
    ghModel: 'XG-3100A',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SSNA',
  },
  {
    ghModel: 'XG-SG5500',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SSPA',
  },
  {
    ghModel: 'XG-R5750',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SSRA',
  },
  {
    ghModel: 'XG-TR',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SXTA',
  },
  {
    ghModel: 'XG-TRDeep',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'SXTA',
  },
  {
    ghModel: 'XG-6675RTZ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Techzone DT',
  },
  {
    ghModel: 'XG-6675TZ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Techzone DT',
  },
  {
    ghModel: 'XG-2300',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Trimaire',
  },
  {
    ghModel: 'XG-2400',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Trimaire',
  },
  {
    ghModel: 'XG-2500',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Trimaire',
  },
  {
    ghModel: 'XG-2600',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Trimaire',
  },
  {
    ghModel: 'XG-2700',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Trimaire',
  },
  {
    ghModel: 'XG-2800',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Trimaire',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Type B Centrifugal',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VBBA',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VBBA',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VBBB',
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VCDB',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VCDB',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VCDC',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VDBA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VEBK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VEDK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SFB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VFBA',
  },
  {
    ghModel: 'RSFP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VHBB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'BSQ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VIBK',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VIDK',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'LBP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VLBK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LDP',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VLDK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VRBK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RSF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VSBB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VTBK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LD',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VTDK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VUBK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VUDK',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VWBK',
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VWDK',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VXCCB 1.5',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'VXCCB 4',
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'WEHC',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'WEHC',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIC',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'WEHC',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'XG-AFL-10',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFL-15',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFL-20',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFL-25',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFL-30',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFLC-10',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFLC-15',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFLC-20',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFLC-25',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'XG-AFLC-30',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'Widebody CD/CE',
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'WPBC',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'WPDC',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIC',
    trademark: ['Greenheck'],
    compName: 'Carnes',
    compModel: 'WPDC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'Carrier',
    compModel: '39L',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'Carrier',
    compModel: '42BH',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'Carrier',
    compModel: '42BV',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'A3S8',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'ACA-482-12-6',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'ACA-482-6-6',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'ACA-482-8-8',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'CATL30',
  },
  {
    ghModel: 'AC153S',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'CATL30',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'CATL42',
  },
  {
    ghModel: 'AC154S',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'CATL42',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'CEA662HSL',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'G400',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'G4D4',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'G4D4A',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'G6D4',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'G6D4A',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'G6K4',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'LVS1',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'LVS2',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X2D4',
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X2HW',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X2YH',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4D3',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4D4A',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4DD',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4DDC',
  },
  {
    ghModel: 'K8204E',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4DDCM',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4J4',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4J4A',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4JE',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4JI',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4K4',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4SH',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4VH',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X4YH',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X5DD',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X5SD',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X5SD',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6D3',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6D3',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6D3A',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6D3C',
  },
  {
    ghModel: 'K8206E',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6D4CM',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6D5',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6DAF',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6DD',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6K4',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X6K9A',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'Cesco',
    compModel: 'X7C2',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: ' X6D3',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'A3S8',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'ACA-482-12-6',
    filter: {
      category: 'Specialty'
    }
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'ACA-482-6-6',
    filter: {
      category: 'Specialty'
    }
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'ACA-482-8-8',
    filter: {
      category: 'Specialty'
    }
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'CATL30',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'CATL30',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'CATL42',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'CATL42',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'CEA662HSL',
  },
  {
    ghModel: 'PEV-400',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'CSEP45S',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'G400',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'G4D4',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'G4D4A',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'G6D4',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'G6D4A',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'G6K4',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'LVS1',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'LVS2',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'P4K3M',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X2D4',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X2HW',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X2YH',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4D3',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4D4A',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4DD',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4DDC',
  },
  {
    ghModel: 'EACC-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4DDCM',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4J4',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4J4A',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4JE',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4JI',
  },
  {
    ghModel: 'EDK-430',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4K3',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4K4',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4SH',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4VH',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X4YH',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X5DD',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X5SD',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6D3A',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6D3C',
  },
  {
    ghModel: 'EACC-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6D4CM',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6D5',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6DAF',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6DD',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6K4',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X6K9A',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck'],
    compName: 'Cesco',
    compModel: 'X7C2',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: ' X6D3',
  },
  {
    ghModel: 'VLPI',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'P4K3M',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'X2D4',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'X4D3',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'X4D4A',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'X4DDC',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'X5DD',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'X6D3A',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'Cesco',
    compModel: 'X6DAF',
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '37',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '37',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '47',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '59',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '70',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '5120',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '5120',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '10A',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '10A',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '16A-AM',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '16A-LS',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '16A-WF',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '44SQA',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51 RA',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: '51 RA',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BCSW-FRP',
    trademark: ['Greenheck'],
    compName: 'Chicago',
    compModel: 'BI_FRP',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Combat',
    compModel: 'DF',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Combat',
    compModel: 'DF',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Combat',
    compModel: 'DF',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'Combat',
    compModel: 'DF',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Combat',
    compModel: 'IDF',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Combat',
    compModel: 'IDF',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Combat',
    compModel: 'IDF',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: '2252',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: '4830',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: '6830',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: '6917',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: '1302G',
  },
  {
    ghModel: 'T9612',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A-12350',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A12360',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A2097',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4080',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4097',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4100',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4105',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4180',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6080',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6085',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6100',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6105',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6155',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A-6370',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A8370',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'A-8890',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'B5157',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'B5157',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'BVF',
  },
  {
    ghModel: 'K6744X',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-4174',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-6174',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-6804',
  },
  {
    ghModel: 'SCC901MD',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-9614',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCH-5704',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCV-5704',
  },
  {
    ghModel: 'AFG501',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'EW-5210',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-407',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-410',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-607',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-610',
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-2300',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-4700',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-7705',
  },
  {
    ghModel: 'SCC901',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-9615',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'RSH-5700',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'ConstructionSpecialties',
    compModel: 'RSV-5700',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: '2252',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: '2272',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: '6118',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: '6118',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: '6830',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: '6917',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'A2097',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4100',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4105',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4180',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6100',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6105',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6177',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-4174',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-6804',
  },
  {
    ghModel: 'EHV-901D',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-9614',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'EW-5210',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'RSH-5700',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck'],
    compName: 'ConstructionSpecialties',
    compModel: 'RSV-5700',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1242',
  },
  {
    ghModel: 'ESU-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1242',
  },
  {
    ghModel: 'ESU-150S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1242',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1242',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1242',
  },
  {
    ghModel: 'ESU-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1302',
  },
  {
    ghModel: 'ESU-130S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1302',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1302',
  },
  {
    ghModel: 'ESF-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1321',
  },
  {
    ghModel: 'ESF-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1322',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '2282',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '4157',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '4830',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '6157',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '6850',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '6870',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '6917',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '1302G',
  },
  {
    ghModel: 'AFS-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A12350',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A12360',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A12370',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4080',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4097',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4110',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4115',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4910',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4920',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6080 ',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6085',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6097',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6155',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6370',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A8370',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A8890',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'B5157',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'BVF',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-5304',
  },
  {
    ghModel: 'EHH-601DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-5304',
  },
  {
    ghModel: 'ESD-635D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-6174',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-6174',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-6174',
  },
  {
    ghModel: 'ESD-603D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC7034',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC7034',
  },
  {
    ghModel: 'ESD-603D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC7044',
  },
  {
    ghModel: 'EHV-901D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DC-9615',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCH-5704',
  },
  {
    ghModel: 'ESS-502D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCH-5704',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCV-5704',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCV-5704',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-407',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-410',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-602',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-607',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'GS-610',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-2300',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-4700',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-7315',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-7705',
  },
  {
    ghModel: 'EHV-901',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'RS-9615',
  },
  {
    ghModel: 'EES-T200V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'VC-500M',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '2252',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: '6917',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A2097',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4097',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4100',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A4105',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6100',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6105',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'A6177',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCH-5704',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'DCV-5704',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'RSH-5700',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'ConstructionSpecialties',
    compModel: 'RSV-5700',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'A-DBE-06',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'A-DWE-04',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'A-DWF-04',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'A-DWF-06',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'AEN-04',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'AEO-04',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'A-LEB-04',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'A-LEB-06',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'BEX-04',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'C-DWE-44',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'C-DWE-46',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'C-DWE-AF',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'DBE-04',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'D-DBE-04',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'D-DBE-06',
  },
  {
    ghModel: ' K605D',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'DEM-06',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'DEM-06',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'DWE-04',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'DWE-06',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'DWF-04',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'DWF-06',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'HDL-06',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'IEC-04',
  },
  {
    ghModel: 'AC155',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LEC-01',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LEC-04',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LEC-06',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LED-04',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LES-01',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LEY-03',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LEY-04',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LFC-04',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'LFC-06',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'UEB-06',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'UFA-08',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'UFC-08',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'UFC-12',
  },
  {
    ghModel: 'T9612',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'UFD-12',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'WDL-04',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'Dowco',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'Dowco',
    compModel: 'A-DBE',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Dowco',
    compModel: 'A-DBE-06',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Dowco',
    compModel: 'DBE-06',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Dowco',
    compModel: 'LEC-04',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Dowco',
    compModel: 'LEC-06',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck'],
    compName: 'Dowco',
    compModel: 'V-DEM-05',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck'],
    compName: 'Dowco',
    compModel: 'WDL-04',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'A-DWE',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'A-DWE-04',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'A-DWF-04',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'A-DWF-06',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'AEN',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'AEN-04',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'AEO',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'AEO-04',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'A-LEB',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'A-LEB-04',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'A-LEB-06',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'BEX-04',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'C-DWE-44',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'C-DWE-46',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'C-DWE-46',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'C-DWE-AF',
  },
  {
    ghModel: 'ESID-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'C-LEB',
  },
  {
    ghModel: 'ESID-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'C-LEB-04',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DBE-04',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'D-DBE-04',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'D-DBE-04',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'D-DBE-06',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'D-DBE-06',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DEM-05',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DEM-06',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DWE-04',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DWE-04',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DWE-06',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DWE-06',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DWF-04',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'DWF-06',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'HDL-06',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'IEC-04',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEB-04',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEB-04',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEC-01',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEC-04',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEC-06',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LED-04',
  },
  {
    ghModel: 'EDK-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEH-04',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LES-01',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEY-03',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LEY-04',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LFC-04',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'LFC-06',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'M-Series',
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'M-Series',
  },
  {
    ghModel: 'PEV-400',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'P-LEC-04-XDOT-D',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'P-Series',
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'P-Series',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'UEB-06',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'UFA-08',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'UFC-08',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'UFC-12',
  },
  {
    ghModel: 'AFS-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'UFD-12',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Dowco',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'Dowco',
    compModel: 'A-DBE',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'Dowco',
    compModel: 'A-DBE-06',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Dowco',
    compModel: 'DBE-06',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Dowco',
    compModel: 'LEC-04',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'Dowco',
    compModel: 'LEC-06',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'Dowco',
    compModel: 'V-DEM-05',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'Dunham_Bush',
    compModel: 'CS Squared',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'EatonWilliams_ColmanModucel',
    compModel: 'Flexaire LK',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'EnergyLogic',
    compModel: 'AirVolution',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'EnergyLogic',
    compModel: 'AVD 370',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'EnergyLogic',
    compModel: 'AVD 550',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'EnergyLogic',
    compModel: 'AVD 780',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'EnergyLogic',
    compModel: 'AVD3',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'EnergyLogic',
    compModel: 'AirVolution',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'EnergyLogic',
    compModel: 'AVD 370',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'EnergyLogic',
    compModel: 'AVD 550',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'EnergyLogic',
    compModel: 'AVD 780',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'EnergyLogic',
    compModel: 'AVD3',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'DJ',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'DJ',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'DJ',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'HE',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'HE',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'HE',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'RE',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'RT',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'RT',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'RT',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'XE',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'XE',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'EngineeredAir',
    compModel: 'XE',
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'Entrematic',
    compModel: 'Apex',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'Entrematic',
    compModel: 'C-Class',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Entrematic',
    compModel: 'Colossus',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Entrematic',
    compModel: 'I-Class',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'Entrematic',
    compModel: 'Apex',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'Entrematic',
    compModel: 'C-Class',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Entrematic',
    compModel: 'Colossus',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Entrematic',
    compModel: 'I-Class',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Envira-NorthSystemsLimited',
    compModel: 'Alite 3',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Envira-NorthSystemsLimited',
    compModel: 'Altra-Air Sailfin',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'Envira-NorthSystemsLimited',
    compModel: 'Axiom',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Envira-NorthSystemsLimited',
    compModel: 'Alite 3',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Envira-NorthSystemsLimited',
    compModel: 'Altra-Air Sailfin',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'Envira-NorthSystemsLimited',
    compModel: 'Axiom',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'Enviro_Tec',
    compModel: 'H',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'Enviro_Tec',
    compModel: 'MQL',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Greenheck'],
    compName: 'Enviro_Tec',
    compModel: 'MQL',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'Enviro_Tec',
    compModel: 'VB',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Greenheck'],
    compName: 'FirstCo',
    compModel: 'MB',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'FirstCo',
    compModel: 'SHW',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'FirstCo',
    compModel: 'SHX',
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Axcent 2',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Centriflow Plus',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Centriflow Plus',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Centriflow Plus 3000',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Centrimaster GT-1',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Centrimaster GT-3',
  },
  {
    ghModel: 'BIDW-21',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Centrimaster GT-5',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-41',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Centrimaster GT-5',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'EC',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'EU Express',
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Europal',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Europal',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'HC',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'HC',
  },
  {
    ghModel: 'GJX',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'Jet Fan',
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'FlaktWoods',
    compModel: 'JM Aerofoil',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'HBTR-151',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Flanders',
    compModel: 'DBT series',
  },
  {
    ghModel: 'HBTR-451',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Flanders',
    compModel: 'DBT series',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'GoFanYourself',
    compModel: 'Z-Tech',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'GoFanYourself',
    compModel: 'Z-Tech3',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'GoFanYourself',
    compModel: 'Z-Tech',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'GoFanYourself',
    compModel: 'Z-Tech3',
  },
  {
    ghModel: '9208',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFA-801',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFA-801',
  },
  {
    ghModel: '9112',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFJ-120',
  },
  {
    ghModel: '9106',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFJ-601',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFJ-601',
  },
  {
    ghModel: '9108',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFJ-801',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFJ-801',
  },
  {
    ghModel: '9612',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFS-120',
  },
  {
    ghModel: 'T9612',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'AFS-120',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'BVF',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EAC-401',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EAC-601',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EACA-601',
  },
  {
    ghModel: 'K8206AMD',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EACA-601D',
  },
  {
    ghModel: 'K8204E',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EACC-401',
  },
  {
    ghModel: 'K8206E',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EACC-601',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EAD-401',
  },
  {
    ghModel: 'T6786',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EAD-601',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EAH-401',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EAH-690',
  },
  {
    ghModel: 'K8504',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ECD-401',
  },
  {
    ghModel: 'K8506',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ECD-601',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EDD-401',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EDD-601',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EDJ-401',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EDJ-430',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EDJ-601',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EDK-402',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EES-401',
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-201',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-401',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-501',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-501X',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-601',
  },
  {
    ghModel: 'SCH601MDE',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-601DE',
  },
  {
    ghModel: 'SCH7',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-701',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHH-701',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHM-601',
  },
  {
    ghModel: 'SCC901',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHV-901',
  },
  {
    ghModel: 'SCC901MD',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EHV-901D',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-202',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-403',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-435',
  },
  {
    ghModel: 'K6744X',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-435X',
  },
  {
    ghModel: 'K6776',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-603',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-635',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-635DE',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESD-635X',
  },
  {
    ghModel: 'T63815',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESJ-150',
  },
  {
    ghModel: 'T608',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESJ-202',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESJ-202',
  },
  {
    ghModel: 'K609',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESJ-401',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESJ-602',
  },
  {
    ghModel: 'CBK638HP',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESK-402',
  },
  {
    ghModel: 'K638',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESK-402',
  },
  {
    ghModel: 'K605MD',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESS-502D',
  },
  {
    ghModel: 'AC460',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESU-130',
  },
  {
    ghModel: 'AC460S',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESU-130S',
  },
  {
    ghModel: 'AC420',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESU-150',
  },
  {
    ghModel: 'AC420S',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'ESU-150S',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EVH-501',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EVH-501D',
  },
  {
    ghModel: 'SCV602MD',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'EVH-602D',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'FAD-402',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'FAD-635',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'FDS-402',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'FDS-602',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'FSJ-402',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'FSJ-602',
  },
  {
    ghModel: 'ASL401',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'FSL-401',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'GCE-402',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'GCI-402',
  },
  {
    ghModel: 'ESD-635PD',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'K6746PD',
  },
  {
    ghModel: 'EHH-601PD',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'SCH601PD',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'SED-501',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'SED-501',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'SES-202',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'SES-202',
  },
  {
    ghModel: 'K611',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'SES-401',
  },
  {
    ghModel: 'KV603',
    trademark: ['Airolite'],
    compName: 'Greenheck',
    compModel: 'SEV-401',
  },
  {
    ghModel: 'VAXE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'AE',
  },
  {
    ghModel: 'VAXS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'AS',
  },
  {
    ghModel: 'VAX',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'AX',
  },
  {
    ghModel: 'VQIB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'BCF',
  },
  {
    ghModel: 'VQID',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'BDF',
  },
  {
    ghModel: 'VICB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'BSQ',
  },
  {
    ghModel: 'VBF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'CBF',
  },
  {
    ghModel: 'VQI',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'CSP',
  },
  {
    ghModel: 'VCSW',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'CSW',
  },
  {
    ghModel: 'VUCB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'CUBE',
  },
  {
    ghModel: 'VUCD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'CUE',
  },
  {
    ghModel: 'VWCD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'CW',
  },
  {
    ghModel: 'VWCD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'CWB',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'EAC-401',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'EACA-601',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'EAD-401',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'EAD-635',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'EHH-501',
  },
  {
    ghModel: 'VMQB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'EQB',
  },
  {
    ghModel: 'VERD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ERD',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESD-202',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESD-435',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESD-635',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESJ-202',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESJ-401',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESJ-602',
  },
  {
    ghModel: 'VESRMD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESRMD',
  },
  {
    ghModel: 'VESRMDF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'ESRMDF',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'EVH-501',
  },
  {
    ghModel: 'VGI',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'FGI',
  },
  {
    ghModel: 'VGR',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'FGR',
  },
  {
    ghModel: 'VJC',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'FJC',
  },
  {
    ghModel: 'VJI',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'FJI',
  },
  {
    ghModel: 'VECD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'G',
  },
  {
    ghModel: 'VECB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'GB',
  },
  {
    ghModel: 'VRSI',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'GRSI',
  },
  {
    ghModel: 'VRSR',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'GRSR',
  },
  {
    ghModel: 'VLCB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'LB',
  },
  {
    ghModel: 'VLPB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'LBP',
  },
  {
    ghModel: 'VLCD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'LD',
  },
  {
    ghModel: 'VLPD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'LDP',
  },
  {
    ghModel: 'VQEID',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'QEID',
  },
  {
    ghModel: 'VQEI',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'QEI',
  },
  {
    ghModel: 'VVRA',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RA',
  },
  {
    ghModel: 'VRBCE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBCE',
  },
  {
    ghModel: 'VRBCF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBCF',
  },
  {
    ghModel: 'VRBCS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBCS',
  },
  {
    ghModel: 'VRBE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBE',
  },
  {
    ghModel: 'VRBF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBF',
  },
  {
    ghModel: 'VRBS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBS',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBU',
  },
  {
    ghModel: 'VRBUO',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RBUMO',
  },
  {
    ghModel: 'VRDE3',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RCE3',
  },
  {
    ghModel: 'VRDS3',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RCS3',
  },
  {
    ghModel: 'VRDU',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RDU',
  },
  {
    ghModel: 'VRDE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RE',
  },
  {
    ghModel: 'VRGU',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RGU',
  },
  {
    ghModel: 'VRBP',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RPBR',
  },
  {
    ghModel: 'VRPBF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RPBRF',
  },
  {
    ghModel: 'VRDP',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RPDR',
  },
  {
    ghModel: 'VRPDF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RPDRF',
  },
  {
    ghModel: 'VRDS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RS',
  },
  {
    ghModel: 'VSF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RSF',
  },
  {
    ghModel: 'VSFP',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'RSFP',
  },
  {
    ghModel: 'VSAF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SAF',
  },
  {
    ghModel: 'VWBCE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SBCE',
  },
  {
    ghModel: 'VWBCR',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SBCR',
  },
  {
    ghModel: 'VWBCS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SBCS',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SBE',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SBS',
  },
  {
    ghModel: 'VWCE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SCE3',
  },
  {
    ghModel: 'VWCR',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SCR3',
  },
  {
    ghModel: 'VWCS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SCS3',
  },
  {
    ghModel: 'VWE',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SE',
  },
  {
    ghModel: 'VFCB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SFB',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SFD',
  },
  {
    ghModel: 'VQ',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SP',
  },
  {
    ghModel: 'VICD',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SQ',
  },
  {
    ghModel: 'VWS',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SS',
  },
  {
    ghModel: 'VBIB',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SWB',
  },
  {
    ghModel: 'VBID',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'SWD',
  },
  {
    ghModel: 'VTIC',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'TBI-CA',
  },
  {
    ghModel: 'VTIF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'TBI-FS',
  },
  {
    ghModel: 'VTC',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'TCB',
  },
  {
    ghModel: 'VUSF',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'USF',
  },
  {
    ghModel: 'VUSG',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'USGF',
  },
  {
    ghModel: 'VJHP',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'VEKTOR-H',
  },
  {
    ghModel: 'VLPI',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'WIH',
  },
  {
    ghModel: 'VLPR',
    trademark: ['Venco'],
    compName: 'Greenheck',
    compModel: 'WRH',
  },
  {
    ghModel: 'AIS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'A ',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'AFSW',
  },
  {
    ghModel: 'AIS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'AI',
  },
  {
    ghModel: 'AS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'AIS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'BISW',
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'BSW',
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CBE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CBI',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CBX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CDE',
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CI',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'CX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'MAC-18-339-B4-J1',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'DF',
  },
  {
    ghModel: 'NYB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'DRC',
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'DSQ',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'FFE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'FFS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SFD',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'GFV',
  },
  {
    ghModel: 'FHI',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'GIH',
  },
  {
    ghModel: 'LB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'GRC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'FHR',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'GRH',
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'GW',
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'GWB',
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'LB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'LBE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LD',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'LDE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'ORB',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'P',
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PBE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'AIS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PBS',
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PBS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PBU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PBUMO',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'AE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PDE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'AIS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PDS',
  },
  {
    ghModel: 'RDU',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PDU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PFE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBF',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PFF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS ',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PFS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PLN',
  },
  {
    ghModel: 'QEP-DIRECT',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PLN4',
  },
  {
    ghModel: 'GRS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'PUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'QEM',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'QEP',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'LB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RCX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPBE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCE ',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPBE-CA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS ',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPBS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPBS-CA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCE3',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPDE-CA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCS3',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPDS-CA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBF',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RPX',
  },
  {
    ghModel: 'GRS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'RS',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SBP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SBP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SDE',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SDP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SDP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SDS',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPBE-CA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPBE-CA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPBE-FS',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPBE-FS ',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCE3',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPDE-CA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS3',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPDS-CA',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPFE ',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPFS',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPNE',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SPNS',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'BSQ',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SQB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SQD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SW',
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SW',
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SWB-200',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'SWB-300',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'TAB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'TAD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'TBIRU',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'Vektor-H',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'TCB-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'UCBE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'UCBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'UCE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'UCH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'UF',
  },
  {
    ghModel: 'LBP',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'W',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LBP',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'W2',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LBP',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'W4',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LDP',
    trademark: ['Greenheck'],
    compName: 'Greenheck_old',
    compModel: 'WD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'CFSD-22',
  },
  {
    ghModel: 'CFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'CFSD-23',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'DFD-21',
  },
  {
    ghModel: 'FSD-213',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'FSD-21',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'FSD-22',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'FSD-23',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'FSD-28',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'FSD-33',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'OFSD-22',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'OFSD-23',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'OFSD-33',
  },
  {
    ghModel: 'SMD-203',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SMD-21',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SMD-22',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SMD-23',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SMD-33',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SMD-43',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SMDR-53',
  },
  {
    ghModel: 'SSFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SSFSD-23',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Greenheck_old',
    compModel: 'SSSMD-23',
  },
  {
    ghModel: 'IMO-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Halton',
    compModel: 'FDL',
  },
  {
    ghModel: 'IMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Halton',
    compModel: 'FDL',
  },
  {
    ghModel: 'SSIMO-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Halton',
    compModel: 'FDL',
  },
  {
    ghModel: 'SSIMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Halton',
    compModel: 'FDL',
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '03',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '03',
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '04',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '05',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '05',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '05',
  },
  {
    ghModel: 'FPB',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '07',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '13',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '13',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-21',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '13',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '13',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '31',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '31',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '31',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '38',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '46',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '48',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '48',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '49',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '52',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '52',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '52',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '53',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '53',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '53',
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '53',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '54',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '61',
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '65',
    filter: {
      category: 'inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '65',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TAUB-CA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '69',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '69',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '111',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '112',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '113',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '03P',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '03U',
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '31-BA',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '31-BC',
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '32-BA',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '32-BC',
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '33-BA',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '33-BC',
  },
  {
    ghModel: 'BCSW-FRP',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '41-FA-66FG',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '46H',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'Hartzell',
    compModel: '69H',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'EBD',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'EBD',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'EBD',
  },
  {
    ghModel: 'MSX ',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'EC',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'HRMU',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'HRMU',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'HRMU',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'IHRMU',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'IHRMU',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'ISHRMU',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'ISHRMU',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'LU',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'LU',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'LU',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'SB',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'SBD',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'SBD',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'SBD',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'SBDR',
  },
  {
    ghModel: 'MSX ',
    trademark: ['Greenheck'],
    compName: 'Hastings',
    compModel: 'SBE',
  },
  {
    ghModel: 'GJI',
    trademark: ['Greenheck'],
    compName: 'Howden',
    compModel: 'ITF',
  },
  {
    ghModel: 'GJX',
    trademark: ['Greenheck'],
    compName: 'Howden',
    compModel: 'Jet Fan',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'HumongousFan',
    compModel: 'Humongous Fan',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'HumongousFan',
    compModel: 'Humongous Fan',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'HunterIndustrial',
    compModel: 'ECO',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'HunterIndustrial',
    compModel: 'Titan',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'HunterIndustrial',
    compModel: 'XP',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'HunterIndustrial',
    compModel: 'ECO',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'HunterIndustrial',
    compModel: 'Titan',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'HunterIndustrial',
    compModel: 'XP',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'ICE',
    compModel: 'BMA',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'ICE',
    compModel: 'BMA',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'ICE',
    compModel: 'BMA',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'ICE',
    compModel: 'IMA',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'ICE',
    compModel: 'IMA',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'ICE',
    compModel: 'MTI',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'ICE',
    compModel: 'MTI',
  },
  {
    ghModel: 'CB608',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '250',
  },
  {
    ghModel: 'CB6482',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '250',
  },
  {
    ghModel: 'T608',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '250',
  },
  {
    ghModel: 'T608',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '250',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '250',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '258',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '408',
  },
  {
    ghModel: 'K611',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '435',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '471',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '608',
  },
  {
    ghModel: 'K6776',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '658',
  },
  {
    ghModel: '9112',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '1280-A',
  },
  {
    ghModel: '9112',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '1280-S',
  },
  {
    ghModel: 'T63815',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '155-45',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '413-SXP',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '450-XP',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '455-XP',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '456-XP',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '458-XP',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '537-XP',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '537-XP',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '613-SXP',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '650-XP',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '652-DD',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '653-XP',
  },
  {
    ghModel: '9106',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '680-A',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '680-A',
  },
  {
    ghModel: '9108',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '880-A',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: '880-A',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: 'BV',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: 'SP43V',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: 'SP-537',
  },
  {
    ghModel: 'SCH7',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: 'SP-737',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'IndustrialLouvers',
    compModel: 'SP-737',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '150',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '150',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '250',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '258',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '650',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '457-A',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '458-XP',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '480XP',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: '480XP',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: 'SP437V',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: 'SP437V',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'IndustrialLouvers',
    compModel: 'SP-537',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '408',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '435',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '436',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '471',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '608',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '658',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '671',
  },
  {
    ghModel: 'ESU-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '1516',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '1280-A',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '1280-S',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '155-45',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '408-SXP',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '413-SXP',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '450-XP',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '450-XPI',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '455-XP',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '456-XP',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '458-XP',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '537-XP',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '608SXP',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '613-SXP',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '650-XP',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '652-DD',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '652-DD',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '653-XP',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '653-XP',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '653XP-DC',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '658-XP',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '667-71',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '667-71',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '680-A',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '880-A',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: '880AA',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: 'BV',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: 'LE-45C',
  },
  {
    ghModel: 'ECD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: 'LE-66C',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: 'SP537HD-DC',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'IndustrialLouvers',
    compModel: 'SP717',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'IndustrialLouvers',
    compModel: '250',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'IndustrialLouvers',
    compModel: '258',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'IndustrialLouvers',
    compModel: '653-XP',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'IndustrialLouvers',
    compModel: 'SP-537',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'InternationalEnvironment',
    compModel: 'HBC',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'InternationalEnvironment',
    compModel: 'VBY',
  },
  {
    ghModel: 'VRD',
    trademark: ['Greenheck'],
    compName: 'Joy',
    compModel: 'AXIVANE COMPAX',
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Joy',
    compModel: 'SERIES 1000',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Joy',
    compModel: 'SERIES 1000',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VRD',
    trademark: ['Greenheck'],
    compName: 'Joy',
    compModel: 'SERIES 1000',
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Joy',
    compModel: 'SERIES 2000',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Joy',
    compModel: 'SERIES 2000',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VRD',
    trademark: ['Greenheck'],
    compName: 'Joy',
    compModel: 'SERIES 2000',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'DFG',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'DFG',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'DFG',
  },
  {
    ghModel: 'LSF',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'EKS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'EKS',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'IFG',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'IFG',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'IFG',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KA',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KD',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KD',
  },
  {
    ghModel: 'HOODTYPE2',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KF',
  },
  {
    ghModel: 'HOODTYPE2',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KG',
  },
  {
    ghModel: 'KSF',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS',
  },
  {
    ghModel: 'KSF',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS',
  },
  {
    ghModel: 'KSFB',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS',
  },
  {
    ghModel: 'KSFD',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS-100',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS-200T',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS-300T',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS-400T',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KS-400T',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KSI-100',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KSI-200T',
  },
  {
    ghModel: 'HOODTYPE1',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'KSI-300T',
  },
  {
    ghModel: 'LSF',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'LEKS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'LEKS',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'MUA',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Kees',
    compModel: 'NTS',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'KelleyLoadingDockSolutions',
    compModel: 'KIF',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'KelleyLoadingDockSolutions',
    compModel: 'KIF',
  },
  {
    ghModel: 'XG-4502S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '480',
  },
  {
    ghModel: 'XG-SGRP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1310',
  },
  {
    ghModel: 'XG-SGSP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1320',
  },
  {
    ghModel: 'XG-SGRH',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1330',
  },
  {
    ghModel: 'XG-SG5500',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1340',
  },
  {
    ghModel: 'XG-SGLF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1380',
  },
  {
    ghModel: 'XG-5800',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1400',
  },
  {
    ghModel: 'XG-5700',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1450',
  },
  {
    ghModel: 'XG-6610',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1910',
  },
  {
    ghModel: 'XG-6615',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1915',
  },
  {
    ghModel: 'XG-6650',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1950',
  },
  {
    ghModel: 'XG-6675',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1975',
  },
  {
    ghModel: 'XG-4504S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '4880',
  },
  {
    ghModel: 'XG-HPL-CA',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5000',
  },
  {
    ghModel: 'XG-HPL-2CH',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5100',
  },
  {
    ghModel: 'XG-DGSF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5600',
  },
  {
    ghModel: 'XG-7650',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6200',
  },
  {
    ghModel: 'XG-7300',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6300',
  },
  {
    ghModel: 'XG-7300R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6390',
  },
  {
    ghModel: 'XG-7550',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6400',
  },
  {
    ghModel: 'XG-7550R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6490',
  },
  {
    ghModel: 'XG-7600R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6490',
  },
  {
    ghModel: 'XG-7650R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6490',
  },
  {
    ghModel: 'XG-7600',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6500',
  },
  {
    ghModel: 'XG-7500',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6600',
  },
  {
    ghModel: 'XG-7500R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6690',
  },
  {
    ghModel: 'XG-SPRTB',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6790',
  },
  {
    ghModel: 'XG-DAF-CC5',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '7000',
  },
  {
    ghModel: 'XG-9000',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '51240',
  },
  {
    ghModel: 'XG-SG2000',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '51350',
  },
  {
    ghModel: 'XG-PRTB',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '56790',
  },
  {
    ghModel: 'XG-7900',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1240PE',
  },
  {
    ghModel: 'XG-7950',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1240PE',
  },
  {
    ghModel: 'XG-SGDB',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1390F',
  },
  {
    ghModel: 'XG-5800A',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1400A',
  },
  {
    ghModel: 'XG-5800FRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1400FR',
  },
  {
    ghModel: 'XG-5700A',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1450A',
  },
  {
    ghModel: 'XG-2000',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1500/1800',
  },
  {
    ghModel: 'XG-2100',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1500/1800',
  },
  {
    ghModel: 'XG-2200',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1500/1800',
  },
  {
    ghModel: 'XG-2300',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1600/1850',
  },
  {
    ghModel: 'XG-2400',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1600/1850',
  },
  {
    ghModel: 'XG-2500',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1600/1850',
  },
  {
    ghModel: 'XG-2600',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1600/1850',
  },
  {
    ghModel: 'XG-2700',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1600/1850',
  },
  {
    ghModel: 'XG-2800',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1600/1850',
  },
  {
    ghModel: 'XG-6600SQR',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1900SQR',
  },
  {
    ghModel: 'XG-6600SQ',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1900SQS',
  },
  {
    ghModel: 'XG-6610SP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1910 L',
  },
  {
    ghModel: 'XG-6610RSP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1910 L NB',
  },
  {
    ghModel: 'XG-6610R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1910R',
  },
  {
    ghModel: 'XG-6615R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1915R',
  },
  {
    ghModel: 'XG-6650R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1950R',
  },
  {
    ghModel: 'XG-6675TZ',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1975 Tech Zone',
  },
  {
    ghModel: 'XG-6675R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1975R',
  },
  {
    ghModel: 'XG-6675RTZ',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '1975R Tech Zone',
  },
  {
    ghModel: 'XG-HPL-GSJ',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5000HF',
  },
  {
    ghModel: 'XG-L',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-1',
  },
  {
    ghModel: 'XG-S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-1S',
  },
  {
    ghModel: 'XG-LT',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-2',
  },
  {
    ghModel: 'XG-LTC',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-2CR/L',
  },
  {
    ghModel: 'XG-ST',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-2S',
  },
  {
    ghModel: 'XG-LS3',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-3',
  },
  {
    ghModel: 'XG-LTC3',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-3C',
  },
  {
    ghModel: 'XG-LS4',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180-4S',
  },
  {
    ghModel: 'XG-LM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-1',
  },
  {
    ghModel: 'XG-LMH',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-1',
  },
  {
    ghModel: 'XG-SM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-1S',
  },
  {
    ghModel: 'XG-LTM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-2 ',
  },
  {
    ghModel: 'XG-LTMH',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-2 ',
  },
  {
    ghModel: 'XG-LTCM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-2CR/L',
  },
  {
    ghModel: 'XG-STM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-2S',
  },
  {
    ghModel: 'XG-LS3M',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-3',
  },
  {
    ghModel: 'XG-LTC3M',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-3C',
  },
  {
    ghModel: 'XG-LS4M',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5180M-4S',
  },
  {
    ghModel: 'XG-DGDF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5600A',
  },
  {
    ghModel: 'XG-H4252',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5680H-15',
  },
  {
    ghModel: 'XG-H4352',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5680H-30',
  },
  {
    ghModel: 'XG-V4252',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5680V-15',
  },
  {
    ghModel: 'XG-V4352',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5680V-30',
  },
  {
    ghModel: 'XG-H4254',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '56880H-15',
  },
  {
    ghModel: 'XG-V4254',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '56880V-15',
  },
  {
    ghModel: 'XG-H4354',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '56880V-30',
  },
  {
    ghModel: 'XG-V4354',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '56880V-30',
  },
  {
    ghModel: 'XG-H4002M',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5800H',
  },
  {
    ghModel: 'XG-V4002M',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5800V',
  },
  {
    ghModel: 'XG-H4002',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '580H',
  },
  {
    ghModel: 'XG-V4002',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '580V',
  },
  {
    ghModel: 'XG-H4004',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5880H',
  },
  {
    ghModel: 'XG-V4004',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5880V',
  },
  {
    ghModel: 'XG-PF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '58PF',
  },
  {
    ghModel: 'XG-4004PCF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5DMGDR',
  },
  {
    ghModel: 'XG-4004P',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5DMGDU',
  },
  {
    ghModel: 'XG-4000PCFPFR',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5DMGPR',
  },
  {
    ghModel: 'XG-4000PCFPFS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5DMGPR w/Extractor',
  },
  {
    ghModel: 'XG-4000PFR',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5DMGPU',
  },
  {
    ghModel: 'XG-4000PFS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5DMGPU w/Extractor',
  },
  {
    ghModel: 'XG-4002PCF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5DMGSR',
  },
  {
    ghModel: 'XG-OBDA',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5OBD',
  },
  {
    ghModel: 'XG-HRD-CL',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5RF',
  },
  {
    ghModel: 'XG-HRD-GS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5RFHF',
  },
  {
    ghModel: 'XG-3100A',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5RM1',
  },
  {
    ghModel: 'XG-3000',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5RM2',
  },
  {
    ghModel: 'XG5500',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5SH',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5SHPC',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '5SHV',
  },
  {
    ghModel: 'XG-7650FRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6200FR',
  },
  {
    ghModel: 'XG-7550R-F',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6290F',
  },
  {
    ghModel: 'XG-76050R-F',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6290F',
  },
  {
    ghModel: 'XG-7550RFRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6290FR',
  },
  {
    ghModel: 'XG-7550FRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6400FR',
  },
  {
    ghModel: 'XG-7600FRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6500FR',
  },
  {
    ghModel: 'XG-7500RFRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6590FR',
  },
  {
    ghModel: 'XG-7500FRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '6600FR',
  },
  {
    ghModel: 'XG-H4002SM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '800H',
  },
  {
    ghModel: 'XG-V4002SM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '800V',
  },
  {
    ghModel: 'XG-H4002S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '80H',
  },
  {
    ghModel: 'XG-V4002S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '80V',
  },
  {
    ghModel: 'XG-H4004S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '880H',
  },
  {
    ghModel: 'XG-V4004S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '880V',
  },
  {
    ghModel: 'XG-DGDFSS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '9700D',
  },
  {
    ghModel: 'XG-H4002SS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '980H',
  },
  {
    ghModel: 'XG-V4002SS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '980V',
  },
  {
    ghModel: 'XG-H4004SS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '9880H',
  },
  {
    ghModel: 'XG-V4004SS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '9880V',
  },
  {
    ghModel: 'XG-CC5SS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '9EGC5',
  },
  {
    ghModel: 'XG-RHFSS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: '9S80HF',
  },
  {
    ghModel: 'XG-H4002-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF580H',
  },
  {
    ghModel: 'XG-V4002-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF580V',
  },
  {
    ghModel: 'XG-H4004-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF5880H',
  },
  {
    ghModel: 'XG-V4004-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF5880V',
  },
  {
    ghModel: 'XG-H4002S-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF80H',
  },
  {
    ghModel: 'XG-V4002S-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF80V',
  },
  {
    ghModel: 'XG-H4004S-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF880H',
  },
  {
    ghModel: 'XG-V4004S-AF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AF880V',
  },
  {
    ghModel: 'XG-DD-REC',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AFA',
  },
  {
    ghModel: 'XG-DD-RD-360',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AFC',
  },
  {
    ghModel: 'XG-DD-CU',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AFD',
  },
  {
    ghModel: 'XG-DD-WALL-1W',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AFE',
  },
  {
    ghModel: 'XG-DD-RD-180',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AFP',
  },
  {
    ghModel: 'XG-DD-RD-90',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AFQ',
  },
  {
    ghModel: 'XG-DD-WALL-FM',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'AFR',
  },
  {
    ghModel: 'XG-JA',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'CRN',
  },
  {
    ghModel: 'XG-AFL-10',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL10',
  },
  {
    ghModel: 'XG-AFLC-10',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL10-FF',
  },
  {
    ghModel: 'XG-AFL-15',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL15',
  },
  {
    ghModel: 'XG-AFLC-15',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL15-FF',
  },
  {
    ghModel: 'XG-AFL-20',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL20',
  },
  {
    ghModel: 'XG-AFLC-20',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL20-FF',
  },
  {
    ghModel: 'XG-AFL-25',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL25',
  },
  {
    ghModel: 'XG-AFLC-25',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL25-FF',
  },
  {
    ghModel: 'XG-AFL-30',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL30',
  },
  {
    ghModel: 'XG-AFLC-30',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFL30-FF',
  },
  {
    ghModel: 'XG-UP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFP/1900BOOT',
  },
  {
    ghModel: 'XG-UPI',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DFP/1900BOOT w/Insulation',
  },
  {
    ghModel: 'XG-RL-SP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DMD',
  },
  {
    ghModel: 'XG-RL',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DPL',
  },
  {
    ghModel: 'XG-RL-DF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DPL2',
  },
  {
    ghModel: 'XG-MSE-HC-M',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DVD-Primary',
  },
  {
    ghModel: 'XG-MSE-HC-S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'DVD-Secondary',
  },
  {
    ghModel: 'XG-CC1',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'EGC10',
  },
  {
    ghModel: 'XG-CC15',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'EGC15',
  },
  {
    ghModel: 'XG-CC5',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'EGC5',
  },
  {
    ghModel: 'XG-CC45',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'EGC545',
  },
  {
    ghModel: 'XG-CC5F',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'EGC5-5FF',
  },
  {
    ghModel: 'XG-STBPF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'HCF23/5HCF23',
  },
  {
    ghModel: 'XG-TBPF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'HCF23/5HCF23',
  },
  {
    ghModel: 'XG-OBD',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'OBD',
  },
  {
    ghModel: 'XG-5750',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PLQ',
  },
  {
    ghModel: 'XG-Phenomenator',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PLQIMP',
  },
  {
    ghModel: 'XG-5750RP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PLQR',
  },
  {
    ghModel: 'XG-BDS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PR12',
  },
  {
    ghModel: 'XG-RSD',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PRD100',
  },
  {
    ghModel: 'XG-L9',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PRSG15',
  },
  {
    ghModel: 'XG-PHPS-IT',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBA',
  },
  {
    ghModel: 'XG-PHPSI-IT',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBA-Insulated',
  },
  {
    ghModel: 'XG-PHPR ',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBR',
  },
  {
    ghModel: 'XG-PHPRI',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBR-Insulated',
  },
  {
    ghModel: 'XG-PHPS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBS',
  },
  {
    ghModel: 'XG-PHCS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBSC',
  },
  {
    ghModel: 'XG-PHCR-DB',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBSCDB',
  },
  {
    ghModel: 'XG-PHPSI',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBS-Insulated',
  },
  {
    ghModel: 'XG-PHCR',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBSRC',
  },
  {
    ghModel: 'XG-PHPS-E',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBSS',
  },
  {
    ghModel: 'XG-PHPSI-E',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBSS-Insulated',
  },
  {
    ghModel: 'XG-PHSP-J',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBT',
  },
  {
    ghModel: 'XG-PHSPI-J',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'PTBT-Insulated',
  },
  {
    ghModel: 'XG-3200',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'R1DBR10',
  },
  {
    ghModel: 'XG-MRSD',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'R580',
  },
  {
    ghModel: 'XG-MRDD',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'R5880',
  },
  {
    ghModel: 'XG-MREC',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'REGC5',
  },
  {
    ghModel: 'XG-3100S-A',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'RM1',
  },
  {
    ghModel: 'XG-R5750',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'RMPLQ',
  },
  {
    ghModel: 'XG-MPK',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'RPN',
  },
  {
    ghModel: 'XG-G3',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'RSG15',
  },
  {
    ghModel: 'XG-4538S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S480H',
  },
  {
    ghModel: 'XG-4500S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S480HZ',
  },
  {
    ghModel: 'XG-RH',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S580',
  },
  {
    ghModel: 'XG-RHF ',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S580-5FF',
  },
  {
    ghModel: 'XG-H4002R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S580HZ',
  },
  {
    ghModel: 'XG-V4002R',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S580HZ',
  },
  {
    ghModel: 'XG-SRP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S580P',
  },
  {
    ghModel: 'XG-SRPF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S580P-5FF',
  },
  {
    ghModel: 'XG-SRH',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S80 ',
  },
  {
    ghModel: 'XG-SRHF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S80-5FF',
  },
  {
    ghModel: 'XG-H4002RS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S80HZ',
  },
  {
    ghModel: 'XG-V4002RS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S80HZ',
  },
  {
    ghModel: 'XG-RP',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S80P',
  },
  {
    ghModel: 'XG-RPF',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S80P-5FF',
  },
  {
    ghModel: 'XG-RHSS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S980',
  },
  {
    ghModel: 'XG-H4002RSS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S980HZ',
  },
  {
    ghModel: 'XG-V4002RSS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'S980HZ',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'SH ',
  },
  {
    ghModel: 'XG-5500SFRS',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'SHFR',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'SHPC',
  },
  {
    ghModel: 'XG-5500DD',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'SHRPLQ',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'SHV',
  },
  {
    ghModel: 'XG-TR',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'SRAC325',
  },
  {
    ghModel: 'XG-TRDeep',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'SRAC325',
  },
  {
    ghModel: 'XG-Periflow',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'Sterilflo',
  },
  {
    ghModel: 'XG-MSCVH',
    trademark: ['Greenheck'],
    compName: 'Krueger',
    compModel: 'VPQ-4',
  },
  {
    ghModel: 'SCH601PD',
    trademark: ['Airolite'],
    compName: 'LeaderIndustries',
    compModel: 'DCPBVB',
  },
  {
    ghModel: 'K6746PD',
    trademark: ['Airolite'],
    compName: 'LeaderIndustries',
    compModel: 'DCPEAB',
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ACE-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ACE-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ACRU-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ACRU-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ACW-B',
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ACW-D',
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ADB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ADB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ADD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ADD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFB-C',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFB-H',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFBH-S',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFB-S',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFBV-C',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFBV-H',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFD-C',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFD-C',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AFDV-C',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'APB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'APB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'APB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'APB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCE3',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'APD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS3',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'APD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'RSF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ASP',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RSFP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ASP-T',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RDU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AUD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AUD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AVAB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AVAD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AVB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AWD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'AWD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA DWDI',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA DWDI',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA SWSI',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA SWSI',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA-4 DWDI',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA-4 DWDI',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA-4 SWSI',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA-4 SWSI',
  },
  {
    ghModel: 'IC',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC',
  },
  {
    ghModel: 'ICO',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC',
  },
  {
    ghModel: 'IC',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-ACH',
  },
  {
    ghModel: 'ICO',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-ACHO',
  },
  {
    ghModel: 'MCY',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-CH',
  },
  {
    ghModel: 'MCB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-FH',
  },
  {
    ghModel: 'ICW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-IHPWD',
  },
  {
    ghModel: 'MCP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-M',
  },
  {
    ghModel: 'MAC',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-MBDY',
  },
  {
    ghModel: 'MAC',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-PBB',
  },
  {
    ghModel: 'MAC',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-PBD',
  },
  {
    ghModel: 'MAC',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAC-SBD',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAF DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CAF DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'VEKTOR-CD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CA-VP',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'PLG ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CCP-A',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CCP-F',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CF',
  },
  {
    ghModel: 'BISW-41_BELT',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CF',
  },
  {
    ghModel: 'BISW-41_DIRECT',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CF',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CF SWSI',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CF-4',
  },
  {
    ghModel: 'LSF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CFS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RSFP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CFS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CIC',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPA Centri-Pac',
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPA Centri-Pac',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW-21',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPA-A',
  },
  {
    ghModel: 'SFB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPFB',
  },
  {
    ghModel: 'SFD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPFD',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPL-A',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPL-F',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'BISW-41_BELT',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS Centri-Pac',
  },
  {
    ghModel: 'BISW-41_DIRECT',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS Centri-Pac',
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS Centri-Pac',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS-A',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS-A',
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS-A',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'VEKTOR-CH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPS-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPV Centri-Pac',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'VEKTOR-CD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CPV-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CVB Centri-Vane',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CVD Centri-Vane',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCBRU ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CVR Centri-Vane',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'CVR-S Centri-Vane',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'DB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'DBX',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'DBX ',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EDB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EDB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EDD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EDD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ERV',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ERV',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIVENT',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'ERV',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EWB-SM',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EWB-SM',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCE3',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EWD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS3',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'EWD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'GC',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'Gemini',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'Gemini Inline',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'FGI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'GI',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'GN',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'FGR',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'GR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'RBCE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HEE ',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HEE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCE3',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HEE-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RE2',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HEE-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HEF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HES',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HES',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCS3',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HES-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RS2',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HES-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HLC-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HLC-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HSE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HSE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HSS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HSS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'HWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'KSFB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'KSPB',
  },
  {
    ghModel: 'KSFD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'KSPD',
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'LPB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'LSU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RGU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'LSUG',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'LUE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'LXUL',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'LXUM',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'MH',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'MHA',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'MHB',
  },
  {
    ghModel: 'IPO ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'MHR',
  },
  {
    ghModel: 'ESRMD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'MRS',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'PLC',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'PLC',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'PLC',
  },
  {
    ghModel: 'QEP-DIRECT',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'PLC',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'PLCD',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'GRSI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'PR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'GRSR',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'PR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMX',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMXD-HP',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMX-HP',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMX-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMX-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMXLE (HP)',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMX-LE HP',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMXU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMXU-HP',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMX-VP',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'QMX-VPHP',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SDB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SPB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SPB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SPD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SPD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'BSQ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SQI-B',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SQI-B',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SQI-D',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BSQ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SQN-B',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SQN-D',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'NYB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SRSHB',
  },
  {
    ghModel: 'NYD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SRSHD',
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SUBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SUBH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SWB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SWD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'SWD',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCN-B',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCND',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCNH-B',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCNHB-LE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCNHD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCBRS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCNHS-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCNHU-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCNS-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TCNU-N',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TDB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TDB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TIB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TIB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TID',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'LBP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TLC-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LDP',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TLC-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TMX',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'GRSI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'GRSR',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TRE',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TRE',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'TUD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'UCIC',
  },
  {
    ghModel: 'TCBRU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'UCV Centri-Vane',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VAB ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VAB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VAD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB ',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VAHB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VCR',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VCR-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'FHI',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VI',
  },
  {
    ghModel: 'FHR',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'VR',
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLPH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLPH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLW',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLW',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLWH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XLWH ',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMPH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMPH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMW',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMW',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMWH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'LorenCook',
    compModel: 'XMWH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'VECB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ACE-B',
  },
  {
    ghModel: 'VECD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ACE-D',
  },
  {
    ghModel: 'VUCB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ACRU-B',
  },
  {
    ghModel: 'VUCD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ACRU-D',
  },
  {
    ghModel: 'VWCB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ACW-B',
  },
  {
    ghModel: 'VWCD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ACW-D',
  },
  {
    ghModel: 'VTIC',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ADB',
  },
  {
    ghModel: 'VAX',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ADD',
  },
  {
    ghModel: 'VTIF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AFB-H',
  },
  {
    ghModel: 'VTIF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AFBH-S',
  },
  {
    ghModel: 'VTIF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AFB-S',
  },
  {
    ghModel: 'VTIF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AFBV-H',
  },
  {
    ghModel: 'VAX',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AFD-C',
  },
  {
    ghModel: 'VAX',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AFDV-C',
  },
  {
    ghModel: 'VWBCE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'APB',
  },
  {
    ghModel: 'VWBCS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'APB',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'APB',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'APB',
  },
  {
    ghModel: 'VWCE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'APD',
  },
  {
    ghModel: 'VWCS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'APD',
  },
  {
    ghModel: 'VSF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ASP',
  },
  {
    ghModel: 'VSFP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'ASP-T',
  },
  {
    ghModel: 'VRDU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AUD',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AVB',
  },
  {
    ghModel: 'VWBCE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AWB',
  },
  {
    ghModel: 'VWBCS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AWB',
  },
  {
    ghModel: 'VWE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AWD',
  },
  {
    ghModel: 'VWS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'AWD',
  },
  {
    ghModel: 'VCSW',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CA SWSI',
  },
  {
    ghModel: 'VCSW',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CA-4 SWSI',
  },
  {
    ghModel: 'VCSW',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CF SWSI',
  },
  {
    ghModel: 'VSFP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CFS',
  },
  {
    ghModel: 'VBIB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPA Centri-Pac',
  },
  {
    ghModel: 'VUSF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPA Centri-Pac',
  },
  {
    ghModel: 'VFCB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPFB',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPFD',
  },
  {
    ghModel: 'VUSF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPS Centri-Pac',
  },
  {
    ghModel: 'VBIB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPS-A',
  },
  {
    ghModel: 'VUSF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPS-A',
  },
  {
    ghModel: 'VJHP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPS-LE',
  },
  {
    ghModel: 'VUSF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CPV Centri-Pac',
  },
  {
    ghModel: 'VTC',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CVB Centri-Vane',
  },
  {
    ghModel: 'VQEID',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'CVD Centri-Vane',
  },
  {
    ghModel: 'VQID',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'DB ',
  },
  {
    ghModel: 'VQIB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'DBX',
  },
  {
    ghModel: 'VQID',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'DBX ',
  },
  {
    ghModel: 'VTIC',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EDB',
  },
  {
    ghModel: 'VAX',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EDD',
  },
  {
    ghModel: 'VRBUO',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EUB',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EWB',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EWB',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EWB-SM',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EWB-SM',
  },
  {
    ghModel: 'VWCE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EWD',
  },
  {
    ghModel: 'VWCS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'EWD',
  },
  {
    ghModel: 'VQ',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'GC',
  },
  {
    ghModel: 'VQ',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'Gemini',
  },
  {
    ghModel: 'VQI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'Gemini Inline',
  },
  {
    ghModel: 'VGI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'GI',
  },
  {
    ghModel: 'VQI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'GN',
  },
  {
    ghModel: 'VGR',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'GR',
  },
  {
    ghModel: 'VRBCE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HEE ',
  },
  {
    ghModel: 'VRBCS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HEE ',
  },
  {
    ghModel: 'VRDE3',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HEE-D',
  },
  {
    ghModel: 'VRBF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HEF',
  },
  {
    ghModel: 'VRBCE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HES',
  },
  {
    ghModel: 'VRBCS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HES',
  },
  {
    ghModel: 'VRDS3',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HES-D',
  },
  {
    ghModel: 'VLCB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HLC-B',
  },
  {
    ghModel: 'VLCD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HLC-D',
  },
  {
    ghModel: 'VRBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HSE ',
  },
  {
    ghModel: 'VRBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HSE ',
  },
  {
    ghModel: 'VRBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HSS',
  },
  {
    ghModel: 'VRBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HSS',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HWB',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'HWB',
  },
  {
    ghModel: 'VUCB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'LPB',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'LSU',
  },
  {
    ghModel: 'VRGU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'LSUG',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'LUE',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'LXUL',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'LXUM',
  },
  {
    ghModel: 'VESRMD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'MRS',
  },
  {
    ghModel: 'VRSI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'PR',
  },
  {
    ghModel: 'VRSR',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'PR',
  },
  {
    ghModel: 'VQEI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'QMX',
  },
  {
    ghModel: 'VQEID',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'QMXD-HP',
  },
  {
    ghModel: 'VQEI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'QMX-HP',
  },
  {
    ghModel: 'VJHP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'QMX-LE',
  },
  {
    ghModel: 'VJHP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'QMXLE (HP)',
  },
  {
    ghModel: 'VQIB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SDB',
  },
  {
    ghModel: 'VWBCE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SPB',
  },
  {
    ghModel: 'VWBCS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SPB',
  },
  {
    ghModel: 'VWE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SPD',
  },
  {
    ghModel: 'VWS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SPD',
  },
  {
    ghModel: 'VICB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SQI-B',
  },
  {
    ghModel: 'VTC',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SQI-B',
  },
  {
    ghModel: 'VICD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SQI-D',
  },
  {
    ghModel: 'VICB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SQN-B',
  },
  {
    ghModel: 'VICD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SQN-D',
  },
  {
    ghModel: 'VRBUO',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SUBH',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SWB',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SWB',
  },
  {
    ghModel: 'VWE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SWD',
  },
  {
    ghModel: 'VWS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'SWD',
  },
  {
    ghModel: 'VTC',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TCN-B',
  },
  {
    ghModel: 'VQEID',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TCND',
  },
  {
    ghModel: 'VTC',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TCNH-B',
  },
  {
    ghModel: 'VJHP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TCNHB-LE',
  },
  {
    ghModel: 'VQEID',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TCNHD',
  },
  {
    ghModel: 'VQIB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TDB',
  },
  {
    ghModel: 'VQID',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TDB',
  },
  {
    ghModel: 'VTIF',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TIB',
  },
  {
    ghModel: 'VLBP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TLC-B',
  },
  {
    ghModel: 'VLDP',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TLC-D',
  },
  {
    ghModel: 'VRSI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TR',
  },
  {
    ghModel: 'VRSR',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TR',
  },
  {
    ghModel: 'VLPI',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TRE',
  },
  {
    ghModel: 'VLPR',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'TRE',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'VB',
  },
  {
    ghModel: 'VUCB',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'VCR',
  },
  {
    ghModel: 'VUCD',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'VCR-D',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLP',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLP',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLPH',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLPH',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLW',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLW',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLWH ',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XLWH ',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMP',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMP',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMPH',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMPH',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMW',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMW',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMWH',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'LorenCook',
    compModel: 'XMWH',
  },
  {
    ghModel: 'K8504',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'AC-45C',
  },
  {
    ghModel: '9112',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-1212',
  },
  {
    ghModel: '9612',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-1212',
  },
  {
    ghModel: 'T9612',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-1212',
  },
  {
    ghModel: '9108',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-88',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-88',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'BVF',
  },
  {
    ghModel: 'K8506',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'DC-65C',
  },
  {
    ghModel: 'CV6774',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'EL-21',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'EL-21',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'EL-27',
  },
  {
    ghModel: 'K6776',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'EL-33',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'EL-48',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'EL-48A',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IF-21A',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL-23',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL-24',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL-31',
  },
  {
    ghModel: 'T6786',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL-33A',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL-34',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL38',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL-52',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IL-52',
  },
  {
    ghModel: 'ESD-635PD',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IP69',
  },
  {
    ghModel: 'K6746PD',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'IP69',
  },
  {
    ghModel: 'T63815',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'TE-15',
  },
  {
    ghModel: 'T608',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'TE-57',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Louver_Dampers',
    compModel: 'TE-57',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Louver_Dampers',
    compModel: 'IL23',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Louver_Dampers',
    compModel: 'IL31',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Louver_Dampers',
    compModel: 'TE57',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17D',
  },
  {
    ghModel: 'ODFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17DAV',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17DX',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17DX',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17DX',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17MA',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17MD',
  },
  {
    ghModel: 'SSDFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17MD-SS',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17S',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17S(SS)',
  },
  {
    ghModel: 'OFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17SAV',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17SF',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17SX',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17SX',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '17SX',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '38D',
  },
  {
    ghModel: 'DFD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '38MD',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '38S',
  },
  {
    ghModel: 'SSFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: '38S(SS)',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'A28',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'A29',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'A38',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AC45C',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AC-45C',
  },
  {
    ghModel: 'EACC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AC45CM',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-482-12-6',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-482-6-6',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-482-8-8',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'ACL-88',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AD1',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AD1',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AD2',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AD2',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AD5',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AD6',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AF53',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AF53-1',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'AF53-1',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'BVF',
  },
  {
    ghModel: 'MBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D150VCD',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D1770',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D1770',
  },
  {
    ghModel: 'SEVCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D182',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D182',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D182',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D200VCRD',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D335',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D388',
  },
  {
    ghModel: 'SSWDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D390',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'D395',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DATL30',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DATL30',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DATL42',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DATL42',
  },
  {
    ghModel: 'ECD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DC65C',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DC65D',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DC65DC',
  },
  {
    ghModel: 'EACC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DC68CM',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'DID200',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL21',
  },
  {
    ghModel: 'EDK-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL27',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL33',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL47',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL47A',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL47E',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL47I',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL48',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL63',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL79',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL81',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL85',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EL88A',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EP48M',
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'EP48M',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'G181',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GB1',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GB2',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD1',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD1',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD1',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD1 ',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD2',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD2',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD2',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD2',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD26',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GD27',
  },
  {
    ghModel: 'HCD-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI30',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI41',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI42',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI43',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI50',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI51',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI54',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI55',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GI80',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GID50',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'GID51',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'HDD100',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'HDD101',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IF21A',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL20',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL21',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL21A',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL24',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL31A',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL33A',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL34',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL38',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL52',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL53',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL58',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL59',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL65',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL75',
  },
  {
    ghModel: 'ESD-635PD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IP69',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'K1(SS)',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'KA1',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'KA2',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'KR1',
  },
  {
    ghModel: 'SMD-201M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'KR1',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'KR2',
  },
  {
    ghModel: 'SMD-202M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'KR2',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'L11',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'L12',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'L91',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'L95',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'L97',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'LA1',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'LEA662HSL',
  },
  {
    ghModel: 'PEV-400',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'LSEP45S',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MA1',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MA1',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MA1VG',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MA2',
  },
  {
    ghModel: 'FSD-312M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MA2',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MA2VG',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MR1',
  },
  {
    ghModel: 'FSD-211M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MR1',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MR2',
  },
  {
    ghModel: 'FSD-212M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MR2',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS1',
  },
  {
    ghModel: 'FSD-211M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS1',
  },
  {
    ghModel: 'SSFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS1(SS)',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS1VF',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS2',
  },
  {
    ghModel: 'FSD-212M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS2',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS2C',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS2C',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS2C',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MS2VF',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'MT1',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR14',
  },
  {
    ghModel: 'HB-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR19',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR55',
  },
  {
    ghModel: 'WD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR66',
  },
  {
    ghModel: 'WD-320',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR66',
  },
  {
    ghModel: 'WD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PR66',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'PRL',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'R7',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'RK',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'RM',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'S3',
  },
  {
    ghModel: 'FDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'S3',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'SL21',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'SL21A',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'SL33',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'SL33A',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'SL48',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'SL63',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'T28B',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'T28F',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'T29B',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'T29F',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Louver_Dampers',
    compModel: 'TE15',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL23',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Louver_Dampers',
    compModel: 'IL31',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'Louver_Dampers',
    compModel: 'TE57',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'AirVolution',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'AVD 370',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'AVD 550',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'AVD 780',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'AVD3',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'X Series',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'Y Series',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'MacroAir',
    compModel: 'Z Series',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'AirVolution',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'AVD 370',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'AVD 550',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'AVD 780',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'AVD3',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'X Series',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'Y Series',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'MacroAir',
    compModel: 'Z Series',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'MagicAire',
    compModel: 'BMW',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'MagicAire',
    compModel: 'BMX',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'MagicAire',
    compModel: 'BVW',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'MagicAire',
    compModel: 'BVX',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Greenheck'],
    compName: 'MagicAire',
    compModel: 'DVW',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Greenheck'],
    compName: 'MagicAire',
    compModel: 'DVX',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'MagicAire',
    compModel: 'HBAX',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'McQuayInternational',
    compModel: 'Destiny',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'McQuayInternational',
    compModel: 'Destiny',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'McQuayInternational',
    compModel: 'Vision',
  },
  {
    ghModel: 'VEKTOR-CD',
    trademark: ['Greenheck'],
    compName: 'MKPlastics',
    compModel: 'Axijet F/S',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'MKPlastics',
    compModel: 'Axijet-V',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'MKPlastics',
    compModel: 'Axijet-VM',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'BCSW-FRP',
    trademark: ['Greenheck'],
    compName: 'MKPlastics',
    compModel: 'DHK',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'MKPlastics',
    compModel: 'KVC',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'HBG',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'HBG',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'HDG',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'HDG',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'HDG',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'HPG',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'MDB',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'MDB',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'MDB',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'MRB',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'Modine',
    compModel: 'MRB',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1602D',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1602J',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604AD',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604CD',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604D',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604DD',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604ESJ',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604J',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604JD',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604KD',
  },
  {
    ghModel: 'ASL401',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604SL',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1604Y',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1605WD',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1605WDF',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606AD',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606CDAF',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606D',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606DD',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606DHPF',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606DHPF',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606J',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606JD',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606WD',
  },
  {
    ghModel: 'SCH601MD',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606WDF',
  },
  {
    ghModel: 'SCH601MDE',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1606WDF',
  },
  {
    ghModel: 'T9612',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1612QS',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1614TL',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '16BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '16BVF',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1704AD',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1704D',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1704J',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1706AD',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1706D',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'NailorIndustries',
    compModel: '1706J',
  },
  {
    ghModel: 'XG-5800FRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4020',
  },
  {
    ghModel: 'XG-7500FRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4080',
  },
  {
    ghModel: 'XG-PRTBFRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4113',
  },
  {
    ghModel: 'XG-CC5FRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4116',
  },
  {
    ghModel: 'XG-RSD',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4250',
  },
  {
    ghModel: 'XG-SPRTB',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4302',
  },
  {
    ghModel: 'XG-7500',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4320',
  },
  {
    ghModel: 'XG-7550',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4320',
  },
  {
    ghModel: 'XG-7300',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4350',
  },
  {
    ghModel: 'XG-7500R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4360',
  },
  {
    ghModel: 'XG-7550R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4360',
  },
  {
    ghModel: 'XG-7600R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4360',
  },
  {
    ghModel: 'XG-7650R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4360',
  },
  {
    ghModel: 'XG-5700FRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4420',
  },
  {
    ghModel: 'XG-6610',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5010',
  },
  {
    ghModel: 'XG-6615',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5015',
  },
  {
    ghModel: 'XG-6650',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5050',
  },
  {
    ghModel: 'XG-6675',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5075',
  },
  {
    ghModel: 'XG-SS-LT',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5410',
  },
  {
    ghModel: 'XG-PHPS-E',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5600',
  },
  {
    ghModel: 'XG-PHPS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5700',
  },
  {
    ghModel: 'XG-PHPS-IT',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5800',
  },
  {
    ghModel: 'XG5500',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6200',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6250',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6500',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6550',
  },
  {
    ghModel: 'XG-9000',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '7200',
  },
  {
    ghModel: 'XG-7600FRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4080CB',
  },
  {
    ghModel: 'XG-7500RFRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4080R',
  },
  {
    ghModel: 'XG-PRTB',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4302A',
  },
  {
    ghModel: 'XG-7600',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4320CB ',
  },
  {
    ghModel: 'XG-7650',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4320CB ',
  },
  {
    ghModel: 'XG-7900',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4320M ',
  },
  {
    ghModel: 'XG-7950',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '4320MR',
  },
  {
    ghModel: 'XG-RL',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '45DL1',
  },
  {
    ghModel: 'XG-RL-DF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '45DL2',
  },
  {
    ghModel: 'XG-RL-SP',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '45DLC1',
  },
  {
    ghModel: 'XG-RL-SP-DF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '45DLC2',
  },
  {
    ghModel: 'XG-LFL',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '48LV',
  },
  {
    ghModel: 'XG-2000',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-24x Frame A',
  },
  {
    ghModel: 'XG-2100',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-24x Frame E',
  },
  {
    ghModel: 'XG-2200',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-24x Frame G',
  },
  {
    ghModel: 'XG-2500',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-28x Frame A',
  },
  {
    ghModel: 'XG-2600',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-28x Frame E',
  },
  {
    ghModel: 'XG-2700',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-28x Frame G',
  },
  {
    ghModel: 'XG-2300',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-48x Frame A',
  },
  {
    ghModel: 'XG-2400',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-48x Frame E',
  },
  {
    ghModel: 'XG-2800',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '49-48x Frame G',
  },
  {
    ghModel: 'XG-6600SQ',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5000CTD',
  },
  {
    ghModel: 'XG-6600SQR',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5000RCTD',
  },
  {
    ghModel: 'XG-6610R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5010R',
  },
  {
    ghModel: 'XG-6615R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5015R',
  },
  {
    ghModel: 'XG-6650R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5050R',
  },
  {
    ghModel: 'XG-6675R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5075R',
  },
  {
    ghModel: 'XG-6675TZ',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5075TZ',
  },
  {
    ghModel: 'XG-6675RTZ',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5075TZR',
  },
  {
    ghModel: 'XG-4538',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5130H-HD',
  },
  {
    ghModel: 'XG-RH',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5145H',
  },
  {
    ghModel: 'XG-RHE',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5145H',
  },
  {
    ghModel: 'XG-L',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-1A',
  },
  {
    ghModel: 'XG-S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-1B',
  },
  {
    ghModel: 'XG-LT',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-2A',
  },
  {
    ghModel: 'XG-ST',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-2B',
  },
  {
    ghModel: 'XG-LTC',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-2C/D',
  },
  {
    ghModel: 'XG-LS3',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-3A',
  },
  {
    ghModel: 'XG-LTC3',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-3C',
  },
  {
    ghModel: 'XG-LS4',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51C-4A',
  },
  {
    ghModel: 'XG-LV',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51CD-1A',
  },
  {
    ghModel: 'XG-SH',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51CD-1B',
  },
  {
    ghModel: 'XG-LTV',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51CD-2A',
  },
  {
    ghModel: 'XG-STH',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51CD-2B',
  },
  {
    ghModel: 'XG-DGDF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51DGD',
  },
  {
    ghModel: 'XG-DGSF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51DGS',
  },
  {
    ghModel: 'XG-H4004',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51DH',
  },
  {
    ghModel: 'XG-V4004',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51DV',
  },
  {
    ghModel: 'XG-4004PCF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51DVC',
  },
  {
    ghModel: 'XG-CC5',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51EC',
  },
  {
    ghModel: 'XG-CC45',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51EC45',
  },
  {
    ghModel: 'XG-CC15',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51EC-CB',
  },
  {
    ghModel: 'XG-CC1',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51EC-CC',
  },
  {
    ghModel: 'XG-RHEF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FB45',
  },
  {
    ghModel: 'XG-RHF ',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FB45',
  },
  {
    ghModel: 'XG-CC5F',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FE',
  },
  {
    ghModel: 'XG-CC45F',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FE45',
  },
  {
    ghModel: 'XG-H4002R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FH',
  },
  {
    ghModel: 'XG-V4002R',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FH',
  },
  {
    ghModel: 'XG-4500',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FH-HD',
  },
  {
    ghModel: 'XG-RPF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51FP',
  },
  {
    ghModel: 'XG-RP',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51PR',
  },
  {
    ghModel: 'XG-4000PCFPFR',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51PRC',
  },
  {
    ghModel: 'XG-H4002',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51SH',
  },
  {
    ghModel: 'XG-V4002',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51SV',
  },
  {
    ghModel: 'XG-4002PCF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '51SVC',
  },
  {
    ghModel: 'XG-UP',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5300MP',
  },
  {
    ghModel: 'XG-UPI',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5300MPI',
  },
  {
    ghModel: 'XG-SSR-LT',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5410R',
  },
  {
    ghModel: 'XG-DS-LT',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5420S',
  },
  {
    ghModel: 'XG-DST-LT',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5420T',
  },
  {
    ghModel: 'XG-PHPSI-E',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5600I',
  },
  {
    ghModel: 'XG-PHPSI',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5700I',
  },
  {
    ghModel: 'XG-PHPR ',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5700RI',
  },
  {
    ghModel: 'XG-PHPRI',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5700RI',
  },
  {
    ghModel: 'XG-PHPSI-IT',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '5800I',
  },
  {
    ghModel: 'XG-PHCS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '59BS',
  },
  {
    ghModel: 'XG-PHCR',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '59BSR',
  },
  {
    ghModel: 'XG-PHCR-DB',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '59NDR',
  },
  {
    ghModel: 'XG-SRH',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6145H',
  },
  {
    ghModel: 'XG-4538S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6145H-HD',
  },
  {
    ghModel: 'XG-H4004S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61DH',
  },
  {
    ghModel: 'XG-4504S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61DH-HD',
  },
  {
    ghModel: 'XG-V4004S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61DV',
  },
  {
    ghModel: 'XG-CC5S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61EC',
  },
  {
    ghModel: 'XG-SRHF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61FB45',
  },
  {
    ghModel: 'XG-H4002RS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61FH',
  },
  {
    ghModel: 'XG-V4002RS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61FH',
  },
  {
    ghModel: 'XG-4500S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61FH-HD',
  },
  {
    ghModel: 'XG-SRPF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61FP',
  },
  {
    ghModel: 'XG-SRP',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61PR',
  },
  {
    ghModel: 'XG-H4002S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61SH',
  },
  {
    ghModel: 'XG-4502S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61SH-HD',
  },
  {
    ghModel: 'XG-V4002S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '61SV',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6200IV',
  },
  {
    ghModel: 'XG-5500SFRS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6500-FRD',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6500IV',
  },
  {
    ghModel: 'XG-5500DD',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '66UNI',
  },
  {
    ghModel: 'XG-RHSS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6745H',
  },
  {
    ghModel: 'XG-4545SS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '6755H-HD',
  },
  {
    ghModel: 'XG-H4004SS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67DH',
  },
  {
    ghModel: 'XG-V4004SS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67DV',
  },
  {
    ghModel: 'XG-CC5SS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67EC',
  },
  {
    ghModel: 'XG-RHFSS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67FB45',
  },
  {
    ghModel: 'XG-H4002RSS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67FH',
  },
  {
    ghModel: 'XG-V4002RSS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67FH',
  },
  {
    ghModel: 'XG-RPSS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67PR',
  },
  {
    ghModel: 'XG-H4002SS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67SH',
  },
  {
    ghModel: 'XG-V4002SS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '67SV',
  },
  {
    ghModel: 'XG-H4004-AF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '71DH',
  },
  {
    ghModel: 'XG-V4004-AF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '71DV',
  },
  {
    ghModel: 'XG-H4002-AF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '71SH',
  },
  {
    ghModel: 'XG-V4002-AF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '71SV',
  },
  {
    ghModel: 'XG-H4352',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '813SH',
  },
  {
    ghModel: 'XG-H4354',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '813SV',
  },
  {
    ghModel: 'XG-V4352',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '813SV',
  },
  {
    ghModel: 'XG-V4354',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '813SV',
  },
  {
    ghModel: 'XG-H4154',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81DH',
  },
  {
    ghModel: 'XG-H4254',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81DH',
  },
  {
    ghModel: 'XG-V4154',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81DV',
  },
  {
    ghModel: 'XG-V4254',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81DV',
  },
  {
    ghModel: 'XG-H4152',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81SH',
  },
  {
    ghModel: 'XG-H4252',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81SH',
  },
  {
    ghModel: 'XG-V4152',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81SV',
  },
  {
    ghModel: 'XG-V4252',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '81SV',
  },
  {
    ghModel: 'XG-HRD-CL',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '92FRP',
  },
  {
    ghModel: 'XG-HRD-GS',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '92FRPF',
  },
  {
    ghModel: 'XG-HPL-CA',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '92LFD-AL',
  },
  {
    ghModel: 'XG-HPL-GSJ',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: '92LFDFS-AL',
  },
  {
    ghModel: 'XG-3100',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'ARNR ',
  },
  {
    ghModel: 'XG-3100A',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'ARNRA1',
  },
  {
    ghModel: 'XG-STBPF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DFA',
  },
  {
    ghModel: 'XG-TBPF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DFA',
  },
  {
    ghModel: 'XG-DD-REC',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DFF1',
  },
  {
    ghModel: 'XG-DD-CU',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DFF1C',
  },
  {
    ghModel: 'XG-DD-RD-180',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DFR180',
  },
  {
    ghModel: 'XG-DD-RD-360',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DFR360',
  },
  {
    ghModel: 'XG-DD-RD-90',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DFR90',
  },
  {
    ghModel: 'XG-DD-WALL-1W',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DWF1',
  },
  {
    ghModel: 'XG-DD-WALL-FM',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'DWR1',
  },
  {
    ghModel: 'XG-L9',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'EGL',
  },
  {
    ghModel: 'XG-G3',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'EGR',
  },
  {
    ghModel: 'XG-AFL-10',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL10',
  },
  {
    ghModel: 'XG-AFLC-10',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL10-C',
  },
  {
    ghModel: 'XG-AFL-15',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL15',
  },
  {
    ghModel: 'XG-AFLC-15',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL15-C',
  },
  {
    ghModel: 'XG-AFL-20',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL20',
  },
  {
    ghModel: 'XG-AFLC-20',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL20-C',
  },
  {
    ghModel: 'XG-AFL-25',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL25',
  },
  {
    ghModel: 'XG-AFLC-25',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL25-C',
  },
  {
    ghModel: 'XG-AFL-30',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL30',
  },
  {
    ghModel: 'XG-AFLC-30',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FL30-C',
  },
  {
    ghModel: 'XG-AFLI',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FM10',
  },
  {
    ghModel: 'XG-AFLIR',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FMR10',
  },
  {
    ghModel: 'XG-AFL-TS-10',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FT10',
  },
  {
    ghModel: 'XG-AFL-TS-15',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FT15',
  },
  {
    ghModel: 'XG-AFL-TS-20',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'FT20',
  },
  {
    ghModel: 'XG-WFO',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'NFA',
  },
  {
    ghModel: 'XG-OBD',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'OBD ',
  },
  {
    ghModel: 'XG-OBDA',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'OBD-A',
  },
  {
    ghModel: 'XG-PF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'PF',
  },
  {
    ghModel: 'XG-NDRH',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RACA',
  },
  {
    ghModel: 'XG-3200',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RDB',
  },
  {
    ghModel: 'XG-MRDD',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RDDG',
  },
  {
    ghModel: 'XG-MREC',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RECG',
  },
  {
    ghModel: 'XG-3100S',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RNR ',
  },
  {
    ghModel: 'XG-3100S-A',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RNRA1',
  },
  {
    ghModel: 'XG-5700',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RNS3',
  },
  {
    ghModel: 'XG-5800',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RNS4',
  },
  {
    ghModel: 'XG-5800A',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RNSA',
  },
  {
    ghModel: 'XG-MRPF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RPG',
  },
  {
    ghModel: 'XG-MPK',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RPL',
  },
  {
    ghModel: 'XG-MRSD',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RSDG',
  },
  {
    ghModel: 'XG-R5750',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'RUNI',
  },
  {
    ghModel: 'XG-SG2000',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SG-280',
  },
  {
    ghModel: 'XG-SG5500',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SG-6500',
  },
  {
    ghModel: 'XG-SGRH',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SG-BM4',
  },
  {
    ghModel: 'XG-SGLF',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SG-LGS',
  },
  {
    ghModel: 'XG-SGSP',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SG-LM',
  },
  {
    ghModel: 'XG-SGRP',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SG-RP',
  },
  {
    ghModel: 'XG-TR',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SR',
  },
  {
    ghModel: 'XG-TRDeep',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'SR',
  },
  {
    ghModel: 'XG-Periflow',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'Steri-System',
  },
  {
    ghModel: 'XG-5750',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'UNI',
  },
  {
    ghModel: 'XG-Phenomenator',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'UNI2-HI',
  },
  {
    ghModel: 'XG-5750T',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'UNI-PD',
  },
  {
    ghModel: 'XG-5750RP',
    trademark: ['Greenheck'],
    compName: 'NailorIndustries',
    compModel: 'UNI-RP',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '110',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '210',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '716',
  },
  {
    ghModel: 'CRD-60',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '720',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '722',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1010',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1010',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1012',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1012',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1020',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1020',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1022',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1022',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1090',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1110',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1120',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1210',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1210',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1211',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1211',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1212',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1212',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1213',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1213',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1220',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1220',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1222',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1222',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1223',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1223',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1260',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1260',
  },
  {
    ghModel: 'SMD-203',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1260',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1261',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1261',
  },
  {
    ghModel: 'SMD-203',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1261',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1262',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1262',
  },
  {
    ghModel: 'SMD-203',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1262',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1263',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1263',
  },
  {
    ghModel: 'SMD-203',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1263',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1270',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1270',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1270',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1271',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1271',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1272',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1272',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1273',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1273',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1280',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1281',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1282',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1283',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1510',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1517',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1520',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1527',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1530',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1537',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1540',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1547',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1810',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1820',
  },
  {
    ghModel: 'MBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1870',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1870',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1890',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1910',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1917',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1920',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1927',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1970',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1975',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1980',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1985',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1990',
  },
  {
    ghModel: 'HCDR-351',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1995',
  },
  {
    ghModel: 'VCD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '2010',
  },
  {
    ghModel: 'VCD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '2020',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0100G',
  },
  {
    ghModel: 'OFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0110GOW',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0110H',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0110V',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0114-12',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0114-14',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0114-16',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0120H',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0120V',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0124-12',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0124-14',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0124-16',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0130H',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0130V',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0134-12',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0134-14',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0134-16',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0140H',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0140V',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0210H',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0220H',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0220V',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0230H',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0230V',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0240H',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0240V',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0510H',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0510V',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0520H',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0520V',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0530H',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0530V',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0570H',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0570V',
  },
  {
    ghModel: 'FD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0570V',
  },
  {
    ghModel: 'FD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0580V',
  },
  {
    ghModel: 'FD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0590V',
  },
  {
    ghModel: 'CRD-1LP',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0716-4',
  },
  {
    ghModel: 'CRD-1LP',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0716-4A',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0716A',
  },
  {
    ghModel: 'CRD-60B',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0720B',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '075X series',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '076X series',
  },
  {
    ghModel: 'RAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0800 Type M2',
  },
  {
    ghModel: 'PRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '0800-5',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '08SCL',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '08SH',
  },
  {
    ghModel: 'IMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1201-MDG',
  },
  {
    ghModel: 'SSIMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1201-MDG',
  },
  {
    ghModel: 'IMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1201-MDS',
  },
  {
    ghModel: 'SSIMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1201-MDS',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1210BAL',
  },
  {
    ghModel: 'SMD-301M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1210M',
  },
  {
    ghModel: 'SMD-301V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1210VB',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1211BAL',
  },
  {
    ghModel: 'SMD-301M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1211M',
  },
  {
    ghModel: 'SMD-301V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1211VB',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1212BAL',
  },
  {
    ghModel: 'SMD-301M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1212M',
  },
  {
    ghModel: 'SMD-301V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1212VB',
  },
  {
    ghModel: 'SMD-301V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1212VB-4',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1213BAL',
  },
  {
    ghModel: 'SMD-301M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1213M',
  },
  {
    ghModel: 'SMD-301V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1213VB',
  },
  {
    ghModel: 'CFSD-211-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-1',
  },
  {
    ghModel: 'CFSD-211-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-1',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-1',
  },
  {
    ghModel: 'CFSD-211-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-2',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-2',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-2',
  },
  {
    ghModel: 'CFSD-211-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-3',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1217C-3',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1220-3',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1220M',
  },
  {
    ghModel: 'FSD-312M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1220M',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221-3',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221-DOW',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221G',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221G',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221M',
  },
  {
    ghModel: 'FSD-312M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221M',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221VB',
  },
  {
    ghModel: 'FSD-311V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1221VB',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1222-3',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1222M',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1222VB',
  },
  {
    ghModel: 'FSD-311V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1222VB',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1223-3',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1223VB',
  },
  {
    ghModel: 'FSD-311V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1223VB',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1271G',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1271G',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1280EAF',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290F',
  },
  {
    ghModel: 'FDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290F',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290FS',
  },
  {
    ghModel: 'SSDFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290F-SS',
  },
  {
    ghModel: 'SSFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290F-SS',
  },
  {
    ghModel: 'SFSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290FS-SS',
  },
  {
    ghModel: 'SSFSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290FS-SS',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290S',
  },
  {
    ghModel: 'SSSMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1290S-SS',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CB',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370CF',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FF',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370FR',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370HMU',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370VM',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370VM',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1370VM',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CB',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380CF',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FF',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380FR',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380HMU',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380HMU',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380HMU',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380HMU',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380HMU',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380HMU',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380VM',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380VM',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1380VM',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1390CB',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1600PHM',
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1600PHM',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1602D',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1602J',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604AD',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604CD',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604D',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604DD',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604ESJ',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604J',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604JD',
  },
  {
    ghModel: 'EDK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604KD',
  },
  {
    ghModel: 'FSL-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604SL',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1604Y',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1605WD',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1605WDF',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606AD',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606CDAF',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606D',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606DD',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606DHPF',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606DHPF',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606J',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606JD',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606WD',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606WDF',
  },
  {
    ghModel: 'EHH-601DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1606WDF',
  },
  {
    ghModel: 'AFS-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1612QS',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1614TL',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '16BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '16BVF',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1704AD',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1704D',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1704J',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1706AD',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1706D',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1706J',
  },
  {
    ghModel: 'HB-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1900CB',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '1905CB',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '2010EAF',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '2010W/B & EAF',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '2020EAF',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '2020W/B & EAF',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: '722A',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0100G',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0110',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0110G',
  },
  {
    ghModel: 'ODFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0110GOW',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0114-12',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0114-14',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0114-16',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0120',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0124-12',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0124-14',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0124-16',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0130',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0134-12',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0134-14',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0134-16',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0140',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0510',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0520',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D0530',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1200',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1201',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1202',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1203',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1250',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1251',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1252',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NailorIndustries',
    compModel: 'D1253',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'ACSLJ-12',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'ACSLJ-6',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'ACSLJ-8',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'A-SLD-4-45-GL',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'A-SLD-6',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'A-XAD-4-45-GL',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'A-XAD-6',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'BVEX',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'BVFL',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'CA-XAD-4-45-GL',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'CA-XAD-6-45-GL',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'CE-XAJ-4',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'CI-XAJ-4',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'ESHX-4',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'SLD-4-45-GL',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'SLD-6-45-GL',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'SLJ-4',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'SLJ-6',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'WDR-4',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'WDR-6',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAD-2-45-GL',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAD-4',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAD-4-45-GL',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAD-6-GL',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAD-6-MD',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAD-6-MD',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XADD-4-45-GL',
  },
  {
    ghModel: 'K601D',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XADV-4',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAJ-4',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAJ-4-GL',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'NCA',
    compModel: 'XAL-6',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'ACSLJ-12',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'ACSLJ-6',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'ACSLJ-8',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'A-SLD-4-45-GL',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'A-SLD-6',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'A-XAD-4-45-GL',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'A-XAD-6',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'BVEX',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'BVFL',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'CA-XAD-4-45-GL',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'CA-XAD-6-45-GL',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'CE-XAJ-4',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'CI-XAJ-4',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'ESHX-4',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'SLD-4-45-GL',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'SLD-6-45-GL',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'SLJ-4',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'SLJ-6',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'WDR-4',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'WDR-6',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAD-2-45-GL',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAD-4',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAD-4-45-GL',
  },
  {
    ghModel: 'ESID-430',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAD-4-IE',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAD-6-GL',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAD-6-MD',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAD-6-MD',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XADD-4-45-GL',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XADV-4',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAJ-4',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAJ-4-GL',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAL-6',
  },
  {
    ghModel: 'EDK-430',
    trademark: ['Greenheck'],
    compName: 'NCA',
    compModel: 'XAZ-4-30-GL',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'ACD-44',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'ACD-54',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'ACD-54',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'ACD-56',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'ACD-56',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'A-XAD-4-46',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'A-XAJ-4',
  },
  {
    ghModel: 'WD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CBD-112',
  },
  {
    ghModel: 'WD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CBD-112',
  },
  {
    ghModel: 'WD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CBD-112',
  },
  {
    ghModel: 'WD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CBD-112',
  },
  {
    ghModel: 'WD-320',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CBD-112',
  },
  {
    ghModel: 'WD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CBD-112',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CD-RD',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CD-RD-T',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CD-S/R',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CD-S/R-HC',
  },
  {
    ghModel: 'CRD-60',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CFS-2F',
  },
  {
    ghModel: 'CRD-60X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'CFSX-2F',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-S',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-S',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-S',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-SL',
  },
  {
    ghModel: 'FD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-SL',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-USL',
  },
  {
    ghModel: 'FD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-USL',
  },
  {
    ghModel: 'FD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-A-USL',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-B',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-B',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-B-S',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-B-S',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-B-S',
  },
  {
    ghModel: 'FD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-B-SL',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-C',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-C',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-C-M-H',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-C-M-H',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-C-S',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-C-S',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-C-S',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-A',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-A',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-A-S',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-A-S',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-A-S',
  },
  {
    ghModel: 'DFD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-A-SL',
  },
  {
    ghModel: 'DFD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-A-SL',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-B',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-B',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-B-S',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-B-S',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-B-S',
  },
  {
    ghModel: 'DFD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-B-SL',
  },
  {
    ghModel: 'DFD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-B-SL',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-C',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-C',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-C-S',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-C-S',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-C-S',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-MB-3V',
  },
  {
    ghModel: 'DFD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-MB-3V',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-MB-AF',
  },
  {
    ghModel: 'ODFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-OW',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FDD-RD',
  },
  {
    ghModel: 'OFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-OW',
  },
  {
    ghModel: 'FDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FD-RD',
  },
  {
    ghModel: 'CFSD-211-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-CR-211',
  },
  {
    ghModel: 'CFSD-211-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-CR-211',
  },
  {
    ghModel: 'CFSD-211-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-CR-211',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-CR-212',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-CR-212',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-CR-212',
  },
  {
    ghModel: 'GFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-FA-211',
  },
  {
    ghModel: 'GFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-FA-212',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-OW-211',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-OW-212',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-S-211',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-S-211',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-3V-S-212',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-AF-OW-211',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-AF-OW-212',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-AF-S-211',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-AF-S-211',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-AF-S-212',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-RD-211',
  },
  {
    ghModel: 'FSDR-512',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'FSD-RD-212',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'HDB-H',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'HDB-M',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'MBD-57',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'MBD-RD-88',
  },
  {
    ghModel: 'MBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-20',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-20',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-20RS',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-500RS',
  },
  {
    ghModel: 'HCDR-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-800RS',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-900RS',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-OBD-820',
  },
  {
    ghModel: 'HCD-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-OBD-AF-101',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-OBD-AF-821 ',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-OBD-AF-822',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'PBD-OBD-AF-823',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-57',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-57',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-AF',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-IB-59',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-LL-57',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-LL-57',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-LL-HD-57',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-RD-88',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SCD-RD-LL-88',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SSD-3V-201',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SSD-3V-202',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SSD-AF-201',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SSD-AF-202',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SSD-RD-201',
  },
  {
    ghModel: 'SMDR-502',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'SSD-RD-202',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'WDR-6-FL',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'WDRV-6',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-1',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-1',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-1',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-1',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-1',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-1',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-2',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-2',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-2',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-2',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-2',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XABD-2',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XAD-4-FL',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XAD-6-GL-FL',
  },
  {
    ghModel: 'ESD-635D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XAD-6-MD',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XAL-2-GL',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XPHJ & XPHZ',
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'NCA',
    compModel: 'XPHJ & XPHZ',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'NCA',
    compModel: 'A-XAD-4-45-GL',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'NCA',
    compModel: 'A-XAD-6',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'NCA',
    compModel: 'CA-XAD-4-45-GL',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'NCA',
    compModel: 'XAD-2-45-GL',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'NCA',
    compModel: 'XAD-4',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'NCA',
    compModel: 'XAD-6-GL',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'NCA',
    compModel: 'XAJ-4',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: '30',
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'ACOUSTAFOIL',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'ACOUSTAFOIL',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'ACOUSTAFOIL',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'ACOUSTAFOIL',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'ACOUSTAFOIL',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'ACOUSTAFOIL-Q',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'ACOUSTAFOIL-Q',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'AF-30',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'AF-40',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'AF-50',
  },
  {
    ghModel: 'FPB',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'COMPACT GI',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'DOUBLE WIDTH ACOUSTAFOIL FAN',
    filter: {
      category: 'Blowers'
    }

  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'DUCT FAN',
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'DUCT FAN',
  },
  {
    ghModel: 'BCSW-FRP',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'FRP FUME EXHAUSTER',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'GENERAL PURPOSE FAN ',
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'GENERAL PURPOSE FANS',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'GENERAL PURPOSE FANS',
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'GPA',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'GPA',
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'L',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB-CA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'L',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'PLENUM FAN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'PLENUM FAN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'PLR',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'PLUG FAN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'RTS',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SERIES 20 GI',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SERIES 20 GI',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SERIES 30 GI',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SERIES 30 GI',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SERIES 45 GI',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SERIES 45 GI',
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SWSI FAN',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'SWSI FAN',
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'TUBE AXIAL',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'TUBE AXIAL',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'TUBE AXIAL',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'TUBE AXIAL',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'TUBULAR ACOUSTAFOIL',
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'VANE AXIAL',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'NewYorkBlower',
    compModel: 'VANE AXIAL',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5010',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5010',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW-21',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5010',
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5010',
  },
  {
    ghModel: 'BISW-21',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5020',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5020',
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5310',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5310',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5312',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5391',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5392',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5691',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '6610',
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '6620',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '6630',
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '7410',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '7410',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '7510',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '7520',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '7991',
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '8091',
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '8093',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '8310',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '9240',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '9240',
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5010-DSISW',
  },
  {
    ghModel: 'BIDW-21',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5020-DIDW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-41',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5020-DIDW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'NorthernBlower',
    compModel: '5020-SISW',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'PattersonFan',
    compModel: 'High-5',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'PattersonFan',
    compModel: 'V-Series',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'PattersonFan',
    compModel: 'High-5',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'PattersonFan',
    compModel: 'V-Series',
  },
  {
    ghModel: 'RBCE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCE3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCS3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RE2',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RS2',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCE3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RCS3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RE2',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RS2',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'FHI',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AIRETTE',
  },
  {
    ghModel: 'FHR',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'AIRETTE',
  },
  {
    ghModel: 'SCE3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BC',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BCH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BCH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BCH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BCH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BHH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BHH',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BHM',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BHM',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BLL',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BLL',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCR3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BREEZEX',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCR3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'BX',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'CENTREX',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'D',
  },
  {
    ghModel: 'AE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DAE',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'AS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DAS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DOMEX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DOMEX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GRSI',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'GRSR',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DXB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DYNAMO',
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'DYNAPAK',
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ERV D-Series',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ERV D-Series',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ERV M-Series',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ERV O-Series',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ERV O-Series',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ERV S-Series',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ERV S-Series',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ESI',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ESP',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'FH SERIES',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'FUMEX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'FUMEX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'FUMEX',
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'FUMEX',
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'FX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'FXB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RDU',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'HC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'HF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'HS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'HX',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'HZ ',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RDU',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'HZ ',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'IND-AH',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'IND-OT',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'IND-WT',
  },
  {
    ghModel: 'LD',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'LC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'LC-A',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LBP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'LF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'LDP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'LF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'MBP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'MBP',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'RSF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'MU ',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RSF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'MUFFAN',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'P',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'P',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'PENNHOUSE',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'PENNHOUSE',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'PNB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'PNB',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCE3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'PND',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS3',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'PND',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'REX',
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'SX',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'BSQ',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'SX-BC',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'TDA',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'TDA-RA',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'TUB',
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'TXB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'TXB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'TXB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'TXD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'UVF',
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'VCR',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'VCR',
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'vPlume',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'VSP',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'VXB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'VXD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'WFX',
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'WFXB',
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'Z',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ZC',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ZC',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BCF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ZCC',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BDF',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ZCC',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ZEPHYR',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ZEPHYR',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'PennBarry',
    compModel: 'ZEPHYR JR',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'VEKTOR-CD',
    trademark: ['Greenheck'],
    compName: 'PlasticAir',
    compModel: 'Skyplume G1/G2',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: '9208',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EAA-845',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EAA-845',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EBE-445',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EBI-445',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'SCH601',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'ECD-635',
  },
  {
    ghModel: 'SCH7',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'ECD-745',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'ECD-745',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'ECV-245',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'ECV-545',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'ECV-645-MD',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFD-245',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFD-435',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFD-437',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFD-445',
  },
  {
    ghModel: 'K6776-CB6776',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFD-645',
  },
  {
    ghModel: 'T608',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-245',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-245',
  },
  {
    ghModel: 'K609',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-430 ',
  },
  {
    ghModel: 'K609',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-437',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-437-HP',
  },
  {
    ghModel: 'K609',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-445',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-607-HP',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-637-MD',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFJ-645',
  },
  {
    ghModel: 'K638',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFK-430',
  },
  {
    ghModel: 'K638',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFK-437',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFK-437',
  },
  {
    ghModel: 'K638',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFK-445',
  },
  {
    ghModel: 'K638HP',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFK-445',
  },
  {
    ghModel: 'K6776-CB6776',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFK-637',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFK-638',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFY-245',
  },
  {
    ghModel: 'T5833',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFY-245',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFY-445',
  },
  {
    ghModel: 'T5833',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EFY-445',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EOD-637',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'EOJ-445',
  },
  {
    ghModel: '9612',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SAJ-1235',
  },
  {
    ghModel: 'T9612',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SAJ-1235',
  },
  {
    ghModel: '9108',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SAJ-835',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SAJ-835',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SDF-445',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SDF-635',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SFJ-445',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Pottorff',
    compModel: 'SFJ-645',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'ECV-545',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFD-245',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFD-435',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFJ-245',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFJ-430',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFJ-437',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFJ-445',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFJ-645',
  },
  {
    ghModel: 'EHV-901',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFJ-937',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Pottorff',
    compModel: 'EFK-638',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'A42',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD20R',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-250',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-250',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-250',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-250',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-250',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-250',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50B',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50B',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50C',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-50C',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-52',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-52B',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-52C',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD54',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD54',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD54B',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD54B',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD54C',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD54C',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-58',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-58',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-58B',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-58B',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-58C',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-58C',
  },
  {
    ghModel: 'WD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-60',
  },
  {
    ghModel: 'WD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-60',
  },
  {
    ghModel: 'WD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-60B',
  },
  {
    ghModel: 'WD-320',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-60B',
  },
  {
    ghModel: 'WD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-60C',
  },
  {
    ghModel: 'WD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD-60C',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD64',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD64',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD64B',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD64B',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD64C',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD64C',
  },
  {
    ghModel: 'HB-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BD84',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'BVF',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CAD',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-150',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CBD-250',
  },
  {
    ghModel: 'MBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-10',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-10R',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-21R',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-21R',
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-22R',
  },
  {
    ghModel: 'VCDRM-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-22R',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-25R',
  },
  {
    ghModel: 'FBH-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-41',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-41',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-41',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-41',
  },
  {
    ghModel: 'VCD-20V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-41',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-41',
  },
  {
    ghModel: 'VCD-23V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-41',
  },
  {
    ghModel: 'FBV-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-42',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-42',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-42',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-42',
  },
  {
    ghModel: 'VCD-20V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-42',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-42',
  },
  {
    ghModel: 'VCD-23V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-42',
  },
  {
    ghModel: 'FBH-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-45',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-45',
  },
  {
    ghModel: 'VCD-33V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-45',
  },
  {
    ghModel: 'FBV-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-46',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-46',
  },
  {
    ghModel: 'VCD-33V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-46',
  },
  {
    ghModel: 'VCD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-51',
  },
  {
    ghModel: 'VCD-42V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-51',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-51-IB',
  },
  {
    ghModel: 'VCD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-52',
  },
  {
    ghModel: 'VCD-42V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-52',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-53',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-54',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD81',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD81R',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD82',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD83',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD84',
  },
  {
    ghModel: 'HCD-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-91',
  },
  {
    ghModel: 'HCD-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-92',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-93',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CD-94',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CFD-15',
  },
  {
    ghModel: 'CRD-1LP',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CFD-15-LP',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CFD-15R',
  },
  {
    ghModel: 'CRD-60',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CFD-20',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CFD-521',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CFD-521-BT',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'CFD-521-IP',
  },
  {
    ghModel: 'RAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'DMR',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EAA-845',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EAJ-637',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EBE-445',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EBI-445',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EBV-145',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EBV-445',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECD-245',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECD-445',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECD-545-MD',
  },
  {
    ghModel: 'EHH-601DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECD-545-MD',
  },
  {
    ghModel: 'EHH-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECD-635',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECD-745',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECV-245',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ECV-645-MD',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EDD-445',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EDD-637',
  },
  {
    ghModel: 'ESID-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EEI-430',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-437',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-445 ',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-637',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-637',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-637-MD',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-645',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-437-HP',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-607-HP',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-637-MD',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFK-430',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFK-437',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFK-445',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFK-637',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFY-245',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EFY-445 ',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EOD-445',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EOD-637',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EOJ-445',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EOJ-637',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'ERD-645',
  },
  {
    ghModel: 'FSL-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EVS-422',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EXA-645',
  },
  {
    ghModel: 'EACA-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EXA-645-MD',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'EXD-645',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FD-125R',
  },
  {
    ghModel: 'SSFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FD-125R(SS)',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FD-140',
  },
  {
    ghModel: 'SSDFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FD-140(SS)',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FD-150',
  },
  {
    ghModel: 'DFD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FD-340',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-125R',
  },
  {
    ghModel: 'SSFSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-125R(SS)',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-141',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-141-OP',
  },
  {
    ghModel: 'GFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-141-SP',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-142',
  },
  {
    ghModel: 'FSD-212M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-142',
  },
  {
    ghModel: 'SSFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-142(SS)',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-142-OP',
  },
  {
    ghModel: 'GFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-142-SP',
  },
  {
    ghModel: 'FSD-213',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-143',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-151',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-151',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-151-OP',
  },
  {
    ghModel: 'FSD-311V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-151-V',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-152',
  },
  {
    ghModel: 'FSD-312M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-152',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-152-OP',
  },
  {
    ghModel: 'CFSD-211-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-171',
  },
  {
    ghModel: 'CFSD-211-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-171',
  },
  {
    ghModel: 'CFSD-211-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-171',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-172',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-172',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-172',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-341',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'FSD-351',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'HAD',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'HJ-445',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'MD-41',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'MD-42',
  },
  {
    ghModel: 'VRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'NPR-BI',
  },
  {
    ghModel: 'PRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'PPR-BO',
  },
  {
    ghModel: 'RBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'RD-10',
  },
  {
    ghModel: 'RBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'RD-10R',
  },
  {
    ghModel: 'AFS-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SAJ-1235',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SAJ-835',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-141',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-141(SS)',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-142',
  },
  {
    ghModel: 'SMD-202M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-142',
  },
  {
    ghModel: 'SMD-203',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-143',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-151',
  },
  {
    ghModel: 'SMD-301M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-151',
  },
  {
    ghModel: 'SMD-301V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-151-V',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-152',
  },
  {
    ghModel: 'SMD-302M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-152',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-25R',
  },
  {
    ghModel: 'SSSMDR-502',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SD-25R(SS)',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SDF-445',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SDF-635',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SFD-445',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SFD-635',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SFJ-445',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'SFJ-645',
  },
  {
    ghModel: 'HTD-640',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'TD-93',
  },
  {
    ghModel: 'HTD-640',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'TD-94',
  },
  {
    ghModel: 'SSDFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10(SS)',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10(SS)',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10-A',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10A(SS)',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10-B',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10B(SS)',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10B-IMB',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10C(SS)',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-A',
  },
  {
    ghModel: 'SSDFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-A(SS)',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-B',
  },
  {
    ghModel: 'SSDFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-B(SS)',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-C',
  },
  {
    ghModel: 'SSDFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-C(SS)',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-IMA',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-IMA',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-IMB',
  },
  {
    ghModel: 'DFD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-TLA',
  },
  {
    ghModel: 'DFD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10D-TLB',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10-IMA',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10-IMB',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-10-TLA',
  },
  {
    ghModel: 'FDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-15R',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30-A',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30-B',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30-C',
  },
  {
    ghModel: 'DFD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30D-A',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30D-A',
  },
  {
    ghModel: 'DFD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30D-B',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30D-B',
  },
  {
    ghModel: 'DFD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30D-C',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFD-30D-C',
  },
  {
    ghModel: 'SSDFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'VFDR-15R(SS)',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Pottorff',
    compModel: 'XAV-545',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'ECD-545',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'ECV-545',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-245',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFD-435',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-245',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-430',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-437',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-445',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFJ-645',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Pottorff',
    compModel: 'EFK-638',
  },
  {
    ghModel: 'XG-SRP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '10',
  },
  {
    ghModel: 'XG-H4002S-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '21',
  },
  {
    ghModel: 'XG-V4002S-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '21',
  },
  {
    ghModel: 'XG-H4004S-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '22',
  },
  {
    ghModel: 'XG-V4004S-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '22',
  },
  {
    ghModel: 'XG-CC5',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '80',
  },
  {
    ghModel: 'XG-CC15',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '81',
  },
  {
    ghModel: 'XG-CC1',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '82',
  },
  {
    ghModel: 'XG-CC45',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '85',
  },
  {
    ghModel: 'XG-4500S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '90',
  },
  {
    ghModel: 'XG-4538S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '91',
  },
  {
    ghModel: 'XG-4500',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '97',
  },
  {
    ghModel: 'XG-4538',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '98',
  },
  {
    ghModel: 'XG-SRH',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '530',
  },
  {
    ghModel: 'XG-V4002SM',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '540',
  },
  {
    ghModel: 'XG-RH',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '630',
  },
  {
    ghModel: 'XG-RHE',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '630',
  },
  {
    ghModel: 'XG-V4002M',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '640',
  },
  {
    ghModel: 'XG-RHSS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '730',
  },
  {
    ghModel: 'XG-4502S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '910',
  },
  {
    ghModel: 'XG-4504S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '920',
  },
  {
    ghModel: 'XG-RP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '10A',
  },
  {
    ghModel: 'XG-RPF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '10AFF',
  },
  {
    ghModel: 'XG-RPFSS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '10AFF',
  },
  {
    ghModel: 'XG-SRPF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '10FF',
  },
  {
    ghModel: 'XG-PRTBFRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '10-FR',
  },
  {
    ghModel: 'XG-RPSS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '10SS',
  },
  {
    ghModel: 'XG-H4152',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '151L',
  },
  {
    ghModel: 'XG-V4152',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '151S',
  },
  {
    ghModel: 'XG-H4154',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '152L',
  },
  {
    ghModel: 'XG-V4154',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '152S',
  },
  {
    ghModel: 'XG-H4252',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '200L',
  },
  {
    ghModel: 'XG-H4254',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '200L',
  },
  {
    ghModel: 'XG-V4252',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '200S',
  },
  {
    ghModel: 'XG-V4254',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '200S',
  },
  {
    ghModel: 'XG-H4002-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '21L',
  },
  {
    ghModel: 'XG-V4002-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '21S',
  },
  {
    ghModel: 'XG-H4004-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '22L',
  },
  {
    ghModel: 'XG-V4004-AF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '22S',
  },
  {
    ghModel: 'XG-H4352',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '301L',
  },
  {
    ghModel: 'XG-V4352',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '301S',
  },
  {
    ghModel: 'XG-H4354',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '302L',
  },
  {
    ghModel: 'XG-V4354',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '302S',
  },
  {
    ghModel: 'XG-H4002S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '510L',
  },
  {
    ghModel: 'XG-V4002S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '510S',
  },
  {
    ghModel: 'XG-H4002RS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '510Z',
  },
  {
    ghModel: 'XG-V4002RS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '510Z',
  },
  {
    ghModel: 'XG-H4004S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '520L',
  },
  {
    ghModel: 'XG-V4004S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '520S',
  },
  {
    ghModel: 'XG-SRHF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '530FF',
  },
  {
    ghModel: 'XG-H4002',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '610L',
  },
  {
    ghModel: 'XG-V4002',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '610S',
  },
  {
    ghModel: 'XG-H4002R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '610Z',
  },
  {
    ghModel: 'XG-V4002R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '610Z',
  },
  {
    ghModel: 'XG-H4004',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '620L',
  },
  {
    ghModel: 'XG-V4004',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '620S',
  },
  {
    ghModel: 'XG-RHEF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '630FF',
  },
  {
    ghModel: 'XG-RHF ',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '630FF',
  },
  {
    ghModel: 'XG-H4002SS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '710L',
  },
  {
    ghModel: 'XG-V4002SS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '710S',
  },
  {
    ghModel: 'XG-H4002RSS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '710Z',
  },
  {
    ghModel: 'XG-V4002RSS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '710Z',
  },
  {
    ghModel: 'XG-H4004SS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '720L',
  },
  {
    ghModel: 'XG-V4004SS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '720S',
  },
  {
    ghModel: 'XG-RHFSS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '730FF',
  },
  {
    ghModel: 'XG-CC5F',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '80FF',
  },
  {
    ghModel: 'XG-CC5FRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '80-FR',
  },
  {
    ghModel: 'XG-4500SF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '90FH',
  },
  {
    ghModel: 'XG-4538SF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: '91FH',
  },
  {
    ghModel: 'XG-L',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ACVD-1L',
  },
  {
    ghModel: 'XG-S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ACVD-1S',
  },
  {
    ghModel: 'XG-LTC',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ACVD-2CS/CL',
  },
  {
    ghModel: 'XG-LT',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ACVD-2L',
  },
  {
    ghModel: 'XG-ST',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ACVD-2S',
  },
  {
    ghModel: 'XG-LS3',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ACVD-3L',
  },
  {
    ghModel: 'XG-LS4',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ACVD-4S',
  },
  {
    ghModel: 'XG-9000',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'AMCD',
  },
  {
    ghModel: 'XG5500',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'AMD',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'AMDA',
  },
  {
    ghModel: 'XG-STBPF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'AMF',
  },
  {
    ghModel: 'XG-TBPF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'AMF',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'AMX',
  },
  {
    ghModel: 'XG-JA',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'AN',
  },
  {
    ghModel: 'XG-PRTB',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'APFRF',
  },
  {
    ghModel: 'XG-3000',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ARCD',
  },
  {
    ghModel: 'XG-DGCO',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ATG1/A',
  },
  {
    ghModel: 'XG-DGSF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ATG1/B',
  },
  {
    ghModel: 'XG-DGDF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ATG1/C',
  },
  {
    ghModel: 'XG-DGLP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'ATG2/B',
  },
  {
    ghModel: 'XG-AFL-10',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF 1"',
  },
  {
    ghModel: 'XG-AFL-15',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF 1.5"',
  },
  {
    ghModel: 'XG-AFL-20',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF 2"',
  },
  {
    ghModel: 'XG-AFL-25',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF 2.5"',
  },
  {
    ghModel: 'XG-AFL-30',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF 3"',
  },
  {
    ghModel: 'XG-AFLI',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF4-AS',
  },
  {
    ghModel: 'XG-AFLIR',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF4-RT',
  },
  {
    ghModel: 'XG-AFLC-10',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF-C 1"',
  },
  {
    ghModel: 'XG-AFLC-15',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF-C 1.5"',
  },
  {
    ghModel: 'XG-AFLC-20',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF-C 2"',
  },
  {
    ghModel: 'XG-AFLC-25',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF-C 2.5"',
  },
  {
    ghModel: 'XG-AFLC-30',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CF-C 3"',
  },
  {
    ghModel: 'XG-AFL-TS-10',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CFT 1"',
  },
  {
    ghModel: 'XG-AFL-TS-15',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CFT 1.5"',
  },
  {
    ghModel: 'XG-AFL-TS-20',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CFT 2"',
  },
  {
    ghModel: 'XG-DAF-CC5',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'CSRD',
  },
  {
    ghModel: 'XG-DD-CU',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'DF1C',
  },
  {
    ghModel: 'XG-DD-REC',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'DFE',
  },
  {
    ghModel: 'XG-DD-WALL-FM',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'DFR',
  },
  {
    ghModel: 'XG-DD-WALL-1W',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'DFW',
  },
  {
    ghModel: 'XG-DD-RD-180',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'DR180',
  },
  {
    ghModel: 'XG-DD-RD-360',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'DR360',
  },
  {
    ghModel: 'XG-DD-RD-90',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'DR90',
  },
  {
    ghModel: 'XG-G3',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'EG ',
  },
  {
    ghModel: 'XG-L9',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'EGL',
  },
  {
    ghModel: 'XG-HRD-CL',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'FRFD',
  },
  {
    ghModel: 'XG-HRD-GS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'FRFDC',
  },
  {
    ghModel: 'XG-RL',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'HCD1B',
  },
  {
    ghModel: 'XG-RL-DF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'HCD2B',
  },
  {
    ghModel: 'XG-RL-SP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'HCDSD1B',
  },
  {
    ghModel: 'XG-RL-SP-DF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'HCDSD2B',
  },
  {
    ghModel: 'XG-Periflow',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'HORD',
  },
  {
    ghModel: 'XG-MPK',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'JN',
  },
  {
    ghModel: 'XG-2000',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2100',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2200',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2300',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2400',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2500',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2600',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2700',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-2800',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LBP',
  },
  {
    ghModel: 'XG-5200',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LCMD',
  },
  {
    ghModel: 'XG-HPL-CA',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LFD',
  },
  {
    ghModel: 'XG-HPL-GSJ',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LFDC',
  },
  {
    ghModel: 'XG-DST-LT',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LTA ',
  },
  {
    ghModel: 'XG-SS-LT',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LTA10',
  },
  {
    ghModel: 'XG-DS-LT',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LTALP',
  },
  {
    ghModel: 'XG-SSR-LT',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LTR',
  },
  {
    ghModel: 'XG-LFL',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'LV',
  },
  {
    ghModel: 'XG-WFO',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MFD-DP/TP',
  },
  {
    ghModel: 'XG-SGDB',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSBG',
  },
  {
    ghModel: 'XG-SGRH',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSBL',
  },
  {
    ghModel: 'XG-SG5500',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSD',
  },
  {
    ghModel: 'XG-SGLF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSL',
  },
  {
    ghModel: 'XG-SGSP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSLG',
  },
  {
    ghModel: 'XG-SGRP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSPG',
  },
  {
    ghModel: 'XG-SGWM',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSSG',
  },
  {
    ghModel: 'XG-SG2000',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'MSSL',
  },
  {
    ghModel: 'XG-7600',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDC',
  },
  {
    ghModel: 'XG-7650',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDC',
  },
  {
    ghModel: 'XG-7500R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDDR',
  },
  {
    ghModel: 'XG-7550R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDDR',
  },
  {
    ghModel: 'XG-7600R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDDR',
  },
  {
    ghModel: 'XG-7650R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDDR',
  },
  {
    ghModel: 'XG-7500RFRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDDR-FR',
  },
  {
    ghModel: 'XG-7550RFRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDDR-FR',
  },
  {
    ghModel: 'XG-7500',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDF',
  },
  {
    ghModel: 'XG-7550',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDF',
  },
  {
    ghModel: 'XG-7500FRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDF-FR',
  },
  {
    ghModel: 'XG-7550FRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDF-FR',
  },
  {
    ghModel: 'XG-7900',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDMC ',
  },
  {
    ghModel: 'XG-7950',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDMC ',
  },
  {
    ghModel: 'XG-7600FRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDN-FR',
  },
  {
    ghModel: 'XG-7650FRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PDN-FR',
  },
  {
    ghModel: 'XG-SPRTB',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PFRF',
  },
  {
    ghModel: 'XG-7300',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PIB',
  },
  {
    ghModel: 'XG-7300R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PIB',
  },
  {
    ghModel: 'XG-MSE-HC-S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PPD-D Prodigy',
  },
  {
    ghModel: 'XG-MSE-HC-M',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'PPD-M Prodigy',
  },
  {
    ghModel: 'XG-NDRH',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RAC',
  },
  {
    ghModel: 'XG-3100S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RCDE',
  },
  {
    ghModel: 'XG-MREC',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RECG',
  },
  {
    ghModel: 'XG-3200',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RID',
  },
  {
    ghModel: 'XG-R5750',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RPD',
  },
  {
    ghModel: 'XG-MRPF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RPG',
  },
  {
    ghModel: 'XG-MRSD',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RSG ',
  },
  {
    ghModel: 'XG-MRSDW',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RSG  2"',
  },
  {
    ghModel: 'XG-MRDD',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RSGDD',
  },
  {
    ghModel: 'XG-MRDDW',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'RSGDD 2"',
  },
  {
    ghModel: 'XG-5700',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SCD',
  },
  {
    ghModel: 'XG-5800',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SCD-4C',
  },
  {
    ghModel: 'XG-5700A',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SCDA',
  },
  {
    ghModel: 'XG-5800A',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SCDA-4C',
  },
  {
    ghModel: 'XG-5700FRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SCD-FR',
  },
  {
    ghModel: 'XG-5800FRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SCD-FR 4th Cone',
  },
  {
    ghModel: 'XG-4004P',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDG AL',
  },
  {
    ghModel: 'XG-4004SP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDG GV',
  },
  {
    ghModel: 'XG-4004PCF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDGE-Double',
  },
  {
    ghModel: 'XG-4000PCFPFR',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDGER',
  },
  {
    ghModel: 'XG-4002PCF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDGE-Single',
  },
  {
    ghModel: 'XG-4000SPFR',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDGR GV',
  },
  {
    ghModel: 'XG-6610R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDR100',
  },
  {
    ghModel: 'XG-6610RSP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDR100 Type-16',
  },
  {
    ghModel: 'XG-6615R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDR150',
  },
  {
    ghModel: 'XG-6600SQR',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDR4',
  },
  {
    ghModel: 'XG-6650R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDR50',
  },
  {
    ghModel: 'XG-6675R',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDR75',
  },
  {
    ghModel: 'XG-6675RTZ',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDR75-Type-19',
  },
  {
    ghModel: 'XG-6610',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDS100',
  },
  {
    ghModel: 'XG-6610SP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDS100 Type-16',
  },
  {
    ghModel: 'XG-6615',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDS150',
  },
  {
    ghModel: 'XG-6600SQ',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDS4',
  },
  {
    ghModel: 'XG-6650',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDS50',
  },
  {
    ghModel: 'XG-6675',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDS75',
  },
  {
    ghModel: 'XG-6675TZ',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SDS75 Type-19',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SMD ',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SMDA',
  },
  {
    ghModel: 'XG-5500SFRS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SMD-FR',
  },
  {
    ghModel: 'XG-5500DD',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SMDP',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SMX',
  },
  {
    ghModel: 'XG-5750',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SPD',
  },
  {
    ghModel: 'XG-Phenomenator',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SPDHI',
  },
  {
    ghModel: 'XG-PF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SPF',
  },
  {
    ghModel: 'XG-Phenomenator-LF',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SPJD',
  },
  {
    ghModel: 'XG-TR',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SR',
  },
  {
    ghModel: 'XG-TRDeep',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'SR',
  },
  {
    ghModel: 'XG-PHCS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBD',
  },
  {
    ghModel: 'XG-PHPS-IT',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBD3',
  },
  {
    ghModel: 'XG-PHPS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBD4',
  },
  {
    ghModel: 'XG-PHSP-J',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBD7',
  },
  {
    ghModel: 'XG-PHPSI-IT',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBDI3',
  },
  {
    ghModel: 'XG-PHPSI',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBDI4',
  },
  {
    ghModel: 'XG-MLAD',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBDI6-HC',
  },
  {
    ghModel: 'XG-PHSPI-J',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBDI7',
  },
  {
    ghModel: 'XG-PHCR',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBDR',
  },
  {
    ghModel: 'XG-PHCR-DB',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBDV',
  },
  {
    ghModel: 'XG-PHPR ',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBR',
  },
  {
    ghModel: 'XG-PHPRI',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'TBRI',
  },
  {
    ghModel: 'XG-UP',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'UP',
  },
  {
    ghModel: 'XG-UPI',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'UP w/ Insulation',
  },
  {
    ghModel: 'XG-OBD',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'VC33',
  },
  {
    ghModel: 'XG-BDS',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'VCR8',
  },
  {
    ghModel: 'XG-RSD',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'VCR9',
  },
  {
    ghModel: 'XG-OBDA',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'VCS3AL',
  },
  {
    ghModel: 'XG-MSCVH',
    trademark: ['Greenheck'],
    compName: 'PriceIndustries',
    compModel: 'VPD - VariTherm',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '1245RAAZ',
  },
  {
    ghModel: 'AC155',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '15045RZ',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '245RB',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '245RD',
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '2DDWRG',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '2RCV',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '3RRGV',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4375B',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4375D',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4375D125',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4375FD',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4375FDABL',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4375Z/4375Z125',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445DD',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445FB',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RBABL',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RBABL125',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RBED',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RBI',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RBID',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RD',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RDABL',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '445RDHBD',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4DDWRG',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '4RCH',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '5DDWRG',
  },
  {
    ghModel: 'AFG501',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '5FHDA',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375D/6375D',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375DC',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375DC',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375DFL',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375DFL',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375DHAXDC',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375FD',
  },
  {
    ghModel: 'T6786',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375RDABL',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375RDHAX',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6375Z/6375Z125',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '6425DD',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '645DD',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '645FB',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '645RAAB',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '645RB',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '645RBABL',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '645RBABL125',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '845FBAF',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '845RAAZ',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: '8DDWRG',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: 'AEL-30',
  },
  {
    ghModel: 'AC153S',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: 'AEL-30',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: 'AEL-42',
  },
  {
    ghModel: 'AC154S',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: 'AEL-42',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'Reliable',
    compModel: 'RBV7020-4',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '245RB',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '245RD',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '2DDWRG',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '2RCV',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '3RRGV',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '4375B',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '4375D',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '4375FD',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '4375FDABL',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '5DDWRG',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '5FHDA',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375D',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375DC',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375DC',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375DFL',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375DFL',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375DHAXDC',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375FD',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '6375RDHAX',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '645RAAB',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '645RB',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '645RBABL',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'Reliable',
    compModel: '645RBABL125',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '1245RAAZ',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '15045Rz',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '15045Rz',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '4375Z',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445DD',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445FB',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445RBABL',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445RBED',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445RBI',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445RBID',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445RD',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445RDABL',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '445RDHBD',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '4DDWRG',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '4RCH',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '6375RBABL',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '6375RDABL',
  },
  {
    ghModel: 'ESD-603D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '6375RDD',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '6375RDHBD',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '6375Z',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '6375Z125',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '6425DD',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '645DD',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '645DD',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '645FB',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '645RAAZ',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '845FBAF',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '845RAAZ',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: '8DDWRG',
  },
  {
    ghModel: 'ESU-130S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'AEL-30',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'AEL-30',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'AEL-30',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'AEL-42',
  },
  {
    ghModel: 'ESU-154S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'AEL-42',
  },
  {
    ghModel: 'ESU-150S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'AEL-45',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'BVF',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'RBV7020-4',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'RPH',
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Reliable',
    compModel: 'RPH',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '245D',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '245RB',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '4375B',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '4375D',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '445RDABL',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '445RDHBD',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '5DDRR',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '6375RD',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '6375RDHAF',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'Reliable',
    compModel: '645RB',
  },
  {
    ghModel: 'MINIC',
    trademark: ['Greenheck', 'Venco'],
    compName: 'RenewAire',
    compModel: 'EV450',
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck', 'Venco'],
    compName: 'RenewAire',
    compModel: 'HE1X',
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck', 'Venco'],
    compName: 'RenewAire',
    compModel: 'HE2X',
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck', 'Venco'],
    compName: 'RenewAire',
    compModel: 'HE3X',
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck', 'Venco'],
    compName: 'RenewAire',
    compModel: 'HE4X',
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'B',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'BB',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'BB',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'BK',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'BK',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'CF',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'CFS',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'EB',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'FL/FLH',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'LS',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QB',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QB',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QB-BC',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QB-BC',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QB-LT',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QCF Series',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QCF Series',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QK',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'QK',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'RS',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'Reversomatic',
    compModel: 'TLD',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'ADF',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'ADF',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'ADF',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'ADFH',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'ADFH',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'DFCH',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'DFCV',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'HRPB',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'HRPB',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'PDH',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'PDH',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'PDH',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'PEH',
  },
  {
    ghModel: 'KSFB',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RBA',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RBA',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RBHA',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RBL',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RDF',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RDH',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RDH',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RDH',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'REC',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'REH',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RPB',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RPB',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RPB',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Reznor',
    compModel: 'RPBL',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Rite-Hite',
    compModel: 'Revolution',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Rite-Hite',
    compModel: 'Revolution 150',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'Rite-Hite',
    compModel: 'Revolution 25',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Rite-Hite',
    compModel: 'Revolution 75',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Rite-Hite',
    compModel: 'Revolution',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Rite-Hite',
    compModel: 'Revolution 150',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'Rite-Hite',
    compModel: 'Revolution 25',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Rite-Hite',
    compModel: 'Revolution 75',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'CFA-M-single blower',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'CFA-M-twin blower',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'CFA-single blower',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'CFA-twin blower',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'HVLS-RA',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'MPX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'MPU',
  },
  {
    ghModel: 'KSF',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'NSA',
  },
  {
    ghModel: 'KSFB',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'NSA',
  },
  {
    ghModel: 'KSFD',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'NSA',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'NSA',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'R76',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RAM-M-single blower',
  },
  {
    ghModel: 'TSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RAM-M-single blower',
    filter: {
      category: 'MakeUpAir'
    }
  },
  {
    ghModel: 'TSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RAM-M-twin blower',
    filter: {
      category: 'MakeUpAir'
    }
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RAM-single blowers',
  },
  {
    ghModel: 'TSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RAM-single blowers',
    filter: {
      category: 'MakeUpAir'
    }
  },
  {
    ghModel: 'TSU',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RAM-twin blowers',
    filter: {
      category: 'MakeUpAir'
    }
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RD',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RD',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'RE',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'R-IBT',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'R-IBT',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'R-IBT',
  },
  {
    ghModel: 'KSF',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'SA-RA',
  },
  {
    ghModel: 'KSFB',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'SA-RA',
  },
  {
    ghModel: 'KSFD',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'SA-RA',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'SA-RA',
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'RuppAir',
    compModel: 'TICF-RA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'RuppAir',
    compModel: 'HVLS-RA',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ACL1245',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ACL645',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ACL845',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ACL845AF',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'BV100',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELBD812',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELBD813',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELC445D',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELC6375DAX',
  },
  {
    ghModel: 'K6746MD',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELC6375DXD',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELC6375DXW',
  },
  {
    ghModel: 'AC155',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF15J',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF211',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF211D',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF22V',
  },
  {
    ghModel: 'K6744X',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF375DFL',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF375DX',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF375DXH',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF375X',
  },
  {
    ghModel: 'K609HP',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF375XH',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF40V',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF40Y',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF445DX',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF445DXH',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF520DD',
  },
  {
    ghModel: 'K605D',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF520DD',
  },
  {
    ghModel: 'K6746X',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6375DFL',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6375DX',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6375DXD',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6375DXH',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6375X',
  },
  {
    ghModel: 'K6096HP',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6375XH',
  },
  {
    ghModel: 'K6856',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6425DD',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6811',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6811DD',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF6811S',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF811DD',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELF811SH',
  },
  {
    ghModel: 'T6786',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELM6375DX',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELM6DW',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELM811',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELM811D',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELM811H',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ELM90HFA',
  },
  {
    ghModel: 'SCH201',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME220DD',
  },
  {
    ghModel: 'SCV302',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME3625',
  },
  {
    ghModel: 'SCV302MD',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME3625DFLMD',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME420DD',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME520DD',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME520MD',
  },
  {
    ghModel: 'SCH601MD',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME520MD',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME520V',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME6625D',
  },
  {
    ghModel: 'SCH701',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'EME720',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ET125',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'ET125-30',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'GFL800',
  },
  {
    ghModel: 'SCC901',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'HZ850',
  },
  {
    ghModel: 'SCC901MD',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'HZ850',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L375D',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L375D',
  },
  {
    ghModel: 'ASL401',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L5361',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L6375D',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L6811',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L6811S',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L811',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'L811S',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'LM6375D',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'LV811',
  },
  {
    ghModel: 'K6746PD',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'PPHB637HDXD',
  },
  {
    ghModel: 'AFG501',
    trademark: ['Airolite'],
    compName: 'Ruskin',
    compModel: 'XP500',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELC445D',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELC6375DAX/ELC6375DXW',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF211',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF211D',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF375DX',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF375DXH',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF6350DMP',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF6375DX',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF6375DXH',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF6811/ELF6811S',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELF811SH/GFL800',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELM6375DX',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELM6DW',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'ELM811D',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'EME520DD',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'EME520MD',
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'EVT',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'EVT',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIC',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'MCV1000',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIC',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'MCV500',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIVENT',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'MV250',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIVENT',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'MV450',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MINIVENT',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'MV750',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'PHM',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'Ruskin',
    compModel: 'PHM',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'IMO-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'A-60',
  },
  {
    ghModel: 'IMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'A-60',
  },
  {
    ghModel: 'SSIMO-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'A-60(316SS)',
  },
  {
    ghModel: 'SSIMO-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'A-60(316SS)',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ACL1245',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ACL645',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ACL845',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ACL845AF',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ADC-22',
  },
  {
    ghModel: 'RAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ADF',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ADH-22',
  },
  {
    ghModel: 'RAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ADR',
  },
  {
    ghModel: 'AMS',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'AMS',
  },
  {
    ghModel: 'AMD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'AMS050',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A1',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A1',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A1',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A1',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A1',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A1',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD2/A2',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BD6',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BDR2',
  },
  {
    ghModel: 'SSWDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BDR2(SS)',
  },
  {
    ghModel: 'HBT-221',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BTO-92',
  },
  {
    ghModel: 'HBTR-151',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BTR92',
  },
  {
    ghModel: 'HBTR-451',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BTR92',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BV100',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BV100',
  },
  {
    ghModel: 'BVC',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'BVC100',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD2',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD4',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBD6',
  },
  {
    ghModel: 'HBR-050',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBDR82',
  },
  {
    ghModel: 'HB-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBS7',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBS8',
  },
  {
    ghModel: 'HBS-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBS8BL',
  },
  {
    ghModel: 'HBS-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBS8BL',
  },
  {
    ghModel: 'HBS-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBS8BL',
  },
  {
    ghModel: 'HBS-431',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBS8BL',
  },
  {
    ghModel: 'HB-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CBS92',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD102',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD30AF1',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD30AF2',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD30VG1',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD30VG1',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD35',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD35',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD355',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD355',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD356',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD356',
  },
  {
    ghModel: 'FBH-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD36',
  },
  {
    ghModel: 'FBV-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD36',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD36',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD36',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD40',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD40',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD40',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD403',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD44',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD45',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD46',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD50',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD50',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD50',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD504',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD504',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD504',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD50L',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD50L',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD50L',
  },
  {
    ghModel: 'VCD-43V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD50V',
  },
  {
    ghModel: 'VCD-43V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD-50V',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD51',
  },
  {
    ghModel: 'FBH-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD60',
  },
  {
    ghModel: 'FBV-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD60',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD60',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD80AF1',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD80AF2',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD80AF3',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD80AF4',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD80VG1',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD80VG2',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD80VGX',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CD82HT',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDR25',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDR25',
  },
  {
    ghModel: 'HCDR-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDR82',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDR82',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDR92',
  },
  {
    ghModel: 'HCDR-351',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDRI92',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDRS15',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDRS15',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDRS25',
  },
  {
    ghModel: 'HCDR-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDRS82',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDTI-50',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CDTI-50BF',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD2',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD3',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD3.5',
  },
  {
    ghModel: 'CRD-1LP',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD4',
  },
  {
    ghModel: 'CRD-1LP',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD4W',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD5',
  },
  {
    ghModel: 'CRD-1WJ',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD7',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD7T',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD7T-IB6',
  },
  {
    ghModel: 'CRD-60',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFD8',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFDR2',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFDR2W',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFDR3',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFDR3.5',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFDR3W',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'CFDR5',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DFD35',
  },
  {
    ghModel: 'SEDFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DFD35SS',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DFD60',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DFD60-3',
  },
  {
    ghModel: 'CRD-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DFSDR1',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD2',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD20',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD20G',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD23',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD230',
  },
  {
    ghModel: 'SSDFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD23SS',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD2-D',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD2GA',
  },
  {
    ghModel: 'ODFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD2OW',
  },
  {
    ghModel: 'SSDFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD2SS',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD40',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD430',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD60',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'DIBD630',
  },
  {
    ghModel: 'AMD-42-TD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EAMS060',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELBD812',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELBD813',
  },
  {
    ghModel: 'EACA-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELC6375DXD',
  },
  {
    ghModel: 'ESD-635D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELC6375DXD',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF15J',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF15J',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF22V',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF375DFL',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF375X',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF375XH',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF40V/ELF40Y',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF445DX',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF445DXH ',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF520DD',
  },
  {
    ghModel: 'ESD-634X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6375DFL',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6375DFL',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6375DXD',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6375X',
  },
  {
    ghModel: 'EDJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6375XH',
  },
  {
    ghModel: 'EHM-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6425DD',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6811DD',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF811DD',
  },
  {
    ghModel: 'EDK-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF81S30',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELF81S30',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELM6375DX',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELM6375DX/ELM6DW',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELM6375DX/ELM6DW',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELM811/ELM811H',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ELM90HFA',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME220DD',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME3625',
  },
  {
    ghModel: 'EVH-302D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME3625DFLMD',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME420DD',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME520MD',
  },
  {
    ghModel: 'EHH-601DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME520MD',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME520V',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME5625MD/EME6625D',
  },
  {
    ghModel: 'EVH-602D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME6625D',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME720',
  },
  {
    ghModel: 'EHH-701',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EME745',
  },
  {
    ghModel: 'ESU-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ET125',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ET125',
  },
  {
    ghModel: 'ESU-130',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ET125-30',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ET125-30',
  },
  {
    ghModel: 'EES-T200V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'EV300',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FD35G',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FD60G',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FDR-25',
  },
  {
    ghModel: 'FDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FDR-25',
  },
  {
    ghModel: 'FSD-213',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD35',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD350OW',
  },
  {
    ghModel: 'FSD-213',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD35G',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD36',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD36C',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD36C',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD36C',
  },
  {
    ghModel: 'GFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD36FA',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD36G',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD36OW',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD37',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD37G',
  },
  {
    ghModel: 'SSFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD37SS',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60-2',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60-2GA',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60-2OW',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60-3',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60G',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60GA',
  },
  {
    ghModel: 'FSD-311M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60M',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60-OW',
  },
  {
    ghModel: 'FSD-311V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60V',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSD60-VG',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSDR-25',
  },
  {
    ghModel: 'FSDR-512',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'FSDR-25',
  },
  {
    ghModel: 'EHV-901D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'HZ850',
  },
  {
    ghModel: 'IAQ-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IAQ50X',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD2',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD20',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD20G',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD23',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD2-D',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD2G',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD2GA',
  },
  {
    ghModel: 'OFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD2OW',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD2SS',
  },
  {
    ghModel: 'SSFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD2SSG',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD40',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBD60',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBDT1',
  },
  {
    ghModel: 'FD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBDT1',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBDT2',
  },
  {
    ghModel: 'FD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IBDT2',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IL35',
  },
  {
    ghModel: 'VCD-34V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'IL35',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'L375D',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'L375D',
  },
  {
    ghModel: 'FSL-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'L5361',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'L6375D',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'L6811/L6811S',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'L811/L811S',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'LM375D ',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'LM6375D',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'LV811/EV811',
  },
  {
    ghModel: 'MBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'MD15',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'MD15',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'MD25',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'MDRS25',
  },
  {
    ghModel: 'VRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'NRD18',
  },
  {
    ghModel: 'HPR-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'PDR92',
  },
  {
    ghModel: 'PEV-400',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'PHB4SEV',
  },
  {
    ghModel: 'ESD-635PD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'PHB637HDXD',
  },
  {
    ghModel: 'PRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'PRD18',
  },
  {
    ghModel: 'WD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-200',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-320',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-400',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-410',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-420',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'WD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'S3G',
  },
  {
    ghModel: 'HSD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD102',
  },
  {
    ghModel: 'SMD-203',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD35',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD35SS',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD36',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD36SS',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD37',
  },
  {
    ghModel: 'SESMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD37SS',
  },
  {
    ghModel: 'SSSMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD37SS',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD50',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD60',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD60-2',
  },
  {
    ghModel: 'SMD-301M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD60M',
  },
  {
    ghModel: 'SMD-301V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SD60V',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SDRS25',
  },
  {
    ghModel: 'SMDR-502',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SDRS25',
  },
  {
    ghModel: 'SSSMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'SDRS25SS',
  },
  {
    ghModel: 'HTD-640',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'TD102',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'TED50',
  },
  {
    ghModel: 'HTD-630',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'TFD80',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'XP500',
  },
  {
    ghModel: 'RBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ZPD15',
  },
  {
    ghModel: 'RBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Ruskin',
    compModel: 'ZPD25',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELC445D',
  },
  {
    ghModel: 'VACC-6',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELC6375DAX',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF211D',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF221',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF375DX',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF375DXH',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6350DMP',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6375DX',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6375DXH',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF6811 ',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELF837',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELM6375DX',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'ELM811D',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'EME520DD',
  },
  {
    ghModel: 'VLPI',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'PH811(S)',
  },
  {
    ghModel: 'VLPR',
    trademark: ['Venco'],
    compName: 'Ruskin',
    compModel: 'PH811(S)',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'A-DBE-06',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'A-DWE-04',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'A-DWF-04',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'A-DWF-06',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'AEN-04',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'AEO-04',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'A-LEB-04',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'A-LEB-06',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'BEX-04',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'C-DWE-44',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'C-DWE-46',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'C-DWE-AF',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'DBE-04',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'D-DBE-04',
  },
  {
    ghModel: 'K6846',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'D-DBE-06',
  },
  {
    ghModel: ' K605D',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'DEM-06',
  },
  {
    ghModel: 'K605',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'DEM-06',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'DWE-04',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'DWE-06',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'DWF-04',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'DWF-06',
  },
  {
    ghModel: 'K6746MDE',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'HDL-06',
  },
  {
    ghModel: 'ENCB609',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'IEC-04',
  },
  {
    ghModel: 'AC155',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LEC-01',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LEC-04',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LEC-06',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LED-04',
  },
  {
    ghModel: 'AC153',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LES-01',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LEY-03',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LEY-04',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LFC-04',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'LFC-06',
  },
  {
    ghModel: 'T9106',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'UEB-06',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'UFA-08',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'UFC-08',
  },
  {
    ghModel: 'T9112',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'UFC-12',
  },
  {
    ghModel: 'T9612',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'UFD-12',
  },
  {
    ghModel: 'SCH501X',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'SafeAir',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'SafeAir',
    compModel: '2215',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'SafeAir',
    compModel: '2411',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'SafeAir',
    compModel: '2414',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'SafeAir',
    compModel: '2611',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'SafeAir',
    compModel: '2614',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'SafeAir',
    compModel: 'A-DBE-06',
  },
  {
    ghModel: 'EVH-501',
    trademark: ['Greenheck'],
    compName: 'SafeAir',
    compModel: 'V-DEM-05',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '420',
  },
  {
    ghModel: 'CFSD-211-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '471',
  },
  {
    ghModel: 'CFSD-211-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '471',
  },
  {
    ghModel: 'CFSD-211-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '471',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '472',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '472',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '472',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '600',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '604',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '610',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '610',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '610',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '610',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '611',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '611',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '611',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '611',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '611',
  },
  {
    ghModel: 'FBV-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '613',
  },
  {
    ghModel: 'FBH-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '615',
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '616',
  },
  {
    ghModel: 'VCDRM-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '616',
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '617',
  },
  {
    ghModel: 'VCDRM-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '617',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '621',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '622',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '630',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '631',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '650',
  },
  {
    ghModel: 'VCD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '650',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '662',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '681',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '682',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '771',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '772',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '781',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '782',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '2412',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '2415',
  },
  {
    ghModel: 'ESD-603',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '2615',
  },
  {
    ghModel: 'ESID-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '9417',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '9418',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '9490',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-12A',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-12B',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-12CRD',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-12OV',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-12RC',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-140V',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-14A',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-14B',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-14CRD',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-14RC',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-16A',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-16B',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-16CRD',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-16OV',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150-16RC',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150A',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150B',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150C-OV/RD',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150C-RC',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '150D-RC',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '151A',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '152A',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '300A',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '300B',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '300C-OV/RD',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '300C-RC',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '300D-OV/RD',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '420R',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '610R',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '610R',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '612R',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '771-3',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '771OP',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '772OP',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: '781-3',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AAM-1',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AAM-2',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AAM-3',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AAO-1',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AAO-2',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AAO-3',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'A-DWE-04',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'A-DWF-04',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'A-DWF-06',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AEN-04',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'AEO-04',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'A-LEB-04',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'A-LEB-06',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'ARM-1',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'ARM-2',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'ARM-3',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'ARO-1',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'ARO-2',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'ARO-3',
  },
  {
    ghModel: 'MBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BDD',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BDD',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BDR',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BEX-04',
  },
  {
    ghModel: 'BR-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'BR-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'BRL',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'C-DWE-44',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'C-DWE-46',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'C-DWE-AF',
  },
  {
    ghModel: 'ESID-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'C-LEB-04',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-12A',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-12B',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-12CRD',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-12OV',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-12RC',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-14A',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-14B',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-14CRD',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-14OV',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-14RC',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-16A',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-16B',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-16CRD',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-16OV',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150-16RC',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150A',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150B',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150C-OV/RD',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-150C-RC',
  },
  {
    ghModel: 'DFDTF-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-175-3',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-300A',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-300B',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-300C-OV/RD',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'DBE-04',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-DBE-04',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'D-DBE-06',
  },
  {
    ghModel: 'SED-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'DEM-06',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'DWE-04',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'DWE-06',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'DWF-04',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'DWF-06',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'HDL-06',
  },
  {
    ghModel: 'HCDR-351',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'HTR-1',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'HTR-2',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'HTR-3',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'IEC-04',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LEB-04',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LEC-01',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LEC-04',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LEC-06',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LED-04',
  },
  {
    ghModel: 'ESU-130S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LER',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LER',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LES-01',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LEY-03',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LEY-04',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LFC-04',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'LFC-06',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'M-Series',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'M-Series',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'PEV-400',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'P-LEC-04-XDOT-D',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'P-Series',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'P-Series',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'RAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'ROUND',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SAC',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SAH',
  },
  {
    ghModel: 'HB-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SH1',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SH2',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SHM',
  },
  {
    ghModel: 'VRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SNH',
  },
  {
    ghModel: 'PRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'SPH',
  },
  {
    ghModel: 'AFJ-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'UEB-06',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'UFA-08',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'UFC-08',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'UFC-12',
  },
  {
    ghModel: 'AFS-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'UFD-12',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'WDL-04',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'SafeAir',
    compModel: 'WDL-06',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'SafeAir',
    compModel: '2215',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'SafeAir',
    compModel: '2411',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'SafeAir',
    compModel: '2414',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'SafeAir',
    compModel: '2611',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'SafeAir',
    compModel: '2614',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'SafeAir',
    compModel: 'A-DBE-06',
  },
  {
    ghModel: 'VAVH-5',
    trademark: ['Venco'],
    compName: 'SafeAir',
    compModel: 'V-DEM-05',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Serco',
    compModel: 'SIF',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Serco',
    compModel: 'SIF',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'D3',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'EPPLER',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'FANTOM',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'MINIPROP',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'SHOPPROP',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'ST3',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'STOL',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'Skyblade',
    compModel: 'TURBOPROP',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'D3',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'EPPLER',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'FANTOM',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'MINIPROP',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'SHOPPROP',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'ST3',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'STOL',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'Skyblade',
    compModel: 'TURBOPROP',
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'BIL',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'RSF',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'C/DAFH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RSFP',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'C/DAFV',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'ICO',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CBP',
  },
  {
    ghModel: 'SFD',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CEB',
  },
  {
    ghModel: 'SFB',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CET',
  },
  {
    ghModel: 'SP',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CFP',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'CSP',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CFP 500-900 Series',
    filter: {
      category: 'Ceiling'
    }
  },
  {
    ghModel: 'SQ',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CLD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'BSQ',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CLT',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CM',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CMP',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CRH-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CRH-T',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CRV-D',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CRV-T',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CW',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CRW-D',
  },
  {
    ghModel: 'CWB',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'CRW-T',
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'DDT',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AXIAL-M100-QD',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'FUTURE-100',
  },
  {
    ghModel: 'AXIAL-M120-QD',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'FUTURE-120',
  },
  {
    ghModel: 'AXIAL-M150-QD',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'FUTURE-150',
  },
  {
    ghModel: 'RBE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HAI',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HAI',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HAM/HAT-B',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HAM/HAT-B',
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HIT-B',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HIT-B',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HIT-T',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HXA-D',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SCS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HXA-D',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HXA-T',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SBCS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HXA-T',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HXB-T',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'SS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HXB-T',
    filter: {
      category: 'Wall mounted'
    }
  },
  {
    ghModel: 'RBCE',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HZD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBCS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'HZD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'MAC',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'PBB',
  },
  {
    ghModel: 'RDU',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'RXD',
  },
  {
    ghModel: 'RBU',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'RXT',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'RBUMO',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'RXTC',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TDB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TDB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VTIC',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TDB',
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TGT',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'GJX',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TJFU',
  },
  {
    ghModel: 'MINIC',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TR300',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TRC1600',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TRC500',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TRC800',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ECV',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TRCe500',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TUB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VTIC',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TUB',
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TUB-HT',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TUB-HT',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VTIC',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TUB-HT',
  },
  {
    ghModel: 'VTIF',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'TUB-HT',
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'SolerPalau',
    compModel: 'VP-BI',
  },
  {
    ghModel: 'VTC',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'BIL',
  },
  {
    ghModel: 'VSF',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'C/DAFH',
  },
  {
    ghModel: 'VSFP',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'C/DAFV',
  },
  {
    ghModel: 'VFCD',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CEB',
  },
  {
    ghModel: 'VFCB',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CET',
  },
  {
    ghModel: 'VQ',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CFP',
  },
  {
    ghModel: 'VQI',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CFP 500-900 Series',
  },
  {
    ghModel: 'VICD',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CLD',
  },
  {
    ghModel: 'VICB',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CLT',
  },
  {
    ghModel: 'VBIB',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CM',
  },
  {
    ghModel: 'VECD',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CRH-D',
  },
  {
    ghModel: 'VECB',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CRH-T',
  },
  {
    ghModel: 'VUCD',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CRV-D',
  },
  {
    ghModel: 'VUCB',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CRV-T',
  },
  {
    ghModel: 'VWCD',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CRW-D',
  },
  {
    ghModel: 'VWCB',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'CRW-T',
  },
  {
    ghModel: 'VRBE',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HAI',
  },
  {
    ghModel: 'VRBS',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HAI',
  },
  {
    ghModel: 'VRDE',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HAM/HAT-B',
  },
  {
    ghModel: 'VRDS',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HAM/HAT-B',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HIT-B',
  },
  {
    ghModel: 'VWBS',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HIT-B',
  },
  {
    ghModel: 'VWBE',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HIT-T',
  },
  {
    ghModel: 'VWBCE',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HXA-T',
  },
  {
    ghModel: 'VWBCS',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HXA-T',
  },
  {
    ghModel: 'VWE',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HXB-T',
  },
  {
    ghModel: 'VWS',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HXB-T',
  },
  {
    ghModel: 'VRBCE',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HZD',
  },
  {
    ghModel: 'VRBCS',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'HZD',
  },
  {
    ghModel: 'VRDU',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'RXD',
  },
  {
    ghModel: 'VRBU',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'RXT',
  },
  {
    ghModel: 'VRBUO',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'RXTC',
  },
  {
    ghModel: 'VAX',
    trademark: ['Venco'],
    compName: 'SolerPalau',
    compModel: 'TGT',
  },
  {
    ghModel: 'KSFB',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'AH',
  },
  {
    ghModel: 'MSX',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'AH',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'PV',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'PV',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'PV',
  },
  {
    ghModel: 'IG',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'RT',
  },
  {
    ghModel: 'IGK',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'RT',
  },
  {
    ghModel: 'IGX',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'RT',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDFE',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDFE',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDFI',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDFW',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDFW',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDFW',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDR',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDR',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'Sterling',
    compModel: 'SDR',
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'StrobicAir',
    compModel: 'BS Series',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'StrobicAir',
    compModel: 'BS Series',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'StrobicAir',
    compModel: 'M Series',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-CH',
    trademark: ['Greenheck'],
    compName: 'StrobicAir',
    compModel: 'Mono-Stack',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'StrobicAir',
    compModel: 'Tri-Stack',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'StrobicAir',
    compModel: 'TS Series',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '501',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '501',
  },
  {
    ghModel: 'VCD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '501',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '501',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '701',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '702',
  },
  {
    ghModel: 'HB-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '710',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '810',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '901',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '902',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '905',
  },
  {
    ghModel: 'HCDR-351',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '910',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '50TI1',
  },
  {
    ghModel: 'HB-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '702BL',
  },
  {
    ghModel: 'HB-431',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '702BL',
  },
  {
    ghModel: 'HBS-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '702BL',
  },
  {
    ghModel: 'HBS-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '702BL',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '801V1',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '801V1',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '802HT',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '802V1',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '802V2',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '811A1',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '811A2',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '822A1',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '822A2',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '822A3',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '822A4',
  },
  {
    ghModel: 'HCD-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: '87X',
  },
  {
    ghModel: 'HPR-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: 'PDR703',
  },
  {
    ghModel: 'HBT-221',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: 'SBTO-92',
  },
  {
    ghModel: 'HBTR-151',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: 'SBTR92',
  },
  {
    ghModel: 'HBTR-451',
    trademark: ['Greenheck', 'Venco'],
    compName: 'Swartwout',
    compModel: 'SBTR92',
  },
  {
    ghModel: 'GJX',
    trademark: ['Greenheck'],
    compName: 'SystemAir',
    compModel: 'AJ',
  },
  {
    ghModel: 'GJI',
    trademark: ['Greenheck'],
    compName: 'SystemAir',
    compModel: 'IV',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '1000',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '1000',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '1000',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '1400',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '1500',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '1500',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000',
  },
  {
    ghModel: 'ICD-44',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '9000',
  },
  {
    ghModel: 'SMD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '1000SM',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '7000CW',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: '9000BF',
  },
  {
    ghModel: 'AMD-42-TD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'TAMCO',
    compModel: 'AIR-IQ',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'Temtrol',
    compModel: 'FC',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'Temtrol',
    compModel: 'FC',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'AMU',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'AMU',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'AMU',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'AR',
  },
  {
    ghModel: 'VSU',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'AR',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'Demand Air',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'TAM ',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'TitanAir',
    compModel: 'TAM ',
  },
  {
    ghModel: 'XG-H4252',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '111RL',
  },
  {
    ghModel: 'XG-V4252',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '111RS',
  },
  {
    ghModel: 'XG-H4254',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '112RL',
  },
  {
    ghModel: 'XG-V4254',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '112RS',
  },
  {
    ghModel: 'XG-H4152',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '121RL',
  },
  {
    ghModel: 'XG-V4152',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '121RS',
  },
  {
    ghModel: 'XG-V4154',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '121RS',
  },
  {
    ghModel: 'XG-H4154',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '122RL',
  },
  {
    ghModel: 'XG-H4352',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '131RL',
  },
  {
    ghModel: 'XG-H4354',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '131RS',
  },
  {
    ghModel: 'XG-V4352',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '131RS',
  },
  {
    ghModel: 'XG-V4354',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '131RS',
  },
  {
    ghModel: 'XG-SRHF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '23RFL',
  },
  {
    ghModel: 'XG-SRH',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '23RL',
  },
  {
    ghModel: 'XG-L',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-L1',
  },
  {
    ghModel: 'XG-LM',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-L1 w/AG-20',
  },
  {
    ghModel: 'XG-LT',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-L2',
  },
  {
    ghModel: 'XG-LTM',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-L2 w/AG-20',
  },
  {
    ghModel: 'XG-LTC',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-L2C',
  },
  {
    ghModel: 'XG-LS3',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-L3/S3',
  },
  {
    ghModel: 'XG-LS3M',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-L3/S3 w/AG-20',
  },
  {
    ghModel: 'XG-LS4',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250AA-L4/S4',
  },
  {
    ghModel: 'XG-LS4M',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250AA-L4/S4 w/AG-20',
  },
  {
    ghModel: 'XG-S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-S1',
  },
  {
    ghModel: 'XG-SM',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-S1 w/AG-20',
  },
  {
    ghModel: 'XG-ST',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-S2',
  },
  {
    ghModel: 'XG-STM',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '250-AA-S2 w/AG-20',
  },
  {
    ghModel: 'XG-H4002-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '271FL',
  },
  {
    ghModel: 'XG-V4002-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '271FS',
  },
  {
    ghModel: 'XG-H4002S-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '271RL',
  },
  {
    ghModel: 'XG-V4002S-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '271RS',
  },
  {
    ghModel: 'XG-H4004-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '272FL',
  },
  {
    ghModel: 'XG-V4004-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '272FS',
  },
  {
    ghModel: 'XG-H4004S-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '272RL',
  },
  {
    ghModel: 'XG-V4004S-AF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '272RS',
  },
  {
    ghModel: 'XG-H4004',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '300FL',
  },
  {
    ghModel: 'XG-V4004',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '300FS',
  },
  {
    ghModel: 'XG-4504S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '300R-HD',
  },
  {
    ghModel: 'XG-H4004S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '300RL',
  },
  {
    ghModel: 'XG-H4004SS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '300RL-SS',
  },
  {
    ghModel: 'XG-V4004S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '300RS',
  },
  {
    ghModel: 'XG-V4004SS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '300RS-SS',
  },
  {
    ghModel: 'XG-H4002',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '301FL',
  },
  {
    ghModel: 'XG-V4002',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '301FS',
  },
  {
    ghModel: 'XG-4502S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '301R-HD',
  },
  {
    ghModel: 'XG-H4002S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '301RL',
  },
  {
    ghModel: 'XG-H4002SS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '301RL-SS',
  },
  {
    ghModel: 'XG-V4002S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '301RS',
  },
  {
    ghModel: 'XG-V4002SS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '301RS-SS',
  },
  {
    ghModel: 'XG-4500S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '30R',
  },
  {
    ghModel: 'XG-4500SF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '30RF',
  },
  {
    ghModel: 'XG-4538S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '33R',
  },
  {
    ghModel: 'XG-4538SF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '33RF',
  },
  {
    ghModel: 'XG-RH',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350FL',
  },
  {
    ghModel: 'XG-RHF ',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350FLF',
  },
  {
    ghModel: 'XG-RHFSS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350RLFSS',
  },
  {
    ghModel: 'XG-RHSS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350RL-SS',
  },
  {
    ghModel: 'XG-H4002R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350ZF',
  },
  {
    ghModel: 'XG-V4002R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350ZF',
  },
  {
    ghModel: 'XG-H4002RS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350ZR',
  },
  {
    ghModel: 'XG-V4002RS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '350ZR',
  },
  {
    ghModel: 'XG-RHEF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '3FFL',
  },
  {
    ghModel: 'XG-RHE',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '3FL',
  },
  {
    ghModel: 'XG-CC45',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '45F',
  },
  {
    ghModel: 'XG-CC5',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '50F Code A',
  },
  {
    ghModel: 'XG-CC15',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '50F Code B',
  },
  {
    ghModel: 'XG-CC1',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '50F Code C',
  },
  {
    ghModel: 'XG-CC5F',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '50FF',
  },
  {
    ghModel: 'XG-CC5S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '50R',
  },
  {
    ghModel: 'XG-CC5SS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '50R-SS',
  },
  {
    ghModel: 'XG-4500',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '55F',
  },
  {
    ghModel: 'XG-4500F',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '55FF',
  },
  {
    ghModel: 'XG-4538',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '56F',
  },
  {
    ghModel: 'XG-4538F',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '56FF',
  },
  {
    ghModel: 'XG-RP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '8F',
  },
  {
    ghModel: 'XG-RPF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '8FF',
  },
  {
    ghModel: 'XG-SRP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '8R',
  },
  {
    ghModel: 'XG-SRPF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '8RF',
  },
  {
    ghModel: 'XG-RPSS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: '8SS',
  },
  {
    ghModel: 'XG-OBD',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'AG-15',
  },
  {
    ghModel: 'XG-OBDA',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'AG-15-AA',
  },
  {
    ghModel: 'XG-BDS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'AG-85',
  },
  {
    ghModel: 'XG-DAF-CC5',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CSR',
  },
  {
    ghModel: 'XG-2400',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-480 Border 11',
  },
  {
    ghModel: 'XG-2300',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-480 Border 13',
  },
  {
    ghModel: 'XG-2800',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-480 Border 6',
  },
  {
    ghModel: 'XG-2100',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-540 Border 11',
  },
  {
    ghModel: 'XG-2000',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-540 Border 13',
  },
  {
    ghModel: 'XG-2200',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-540 Border 6',
  },
  {
    ghModel: 'XG-2600',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-580 Border 11',
  },
  {
    ghModel: 'XG-2500',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-580 Border 13',
  },
  {
    ghModel: 'XG-2700',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-580 Border 6',
  },
  {
    ghModel: 'XG-DGSF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-700L',
  },
  {
    ghModel: 'XG-DGDF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'CT-700L w/Aux Frame',
  },
  {
    ghModel: 'XG-RSD',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'D-100',
  },
  {
    ghModel: 'XG-5500DD',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DAT',
  },
  {
    ghModel: 'XG-RL',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DL',
  },
  {
    ghModel: 'XG-RL-DF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DL-SV',
  },
  {
    ghModel: 'XG-DD-RD-180',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DV180',
  },
  {
    ghModel: 'XG-DD-CU',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DVC1',
  },
  {
    ghModel: 'XG-DD-RD-360',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DVCP',
  },
  {
    ghModel: 'XG-DD-WALL-1W',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DVIR',
  },
  {
    ghModel: 'XG-DD-WALL-FM',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DVR1',
  },
  {
    ghModel: 'XG-DD-REC',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DVRI',
  },
  {
    ghModel: 'XG-DD-RD-90',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'DVVC',
  },
  {
    ghModel: 'XG-G3',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'EG ',
  },
  {
    ghModel: 'XG-L9',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'EG-L',
  },
  {
    ghModel: 'XG-AFL-10',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-10',
  },
  {
    ghModel: 'XG-AFLC-10',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-10 Border 77',
  },
  {
    ghModel: 'XG-AFL-15',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-15',
  },
  {
    ghModel: 'XG-AFLC-15',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-15 Border 77',
  },
  {
    ghModel: 'XG-AFL-20',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-20',
  },
  {
    ghModel: 'XG-AFLC-20',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-20 Border 77',
  },
  {
    ghModel: 'XG-AFL-25',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-25',
  },
  {
    ghModel: 'XG-AFLC-25',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-25 Border 77',
  },
  {
    ghModel: 'XG-AFL-30',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-30',
  },
  {
    ghModel: 'XG-AFLC-30',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FL-30 Border 77',
  },
  {
    ghModel: 'XG-AFL-TS-10',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FTBI-10',
  },
  {
    ghModel: 'XG-AFL-TS-15',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FTBI-15',
  },
  {
    ghModel: 'XG-AFL-TS-20',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'FTBI-20',
  },
  {
    ghModel: 'XG-LFL',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'LL',
  },
  {
    ghModel: 'XG-6600SQ',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MB-',
  },
  {
    ghModel: 'XG-6600SQR',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MBR-',
  },
  {
    ghModel: 'XG-9000',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MCD-AA',
  },
  {
    ghModel: 'XG-AFLI',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MF-10',
  },
  {
    ghModel: 'XG-AFLIR',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MFR-10',
  },
  {
    ghModel: 'XG-6650',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'ML-37',
  },
  {
    ghModel: 'XG-6675',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'ML-38',
  },
  {
    ghModel: 'XG-6610',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'ML-39',
  },
  {
    ghModel: 'XG-6610SP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'ML-39 Border 16',
  },
  {
    ghModel: 'XG-6615',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'ML-40',
  },
  {
    ghModel: 'XG-6650R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MLR-37',
  },
  {
    ghModel: 'XG-6675R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MLR-38',
  },
  {
    ghModel: 'XG-6610R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MLR-39',
  },
  {
    ghModel: 'XG-6610RSP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MLR-39 Border 16',
  },
  {
    ghModel: 'XG-6615R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MLR-40',
  },
  {
    ghModel: 'XG-6675RTZ',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MLR-TZ',
  },
  {
    ghModel: 'XG-6675TZ',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'ML-TZ',
  },
  {
    ghModel: 'XG-UP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MP',
  },
  {
    ghModel: 'XG-UPI',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'MPI',
  },
  {
    ghModel: 'XG-PHCS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'N-1/N-4',
  },
  {
    ghModel: 'XG-PHCR-DB',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'N-1-DR/N-4-DR',
  },
  {
    ghModel: 'XG-PHCR',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'N-1-R/N-4-R',
  },
  {
    ghModel: 'XG-5750',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'OMNI',
  },
  {
    ghModel: 'XG-5750RP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'ONNI-RS',
  },
  {
    ghModel: 'XG-7500R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAR',
  },
  {
    ghModel: 'XG-7550R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAR',
  },
  {
    ghModel: 'XG-7600R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAR',
  },
  {
    ghModel: 'XG-7650R',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAR',
  },
  {
    ghModel: 'XG-7500RFRS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAR-FR',
  },
  {
    ghModel: 'XG-7550RFRS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAR-FR',
  },
  {
    ghModel: 'XG-7500',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAS',
  },
  {
    ghModel: 'XG-7550',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAS',
  },
  {
    ghModel: 'XG-7500FRS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAS-FR',
  },
  {
    ghModel: 'XG-7550FRS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PAS-FR',
  },
  {
    ghModel: 'XG-7600',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PCS',
  },
  {
    ghModel: 'XG-7650',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PCS',
  },
  {
    ghModel: 'XG-PF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PF',
  },
  {
    ghModel: 'XG-7900',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PMC',
  },
  {
    ghModel: 'XG-7950',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PMC',
  },
  {
    ghModel: 'XG-SPRTB',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PXP ',
  },
  {
    ghModel: 'XG-PRTB',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'PXP-AA',
  },
  {
    ghModel: 'XG-MRDD',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'R-300F',
  },
  {
    ghModel: 'XG-MRSD',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'R-301F',
  },
  {
    ghModel: 'XG-R5750',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'R-OMNI',
  },
  {
    ghModel: 'XG-4004PCF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'S300F',
  },
  {
    ghModel: 'XG-4002PCF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'S301F',
  },
  {
    ghModel: 'XG-4000PCFPFR',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'S8F',
  },
  {
    ghModel: 'XG-SG2000',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SG-1500FL',
  },
  {
    ghModel: 'XG-SGRH',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SG-3300RL',
  },
  {
    ghModel: 'XG-SGDB',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SG-BG',
  },
  {
    ghModel: 'XG-SGLF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SG-LFF',
  },
  {
    ghModel: 'XG-SGRP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SG-PR',
  },
  {
    ghModel: 'XG-SGSP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SG-PS',
  },
  {
    ghModel: 'XG-SG5500',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SG-TDC',
  },
  {
    ghModel: 'XG-Periflow',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'SteriTec',
  },
  {
    ghModel: 'XG-MSE-HC-S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'T3SQ-2-D',
  },
  {
    ghModel: 'XG-MSE-HC-M',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'T3SQ-2-M',
  },
  {
    ghModel: 'XG-MSCVH',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'T3SQ-4',
  },
  {
    ghModel: 'XG-WFO',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TAF-R',
  },
  {
    ghModel: 'XG-PHPS-E',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBD-10',
  },
  {
    ghModel: 'XG-PHPS-IT',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBD-30',
  },
  {
    ghModel: 'XG-PHPS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBD-80',
  },
  {
    ghModel: 'XG-PHPSI-E',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBDI-10',
  },
  {
    ghModel: 'XG-PHPSI-IT',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBDI-30',
  },
  {
    ghModel: 'XG-PHPSI',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBDI-80',
  },
  {
    ghModel: 'XG-PHPRI',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBDR-80',
  },
  {
    ghModel: 'XG-PHPR ',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBDRI-80',
  },
  {
    ghModel: 'XG-JA',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TBF-AA',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TDC',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TDCA ',
  },
  {
    ghModel: 'XG5500',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TDC-AA',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TDCA-AA',
  },
  {
    ghModel: 'XG-5500SFRS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TDC-FR',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TDV ',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TDV-AA',
  },
  {
    ghModel: 'XG-Phenomenator',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TJD',
  },
  {
    ghModel: 'XG-Phenomenator-LF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TJD',
  },
  {
    ghModel: 'XG-HPL-CA',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TLF-AA',
  },
  {
    ghModel: 'XG-HPL-GSJ',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TLF-AA (HEPA)',
  },
  {
    ghModel: 'XG-3100S-A',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TMR',
  },
  {
    ghModel: 'XG-3100A',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TMR-AA',
  },
  {
    ghModel: 'XG-3000',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TMRA-AA',
  },
  {
    ghModel: 'XG-5700',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TMS',
  },
  {
    ghModel: 'XG-5700A',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TMS-AA',
  },
  {
    ghModel: 'XG-5700FRS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TMS-FR',
  },
  {
    ghModel: 'XG-MPK',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TND-AA',
  },
  {
    ghModel: 'XG-HRD-CL',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TriTec-AL',
  },
  {
    ghModel: 'XG-HRD-GS',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TriTecR',
  },
  {
    ghModel: 'XG-STBPF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TRM-S/TRM',
  },
  {
    ghModel: 'XG-TBPF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'TRM-S/TRM',
  },
  {
    ghModel: 'XG-PHSP-J',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'T-Slot',
  },
  {
    ghModel: 'XG-PHSPI-J',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'T-Slot-In',
  },
  {
    ghModel: 'XG-4004P',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'US300FL',
  },
  {
    ghModel: 'XG-4000PFR',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'US8F',
  },
  {
    ghModel: 'XG-4000SPFR',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'US8F',
  },
  {
    ghModel: 'XG-RL-SP',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'US-DL',
  },
  {
    ghModel: 'XG-RL-SP-DF',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'US-DL-SV',
  },
  {
    ghModel: 'XG-3200',
    trademark: ['Greenheck'],
    compName: 'Titus',
    compModel: 'XC-310',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'BCHC',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'BCVC',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CAFD',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CAFD',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW-21',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CAFD',
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CAFD',
  },
  {
    ghModel: 'BISW-21',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CBID',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CBID',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CPAD',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CPBD',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'SWB',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'CUBA',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'LPC',
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'Trane',
    compModel: 'M-Series',
  },
  {
    ghModel: 'XG-5800',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '1300',
  },
  {
    ghModel: 'XG-6650',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6050',
  },
  {
    ghModel: 'XG-6675',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6075',
  },
  {
    ghModel: 'XG-6610',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6100',
  },
  {
    ghModel: 'XG-6615',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6150',
  },
  {
    ghModel: 'XG-5800A',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '1300A',
  },
  {
    ghModel: 'XG-2800',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 025 A',
  },
  {
    ghModel: 'XG-2700',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 025 B',
  },
  {
    ghModel: 'XG-2200',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 025 C',
  },
  {
    ghModel: 'XG-2400',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 050 A',
  },
  {
    ghModel: 'XG-2600',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 050 B',
  },
  {
    ghModel: 'XG-2100',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 050 C',
  },
  {
    ghModel: 'XG-2300',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 100 A',
  },
  {
    ghModel: 'XG-2500',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 100 B',
  },
  {
    ghModel: 'XG-2000',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '4000 100 C',
  },
  {
    ghModel: 'XG-6650R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6050R',
  },
  {
    ghModel: 'XG-6675TZ',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6075 Tech Zone',
  },
  {
    ghModel: 'XG-6675R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6075R',
  },
  {
    ghModel: 'XG-6675RTZ',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6075R Tech Zone',
  },
  {
    ghModel: 'XG-6610R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6100R',
  },
  {
    ghModel: 'XG-L9',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: '6L',
  },
  {
    ghModel: 'XG-L',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A201',
  },
  {
    ghModel: 'XG-LM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A201-MSDM',
  },
  {
    ghModel: 'XG-LT',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A202',
  },
  {
    ghModel: 'XG-LTM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A202-MSDM',
  },
  {
    ghModel: 'XG-LTC3',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A203',
  },
  {
    ghModel: 'XG-LTC3M',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A203-MSDM',
  },
  {
    ghModel: 'XG-LTC',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A20C/40C',
  },
  {
    ghModel: 'XG-LTCM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A20C/40C-MSDM',
  },
  {
    ghModel: 'XG-S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A401',
  },
  {
    ghModel: 'XG-SM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A401-MSDM',
  },
  {
    ghModel: 'XG-ST',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A402',
  },
  {
    ghModel: 'XG-STM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A402-MSDM',
  },
  {
    ghModel: 'XG-LS3',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A403',
  },
  {
    ghModel: 'XG-LS3M',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A403-MSDM',
  },
  {
    ghModel: 'XG-LS4',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A404',
  },
  {
    ghModel: 'XG-LS4M',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A404-MSDM',
  },
  {
    ghModel: 'XG-PF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A45',
  },
  {
    ghModel: 'XG-H4002',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A50',
  },
  {
    ghModel: 'XG-H4002M',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A52',
  },
  {
    ghModel: 'XG-H4004',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A54 ',
  },
  {
    ghModel: 'XG-V4002',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A60',
  },
  {
    ghModel: 'XG-V4002M',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A62',
  },
  {
    ghModel: 'XG-V4004',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A64 ',
  },
  {
    ghModel: 'XG-H4002R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A70',
  },
  {
    ghModel: 'XG-V4002R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A70',
  },
  {
    ghModel: 'XG-RH',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A70D',
  },
  {
    ghModel: 'XG-RHF ',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'A70DFB',
  },
  {
    ghModel: 'XG-DGSF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'ADXFR',
  },
  {
    ghModel: 'XG-DGDF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'ADXFR w/Aux Frame',
  },
  {
    ghModel: 'XG-H4254',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGD DH1',
  },
  {
    ghModel: 'XG-H4354',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGD DH3',
  },
  {
    ghModel: 'XG-V4254',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGD DV1',
  },
  {
    ghModel: 'XG-V4354',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGD DV3',
  },
  {
    ghModel: 'XG-MRDDW',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGR',
  },
  {
    ghModel: 'XG-H4252',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGS SH1',
  },
  {
    ghModel: 'XG-H4352',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGS SH3',
  },
  {
    ghModel: 'XG-V4252',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGS SV1',
  },
  {
    ghModel: 'XG-V4352',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIGS SV3',
  },
  {
    ghModel: 'XG-AFL-10',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-100',
  },
  {
    ghModel: 'XG-AFLC-10',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-100-C',
  },
  {
    ghModel: 'XG-AFL-15',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-150',
  },
  {
    ghModel: 'XG-AFLC-15',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-150-C',
  },
  {
    ghModel: 'XG-AFL-20',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-200',
  },
  {
    ghModel: 'XG-AFLC-20',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-200-C',
  },
  {
    ghModel: 'XG-AFL-25',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-250',
  },
  {
    ghModel: 'XG-AFLC-25',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-250-C',
  },
  {
    ghModel: 'XG-AFL-30',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-300',
  },
  {
    ghModel: 'XG-AFLC-30',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AIR-TRAC-300-C',
  },
  {
    ghModel: 'XG5500',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AM ',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AMA',
  },
  {
    ghModel: 'XG-OBDA',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AOBD',
  },
  {
    ghModel: 'XG-3100A',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AP3',
  },
  {
    ghModel: 'XG-RP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'APG',
  },
  {
    ghModel: 'XG-RPF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'APGFB',
  },
  {
    ghModel: 'XG-PHPS-E',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'APPS',
  },
  {
    ghModel: 'XG-PHPSI-E',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'APPS-Insulated',
  },
  {
    ghModel: 'XG-PRTB',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'APTL',
  },
  {
    ghModel: 'XG-9000',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AQD',
  },
  {
    ghModel: 'XG-5500',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'ARC',
  },
  {
    ghModel: 'XG-H4002-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AV50',
  },
  {
    ghModel: 'XG-H4004-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AV54',
  },
  {
    ghModel: 'XG-V4002-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AV60',
  },
  {
    ghModel: 'XG-V4004-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AV64',
  },
  {
    ghModel: 'XG-3000',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AVJR',
  },
  {
    ghModel: 'XG-H4002S-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AVS50',
  },
  {
    ghModel: 'XG-H4004S-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AVS54',
  },
  {
    ghModel: 'XG-V4002S-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AVS60',
  },
  {
    ghModel: 'XG-V4004S-AF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'AVS64',
  },
  {
    ghModel: 'XG-CC1',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'CRE1000',
  },
  {
    ghModel: 'XG-CC5',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'CRE500',
  },
  {
    ghModel: 'XG-CC5F',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'CRE500FB',
  },
  {
    ghModel: 'XG-CC15',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'CRE510',
  },
  {
    ghModel: 'XG-MSE-HC-M',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DCVH',
  },
  {
    ghModel: 'XG-DD-WALL-1W',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DFX',
  },
  {
    ghModel: 'XG-MPK',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DJ',
  },
  {
    ghModel: 'XG-DD-RD-180',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DS180',
  },
  {
    ghModel: 'XG-DD-RD-360',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DS360',
  },
  {
    ghModel: 'XG-DD-RD-90',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DSC90',
  },
  {
    ghModel: 'XG-DD-REC',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DSQ',
  },
  {
    ghModel: 'XG-DD-WALL-FM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DSQ1',
  },
  {
    ghModel: 'XG-DAF-CC5',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'DSR',
  },
  {
    ghModel: 'XG-PHSP-J',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'FVPS',
  },
  {
    ghModel: 'XG-PHSPI-J',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'FVPS-Insulated',
  },
  {
    ghModel: 'XG-JA',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'GJ Jet-Flo',
  },
  {
    ghModel: 'XG-PHCR-DB',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'HIDR',
  },
  {
    ghModel: 'XG-PHCS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'HISD',
  },
  {
    ghModel: 'XG-PHCR',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'HUSR',
  },
  {
    ghModel: 'XG-Periflow',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'ISOTEK',
  },
  {
    ghModel: 'XG-PHPS-IT',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'ITPS',
  },
  {
    ghModel: 'XG-PHPSI-IT',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'ITPS-Insulated',
  },
  {
    ghModel: 'XG-UP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'LP',
  },
  {
    ghModel: 'XG-UPI',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'LP W/Insulation',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'M',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'MA',
  },
  {
    ghModel: 'XG-5500SFRS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'M-FR',
  },
  {
    ghModel: 'XG-RL-DF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'MTRJ',
  },
  {
    ghModel: 'XG-RL-SP-DF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'MTRJ-U',
  },
  {
    ghModel: 'XG-3100S-A',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'P3',
  },
  {
    ghModel: 'XG-SRP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PG',
  },
  {
    ghModel: 'XG-SRPF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PGFB',
  },
  {
    ghModel: 'XG-7900',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PM',
  },
  {
    ghModel: 'XG-7950',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PM',
  },
  {
    ghModel: 'XG-7500',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PP',
  },
  {
    ghModel: 'XG-7550',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PP',
  },
  {
    ghModel: 'XG-7500R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PR',
  },
  {
    ghModel: 'XG-7550R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PR',
  },
  {
    ghModel: 'XG-7600R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PR',
  },
  {
    ghModel: 'XG-7650R',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PR',
  },
  {
    ghModel: 'XG-PHPR ',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PSDR',
  },
  {
    ghModel: 'XG-PHPRI',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PSDR-Insulated',
  },
  {
    ghModel: 'XG-SPRTB',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PTL',
  },
  {
    ghModel: 'XG-7600',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PV',
  },
  {
    ghModel: 'XG-7650',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'PV',
  },
  {
    ghModel: 'XG-MRSD',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'RA50',
  },
  {
    ghModel: 'XG-MRDD',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'RA54',
  },
  {
    ghModel: 'XG-BDS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'RBN',
  },
  {
    ghModel: 'XG-5500S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'RC',
  },
  {
    ghModel: 'XG-RSD',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'RRN',
  },
  {
    ghModel: 'XG-R5750',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'RT1100',
  },
  {
    ghModel: 'XG-5700',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'S1200',
  },
  {
    ghModel: 'XG-5700A',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'S1200A',
  },
  {
    ghModel: 'XG-6610SP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'S6100',
  },
  {
    ghModel: 'XG-6610RSP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'S6100R',
  },
  {
    ghModel: 'XG-4002PCF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDA60-R',
  },
  {
    ghModel: 'XG-4004PCF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDA64-R',
  },
  {
    ghModel: 'XG-4004P',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDA64-U',
  },
  {
    ghModel: 'XG-4000PCFPFR',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDAPG-R',
  },
  {
    ghModel: 'XG-4000PCFPFS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDAPG-R w/Extractor',
  },
  {
    ghModel: 'XG-4000PFR',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDAPG-U',
  },
  {
    ghModel: 'XG-4000PFS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDAPG-U w/Extractor',
  },
  {
    ghModel: 'XG-SGDB',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SDBS',
  },
  {
    ghModel: 'XG-SGSP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SG100',
  },
  {
    ghModel: 'XG-SGRP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SG200',
  },
  {
    ghModel: 'XG-SG5500',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SG500',
  },
  {
    ghModel: 'XG-SGRH',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SG600',
  },
  {
    ghModel: 'XG-SG2000',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SG700',
  },
  {
    ghModel: 'XG-SGLF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SG800',
  },
  {
    ghModel: 'XG-STBPF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SMF/ASMF',
  },
  {
    ghModel: 'XG-TBPF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SMF/ASMF',
  },
  {
    ghModel: 'XG-OBD',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SOBD',
  },
  {
    ghModel: 'XG-TR',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SRD',
  },
  {
    ghModel: 'XG-TRDeep',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SRD',
  },
  {
    ghModel: 'XG-LFL',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'Stripeline EH',
  },
  {
    ghModel: 'XG-RL',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'STRJ',
  },
  {
    ghModel: 'XG-RL-SP',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'STRJ-U',
  },
  {
    ghModel: 'XG-PHPS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SVPS',
  },
  {
    ghModel: 'XG-PHPSI',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'SVPS-Insulated',
  },
  {
    ghModel: 'XG-5750',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T1100',
  },
  {
    ghModel: 'XG-4500S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T115 0 degree',
  },
  {
    ghModel: 'XG-4538S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T115 38 degree',
  },
  {
    ghModel: 'XG-4502S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T125',
  },
  {
    ghModel: 'XG-4504S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T155',
  },
  {
    ghModel: 'XG-H4002S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T50',
  },
  {
    ghModel: 'XG-H4002SM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T52',
  },
  {
    ghModel: 'XG-H4004S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T54',
  },
  {
    ghModel: 'XG-V4002S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T60',
  },
  {
    ghModel: 'XG-V4002SM',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T62',
  },
  {
    ghModel: 'XG-V4004S',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T64',
  },
  {
    ghModel: 'XG-H4002RS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T70',
  },
  {
    ghModel: 'XG-V4002RS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T70',
  },
  {
    ghModel: 'XG-SRH',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T70D ',
  },
  {
    ghModel: 'XG-SRHF',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'T70DFB',
  },
  {
    ghModel: 'XG-HPL-CA',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'Tensor',
  },
  {
    ghModel: 'XG-HPL-GSJ',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'Tensor-HEPA',
  },
  {
    ghModel: 'XG-G3',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'TF',
  },
  {
    ghModel: 'XG-WFO',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'UFR',
  },
  {
    ghModel: 'XG-3200',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'VB',
  },
  {
    ghModel: 'XG-HRD-CL',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'Vector',
  },
  {
    ghModel: 'XG-HRD-GS',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'Vector-H',
  },
  {
    ghModel: 'XG-CC45',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'VPER',
  },
  {
    ghModel: 'XG-MSCVH',
    trademark: ['Greenheck'],
    compName: 'Tuttle_Bailey',
    compModel: 'VTD',
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'ACV',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'AFPF',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'AFPF',
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'AFPL',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'APF',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'APF',
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'ATAB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'ATAB ',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'ATAD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'ATAD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAE-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAE-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAE-SW',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAE-SW',
  },
  {
    ghModel: 'AFDW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAF',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAF',
  },
  {
    ghModel: 'AFDW-21',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAF-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFDW-42',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAF-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'AFSW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAF-SW',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAF-SW',
  },
  {
    ghModel: 'VEKTOR-CD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAIFE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BAV',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BC',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BISW-41_BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BC',
  },
  {
    ghModel: 'BIDW-21',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BC-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BIDW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BC-DW',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'VEKTOR-CD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCIFE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCJ',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCJU2',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCJU5',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCPF',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCPF',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'PLG',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCPL',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'GB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCRD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUBE',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCRU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'CUE',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCRU',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'BCSW-FRP',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCSF',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'BISW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BC-SW',
  },
  {
    ghModel: 'CSW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BC-SW',
  },
  {
    ghModel: 'BISW-41',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCV',
  },
  {
    ghModel: 'USF',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'BCV',
    filter: {
      category: 'Blowers'
    }
  },
  {
    ghModel: 'G',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'DCRD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'SFD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'DDF',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPF',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPF',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPF',
  },
  {
    ghModel: 'QEP-DIRECT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPF',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPFN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPFN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPFN',
  },
  {
    ghModel: 'QEP-DIRECT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPFN',
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPLQN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQ',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQ',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQ',
  },
  {
    ghModel: 'QEP-DIRECT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQ',
  },
  {
    ghModel: 'APH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'APM',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEP-BELT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQN',
  },
  {
    ghModel: 'QEP-DIRECT',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'EPQN',
  },
  {
    ghModel: 'SFB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'FC',
  },
  {
    ghModel: 'SFB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'FCJ',
  },
  {
    ghModel: 'FPB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'JRW',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'JRW',
  },
  {
    ghModel: 'LSF',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'LPSF',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'HPA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'MPQN',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'HPA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'MPQS',
    filter: {
      category: 'Plenum'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLBR',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLBR',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLBR',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLBSH',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLBSH',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QCLBSH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VEKTOR-MH',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QFE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QIFE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSL',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSL',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSL',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSLR',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSLR',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSLR',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'QEID',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSLSH',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSLSH',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'QEI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'QSLSH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'IPA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'RBA',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'RBO',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'RBP',
  },
  {
    ghModel: 'IPO',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'RBR',
  },
  {
    ghModel: 'IPW',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'RBW',
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCLB',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TCBRS',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCLB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCBRU',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCLB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TCB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCLBR',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'MCP',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCPM',
  },
  {
    ghModel: 'MCB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCSM',
  },
  {
    ghModel: 'MCY',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCSM',
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCTA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCTA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCTA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCTS',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCVA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCVA',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCVS',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCVS',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCVX',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TCVX ',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'TDI',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TD',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VEKTOR-H',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TFE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'TCF',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TSL',
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB-CA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TUB',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUB',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TUBSH',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TAUD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TUD',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'VEKTOR-MD',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'TVIFE',
    filter: {
      category: 'Fume exhaust'
    }
  },
  {
    ghModel: 'TBI-CA',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'UBTA',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'TBI-FS',
    trademark: ['Greenheck'],
    compName: 'TwinCityFan',
    compModel: 'UBTS',
    filter: {
      category: 'Roof mounted'
    }
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-4',
  },
  {
    ghModel: 'T6786',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-6',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-7',
  },
  {
    ghModel: '9208',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'AXF-8',
  },
  {
    ghModel: 'T9208',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'AXF-8',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'CFL-D-4',
  },
  {
    ghModel: 'K8504',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'CFL-D-4',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'CFL-D-6',
  },
  {
    ghModel: 'K609-CB609',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'EL-4',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-2',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-4',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-4',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-6',
  },
  {
    ghModel: 'K6776',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-6',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'SAFL-4',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'SAFL-6',
  },
  {
    ghModel: 'SCH401',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'SED-4',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'SED-5',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'SFL-D-4',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'SFL-D-6',
  },
  {
    ghModel: 'SCV501MD',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'VV-10-D',
  },
  {
    ghModel: '9108',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'XAC-4',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'XAC-4',
  },
  {
    ghModel: '9112',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'XAC-6',
  },
  {
    ghModel: '9112',
    trademark: ['Airolite'],
    compName: 'UnitedEnertech',
    compModel: 'XAC-9',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-4',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-6',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'CFL-D-4',
  },
  {
    ghModel: 'EDJ-401',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'EL-4',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'EL-4',
  },
  {
    ghModel: 'EES-401',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'ES-EL-4',
  },
  {
    ghModel: 'AFL-501',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'FEMA-5',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-2',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-4',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-6',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-6.1',
  },
  {
    ghModel: 'ESID-430',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'FL-ID-4',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'SED-5',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'VF-2',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'XSD-130',
  },
  {
    ghModel: 'EHH-201',
    trademark: ['Greenheck'],
    compName: 'UnitedEnertech',
    compModel: 'XSD-55',
  },
  {
    ghModel: 'EAD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-6',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'AH-620',
  },
  {
    ghModel: 'HCD-240',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'AH-621',
  },
  {
    ghModel: 'AFA-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'AXF-8',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'BF',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'BVE',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'BVF',
  },
  {
    ghModel: 'CRD-60',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C/FS-2',
  },
  {
    ghModel: 'CRD-60B',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C/FSB',
  },
  {
    ghModel: 'CRD-60X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C/FSX-2',
  },
  {
    ghModel: 'ES-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'ES-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-600',
  },
  {
    ghModel: 'EM-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-11',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-30',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-31',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-32',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-41',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'EM-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CB-601',
  },
  {
    ghModel: 'WDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD',
  },
  {
    ghModel: 'RBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-100-ERC',
  },
  {
    ghModel: 'FBH-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-110',
  },
  {
    ghModel: 'FBV-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-110',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-110',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-110',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-110',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-110',
  },
  {
    ghModel: 'FBH-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-111',
  },
  {
    ghModel: 'FBV-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-111',
  },
  {
    ghModel: 'VCD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-111',
  },
  {
    ghModel: 'VCD-18',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-111',
  },
  {
    ghModel: 'VCD-20',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-111',
  },
  {
    ghModel: 'VCD-23',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-111',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-120',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-121',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-145',
  },
  {
    ghModel: 'VCD-40',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-146',
  },
  {
    ghModel: 'FBH-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-150',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-150',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-150',
  },
  {
    ghModel: 'FBV-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-151',
  },
  {
    ghModel: 'VCD-43',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-151',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-160',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-160',
  },
  {
    ghModel: 'VCD-34V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-160',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-161',
  },
  {
    ghModel: 'VCD-34',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-161',
  },
  {
    ghModel: 'VCD-34V',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-161',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-170',
  },
  {
    ghModel: 'VCD-33',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CD-171',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CEL-D-4',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CFL-D-6',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-RD',
  },
  {
    ghModel: 'CRD-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-RD-A',
  },
  {
    ghModel: 'CRD-1WJ',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-RD-HC',
  },
  {
    ghModel: 'CRD-1WJ',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-RD-HC-A',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-S/R',
  },
  {
    ghModel: 'CRD-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-S/R-A',
  },
  {
    ghModel: 'CRD-1WJ',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-S/R-HC',
  },
  {
    ghModel: 'CRD-1WJ',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-S/R-HCA',
  },
  {
    ghModel: 'CRD-1WJ',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-SR-WT',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-SR-WTP',
  },
  {
    ghModel: 'CRD-1WT',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'C-SR-WTP-A',
  },
  {
    ghModel: 'ESD-435X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'DCFL-D-4',
  },
  {
    ghModel: 'ESD-635DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'DCFL-D-6',
  },
  {
    ghModel: 'ESD-635X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'DCFL-D-6',
  },
  {
    ghModel: 'EHH-501X',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'DCSED-5.1',
  },
  {
    ghModel: 'EHH-601D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'DCSED-5.1',
  },
  {
    ghModel: 'EHH-601DE',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'DCSED-5.1',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'D-DR-4',
  },
  {
    ghModel: 'EDD-601',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'D-DR-6',
  },
  {
    ghModel: 'EHV-550D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'D-HV-6',
  },
  {
    ghModel: 'EHV-550D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'D-HV-6',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'EB',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ED',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ED-I',
  },
  {
    ghModel: 'ESU-153S',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'EL-1-CO',
  },
  {
    ghModel: 'ESU-153',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'EL-1-FL',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'EL-2',
  },
  {
    ghModel: 'FSD-231',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-3-I',
  },
  {
    ghModel: 'CFSD-211-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-CR-I',
  },
  {
    ghModel: 'CFSD-211-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-CR-I',
  },
  {
    ghModel: 'CFSD-211-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-CR-I',
  },
  {
    ghModel: 'CFSD-212-1',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-CR-II',
  },
  {
    ghModel: 'CFSD-212-2',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-CR-II',
  },
  {
    ghModel: 'CFSD-212-3',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-CR-II',
  },
  {
    ghModel: 'GFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-FA-I',
  },
  {
    ghModel: 'GFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-FA-II',
  },
  {
    ghModel: 'FSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-I',
  },
  {
    ghModel: 'FSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-II',
  },
  {
    ghModel: 'OFSD-211',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-OW-I',
  },
  {
    ghModel: 'OFSD-212',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-3V-OW-II',
  },
  {
    ghModel: 'FSD-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-AF-3-l',
  },
  {
    ghModel: 'FSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-AF-I',
  },
  {
    ghModel: 'FSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-AF-II',
  },
  {
    ghModel: 'OFSD-311',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-AF-OW-I',
  },
  {
    ghModel: 'OFSD-312',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-AF-OW-II',
  },
  {
    ghModel: 'FSDR-511',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-RD-I',
  },
  {
    ghModel: 'FSDR-512',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'F/S-RD-II',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FA-157',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-ACMH',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-ALB',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-ALB',
  },
  {
    ghModel: 'OFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-AOW',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-ASL',
  },
  {
    ghModel: 'FD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-ASL',
  },
  {
    ghModel: 'FD-100',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-AUSL',
  },
  {
    ghModel: 'FD-300',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-AUSL',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-B',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-B',
  },
  {
    ghModel: 'FD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-BSL',
  },
  {
    ghModel: 'FD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-C',
  },
  {
    ghModel: 'FD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-C',
  },
  {
    ghModel: 'DFD-210',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-110-3V',
  },
  {
    ghModel: 'DFDAF-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-160-AF',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-ALB',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-ALB',
  },
  {
    ghModel: 'ODFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-AOW',
  },
  {
    ghModel: 'DFD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-ASL',
  },
  {
    ghModel: 'DFD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-ASL',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-B',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-B',
  },
  {
    ghModel: 'DFD-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-BSL',
  },
  {
    ghModel: 'DFD-310',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-BSL',
  },
  {
    ghModel: 'DFD-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-C',
  },
  {
    ghModel: 'DFD-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-C',
  },
  {
    ghModel: 'DFDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-R',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SALB',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SALB',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SALB',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SB',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SB',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SB',
  },
  {
    ghModel: 'DFD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SC',
  },
  {
    ghModel: 'DFD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SC',
  },
  {
    ghModel: 'DFD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FDD-SC',
  },
  {
    ghModel: 'FDR-510',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-R',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-SALB',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-SALB',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-SALB ',
  },
  {
    ghModel: 'FD-150X12',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-SB',
  },
  {
    ghModel: 'FD-150X14',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-SB',
  },
  {
    ghModel: 'FD-150X16',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FD-SB',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'H-420',
  },
  {
    ghModel: 'HCD-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'H-421',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'H-620',
  },
  {
    ghModel: 'HCD-220',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'H-621',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'HCB-700',
  },
  {
    ghModel: 'HB-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'HCB-750',
  },
  {
    ghModel: 'HCDR-150',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'HD-292',
  },
  {
    ghModel: 'HCDR-250',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'HD-392',
  },
  {
    ghModel: 'HCDR-350',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'HD-492',
  },
  {
    ghModel: 'HCDR-351',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'HD-492-ISO',
  },
  {
    ghModel: 'RBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'i-3',
  },
  {
    ghModel: 'RBD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'i-4',
  },
  {
    ghModel: 'HBR-050',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'i-900',
  },
  {
    ghModel: 'HB-110',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ICB-800',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ICB-900',
  },
  {
    ghModel: 'HB-230',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ICB-950',
  },
  {
    ghModel: 'HBS-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ICBL-10',
  },
  {
    ghModel: 'HBS-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ICBL-10',
  },
  {
    ghModel: 'HBS-331',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ICBL-20',
  },
  {
    ghModel: 'HBS-431',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'ICBL-20',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'L-95-C',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'L-95H',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'LBD-4E',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'LBD-4I',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'MD-105',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'MD-106',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'MD-115',
  },
  {
    ghModel: 'MBD-15',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'MD-116',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'NAH-720-1',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'NAH-720-3',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'NAH-720-4',
  },
  {
    ghModel: 'HCD-330',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'NAH-721-1',
  },
  {
    ghModel: 'HCD-430',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'NAH-721-3',
  },
  {
    ghModel: 'HCD-530',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'NAH-721-4',
  },
  {
    ghModel: 'CAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'O-95-C',
  },
  {
    ghModel: 'HAD-10',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'O-95-C',
  },
  {
    ghModel: 'VRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'PN-10',
  },
  {
    ghModel: 'PRAD',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'PP-10',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'RD',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'RD-4',
  },
  {
    ghModel: 'EVH-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'RD-4',
  },
  {
    ghModel: 'MBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'RD-WM',
  },
  {
    ghModel: 'VCDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'RI',
  },
  {
    ghModel: 'VCDR-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'RI',
  },
  {
    ghModel: 'RBDR-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'RI-ERC-LM',
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'R-OB',
  },
  {
    ghModel: 'VCDRM-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'R-OB',
  },
  {
    ghModel: 'VCDRM-50',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'R-PB',
  },
  {
    ghModel: 'VCDRM-53',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'R-PB',
  },
  {
    ghModel: 'SMD-201',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'S-3V-I',
  },
  {
    ghModel: 'SMD-202',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'S-3V-II',
  },
  {
    ghModel: 'SMD-301',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'S-AF-I',
  },
  {
    ghModel: 'SMD-302',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'S-AF-II',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SAFL-D-4',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SAFL-D-6',
  },
  {
    ghModel: 'MBD-10M',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SB',
  },
  {
    ghModel: 'EHH-401',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SED-4',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SFL-4',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SFL-6',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SFL-D-4',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SFL-D-6',
  },
  {
    ghModel: 'SMDR-501',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'S-RD-I',
  },
  {
    ghModel: 'SMDR-502',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'S-RD-II',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'TB-155',
  },
  {
    ghModel: 'ICD-45',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'TB-156',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'VV-10-D',
  },
  {
    ghModel: 'AMS',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'X-10',
  },
  {
    ghModel: 'AMD-42',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'X-12',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'XAC-4',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'XAC-6',
  },
  {
    ghModel: 'AFJ-120',
    trademark: ['Greenheck', 'Venco'],
    compName: 'UnitedEnertech',
    compModel: 'XAC-9',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-4',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'AFL-D-6',
  },
  {
    ghModel: 'VACC-4',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'CFL-D-4',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'EL-4',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-2',
  },
  {
    ghModel: 'VASD-4',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-4',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'FL-D-6',
  },
  {
    ghModel: 'VAHH-5',
    trademark: ['Venco'],
    compName: 'UnitedEnertech',
    compModel: 'SED-5',
  },
  {
    ghModel: 'K8204',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VAAC-4',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VAAC-6',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VAAD-4',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VAAD-6',
  },
  {
    ghModel: 'SCH501',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VAHH-5',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VASD-2',
  },
  {
    ghModel: 'K6744',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VASD-4',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VASD-6',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VASJ-2',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VASJ-4',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VASJ-6',
  },
  {
    ghModel: 'SCV501',
    trademark: ['Airolite'],
    compName: 'Venco',
    compModel: 'VAVH-5',
  },
  {
    ghModel: 'EAC-401',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VAAC-4',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VAAC-6',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VAAD-4',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VAAD-6',
  },
  {
    ghModel: 'EHH-501',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VAHH-5',
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VASD-2',
  },
  {
    ghModel: 'ESD-435',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VASD-4',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VASD-6',
  },
  {
    ghModel: 'ESJ-201',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VASJ-2',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VASJ-4',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VASJ-6',
  },
  {
    ghModel: 'EVH-501D',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VAVH-5',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VLPI',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'Venco',
    compModel: 'VLPR',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'K8504',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2000',
  },
  {
    ghModel: 'K8206A',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2100',
  },
  {
    ghModel: 'KN827',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2200',
  },
  {
    ghModel: 'K8206',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2300',
  },
  {
    ghModel: 'KX827',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2350',
  },
  {
    ghModel: 'K8506',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2400',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2600',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2730',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2745',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2750',
  },
  {
    ghModel: 'K6844',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2760',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2850',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '2855',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '4420',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '4450',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '4455',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '4600',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '4650',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'VentProducts',
    compModel: '4655',
  },
  {
    ghModel: 'ECD-401',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2000',
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2100',
  },
  {
    ghModel: 'GCI-402',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2200',
  },
  {
    ghModel: 'EAC-601',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2300',
  },
  {
    ghModel: 'GCE-402',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2350',
  },
  {
    ghModel: 'ECD-601',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2400',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2600',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2730',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2740',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2745',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2750',
  },
  {
    ghModel: 'EDD-401',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2760',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2850',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '2855',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '4420',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '4450',
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '4455',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '4600',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '4650',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '4655',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '7130',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'VentProducts',
    compModel: '7130',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'EACA-601',
    trademark: ['Venco'],
    compName: 'VentProducts',
    compModel: '2100',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Venco'],
    compName: 'VentProducts',
    compModel: '2600',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Venco'],
    compName: 'VentProducts',
    compModel: '2730',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Venco'],
    compName: 'VentProducts',
    compModel: '2850',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Venco'],
    compName: 'VentProducts',
    compModel: '2855',
  },
  {
    ghModel: 'WIH',
    trademark: ['Venco'],
    compName: 'VentProducts',
    compModel: '7130',
  },
  {
    ghModel: 'WRH',
    trademark: ['Venco'],
    compName: 'VentProducts',
    compModel: '7130',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'WeatherRite',
    compModel: 'TT',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'WeatherRite',
    compModel: 'TT',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'WeatherRite',
    compModel: 'TT',
  },
  {
    ghModel: 'DG',
    trademark: ['Greenheck'],
    compName: 'WeatherRite',
    compModel: 'XT',
  },
  {
    ghModel: 'DGK',
    trademark: ['Greenheck'],
    compName: 'WeatherRite',
    compModel: 'XT',
  },
  {
    ghModel: 'DGX',
    trademark: ['Greenheck'],
    compName: 'WeatherRite',
    compModel: 'XT',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'WilliamsComfortProducts',
    compModel: 'AH',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'WilliamsComfortProducts',
    compModel: 'AV',
  },
  {
    ghModel: 'T9108',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'ACF-845A',
  },
  {
    ghModel: '6784',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'A-SDL-4',
  },
  {
    ghModel: '6786',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'A-SDL-6',
  },
  {
    ghModel: 'BVE',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EX Series',
  },
  {
    ghModel: 'T6482',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EX-245',
  },
  {
    ghModel: 'T5832',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EX-2V',
  },
  {
    ghModel: 'K666',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EX-445',
  },
  {
    ghModel: 'K601',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EX-4V',
  },
  {
    ghModel: 'K6096',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EX-645',
  },
  {
    ghModel: 'T645',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EXA-412',
  },
  {
    ghModel: 'T6636',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EXA-612',
  },
  {
    ghModel: 'T6784',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EXASDL-4',
  },
  {
    ghModel: 'T6796',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EXASDL-6',
  },
  {
    ghModel: 'K6772',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-2',
  },
  {
    ghModel: 'K6774',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-4',
  },
  {
    ghModel: 'K6746',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-6',
  },
  {
    ghModel: 'BVF',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'FL Series',
  },
  {
    ghModel: 'AC155',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'L-1545',
  },
  {
    ghModel: 'AC154',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'L-15HFA',
  },
  {
    ghModel: '609',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'L-445',
  },
  {
    ghModel: '6096',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'L-645',
  },
  {
    ghModel: '6774',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'SDL-4',
  },
  {
    ghModel: '6776',
    trademark: ['Airolite'],
    compName: 'WonderMetals',
    compModel: 'SDL-6',
  },
  {
    ghModel: 'AFJ-801',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'ACF-845A',
    filter: {
      category: 'Specialty'
    }
  },
  {
    ghModel: 'FAD-402',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'A-SDL-4',
  },
  {
    ghModel: 'FAD-635',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'A-SDL-6',
  },
  {
    ghModel: 'BVE',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EX Series',
  },
  {
    ghModel: 'ESJ-202',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EX-245',
  },
  {
    ghModel: 'SES-202',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EX-2V',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EX-430-WPL',
  },
  {
    ghModel: 'ESJ-401',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EX-445',
  },
  {
    ghModel: 'SEH-401',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EX-4V',
  },
  {
    ghModel: 'ESJ-602',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EX-645',
  },
  {
    ghModel: 'EAH-401',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXA-412',
  },
  {
    ghModel: 'EAH-690',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXA-612',
  },
  {
    ghModel: 'EAD-401',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXASDL-4',
  },
  {
    ghModel: 'EAD-635',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXASDL-6',
  },
  {
    ghModel: 'WIH',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXPH-430',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'WRH',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXPH-430',
    filter: {
      category: 'Gravity'
    }
  },
  {
    ghModel: 'ESD-202',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-2',
  },
  {
    ghModel: 'ESD-403',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-4',
  },
  {
    ghModel: 'ESK-402',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-4',
  },
  {
    ghModel: 'ESD-635',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-6',
  },
  {
    ghModel: 'BVF',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'FL Series',
  },
  {
    ghModel: 'ESJ-155',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'L-1545',
  },
  {
    ghModel: 'ESU-154',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'L-15HFA',
  },
  {
    ghModel: 'FSJ-402',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'L-445',
  },
  {
    ghModel: 'FSJ-602',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'L-645',
  },
  {
    ghModel: 'FDS-402',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'SDL-4',
  },
  {
    ghModel: 'FDS-602',
    trademark: ['Greenheck'],
    compName: 'WonderMetals',
    compModel: 'SDL-6',
  },
  {
    ghModel: 'VASJ-2',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EX-245',
  },
  {
    ghModel: 'VASJ-4',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EX-445',
  },
  {
    ghModel: 'VASJ-6',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EX-645',
  },
  {
    ghModel: 'VAAD-4',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EXASDL-4',
  },
  {
    ghModel: 'VAAD-6',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EXASDL-6',
  },
  {
    ghModel: 'VLPI',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EXPH-430',
  },
  {
    ghModel: 'VLPR',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EXPH-430',
  },
  {
    ghModel: 'VASD-2',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-2',
  },
  {
    ghModel: 'VASD-6',
    trademark: ['Venco'],
    compName: 'WonderMetals',
    compModel: 'EXSDL-6',
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Woods',
    compModel: 'HG',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Woods',
    compModel: 'HG',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'AX',
    trademark: ['Greenheck'],
    compName: 'Woods',
    compModel: 'JM',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAB',
    trademark: ['Greenheck'],
    compName: 'Woods',
    compModel: 'KG',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'VAD',
    trademark: ['Greenheck'],
    compName: 'Woods',
    compModel: 'KG',
    filter: {
      category: 'Inline'
    }
  },
  {
    ghModel: 'MINIVENT',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'Mini Preconditioner',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'MSCF',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'Solution',
  },
  {
    ghModel: 'ERV',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'Standard ERVs',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'ERVE',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'Standard ERVs',
    filter: {
      category: 'EnergyRecoveryVentilators'
    }
  },
  {
    ghModel: 'VFCD',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'YMB',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'YSHW',
  },
  {
    ghModel: 'LFC',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'YSHX',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'YSVW',
  },
  {
    ghModel: 'VFC',
    trademark: ['Greenheck'],
    compName: 'York',
    compModel: 'YSVX',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'ZooFans',
    compModel: 'ECO',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'GJI',
    trademark: ['Greenheck'],
    compName: 'ZooFans',
    compModel: 'JVEC',
  },
  {
    ghModel: 'DS',
    trademark: ['Greenheck'],
    compName: 'ZooFans',
    compModel: 'Titan',
    filter: {
      category: 'Circulator'
    }
  },
  {
    ghModel: 'DC',
    trademark: ['Greenheck'],
    compName: 'ZooFans',
    compModel: 'XP',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'ZooFans',
    compModel: 'ECO',
  },
  {
    ghModel: 'VCFI',
    trademark: ['Venco'],
    compName: 'ZooFans',
    compModel: 'Titan',
  },
  {
    ghModel: 'VCFC',
    trademark: ['Venco'],
    compName: 'ZooFans',
    compModel: 'XP',
  },
];
