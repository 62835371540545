import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { LachesisService } from '../../../analytics/services/lachesis.service';
import { UsersService } from '../../../users/services/users.service';
import { ErrorReportingData } from './models/error-reporting-data';

@Component({
  selector: 'app-error-reporting-dialog',
  templateUrl: './error-reporting-dialog.component.html',
  styleUrls: ['./error-reporting-dialog.component.scss'],
})
export class ErrorReportingDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  email: UntypedFormControl;
  comments: UntypedFormControl;

  validate = false;

  authenticated: boolean;

  constructor(
    private dialog: MatDialog,
    private users: UsersService,
    private dialogRef: MatDialogRef<ErrorReportingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ErrorReportingData,
    private lachesisService: LachesisService
  ) {
    this.email = new UntypedFormControl('', [Validators.email]);

    this.comments = new UntypedFormControl('');

    this.dialog.closeAll();

    this.formGroup = new UntypedFormGroup({
      email: this.email,
      comments: this.comments,
    });

    this.users.getCurrentUser(false).then(
      (userData) => {
        this.authenticated = userData.authenticated;

        this.email.setValue(this.authenticated ? userData.email : '');
      },
      () => {
        this.authenticated = false;
        this.email.setValue('');
      }
    );
  }

  ngOnInit() {}

  submitReport() {
    this.lachesisService.trackUserError(
      this.data.errorID,
      this.data.errorData,
      this.email.value,
      this.comments.value
    );

    this.dialogRef.close();
  }
}
