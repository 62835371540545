<svg
  id="Lab_Exhaust"
  data-name="Lab Exhaust"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 338.32 496.45"
>
  <defs>
    <style>
      .cls-1 {
        stroke: #99a5d4;
        stroke-linejoin: bevel;
        stroke-width: 3px;
      }

      .cls-1,
      .cls-2 {
        stroke-linecap: round;
      }

      .cls-1,
      .cls-2,
      .cls-3 {
        fill: none;
      }

      .cls-4 {
        fill: #bababa;
      }

      .cls-4,
      .cls-5,
      .cls-6,
      .cls-7,
      .cls-3,
      .cls-8 {
        stroke: #aeb4a1;
      }

      .cls-4,
      .cls-6,
      .cls-7,
      .cls-3 {
        stroke-miterlimit: 15.12;
      }

      .cls-5 {
        opacity: 0.65;
      }

      .cls-5,
      .cls-7,
      .cls-2,
      .cls-8 {
        stroke-width: 2px;
      }

      .cls-5,
      .cls-7,
      .cls-8 {
        fill: #e4dbdb;
      }

      .cls-5,
      .cls-8 {
        stroke-linejoin: round;
      }

      .cls-9 {
        font-size: 24px;
      }

      .cls-9,
      .cls-10 {
        font-family: ArialMT, Arial;
      }

      .cls-10 {
        font-size: 21px;
      }

      .cls-6 {
        fill: url(#Gray_Gradient);
      }

      .cls-11 {
        fill: #fffdf3;
      }

      .cls-11,
      .cls-12,
      .cls-13 {
        stroke-width: 0px;
      }

      .cls-12 {
        fill: #916f6f;
      }

      .cls-2 {
        stroke: #0060a3;
      }

      .cls-13 {
        fill: #dbd0d0;
      }
    </style>
    <symbol id="Arrow" viewBox="0 0 20.23 20.65">
      <g>
        <polyline
          id="Arrow_Bottom"
          data-name="Arrow Bottom"
          class="cls-2"
          points="1 19.65 10.12 10.53 19.23 19.65"
        />
        <polyline
          id="Arrow_Middle"
          data-name="Arrow Middle"
          class="cls-2"
          points="1 15.09 10.12 5.97 19.23 15.09"
        />
        <polyline
          id="Arrow_Top"
          data-name="Arrow Top"
          class="cls-2"
          points="1 10.53 10.12 1.41 19.23 10.53"
        />
      </g>
    </symbol>
    <symbol id="Blower_Fan" data-name="Blower Fan" viewBox="0 0 104.33 218.27">
      <g>
        <path
          id="BF_Base"
          data-name="BF Base"
          class="cls-7"
          d="m43.92,214.8c-14.26-4.03-30.28-20.84-28.23-45.74l2.72-55.06h47.51v17.06c59.3,6.36,45.25,102.72-22,83.73Z"
        />
        <circle
          id="BF_Center"
          data-name="BF Center"
          class="cls-7"
          cx="59.76"
          cy="174.1"
          r="17.06"
        />
        <polygon
          id="BF_Mid_Box"
          data-name="BF Mid Box"
          class="cls-7"
          points="18.41 114.01 12.23 77.76 75.61 77.75 65.92 114.01 18.41 114.01"
        />
        <polygon
          id="BF_Top"
          data-name="BF Top"
          class="cls-7"
          points="1.21 77.75 15.62 1 70.89 1 86.63 77.75 1.21 77.75"
        />
      </g>
    </symbol>
    <symbol
      id="Blower_Fan_Arrows"
      data-name="Blower Fan Arrows"
      viewBox="0 0 104.33 243.85"
    >
      <g>
        <use
          width="104.33"
          height="218.27"
          transform="translate(0 25.58)"
          xlink:href="#Blower_Fan"
        />
        <use
          id="In_Arrow"
          data-name="In Arrow"
          width="20.23"
          height="20.65"
          transform="translate(70.69 115.18) rotate(-44.95)"
          xlink:href="#Arrow"
        />
        <use
          id="Out_Arrow"
          data-name="Out Arrow"
          width="20.23"
          height="20.65"
          transform="translate(33.94)"
          xlink:href="#Arrow"
        />
      </g>
    </symbol>
    <linearGradient
      id="Gray_Gradient"
      data-name="Gray Gradient"
      x1="10.53"
      y1="90.29"
      x2="10.53"
      y2="-8.54"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset=".2" stop-color="#ccc" />
      <stop offset="1" stop-color="#fff" />
    </linearGradient>
    <symbol id="Damper" viewBox="0 0 21.06 96.32">
      <g>
        <rect class="cls-6" x=".5" y=".5" width="20.06" height="95.32" />
        <g id="Fin">
          <line class="cls-3" x1="2.56" y1="75.34" x2="18.5" y2="92.84" />
          <ellipse
            class="cls-4"
            cx="10.42"
            cy="83.96"
            rx="1.31"
            ry="1.81"
            transform="translate(-53.84 28.92) rotate(-42.34)"
          />
        </g>
        <g id="Fin-2" data-name="Fin">
          <line class="cls-3" x1="2.67" y1="57.16" x2="18.62" y2="74.66" />
          <ellipse
            class="cls-4"
            cx="10.53"
            cy="65.79"
            rx="1.31"
            ry="1.81"
            transform="translate(-41.56 24.25) rotate(-42.34)"
          />
        </g>
        <g id="Fin-3" data-name="Fin">
          <line class="cls-3" x1="2.67" y1="39.54" x2="18.62" y2="57.03" />
          <ellipse
            class="cls-4"
            cx="10.53"
            cy="48.16"
            rx="1.31"
            ry="1.81"
            transform="translate(-29.69 19.66) rotate(-42.34)"
          />
        </g>
        <g id="Fin-4" data-name="Fin">
          <line class="cls-3" x1="2.67" y1="3.21" x2="18.62" y2="20.71" />
          <ellipse
            class="cls-4"
            cx="10.53"
            cy="11.83"
            rx="1.31"
            ry="1.81"
            transform="translate(-5.22 10.18) rotate(-42.34)"
          />
        </g>
        <g id="Fin-5" data-name="Fin">
          <line class="cls-3" x1="2.67" y1="21.16" x2="18.62" y2="38.66" />
          <ellipse
            class="cls-4"
            cx="10.53"
            cy="29.79"
            rx="1.31"
            ry="1.81"
            transform="translate(-17.32 14.86) rotate(-42.34)"
          />
        </g>
      </g>
    </symbol>
    <symbol
      id="Standard_Fan"
      data-name="Standard Fan"
      viewBox="0 0 97.46 182.39"
    >
      <g>
        <rect
          id="SF_Base"
          data-name="SF Base"
          class="cls-8"
          x="18.18"
          y="158.26"
          width="76.62"
          height="23.13"
        />
        <rect
          id="SF_Bottom_Post"
          data-name="SF Bottom Post"
          class="cls-8"
          x="36.61"
          y="99.39"
          width="37.28"
          height="58.87"
        />
        <rect
          id="SF_Top_Post"
          data-name="SF Top Post"
          class="cls-8"
          x="36.61"
          y="74.48"
          width="37.28"
          height="24.91"
        />
        <rect
          id="SF_Side_Box"
          data-name="SF Side Box"
          class="cls-8"
          x="1"
          y="94.28"
          width="35.61"
          height="53.07"
        />
        <polygon
          id="SF_Top"
          data-name="SF Top"
          class="cls-8"
          points="14.05 74.48 29.44 1 79.75 1 96.46 74.48 14.05 74.48"
        />
        <polygon
          id="SF_Left_Triangle"
          data-name="SF Left Triangle"
          class="cls-8"
          points="36.61 149.53 26.22 158.26 36.61 158.26 36.61 149.53"
        />
        <polygon
          id="SF_Right_Triangle"
          data-name="SF Right Triangle"
          class="cls-8"
          points="73.9 149.53 84.29 158.26 73.9 158.26 73.9 149.53"
        />
      </g>
    </symbol>
    <symbol
      id="Standard_Fan_Arrows"
      data-name="Standard Fan Arrows"
      viewBox="0 0 99.2 205.64"
    >
      <g>
        <use
          width="97.46"
          height="182.39"
          transform="translate(0 23.25)"
          xlink:href="#Standard_Fan"
        />
        <use
          id="In_Arrow-2"
          data-name="In Arrow"
          width="20.23"
          height="20.65"
          transform="translate(70.7 110.07) rotate(-45.44)"
          xlink:href="#Arrow"
        />
        <use
          id="Out_Arrow-2"
          data-name="Out Arrow"
          width="20.23"
          height="20.65"
          transform="translate(44.91)"
          xlink:href="#Arrow"
        />
      </g>
    </symbol>
  </defs>
  <g id="Base">
    <rect
      id="Box"
      class="cls-8"
      x="130.68"
      y="240.65"
      width="116.62"
      height="82.87"
    />
    <polygon
      class="cls-8"
      points="126.36 331.98 130.67 323.51 247.31 323.51 251.36 331.98 126.36 331.98"
    />
  </g>
  <g id="Floor">
    <rect
      id="Bottom"
      class="cls-11"
      x="13.99"
      y="331.98"
      width="324.33"
      height="164.47"
    />
    <g id="Middle">
      <rect
        id="Middle-2"
        data-name="Middle"
        class="cls-13"
        x="13.99"
        y="349.76"
        width="324.33"
        height="26.1"
      />
      <polyline
        id="Line"
        class="cls-1"
        points="336.82 351.56 290.89 373.84 240.88 351.56 194.42 373.84 144.94 351.56 97.96 373.84 49 351.56 1.5 373.84"
      />
    </g>
    <rect
      id="Top"
      class="cls-12"
      x="13.99"
      y="331.98"
      width="324.33"
      height="17.78"
    />
  </g>
  <g id="System_Inlet_Volume" data-name="System Inlet Volume">
    <use
      width="20.23"
      height="20.65"
      transform="translate(179 419.43) scale(1.1)"
      xlink:href="#Arrow"
    />
    <text class="cls-10" transform="translate(204.97 458.66)">
      <tspan x="0" y="0">System Inlet</tspan>
      <tspan x="22.17" y="25.2">Volume</tspan>
    </text>
    <path
      id="Box-2"
      data-name="Box"
      class="cls-5"
      d="m147.74,417.86l-.59-94.35h84.57l.59,94.35c-18.75,2.68-27.16,2.68-38.75-.94-20.11-6.28-30.75.13-45.82.94Z"
    />
  </g>
  <g *ngIf="bypassAirPlenum" id="Bypass_Air" data-name="Bypass Air">
    <text class="cls-10" transform="translate(22.21 322.76)">
      <tspan x="0" y="0">Bypass Air</tspan>
    </text>
    <polygon
      id="Vent"
      class="cls-8"
      points="130.63 240.65 113.06 240.65 71.45 267.3 91.27 299.99 130.63 299.99 130.63 240.65"
    />
    <use
      width="20.23"
      height="20.65"
      transform="translate(70.62 273.54) rotate(57.82)"
      xlink:href="#Arrow"
    />
    <use
      width="21.06"
      height="96.32"
      transform="translate(119.41 246.14) scale(.45 .5)"
      xlink:href="#Damper"
    />
  </g>
  <g id="Fans">
    <g
      *ngFor="let column of columns; let i = index; let last = last"
      id="Column_{{ i + 1 }}"
      [ngSwitch]="fanType"
    >
      <ng-container *ngSwitchCase="'Blower'">
        <use
          id="Blower_Fan_{{ i + 1 }}"
          data-name="Blower Fan {{ i + 1 }}"
          width="104.33"
          height="218.27"
          transform="translate(130.89 102.98)"
          xlink:href="#Blower_Fan"
        />
        <use
          id="Blower_Fan_Arrows_{{ i + 1 }}"
          data-name="Blower Fan Arrows {{ i + 1 }}"
          width="104.33"
          height="243.85"
          transform="translate(130.88 77.44)"
          xlink:href="#Blower_Fan_Arrows"
        />
      </ng-container>

      <ng-container *ngSwitchCase="'Fan'">
        <use
          id="Standard_Fan_{{ i + 1 }}"
          data-name="Standard Fan {{ i + 1 }}"
          width="97.46"
          height="182.39"
          transform="translate(133.9 59.13)"
          xlink:href="#Standard_Fan"
        />
        <use
          id="Standard_Fan_Arrows_{{ i + 1 }}"
          data-name="Standard Fan Arrows {{ i + 1 }}"
          width="99.2"
          height="205.64"
          transform="translate(133.92 35.84)"
          xlink:href="#Standard_Fan_Arrows"
        />
      </ng-container>
    </g>
    <g id="Fan_Labels" data-name="Fan Labels" [ngSwitch]="fanType">
      <text
        *ngSwitchCase="'Blower'"
        id="Blower_Entrained_Air"
        data-name="Blower Entrained Air"
        class="cls-10"
        transform="translate(241.2 202.76)"
      >
        <tspan x="0" y="0">Entrained</tspan>
        <tspan x="32.12" y="25.2">Air</tspan>
      </text>
      <text
        *ngSwitchCase="'Fan'"
        id="Fan_Entrained_Air"
        data-name="Fan Entrained Air"
        class="cls-10"
        transform="translate(241.92 154.5)"
      >
        <tspan x="0" y="0">Entrained</tspan>
        <tspan x="32.12" y="25.2">Air</tspan>
      </text>
    </g>
  </g>
  <text class="cls-9" transform="translate(69.81 20.6)">
    <tspan x="0" y="0">Total System Volume</tspan>
  </text>
</svg>
