import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EmailDialogComponent } from '../dialogs/email-dialog/email-dialog.component';
import { EmailResponse } from '../dialogs/email-dialog/models/email-response.models';

@Injectable({
  providedIn: 'root'
})
export class EmailDialog {
  constructor(private dialog: MatDialog) {}

  show(
    title: string,
    subject?: string,
    message?: string
  ): Promise<EmailResponse> {
    return new Promise<EmailResponse>((results, reject) => {
      const dialogRef = this.dialog.open(EmailDialogComponent, {
        autoFocus: true,

        disableClose: false,

        data: {
          title: title,

          subject: subject,

          message: message
        }
      });

      const subscription = dialogRef.beforeClosed().subscribe(
        (result: EmailResponse) => {
          results(result);
        },
        errorData => {
          reject(errorData);
        },
        () => {
          subscription.unsubscribe();
        }
      );
    });
  }
}
