import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { Item } from '../../projects/models/project-item.model';

export class ProductInfoDialogData {
    size: ValidSize;
    tab: string;
    item: Item;
    tag?: string;
    quantity?: number;


    constructor(size: ValidSize,
        tab: string,
        revisionItem?: Item,
        tag?: string,
        quantity?: number) {

        this.size = size;
        this.tab = tab;
        this.item = revisionItem;
        this.tag = tag;
        this.quantity = quantity;

    }
}


