import { Component, OnInit, Input } from '@angular/core';
import { LayoutConfiguration } from '../../models/layout-config/layout-configuration.model';

@Component({
  selector: 'ds-svg',
  templateUrl: './ds-svg.component.html',
  styleUrls: ['./ds-svg.component.scss'],
})
export class DsSvgComponent implements OnInit {
  @Input('layout-config') layoutConfig: LayoutConfiguration;

  constructor() {}

  ngOnInit() {}
}
