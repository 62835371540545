<side-dialog-title>Elevation Locator</side-dialog-title>

<side-dialog-content>
  <div class="elevation-parent">
    <div class="searchBox">
      <form [formGroup]="formGroup">
        <mat-form-field class="address-field" (keydown)="addressKeyUp($event)">
          <mat-label>Address</mat-label>

          <input
            matInput
            formControlName="addressSearch"
            type="text"
            autocomplete="off"
          />
          <button
            matSuffix
            mat-icon-button
            color="primary"
            (click)="currentLocationClick()"
          >
            <mat-icon>gps_fixed</mat-icon>
          </button>
        </mat-form-field>
      </form>
      <button
        mat-raised-button
        color="primary"
        (click)="searchClick()"
        [disabled]="addressSearch.invalid"
      >
        Locate
      </button>
    </div>
    <div class="map-container">
      <div class="map" #map></div>
      <div class="elevationBox">
        <span>Elevation: {{ elevation | number : '1.0-0' }} Feet</span>
      </div>
    </div>
  </div>
</side-dialog-content>

<side-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    (click)="okClick()"
    [disabled]="addressSearch.invalid"
  >
    OK
  </button>
  <button mat-raised-button color="primary" (click)="cancelClick()">
    Cancel
  </button>
</side-dialog-actions>
