import {
  AfterContentChecked,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../environments/environment';
import { LachesisService } from './analytics/services/lachesis.service';
import { BreadcrumbService } from './breadcrumb/breadcrumb.service';
import { FeedbackDialogComponent } from './dialogs/feedback-dialog/feedback-dialog.component';
import { LoadingGraphic } from './ecaps-core/controllers/loading-graphic.controller';
import { LaunchTypes } from './external-communication/models/launch-types.enum';
import { ExternalCommunicationService } from './external-communication/services/external-communication.service';
import { RevitCommsService } from './external-communication/services/revit-comms.service';
import { GoogleAnalyticsService } from './google/services/google-analytics.service';
import { Project } from './projects/models/project.model';
import { ProjectsService } from './projects/services/projects.service';
import { SideBarGroup } from './side-bar/models/side-bar-group.model';
import { SideBarMenuItem } from './side-bar/models/side-bar-menu-item.model';
import { SideBarService } from './side-bar/services/side-bar.service';
import { SideDialogDirective } from './side-dialog/directives/side-dialog.directive';
import { SideDialogService } from './side-dialog/services/side-dialog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'app';

  sidebarExpanded = true;

  sidebarGroups: Array<SideBarGroup>;
  private sidebarGroupSubscription: Subscription;

  localProject: Project;
  localProjectSubscription: Subscription;

  production = environment.production;

  isRevitPlugin = false;
  revitShowIcon = false;

  @ViewChild('sideDialog', { static: true })
  private sideDialogDrawer: MatDrawer;

  @ViewChild(SideDialogDirective, { static: true })
  private sideDialogContainer: SideDialogDirective;

  constructor(
    analytics: GoogleAnalyticsService,
    public loadingGraphic: LoadingGraphic,
    private externalComm: ExternalCommunicationService,
    private sidebarService: SideBarService,
    private projectsService: ProjectsService,
    private dialog: MatDialog,
    private sideDialogService: SideDialogService,
    private router: Router,
    private lachesisService: LachesisService,
    private revitComms: RevitCommsService,
    private breadcrumbs: BreadcrumbService
  ) {
    this.isRevitPlugin = revitComms.isRevitApp;

    this.revitComms.revitAppUpdated.subscribe(() => {
      this.isRevitPlugin = this.revitComms.isRevitApp;
    });

    if (
      window.localStorage.getItem('lsv') !== environment.localStorageVersion
    ) {
      window.localStorage.clear();

      window.localStorage.setItem('lsv', environment.localStorageVersion);
    }

    this.sidebarGroups = this.sidebarService.sidebarItems;

    this.sidebarGroupSubscription =
      this.sidebarService.sidebarItemsUpdate.subscribe((itemList) => {
        setTimeout(() => {
          this.sidebarGroups = itemList;
        });
      });

    this.projectsService.getLocalProject().then((project) => {
      this.localProject = project;
    });

    this.localProjectSubscription =
      this.projectsService.localProjectUpdated.subscribe((newProject) => {
        this.localProject = newProject;
      });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.lachesisService.trackPage(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    const loadingGraphic = document.getElementsByTagName('loading-graphic')[0];

    (<any>document.body).appendChild(loadingGraphic);

    this.sideDialogService.setDrawer(
      this.sideDialogDrawer,
      this.sideDialogContainer
    );
  }

  ngAfterContentChecked() {
    if (this.loadingGraphic.getLayers().length > 0) {
      this.loadingGraphic.layersUpdated.next();
    }
  }

  @HostListener('document:contextmenu', ['$event'])
  onContextMenu($event: MouseEvent) {
    if (this.revitComms.isRevitApp) {
      $event.preventDefault();
      $event.stopPropagation();
      $event.cancelBubble = true;
      $event.stopImmediatePropagation();
    }
  }

  bodyClick() {
    if (this.sidebarExpanded === true) {
      this.toggleSidebar();
    }
  }

  greenheckClick() {
    this.externalComm.launchExternal(
      environment.greenheckUrls.root,
      LaunchTypes.newWindow
    );
  }

  feedbackClick() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    this.dialog.open(FeedbackDialogComponent, dialogConfig);
  }

  getItemCount(): number {
    if (!this.localProject) {
      return 0;
    } else {
      return this.localProject.getItemCount();
    }
  }

  showMenuItem(menuItem: SideBarMenuItem): boolean {
    if (typeof menuItem.revitEnabled === 'boolean') {
      if (this.isRevitPlugin && !menuItem.revitEnabled) {
        return false;
      }
    }
    if (!!menuItem.visible && !menuItem.visible()) {
      return false;
    }
    return true;
  }

  toggleSidebar() {
    this.sidebarExpanded = !this.sidebarExpanded;
  }

  sidebarItemClick(menuItem: SideBarMenuItem): void {
    this.closeSidebar();

    if (!!menuItem && !!menuItem.click) {
      menuItem.click();
    }
  }

  closeSidebar(): void {
    this.sidebarExpanded = false;
  }

  openSidebar(): void {
    this.sidebarExpanded = true;
  }

  goHome(): void {
    this.breadcrumbs.crumbs = [];

    this.router.navigate(['']);
  }
}
