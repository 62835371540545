export enum ProductEntities {
  circulatorSelector = 'CirculatorSelector',
  fans = 'FanSelector',
  roofSupply = 'RoofSupply',
  sidewallMounted = 'SidewallMounted',
  ceilingAndCabinet = 'CeilingAndCabinet',
  inline = 'InlineSelector',
  vaneAxial = 'VaneAxialSelector',
  blower = 'BlowerSelector',
  gravity = 'GravitySelector',
  plugAndPlenum = 'PlugAndPlenumSelector',
  fumeExhaust = 'FumeExhaust',
  louver = 'LouverSelector',
  temperedAirProduct = 'RV_RVE_Selector',
  makeUpAir = 'MUA_Selector',
  preconditioners = 'Preconditioners_Selector',
  circulatorsDDF = 'DDFSelector'
}
