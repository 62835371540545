import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  FallstreakHttpClient,
  HttpOptions,
  HttpRequestOptions,
  IFallstreakHttpClient,
} from '../../fallstreakClient';
import {
  EcapsStateService,
  methods,
} from '../handlers/services/ecaps-state.service';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private httpClient: IFallstreakHttpClient;

  constructor(private http: HttpClient, private ecapsState: EcapsStateService) {
    this.httpClient = FallstreakHttpClient.FromEnvironment(
      this.http,
      environment
    );
  }

  public get<T = Object>(path: string, options?: HttpOptions): Observable<T> {
    const stateKey = this.ecapsState.addPendingAsyncCall(path, methods.get);

    const subject = new Subject<T>();

    const subscription = this.httpClient.get<T>(path, options).subscribe({
      next: (resultData) => {
        subject.next(resultData);
      },
      error: (errorData) => {
        subject.error(errorData);
      },
      complete: () => {
        subject.complete();

        subscription.unsubscribe();

        this.ecapsState.removePendingAsyncCall(stateKey);
      },
    });

    return subject.asObservable();
  }

  public post<T = Object>(
    path: string,
    body: any,
    options?: HttpOptions
  ): Observable<T> {
    const stateKey = this.ecapsState.addPendingAsyncCall(
      path,
      methods.post,
      body
    );

    const subject = new Subject<T>();

    const subscription = this.httpClient
      .post<T>(path, body, options)
      .subscribe({
        next: (resultData) => {
          subject.next(resultData);
        },
        error: (errorData) => {
          subject.error(errorData);
        },
        complete: () => {
          subject.complete();

          subscription.unsubscribe();

          this.ecapsState.removePendingAsyncCall(stateKey);
        },
      });

    return subject.asObservable();
  }

  public put<T = Object>(
    path: string,
    body: any,
    options?: HttpOptions
  ): Observable<T> {
    const stateKey = this.ecapsState.addPendingAsyncCall(
      path,
      methods.put,
      body
    );

    const subject = new Subject<T>();

    const subscription = this.httpClient.put<T>(path, body, options).subscribe({
      next: (resultData) => {
        subject.next(resultData);
      },
      error: (errorData) => {
        subject.error(errorData);
      },
      complete: () => {
        subject.complete();

        subscription.unsubscribe();

        this.ecapsState.removePendingAsyncCall(stateKey);
      },
    });

    return subject.asObservable();
  }

  public delete<T = Object>(
    path: string,
    options?: HttpOptions
  ): Observable<T> {
    const stateKey = this.ecapsState.addPendingAsyncCall(path, methods.delete);

    const subject = new Subject<T>();

    const subscription = this.httpClient.delete<T>(path, options).subscribe({
      next: (resultData) => {
        subject.next(resultData);
      },
      error: (errorData) => {
        subject.error(errorData);
      },
      complete: () => {
        subject.complete();

        subscription.unsubscribe();

        this.ecapsState.removePendingAsyncCall(stateKey);
      },
    });

    return subject.asObservable();
  }

  public request<T = Object>(
    method: methods | string,
    path: string,
    options?: HttpRequestOptions
  ): Observable<T> {
    const stateKey = this.ecapsState.addPendingAsyncCall(path, method);

    const subject = new Subject<T>();

    const subscription = this.httpClient
      .request<T>(method, path, options)
      .subscribe({
        next: (resultData) => {
          subject.next(resultData);
        },
        error: (errorData) => {
          subject.error(errorData);
        },
        complete: () => {
          subject.complete();

          subscription.unsubscribe();

          this.ecapsState.removePendingAsyncCall(stateKey);
        },
      });

    return subject.asObservable();
  }
}
