import { Injectable } from '@angular/core';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';
import { BathSpCalculatorDialogComponent } from '../dialogs/bath-sp-calculator-dialog/bath-sp-calculator-dialog.component';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../../google/services/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class BathSpCalculator {
  constructor(
    private sideDialogService: SideDialogService,
    private gaServices: GoogleAnalyticsService
  ) {}

  show(): Promise<any> {
    return new Promise<any>((resolve) => {
      this.gaServices.trackEvent_Old(
        EventCategories.staticPressureCalculator,
        'Open'
      );

      const dialogRef = this.sideDialogService.open(
        BathSpCalculatorDialogComponent,
        {
          disableClose: false,
          autoFocus: true,
        }
      );

      const subscription = dialogRef.beforeClose.subscribe((results) => {
        subscription.unsubscribe();

        resolve(results);
      });
    });
  }
}
