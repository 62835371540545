import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RepOfficeInfosService } from '../../../users/services/rep-office-infos.service';
import { LoadingGraphic } from '../../controllers/loading-graphic.controller';
import { IRep, IRepInfo } from './models/i-rep.interface';

interface IAssignmentGroup {
  name: string;
  reps: IRep[];
}

interface IRegionGroup {
  name: string;
  assignments: IAssignmentGroup[];
  reps: IRep[];
}

@Component({
  selector: 'app-rep-locator-dialog',
  templateUrl: './rep-locator-dialog.component.html',
  styleUrls: ['./rep-locator-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RepLocatorDialogComponent implements OnInit {
  protected regionGroups: IRegionGroup[];
  protected repListFiltered: IRep[];
  protected postalcode: string;

  private _selectedRegion: IRegionGroup;
  protected get selectedRegion(): IRegionGroup {
    return this._selectedRegion;
  }
  protected set selectedRegion(value: IRegionGroup) {
    this._selectedRegion = value;
    this.postalcode = '';
    this.selectedState = undefined;
    this.refreshRepList();
  }

  private _selectedState?: IAssignmentGroup;
  protected get selectedState(): IAssignmentGroup | undefined {
    return this._selectedState;
  }
  protected set selectedState(value: IAssignmentGroup | undefined) {
    this._selectedState = value;
    this.postalcode = '';
    this.refreshRepList();
  }

  constructor(
    private dialogRef: MatDialogRef<RepLocatorDialogComponent>,
    private repOfficeService: RepOfficeInfosService,
    private loadingGraphic: LoadingGraphic
  ) {}

  ngOnInit() {
    this.getRepList();
  }

  private getRepList() {
    const processRepList = (repList: IRepInfo[]) => {
      this.regionGroups = this.getRegionGroups(repList);

      this.selectedRegion =
        this.regionGroups.find((region) => region.name === 'USA') ??
        this.regionGroups[0];
    };

    if (!this.repOfficeService.repInfoList) {
      let loading = true;

      this.loadingGraphic.show('Loading...', () => loading);
      this.repOfficeService.loadRepOfficeData().then((repList) => {
        processRepList(repList);

        loading = false;
        this.loadingGraphic.hide();
      });
    } else {
      processRepList(this.repOfficeService.repInfoList);
    }
  }

  private getRegionGroups(repList: IRepInfo[]): IRegionGroup[] {
    return repList
      .filter((rep) => (rep.assignment ?? '').trim().length > 0)
      .map<string>((rep) => rep.region)
      .filter((region, index, self) => self.indexOf(region) === index)
      .sort((a, b) => a.localeCompare(b))
      .map<IRegionGroup>((region) => ({
        name: region,
        assignments: repList
          .filter((rep) => rep.region === region)
          .map((rep) => rep.assignment)
          .filter(
            (assignment, index, self) => self.indexOf(assignment) === index
          )
          .sort((a, b) => a.localeCompare(b))
          .map((assignment) => ({
            name: assignment,
            reps: repList
              .filter(
                (rep) => rep.region === region && rep.assignment === assignment
              )
              .sort((a, b) => a.repInfo.displayOrder - b.repInfo.displayOrder)
              .map((rep) => rep.repInfo),
          })),
        reps: repList
          .filter((rep) => rep.region === region)
          .sort((a, b) => {
            if (a.assignment === b.assignment) {
              return a.repInfo.displayOrder - b.repInfo.displayOrder;
            }

            return a.assignment.localeCompare(b.assignment);
          })
          .map((rep) => rep.repInfo),
      }));
  }

  protected repListByPostalCode() {
    if (this.postalcode.length === 5) {
      this.repOfficeService
        .loadRepOfficeByZipCode(this.postalcode)
        .then((officeNum) => {
          this.repListFiltered = this.selectedRegion.reps.filter(
            (rep) => Number(rep.officeNum).toString() === officeNum
          );
        });
    }
  }

  selectRep(rep: IRepInfo) {
    this.dialogRef.close(rep);
  }

  refreshRepList() {
    this.repListFiltered = !this.selectedState
      ? this.selectedRegion.reps
      : this.selectedState.reps;
  }
}
