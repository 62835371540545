<div class="selection-page">
  <div class="accordion">
    <layout-config-accordion
      [layoutConfig]="layoutConfig"
      (locationUpdated)="locationUpdated($event)"
    >
    </layout-config-accordion>
  </div>

  <div [ngSwitch]="layoutConfig?.modelGroup" class="grid-container">
    <stationary-grid
      *ngSwitchCase="'WindDrivenRain'"
      [layoutConfig]="layoutConfig"
    ></stationary-grid>
    <stationary-grid
      *ngSwitchCase="'Hurricane'"
      [layoutConfig]="layoutConfig"
    ></stationary-grid>
    <stationary-grid
      *ngSwitchCase="'Specialty'"
      [layoutConfig]="layoutConfig"
    ></stationary-grid>
    <stationary-grid
      *ngSwitchCase="'Operable'"
      [layoutConfig]="layoutConfig"
    ></stationary-grid>
    <stationary-grid
      *ngSwitchCase="'Stationary'"
      [layoutConfig]="layoutConfig"
    ></stationary-grid>
    <sidewall-mounted-grid
      *ngSwitchCase="'SidewallMounted'"
      [layoutConfig]="layoutConfig"
    ></sidewall-mounted-grid>
    <ceiling-cabinet-grid
      *ngSwitchCase="'CeilingAndCabinet'"
      [layoutConfig]="layoutConfig"
    ></ceiling-cabinet-grid>
    <blowers-grid
      *ngSwitchCase="'ScrolledBlower'"
      [layoutConfig]="layoutConfig"
    ></blowers-grid>
    <inline-fans-grid
      *ngSwitchCase="'InlineFans'"
      [layoutConfig]="layoutConfig"
    ></inline-fans-grid>
    <vane-axial-grid
      *ngSwitchCase="'VaneAxial'"
      [layoutConfig]="layoutConfig"
    ></vane-axial-grid>
    <gravity-grid
      *ngSwitchCase="'Gravity'"
      [layoutConfig]="layoutConfig"
    ></gravity-grid>
    <plug-plenum-grid
      *ngSwitchCase="'PlugAndPlenum'"
      [layoutConfig]="layoutConfig"
    ></plug-plenum-grid>
    <circulator-grid
      *ngSwitchCase="'Circulator'"
      [layoutConfig]="layoutConfig"
    ></circulator-grid>
    <circulator-ddf-grid
      *ngSwitchCase="'DestratFans'"
      [layoutConfig]="layoutConfig"
    ></circulator-ddf-grid>

    <ng-container *ngSwitchCase="'MakeUpAir'">
      <ng-container [ngSwitch]="layoutConfig?.getQuestion('HeatingType').value">
        <mua-grid *ngSwitchDefault [layoutConfig]="layoutConfig"></mua-grid>
        <mua-none-grid
          *ngSwitchCase="'None'"
          [layoutConfig]="layoutConfig"
        ></mua-none-grid>
      </ng-container>
    </ng-container>

    <erv-grid
      *ngSwitchCase="'Preconditioner'"
      [layoutConfig]="layoutConfig"
    ></erv-grid>

    <roof-exhaust-grid
      *ngSwitchDefault
      [layoutConfig]="layoutConfig"
    ></roof-exhaust-grid>
  </div>
</div>
