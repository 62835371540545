import { EventEmitter } from '@angular/core';
import { SideDialogComponent } from '../classes/side-dialog-component.interface';

export class SideDialogRef {
  componentInstance: SideDialogComponent;

  afterOpen = new EventEmitter<void>();

  afterClosed = new EventEmitter<any>();

  beforeClose = new EventEmitter<any>();
}
