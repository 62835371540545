import { IComms } from './i-comms.interface';

export class TestComms implements IComms {
  appToWeb: (eventID: string, eventName: string, payload: any) => void;

  webToApp(eventName: string, payload: any): Promise<any> {
    return new Promise<any>((respond, reject) => {
      console.debug('[TestComms] webToApp(%o, %o)', eventName, payload);

      respond(null);
    });
  }

  initCallback() {
    console.debug('[TestComms] initCallback()');
  }

  eventResponse(eventID: string, eventName: string, data?: string) {
    console.debug(
      '[TestComms] eventResponse(%o, %o, %o)',
      eventID,
      eventName,
      data
    );
  }

  eventReject(eventID: string, eventName: string, data?: string) {
    console.debug(
      '[TestComms] eventReject(%o, %o, %o)',
      eventID,
      eventName,
      data
    );
  }
}
