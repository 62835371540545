<side-dialog-title>Energy Recovery Model Comparison</side-dialog-title>

<side-dialog-content>
  <div class="header">
    <div class="group">
      <div class="title">Without Energy<br />Recovery</div>
      <div class="sizes">
        <div class="size">
          <div class="image"></div>
          <div class="sizeName">{{ size.name }}</div>
          <button mat-raised-button color="primary" (click)="addProduct(size)">
            Add
          </button>
        </div>
      </div>
    </div>
    <div class="group">
      <div class="title">With Energy Recovery</div>
      <div class="sizes">
        <div
          class="size"
          *ngFor="
            let compareSize of !!compareSelectionResults
              ? compareSelectionResults.validSizes
              : null
          "
        >
          <div class="image"></div>
          <div class="sizeName">{{ compareSize.name }}</div>
          <button
            mat-raised-button
            color="primary"
            (click)="addProduct(compareSize)"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="body">
    <div class="row">
      <div class="title">Enthalpy Recovery Ratio (%)</div>
      <div class="data">-</div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.outputs.housingWheelModel.energyRecoveryPerformance
            .totalOutdoorEfficiencySummer | number : '1.1-1'
        }}
        <mat-icon
          class="enthalpyWarn"
          *ngIf="
            compareSize.outputs.housingWheelModel.energyRecoveryPerformance
              .totalOutdoorEfficiencySummer < 50
          "
          title="Caution: Click Icon for More Information"
          (click)="cautionIconClick(compareSize)"
          >warning</mat-icon
        >
      </div>
    </div>
    <div class="row">
      <div class="title">ER Media</div>
      <div class="data">
        {{
          size.outputs.housingWheelModel.energyRecoveryPerformance
            .energyRecoveryMedia
        }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.outputs.housingWheelModel.energyRecoveryPerformance
            .energyRecoveryMedia
        }}
      </div>
    </div>
    <div class="row">
      <div class="title">Relative Price</div>
      <div class="data">1.00</div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{ compareSize.pricing.price / size.pricing.price | number : '1.2-2' }}
      </div>
    </div>
    <div class="row">
      <div class="title">Weight (lbs)</div>
      <div class="data">{{ size.pricing.weight | number : '1.0-0' }}</div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{ compareSize.pricing.weight | number : '1.0-0' }}
      </div>
    </div>
    <div class="row">
      <div class="title">Dimensions (LxWxH)(in.)</div>
      <div class="data">
        {{
          size.outputs.housingWheelModel.dimensions.length | number : '1.0-0'
        }}
        x
        {{ size.outputs.housingWheelModel.dimensions.width | number : '1.0-0' }}
        x
        {{
          size.outputs.housingWheelModel.dimensions.height | number : '1.0-0'
        }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.outputs.housingWheelModel.dimensions.length
            | number : '1.0-0'
        }}
        x
        {{
          compareSize.outputs.housingWheelModel.dimensions.width
            | number : '1.0-0'
        }}
        x
        {{
          compareSize.outputs.housingWheelModel.dimensions.height
            | number : '1.0-0'
        }}
      </div>
    </div>
    <div class="row">
      <div class="title">MCA/MOP (Amps)</div>
      <div class="data">
        {{ size.outputs.fanModel.minimumCircuitAmps | number : '1.1-1' }} /
        {{
          size.outputs.fanModel.maximumOvercurrentProtection | number : '1.0-0'
        }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{ compareSize.outputs.fanModel.minimumCircuitAmps | number : '1.1-1' }}
        /
        {{
          compareSize.outputs.fanModel.maximumOvercurrentProtection
            | number : '1.0-0'
        }}
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        size.outputs.coolingModel.coolingModelType.toLowerCase() !== 'none'
      "
    >
      <div class="title">Cooling LAT (F)</div>
      <div class="data">
        {{ size.outputs.coolingModel.leavingAirDryBulb | number : '1.1-1' }} /
        {{ size.outputs.coolingModel.leavingAirWetBulb | number : '1.1-1' }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.outputs.coolingModel.leavingAirDryBulb | number : '1.1-1'
        }}
        /
        {{
          compareSize.outputs.coolingModel.leavingAirWetBulb | number : '1.1-1'
        }}
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        size.outputs.coolingModel.coolingModelType.toLowerCase() !== 'none'
      "
    >
      <div class="title">Cooling Dewpoint (F)</div>
      <div class="data">
        {{ size.outputs.coolingModel.leavingAirDewPoint | number : '1.1-1' }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.outputs.coolingModel.leavingAirDewPoint | number : '1.1-1'
        }}
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        size.outputs.coolingModel.coolingModelType.toLowerCase() !== 'none'
      "
    >
      <div class="title">Total Cooling Capacity (MBH)</div>
      <div class="data">
        {{ size.outputs.coolingModel.coolingCapacity | number : '1.0-0' }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.outputs.coolingModel.coolingCapacity | number : '1.0-0'
        }}
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() !==
        'none'
      "
    >
      <div class="title">Heating LAT (F)</div>
      <div class="data">
        {{ size.outputs.heatingModel.leavingDryBulb | number : '1.0-0' }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{ compareSize.outputs.heatingModel.leavingDryBulb | number : '1.0-0' }}
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        size.outputs.heatingModel.selectedHeatingModelType.toLowerCase() !==
        'none'
      "
    >
      <div class="title">
        Heating Capacity ({{
          !!size.selectionLayoutConfig.getQuestion('HeatingType').value &&
          size.selectionLayoutConfig
            .getQuestion('HeatingType')
            .value.toLowerCase()
            .trim() === 'electric'
            ? 'kW'
            : 'MBH'
        }})
      </div>
      <div class="data">
        {{ size.outputs.heatingModel.heatingCapacity | number : '1.0-0' }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.outputs.heatingModel.heatingCapacity | number : '1.0-0'
        }}
      </div>
    </div>
    <div class="row">
      <div class="title">Supply Volume (CFM)</div>
      <div class="data">
        {{
          size.selectionLayoutConfig.getQuestion('SupplyVolume').value
            | number : '1.0-0'
        }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.selectionLayoutConfig.getQuestion('SupplyVolume').value
            | number : '1.0-0'
        }}
      </div>
    </div>
    <div class="row">
      <div class="title">Outdoor Volume (CFM)</div>
      <div class="data">
        {{
          size.selectionLayoutConfig.getQuestion('OutdoorAirVolume').value
            | number : '1.0-0'
        }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.selectionLayoutConfig.getQuestion('OutdoorAirVolume')
            .value | number : '1.0-0'
        }}
      </div>
    </div>
    <div class="row">
      <div class="title">Exhaust Volume (CFM)</div>
      <div class="data">
        {{
          size.selectionLayoutConfig.getQuestion('ExhaustVolumeInput').value
            | zeroToDash : '1.0-0'
        }}
      </div>
      <div
        class="data"
        *ngFor="
          let compareSize of !!compareSelectionResults
            ? compareSelectionResults.validSizes
            : null
        "
      >
        {{
          compareSize.selectionLayoutConfig.getQuestion('ExhaustVolumeInput')
            .value | zeroToDash : '1.0-0'
        }}
      </div>
    </div>
  </div>
</side-dialog-content>

<side-dialog-actions>
  <button mat-raised-button color="primary" (click)="closeClick()">
    Close
  </button>
</side-dialog-actions>
