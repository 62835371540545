import { EventEmitter } from '@angular/core';
import { IGridColumn } from '../../../../selections/grids/models/grid-column.interface';
import { ColumnListDef } from '../../../../selections/grids/constants/column-list.const';
import {
  ZeroToDashPipe,
  HemisphericalSonesPipe,
  SphericalSonesPipe,
  PercentZeroToDash,
  EmptyStringToDashPipe,
} from '../../../../ecaps-core/pipes/custom-pipes.pipe';
import {
  DecimalPipe,
  PercentPipe,
} from '../../../../../../node_modules/@angular/common';
import { FilterTypes } from '../../../../selections/grids/enums/filter-types.enum';

export const ColumnList: ColumnListDef = {
  iconClick: new EventEmitter<string>(),

  columns: [
    {
      key: 'selector',
      text: 'Selector',
      filterType: null,
      render: false,
    },

    {
      key: 'tag',
      text: 'TAG',
      filterType: null,
      render: false,
    },

    {
      key: 'location',
      text: 'LOCATION',
      filterType: null,
    },

    {
      key: 'areaServed',
      text: 'AREA SERVED',
      filterType: null,
    },

    {
      key: 'driveType',
      text: 'DRIVE TYPE',
      filterType: null,
    },

    {
      key: 'operatingVolume',
      text: 'CFM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'totalStaticPressure',
      text: 'TOTAL EXTERNAL SP',
      valueFormat: (value) =>
        new DecimalPipe('en_us').transform(value, '1.0-2'),
      filterType: null,
    },

    {
      key: 'rpm',
      text: 'FAN RPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'supplyFanRPM',
      text: 'Supply FRPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'bhp',
      text: 'BHP',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },

    {
      key: 'supplyFanBHP',
      text: 'BHP',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },

    {
      key: 'motorHP',
      text: 'HP',
      filterType: null,
    },

    {
      key: 'motorQty',
      text: 'MOTOR QTY',
      filterType: null,
    },

    {
      key: 'vcp',
      text: 'V/C/P',
      filterType: null,
    },

    {
      key: 'inletSones',
      text: 'SONES (INLET)',
      valueFormat: (value) => new HemisphericalSonesPipe().transform(value),
      filterType: null,
    },

    {
      key: 'notes',
      text: 'NOTES',
      filterType: null,
      render: false,
    },

    {
      key: 'esp',
      text: 'ESP',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-3'),
      filterType: null,
    },

    {
      key: 'maxRpm',
      text: 'MAX RPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'quantity',
      text: 'QUANTITY',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'fanQuantity',
      text: 'FAN QTY',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'averageAirSpeed',
      text: 'AVG AIR SPEED (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'avgAirSpeedMax',
      text: 'AVG AIR SPEED MAX (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'mfg',
      text: 'MFG',
      filterType: null,
      render: false,
    },

    {
      key: 'modelGroup',
      text: 'TYPE',
      filterType: null,
    },

    {
      key: 'dischargePosition',
      text: 'DISCHARGE POSITION',
      filterType: null,
    },

    {
      key: 'arrangement',
      text: 'ARRANGEMENT',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'watts',
      text: 'WATTS',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'sphericalSones',
      text: 'SONES (SPHERICAL)',
      valueFormat: (value) => new SphericalSonesPipe().transform(value),
      filterType: null,
    },

    {
      key: 'inletDba',
      text: 'DBA (INLET)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'inletLwa',
      text: 'LWA (INLET)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'feg',
      text: 'FEG',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'cfei40Speed',
      text: 'CFEI at 40% Speed',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.2-2'),
      filterType: null,
    },

    {
      key: 'cfei100Speed',
      text: 'CFEI at 100% Speed',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.2-2'),
      filterType: null,
    },

    {
      key: 'fei',
      text: 'FEI',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.2-2'),
      filterType: null,
    },

    {
      key: 'peakTE',
      text: 'PEAK TE (%)',
      valueFormat: (value) =>
        new PercentPipe('en_us').transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'operatingTE',
      text: 'OPERATING TE (%)',
      valueFormat: (value) =>
        new PercentPipe('en_us').transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'ptsPeakTE',
      text: 'PTS WITHIN PEAK (%)',
      valueFormat: (value) => new PercentZeroToDash().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'plumeHeight',
      text: 'EFFECTIVE PLUME HEIGHT (FT)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'dilutionRatio',
      text: 'DILUTION RATIO',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'nozzleOutletVelocity',
      text: 'NOZZLE OUTLET VELOCITY (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'nozzleSize',
      text: 'NOZZLE SIZE (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'fullLoadAmps',
      text: 'NEC FLA (AMPS)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value),
      filterType: null,
    },

    {
      key: 'weight',
      text: 'WEIGHT (LBS)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'enclosure',
      text: 'ENCLOSURE',
      filterType: null,
    },

    {
      key: 'application',
      text: 'APPLICATION',
      filterType: null,
    },

    {
      key: 'actualVolume',
      text: 'CFM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'actualPressureDrop',
      text: 'PRESSURE DROP (IN. WG)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.3-3'),
      filterType: null,
    },

    {
      key: 'actualThroatVelocity',
      text: 'THROAT VELOCITY (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'actualThroatSize',
      text: 'THROAT SIZE (IN.)',
      filterType: null,
    },

    {
      key: 'actualThroatArea',
      text: 'THROAT AREA (SQ FT)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: null,
    },

    {
      key: 'hoodWidth',
      text: 'HOOD WIDTH (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'hoodLength',
      text: 'HOOD LENGTH (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'overallHeight',
      text: 'HEIGHT (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'suggestedWidth',
      text: 'WIDTH (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },

    {
      key: 'suggestedHeight',
      text: 'HEIGHT (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },

    {
      key: 'actualDepth',
      text: 'DEPTH (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'actualVelocity',
      text: 'FREE AREA VELOCITY (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'actualFreeArea',
      text: 'FREE AREA (SQ FT)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: null,
    },

    {
      key: 'freeAreaPercentage',
      text: 'FREE AREA (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'maxVelocity',
      text: 'MAX VELOCITY (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'sectionsWide',
      text: 'SECTIONS WIDE',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'sectionsHigh',
      text: 'SECTIONS HIGH',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'model',
      text: 'MODEL',
      filterType: null,
    },

    {
      key: 'enthalpyRecoveryRatio',
      text: 'ENTHALPY RECOVERY RATIO (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.1-1'),
      filterType: null,
    },

    {
      key: 'coolingDBWB',
      text: 'COOLING LAT (DB/WB)',
      filterType: null,
    },

    {
      key: 'summerDBWB',
      text: 'SUMMER LAT (DB/WB)',
      filterType: null,
    },

    {
      key: 'winterDBWB',
      text: 'WINTER LAT (DB/WB)',
      filterType: null,
    },

    {
      key: 'winterASHRAE901',
      text: 'WINTER ASHRAE 90.1 ERR',
      filterType: null,
    },

    {
      key: 'coolingCap',
      text: 'TOTAL COOLING CAP (MBH)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'coolingEffect',
      text: 'COOLING EFFECT (F)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'heatingLat',
      text: 'HEATING LAT (DB)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'heatingCap',
      text: 'HEATING CAP',
      filterType: null,
    },

    {
      key: 'mcaMop',
      text: 'MCA/MOP',
      filterType: null,
    },

    {
      key: 'mca',
      text: 'MIN CIRCUIT AMPACITY (MCA)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.1-1'),
      filterType: null,
    },
    {
      key: 'mop',
      text: 'MAX OVERCURRENT PROTECTION (MOP)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'dimensions',
      text: 'DIMENSIONS (LxWxH) (IN.)',
      filterType: null,
    },

    {
      key: 'heatingType',
      text: 'HEATING TYPE',
      filterType: null,
    },

    {
      key: 'coolingDB',
      text: 'COOLING DB',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: null,
    },

    {
      key: 'coolingWB',
      text: 'COOLING WB',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-1'),
      filterType: null,
    },

    {
      key: 'wheelDiameter',
      text: 'WHEEL DIAMETER (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: null,
    },

    {
      key: 'wheelRotation',
      text: 'WHEEL ROATION',
      filterType: null,
    },

    {
      key: 'outletVelocity',
      text: 'OUTLET VELOCITY (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'roofOpeningLW',
      text: 'ROOF OPENING (IN.)',
      filterType: null,
    },

    {
      key: 'wallOpening',
      text: 'WALL OPENING (IN.)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-3'),
      filterType: null,
    },

    {
      key: 'operatingFrequency',
      text: 'OPERATING FREQUENCY (HZ)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: null,
    },

    {
      key: 'operatingFanRPM',
      text: 'OPERATING FAN RPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'speedNonAdjusted',
      text: 'NON-ADJUSTED MOTOR SPEED (RPM)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'motorSize',
      text: 'MOTOR SIZE (W)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'motorSizeHP',
      text: 'MOTOR SIZE (HP)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: null,
    },

    {
      key: 'tipSpeed',
      text: 'TIP SPEED (FT/MIN)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: null,
    },

    {
      key: 'outletSones',
      text: 'OUTLET SONES',
      valueFormat: (value) => new HemisphericalSonesPipe().transform(value),
      filterType: null,
    },

    {
      key: 'sones',
      text: 'SONES',
      valueFormat: (value) => new HemisphericalSonesPipe().transform(value),
      filterType: null,
    },

    {
      key: 'dba',
      text: 'DBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'outletDba',
      text: 'OUTLET DBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'outletLwa',
      text: 'OUTLET SOUND POWER (LWA)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'radiatedDba',
      text: 'RADIATED DBA',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'radiatedLwa',
      text: 'RADIATED SOUND POWER (LWA)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'motorFLACalc',
      text: 'FLA (AMPS)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },

    {
      key: 'affectedArea',
      text: 'COVERAGE AREA PER FAN (SQ FT)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'standbyPower',
      text: 'STANDBY POWER PER FAN (W)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'fanSeries',
      text: 'FAN SERIES',
      valueFormat: (value) => new EmptyStringToDashPipe().transform(value),
      filterType: null,
    },

    {
      key: 'fanEfficiency',
      text: 'INTEGRATED EFFICIENCY (CFM/W)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'fanSpacing',
      text: 'FAN SPACING (FT)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'minFanSpacing',
      text: 'MIN FAN SPACING (FT)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: null,
    },

    {
      key: 'impellerDiameter',
      text: 'FAN DIAMETER (FT)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-4'),
      filterType: null,
    },

    {
      key: 'directionOfOperation',
      text: 'DIRECTION OF OPERATION',
      filterType: null,
    },

    {
      key: 'bladeCount',
      text: 'BLADE COUNT',
      filterType: null,
    },

    {
      key: 'dropLength',
      text: 'DROP LENGTH (FT)',
      filterType: null,
    },

    {
      key: 'mountingStructure',
      text: 'MOUNTING STRUCTURE',
      filterType: null,
    },

    {
      key: 'ledLight',
      text: 'LED LIGHT',
      filterType: null,
    },
    {
      key: 'supplyTotalBhp',
      text: 'Supply Total BHP',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },
    {
      key: 'supplySize',
      text: 'Supply Size (HP)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },
    {
      key: 'exhaustBhp',
      text: 'Exhaust Total BHP',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },
    {
      key: 'exhaustSize',
      text: 'Exhaust Size (HP)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },
    {
      key: 'summerASHRAE901ERR',
      text: 'Summer ASHRAE 90.1 ERR (%)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.greaterThan,
    },
    {
      key: 'supplyOutletdBA',
      text: 'Supply Outlet Sound (dBA)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },

    {
      key: 'supplyOutletSones',
      text: 'Supply Outlet Sound (Sones)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'exhaustFRPM',
      text: 'Exhaust FRPM',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'fanPower',
      text: 'Fan Power (W/CFM)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-2'),
      filterType: null,
    },
    {
      key: 'exhaustInletSoundDba',
      text: 'Exhaust Inlet Sound (dBA)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'exhaustFanInletSoundSones',
      text: 'Exhaust Inlet Sound (Sones)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'ashpDeratedLeavingAirDryBulbHeat',
      text: 'Air Source Heat Pump Heating LAT (DB)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'ashpDeratedHeatingCapacity',
      text: 'Air Source Heat Pump Heating Cap (MBH)',
      valueFormat: (value) => new ZeroToDashPipe().transform(value, '1.0-0'),
      filterType: FilterTypes.lessThan,
    },
    {
      key: 'erDevice',
      text: 'ER Device',
      filterType: FilterTypes.contains,
    },
    {
      key: 'erMedia',
      text: 'ER Media',
      filterType: FilterTypes.contains,
    },
  ],

  getColumn(key: string): IGridColumn {
    const oldColumn = ColumnList.columns.find((column) => column.key === key);

    const newColumn: IGridColumn = {
      key: oldColumn.key,
      text: oldColumn.text,
      valueFormat: oldColumn.valueFormat,
      filterType: oldColumn.filterType,
      render: oldColumn.render,
    };

    if (!!oldColumn.icon) {
      newColumn.icon = {
        name: oldColumn.icon.name,
        svg: oldColumn.icon.svg,
        click: oldColumn.icon.click,
        tooltip: oldColumn.icon.tooltip,
      };
    }

    return newColumn;
  },

  getColumns(...keys: string[]): Array<IGridColumn> {
    const output = new Array<IGridColumn>();

    for (const key of keys) {
      output.push(ColumnList.getColumn(key));
    }

    return output;
  },
};
