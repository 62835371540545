import { Component, OnInit } from '@angular/core';
import { LayoutConfiguration } from '../../selections/models/layout-config/layout-configuration.model';
import { LayoutService } from '../../selections/services/layout.service';
import { BusinessUnits } from '../../products/enums/business-units.enum';

@Component({
  selector: 'app-plenum-rating-page',
  templateUrl: './plenum-rating-page.component.html',
  styleUrls: ['./plenum-rating-page.component.scss'],
})
export class PlenumRatingPageComponent implements OnInit {
  layoutConfig: LayoutConfiguration;

  constructor(private layoutService: LayoutService) {
    this.layoutService
      .createLayoutConfiguration('PlenumCalculator', BusinessUnits.fans, null)
      .then((newConfig) => {
        this.layoutConfig = newConfig;
      });
  }

  ngOnInit() {}
}
