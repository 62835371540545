import { Injectable } from '@angular/core';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { ProjectsService } from '../services/projects.service';
import { Projects } from './projects.controller';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CreateNewProjectDialog {
  constructor(
    private core: EcapsCore,
    private projectsService: ProjectsService,
    private projects: Projects,
    private router: Router
  ) {}

  show(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.core
        .showMessageBox({
          title: 'Create New Project',
          message:
            'This action will empty your current job.  Any unsaved changes to your job will be lost.  Do you wish to proceed?',
          icon: 'warning',
          buttons: [
            {
              title: 'Save Job and Create New Project',
              value: 'SAVE_NEW',
            },
            {
              title: 'Create New Project',
              value: 'NEW',
            },
            {
              title: 'Cancel',
            },
          ],
        })
        .then((result: string) => {
          switch (result) {
            case 'SAVE_NEW': {
              this.projects
                .saveProject(
                  undefined,
                  'Create New Project Lightbox',
                  'Save and New'
                )
                .then(() => {
                  this.createNewProject().then(() => {
                    resolve(true);

                    this.router.navigateByUrl('');
                  });
                });

              break;
            }
            case 'NEW': {
              this.createNewProject().then(() => {
                resolve(true);

                this.router.navigateByUrl('');
              });

              break;
            }
            case 'CANCEL': {
              resolve(false);

              break;
            }
          }
        });
    });
  }

  private createNewProject(): Promise<void> {
    return new Promise<void>((resolve) => {
      let creating = true;

      this.core.showLoadingGraphic('Loading...', () => creating);

      this.projectsService.createProject().then((newProject) => {
        this.projectsService.setLocalProject(newProject);

        creating = false;

        this.core.hideLoadingGraphic();

        resolve();
      });
    });
  }
}
