import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { LocationUpdate } from '../../../ecaps-core/dialogs/elevation-dialog/models/location-update.model';
import { Group as LayoutConfigGroup } from '../../models/layout-config/group.model';
import { LayoutConfiguration } from '../../models/layout-config/layout-configuration.model';
import { Question as LayoutConfigQuestion } from '../../models/layout-config/question.model';

@Component({
  selector: 'layout-config-accordion',
  templateUrl: './layout-config-accordion.component.html',
  styleUrls: ['./layout-config-accordion.component.scss'],
})
export class LayoutConfigAccordionComponent implements OnInit, OnChanges {
  @Input('layoutConfig') public layoutConfig: LayoutConfiguration;

  @Output('requiredCompleted') public requiredCompleted =
    new EventEmitter<void>();
  @Output('locationUpdated') public locationUpdated =
    new EventEmitter<LocationUpdate>();
  @Output('selectionChanged') public selectionChanged =
    new EventEmitter<void>();

  private questionsValid: { [key: string]: boolean } = {};

  public accordionGroups: Array<LayoutConfigGroup>;

  protected chromeVersion: number;

  constructor() {
    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    this.chromeVersion = raw ? parseInt(raw[2], 10) : -1;
  }

  ngOnInit() {}

  ngOnChanges() {
    this.getAccordionGroups();
  }

  getAccordionGroups() {
    if (
      this.layoutConfig &&
      this.layoutConfig.group &&
      this.layoutConfig.group.groups &&
      this.layoutConfig.group.groups.length > 0
    ) {
      this.accordionGroups = this.layoutConfig.group.groups.filter(
        (group) => group.visible
      );

      this.questionsValid = {};

      this.accordionGroups.forEach((group) => {
        group.questions.forEach((question) => {
          this.questionsValid[question.name] = true;
        });
      });
    } else {
      this.accordionGroups = null;
    }

    return this.accordionGroups;
  }

  getAccordionQuestions(group: LayoutConfigGroup): Array<LayoutConfigQuestion> {
    if (group && group.questions && group.questions.length) {
      return group.questions.filter((question) => question.visible);
    }
  }

  accordionClicked() {
    const self = this;

    setTimeout(() => {
      self.selectionChanged.next();
    }, 250);
  }

  setAutoFocus(question: LayoutConfigQuestion) {
    const firstQuestion = this.layoutConfig.group.groups
      .filter((subGroup) => subGroup.visible)[0]
      .questions.filter((groupQuestion) => groupQuestion.required)[0];

    return question === firstQuestion;
  }

  questionAnswered() {
    if (this.layoutConfig.requiredComplete()) {
      this.requiredCompleted.next();
    }
  }

  locationUpdatedEvent($event: LocationUpdate) {
    this.locationUpdated.next($event);
  }

  showWarning(group: LayoutConfigGroup): boolean {
    if (!!this.questionsValid) {
      let invalid = false;

      group.questions.forEach((question) => {
        if (!this.questionsValid[question.name]) {
          invalid = true;
        }
      });

      return invalid;
    } else {
      return false;
    }
  }

  validChanged(question: LayoutConfigQuestion, valid: boolean) {
    if (!!this.questionsValid) {
      this.questionsValid[question.name] = valid;
    }
  }
}
