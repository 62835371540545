import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SideBarService } from '../../side-bar/services/side-bar.service';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MaintenancePageComponent implements OnInit {
  constructor(private sidebarService: SideBarService) {}

  ngOnInit() {
    this.sidebarService.setSidebarItems([]);
  }
}
