import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-water-rejection-rating-info-dialog',
  templateUrl: './water-rejection-rating-info-dialog.component.html',
  styleUrls: ['./water-rejection-rating-info-dialog.component.scss'],
})
export class WaterRejectionRatingInfoDialogComponent implements OnInit {
  dataSource = [
    {
      rating: 10,
      description:
        'AMCA 550 (High Velocity Wind Driven Rain) Listed products only',
      iconColor: 'Green',
    },
    {
      rating: 9,
      description:
        'AMCA 500-L Class Wind Driven Rain A (50 MPH/8"): >700 FPM core velocity',
      iconColor: 'Green',
    },
    {
      rating: 8,
      description:
        'AMCA 500-L Class A Wind Driven Rain (50 MPH/8"): >500 FPM - <700 FPM core velocity',
      iconColor: 'Green',
    },
    {
      rating: 7,
      description:
        'AMCA 500-L Class A Wind Driven Rain (50 MPH/8"): <500 FPM core velocity',
      iconColor: 'Green',
    },
    {
      rating: 6,
      description: 'AMCA 500-L BPWP: >1100 FPM free area velocity',
      iconColor: 'Yellow',
    },
    {
      rating: 5,
      description: 'AMCA 500-L BPWP: >950 FPM - <1100 FPM free area velocity',
      iconColor: 'Yellow',
    },
    {
      rating: 4,
      description: 'AMCA 500-L BPWP: >800 FPM - <950 FPM free area velocity',
      iconColor: 'Yellow',
    },
    {
      rating: 3,
      description: 'AMCA 500-L BPWP: >650 FPM - <800 FPM free area velocity',
      iconColor: 'Red',
    },
    {
      rating: 2,
      description: 'AMCA 500-L BPWP: <650 FPM',
      iconColor: 'Red',
    },
    {
      rating: 1,
      description: 'No AMCA or less than 650 FPM if tested',
      iconColor: 'Red',
    },
  ];

  constructor(
    private dialogRef: MatDialogRef<WaterRejectionRatingInfoDialogComponent>
  ) {}

  ngOnInit() {}

  closeClick() {
    this.dialogRef.close();
  }
}
