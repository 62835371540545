export const OutdoorAirAccessories = [
    {
        accessory: 'Energy Wheel Frost Control',
        description: `Energy wheel frost control is available for preventing frost build-up on the wheel
            in climates that have cold outdoor winter temperatures and/or moist indoor conditions. Frost
            control is enabled based upon the outdoor air temperature being less than 5℉ and an increase
            in energy wheel pressure drop.`,
        options: [
            'Time Exhaust',
            'Modulating Wheel',
            'Cycle Wheel'
        ]
    },
    {
        accessory: 'Energy Wheel Rotation Sensor',
        description: `The rotation sensor senses when a wheel failure occurs and generates an alarm on
            the unit’s microprocessor controller.`,
        options: []
    },
    {
        accessory: 'Economizer Control',
        description: `Economizer control can either be used to control the mixing dampers in a unit with
            recirculation or used to control the energy wheel on units that are 100% outdoor air to provide
            free cooling.`,
        options: [
            'Outdoor Air Temperature',
            'Outdoor Air Dew Point'
        ]
    },
    {
        accessory: 'Network Protocol',
        description: `The network protocol allows the unit to interface with a control’s front end.
            Interfacing allows for the ability to both monitor and control the equipment.`,
        options: [
            'BACNet&reg; MS/TP',
            'BACNet&reg; IP',
            'LonWorks&reg;',
            'Modbus&reg; RTU',
            'Modbus&reg; IP'
        ]
    },
    {
        accessory: 'Dirty Filter Sensor',
        description: `The dirty filter sensor monitors filter pressure drop and generates an alarm on the
            unit’s microprocessor controller when filters need to be changed.`,
        options: [
            'Outdoor',
            'Exhaust',
            'Supply',
            'All'
        ]
    },
    {
        accessory: 'Airflow Monitoring',
        description: `Airflow monitoring provides flow stations that are capable of measuring airflow and
            providing a digital readout.`,
        options: [
            'Outdoor Air',
            'Exhaust Air',
            'Outdoor Air and Exhaust'
        ]
    },
    {
        accessory: 'Phase/Brown Out Protection',
        description: `Provides a sensor that protects that the electrical components in the unit during
            phase loss or low voltage situations.`,
        options: []
    },
    {
        accessory: 'Smoke Detectors',
        description: `Field-mounted smoke detectors can be provided with two normally open and two
            normally closed contacts for alarm notification.`,
        options: [
            'Supply: Field-installed',
            'Exhaust: Field-installed',
            'Both: Field-installed'
        ]
    },
    {
        accessory: 'Condensate Overflow',
        description: `The condensate overflow switch is mounted in the drain pan within the unit. If the
            water level in the drain pain reaches a certain level, the switch will open a set of contacts.`,
        options: []
    },
    {
        accessory: 'Damper End Switch',
        description: `Damper end switches are available for 100% outdoor air units to ensure the dampers
            are proven open prior to the fans starting.`,
        options: []
    },
    {
        accessory: 'Vapor Tight Lights',
        description: `Service lights are provided in the unit to make maintenance easier. Service lights
            are single-point power when a factory powered service outlet is selected.`,
        options: []
    },
    {
        accessory: 'Service Outlet',
        description: `Provides a GFCI outlet for service purposes.`,
        options: [
            'Field-wired and powered',
            'Factory-mounted and wired'
        ]
    },
    {
        accessory: 'Hail Guards',
        description: `Coil guards can be provided to protect the coil from hail damage. Coil guards can
            also be utilized to protect the coil from additional damage.`,
        options: []
    },
    {
        accessory: 'Finish',
        description: `Multiple unit finishes are available. The Permatector™ finish provides a 2,500
            hour salt spray rating. Hi-Pro Polyester provides a 5,000 hour salt spray rating. Baked
            enamel colors are available for custom color applications.`,
        options: [
            'Permatector&trade;',
            'Baked Enamel',
            'Hi-Pro Polyester'
        ]
    },
    {
        accessory: 'Coil Coating',
        description: `ElectroFin® coil coating is available to provide a corrosion resistant coating.`,
        options: [
            'Exterior Coil',
            'Interior Coil',
            'All Coils'
        ]
    },
    {
        accessory: 'Roof Curbs',
        description: `Unit roof curbs are available in multiple heights.`,
        options: [
            '14',
            '18',
            '24'
        ]
    },
    {
        accessory: 'Compressor Warranty',
        description: `Standard compressor warranty is one year. An extended warranty can be provided
            for additional coverage (material only).`,
        options: [
            '5-year extended'
        ]
    },
    {
        accessory: 'Indirect Gas-Fired Heat Exchanger Warranty',
        description: `Standard gas-fired heat exchanger warranty is one year. An extended warranty
            can be provided for additional coverage (material only).`,
        options: [
            '5-year extended',
            '10-year extended'
        ]
    },
    {
        accessory: 'Unit Warranty',
        description: `Standard unit warranty is one year. An extended warranty can be provided for
            additional coverage (material only).`,
        options: [
            '1-year extended',
            '2-years (1-year extended)'
        ]
    }
];
