import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectPropertiesDialogComponent } from '../dialogs/project-properties-dialog/project-properties-dialog.component';
import { Project } from '../models/project.model';
import { ProjectSummary } from '../models/project-summary.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectPropertiesDialog {
  constructor(private dialog: MatDialog) {}

  show(
    project: Project | ProjectSummary,
    copyProject: boolean = false,
    name?: string,
    location?: string
  ): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
        project: project,
        copyProject: copyProject,
        name: name,
        location: location
      };

      const dialogRef = this.dialog.open(
        ProjectPropertiesDialogComponent,
        dialogConfig
      );

      const subscription = dialogRef.beforeClosed().subscribe(results => {
        subscription.unsubscribe();

        resolve(!!results);
      });
    });

    return promise;
  }
}
