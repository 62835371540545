import { Item } from './project-item.model';
import { Note } from './project-note.model';
import { EventEmitter } from '@angular/core';
import { ProjectRevisionUpdateTypes } from '../enums/project-revision-update-types.enum';
import { IProjectEvent } from './i-project-event.interface';
import { Project } from './project.model';
import { ProjectItemStatus } from './project-item-status.enum';

export class Revision {
  private _active: boolean;

  public get active(): boolean {
    return this._active;
  }
  public set active(value: boolean) {
    this._active = value;

    this.markDirty();

    this.fireUpdate({
      source: this,
      type: ProjectRevisionUpdateTypes.propertiesUpdated,
    });
  }

  private _comment: string;
  public get comment(): string {
    return this._comment;
  }
  public set comment(value: string) {
    this._comment = value;

    this.markDirty();

    this.fireUpdate({
      source: this,
      type: ProjectRevisionUpdateTypes.propertiesUpdated,
    });
  }

  private _creationDate: Date;
  public get creationDate(): Date {
    return this._creationDate;
  }

  private _id: string;
  public get id(): string {
    return this._id;
  }

  items: Item[] = [];

  private _name: string;
  public get name(): string {
    return this._name;
  }
  public set name(value: string) {
    this._name = value;

    this.markDirty();

    this.fireUpdate({
      source: this,
      type: ProjectRevisionUpdateTypes.propertiesUpdated,
    });
  }

  notes: Note[] = [];

  private _revisionConfigEntityKey: string;
  public get revisionConfigEntityKey(): string {
    return this._revisionConfigEntityKey;
  }

  private _size: number;
  public get size(): number {
    return this._size;
  }
  public set size(value: number) {
    this._size = value;

    this.fireUpdate({
      source: this,
      type: ProjectRevisionUpdateTypes.propertiesUpdated,
    });
  }

  private _project: Project;
  public get project(): Project {
    return this._project;
  }
  public set project(value: Project) {
    this._project = value;
  }

  private _statusMessages: string[] = [];
  public get statusMessages(): string[] {
    return this._statusMessages;
  }

  private _internalStatusMessages: string[] = [];
  public get internalStatusMessages(): string[] {
    return this._internalStatusMessages;
  }

  public get isValid(): boolean {
    return (
      this.items.filter((item) => item.status === ProjectItemStatus.invalid)
        .length === 0
    );
  }

  updated = new EventEmitter<IProjectEvent>();

  constructor(revisionData: any) {
    this._active = revisionData.active;
    this._comment = revisionData.comment;
    this._creationDate = new Date(revisionData.creationDate);
    this._id = revisionData.id;
    this._statusMessages = revisionData.statusMessages;
    this._internalStatusMessages = revisionData.internalStatusMessages;

    this.items = new Array<Item>();

    if (revisionData.items) {
      revisionData.items.forEach((itemData) => {
        this.addItem(new Item(itemData));
      });
    }

    this._name = revisionData.name;

    if (!!revisionData.notes) {
      revisionData.notes.forEach((noteData) => {
        this.addNote(new Note(noteData));
      });
    }

    this._revisionConfigEntityKey = revisionData.revisionConfigEntityKey;

    this._size = revisionData.size;
  }

  toJSON() {
    return {
      active: this._active,
      comment: this._comment,
      creationDate: this._creationDate,
      id: this._id,
      items: this.items.map((item) => item.toJSON()),
      name: this._name,
      notes: this.notes,
      size: this._size,
      revisionConfigEntityKey: this._revisionConfigEntityKey,
    };
  }

  addItem(item: Item) {
    if (!this.items.find((subItem) => subItem.id === item.id)) {
      item.revision = this;

      this.items.push(item);

      this.markDirty();

      this.fireUpdate({
        source: item,
        type: ProjectRevisionUpdateTypes.itemAdded,
      });
    }
  }

  removeItem(item: Item) {
    const index = this.items.findIndex(
      (revisonItem) =>
        revisonItem.id.toLowerCase().trim() === item.id.toLowerCase().trim()
    );

    if (index > -1) {
      this.items.splice(index, 1);

      item.dispose();

      this.markDirty();

      this.fireUpdate({
        source: item,
        type: ProjectRevisionUpdateTypes.itemDeleted,
      });
    }
  }

  addNote(note: Note) {
    if (!this.notes.find((subNote) => subNote.id === note.id)) {
      note.revision = this;

      this.notes.push(note);

      this.markDirty();

      this.fireUpdate({
        source: note,
        type: ProjectRevisionUpdateTypes.noteAdded,
      });
    }
  }

  removeNote(note: Note) {
    const index = this.notes.findIndex((noteItem) => noteItem.id === note.id);

    if (index > -1) {
      this.notes.splice(index, 1);

      this.markDirty();

      this.fireUpdate({
        source: note,
        type: ProjectRevisionUpdateTypes.noteDeleted,
      });
    }
  }

  private fireUpdate(data: IProjectEvent) {
    this.updated.next(data);

    if (!!this.project) {
      this.project.updated.next(data);
    }
  }

  private markDirty() {
    if (!!this.project) {
      this.project.isDirty = true;
    }
  }

  dispose() {
    this.items.forEach((item) => item.dispose());

    this.items.splice(0);
  }
}
