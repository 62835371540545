import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: 'input[toUpperCase]'
})
export class InputUppercaseDirective {

  constructor(private element: ElementRef) { }

  @HostListener('blur')
  onblur() {

    this.element.nativeElement.value = this.element.nativeElement.value.toUpperCase();

  }

}
