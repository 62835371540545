<h2 mat-dialog-title>Invalid Mark</h2>

<mat-dialog-content>
  <div class="title-message">
    <mat-icon class="dp24">warning</mat-icon>
    <span
      >The mark {{ item.tag }} ({{ item.name }}) is invalid and must be
      reselected.</span
    >
  </div>
  <div *ngIf="!production" class="dev-msg-lbl"><b>DEV Messages:</b></div>
  <ul *ngIf="!production">
    <li *ngFor="let devMessage of devMessages">{{ devMessage }}</li>
  </ul>
  <div *ngIf="!production"><b>PROD Messages:</b></div>
  <ul>
    <li *ngFor="let prodMessage of prodMessages">{{ prodMessage }}</li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="reselectClick()">
    Remove &amp; Reselect
  </button>
  <button mat-raised-button color="primary" [matDialogClose]="false">
    Cancel
  </button>
</mat-dialog-actions>
