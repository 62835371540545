import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RevitCommsService } from '../external-communication/services/revit-comms.service';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../google/services/google-analytics.service';
import { LachesisService } from '../analytics/services/lachesis.service';

@Injectable({
  providedIn: 'root',
})
export class RevitAppGuard {
  firstNavigation = true;

  constructor(
    private revitComms: RevitCommsService,
    private googleAnalytics: GoogleAnalyticsService,
    private lachesisService: LachesisService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.firstNavigation) {
      this.firstNavigation = false;

      this.revitComms.revitVersion = route.params.revitVersion;
      this.revitComms.revitPluginVersion = route.params.pluginVersion;

      this.googleAnalytics.application = 'Revit Add-in';
      this.googleAnalytics.revitVersion = route.params.revitVersion;
      this.googleAnalytics.revitAddinVersion = route.params.pluginVersion;

      this.googleAnalytics.trackEvent_Old(
        EventCategories.revitAddin,
        'Revit Version',
        route.params.revitVersion
      );

      this.googleAnalytics.trackEvent_Old(
        EventCategories.revitAddin,
        'Revit Plugin Version',
        route.params.pluginVersion
      );

      if (
        window.location.pathname.toLowerCase().indexOf('/performance/') > -1
      ) {
        this.lachesisService.trackEvent({
          eventName: 'Revit Performance Selection',
          revitVersion: route.params.revitVersion,
          revitPluginVersion: route.params.pluginVersion,
        });

        this.googleAnalytics.trackEvent_Old(
          EventCategories.revitAddin,
          'Performance',
          'Plugin Open'
        );
      } else if (
        window.location.pathname.toLowerCase().indexOf('/models/') > -1
      ) {
        this.lachesisService.trackEvent({
          eventName: 'Revit Model Selection',
          revitVersion: route.params.revitVersion,
          revitPluginVersion: route.params.pluginVersion,
        });

        this.googleAnalytics.trackEvent_Old(
          EventCategories.revitAddin,
          'Model',
          'Plugin Open'
        );
      } else if (
        window.location.pathname.toLowerCase().indexOf('/revit/jobs') > -1
      ) {
        this.lachesisService.trackEvent({
          eventName: 'Revit Jobs Selection',
          revitVersion: route.params.revitVersion,
          revitPluginVersion: route.params.pluginVersion,
        });

        this.googleAnalytics.trackEvent_Old(
          EventCategories.revitAddin,
          'Model',
          'Plugin Open'
        );
      }
    }

    return true;
  }
}
