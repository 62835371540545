import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorReportingDialogComponent } from '../dialogs/error-reporting-dialog/error-reporting-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorReportingDialog {
  lastDialogRef: MatDialogRef<ErrorReportingDialogComponent>;

  constructor(private dialog: MatDialog) {}

  show(errorID: string, errorData: any) {
    return new Promise<boolean>(result => {
      if (!this.lastDialogRef) {
        this.lastDialogRef = this.dialog.open(ErrorReportingDialogComponent, {
          disableClose: false,
          autoFocus: true,
          data: {
            errorID: errorID,
            errorData: errorData
          }
        });

        const subscription = this.lastDialogRef.beforeClosed().subscribe({
          complete: () => {
            subscription.unsubscribe();

            this.lastDialogRef = null;

            result(true);
          }
        });
      } else {
        console.error();

        result(true);
      }
    });
  }
}
