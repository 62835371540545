import { ProjectSummary } from '../../../models/project-summary.model';
import { Project } from '../../../models/project.model';

export class ProjectInfoDialogData {
  publicToken: string;

  projectSummary: ProjectSummary;

  project: Project;

  constructor(projectSummary: ProjectSummary, publicToken: string, project?: Project) {
    this.projectSummary = projectSummary;

    this.publicToken = publicToken;

    this.project = project;
  }
}
