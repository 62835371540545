import { ChartLayer } from './chart-layer.model';
import { ChartGrid } from './chart-grid.model';
import { ChartAxis } from './chart-axis.model';

export class Chart {
    bottomAxis: ChartAxis;
    grid: ChartGrid;
    layers: Array<ChartLayer>;
    leftAxis: ChartAxis;
    rightAxis: ChartAxis;
    title: string;
    type: string;

    constructor(chartData: any) {
        chartData = (chartData || {});

        this.bottomAxis = new ChartAxis(chartData.bottomAxis);
        this.grid = new ChartGrid(chartData.grid);

        this.layers = new Array<ChartLayer>();

        if (chartData.layers && chartData.layers.length > 0) {
            for (let i = 0; i < chartData.layers.length; i++) {
                this.layers.push(new ChartLayer(chartData.layers[i]));
            }
        }

        this.leftAxis = new ChartAxis(chartData.leftAxis);
        this.rightAxis = chartData.rightAxis && new ChartAxis(chartData.rightAxis);
        this.title = chartData.title;
        this.type = chartData.type;
    }
}
