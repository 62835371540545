<h2 mat-dialog-title>eCAPS Error Reporting</h2>

<mat-dialog-content>
  <div class="error-reporting-dialog">
    <span
      >Please feel free to provide any additional information on what caused
      your error. We value your assistance and apologize for the
      inconvenience.</span
    >
    <form [formGroup]="formGroup" [class.validate]="validate">
      <mat-form-field>
        <mat-label>Email</mat-label>

        <input
          matInput
          type="email"
          formControlName="email"
          [readonly]="authenticated"
        />
        <mat-error *ngIf="!!email.errors">{{
          email | fieldErrorToText
        }}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Comments</mat-label>

        <textarea
          matInput
          formControlName="comments"
          autocomplete="off"
        ></textarea>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="submitReport()">
    Submit
  </button>
</mat-dialog-actions>
