import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormatObjectPipe } from '../../../ecaps-core/pipes/custom-pipes.pipe';
import { IEventItemModelsPayload } from '../../../google/services/google-analytics.service';
import { ProductTypes } from '../../../products/enums/product-types.enum';
import { DimensionInfo } from '../../../products/models/dimension-info.model';
import { FileInfo } from '../../../products/models/file-info.model';
import {
  IProduct,
  IProductCategory,
  productGroups,
} from '../../../products/models/product-groups.const';
import { LayoutConfiguration } from '../layout-config/layout-configuration.model';
import { IValidSizeEvent } from './i-valid-size-event.interface';
import { SizeStability } from './size-stability.model';
import { ValidSizeUpdateTypes } from './valid-size-update-types.enum';

export class ValidSize {
  public updated = new EventEmitter<IValidSizeEvent>();

  private _businessUnit: string;
  public get businessUnit(): string {
    return this._businessUnit;
  }
  public set businessUnit(value: string) {
    this._businessUnit = value;
  }

  private _features: any;
  public get features(): any {
    return this._features;
  }
  public set features(value: any) {
    this._features = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _id: string;
  public get id(): string {
    return this._id;
  }

  private _integrationId: string;
  public get integrationId(): string {
    return this._integrationId;
  }

  private _model: string;
  public get model(): string {
    return this._model;
  }

  private _name: string;
  public get name(): string {
    return this._name;
  }

  private _outputs: any;
  public get outputs(): any {
    return this._outputs;
  }

  private _pricing: any;
  public get pricing(): any {
    return this._pricing;
  }

  private _productIndex: string;
  public get productIndex(): string {
    return this._productIndex;
  }

  private _sorting: any;
  public get sorting(): any {
    return this._sorting;
  }

  private _stability: SizeStability;
  public get stability(): SizeStability {
    return this._stability;
  }

  private _selectionLayoutConfig: LayoutConfiguration;
  public get selectionLayoutConfig(): LayoutConfiguration {
    return this._selectionLayoutConfig;
  }

  private _selectionKey: string;
  public get selectionKey(): string {
    return this._selectionKey;
  }

  private _productType: ProductTypes;
  public get productType(): ProductTypes {
    return this._productType;
  }

  private _charts: any;
  public get charts(): any {
    return this._charts;
  }
  public set charts(value: any) {
    this._charts = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _description: Array<string>;
  public get description(): Array<string> {
    return this._description;
  }
  public set description(value: Array<string>) {
    this._description = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _specifications: Array<string>;
  public get specifications(): Array<string> {
    return this._specifications;
  }
  public set specifications(value: Array<string>) {
    this._specifications = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private layoutConfigUpdateSubscription: Subscription;

  private _layoutConfig: LayoutConfiguration;
  public get layoutConfig(): LayoutConfiguration {
    return this._layoutConfig;
  }
  public set layoutConfig(value: LayoutConfiguration) {
    this._layoutConfig = value;

    if (!!this.layoutConfigUpdateSubscription) {
      this.layoutConfigUpdateSubscription.unsubscribe();

      this.layoutConfigUpdateSubscription = null;
    }

    if (!!this._layoutConfig) {
      this.layoutConfigUpdateSubscription =
        this._layoutConfig.updated.subscribe(() => {
          this.fireUpdate({
            source: this._layoutConfig,
            type: ValidSizeUpdateTypes.layoutConfigUpdated,
          });
        });
    }

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _dimensions: Array<DimensionInfo>;
  public get dimensions(): Array<DimensionInfo> {
    return this._dimensions;
  }
  public set dimensions(value: Array<DimensionInfo>) {
    this._dimensions = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _files: Array<FileInfo>;
  public get files(): Array<FileInfo> {
    return this._files;
  }
  public set files(value: Array<FileInfo>) {
    this._files = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _amcaDescription: string[];

  public get amcaDescription(): string[] {
    return this._amcaDescription;
  }
  public set amcaDescription(value: string[]) {
    this._amcaDescription = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _amcaRatings = 'N';
  public get amcaRatings(): string {
    return !!this._amcaRatings
      ? this._amcaRatings.toUpperCase().trim()
      : this._amcaRatings;
  }
  public set amcaRatings(value: string) {
    this._amcaRatings = !!value ? value.toUpperCase().trim() : value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _drawingNotes: string[];
  public get drawingNotes(): string[] {
    return this._drawingNotes;
  }
  public set drawingNotes(value: string[]) {
    this._drawingNotes = value;

    this.fireUpdate({
      source: this,
      type: ValidSizeUpdateTypes.propertyUpdated,
    });
  }

  private _status: string;
  public get status(): string {
    return this._status;
  }
  public set status(value: string) {
    this._status = value;
  }

  private selectionLayoutConfigSubscription: Subscription;

  constructor(
    selectionLayoutConfig: LayoutConfiguration,
    productType: ProductTypes,
    selectionKey: string,
    sizeData: any
  ) {
    this._selectionLayoutConfig = selectionLayoutConfig;

    if (!!this._selectionLayoutConfig) {
      this.selectionLayoutConfigSubscription =
        this._selectionLayoutConfig.updated.subscribe(() => {
          this.fireUpdate({
            source: this._selectionLayoutConfig,
            type: ValidSizeUpdateTypes.selectionLayoutConfigUpdated,
          });
        });
    }

    this._selectionKey = selectionKey;
    this._productType = productType;

    const formatObject = new FormatObjectPipe();

    this._businessUnit =
      sizeData.businessUnit || selectionLayoutConfig.businessUnit || 'Fans'; // EC-3753: Fans does not set this on creation.

    if (sizeData.features) {
      this._features = formatObject.transform(sizeData.features);
    } else {
      this._features = {};
    }

    this._id = sizeData.id;
    this._integrationId = sizeData.integrationId;
    this._model = sizeData.model;
    this._name = sizeData.name;

    if (sizeData.outputs) {
      this._outputs = formatObject.transform(sizeData.outputs);
    } else {
      this._outputs = {};
    }

    if (sizeData.pricing) {
      this._pricing = formatObject.transform(sizeData.pricing);
    } else {
      this._pricing = {};

      const keys = Object.keys(this.outputs);

      const price = keys.find(
        (key) => key.toLowerCase().trim() === 'budgetprice'
      );

      if (typeof price !== undefined) {
        this._pricing['price'] = this.outputs[price];
      } else {
        this._pricing['price'] =
          this.outputs[
            keys.find((key) => key.toLowerCase().trim() === 'price')
          ];
      }

      this._pricing['weight'] = this.outputs['weight'];
    }

    this._productIndex = sizeData.productIndex;

    if (sizeData.sorting) {
      this._sorting = formatObject.transform(sizeData.sorting);
    } else {
      this._sorting = {};

      const keys = Object.keys(this.outputs).filter(
        (key) => key.toLowerCase().trim().indexOf('recommendedorder') === 0
      );

      for (let i = 0; i < keys.length; i++) {
        this._sorting[keys[i]] = this.outputs[keys[i]];
      }
    }

    const stability = sizeData.stability
      ? sizeData.stability
      : {
          stable: this.outputs['stable'],
          stabilityMessages: this.outputs['stabilityMessages'],
        };

    this._stability = new SizeStability(stability);
  }

  getGAModel(): IEventItemModelsPayload {
    const payload: IEventItemModelsPayload = {
      model: this.model,
      modelName: this.name,
      item_brand: this.productType,
    };

    const categoryName = this.layoutConfig?.getCategory()?.name;

    const path: string[] = [];

    if (!!categoryName) {
      const findCategory = (
        name: string,
        parent: IProduct | IProductCategory
      ): IProduct | IProductCategory => {
        let result: IProduct | IProductCategory;

        if (parent.name === name) {
          return parent;
        }

        (parent.children ?? parent.children ?? []).forEach((child) => {
          if (!!result) {
            return;
          }

          result = findCategory(name, child);
        });

        return result;
      };

      let category: IProduct | IProductCategory;

      productGroups.forEach((group) => {
        if (!!category) {
          return;
        }

        if (group.name === categoryName) {
          category = group;
        } else {
          category = findCategory(categoryName, group);
        }
      });

      const genPath = (groupItem: IProduct | IProductCategory): void => {
        if (!!groupItem.parent) {
          genPath(groupItem.parent);
        }

        path.push(groupItem.text);
      };

      genPath(category);

      if (path.length > 0) {
        payload.item_category = path[0];
        payload.item_category2 = path.slice(1).join(' > ');
      }
    }

    return payload;
  }

  imagePath(): string {
    let modelGroup = this.selectionLayoutConfig.modelGroup;
    switch (this.model.toLowerCase().trim()) {
      case 'ax': {
        if (modelGroup.toLowerCase().trim() === 'roofexhaust') {
          modelGroup = 'ax-upblast';
        } else if (modelGroup.toLowerCase().trim() === 'inlinefans') {
          modelGroup = 'ax-inline';
        } else if (modelGroup.toLowerCase().trim() === 'vaneaxial') {
          modelGroup = 'vad';
        } else if (modelGroup.toLowerCase().trim() === 'roofsupply') {
          modelGroup = 'ax-roofsupply';
        } else {
          modelGroup = 'ax-upblast';
        }

        break;
      }
      case 'tbi-ca': {
        if (modelGroup.toLowerCase().trim() === 'roofexhaust') {
          modelGroup = 'tbi-ca-upblast';
        } else if (
          modelGroup.toLowerCase().trim() === 'inlinefans' ||
          modelGroup.toLowerCase().trim() === 'vaneaxial'
        ) {
          modelGroup = 'tbi-ca-inline';
        } else {
          modelGroup = 'tbi-ca-upblast';
        }

        break;
      }
      case 'tbi-fs': {
        if (modelGroup.toLowerCase().trim() === 'roofexhaust') {
          modelGroup = 'tbi-fs-upblast';
        } else if (
          modelGroup.toLowerCase().trim() === 'inlinefans' ||
          modelGroup.toLowerCase().trim() === 'vaneaxial'
        ) {
          modelGroup = 'tbi-fs-inline';
        } else {
          modelGroup = 'tbi-fs-upblast';
        }

        break;
      }
      case 'qei': {
        if (modelGroup.toLowerCase().trim() === 'roofexhaust') {
          modelGroup = 'qei-upblast';
        } else if (modelGroup.toLowerCase().trim() === 'inlinefans') {
          modelGroup = 'qei-inline';
        } else {
          modelGroup = 'qei-upblast';
        }

        break;
      }
      case 'qeid': {
        if (modelGroup.toLowerCase().trim() === 'roofexhaust') {
          modelGroup = 'ax-upblast';
        } else if (modelGroup.toLowerCase().trim() === 'inlinefans') {
          modelGroup = 'qei-inline';
        } else {
          modelGroup = 'qei-upblast';
        }

        break;
      }
      case 'aph': {
        const list = [
          12, 15, 16, 18, 20, 22, 24, 27, 30, 33, 36, 40, 44, 49, 54, 60, 66,
          73,
        ];

        const part = this.name.split('-');

        if (list.indexOf(parseInt(part[1], 10)) > -1) {
          modelGroup = 'aph-dd';
        } else {
          modelGroup = this.model.toLowerCase().trim();
        }

        break;
      }
      case 'dc':
      case 'dc-nl': {
        if (
          this._name.toLowerCase().trim() === 'dc-5-4' ||
          this._name.toLowerCase().trim() === 'dc-5-5' ||
          this._name.toLowerCase().trim() === 'dc-5-6' ||
          this._name.toLowerCase().trim() === 'dc-5-7'
        ) {
          modelGroup = 'DC-5-7';
        } else if (
          this._name.toLowerCase().trim() === 'dc-5-7-nl' ||
          this._name.toLowerCase().trim() === 'dc-5-10-nl' ||
          this._name.toLowerCase().trim() === 'dc-5-14-nl' ||
          this._name.toLowerCase().trim() === 'dc-5-16-nl'
        ) {
          modelGroup = 'DC-5-NL';
        } else {
          modelGroup = 'DC-5';
        }
        break;
      }
      case 'dc-mv': {
        modelGroup = 'DC-5-7';
        break;
      }
      case 'dm': {
        modelGroup = 'DM3';
        break;
      }
      case 'ds': {
        if (this.outputs.numberOfBlades === 3) {
          modelGroup = 'DS-3';
        } else {
          modelGroup = 'DS';
        }

        break;
      }
      default: {
        modelGroup = this.model.toLowerCase().trim();

        break;
      }
    }

    return `/assets/images/models/${modelGroup.toUpperCase().trim()}.png`;
  }

  toJSON(): object {
    const output = {
      businessUnit: this.businessUnit,
      features: this.features,
      id: this.id,
      model: this.model,
      name: this.name,
      outputs: this.outputs,
      pricing: this.pricing,
      productIndex: this.productIndex,
      sorting: this.sorting,
      stability: this.stability,
      selectionLayoutConfig: this.selectionLayoutConfig.toJSON(),
      selectionKey: this.selectionKey,
      productType: this.productType,
      charts: this.charts,
      description: this.description,
      specifications: this.specifications,
      layoutConfig: this.layoutConfig.toJSON(),
      dimensions: this.dimensions,
      files: this.files,
      amcaDescription: this.amcaDescription,
      amcaRatings: this.amcaRatings,
    };

    return output;
  }

  private fireUpdate(data: IValidSizeEvent) {
    this.updated.next(data);
  }

  dispose() {
    if (!!this.layoutConfigUpdateSubscription) {
      this.layoutConfigUpdateSubscription.unsubscribe();
    }

    if (!!this.selectionLayoutConfigSubscription) {
      this.selectionLayoutConfigSubscription.unsubscribe();
    }
  }
}
