import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { LachesisService } from '../../analytics/services/lachesis.service';
import { EcapsStateService } from '../handlers/services/ecaps-state.service';
import { ITrimmedError } from './models/interfaces/i-trimmed-error.interface';

@Injectable({
  providedIn: 'root',
})
export class ErrorLoggingService {
  private lastError: ITrimmedError = {
    message: undefined,
    stack: undefined,
  };

  constructor(
    private lachesisService: LachesisService,
    private ecapsState: EcapsStateService
  ) {}

  getLastError(): ITrimmedError {
    return this.lastError;
  }

  logError(error: any): Promise<string> {
    return new Promise<string>(async (result) => {
      if (
        !!error &&
        !!error.message &&
        error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') ===
          -1
      ) {
        if (
          error.message !== this.lastError.message &&
          error.stack !== this.lastError.stack
        ) {
          this.lastError.message = error.message;
          this.lastError.stack = error.stack;

          const errorId = uuid();
          const state = await this.ecapsState.getCurrentState();

          this.lachesisService.trackError({
            errorId,
            errorMessage:
              !!error.error && !!error.error.message
                ? error.error.message
                : error.message,
            stack:
              !!error.error && !!error.error.stack
                ? error.error.stack
                : error.stack,
            state: state,
          });

          result(errorId);
        }
      }
    });
  }
}
