<h2 mat-dialog-title>{{ data.title || 'eCAPS Error' }}</h2>

<mat-dialog-content>
  <div class="error-dialog">
    <mat-icon class="error-icon dp32">error_outline</mat-icon>
    <div class="error-message">
      {{
        data.message ||
          'An error has occured. Please try your request again later.'
      }}
    </div>
    <div *ngIf="isProduction" class="error-data">Error: {{ data.errorID }}</div>
    <div *ngIf="!isProduction" class="error-data">
      Error: {{ errorMessage }}
    </div>
    <div *ngIf="!isProduction" class="error-data">
      Error Stack: {{ errorStack }}
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="reportClick()">
    Report
  </button>
  <button mat-raised-button color="primary" (click)="okClick()">OK</button>
</mat-dialog-actions>
