import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'side-dialog-actions',
  templateUrl: './side-dialog-actions.component.html',
  styleUrls: ['./side-dialog-actions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideDialogActionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
