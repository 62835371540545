import { Injectable } from '@angular/core';

/**
 * @callback ObserveItemCallback
 * @description Callback executed when an observed element is resized or intersects the viewport.
 * @param {Element} element The element that was observed.
 * @param {boolean} observing True if the element is being observed, false if it is no longer being observed.
 * @returns {boolean} True if the element should continue to be observed, false if it should no longer be observed.
 */
export type ObserveItemCallback = (
  element: Element,
  observing: boolean
) => boolean;

/**
 * The type of observation to perform.
 * 'resize' will observe the element for changes in size.
 * 'intersection' will observe the element for changes in intersection with the viewport.
 */
export type ObservationType = 'resize' | 'intersection';

interface IObserveQueueItem {
  element: Element;
  type: ObservationType;
  callback: ObserveItemCallback;
}

/**
 * This service is used to observe DOM elements for changes.
 */
@Injectable({
  providedIn: 'root',
})
export class ObserverService {
  private _resizeObserver!: ResizeObserver;
  private _intersectionObserver!: IntersectionObserver;
  private _mutationObserver!: MutationObserver;

  private _observeQueue: IObserveQueueItem[] = [];

  constructor() {
    this._resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const items = this._observeQueue.filter(
          (item) => item.element === entry.target && item.type === 'resize'
        );

        if (!!items && items.length > 0) {
          items.forEach((item) => {
            if (!item.callback(item.element, true)) {
              this.removeItem(item);
            }
          });
        } else {
          console.warn('[ObserverService] Resize observer item not found.');

          this._resizeObserver.unobserve(entry.target);
        }
      });
    });

    this._intersectionObserver = new IntersectionObserver((entries) => {
      const entryList = entries.filter((entry) => entry.isIntersecting);
      const processedList: IObserveQueueItem[] = [];
      const missingList: Element[] = [];

      entryList.forEach((entry) => {
        const items = this._observeQueue.filter(
          (item) =>
            item.element === entry.target && item.type === 'intersection'
        );

        if (!!items && items.length > 0) {
          processedList.push(...items);

          items.forEach((item) => {
            if (!item.callback(item.element, true)) {
              this.removeItem(item);
            }
          });
        } else {
          missingList.push(entry.target);

          this._intersectionObserver.unobserve(entry.target);
        }
      });

      console.debug(
        '[ObserverService] Intersection observer item(s) executed. %o',
        processedList
      );

      if (missingList.length > 0) {
        console.warn(
          '[ObserverService] Intersection observer item(s) not found. %o',
          missingList
        );
      }
    });

    this._mutationObserver = new MutationObserver((mutations) => {
      const removeList: IObserveQueueItem[] = [];

      mutations.forEach((mutation) => {
        if (mutation.type === 'childList') {
          mutation.removedNodes.forEach((node) => {
            if (node instanceof Element) {
              const item = this._observeQueue.find(
                (item) => item.element === node || node.contains(item.element)
              );

              if (!!item) {
                removeList.push(item);
              }
            }
          });
        }
      });

      removeList.push(
        ...this._observeQueue.filter(
          (item) =>
            !document.body.contains(item.element) && !removeList.includes(item)
        )
      );

      if (removeList.length > 0) {
        setTimeout(() => {
          const skippedList: IObserveQueueItem[] = [];

          removeList.forEach((item) => {
            if (!document.body.contains(item.element)) {
              this.removeItem(item);
            } else {
              skippedList.push(item);
            }
          });

          console.debug(
            '[ObserverService] Observed item(s) removed: %o',
            removeList.filter((item) => !skippedList.includes(item))
          );

          if (skippedList.length > 0) {
            console.debug(
              '[ObserverService] Observed item(s) skipped: %o',
              skippedList
            );
          }
        });
      }
    });

    this._mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  /**
   * Observe an element for changes.
   * @param {(Element|Element[])} element The element or array of elements to observe.
   * @param {keyof typeof ObservationType} type The type of observation to perform.
   * @param {ObserveItemCallback} callback The callback to execute when the element changes.
   * @see {@link ObserveItemCallback}
   */
  observe(
    element: Element | Element[],
    type: ObservationType,
    callback: ObserveItemCallback
  ): void {
    const elementList = Array.isArray(element) ? element : [element];

    const addedList: IObserveQueueItem[] = [];
    const skippedList: Element[] = [];

    elementList.forEach((elementItem) => {
      if (!document.body.contains(elementItem)) {
        skippedList.push(elementItem);

        return;
      }

      const found = this._observeQueue.find(
        (item) => item.element === elementItem && item.type === type
      );

      if (!!found) {
        return;
      }

      const newItem: IObserveQueueItem = {
        element: elementItem,
        type,
        callback,
      };

      this._observeQueue.push(newItem);
      addedList.push(newItem);

      if (newItem.type === 'resize') {
        this._resizeObserver.observe(newItem.element);
      } else {
        this._intersectionObserver.observe(newItem.element);
      }
    });

    if (addedList.length > 0) {
      console.debug('[ObserverService] Observer item(s) added. %o', addedList);
    }

    if (skippedList.length > 0) {
      console.warn(
        '[ObserverService] Observer item(s) skipped because the elements were not in the DOM. %o',
        skippedList
      );
    }
  }

  /**
   * Stop observing an element for changes.
   * @param element The element to stop observing.
   * @param type The type of observation to stop.
   */
  unobserve(element: Element, type: ObservationType): void {
    const found = this._observeQueue.find(
      (item) => item.element === element && item.type === type
    );

    if (!!found) {
      this.removeItem(found);
    }
  }

  /**
   * Stop observing all elements.
   */
  clear() {
    this._observeQueue.forEach((item) => {
      if (item.type === 'resize') {
        this._resizeObserver.unobserve(item.element);
      } else {
        this._intersectionObserver.unobserve(item.element);
      }
    });

    this._observeQueue = [];
  }

  private removeItem(item: IObserveQueueItem): void {
    if (item.type === 'resize') {
      this._resizeObserver.unobserve(item.element);
    } else {
      this._intersectionObserver.unobserve(item.element);
    }

    const index = this._observeQueue.findIndex(
      (queueItem) => queueItem === item
    );

    if (index > -1) {
      const removedItems = this._observeQueue.splice(index, 1);

      removedItems.forEach((removedItem) => {
        removedItem.callback(removedItem.element, false);
      });
    }
  }
}
