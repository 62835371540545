export class ErrorMessageData {

    title?: string;

    message?: string;

    errorID: string;

    errorData: any;

}
