import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { VentabulaChartComponent } from '../ventabula-chart/ventabula-chart.component';
import { ChartingService } from '../../services/charting.service';
import { ValidSize } from '../../../../selections/models/selection-results/valid-size.model';
import { EcapsCore } from '../../../../ecaps-core/controllers/ecaps-core.controller';

@Component({
  selector: 'ventabula-compare-chart',
  templateUrl: './ventabula-compare-chart.component.html',
  styleUrls: ['./ventabula-compare-chart.component.scss'],
})
export class VentabulaCompareChartComponent implements OnInit {
  @Input('chartType') private chartType: string;
  @Input('sizes') private sizes: Array<ValidSize>;
  @Input('showElevation') showElevation = false;

  @ViewChild('chart', { static: false }) private chart: VentabulaChartComponent;

  constructor(private charting: ChartingService, private core: EcapsCore) {}

  ngOnInit() {
    let loading = true;

    this.core.showLoadingGraphic('Loading...', function () {
      return loading;
    });

    const productType = this.sizes[0].productType;
    const selectionKey = this.sizes[0].selectionKey;

    const idList = new Array<string>();

    for (let i = 0; i < this.sizes.length; i++) {
      idList.push(this.sizes[i].id);
    }

    this.charting
      .getCompareCharts(productType, selectionKey, idList, this.chartType)
      .then(
        (resultData) => {
          this.chart.renderChart(resultData);

          loading = false;

          this.core.hideLoadingGraphic();
        },
        () => {
          this.chart.showChartUnavailable();

          loading = false;

          this.core.hideLoadingGraphic();
        }
      );
  }
}
