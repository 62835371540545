import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DomSanitizer,
  SafeHtml,
  SafeResourceUrl,
} from '@angular/platform-browser';
import { LaunchTypes } from '../../../external-communication/models/launch-types.enum';
import { ExternalCommunicationService } from '../../../external-communication/services/external-communication.service';
import { HelpService } from '../../services/help.service';
import { HelpTypes } from '../../services/models/help-types.enum';
import { IHelpItem } from '../../services/models/interfaces/i-help-item.interface';

@Component({
  selector: 'app-help-item-dialog',
  templateUrl: './help-item-dialog.component.html',
  styleUrls: ['./help-item-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HelpItemDialogComponent implements OnInit {
  helpItem: IHelpItem;
  content: SafeHtml;
  private helpService: HelpService;
  protected videoEmbedUrl?: SafeResourceUrl;

  constructor(
    private dialogRef: MatDialogRef<HelpItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      helpItem: IHelpItem;
      helpService: HelpService;
    },
    private sanitizer: DomSanitizer,
    private extComms: ExternalCommunicationService
  ) {
    this.helpItem = this.data.helpItem;
    this.helpService = this.data.helpService;

    if (typeof this.helpItem.messageBox.content === 'string') {
      this.content = this.sanitizer.bypassSecurityTrustHtml(
        this.helpItem.messageBox.content
      );
    }

    if (!!this.helpItem.messageBox.video) {
      if (this.helpItem.messageBox.video.url.indexOf('embed') === -1) {
        const videoId = this.helpItem.messageBox.video.url
          .split('/')
          .pop()
          .split('?')
          .shift();

        this.videoEmbedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `https://www.youtube.com/embed/${videoId}`
        );
      } else {
        this.videoEmbedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.helpItem.messageBox.video.url
        );
      }
    }
  }

  ngOnInit() {}

  linkClick() {
    this.helpService.showHelp(this.helpItem, HelpTypes.link);
  }

  sideDialogClick() {
    this.dialogRef.close(true);
  }

  videoClick() {
    this.extComms.launchExternal(
      this.helpItem.messageBox.video.url,
      LaunchTypes.newWindow
    );
  }
}
