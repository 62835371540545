import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { ProjectSummary } from '../../models/project-summary.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Revision } from '../../models/project-summar-revision.model';
import { TextChangedEvent } from '../../../ecaps-core/components/inline-editor/models/text-changed-event.model';
import { ProjectsService } from '../../services/projects.service';
import { LoadingGraphic } from '../../../ecaps-core/controllers/loading-graphic.controller';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'project-version-history',
  templateUrl: './project-version-history.component.html',
  styleUrls: ['./project-version-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({ height: '0px', minHeight: '0', display: 'none' })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ProjectVersionHistoryComponent implements OnInit, OnChanges {
  @Input('project-summary') projectSummary: ProjectSummary;

  @Input('read-only') readOnly: boolean;

  @Input('active-only') activeOnly: boolean;

  @Input('show-delete') showDelete = false;

  @Output('selectionChanged') selectionChanged = new EventEmitter<Revision>();

  @Output('revisionDeleted') revisionDeleted = new EventEmitter<string[]>();

  expandedElement: Revision;

  dataSource = new MatTableDataSource<Revision>();

  versionHistoryDisplayColumns = ['version', 'creationDate', 'comment'];

  deletedRevisions: string[] = [];

  constructor(
    private projectsService: ProjectsService,
    private loadingGraphic: LoadingGraphic
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    if (!!this.projectSummary) {
      this.expandedElement = this.projectSummary.getActiveRevision();

      if (this.activeOnly) {
        this.dataSource.data = this.projectSummary.revisions.filter(
          (revision) => revision.active
        );
      } else {
        this.dataSource.data = this.projectSummary.revisions;
      }

      this.dataSource.data.sort((a, b) => {
        if (a.creationDate < b.creationDate) {
          return -1;
        } else if (b.creationDate < a.creationDate) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    if (this.showDelete) {
      this.versionHistoryDisplayColumns = [
        'version',
        'creationDate',
        'comment',
        'actions',
      ];
    } else {
      this.versionHistoryDisplayColumns = [
        'version',
        'creationDate',
        'comment',
      ];

      this.deletedRevisions.splice(0);
    }
  }

  revisionClick(revision: Revision) {
    this.expandedElement = revision;

    this.selectionChanged.next(revision);
  }

  commentChanged($event: TextChangedEvent, revision: Revision) {
    let updating = true;

    this.loadingGraphic.show('Updating...', () => updating);

    this.projectsService
      .updateSavedProjectRevision(this.projectSummary, revision, {
        comment: $event.newText,
      })
      .then(() => {
        updating = false;

        this.loadingGraphic.hide();
      });
  }

  deleteRevision(revision: Revision) {
    this.deletedRevisions.push(revision.id);

    this.revisionDeleted.next(this.deletedRevisions);
  }

  undoDeleteRevision(revision: Revision) {
    this.deletedRevisions.splice(
      this.deletedRevisions.findIndex((item) => item === revision.id),
      1
    );

    this.revisionDeleted.next(this.deletedRevisions);
  }

  isDeleted(revision: Revision): boolean {
    return this.deletedRevisions.findIndex((item) => item === revision.id) > -1;
  }
}
