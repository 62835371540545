<div class="equipment-schedule-grid-parent">
  <div class="customize-header" *ngIf="showCustomize === true">
    <button mat-icon-button matTooltip="Customize Grid" (click)="customize()">
      <mat-icon>settings</mat-icon>
    </button>
  </div>

  <div class="equipment-schedule-grid">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      [matSortActive]="sortKey"
      [matSortDirection]="sortDir"
      (matSortChange)="sortChange()"
    >
      <ng-container matColumnDef="selector">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            #selectAllCheckbox
            color="primary"
            (change)="selectAllChange($event)"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            [checked]="rowSelected(element.itemData)"
            (change)="rowSelect($event, element.itemData)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="tag">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TAG</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngSwitch]="element.itemData.status"
        >
          <button
            *ngSwitchCase="'INVALID'"
            mat-button
            color="warn"
            [matTooltip]="getStatusMessages(element.itemData)"
            (click)="invalidItemClick(element.itemData)"
          >
            <span>{{ element.tag }}</span>
            <mat-icon color="warn">warning</mat-icon>
          </button>
          <button
            *ngSwitchDefault
            mat-button
            color="primary"
            (click)="tagClick(element.itemData)"
          >
            {{ element.tag }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="mfg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>MFG</th>
        <td mat-cell *matCellDef="let element">Greenheck</td>
      </ng-container>

      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>NOTES</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-button
            color="primary"
            [matMenuTriggerFor]="noteMenu"
            #menuTrigger="matMenuTrigger"
            [disabled]="element.itemData.status === 'INVALID'"
            (click)="editItemNotes(menuTrigger, element.itemData)"
          >
            {{ getItemNoteText(element.itemData) }}
          </button>
        </td>
      </ng-container>

      <ng-container *ngFor="let column of columnList">
        <ng-container
          *ngIf="column.render !== false"
          [matColumnDef]="column.key"
        >
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <span [innerHtml]="column.text"></span>
            <ng-template [ngIf]="!!column.icon">
              <button mat-icon-button [matTooltip]="column.icon.tooltip">
                <ng-container [ngSwitch]="column.icon.svg">
                  <mat-icon
                    *ngSwitchCase="true"
                    [svgIcon]="column.icon.name"
                    (click)="column.icon?.click()"
                  ></mat-icon>
                  <mat-icon
                    *ngSwitchCase="false"
                    (click)="column.icon?.click()"
                    >{{ column.icon.name }}</mat-icon
                  >
                </ng-container>
              </button>
            </ng-template>
          </th>
          <td mat-cell *matCellDef="let element">
            {{
              column.valueFormat
                ? column.valueFormat(element[column.key])
                : element[column.key]
            }}
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="visibleColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: visibleColumns"
        [class.invalid-item]="row.itemData.status === 'INVALID'"
      ></tr>
    </table>
  </div>

  <div class="notes-parent">
    <div class="title">Library of Notes</div>
    <div class="notes-list">
      <mat-list role="list" verticalWrap="min-content">
        <ng-container *ngFor="let note of userNotes">
          <mat-list-item
            *ngIf="note.id !== -1"
            role="listitem"
            [overflowTooltip]="note.content"
            [overflowElement]="content"
            [class.default-note]="note.defaultNote"
            [class.custom-note]="!note.defaultNote"
          >
            <div #content class="note-content">
              <ng-container *ngIf="note.defaultNote">{{
                note.content
              }}</ng-container>

              <inline-editor
                *ngIf="!note.defaultNote"
                [text]="note.content"
                (textChanged)="customNoteEdited($event, note)"
                [showDelete]="true"
                (deleteClicked)="deleteCustomNote(note)"
              ></inline-editor>
            </div>
          </mat-list-item>
        </ng-container>

        <mat-list-item role="listitem" class="new-note">
          <form [formGroup]="newNoteForm">
            <mat-form-field subscriptSizing="dynamic">
              <input
                matInput
                formControlName="newNote"
                type="text"
                placeholder="New Note"
                (keyup.enter)="addNewNote()"
                (keyup.escape)="newNote.reset()"
                (blur)="addNewNote()"
                autocomplete="off"
              />
            </mat-form-field>
          </form>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>

<mat-menu class="equipment-schedule-note-menu" #noteMenu="matMenu">
  <ng-template matMenuContent>
    <div class="note-list-container">
      <mat-selection-list #noteListMenu color="primary">
        <mat-list-option
          *ngFor="let note of notesMenuList"
          [value]="note"
          [selected]="note.checked"
          (stopPropClick)="noop()"
          togglePosition="before"
          [overflowTooltip]="note.content"
        >
          {{ note.content }}
        </mat-list-option>
      </mat-selection-list>

      <div class="action-bar">
        <button
          mat-raised-button
          color="primary"
          (stopPropClick)="itemNoteOKClick()"
        >
          OK
        </button>
        <button
          mat-raised-button
          color="primary"
          (stopPropClick)="itemNoteClearAllClick()"
        >
          Clear All
        </button>
      </div>
    </div>
  </ng-template>
</mat-menu>
