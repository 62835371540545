<h2 mat-dialog-title>Current Project Properties</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup" [class.validate]="validate">
    <mat-form-field>
      <mat-label>Job Name</mat-label>

      <input
        matInput
        type="text"
        ghSpecialChars
        formControlName="jobName"
        autofocus
      />
      <mat-error *ngIf="!!jobName.errors">{{
        jobName | fieldErrorToText
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Location</mat-label>

      <input matInput type="text" formControlName="location" />
      <mat-error *ngIf="!!location.errors">Invalid Location</mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="okClick()">OK</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="false">
    Cancel
  </button>
</mat-dialog-actions>
