import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { LaunchTypes } from '../../external-communication/models/launch-types.enum';
import { ExternalCommunicationService } from '../../external-communication/services/external-communication.service';
import { LouverCertificationDialog } from '../controllers/louver-certification-dialog.controller';
import { BusinessUnits } from '../enums/business-units.enum';
import { ProductEntities } from '../enums/product-entities.enum';
import { ProductTypes } from '../enums/product-types.enum';
import {
  FlagParent,
  FlagPositions,
  IFlag,
  IInfoLink,
  IProduct,
  IProductCategory,
  IToolboxItem,
  IToolboxItemBase,
  IToolboxItemSvg,
  productGroups,
} from '../models/product-groups.const';

export class Flag implements IFlag {
  private _class?: string;
  public get class(): string {
    const classes: string[] = [
      'flag',
      `flag__${this.position.toLowerCase().replace(/ /g, '-')}`,
    ];

    if (!!this._class) {
      classes.push(this._class);
    }

    return classes.join(' ');
  }

  text: string;
  position: FlagPositions;
  parent: FlagParent;

  constructor(init: IFlag) {
    const { class: _class, ...rest } = init;

    this._class = _class;

    Object.assign(this, rest);
  }
}

/**
 * Organizational grouping of products
 */
export class ProductGroup implements IProduct {
  /**
   * Returns the CSS class for the product group
   */
  public get cssClass(): string {
    const classes: string[] = [];

    if (!!this.parent) {
      classes.push(
        `product-parent__${this.parent.name.toLowerCase().replace(/ /g, '-')}`
      );
    }

    classes.push(
      `product-group__${this.name.toLowerCase().replace(/ /g, '-')}`
    );

    return classes.join(' ');
  }

  private _schedulable?: boolean;
  /**
   * Returns whether or not the product group is schedulable
   */
  public get schedulable(): boolean {
    if (this._schedulable !== undefined) {
      return this._schedulable;
    } else if (!!this.parent) {
      return this.parent.schedulable;
    } else {
      return false;
    }
  }

  private _visible?: boolean;
  /**
   * Returns whether or not the product group is visible in production environments
   */
  public get visible(): boolean {
    if (this._visible !== undefined) {
      return this._visible;
    } else if (!!this.parent) {
      return this.parent.visible;
    } else {
      return true;
    }
  }

  private _revitVisible?: boolean;
  /**
   * Returns whether or not the product group is visible in Revit environments
   */
  public get revitVisible(): boolean {
    if (this._revitVisible !== undefined) {
      return this._revitVisible;
    } else if (!!this.parent) {
      return this.parent.revitVisible;
    } else {
      return true;
    }
  }

  private _disabled?: boolean;
  /**
   * Returns whether or not the product group is disabled
   */
  public get disabled(): boolean {
    if (this._disabled !== undefined) {
      return this._disabled;
    } else if (!!this.parent) {
      return this.parent.disabled;
    } else {
      return false;
    }
  }

  private _toolboxItems?: ToolboxItem[];
  public get toolboxItems(): ToolboxItem[] {
    if (this._toolboxItems !== undefined) {
      return this._toolboxItems;
    } else if (!!this.parent) {
      return this.parent.toolboxItems;
    } else {
      return [];
    }
  }
  public set toolboxItems(value: ToolboxItem[]) {
    this._toolboxItems = value;
  }

  private _flags?: Flag[];
  public get flags(): Flag[] | undefined {
    return this._flags;
  }

  parent?: ProductGroup;
  name: string;
  text: string;
  description?: string[];
  image: string;
  infoLink?: IInfoLink;
  children?: ProductGroup[];
  click?: () => void;

  constructor(init: IProduct) {
    const {
      parent,
      children,
      schedulable,
      visible,
      revitVisible,
      disabled,
      flags,
      ...rest
    } = init;

    Object.assign(this, rest);

    this._schedulable = schedulable;
    this._visible = visible;
    this._revitVisible = revitVisible;
    this._disabled = disabled;

    if (flags) {
      this._flags = flags.map((flag) => new Flag(flag));
    }

    if (children) {
      this.children = children.map((child) => {
        if ('type' in child) {
          return new ProductCategory(child);
        } else {
          return new ProductGroup(child);
        }
      });
    }
  }

  /**
   * Recursively search through all children and sub-children to find a product by name
   * @param {string} name The name of the product to find
   * @returns {ProductCategory} The product if found, otherwise undefined
   */
  findCategory(name: string): ProductGroup | undefined {
    let found: ProductGroup | undefined;

    if (this.children) {
      this.children.forEach((child) => {
        if (!found) {
          if (child.name.trim().toLowerCase() === name.trim().toLowerCase()) {
            found = child;
          } else if (child.children) {
            found = child.findCategory(name);
          }
        }
      });
    }

    return found;
  }
}

/**
 * Selectable product category
 */
export class ProductCategory extends ProductGroup implements IProductCategory {
  /**
   * Returns the CSS class for the product category
   */
  public get cssClass(): string {
    const classes: string[] = [];

    if (!!this.parent) {
      classes.push(
        `product-parent__${this.parent.name.toLowerCase().replace(/ /g, '-')}`
      );
    }

    classes.push(
      `product-category__${this.name.toLowerCase().replace(/ /g, '-')}`
    );

    return classes.join(' ');
  }

  type: ProductTypes;
  trackingType?: ProductTypes;
  bu: BusinessUnits;
  entity: ProductEntities;
  customRoute?: string;
  hasElevation?: boolean;
  defaultAnswers: { [key: string]: any };
  selectionRequestData?: { [key: string]: any };

  constructor(init: IProductCategory) {
    super(init);

    const {
      type,
      trackingType,
      bu,
      entity,
      customRoute,
      hasElevation,
      defaultAnswers,
      selectionRequestData,
    } = init;

    Object.assign(this, {
      type,
      trackingType,
      bu,
      entity,
      customRoute,
      hasElevation,
      defaultAnswers,
      selectionRequestData,
    });
  }
}

/**
 * Toolbox item
 */
export class ToolboxItem implements IToolboxItemBase {
  /**
   * Returns the CSS class for the toolbox item
   */
  public get cssClass(): string {
    return `toolbox-item__${this.name.toLowerCase().replace(/ /g, '-')}`;
  }

  svgIcon?: string;
  icon?: string;
  name: string;
  text: string;
  visible: boolean;
  disabled: boolean;
  improved: boolean;
  revitVisible?: boolean;
  click: () => any;

  constructor(init: IToolboxItem | IToolboxItemSvg) {
    Object.assign(this, init);
  }
}

/**
 * Interface for mapping ToolboxItems to ProductGroups / ProductCategories
 */
interface IToolboxItemMap {
  name: string;
  items: string[];
  children?: IToolboxItemMap[];
}

@Injectable({
  providedIn: 'root',
  deps: [Router, ExternalCommunicationService, LouverCertificationDialog],
})
export class ProductsService {
  productGroups!: ProductGroup[];

  toolboxItems: ToolboxItem[];

  constructor(
    private router: Router,
    private externalComm: ExternalCommunicationService,
    private louverCertificationDialog: LouverCertificationDialog
  ) {
    console.debug(
      '[ProductsService] constructor(%o, %o, %o)',
      router,
      externalComm,
      louverCertificationDialog
    );

    this.setupGroups();

    this.setupToolboxItems();
  }

  /**
   * Recursively search through all product groups to find a product by name
   * @param {string} name The name of the product to find
   * @returns {ProductCategory} The product if found, otherwise undefined
   */
  findCategory(name: string): ProductGroup | undefined {
    let found: ProductGroup | undefined;

    this.productGroups.forEach((group) => {
      if (!found) {
        if (group.name.trim().toLowerCase() === name.trim().toLowerCase()) {
          found = group;
        } else if (!!group.children) {
          found = group.findCategory(name);
        }
      }
    });

    return found;
  }

  /**
   * Returns an array of ToolboxItems based on the provided names
   * @param {string[]} items An array of names of ToolboxItems to return
   * @returns {ToolboxItem[]} An array of ToolboxItems that match the provided names in the same order
   */
  getToolboxItems(items: string[]): ToolboxItem[] {
    items = items.map((item) => item.trim().toLowerCase());

    return this.toolboxItems
      .filter((item) => items.includes(item.name.trim().toLowerCase()))
      .sort(
        (a, b) =>
          items.indexOf(a.name.trim().toLowerCase()) -
          items.indexOf(b.name.trim().toLowerCase())
      );
  }

  private setupGroups(): void {
    this.productGroups = productGroups.map((group) => new ProductGroup(group));

    const assignParents = (children: ProductGroup[], parent?: ProductGroup) => {
      for (const child of children) {
        child.parent = parent;

        if (child.children) {
          assignParents(child.children, child);
        }
      }
    };

    // Recursively assign parents to all children in the product groups
    assignParents(this.productGroups);

    const self = this;

    [
      {
        name: 'ATU',
        click: () => {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10018/ATUSelection_FlowChart.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'Dampers',
        click: () => {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10002/InteractiveDamperSelectionGuide.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'GRD',
        click: () => {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10018/DiffuserGrilleSelectionFlowCharts.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'HLCP',
        click: () => {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10018/HLC_FlowChart.pdf',
            LaunchTypes.newWindow
          );
        },
      },
    ].forEach((item) => {
      const group = this.productGroups.find((g) => g.name === item.name);

      if (!!group) {
        group.click = item.click;
      }
    });
  }

  private setupToolboxItems(): void {
    const self = this;

    const itemList: (IToolboxItem | IToolboxItemSvg)[] = [
      {
        name: 'damperSelectionGuide',
        text: 'Damper Selection Guide',
        icon: 'book',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10002/InteractiveDamperSelectionGuide.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'atuSelectionGuide',
        text: 'Air Terminal Units Selection Guide (Excludes Canada)',
        icon: 'assessment',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10018/ATUSelection_FlowChart.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'revitAddIn',
        text: 'eCAPS Bridge for Revit',
        svgIcon: 'revit',
        visible: true,
        disabled: false,
        improved: false,
        revitVisible: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://www.greenheck.com/resources/software/ecaps-bridge-for-revit',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'grdSelectionGuide',
        text: 'Grille, Register, and Diffuser Selection Guide (Excludes Canada)',
        icon: 'description',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10018/DiffuserGrilleSelectionFlowCharts.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'faq',
        text: 'Get The Most From eCAPS',
        icon: 'info_outline',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.router.navigate(['/faqs']);
        },
      },
      {
        name: 'systemEffectSimulator',
        text: 'System Effect Simulator',
        svgIcon: 'ses',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://ses.greenheck.com/',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'variGreenMotorAndControls',
        text: 'Vari-Green Motor and Controls',
        icon: 'cached',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            `${environment.greenheckUrls.root}/vari-green`,
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'airflowMonitoring',
        text: 'Sure-Aire Airflow Monitoring',
        svgIcon: 'sure_aire',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://www.youtube.com/embed/hEdxg_xnmis',
            LaunchTypes.videoPopup,
            'Sure-Aire Airflow Monitoring'
          );
        },
      },
      {
        name: 'onlineFanCourses',
        text: 'Online Courses (PDH Credit)',
        icon: 'school',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            `${environment.greenheckUrls.root}/resources/education/online-trainings`,
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'ddfSpecSheet',
        text: 'DDF Specification Sheet',
        icon: 'book',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10018/DDF_SalesSheet.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'louverComparisonGuide',
        text: 'Louver Comparison Guide',
        icon: 'book',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10002/' +
              'InteractiveLouverProductSelectionGuide.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'hurricaneTesting',
        text: 'Hurricane Testing',
        svgIcon: 'hurricane_testing',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://www.youtube.com/embed/-tKLRIWrceA',
            LaunchTypes.videoPopup,
            'Hurricane Testing'
          );
        },
      },
      {
        name: 'waterPenetration',
        text: 'Water Penetration',
        svgIcon: 'droplet',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://www.youtube.com/embed/olo4v3JQbHo',
            LaunchTypes.videoPopup,
            'Water Penetration'
          );
        },
      },
      {
        name: 'certificationDescriptions',
        text: 'Certification Descriptions',
        icon: 'info_outline',
        revitVisible: true,
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.louverCertificationDialog.show();
        },
      },
      {
        name: 'energyRecoveryTechnologies',
        text: 'Energy Recovery Technologies',
        icon: 'info_outline',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://greenheck-cms-prod.azureedge.net/greenheck-cms-prod/docs/default-source/pdf-downloads/' +
              'application-articles/era115-15-energy-recovery-technologies.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'selectionGuide',
        text: 'Selection Guide To More Products',
        icon: 'book',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10002/ERV_MUA_Coils_Selection_Guide.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'dxCoolingSystem',
        text: 'What Is A DX Cooling System?',
        icon: 'ac_unit',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10002/DXCooling_otherinfo.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'outdoorAirAccessories',
        text: 'Outdoor Air Accessories',
        icon: 'info_outline',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.router.navigateByUrl('/outdoorairaccessories');
        },
      },
      {
        name: 'supplyFanGuide',
        text: 'Supply Fan Selection Guide',
        icon: 'book',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            '../../assets/docs/Selecting_Best_Supply_Fan_MUA.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'muaProductCatalogs',
        text: 'Make-up Air Product Catalogs',
        icon: 'info_outline',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'http://www.greenheck.com/products/air-conditioning/make-up-air',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'muaControls',
        text: 'Make-up Air Controls',
        icon: 'info_outline',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'http://www.greenheck.com/products/air-conditioning/make-up-air/make-up-air-controls',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'energyRecoveryRequirementsForIECC',
        text: 'Energy Recovery Requirements For IECC/ASHRAE 90.1',
        svgIcon: 'er_wheel',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://greenheck-cms-prod.azureedge.net/greenheck-cms-prod/docs/default-source/pdf-downloads/' +
              'application-articles/era116-16_ashrae-90-1.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'energyRecoveryProductCatalog',
        text: 'Energy Recovery Product Catalog',
        icon: 'book',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10011/erv_preconditioners_catalog.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'creatingASchedule',
        text: 'Create Excel Schedule',
        svgIcon: 'excel',
        visible: true,
        disabled: false,
        improved: false,
        revitVisible: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://greenheck.wistia.com/medias/d5iagkpom1',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'ds3SpecSheet',
        text: 'DS-3 Specifications',
        icon: 'list-alt',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10014/Amplify_HVLS_DS3.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'ds6SpecSheet',
        text: 'DS-6 Specifications',
        icon: 'list-alt',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10014/Amplify_HVLS_DS6.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'dc5LargeSpecSheet',
        text: 'DC-5 (Large) Specifications',
        icon: 'list-alt',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10014/Amplify_HVLS_DC5_%20Large.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'dc5SmallSpecSheet',
        text: 'DC-5 (Small) Specifications',
        icon: 'list-alt',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10014/Amplify_HVLS_DC5_%20Small.pdf',
            LaunchTypes.newWindow
          );
        },
      },
      {
        name: 'hvlsControls',
        text: 'HVLS Controls',
        icon: 'book',
        visible: true,
        disabled: false,
        improved: false,
        click: function () {
          self.externalComm.launchExternal(
            'https://content.greenheck.com/public/DAMProd/Original/10014/Amplify_HVLS_Controls_Sales.pdf',
            LaunchTypes.newWindow
          );
        },
      },
    ];

    const itemMap: IToolboxItemMap[] = [
      {
        name: 'Fans',
        items: [
          'systemEffectSimulator',
          'onlineFanCourses',
          'variGreenMotorAndControls',
          'airflowMonitoring',
        ],
        children: [
          {
            name: 'Circulator',
            items: [
              'onlineFanCourses',
              'ds3SpecSheet',
              'ds6SpecSheet',
              'dc5LargeSpecSheet',
              'dc5SmallSpecSheet',
              'hvlsControls',
            ],
          },
          {
            name: 'DestratFans',
            items: ['onlineFanCourses', 'ddfSpecSheet'],
          },
        ],
      },
      {
        name: 'Louvers',
        items: [
          'louverComparisonGuide',
          'hurricaneTesting',
          'waterPenetration',
          'certificationDescriptions',
        ],
      },
      {
        name: 'Outdoor-Air',
        items: [
          'energyRecoveryTechnologies',
          'energyRecoveryRequirementsForIECC',
          'dxCoolingSystem',
          'outdoorAirAccessories',
        ],
      },
      {
        name: 'MakeUpAir',
        items: [
          'supplyFanGuide',
          'muaProductCatalogs',
          'muaControls',
          'selectionGuide',
        ],
      },
      {
        name: 'Preconditioners',
        items: [
          'energyRecoveryTechnologies',
          'energyRecoveryRequirementsForIECC',
          'selectionGuide',
          'energyRecoveryProductCatalog',
        ],
      },
    ];

    this.toolboxItems = itemList.map((item) => new ToolboxItem(item));

    this.productGroups.forEach((group) => {
      const itemMapGroup = itemMap.find((item) => item.name === group.name);

      if (!!itemMapGroup) {
        const assignItems = (
          group: ProductGroup,
          mapGroup: IToolboxItemMap
        ) => {
          group.toolboxItems = this.getToolboxItems(mapGroup.items);

          if (mapGroup.children) {
            mapGroup.children.forEach((child) => {
              const childGroup = group.children.find(
                (g) => g.name === child.name
              );

              if (!!childGroup) {
                assignItems(childGroup, child);
              }
            });
          }
        };

        assignItems(group, itemMapGroup);
      }
    });
  }
}
