import { Component, OnInit, Input } from '@angular/core';
import { LayoutConfiguration } from '../../models/layout-config/layout-configuration.model';

@Component({
  selector: 'doas-svg',
  templateUrl: './doas-svg.component.html',
  styleUrls: ['./doas-svg.component.scss'],
})
export class DoasSvgComponent implements OnInit {
  @Input('layout-config') layoutConfig: LayoutConfiguration;

  constructor() {}

  ngOnInit() {}

  coolingCoil(): boolean {
    const value = this.getQuestionValue('CoolingType');

    return value !== null && value !== 'None';
  }

  reheat(): boolean {
    if (!!this.layoutConfig) {
      const reheatQuestion = this.layoutConfig.getQuestion('HasReheat');

      return (
        !!reheatQuestion &&
        reheatQuestion.visible === true &&
        reheatQuestion.value === 'Yes'
      );
    } else {
      return false;
    }
  }

  heatingType(): string {
    return this.getQuestionValue('HeatingType');
  }

  exhaustFan(): boolean {
    return this.getQuestionValue('PoweredReliefFan') === 'Yes';
  }

  outdoorHood(): boolean {
    return this.getQuestionValue('UnitMounting') === 'Outdoor';
  }

  recircDamper(): boolean {
    return this.getQuestionValue('RecirculatedAirVolume') !== '0';
  }

  supplyAirPosition(): string {
    return this.getQuestionValue('SupplyAirDischargePosition');
  }

  returnAirPosition(): string {
    return this.getQuestionValue('ReturnAirIntakePosition');
  }

  returnAirDamper(): boolean {
    const value = this.getQuestionValue('ReturnAirDamper');

    return value !== null && value !== 'None';
  }

  returnERVType(): 'RVE' | 'RVC' | 'RV' {
    const value = this.getQuestionValue('EnergyRecoveryType');

    if (['Wheel', 'AluminumWheel'].indexOf(value) > -1) {
      return 'RVE';
    } else if (value === 'Core' || value === 'FiberCore') {
      return 'RVC';
    } else {
      return 'RV';
    }
  }

  private getQuestionValue(name: string): any {
    if (!!this.layoutConfig) {
      const question = this.layoutConfig.getQuestion(name);

      return !!question ? question.value : null;
    } else {
      return null;
    }
  }
}
