<div class="revit-jobs-page">
  <mat-toolbar color="primary">My Jobs</mat-toolbar>

  <div class="jobs-table-parent">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="lastSavedDate"
      matSortDirection="desc"
    >
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Name</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-button
            color="primary"
            [routerLink]="[
              '/revit/jobs',
              element.id,
              revitVersion,
              pluginVersion
            ]"
          >
            {{ element.name }}
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastSavedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Saved On</th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastSavedDate | date : 'M/d/yyyy' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
        <td mat-cell *matCellDef="let element">{{ element.location }}</td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quantity</th>
        <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
        <td mat-cell *matCellDef="let element">{{ element.version }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
