import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { NavigationEnd, Router } from '@angular/router';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import { Selections } from '../../controllers/selections.controller';
import { ValidSize } from '../../models/selection-results/valid-size.model';
import { CompareConfig } from './model/compare-config.model';

@Component({
  selector: 'app-compare-snack-bar',
  templateUrl: './compare-snack-bar.component.html',
  styleUrls: ['./compare-snack-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompareSnackBarComponent implements OnInit {
  items: Array<ValidSize>;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: CompareConfig,
    private changeRef: ChangeDetectorRef,
    private selections: Selections,
    private core: EcapsCore,
    private snackBarRef: MatSnackBarRef<CompareSnackBarComponent>,
    private router: Router
  ) {
    this.items = data.items;

    this.router.events.subscribe((eventData) => {
      if (eventData instanceof NavigationEnd && eventData.id !== 1) {
        this.snackBarRef.dismiss();
      }
    });
  }

  ngOnInit() {}

  setItems(items: Array<ValidSize>) {
    this.items = items;

    this.changeRef.detectChanges();
  }

  compareClick() {
    if (this.items.length < 2) {
      this.core.showMessageBox(
        {
          title: 'Minimum Selection Required',
          message: 'You must select at least two items to compare.',
        },
        true
      );
    } else {
      this.selections.showCompare(this.items);
    }
  }

  clearClick() {
    this.items.splice(0, this.items.length);

    this.snackBarRef.dismiss();
  }

  sizeClick(size: ValidSize) {
    this.items.splice(
      this.items.findIndex((item) => item === size),
      1
    );

    if (this.items.length === 0) {
      this.snackBarRef.dismiss();
    }
  }
}
