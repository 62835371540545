import { HttpClient } from '@angular/common/http';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import {
  EcapsStateService,
  methods,
} from '../handlers/services/ecaps-state.service';

@Directive({
  selector: '[loadChild]',
})
export class LoadChildDirective implements OnInit {
  @Input('loadChild') url: string;

  constructor(
    private element: ElementRef,
    private http: HttpClient,
    private ecapsState: EcapsStateService
  ) {}

  ngOnInit() {
    if (!this.url) {
      return;
    }

    const stateKey = this.ecapsState.addPendingAsyncCall(this.url, methods.get);

    const subscription = this.http
      .get(this.url, { responseType: 'text' })
      .subscribe({
        next: (data) => {
          this.element.nativeElement.innerHTML = data;
        },
        complete: () => {
          this.ecapsState.removePendingAsyncCall(stateKey);

          subscription.unsubscribe();
        },
      });
  }
}
