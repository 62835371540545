<side-dialog-title>Static Pressure Calculator</side-dialog-title>

<side-dialog-content>
  <mat-toolbar class="warning">
    <mat-icon color="warn">warning</mat-icon>
    <span
      >The values generated by this tool are meant for approximation purposes
      only.</span
    >
  </mat-toolbar>

  <div class="step-container">
    <mat-horizontal-stepper linear="true" #stepper>
      <mat-step [stepControl]="roomSize">
        <form [formGroup]="roomSize">
          <ng-template matStepLabel>Room Information</ng-template>

          <mat-form-field>
            <mat-label>Length (ft)</mat-label>

            <mat-select
              formControlName="length"
              (selectionChange)="sqftTotal()"
            >
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
              <mat-option [value]="8">8</mat-option>
              <mat-option [value]="9">9</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="11">11</mat-option>
              <mat-option [value]="12">12</mat-option>
            </mat-select>

            <mat-error *ngIf="roomSize.controls['length'].invalid">{{
              roomSize.controls['length'] | fieldErrorToText
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Width (ft)</mat-label>

            <mat-select formControlName="width" (selectionChange)="sqftTotal()">
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
              <mat-option [value]="8">8</mat-option>
              <mat-option [value]="9">9</mat-option>
              <mat-option [value]="10">10</mat-option>
              <mat-option [value]="11">11</mat-option>
              <mat-option [value]="12">12</mat-option>
            </mat-select>

            <mat-error *ngIf="roomSize.controls['width'].invalid">{{
              roomSize.controls['width'] | fieldErrorToText
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Total Square Feet</mat-label>

            <input #sqft matInput formControlName="sqft" readonly="true" />
            <mat-error *ngIf="roomSize.controls['sqft'].invalid">{{
              roomSize.controls['sqft'] | fieldErrorToText
            }}</mat-error>
          </mat-form-field>

          <div class="fixtures" *ngIf="sqft.value > 100">
            <mat-form-field>
              <mat-label>Toilet Qty</mat-label>

              <mat-select
                formControlName="toiletQty"
                (selectionChange)="fixtureTotal()"
              >
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
              </mat-select>

              <mat-error *ngIf="roomSize.controls['toiletQty'].invalid">{{
                roomSize.controls['toiletQty'] | fieldErrorToText
              }}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Shower Qty</mat-label>

              <mat-select
                formControlName="showerQty"
                (selectionChange)="fixtureTotal()"
              >
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
              </mat-select>

              <mat-error *ngIf="roomSize.controls['showerQty'].invalid">{{
                roomSize.controls['showerQty'] | fieldErrorToText
              }}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Bathtub Qty</mat-label>

              <mat-select
                formControlName="bathtubQty"
                (selectionChange)="fixtureTotal()"
              >
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
              </mat-select>
              <mat-error *ngIf="roomSize.controls['bathtubQty'].invalid">{{
                roomSize.controls['bathtubQty'] | fieldErrorToText
              }}</mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Jetted Tub Qty</mat-label>

              <mat-select
                formControlName="jettedTubQty"
                (selectionChange)="fixtureTotal()"
              >
                <mat-option [value]="0">0</mat-option>
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
              </mat-select>

              <mat-error *ngIf="roomSize.controls['jettedTubQty'].invalid">{{
                roomSize.controls['jettedTubQty'] | fieldErrorToText
              }}</mat-error>
            </mat-form-field>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="ductInformation">
        <form [formGroup]="ductInformation">
          <ng-template matStepLabel>Duct Information</ng-template>

          <mat-form-field>
            <mat-label>Round Duct Diameter (in)</mat-label>

            <mat-select
              formControlName="roundDuctDiameter"
              (selectionChange)="totalEDL()"
            >
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
              <mat-option [value]="8">8</mat-option>
            </mat-select>
            <mat-error
              *ngIf="ductInformation.controls['roundDuctDiameter'].invalid"
              >{{
                ductInformation.controls['roundDuctDiameter'] | fieldErrorToText
              }}</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label>Length of Smooth Duct (PVC or Metal) (ft)</mat-label>

            <input
              type="number"
              matInput
              formControlName="smoothMetal"
              (blur)="totalEDL()"
            />

            <button
              mat-icon-button
              matSuffix
              (stopPropClick)="smoothMetalHelpClick()"
              tabindex="-1"
            >
              <mat-icon>help_outline</mat-icon>
            </button>

            <mat-error
              *ngIf="ductInformation.controls['smoothMetal'].invalid"
              >{{
                ductInformation.controls['smoothMetal'] | fieldErrorToText
              }}</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label
              >Length of Flexible Aluminum Duct (Strung Tight) (ft)</mat-label
            >

            <input
              type="number"
              matInput
              formControlName="flexAluminum"
              (blur)="totalEDL()"
            />

            <button
              mat-icon-button
              matSuffix
              (stopPropClick)="aluminumHelpClick()"
              tabindex="-1"
            >
              <mat-icon>help_outline</mat-icon>
            </button>

            <mat-error
              *ngIf="ductInformation.controls['flexAluminum'].invalid"
              >{{
                ductInformation.controls['flexAluminum'] | fieldErrorToText
              }}</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label
              >Length of Insulated Flexible Duct (Loosely Laid) (ft)</mat-label
            >

            <input
              type="number"
              matInput
              formControlName="flexInsulated"
              (blur)="totalEDL()"
            />

            <button
              mat-icon-button
              matSuffix
              (stopPropClick)="flexInsulatedHelpClick()"
              tabindex="-1"
            >
              <mat-icon>help_outline</mat-icon>
            </button>

            <mat-error
              *ngIf="ductInformation.controls['flexInsulated'].invalid"
              >{{
                ductInformation.controls['flexInsulated'] | fieldErrorToText
              }}</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label>Number of Terminations</mat-label>

            <mat-select
              formControlName="terminationQty"
              (selectionChange)="totalEDL()"
            >
              <mat-option [value]="0">0</mat-option>
              <mat-option [value]="1">1</mat-option>
            </mat-select>
            <mat-error
              *ngIf="ductInformation.controls['terminationQty'].invalid"
              >{{
                ductInformation.controls['terminationQty'] | fieldErrorToText
              }}</mat-error
            >
          </mat-form-field>

          <mat-form-field>
            <mat-label>Number of Elbows</mat-label>

            <mat-select
              formControlName="elbowQty"
              (selectionChange)="totalEDL()"
            >
              <mat-option [value]="0">0</mat-option>
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
              <mat-option [value]="6">6</mat-option>
              <mat-option [value]="7">7</mat-option>
              <mat-option [value]="8">8</mat-option>
              <mat-option [value]="9">9</mat-option>
              <mat-option [value]="10">10</mat-option>
            </mat-select>
            <mat-error *ngIf="ductInformation.controls['elbowQty'].invalid">{{
              ductInformation.controls['elbowQty'] | fieldErrorToText
            }}</mat-error>
          </mat-form-field>
        </form>
      </mat-step>

      <mat-step [stepControl]="calcData">
        <form [formGroup]="calcData">
          <ng-template matStepLabel>Calculation Data</ng-template>

          <mat-form-field>
            <mat-label>CFM Requirement</mat-label>

            <input
              type="number"
              matInput
              formControlName="cfmRequirement"
              readonly="true"
            />
            <mat-error *ngIf="calcData.controls['cfmRequirement'].invalid">{{
              calcData.controls['cfmRequirement'] | fieldErrorToText
            }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Estimated External Static Pressure (in. wg.)</mat-label>

            <input
              type="number"
              matInput
              formControlName="totalSP"
              readonly="true"
            />
            <mat-error *ngIf="calcData.controls['totalSP'].invalid">{{
              calcData.controls['totalSP'] | fieldErrorToText
            }}</mat-error>
          </mat-form-field>
        </form>
      </mat-step>
    </mat-horizontal-stepper>

    <mat-divider></mat-divider>

    <div class="navigation">
      <button
        mat-raised-button
        color="primary"
        [style.display]="stepper.selectedIndex === 0 ? 'none' : 'inline'"
        (click)="stepper.previous()"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
        <span>Back</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        [style.display]="stepper.selectedIndex === 2 ? 'none' : 'inline'"
        (click)="stepper.next()"
      >
        <span>Next</span>
        <mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</side-dialog-content>

<side-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    (click)="okClick()"
    [disabled]="roomSize.invalid || ductInformation.invalid || calcData.invalid"
  >
    Use Calculated Values
  </button>
  <button mat-raised-button color="primary" (click)="cancelClick()">
    Cancel
  </button>
</side-dialog-actions>
