<h2 mat-dialog-title>{{ dialogData.title }}</h2>

<mat-dialog-content>
  <mat-icon *ngIf="!!dialogData.icon" [class]="dialogData.iconClasses + ' mat-icon material-icons dp32'">{{ dialogData.icon }}</mat-icon>
  <div class="message" [innerHTML]="message"></div>
</mat-dialog-content>

<mat-dialog-actions>
  <ng-container [ngSwitch]="!!dialogData.buttons">
    <button *ngSwitchCase="false" mat-raised-button color="primary" mat-dialog-close>OK</button>
    <ng-container *ngSwitchCase="true">
      <ng-container *ngFor="let button of dialogData.buttons">
        <button *ngIf="!!button.clickEvent" mat-raised-button color="primary" (click)="buttonClick(button)">{{ button.title }}</button>
        <button *ngIf="button.value != undefined" mat-raised-button color="primary" [mat-dialog-close]="button.value">
          {{ button.title }}
        </button>
        <button *ngIf="!button.clickEvent && button.value === undefined" mat-raised-button color="primary" mat-dialog-close>
          {{ button.title }}
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-dialog-actions>
