import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorMessageDialogComponent } from '../dialogs/error-message-dialog/error-message-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageDialog {
  lastDialogRef: MatDialogRef<ErrorMessageDialogComponent>;

  constructor(private dialog: MatDialog) {}

  show(
    errorID: string,
    errorData: any,
    title?: string,
    message?: string
  ): Promise<boolean> {
    return new Promise<boolean>(result => {
      if (!this.lastDialogRef) {
        this.lastDialogRef = this.dialog.open(ErrorMessageDialogComponent, {
          disableClose: false,
          autoFocus: true,
          data: {
            title: title,
            message: message,
            errorID: errorID,
            errorData: errorData
          }
        });

        const subscription = this.lastDialogRef.beforeClosed().subscribe({
          complete: () => {
            subscription.unsubscribe();

            this.lastDialogRef = null;

            result(true);
          }
        });
      } else {
        console.error(errorData);

        result(true);
      }
    });
  }
}
