import {
  AfterContentChecked,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface ISvgDefinition {
  text?: string;
  visible?: boolean;
  center?: boolean;
}

@Component({
  selector: 'svg-option',
  template: ``,
  styles: [],
  encapsulation: ViewEncapsulation.None,
})
export class SvgOptionComponent implements OnInit {
  @Input()
  name: string;

  private _text?: string;
  public get text(): string | undefined {
    return this._text;
  }
  @Input()
  public set text(value: string | undefined) {
    this._text = value;
  }

  private _visible?: boolean;
  public get visible(): boolean | undefined {
    return this._visible;
  }
  @Input()
  public set visible(value: boolean | undefined) {
    this._visible = value;
  }

  private _center?: boolean;
  public get center(): boolean | undefined {
    return this._center;
  }
  @Input()
  public set center(value: boolean | undefined) {
    this._center = value;
  }

  public get svgDef(): ISvgDefinition {
    return {
      text: this._text,
      visible: this._visible,
      center: this._center,
    };
  }

  constructor() {}

  ngOnInit(): void {}
}

@Component({
  selector: 'svg-viewer',
  template: `<object
      *ngIf="!!src"
      class="svg-viewer__container"
      type="image/svg+xml"
      [data]="src"
      (load)="processSvg()"
    ></object>
    <ng-content></ng-content>`,
  styles: [
    `
      svg-viewer,
      svg-viewer .svg-viewer__container {
        display: block;
        width: 100%;
        height: 100%;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SvgViewerComponent implements OnInit, AfterContentChecked {
  @ContentChildren(SvgOptionComponent) options?: QueryList<SvgOptionComponent>;

  private _src?: SafeResourceUrl;
  public get src(): SafeResourceUrl | string | undefined {
    return !!this._src ? this._src : undefined;
  }
  @Input()
  public set src(value: SafeResourceUrl | string | undefined) {
    this._src =
      !!value && typeof value === 'string'
        ? this.sanitizer.bypassSecurityTrustResourceUrl(value)
        : value;
  }

  private _defs?: Record<string, ISvgDefinition>;
  public get defs(): Record<string, ISvgDefinition> | undefined {
    return this._defs;
  }
  @Input()
  public set defs(value: Record<string, ISvgDefinition> | undefined) {
    this._defs = value;

    this.setDefs();
  }

  private _svg?: SVGElement;

  constructor(private element: ElementRef, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    let newDefs: Record<string, ISvgDefinition> = {};

    this.options?.forEach((option) => {
      newDefs[option.name] = option.svgDef;
    });

    this._defs = { ...this._defs, ...newDefs };

    this.setDefs();
  }

  processSvg(): void {
    const el = this.element.nativeElement as HTMLElement;

    this._svg = (
      el.firstChild as HTMLObjectElement
    )?.contentDocument.querySelector('svg') as SVGElement;

    this.setDefs();
  }

  private setDefs(): void {
    if (!this._svg || !this._defs) {
      return;
    }

    const setStyle = (node: Element, name: string, value: string) => {
      let styles: Record<string, string> = {};

      if (node.hasAttribute('style')) {
        node
          .getAttribute('style')
          .split(/;/g)
          .forEach((style) => {
            const parts = style.split(/:/g);

            if (parts.length === 2) {
              styles[parts[0].trim()] = parts[1].trim();
            }
          });
      }

      const newStyle: Record<string, string> = {};

      newStyle[name] = value;

      styles = { ...styles, ...newStyle };

      node.setAttribute(
        'style',
        Object.keys(styles)
          .map((key) => `${key}: ${styles[key]}`)
          .join(';')
      );
    };

    Object.keys(this._defs).forEach((nodeId) => {
      const def = this._defs[nodeId];
      const node = this._svg.querySelector(`#${nodeId}`);

      if (!!node) {
        if (def.center === true) {
          node.setAttribute('x', (node.clientWidth / 2).toString());

          setStyle(node, 'text-anchor', 'middle');
        }

        if (def.visible !== undefined) {
          setStyle(node, 'display', def.visible ? 'inline' : 'none');
        }

        if (!!def.text) {
          node.textContent = def.text;
        }
      }
    });
  }
}
