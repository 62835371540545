import { Project } from './project.model';

export class LocalStorageProject {
  key: string;
  id: string;
  lastSavedDate: Date;
  isDirty: boolean;
  itemCount: number;

  constructor(project: Project) {
    this.key = project.key;
    this.id = project.id;
    this.lastSavedDate = project.lastSavedDate;
    this.isDirty = project.isDirty;
    this.itemCount = project.getItemCount();
  }
}
