export enum DrawingTypes {

    image = 'Image',
    svg = 'SVG',
    dwg2D = 'Dwg2D',
    dwg3D = 'Dwg3D',
    revitLOD200 = 'RevitLOD200',
    revitLOD300 = 'RevitLOD300',
    revitReadMe = 'RevitReadMe'

}
