import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Injector,
} from '@angular/core';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { Subscription } from 'rxjs';
import { ColumnList } from '../../constants/column-list.const';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';
@Component({
  selector: 'circulator-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CirculatorGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  constructor(protected injector: Injector) {
    super(injector);

    this.gridSaveKey = '';
  }

  ngOnInit() {
    const selectionMethodQuestion =
      this.layoutConfig.getQuestion('selectionMethod');

    const selectionMethod = !!selectionMethodQuestion
      ? selectionMethodQuestion.value
      : 'ByFanSize';

    this.gridSaveKey =
      selectionMethod === 'ByFanSize'
        ? 'circulatorSizeBased'
        : 'circulatorPerformanceBased';

    this.showRank = selectionMethod === 'ByPerformance';

    const colList =
      selectionMethod === 'ByFanSize'
        ? [
            'budgetPrice',
            'maxCFM',
            'avgAirSpeedMax',
            'coolingEffect',
            'operatingCost',
            'speedMax',
            'motorHP',
            'outletDba',
            'weight',
            'cfei40Speed',
            'cfei100Speed',
            'amca',
            'wheelDiameter',
            'directionOfOperation',
            'bladeCount',
            'affectedArea',
            'fanEfficiency',
            'motorHPWatts',
            'watts',
            'minFanSpacing',
          ]
        : [
            'fanQuantity',
            'budgetPrice',
            'actualCFM',
            'avgAirSpeed',
            'avgAirSpeedMax',
            'coolingEffect',
            'operatingCost',
            'fanRPM',
            'motorHP',
            'outletDba',
            'weight',
            'cfei40Speed',
            'cfei100Speed',
            'amca',
            'wheelDiameter',
            'directionOfOperation',
            'bladeCount',
            'affectedArea',
            'minFanSpacing',
            'speedMax',
            'fanEfficiency',
            'motorHPWatts',
            'watts',
          ];

    this.columnList = ColumnList.getColumns(...colList);

    this.columnList.find((item) => item.key === 'speedMax').text =
      'Max. Fan RPM';

    this.columnList.find((item) => item.key === 'watts').text =
      'Input Watts Per Fan';

    this.columnList.find((item) => item.key === 'wheelDiameter').text =
      'Fan Diameter (ft.)';

    this.columnList.find((item) => item.key === 'operatingCost').text =
      'Total Operating Cost/yr (USD)';

    this.columnList.find((item) => item.key === 'outletDba').text =
      'Total dBA Per Fan';

    this.columnList.find((item) => item.key === 'weight').text =
      'Wt. (lbs) Per Fan';

    this.columnList.find((item) => item.key === 'fanEfficiency').text =
      'Integrated Efficiency (CFM/Watt) Per Fan';

    if (selectionMethod === 'ByPerformance') {
      this.columnList.find((item) => item.key === 'actualCFM').text =
        'Actual CFM per Fan';

      this.columnList.find((item) => item.key === 'watts').text =
        'Input Watts Per Fan';

      colList.splice(colList.length - 10, 10);
    } else {
      this.columnList.find((item) => item.key === 'affectedArea').text =
        'Max. Coverage Area per Fan (sq ft)';

      colList.splice(colList.length - 8, 8);
    }

    this.setVisibleColumns(colList);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.loadVisibleColumns();

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();
  }

  private validSizeToDSRow(size: ValidSize) {
    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.name,
      budgetPrice: size.pricing.price,
      affectedArea: size.outputs.affectedArea,
      minFanSpacing: size.outputs.minimumFanSpacing,
      actualFanSpacing: size.outputs.actualFanSpacing,
      operatingCost: size.outputs.operatingCostPerYear,
      fanRPM: size.outputs.speed,
      speedMax: size.outputs.speedMax,
      motorHP: size.outputs.motorSize,
      motorHPWatts: size.outputs.minimumMotorSizeNEMA,
      fanEfficiency: size.outputs.fanEfficiency,
      outletDba: size.outputs.outletdBA,
      weight: size.pricing.weight,
      amca: size.amcaRatings,
      wheelDiameter: size.outputs.impellerDiameter,
      directionOfOperation: size.outputs.directionOfOperation,
      bladeCount: size.outputs.numberOfBlades,
      cfei40Speed: size.outputs.cfei40Speed,
      cfei100Speed: size.outputs.cfei100Speed,
      maxCFM: size.outputs.maximumVolume,
      fanQuantity: size.outputs.fanQuantity,
      actualCFM: size.outputs.operatingVolume,
      watts: size.outputs.watts,
      avgAirSpeed: size.outputs.averageAirSpeed,
      avgAirSpeedMax: size.outputs.maximumAverageAirSpeed,
      coolingEffect: size.outputs.coolingEffect,
      stable: size.stability.stable,
      sizeData: size,
    };

    return rowData;
  }
}
