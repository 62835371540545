import { Injectable } from '@angular/core';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';
import { ProjectInfoDialogComponent } from '../dialogs/project-info-dialog/project-info-dialog.component';
import { ProjectInfoDialogData } from '../dialogs/project-info-dialog/models/project-info-dialog-data.model';
import { ProjectSummary } from '../models/project-summary.model';
import {
  GoogleAnalyticsService,
  EventCategories,
} from '../../google/services/google-analytics.service';
import { Project } from '../models/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectInfoDialog {
  constructor(
    private sideDialogService: SideDialogService,
    private analytics: GoogleAnalyticsService
  ) {}

  show(
    summary: ProjectSummary,
    publicToken?: string,
    project?: Project
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.sideDialogService.open(
        ProjectInfoDialogComponent,
        {
          disableClose: false,
          autoFocus: true,
          data: new ProjectInfoDialogData(summary, publicToken, project),
        }
      );

      const showSubscription = dialogRef.afterOpen.subscribe(() => {
        showSubscription.unsubscribe();

        if (!!publicToken) {
          this.analytics.trackEvent_Old(
            EventCategories.sharing,
            'Shared Project Viewed',
            publicToken
          );
        }
      });

      const subscription = dialogRef.beforeClose.subscribe((results) => {
        subscription.unsubscribe();

        resolve(!!results);
      });
    });
  }
}
