import { Injectable } from '@angular/core';
import { UsersService } from '../../users/services/users.service';
import { Project } from '../models/project.model';
import { ProjectsService } from '../services/projects.service';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { Users } from '../../users/controllers/users.controller';
import { ProjectPropertiesDialog } from './project-properties-dialog.controller';
import {
  ActionTypes,
  SourceTypes,
} from '../../google/services/google-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class Projects {
  constructor(
    private projectPropertiesDialog: ProjectPropertiesDialog,
    private usersService: UsersService,
    private users: Users,
    private projectsService: ProjectsService,
    private core: EcapsCore
  ) {}

  showProjectProperties(
    project: Project,
    copyProject: boolean = false,
    name?: string,
    location?: string
  ): Promise<boolean> {
    return this.projectPropertiesDialog.show(
      project,
      copyProject,
      name,
      location
    );
  }

  saveProject(
    showNotRequired = false,
    source?: SourceTypes,
    action?: ActionTypes
  ): Promise<boolean> {
    return new Promise<boolean>((result, reject) => {
      this.usersService.getCurrentUser().then((userData) => {
        this.projectsService.getLocalProject().then((project) => {
          if (!project.getActiveRevision().isValid) {
            this.core
              .showMessageBox({
                title: 'Unable To Save',
                message:
                  'Your job currently contains invalid marks. Please resolve these issues in the equipment schedule.',
              })
              .then(() => {
                result(true);
              });
          } else {
            if (!project.isDirty && showNotRequired) {
              this.core
                .showMessageBox({
                  title: 'Save Not Required',
                  message: 'No changes have been made.',
                })
                .then(() => {
                  result(true);
                });
            } else if (!project.isDirty && !showNotRequired) {
              result(true);
            } else {
              if (
                !userData ||
                !userData.authenticated ||
                !project ||
                !project.name
              ) {
                this.saveProjectAs().then(
                  (completed) => {
                    result(completed);
                  },
                  (errorData) => {
                    reject(errorData);
                  }
                );
              } else {
                let saving = true;

                this.core.showLoadingGraphic('Saving...', function () {
                  return saving;
                });

                this.projectsService.saveProject(project, source, action).then(
                  () => {
                    result(true);

                    saving = false;

                    this.core.hideLoadingGraphic();
                  },
                  (errorData) => {
                    reject(errorData);

                    saving = false;

                    this.core.hideLoadingGraphic();
                  }
                );
              }
            }
          }
        });
      });
    });
  }

  saveProjectAs(): Promise<boolean> {
    return new Promise<boolean>((result, reject) => {
      this.usersService.getCurrentUser().then((userData) => {
        this.projectsService.getLocalProject().then((project) => {
          if (!project.getActiveRevision().isValid) {
            this.core
              .showMessageBox({
                title: 'Unable To Save',
                message:
                  'Your job currently contains invalid marks. Please resolve these issues in the equipment schedule.',
              })
              .then(() => {
                result(true);
              });
          } else {
            if (!!userData && userData.authenticated) {
              this.showProjectProperties(project, true).then((results) => {
                result(results);
              });
            } else {
              this.users.showLoginRequiredDialog(false).then((success) => {
                if (success) {
                  this.showProjectProperties(project, true).then((results) => {
                    result(results);
                  });
                } else {
                  result(false);
                }
              });
            }
          }
        });
      });
    });
  }
}
