import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewEncapsulation,
  ElementRef,
} from '@angular/core';
import { LayoutConfiguration } from '../../models/layout-config/layout-configuration.model';
import { ValidSize } from '../../models/selection-results/valid-size.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fume-exhaust-svg',
  templateUrl: './fume-exhaust-svg.component.html',
  styleUrls: ['./fume-exhaust-svg.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FumeExhaustSvgComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription[] = [];

  columns: string[] = [];
  fanCount: number = 1;
  fanWidth: number = 168;
  nozzleType: string = 'Any';
  fanType: string = 'Any';
  plenumType: string = 'None';
  hasFanOnStandby?: boolean;

  private _layoutConfig: LayoutConfiguration;
  public get layoutConfig(): LayoutConfiguration {
    return this._layoutConfig;
  }
  @Input('layout-config') public set layoutConfig(value: LayoutConfiguration) {
    this._layoutConfig = value;

    this._subscriptions.push(
      this._layoutConfig.updated.subscribe(() => {
        this.updateValues();
      })
    );

    this.updateValues();
  }

  private _size: ValidSize;
  public get size(): ValidSize {
    return this._size;
  }
  @Input('size') public set size(value: ValidSize) {
    this._size = value;

    this._subscriptions.push(
      this._size.updated.subscribe(() => {
        this.updateValues();
      })
    );

    this.updateValues();
  }

  entrainedVolume = 0;
  totalSingleFanVolume = 0;

  constructor(private element: ElementRef) {}

  ngOnInit() {}

  ngOnDestroy() {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private getQuestionValue(name: string): any {
    if (!!this._layoutConfig) {
      const question = this._layoutConfig.getQuestion(name);

      return !!question ? question.value : null;
    } else {
      return null;
    }
  }

  private updateValues() {
    if (!this._layoutConfig) {
      this.columns = [];
      this.fanCount = 1;
      this.fanWidth = 168;
      this.nozzleType = 'Any';
      this.fanType = 'Any';
      this.plenumType = 'None';
      this.hasFanOnStandby = false;

      this.updateClasses();
      this.scaleViewbox();

      return;
    }

    this.nozzleType = this.getQuestionValue('FumeExhaustNozzleType') ?? 'Any';
    this.fanType = this.getQuestionValue('FumeExhaustFanType') ?? 'Any';
    this.plenumType =
      this.getQuestionValue('FumeExhaustInletBoxOptions') ?? 'None';

    let quantityName: string = '';

    if (!this._size) {
      quantityName = (
        this._layoutConfig.getQuestion('ExhaustSystemFanQuantity').value ?? ''
      )
        .toLowerCase()
        .trim();

      this.hasFanOnStandby =
        (this.getQuestionValue('HasFanOnStandby') ?? 'No')
          .toLowerCase()
          .trim() === 'yes';
    } else if (!!this._size) {
      quantityName = (
        this._size.selectionLayoutConfig.getQuestion('ExhaustSystemFanQuantity')
          .value ?? ''
      )
        .toLowerCase()
        .trim();

      this.hasFanOnStandby =
        !!this._size.selectionLayoutConfig.getQuestion('HasFanOnStandby') &&
        (
          this._size.selectionLayoutConfig.getQuestion('HasFanOnStandby')
            .value ?? 'No'
        )
          .toLowerCase()
          .trim() === 'yes';
    }

    const quantityIndex = [
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'all',
    ].indexOf(quantityName);

    this.fanCount = quantityIndex > -1 ? quantityIndex + 1 : 0;

    if (this.fanCount === 0) {
      this.columns = [];
    } else {
      this.columns = Array(this.fanCount).fill('');
    }

    if (!!this._size) {
      let systemInletVolume = this._size.outputs.operatingVolume;

      const activeFanCount = this.fanCount - (this.hasFanOnStandby ? 1 : 0);

      if (this.fanCount > 1) {
        systemInletVolume = this._size.outputs.operatingVolume * activeFanCount;
      }

      this.entrainedVolume =
        this._size.outputs.entrainedVolume * activeFanCount - systemInletVolume;

      if (this.entrainedVolume > 0) {
        this.totalSingleFanVolume =
          (systemInletVolume + this.entrainedVolume) / activeFanCount;
      } else {
        this.totalSingleFanVolume = systemInletVolume / activeFanCount;
      }

      this.entrainedVolume /= activeFanCount;

      if (this.entrainedVolume < 0) {
        this.entrainedVolume = 0;
      }
    }

    this.updateClasses();
    this.scaleViewbox();
  }

  private updateClasses() {
    const svgElement = this.element.nativeElement.querySelector(
      'svg'
    ) as SVGElement;

    if (!svgElement) {
      return;
    }

    const removeList: string[] = [];
    svgElement.classList.forEach((className) => {
      if (className.startsWith('fan-count__') || className === 'standby') {
        removeList.push(className);
      }
    });
    removeList.forEach((className) => {
      svgElement.classList.remove(className);
    });

    svgElement.classList.add(`fan-count__${this.fanCount}`);

    if (this.hasFanOnStandby) {
      svgElement.classList.add('standby');
    }
  }

  private scaleViewbox() {
    const svgElement = this.element.nativeElement.querySelector(
      'svg'
    ) as SVGElement;

    if (!svgElement) {
      return;
    }

    const viewBox =
      svgElement.dataset.originalviewbox ??
      svgElement.getAttribute('viewBox') ??
      '';

    svgElement.dataset.originalviewbox = viewBox;

    const viewBoxValues = viewBox.split(' ').map((value) => parseFloat(value));

    if (viewBoxValues.length !== 4) {
      return;
    }

    const width = viewBoxValues[2];

    const newWidth = width + (this.fanCount - 1) * this.fanWidth;

    svgElement.setAttribute(
      'viewBox',
      `${viewBoxValues[0]} ${viewBoxValues[1]} ${newWidth} ${viewBoxValues[3]}`
    );
  }
}
