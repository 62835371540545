import { Project } from '../../../models/project.model';
import { ProjectSummary } from '../../../models/project-summary.model';

export class ProjectPropertiesDialogData {

    project: Project | ProjectSummary;

    copyProject: boolean;

    name?: string;

    location?: string;

}
