import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../environments/environment';
import { GraphicalSelectionPageComponent } from '../pages/graphical-selection-page/graphical-selection-page.component';
import { SelectionPageComponent } from '../pages/selection-page/selection-page.component';

@Injectable({
  providedIn: 'root',
})
export class SelectionPageGuard {
  firstNavigation = true;

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.firstNavigation) {
      this.firstNavigation = false;

      if (
        environment.production &&
        (route.component === SelectionPageComponent ||
          route.component === GraphicalSelectionPageComponent) &&
        this.router.url.indexOf('/group/') !== 0
      ) {
        this.router.navigateByUrl('/');

        return false;
      }
    }

    return true;
  }
}
