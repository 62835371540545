import { CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { LachesisService } from '../../../analytics/services/lachesis.service';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import {
  BoolToYesNoPipe,
  BreakHorsePowerPipe,
  CamelToDisplayPipe,
  HemisphericalSonesPipe,
  MaxThroatVelocityPipe,
  SphericalSonesPipe,
  ZeroToDashPipe,
} from '../../../ecaps-core/pipes/custom-pipes.pipe';
import { RevitCommsService } from '../../../external-communication/services/revit-comms.service';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../../google/services/google-analytics.service';
import { ProductInfoDialog } from '../../../products/controllers/product-info-dialog.controller';
import { Products } from '../../../products/controllers/products.controller';
import { ProductTypes } from '../../../products/enums/product-types.enum';
import { SideDialogComponent } from '../../../side-dialog/classes/side-dialog-component.interface';
import { SideDialogService } from '../../../side-dialog/services/side-dialog.service';
import { ValidSize } from '../../models/selection-results/valid-size.model';
import { LayoutService } from '../../services/layout.service';

interface ICompareDataRow {
  description?: string;
  value: string[];
}

interface ICompareRow {
  title: string;
  data: ICompareDataRow[];
}

@Component({
  selector: 'app-compare-dialog',
  templateUrl: './compare-dialog.component.html',
  styleUrls: ['./compare-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompareDialogComponent implements OnInit, SideDialogComponent {
  sizeList: ValidSize[] = [];

  public get dialogData(): ValidSize[] {
    return this.sizeList;
  }
  public set dialogData(value: ValidSize[]) {
    this.sizeList = value;
  }

  selectedTab = 'information';

  dimensionList: {
    name: string;
    description: string;
  }[] = [];

  productType: string;

  subGroup: string;

  dsTableDS: {
    model: string;
    area: number;
    radius: number;
    spacing: number;
  }[];

  isRevitPlugin = false;

  compareRows: ICompareRow[] = [];

  dsCoverageAreaTitle: string = '';
  dsCoverageRadTitle: string = '';

  constructor(
    private sideDialogService: SideDialogService,
    private core: EcapsCore,
    private layout: LayoutService,
    private products: Products,
    private productInfoDialog: ProductInfoDialog,
    private analytics: GoogleAnalyticsService,
    private revitComms: RevitCommsService,
    private lachesisService: LachesisService
  ) {
    this.isRevitPlugin = this.revitComms.isRevitApp;

    if (this.isRevitPlugin) {
      this.analytics.trackEvent_Old(
        EventCategories.revitAddin,
        'Performance',
        'Compare'
      );
    }
  }

  ngOnInit() {
    this.lachesisService.trackEvent({
      eventName: 'Comparison - Regular',
      slideout: 'Model Comparison',
      slideoutTab: 'Info',
      modelGroup: this.dialogData[0].selectionLayoutConfig.modelGroup,
      productType: this.dialogData[0].productType,
    });

    this.analytics.trackEvent_Old(
      EventCategories.comparison,
      'Pop-Up',
      'Product Comparison Pop-Up Activated',
      this.dialogData.length
    );

    this.analytics.viewItem('Comparison', {
      source: 'Model Comparison Slideout',
      action: 'Information Tab',
      items: this.dialogData.map((size) => size.getGAModel()),
    });

    this.productType = this.dialogData[0].productType.toLowerCase().trim();

    this.subGroup = this.dialogData[0].selectionLayoutConfig.modelGroup
      .toLowerCase()
      .trim();

    this.loadSizeDetails();
  }

  private loadSizeDetails() {
    let loading = true;

    this.core.showLoadingGraphic('Loading...', () => loading);

    Promise.all(
      this.dialogData.map(async (validSize) => {
        if (!validSize.description) {
          await this.layout.getValidSizeCatalogUpdate(validSize);
        }

        if (!validSize.dimensions || !validSize.files) {
          await this.layout.getValidSizeDimensions(validSize);
        }
      })
    ).then(() => {
      this.getDSTableData();

      this.updateDSCoverageAreaTitle();

      this.updateCompareRows();

      loading = false;

      this.core.hideLoadingGraphic();
    });
  }

  getDSTableData() {
    this.dsTableDS = [];

    this.dialogData.forEach((size) => {
      if (size.productType === ProductTypes.circulator) {
        if (
          size.selectionLayoutConfig.getQuestion('SelectionMethod').value ===
          'ByFanSize'
        ) {
          this.dsTableDS.push({
            model: size.name,
            area: parseFloat(
              size.layoutConfig.getQuestion('coverageArea').value
            ),
            radius: parseFloat(
              size.layoutConfig.getQuestion('coverageRadius').value
            ),
            spacing: parseFloat(
              size.layoutConfig.getQuestion('fanSpacing').value
            ),
          });
        } else {
          this.dsTableDS.push({
            model: size.name,
            area: parseFloat(
              size.layoutConfig.getQuestion('AffectedArea').value
            ),
            radius:
              parseFloat(
                size.layoutConfig.getQuestion('ActualFanSpacing').value
              ) / 2,
            spacing: parseFloat(
              size.layoutConfig.getQuestion('ActualFanSpacing').value
            ),
          });
        }
      }
    });
  }

  updateDSCoverageAreaTitle() {
    if (this.sizeList[0].productType === ProductTypes.circulator) {
      if (
        this.sizeList[0].selectionLayoutConfig.getQuestion('SelectionMethod')
          .value === 'ByFanSize'
      ) {
        this.dsCoverageAreaTitle = 'Max. Coverage Area (sq. ft.)';
        this.dsCoverageRadTitle = 'Max. Coverage Radius (ft.)';
      } else {
        this.dsCoverageAreaTitle = 'Required Coverage Area (sq. ft.)';
        this.dsCoverageRadTitle = 'Required Coverage Radius (ft.)';
      }
    }
  }

  getApplicationText(size: ValidSize): string {
    let applicationText;

    if (!!size.outputs.application) {
      applicationText = size.outputs.application;
    } else if (!!size.features.application) {
      applicationText = size.features.application;
    } else {
      applicationText = '-';
    }

    return applicationText;
  }

  cautionClick(size: ValidSize) {
    this.products.showValidSizeWarning(size);
  }

  addSize(size: ValidSize) {
    this.productInfoDialog.show(size, 'customize');
  }

  closeClick() {
    this.sideDialogService.close();
  }

  tabChanged(event: MatTabChangeEvent): void {
    this.selectedTab = event.tab.textLabel;

    this.analytics.viewItem('Comparison', {
      source: 'Model Comparison Slideout',
      action: `${this.selectedTab} Tab` as any,
      items: this.dialogData.map((size) => size.getGAModel()),
    });
  }

  private updateCompareRows() {
    const newRows: ICompareRow[] = [];

    /**
     * Generates a compare data row
     * @param {string} description Description text for the row
     * @param {function} getter Function to get the value for the row
     * @param {(function | string)} [formatter] Function used to format the value or a string specifying the format of a numeric value
     * @returns {ICompareDataRow}
     */
    const getCompDataRow = (
      description: string,
      getter: (value: ValidSize) => any,
      formatter?: ((value: any) => string) | string
    ): ICompareDataRow => ({
      description,
      value: this.sizeList.map((size) => {
        const value = getter(size);
        let newFormatter: (value: any) => string;

        if (typeof formatter === 'string') {
          newFormatter = (value) =>
            new ZeroToDashPipe().transform(value, formatter);
        } else if (typeof formatter === 'function') {
          newFormatter = formatter;
        }

        return newFormatter ? newFormatter(value) : value;
      }),
    });

    const getApplicationText = (): ICompareRow => ({
      title: 'Application',
      data: [
        {
          value: this.sizeList.map((size) => this.getApplicationText(size)),
        },
      ],
    });

    const getDimenstions = (
      format?: string,
      uom?: string
    ): ICompareDataRow[] => {
      const descriptionList = this.sizeList
        .map((size) =>
          size.dimensions
            ? size.dimensions.map((dimension) => ({
                name: dimension.name,
                description: (dimension.description || '').trim(),
              }))
            : []
        )
        .reduce((previousValue, currentValue) => {
          return [...previousValue, ...currentValue];
        })
        .filter((value, index, self) => {
          return self.findIndex((item) => item.name === value.name) === index;
        })
        .sort((a, b) => a.description.localeCompare(b.description));

      const getValue = (
        size: ValidSize,
        name: string,
        formatter?: (value: number) => string
      ): string => {
        if (size && size.dimensions) {
          const dim = size.dimensions.find((item) => item.name === name);

          if (!!dim) {
            return formatter
              ? formatter(dim.value)
              : new DecimalPipe('en-us').transform(dim.value, format);
          } else {
            return '-';
          }
        } else {
          return '-';
        }
      };

      return descriptionList.map((value) => {
        let description = value.description;
        let formatter: (number) => string;

        if (this.sizeList[0].productType === ProductTypes.circulator) {
          if (
            description.toLowerCase().indexOf('diameter') > -1 &&
            this.subGroup === 'circulator'
          ) {
            description = `${description} (ft.)`;

            formatter = (value: number): string =>
              new DecimalPipe('en-us').transform(value / 12, '1.0-3');
          } else {
            description = `${description} (in.)`;
          }
        } else if (!!uom) {
          description = `${description} (${uom})`;
        }

        return {
          description,
          value: this.sizeList.map((size) =>
            getValue(size, value.name, formatter)
          ),
        };
      });
    };

    switch (this.sizeList?.[0]?.productType) {
      case ProductTypes.circulator: {
        // Application
        newRows.push(getApplicationText());

        // Dimensions
        newRows.push({
          title: 'Dimensions & Weight',
          data: [
            ...getDimenstions('1.0-3'),
            {
              description: 'Weight (lbs.)',
              value: this.sizeList.map((size) =>
                new DecimalPipe('en-us').transform(size.pricing.weight, '1.0-0')
              ),
            },
            {
              description: 'Fan Qty',
              value: this.sizeList.map((size) =>
                new DecimalPipe('en-us').transform(
                  size.outputs.fanQuantity,
                  '1.0-0'
                )
              ),
            },
          ],
        });

        // Performance
        newRows.push({
          title: 'Performance',
          data: [
            getCompDataRow(
              'Direct of Operation',
              (size): string => size.outputs.directionOfOperation
            ),
            getCompDataRow(
              'Actual Volume (CFM) per fan',
              (size): string => size.outputs.operatingVolume,
              '1.0-0'
            ),
            getCompDataRow(
              'Coverage Area Per Fan (ft²)',
              (size): string => size.outputs.affectedArea,
              '1.0-0'
            ),
            getCompDataRow(
              this.sizeList[0].selectionLayoutConfig.getQuestion(
                'SelectionMethod'
              )?.value === 'ByFanSize'
                ? 'Max. Coverage Radius (ft.)'
                : 'Required Coverage Radius (ft.)',
              (size): string =>
                this.sizeList[0].selectionLayoutConfig.getQuestion(
                  'SelectionMethod'
                )?.value === 'ByFanSize'
                  ? size.outputs.coverageRadius
                  : size.outputs.actualFanSpacing / 2,
              '1.0-0'
            ),
            getCompDataRow(
              'Ave. Air Speed (ft/min)',
              (size): string => size.outputs.averageAirSpeed,
              '1.0-0'
            ),
            getCompDataRow(
              'Avg. Air Speed Max (ft/min)',
              (size): string => size.outputs.maximumAverageAirSpeed,
              '1.0-0'
            ),
            this.subGroup === 'circulator'
              ? getCompDataRow(
                  'Cooling Effect (F)',
                  (size): string => size.outputs.coolingEffect,
                  '1.0-0'
                )
              : undefined,
            getCompDataRow(
              'Fan RPM',
              (size): string => size.outputs.speed,
              '1.0-0'
            ),
            getCompDataRow(
              'Max Fan RPM',
              (size): string => size.outputs.speedMax,
              '1.0-0'
            ),
            this.subGroup === 'circulator'
              ? getCompDataRow(
                  'Integrated Efficiency (CFM/W)',
                  (size): string => size.outputs.fanEfficiency,
                  '1.0-0'
                )
              : undefined,
            getCompDataRow(
              'Total dBA per Fan',
              (size): string => size.outputs.outletdBA,
              '1.0-0'
            ),
          ].filter((item) => !!item),
        });

        // Pricing
        newRows.push({
          title: 'Pricing',
          data: [
            getCompDataRow(
              'Budget Price (USD)',
              (size) => size.pricing.price,
              (value) =>
                new CurrencyPipe('en-us').transform(
                  value,
                  'USD',
                  'symbol',
                  '1.0-0'
                )
            ),
            getCompDataRow(
              'Operating Cost / yr (USD)',
              (size) => size.outputs.operatingCostPerYear,
              (value) =>
                new CurrencyPipe('en-us').transform(
                  value,
                  'USD',
                  'symbol',
                  '1.0-0'
                )
            ),
          ],
        });

        break;
      }
      case ProductTypes.makeUpAir: {
        // Dimensions
        newRows.push({
          title: 'Dimensions',
          data: [
            getCompDataRow(
              'Overall Height (in.)',
              (size) => size.outputs.housingWheelModel.dimensions.height,
              '1.0-0'
            ),
            getCompDataRow(
              'Overall Length (in.)',
              (size) => size.outputs.housingWheelModel.dimensions.length,
              '1.0-0'
            ),
            getCompDataRow(
              'Overall Width (in.)',
              (size) => size.outputs.housingWheelModel.dimensions.width,
              '1.0-0'
            ),
          ],
        });

        // Performance
        newRows.push({
          title: 'Performance',
          data: [
            getCompDataRow(
              'Total Static Pressure (in. wg)',
              (size) =>
                size.outputs.fanModel.pressureDrop.totalSupplyAirPressureDrop,
              '1.2-2'
            ),
            getCompDataRow(
              'Operating Power (Bhp)',
              (size) => size.outputs.fanModel.supplyFanBHP,
              (value) => new BreakHorsePowerPipe().transform(value)
            ),
            getCompDataRow(
              'Motor Size (hp)',
              (size) =>
                size.outputs.fanModel.supplyMotorList.find(
                  (motor) =>
                    motor.motorModelId ===
                    size.outputs.fanModel.supplyMotorModelId
                )?.motorHorsePowerValue,
              '1.0-2'
            ),
            getCompDataRow(
              'Fan Type',
              (size) => size.outputs.fanModel.supplyBlowerType,
              (value) => new CamelToDisplayPipe().transform(value)
            ),
            getCompDataRow(
              'Fan RPM',
              (size) => size.outputs.fanModel.supplyFanRPM,
              '1.0-0'
            ),
            getCompDataRow(
              'Heating Capacity (MBH or KW)',
              (size) => size.outputs.heatingModel.heatingCapacity,
              '1.0-0'
            ),
            getCompDataRow(
              'Heating LAT (DB)',
              (size) => size.outputs.heatingModel.leavingDryBulb,
              '1.0-0'
            ),
            getCompDataRow(
              'Total Cooling Capacity (MBH)',
              (size) => size.outputs.coolingModel.coolingCapacity,
              '1.0-0'
            ),
            getCompDataRow(
              'Cooling LAT (DB/WB)',
              (size) =>
                `${new ZeroToDashPipe().transform(
                  size.outputs.coolingModel.leavingAirDryBulb,
                  '1.1-1'
                )} / ${new ZeroToDashPipe().transform(
                  size.outputs.coolingModel.leavingAirWetBulb,
                  '1.1-1'
                )}`
            ),
            getCompDataRow(
              'MCA/MOP (amps)',
              (size) =>
                `${new ZeroToDashPipe().transform(
                  size.outputs.fanModel.minimumCircuitAmps,
                  '1.1-1'
                )} / ${new ZeroToDashPipe().transform(
                  size.outputs.fanModel.maximumOvercurrentProtection,
                  '1.0-0'
                )}`
            ),
          ],
        });

        // Pricing
        newRows.push({
          title: 'Pricing',
          data: [
            getCompDataRow(
              'Total Cost of Ownership (USD)',
              (size) => size.pricing.totalCostOfOwnership,
              (value) =>
                new CurrencyPipe('en-us').transform(
                  value,
                  'USD',
                  'symbol',
                  '1.0-0'
                )
            ),
          ],
        });

        break;
      }
      case ProductTypes.preconditioners: {
        // Pricing
        newRows.push({
          title: 'Pricing',
          data: [
            getCompDataRow(
              'Budget Price (USD)',
              (size) => size.pricing.price,
              (value) =>
                new CurrencyPipe('en-us').transform(
                  value,
                  'USD',
                  'symbol',
                  '1.0-0'
                )
            ),
            getCompDataRow(
              'Relative Cost',
              (size) => size.pricing.relativePrice,
              '1.2-2'
            ),
          ],
        });

        // Energy Recovery
        newRows.push({
          title: 'Energy Recovery',
          data: [
            getCompDataRow(
              'ER Device',
              (size) =>
                size.outputs.housingWheelModel.energyRecoveryPerformance
                  .energyRecoveryTypeActual
            ),
            getCompDataRow(
              'ER Media',
              (size) =>
                size.outputs.housingWheelModel.energyRecoveryPerformance
                  .energyRecoveryMedia
            ),
            getCompDataRow(
              'OA Cooling Reduction (tons)',
              (size) =>
                size.outputs.housingWheelModel.energyRecoveryPerformance
                  .outdoorAirCoolingReduction,
              '1.0-1'
            ),
            getCompDataRow(
              'OA Heating Reduction (btu/hr)',
              (size) =>
                size.outputs.housingWheelModel.energyRecoveryPerformance
                  .outdoorAirHeatingReduction,
              '1.0-0'
            ),
          ],
        });

        // Performance
        newRows.push({
          title: 'Performance',
          data: [
            getCompDataRow(
              'Summer Enthalpy Recovery Ratio (%)',
              (size) =>
                size.outputs.housingWheelModel.energyRecoveryPerformance
                  .totalOutdoorEfficiencySummer,
              '1.0-1'
            ),
            getCompDataRow(
              'Summer LAT (DB/WB) (F)',
              (size) =>
                `${new ZeroToDashPipe().transform(
                  size.outputs.housingWheelModel.energyRecoveryPerformance
                    .summerDryBulbOutdoorAirLeaving,
                  '1.1-1'
                )} / ${new ZeroToDashPipe().transform(
                  size.outputs.housingWheelModel.energyRecoveryPerformance
                    .summerWetBulbOutdoorAirLeaving,
                  '1.1-1'
                )}`
            ),
            getCompDataRow(
              'Winter Enthalpy Recovery Ratio (%)',
              (size) =>
                size.outputs.housingWheelModel.energyRecoveryPerformance
                  .totalOutdoorEfficiencyWinter,
              '1.0-1'
            ),
            getCompDataRow(
              'Winter LAT (DB/WB) (F)',
              (size) =>
                `${new ZeroToDashPipe().transform(
                  size.outputs.housingWheelModel.energyRecoveryPerformance
                    .winterDryBulbOutdoorAirLeaving,
                  '1.1-1'
                )} / ${new ZeroToDashPipe().transform(
                  size.outputs.housingWheelModel.energyRecoveryPerformance
                    .winterWetBulbOutdoorAirLeaving,
                  '1.1-1'
                )}`
            ),
            getCompDataRow(
              'MCA/MOP (amps)',
              (size) =>
                `${new ZeroToDashPipe().transform(
                  size.outputs.fanModel.minimumCircuitAmps,
                  '1.1-1'
                )} / ${new ZeroToDashPipe().transform(
                  size.outputs.fanModel.maximumOvercurrentProtection,
                  '1.0-0'
                )}`
            ),
          ],
        });

        // Dimensions & Weight
        newRows.push({
          title: 'Dimensions & Weight',
          data: [
            getCompDataRow(
              'Dimensions (LxWxH) (in.)',
              (size) =>
                `${new ZeroToDashPipe().transform(
                  size.outputs.housingWheelModel.dimensions.length,
                  '1.1-1'
                )} x ${new ZeroToDashPipe().transform(
                  size.outputs.housingWheelModel.dimensions.width,
                  '1.1-1'
                )} x ${new ZeroToDashPipe().transform(
                  size.outputs.housingWheelModel.dimensions.height,
                  '1.1-1'
                )}`
            ),
            getCompDataRow(
              'Weight (lbs)',
              (size) => size.pricing.weight,
              '1.0-0'
            ),
          ],
        });

        break;
      }
      default: {
        // Application
        newRows.push(getApplicationText());

        // Dimensions
        newRows.push({
          title: 'Dimensions & Weight',
          data: [
            ...getDimenstions(undefined, 'in.'),
            getCompDataRow(
              'Weight (lbs)',
              (size) => size.pricing.weight,
              '1.0-0'
            ),
          ],
        });

        // Performance
        if (this.subGroup === 'fumeexhaust') {
          const fanCount = (size: ValidSize): number => {
            const index = [
              'one',
              'two',
              'three',
              'four',
              'five',
              'six',
              'seven',
              'eight',
              'nine',
              'ten',
            ].indexOf(
              size.selectionLayoutConfig
                .getQuestion('ExhaustSystemFanQuantity')
                ?.value.toLowerCase()
                .trim()
            );

            return index > -1 ? index + 1 : 0;
          };

          if (fanCount(this.sizeList[0]) > 1) {
            // System Performance
            newRows.push({
              title: 'System Performance',
              data: [
                getCompDataRow(
                  'Number of Operating Fans',
                  (size) => fanCount(size),
                  '1.0-0'
                ),
                getCompDataRow(
                  'System Inlet Volume (CFM)',
                  (size) =>
                    size.selectionLayoutConfig.getQuestion('Volume')?.value,
                  '1.0-0'
                ),
              ],
            });
          }

          // Individual Fan Performance
          newRows.push({
            title: 'Individual Fan Performance',
            data: [
              getCompDataRow(
                'Inlet Volume (CFM)',
                (size) => size.outputs.operatingVolume,
                '1.0-0'
              ),
              getCompDataRow(
                'External Static Pressure (in. wg)',
                (size) =>
                  size.selectionLayoutConfig.getQuestion('StaticPressure')
                    ?.value,
                '1.0-3'
              ),
              getCompDataRow(
                'Total Static Pressure (in. wg)',
                (size) => size.outputs.operatingStaticPressure,
                '1.0-3'
              ),
              getCompDataRow(
                'Operating Power (Bhp)',
                (size) => size.outputs.operatingPower,
                (value) => new BreakHorsePowerPipe().transform(value)
              ),
              getCompDataRow('Fan RPM', (size) => size.outputs.speed, '1.0-0'),
              getCompDataRow(
                'Percent within Max Fan RPM',
                (size) =>
                  (size.outputs.speedMax - size.outputs.speed) /
                  size.outputs.speedMax,
                (value) => new PercentPipe('en-us').transform(value, '1.0-0')
              ),
              getCompDataRow(
                'Inlet Sound (sones)',
                (size) => size.outputs.inletSound?.sones,
                (value) => new HemisphericalSonesPipe().transform(value)
              ),
              getCompDataRow(
                'Outlet Sound (sones)',
                (size) => size.outputs.outletSound?.sones,
                (value) => new HemisphericalSonesPipe().transform(value)
              ),
              getCompDataRow(
                'Inlet Sound (dBA)',
                (size) => size.outputs.inletSound?.dba,
                '1.0-0'
              ),
              getCompDataRow(
                'Outlet Sound (dBA)',
                (size) => size.outputs.outletSound?.dba,
                '1.0-0'
              ),
              getCompDataRow(
                'Effective Plume Height (ft.)',
                (size) => size.outputs.plumeHeight,
                '1.0-1'
              ),
              getCompDataRow(
                'Dilution Ratio',
                (size) => size.outputs.dilutionRatio,
                '1.0-0'
              ),
              getCompDataRow(
                'Nozzle Outlet Velocity (ft/min)',
                (size) => size.outputs.nozzleOutletVelocity,
                '1.0-0'
              ),
            ],
          });
        } else {
          switch (this.sizeList[0].productType) {
            case ProductTypes.gravity: {
              newRows.push({
                title: 'Performance',
                data: [
                  getCompDataRow(
                    'Actual Area (ft²)',
                    (size) => size.outputs.actualThroatArea,
                    '1.2-2'
                  ),
                  getCompDataRow(
                    'Actual Volume (CFM)',
                    (size) => size.outputs.actualVolume,
                    '1.0-0'
                  ),
                  getCompDataRow(
                    'Actual Pressure (in. wg)',
                    (size) => size.outputs.actualPressureDrop,
                    '1.2-2'
                  ),
                  getCompDataRow(
                    'Actual Throat Velocity (ft/min)',
                    (size) => size.outputs.actualThroatVelocity,
                    '1.0-0'
                  ),
                  getCompDataRow(
                    'Max Throat Velocity (ft/min)',
                    (size) => size.outputs.maxVelocity,
                    (value) =>
                      new MaxThroatVelocityPipe().transform(
                        value,
                        '1.0-0'
                      ) as string
                  ),
                  getCompDataRow(
                    'Percent within max Velocity (%)',
                    (size) => size.outputs.actualToMaxVelocityPercentage,
                    '1.0-0'
                  ),
                ],
              });

              break;
            }
            case ProductTypes.louver: {
              newRows.push({
                title: 'Performance',
                data: [
                  getCompDataRow(
                    'Volume (CFM)',
                    (size) => size.outputs.actualVolume,
                    '1.0-0'
                  ),
                  getCompDataRow(
                    'Pressure Drop (in wg)',
                    (size) => size.outputs.actualPressureDrop,
                    '1.3-3'
                  ),
                  getCompDataRow(
                    'Velocity (ft/min)',
                    (size) => size.outputs.actualVelocity,
                    '1.0-0'
                  ),
                  getCompDataRow(
                    'BPWP (ft/min)',
                    (size) => size.outputs.beginningPointOfWaterPenetration,
                    '1.0-0'
                  ),
                  getCompDataRow(
                    'Water Penetration Rating',
                    (size) => size.outputs.rainResistanceRanking,
                    '1.0-0'
                  ),
                  getCompDataRow(
                    'Free Area (ft²)',
                    (size) => size.outputs.actualFreeArea,
                    '1.1-1'
                  ),
                  getCompDataRow(
                    'Free Area (%)',
                    (size) => size.outputs.freeAreaPercentage,
                    '1.0-0'
                  ),
                ],
              });

              break;
            }
            default: {
              newRows.push({
                title: 'Performance',
                data: [
                  getCompDataRow(
                    'Actual Volume (CFM)',
                    (size) => size.outputs.operatingVolume,
                    '1.0-0'
                  ),
                  getCompDataRow(
                    'External Static Pressure (in. wg)',
                    (size) =>
                      size.selectionLayoutConfig.getQuestion('StaticPressure')
                        ?.value,
                    '1.2-2'
                  ),
                  getCompDataRow(
                    'Total Static Pressure (in. wg)',
                    (size) => size.outputs.operatingStaticPressure,
                    '1.2-2'
                  ),
                  getCompDataRow(
                    'Operating Power (Bhp)',
                    (size) => size.outputs.operatingPower,
                    '1.2-2'
                  ),
                  getCompDataRow(
                    'Fan RPM',
                    (size) => size.outputs.speed,
                    '1.0-0'
                  ),
                  getCompDataRow('Percent within max fan RPM', (size) =>
                    !!size.outputs.speedMax && !!size.outputs.speed
                      ? new PercentPipe('en-us').transform(
                          (size.outputs.speedMax - size.outputs.speed) /
                            size.outputs.speedMax,
                          '1.0-0'
                        )
                      : '-'
                  ),
                  getCompDataRow(
                    'Inlet Sones',
                    (size) => size.outputs.inletSound?.sones,
                    (value) => new HemisphericalSonesPipe().transform(value)
                  ),
                  this.subGroup === 'ceilingandcabinet'
                    ? getCompDataRow(
                        'Spherical Sones',
                        (size) => size.outputs.inletSound?.sphericalSones,
                        (value) => new SphericalSonesPipe().transform(value)
                      )
                    : undefined,
                  getCompDataRow(
                    'Inlet dBA',
                    (size) => size.outputs.inletSound?.dba,
                    '1.0-0'
                  ),
                ].filter((item) => !!item),
              });

              break;
            }
          }
        }

        // Pricing
        newRows.push({
          title: 'Pricing',
          data: [
            getCompDataRow(
              'Budget Price (USD)',
              (size) => size.pricing.price,
              (value) =>
                new CurrencyPipe('en-us').transform(
                  value,
                  'USD',
                  'symbol',
                  '1.0-0'
                )
            ),
            [ProductTypes.louver, ProductTypes.gravity].indexOf(
              this.sizeList[0].productType
            ) === -1
              ? getCompDataRow(
                  'Operating Cost / yr (USD)',
                  (size) => size.outputs.operatingCost,
                  (value) =>
                    new CurrencyPipe('en-us').transform(
                      value,
                      'USD',
                      'symbol',
                      '1.0-0'
                    )
                )
              : undefined,
          ].filter((item) => !!item),
        });

        // Features
        switch (this.sizeList[0].productType) {
          case ProductTypes.gravity: {
            newRows.push({
              title: 'Features',
              data: [
                getCompDataRow('Housing', (size) => size.features.housing),
                getCompDataRow(
                  'Shipped Assembled',
                  (size) => size.outputs.shippedAssembled,
                  (value) => new BoolToYesNoPipe().transform(value)
                ),
              ],
            });

            break;
          }
          case ProductTypes.louver: {
            newRows.push({
              title: 'Features',
              data: [
                getCompDataRow('Material', (size) => size.features.material),
                getCompDataRow(
                  'Blade Orientation',
                  (size) => size.features.bladeOrientation
                ),
                getCompDataRow('Blade Type', (size) => size.features.bladeType),
                getCompDataRow(
                  'Mullion Type',
                  (size) => size.features.mullionType
                ),
              ],
            });

            break;
          }
          default: {
            newRows.push({
              title: 'Features',
              data: [
                getCompDataRow('Impeller', (size) => size.outputs.impeller),
                getCompDataRow('Housing', (size) => size.outputs.housing),
                getCompDataRow(
                  'Drive Type',
                  (size) => size.outputs.driveTypeDesc
                ),
              ],
            });

            break;
          }
        }

        break;
      }
    }

    this.compareRows = newRows;
  }
}
