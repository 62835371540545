import { Component, OnInit, Input } from '@angular/core';
import { ValidSize } from '../../models/selection-results/valid-size.model';

@Component({
  selector: 'ds-fan-spacing',
  templateUrl: './ds-fan-spacing.component.html',
  styleUrls: ['./ds-fan-spacing.component.scss'],
})
export class DsFanSpacingComponent implements OnInit {
  @Input('size') size: ValidSize;

  constructor() {}

  ngOnInit(): void {}

  getFanSpacingTitle(): string {
    return 'Minimum Fan Spacing';
  }

  getDSFanSpacing(): string {
    return this.size.outputs.minimumFanSpacing;
  }
}
