<div class="list-container">
  <div class="filter-container">
    <form class="filter-form" [formGroup]="formGroup">
      <div *ngIf="!production" class="test-input">
        <mat-label
          >Enter Test Restore JSON or Leave Blank for Default Test</mat-label
        >

        <input formControlName="restoreTestJson" matInput />

        <button mat-raised-button *ngIf="!production" (click)="testRestore()">
          Test Restore
        </button>
      </div>

      <mat-form-field>
        <mat-label>Manufacturer</mat-label>
        <mat-select
          formControlName="manufacturer"
          (selectionChange)="updateFilteredModelList()"
        >
          <mat-option
            *ngFor="let manufacturer of manufacturersList"
            [value]="manufacturer.value"
          >
            {{ manufacturer.text | camelToDisplay }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Filter by Model Name</mat-label>

        <input
          formControlName="filter"
          matInput
          (keyup)="updateFilteredModelList()"
          autocomplete="off"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Product Type</mat-label>
        <mat-select
          formControlName="productType"
          (selectionChange)="updateFilteredModelList()"
        >
          <mat-option
            *ngFor="let productType of productTypeList"
            [value]="productType.value"
          >
            {{ productType.text }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Model Group</mat-label>
        <mat-select
          formControlName="modelGroup"
          (selectionChange)="updateFilteredModelList()"
        >
          <mat-option
            *ngFor="let modelGroup of modelGroupList"
            [value]="modelGroup.value"
          >
            {{ modelGroup.text | camelToDisplay }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div
    class="greenheck-eq-bar"
    *ngIf="manufacturerField.value !== 'Greenheck' && filterField.value !== ''"
  >
    <mat-icon class="mat-icon-warning">warning</mat-icon>
    <span>Greenheck Equivalent</span>
  </div>

  <div class="model-list">
    <mat-card appearance="outlined" *ngFor="let model of filteredModelList">
      <mat-card-header>
        <mat-card-title>
          {{ model.name }}
          <mat-icon [matTooltip]="model.description">info_outline</mat-icon>
        </mat-card-title>

        <mat-card-subtitle>{{ model.subtitle }}&nbsp;</mat-card-subtitle>
      </mat-card-header>

      <div class="image square">
        <img
          #modelImage
          class="model-image loading"
          [attr.data-src]="'/assets/images/models/' + model.image"
          (load)="modelImage.classList.remove('loading')"
        />
        <div
          *ngIf="
            manufacturerField.value !== 'Greenheck' && filterField.value !== ''
          "
          class="comp-match"
        >
          Matches {{ getMatchModel(model) }}
        </div>
      </div>

      <mat-card-actions>
        <div
          [matTooltip]="
            !model.allowInsert ? 'Insertable content not yet available.' : null
          "
        >
          <button
            mat-button
            color="primary"
            (click)="insertClick(model)"
            [disabled]="!model.allowInsert"
          >
            <mat-icon svgIcon="revit"></mat-icon>
            <span>Insert</span>
          </button>
        </div>

        <div>
          <button
            class="download-btn"
            mat-button
            color="primary"
            (click)="downloadClick(model)"
          >
            <mat-icon>cloud_download</mat-icon>
            <span>Download</span>
          </button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
