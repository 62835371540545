import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevIconComponent } from './components/dev-icon/dev-icon.component';
import { ClearLocalStorageDialogComponent } from './dialogs/clear-local-storage-dialog/clear-local-storage-dialog.component';
import { InvalidSizesDialogComponent } from './dialogs/invalid-sizes-dialog/invalid-sizes-dialog.component';
import { WebserviceUrlDialogComponent } from './dialogs/webservice-url-dialog/webservice-url-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatListModule,
  ],
  declarations: [
    DevIconComponent,
    ClearLocalStorageDialogComponent,
    InvalidSizesDialogComponent,
    WebserviceUrlDialogComponent,
  ],
  exports: [DevIconComponent],
})
export class DevModule {}
