import { Revision } from './project-revision.model';
import { ProjectRevisionUpdateTypes } from '../enums/project-revision-update-types.enum';
import { IProjectEvent } from './i-project-event.interface';
import { Item } from './project-item.model';

export class Note {
  private _id: string;
  public get id(): string {
    return this._id;
  }

  private _content: string;
  public get content(): string {
    return this._content;
  }
  public set content(value: string) {
    this._content = value;

    this.markDirty();

    this.fireUpdate(ProjectRevisionUpdateTypes.noteUpdated);
  }

  public revision: Revision;

  public item: Item;

  constructor(data: any) {
    this._id = !!data.id ? data.id : data.revisionNoteId;

    this._content = data.content;
  }

  private markDirty() {
    if (!!this.revision && !!this.revision.project) {
      this.revision.project.isDirty = true;
    } else if (!!this.item && !!this.item.revision && !!this.item.revision.project) {
      this.item.revision.project.isDirty = true;
    }
  }

  private fireUpdate(type: ProjectRevisionUpdateTypes) {
    if (!!this.revision) {
      const eventData: IProjectEvent = {
        source: this,
        type
      };

      this.revision.updated.next(eventData);

      if (!!this.revision.project) {
        this.revision.project.updated.next(eventData);
      }
    } else if (!!this.item) {
      const eventData: IProjectEvent = {
        source: this,
        type
      };

      this.item.updated.next(eventData);

      if (!!this.item.revision) {
        this.item.revision.updated.next(eventData);

        if (!!this.item.revision.project) {
          this.item.revision.project.updated.next(eventData);
        }
      }
    }
  }
}
