import { Injectable } from '@angular/core';
import {
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MaintenancePageGuard {

    maintenancePage = false;

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {

        if (state.url === '/maintenance' && !this.maintenancePage) {

            this.maintenancePage = true;

        }

        if (this.maintenancePage && state.url !== '/maintenance') {

            this.router.navigate(['/maintenance']);

            return false;
        }

        return true;

    }

}
