<h2 mat-dialog-title>{{ title }}</h2>

<mat-dialog-content>
  <form [formGroup]="formGroup">
    <div>Please enter the people you would like to send to below:</div>
    <div class="recipients">
      <div class="title">Recipient(s):</div>
      <mat-form-field>
        <mat-label>Number of Recipients</mat-label>

        <mat-select
          formControlName="recipientCount"
          (selectionChange)="recipientCountChanged()"
        >
          <mat-option [value]="1">1</mat-option>
          <mat-option [value]="2">2</mat-option>
          <mat-option [value]="3">3</mat-option>
          <mat-option [value]="4">4</mat-option>
          <mat-option [value]="5">5</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="content" formGroupName="recipients">
        <div
          *ngFor="let recipient of recipients; let i = index"
          class="recipient-line"
          [formGroupName]="'recipient' + i.toString()"
        >
          <mat-form-field>
            <mat-label>Name</mat-label>

            <input formControlName="name" matInput />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Email</mat-label>

            <input formControlName="email" matInput />
            <mat-error>{{ getRecipientError(recipient.email) }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="subject">
      <mat-form-field>
        <mat-label>Subject</mat-label>

        <input formControlName="subject" matInput />
      </mat-form-field>
    </div>
    <div class="message">
      <mat-form-field>
        <mat-label>Message</mat-label>

        <textarea formControlName="message" matInput></textarea>
      </mat-form-field>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-raised-button
    color="primary"
    [disabled]="!canAddRep()"
    (click)="findRepClick()"
  >
    Find Greenheck Rep
  </button>
  <button
    mat-raised-button
    color="primary"
    [disabled]="formGroup.invalid"
    (click)="sendClick()"
  >
    Send
  </button>
  <button mat-raised-button color="primary" matDialogClose>Cancel</button>
</mat-dialog-actions>
