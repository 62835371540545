import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { UsersModule } from '../users/users.module';
import { MenuItemList } from './services/constants/menu-item-list.const';
import { SideBarService } from './services/side-bar.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    UsersModule,
    MatIconModule,
    MatBadgeModule
  ],
  declarations: [],
  providers: [MenuItemList, SideBarService]
})
export class SideBarModule {}
