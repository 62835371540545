import { Revision } from './project-summar-revision.model';

export class ProjectSummary {
  active: boolean;
  creationDate: Date;
  id: string;
  lastSavedDate: Date;
  location: string;
  name: string;
  owner: string;
  revisions: Array<Revision>;

  constructor(data: any) {
    this.active = data.active;
    this.creationDate = new Date(data.creationDate);
    this.id = data.id;
    this.lastSavedDate = new Date(data.lastSavedDate);
    this.location = data.location;
    this.name = data.name;
    this.owner = data.owner;

    this.revisions = new Array<Revision>();

    if (!!data.revisions) {
      data.revisions.every(revision => {
        this.revisions.push(new Revision(revision));

        return true;
      });
    }
  }

  getActiveRevision(): Revision {
    const revisions = this.revisions.filter(revision => revision.active === true);

    if (revisions.length > 0) {
      return revisions[0];
    } else {
      return null;
    }
  }
}
