<form
  [class.required]="question.required"
  [class.disabled]="!question.enabled"
  [class.elevation]="question.name.toLowerCase().trim() === 'elevation'"
  [class.bath-sp]="showBathFanSPCalc()"
  [class.linear]="linear"
  class="configuration-question"
  [formGroup]="formGroup"
>
  <mat-form-field
    [ngSwitch]="hasAnswers()"
    [class.elevation]="question.name.toLowerCase().trim() === 'elevation'"
    [class.help-item]="!!helpItem"
    [class.bath-sp]="showBathFanSPCalc()"
  >
    <mat-label>{{ question.text }}</mat-label>

    <input
      *ngSwitchCase="false"
      #inputField
      matInput
      formControlName="mainInput"
      [type]="
        question.type.toLowerCase().trim() === 'string' ? 'text' : 'number'
      "
      [step]="question.type.toLowerCase().trim() === 'float' ? '0.0001' : '1'"
      (focus)="inputFocus()"
      (blur)="inputBlur()"
      autocomplete="off"
    />

    <!-- TODO: Remove when the Google Maps API is working
    <button
      mat-icon-button
      matSuffix
      *ngIf="
        question.name.toLowerCase().trim() === 'elevation' &&
        (chromeVersion >= 88 || chromeVersion === -1)
      "
      (click)="findElevation()"
      tabindex="-1"
      matTooltip="Find Elevation"
    >
      <mat-icon>place</mat-icon>
    </button> -->

    <button
      mat-icon-button
      matSuffix
      *ngIf="showBathFanSPCalc()"
      (click)="bathSPCalcClick()"
      tabindex="-1"
      matTooltip="Calculate"
    >
      <mat-icon>input</mat-icon>
    </button>

    <button
      mat-icon-button
      matSuffix
      *ngIf="!!helpItem"
      (stopPropClick)="helpIconClick()"
      tabindex="-1"
      [matTooltip]="helpItem.toolTip || 'Help'"
    >
      <ng-container
        [ngSwitch]="!!helpItem.icon ? 1 : !!helpItem.svgIcon ? 2 : 0"
      >
        <mat-icon *ngSwitchCase="2" [svgIcon]="helpItem.svgIcon"></mat-icon>
        <mat-icon *ngSwitchCase="1">{{ helpItem.icon }}</mat-icon>
        <mat-icon *ngSwitchDefault>help_outline</mat-icon>
      </ng-container>
    </button>

    <ng-container *ngSwitchCase="true">
      <ng-container [ngSwitch]="isColorPicker()">
        <mat-select
          *ngSwitchCase="true"
          formControlName="mainInput"
          (focus)="inputFocus()"
          (selectionChange)="questionUpdate()"
        >
          <mat-select-trigger>{{
            getAnswer(mainInput.value)?.text
          }}</mat-select-trigger>

          <mat-option
            *ngFor="let answer of getQuestionAnswers()"
            [class.forceable]="
              answer.state.toLowerCase().trim() === 'forceable'
            "
            [title]="
              answer.state.toLowerCase().trim() === 'forceable'
                ? 'This option will cause a conflict'
                : ''
            "
            [value]="answer.name"
            class="side-swatch"
          >
            <div class="swatch" [style.background]="getSwatchBG(answer.name)">
              <mat-icon *ngIf="!hasSwatch(answer.name)">help_outline</mat-icon>
            </div>
            <mat-icon
              *ngIf="answer.state.toLowerCase().trim() === 'forceable'"
              class="dp20"
              >warning</mat-icon
            ><span>{{ answer.text }}</span>
          </mat-option>
        </mat-select>

        <mat-select
          *ngSwitchCase="false"
          formControlName="mainInput"
          (focus)="inputFocus()"
          (selectionChange)="questionUpdate()"
        >
          <mat-select-trigger>{{ getQuestionAnswerText() }}</mat-select-trigger>

          <mat-option
            *ngFor="let answer of getQuestionAnswers()"
            [class.forceable]="
              answer.state.toLowerCase().trim() === 'forceable'
            "
            [title]="
              answer.state.toLowerCase().trim() === 'forceable'
                ? 'This option will cause a conflict'
                : ''
            "
            [value]="answer.name"
          >
            <mat-icon
              *ngIf="answer.state.toLowerCase().trim() === 'forceable'"
              class="dp20"
              >warning</mat-icon
            >{{ answer.text }}
          </mat-option>
        </mat-select>
      </ng-container>
    </ng-container>

    <mat-error *ngIf="!!mainInput.errors">{{
      mainInput | fieldErrorToText
    }}</mat-error>
    <mat-hint *ngIf="showElevationHint()"
      >Job elevation is
      {{ localProject?.elevation | number : '1.0-0' }} ft</mat-hint
    >
  </mat-form-field>
</form>
