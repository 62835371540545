<div class="outdoor-air-accessories-page">
  <mat-card appearance="outlined">
    <mat-card-title>Greenheck Outdoor-Air Optional Accessories</mat-card-title>
    <mat-card-content>
      Greenheck outdoor air units are available with a number of different
      accessories to fit the need of any project. The list below includes all of
      the available options as well as accessory descriptions to help select the
      accessories that best fit the project requirements.
    </mat-card-content>
  </mat-card>

  <div class="table-parent">
    <table mat-table [dataSource]="accessories">
      <ng-container matColumnDef="accessory">
        <th mat-header-cell *matHeaderCellDef>Accessory</th>
        <td mat-cell *matCellDef="let element">{{ element.accessory }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>Options</th>
        <td mat-cell *matCellDef="let element">
          <div
            class="option-row"
            *ngFor="let option of element.options"
            [innerHTML]="option"
          ></div>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['accessory', 'description', 'options']; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['accessory', 'description', 'options']"
      ></tr>
    </table>
  </div>
</div>
