import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  CamelToDisplayPipe,
  ZeroToDashPipe,
} from '../../../../ecaps-core/pipes/custom-pipes.pipe';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { ColumnList } from '../../constants/column-list.const';

@Component({
  selector: 'mua-none-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MuaNoneGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  constructor(protected injector: Injector) {
    super(injector);

    this.footerProductType = 'MUA(s)';
    this.gridSaveKeyOverride = 'MakeUpAir_HeatingTypeNone';
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'operatingStaticPressure',
      'relativeCost',
      //'totalCostOfOwnership',
      'fanType',
      'driveType',
      'totalBhp',
      'bhp',
      'fei',
      'fanRPM',
      'speedNonAdjusted',
      'motorHP',
      'motorQty',
      'inletSones',
      'inletDba',
      'coolingLat',
      'totalCoolingCap',
      'mcaMop',
      'weight',
      'dimensionsLWH'
      //'budgetPrice'
    );

    const sones = this.columnList.find((item) => item.key === 'inletSones');

    sones.text = 'Sones';

    const dba = this.columnList.find((item) => item.key === 'inletDba');

    dba.text = 'dBA';

    this.setVisibleColumns([
      'operatingStaticPressure',
      'relativeCost',
      'fanType',
      'driveType',
      'totalBhp',
      'inletSones',
      'totalCoolingCap',
      'mcaMop',
      'weight',
      'dimensionsLWH',
    ]);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();
  }

  findSupplyFan(size: ValidSize): number {
    for (let i = 0; i < size.outputs.fanModel.supplyMotorList.length; i++) {
      if (
        size.outputs.fanModel.supplyMotorList[i].motorModelId ===
        size.outputs.fanModel.supplyMotorModelId
      ) {
        return i;
      }
    }

    return 0;
  }

  private validSizeToDSRow(size: ValidSize) {
    const zeroDash = new ZeroToDashPipe();
    const camelCaseDisplay = new CamelToDisplayPipe();

    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.outputs.fanModel.fanModelName,
      operatingStaticPressure:
        size.outputs.fanModel.pressureDrop.totalSupplyAirPressureDrop,
      relativeCost: size.pricing.relativePrice,
      //totalCostOfOwnership: size.pricing.totalCostOfOwnership,
      fanType: `${camelCaseDisplay.transform(
        size.outputs.fanModel.supplyBlowerType
      )}`,
      driveType: size.outputs.fanModel.supplyBlowerDriveType,
      totalBhp: `${zeroDash.transform(
        size.outputs.fanModel.supplyFanTotalBHP,
        '1.0-2'
      )}`,
      bhp: `${zeroDash.transform(size.outputs.fanModel.supplyFanBHP, '1.0-2')}`,
      fanRPM: size.outputs.fanModel.supplyFanRPM,
      speedNonAdjusted:
        size.outputs.fanModel.supplyMotorList[this.findSupplyFan(size)]
          .motorRPM,
      motorHP:
        size.outputs.fanModel.supplyMotorList[this.findSupplyFan(size)]
          .motorHorsePowerValue,
      motorQty: size.outputs.fanModel.supplyFanMotorQuantity,
      fei: size.outputs.fanModel.supplyFanEnergyIndex,
      inletSones: size.outputs.fanModel.sound.supplySound.outletSoundSones,
      inletDba: size.outputs.fanModel.sound.supplySound.outletSoundDba,
      heatingLatDb: size.outputs.heatingModel.leavingDryBulb,
      heatingCap: `${zeroDash.transform(
        size.outputs.heatingModel.heatingCapacity,
        '1.0-0',
        size.outputs.heatingModel.selectedHeatingModelType
          .toLowerCase()
          .trim() === 'electric'
          ? 'kW'
          : 'MBH'
      )}`,
      coolingLat: `${zeroDash.transform(
        size.outputs.coolingModel.leavingAirDryBulb,
        '1.1-1'
      )} /
        ${zeroDash.transform(
          size.outputs.coolingModel.leavingAirWetBulb,
          '1.1-1'
        )}`,
      totalCoolingCap: size.outputs.coolingModel.coolingCapacity,
      mcaMop: `${zeroDash.transform(
        size.outputs.fanModel.minimumCircuitAmps,
        '1.1-1'
      )} /
        ${zeroDash.transform(
          size.outputs.fanModel.maximumOvercurrentProtection,
          '1.0-1'
        )}`,
      weight: size.pricing.weight,
      dimensionsLWH: `${zeroDash.transform(
        size.outputs.housingWheelModel.dimensions.length,
        '1.0-0'
      )} x
        ${zeroDash.transform(
          size.outputs.housingWheelModel.dimensions.width,
          '1.0-0'
        )} x
        ${zeroDash.transform(
          size.outputs.housingWheelModel.dimensions.height,
          '1.0-0'
        )}`,
      //budgetPrice: size.pricing.price,
      stable: size.stability.stable,
      sizeData: size,
    };

    return rowData;
  }
}
