import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DimensionData } from '../models/dimension-data.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DrawingService {
  constructor(private http: HttpClient) {}

  getSizeDimensions(sizeLayoutConfigKey: string): Promise<DimensionData> {
    return new Promise<DimensionData>((results, reject) => {
      firstValueFrom(
        this.http.get(
          environment.serviceRoot + `dimension/base/${sizeLayoutConfigKey}`,
          {
            headers: new HttpHeaders({
              'api-key': environment.defaultApiKey,
            }),
          }
        )
      ).then(
        (resultData) => {
          results(new DimensionData(resultData));
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }
}
