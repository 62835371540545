import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../ecaps-core/services/http.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DescriptionService {
  constructor(private http: HttpService) {}

  getDescription(entityKey: string, template: string): Promise<Array<string>> {
    return new Promise<Array<string>>((results, reject) => {
      firstValueFrom(
        this.http.get(`description/entities/${entityKey}/${template}`, {
          headers: new HttpHeaders({
            'api-key': environment.defaultApiKey,
          }),
        })
      ).then(
        (resultData) => {
          let text = <string>resultData['content'].trim();

          text = text.replace(/\r\n/g, '\n');

          while (text.indexOf('\n\n') > -1) {
            text = text.replace(/\n\n/g, '\n');
          }

          results(text.split(/\n/g));
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }
}
