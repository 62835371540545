import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[stopPropClick]'
})
export class StopPropClickDirective {
  @Output('stopPropClick') clickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  @HostListener('click', ['$event'])
  onclick($event: MouseEvent) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    $event.stopPropagation();
    $event.cancelBubble = true;

    this.clickEvent.next($event);
  }
}
