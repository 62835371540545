<mat-nav-list>
  <mat-list-item (click)="downloadAll()">
    <mat-icon matListItemIcon>done_all</mat-icon>
    <h4 matListItemTitle>All Documents</h4>
  </mat-list-item>
  <mat-divider></mat-divider>
  <mat-list-item
    *ngFor="let listItem of documentList"
    (click)="download(listItem.type)"
  >
    <mat-icon
      *ngIf="!!listItem.svgIcon"
      matListItemIcon
      [svgIcon]="listItem.svgIcon"
    ></mat-icon>
    <mat-icon *ngIf="!listItem.svgIcon" matListItemIcon>{{
      listItem.icon
    }}</mat-icon>
    <h4 matListItemTitle>{{ listItem.text }}</h4>
  </mat-list-item>
</mat-nav-list>
