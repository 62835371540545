import { Injectable, EventEmitter } from '@angular/core';
import { CommunicationsService } from './communications.service';
import { RevitEvents, PluginEvents } from '../models/revit-events.enum';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../ecaps-core/services/auth.service';
import { LachesisService } from '../../analytics/services/lachesis.service';
import { IRevitAppState, IRevitState } from '../models/i-revit-state.inteface';
import {
  RevitStateLevels,
  RevitStateLevelsInt,
} from '../models/revit-state-levels.enum';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RevitCommsService {
  revitAppUpdated = new EventEmitter<void>();

  private _revitVersion: string;
  public get revitVersion(): string {
    return this._revitVersion;
  }
  public set revitVersion(value: string) {
    this._revitVersion = value;

    this.revitAppUpdated.next();
  }

  private _revitPluginVersion: string;
  public get revitPluginVersion(): string {
    return this._revitPluginVersion;
  }
  public set revitPluginVersion(value: string) {
    this._revitPluginVersion = value;

    this.revitAppUpdated.next();
  }

  public get isRevitApp() {
    this.lachesisService.isRevit = !!this._revitVersion;
    return !!this._revitVersion;
  }

  public get localCommsService(): CommunicationsService {
    return this.commsService;
  }

  private _reselectStates: IRevitState[];
  public get reselectStates(): IRevitState[] {
    return this._reselectStates;
  }

  constructor(
    private commsService: CommunicationsService,
    private authService: AuthService,
    private lachesisService: LachesisService,
    private router: Router
  ) {
    this.commsService.addEvent(
      RevitEvents.version,
      () =>
        new Promise<string>((respond) => {
          respond(JSON.stringify({ version: environment.version }));
        })
    );

    this.commsService.addEvent(
      RevitEvents.setAuth,
      (authToken) =>
        new Promise<void>((resolve, reject) => {
          window.localStorage.setItem('authToken', authToken);

          this.authService.loadAuthToken(true);

          this.authService.validateUser(false, false).then((userData) => {
            if (userData.authenticated) {
              resolve();
            } else {
              reject();
            }
          }, reject);
        })
    );

    this.commsService.addEvent(
      RevitEvents.logout,
      () =>
        new Promise<void>((resolve) => {
          this.authService.resetAuthentication();

          resolve();
        })
    );

    this.commsService.addEvent(
      RevitEvents.reselect,
      (payload: string) =>
        new Promise<void>((resolve, reject) => {
          if (!payload) {
            reject(new Error('Invalid payload'));
          } else {
            let appStateData: IRevitAppState = null;

            try {
              appStateData =
                typeof payload === 'string' ? JSON.parse(payload) : payload;
            } catch (ex) {
              reject(ex);

              return;
            }

            if (
              !appStateData ||
              !appStateData.States ||
              !appStateData.States.$values
            ) {
              reject(new Error('Invalid payload'));

              return;
            }

            if (appStateData.States.$values.length === 0) {
              resolve();

              return;
            }

            this._reselectStates = [];
            appStateData.States.$values.forEach((valueItem) => {
              if (
                (!valueItem.BusinessUnit &&
                  (valueItem.Level === RevitStateLevelsInt.product ||
                    valueItem.Level === RevitStateLevelsInt.selector)) ||
                (!valueItem.EntityName &&
                  (valueItem.Level === RevitStateLevelsInt.product ||
                    valueItem.Level === RevitStateLevelsInt.selector)) ||
                (valueItem.Level !== RevitStateLevelsInt.mark &&
                  valueItem.Level !== RevitStateLevelsInt.product &&
                  valueItem.Level !== RevitStateLevelsInt.selector) ||
                !valueItem.Values
              ) {
                reject(new Error('Invalid payload'));

                return;
              }

              if (
                valueItem.Values.$values.filter(
                  (item) => !item || !item.Key || !item.Value
                ).length > 0
              ) {
                reject(new Error('Invalid payload'));

                return;
              }
              let levelSelected;
              switch (valueItem.Level) {
                case RevitStateLevelsInt.mark: {
                  levelSelected = RevitStateLevels.mark;
                  break;
                }
                case RevitStateLevelsInt.product: {
                  levelSelected = RevitStateLevels.product;
                  break;
                }
                case RevitStateLevelsInt.selector: {
                  levelSelected = RevitStateLevels.selector;
                  break;
                }
              }
              this._reselectStates.push({
                businessUnit: valueItem.BusinessUnit,
                entityName: valueItem.EntityName,
                level: levelSelected,
                values: valueItem.Values.$values.map((item) => ({
                  key: item.Key,
                  value: item.Value,
                })),
              });
            });

            this.router.navigate([
              'revit/performance/reselect',
              this.revitVersion,
              this.revitPluginVersion,
            ]);
          }
        })
    );
  }

  authenticated(): Promise<void> {
    return this.commsService.webToApp(
      PluginEvents.authenticated,
      JSON.parse(window.localStorage.getItem('authToken'))
    );
  }

  download(url: string, fileName?: string): Promise<void> {
    return new Promise<void>((respond, reject) => {
      this.commsService
        .webToApp(PluginEvents.download, { fileName })
        .then(() => {
          this.downloadUrl(url, fileName).then(respond);
        }, reject);
    });
  }

  downloadBlob(blob: Blob, fileName?: string): Promise<void> {
    return new Promise<void>((respond, reject) => {
      this.commsService
        .webToApp(PluginEvents.download, { fileName })
        .then(() => {
          this.downloadBlobData(blob, fileName).then(respond);
        }, reject);
    });
  }

  insert(url: string, fileName?: string): Promise<void> {
    return new Promise<void>((respond, reject) => {
      this.commsService.webToApp(PluginEvents.insert, { fileName }).then(() => {
        this.downloadUrl(url, fileName).then(respond);
      }, reject);
    });
  }

  insertBlob(blob: Blob, fileName?: string): Promise<void> {
    return new Promise<void>((respond, reject) => {
      this.commsService.webToApp(PluginEvents.insert, { fileName }).then(() => {
        this.downloadBlobData(blob, fileName).then(respond);
      }, reject);
    });
  }

  launchPopup(url: string): Promise<void> {
    return new Promise<void>((respond, reject) => {
      if (url.toLowerCase().trim().indexOf('http') !== 0) {
        if (url.trim()[0] !== '/') {
          url = `${window.location.origin}/${url}`;
        } else {
          url = `${window.location.origin}${url}`;
        }
      }

      this.commsService
        .webToApp(PluginEvents.launchPopup, { url: url })
        .then(respond, reject);
    });
  }

  private downloadUrl(url: string, fileName?: string): Promise<void> {
    return new Promise<void>((respond) => {
      const anchor = document.createElement('a');

      (<HTMLElement>anchor).style.display = 'none';
      anchor.download = fileName;
      anchor.href = url;

      document.body.appendChild(anchor);

      anchor.click();

      setTimeout(() => {
        window.URL.revokeObjectURL(anchor.href);
        anchor.remove();

        respond();
      }, 1000);
    });
  }

  private downloadBlobData(blob: Blob, fileName?: string): Promise<void> {
    return new Promise<void>((respond) => {
      if (
        (navigator.userAgent.toLowerCase().indexOf('trident') > -1 ||
          navigator.userAgent.toLowerCase().indexOf('edge') > -1) &&
        !!(navigator as any).msSaveOrOpenBlob
      ) {
        (navigator as any).msSaveOrOpenBlob(blob, fileName);

        respond();
      } else {
        this.downloadUrl(window.URL.createObjectURL(blob), fileName).then(
          respond
        );
      }
    });
  }

  getNewVersion(): {
    startDate: Date;
    version: string;
    message?: string;
  } {
    if (!this.isRevitApp || !this.revitPluginVersion) {
      return null;
    }

    const versions = environment.revit.versions
      .filter((versionItem) => {
        const today = new Date();

        if (versionItem.startDate.getTime() < today.getTime()) {
          const notificationVersion = versionItem.version
            .trim()
            .split('.')
            .map((item) => Number(item));

          const currentVersion = this.revitPluginVersion
            .trim()
            .split('.')
            .map((item) => Number(item));

          if (currentVersion.length < notificationVersion.length) {
            currentVersion.push(
              ...Array(notificationVersion.length - currentVersion.length).fill(
                0
              )
            );
          } else if (notificationVersion.length < currentVersion.length) {
            notificationVersion.push(
              ...Array(currentVersion.length - notificationVersion.length).fill(
                0
              )
            );
          }

          let found = false;

          for (let i = 0; i < notificationVersion.length; i++) {
            if (notificationVersion[i] > currentVersion[i]) {
              found = true;
              break;
            } else if (notificationVersion[i] === currentVersion[i]) {
              continue;
            } else if (notificationVersion[i] < currentVersion[i]) {
              break;
            }
          }

          return found;
        } else {
          return false;
        }
      })
      .sort((a, b) => a.version.localeCompare(b.version));

    if (versions.length > 0) {
      const newVersion = versions[versions.length - 1];

      return {
        startDate: newVersion.startDate,
        version: newVersion.version,
        message: newVersion.message,
      };
    } else {
      return null;
    }
  }
}
