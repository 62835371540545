import { Injectable } from '@angular/core';
import { Chart } from '../models/chart.model';
import { CamelCasePipe } from '../../../ecaps-core/pipes/custom-pipes.pipe';
import { ProductTypes } from '../../enums/product-types.enum';
import { ChartTypes } from '../enums/chart-types.enum';
import { HttpService } from '../../../ecaps-core/services/http.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChartingService {
  constructor(private http: HttpService) {}

  getUnitChart(
    chartType: ChartTypes,
    productType: ProductTypes,
    selectionKey: string,
    sizeID: string
  ): Promise<Object> {
    return new Promise<Object>((results, reject) => {
      let ccProductType = new CamelCasePipe().transform(productType.trim());

      if (productType === ProductTypes.temperedAirProduct) {
        ccProductType += 'Fan';
      } else if (
        productType === ProductTypes.makeUpAir ||
        productType === ProductTypes.preconditioners
      ) {
        ccProductType = ProductTypes.temperedAirProduct + 'Fan';
      }

      firstValueFrom(
        this.http.get(
          `/charting/${ccProductType}Selections/${selectionKey}/sizes/${sizeID}/${chartType}`
        )
      ).then(
        (resultData: any) => {
          let output: Object;

          if (!!resultData.grid) {
            output = new Chart(resultData);
          } else {
            output = {};

            for (const key of Object.keys(resultData)) {
              if (!!resultData[key]) {
                output[key] = new Chart(resultData[key]);
              } else {
                output[key] = null;
              }
            }
          }

          results(output);
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }

  getUnitCharts(
    productType: ProductTypes,
    selectionKey: string,
    sizeID: string
  ): Promise<Object> {
    const output = {};

    const chartList = [];

    switch (productType) {
      case ProductTypes.fan: {
        chartList.push(ChartTypes.operating);
        chartList.push(ChartTypes.minMax);
        chartList.push(ChartTypes.series);

        break;
      }

      case ProductTypes.circulator:
      case ProductTypes.temperedAirProduct: {
        chartList.push(ChartTypes.operating);

        break;
      }

      case ProductTypes.makeUpAir:
      case ProductTypes.preconditioners: {
        chartList.push(ChartTypes.operating);

        break;
      }
    }

    return Promise.all(
      chartList.map(async (chartType) => {
        output[chartType] = await this.getUnitChart(
          chartType,
          productType,
          selectionKey,
          sizeID
        );
      })
    ).then(() => output);
  }

  getCompareCharts(
    productType: string,
    selectionKey: string,
    sizeIDList: Array<string>,
    chartType: string
  ): Promise<Chart> {
    return new Promise<Chart>((results, reject) => {
      const ccProductType = new CamelCasePipe().transform(productType);

      firstValueFrom(
        this.http.post(
          `/charting/${ccProductType}Selections/${selectionKey}/comparison/${chartType}`,
          {
            sizes: sizeIDList,
          }
        )
      ).then(
        (resultData) => {
          results(new Chart(resultData));
        },
        (errorData) => {
          reject(errorData);
        }
      );
    });
  }
}
