import { ValidSize } from '../../../models/selection-results/valid-size.model';

export class ErvCompareDialogData {

    exhaustVolume: number;
    size: ValidSize;

    constructor(exhaustVolume: number, size: ValidSize) {
        this.exhaustVolume = exhaustVolume;

        this.size = size;
    }

}
