import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noop-page',
  template: ''
})
export class NoopPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
