export const environment = {
  production: true,
  version: '3.8.1',
  versionDate: 'July 2024',
  copyrightBase: '2015',
  environment: 'production',
  drawingContentBase: 'https://s3.amazonaws.com/greenheck.drawings/',
  serviceRoot: '/api/',
  defaultApiKey: 'uzZIb8O9oY5Ytgon',
  greenheckUrls: {
    root: 'https://www.greenheck.com',
    register: 'https://www.greenheck.com/account/register',
    forgotPassword: 'https://www.greenheck.com/account/forgot',
    repOfficeInfos: 'https://www.greenheck.com/api/all-services/officeinfos',
    repOfficeInfosByZipCode:
      'https://portal.greenheck.com/TerritoryService/territory/TerritorySearch/srchTtyZip',
  },
  serviceEnvironments: {
    dev: '/api/',
    qa: '/api/',
    prod: '/api/',
  },
  ga4: 'G-QT814W9ZQN',
  gtmId: 'GTM-KJ9Z95NZ',
  localStorageVersion:
    '1.0' /* WARNING: Changing this will clear users local storage */,
  lachesis: {
    url: '/lachesis/api',
    authPath: '/lachesis/auth',
    refreshPath: '/lachesis/auth/refresh',
    clientID: 'sIArRD7CY1yZjHGH4lEA',
    clientSecret:
      '43gGIzO7KSHAKrt2WWFOUHbBbeo7bmEwySKeLlDZRKbKrtERByonQMU5ems0F5gy',
  },
  projectItemLimit: 24,
  maxProjectSize: 16777216,
  maxProjectSizeTolerance: 80,
  email: {
    url: '/email',
    defaultEmail: 'ecaps@greenheck.com',
  },
  revit: {
    versions: [
      {
        startDate: new Date('2020-01-01T05:00:00Z'),
        version: '1.0.0',
      },
      {
        startDate: new Date('2020-11-03T05:00:00Z'),
        version: '2.0.0',
      },
      {
        startDate: new Date('2020-11-09T05:00:00Z'),
        version: '2.0.1',
      },
      {
        startDate: new Date('2021-02-15T05:00:00Z'),
        version: '2.1.0',
      },
      {
        startDate: new Date('2021-09-07T05:00:00Z'),
        version: '2.2.7',
      },
      {
        startDate: new Date('2022-03-14T05:00:00Z'),
        version: '2.3.11',
      },
      {
        startDate: new Date('2022-07-11T11:00:00Z'),
        version: '2.3.24',
      },
      {
        startDate: new Date('2023-07-25T11:00:00Z'),
        version: '2.4.30',
        message: 'Updated addin version is available with Revit 2024 support',
      },
      {
        startDate: new Date('2023-08-07T11:00:00Z'),
        version: '2.4.32',
        message:
          'Updated addin hotfix version is available with Revit 2024 support',
      },
      {
        startDate: new Date('2023-10-23T11:00:00Z'),
        version: '2.5.36',
        message: 'Updated addin version 2.5 is available',
      },
    ],
  },
};
