import { BusinessUnits } from '../enums/business-units.enum';
import { ProductEntities } from '../enums/product-entities.enum';
import { ProductTypes } from '../enums/product-types.enum';

export interface IInfoLink {
  url: string;
  sameWindow?: boolean;
}

export type FlagPositions =
  | 'Top Left'
  | 'Top Center'
  | 'Top Right'
  | 'Left Center'
  | 'Right Center'
  | 'Bottom Left'
  | 'Bottom Center'
  | 'Bottom Right';

export type FlagParent = 'Card' | 'Image';

export interface IFlag {
  text: string;
  class: string;
  position: FlagPositions;
  parent: FlagParent;
}

export interface IProduct {
  parent?: IProduct;
  name: string;
  text: string;
  description?: string[];
  visible: boolean;
  revitVisible?: boolean;
  image: string;
  disabled?: boolean;
  infoLink?: IInfoLink;
  flags?: IFlag[];
  schedulable?: boolean;
  toolboxItems?: IToolboxItemBase[];
  children?: (IProduct | IProductCategory)[];
  click?: () => void;
}

export interface IProductCategory extends IProduct {
  type: ProductTypes;
  trackingType?: ProductTypes;
  bu: BusinessUnits;
  entity: ProductEntities;
  customRoute?: string;
  hasElevation?: boolean;
  defaultAnswers: { [key: string]: any };
  selectionRequestData?: { [key: string]: any };
}

export interface IToolboxItemBase {
  name: string;
  text: string;
  visible: boolean;
  disabled: boolean;
  improved: boolean;
  revitVisible?: boolean;
  click: () => any;
}

export interface IToolboxItem extends IToolboxItemBase {
  icon: string;
}

export interface IToolboxItemSvg extends IToolboxItemBase {
  svgIcon: string;
}

export const productGroups: IProduct[] = [
  {
    name: 'Fans',
    text: 'Fans',
    visible: true,
    revitVisible: true,
    disabled: false,
    image: 'fans.png',
    schedulable: true,
    children: [
      {
        name: 'Circulator',
        text: 'Overhead HVLS Fans',
        image: 'hvls.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.circulator,
        bu: BusinessUnits.fans,
        entity: ProductEntities.circulatorSelector,
        defaultAnswers: {},
        children: [
          {
            name: 'performance',
            image: 'hvls_performance.png',
            text: 'Performance-Based Selection',
            description: [
              `For HVLS selections where project-specific performance information is desired.
                Allows for easy optimization of fan sizes and quantities based on the dimensions of the space,
                 air rotation and reputable industry standards.`,

              `Note: Air rotation rate is the rate at which air is circulated throughout a space.
                It is common to see anywhere from 2-5 minutes per air rotation.
                Higher values result in more energy efficient selections and lower values result in more cost effective solutions.`,
            ],
            defaultAnswers: {
              SelectionMethod: 'ByPerformance',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            type: ProductTypes.circulator,
            bu: BusinessUnits.fans,
            entity: ProductEntities.circulatorSelector,
          },
          {
            name: 'size',
            image: 'hvls_size.png',
            text: 'Size-Based Selection',
            description: [
              `For quick HVLS selections where fan diameter is the primary selection criteria.
                        Allows for easy comparison of fan sizes without the need for specific project information.`,
            ],
            defaultAnswers: {
              SelectionMethod: 'ByFanSize',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            type: ProductTypes.circulator,
            bu: BusinessUnits.fans,
            entity: ProductEntities.circulatorSelector,
          },
        ],
      },
      {
        name: 'DestratFans',
        text: 'Directional Destratification Fans',
        image: 'ddf.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.circulator,
        bu: BusinessUnits.fans,
        entity: ProductEntities.circulatorsDDF,
        defaultAnswers: {},
        children: [
          {
            name: 'performance',
            image: 'ddf_performance.png',
            text: 'Performance-Based Selection',
            description: [
              `For HVLS selections where project-specific performance information is desired.
                Allows for easy optimization of fan sizes and quantities based on the dimensions of the space,
                 air rotation and reputable industry standards.`,

              `Note: Air rotation rate is the rate at which air is circulated throughout a space.
                It is common to see anywhere from 2-5 minutes per air rotation.
                Higher values result in more energy efficient selections and lower values result in more cost effective solutions.`,
            ],
            defaultAnswers: {
              SelectionMethod: 'ByPerformance',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            type: ProductTypes.circulator,
            bu: BusinessUnits.fans,
            entity: ProductEntities.circulatorsDDF,
          },
          {
            name: 'size',
            image: 'ddf_size.png',
            text: 'Size-Based Selection',
            description: [
              `For quick HVLS selections where fan diameter is the primary selection criteria.
                        Allows for easy comparison of fan sizes without the need for specific project information.`,
            ],
            defaultAnswers: {
              SelectionMethod: 'ByFanSize',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            type: ProductTypes.circulator,
            bu: BusinessUnits.fans,
            entity: ProductEntities.circulatorsDDF,
          },
        ],
      },
      {
        name: 'RoofExhaust',
        text: 'Roof Exhaust',
        image: 'roofexhaust.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.fans,
        hasElevation: true,
        defaultAnswers: {
          ModelGroup: 'RoofExhaust',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
      },
      {
        name: 'RoofSupply',
        text: 'Roof Supply',
        image: 'roofsupply.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.roofSupply,
        hasElevation: true,
        defaultAnswers: {
          ModelGroup: 'RoofSupply',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
      },
      {
        name: 'SidewallMounted',
        text: 'Wall Mounted',
        image: 'wallmounted.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.sidewallMounted,
        hasElevation: true,
        defaultAnswers: {
          ModelGroup: 'SidewallMounted',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
      },
      {
        name: 'CeilingAndCabinet',
        text: 'Ceiling',
        image: 'ceilingcabinet.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.ceilingAndCabinet,
        hasElevation: true,
        defaultAnswers: {
          ModelGroup: 'CeilingAndCabinet',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
      },
      {
        name: 'InlineFans',
        text: 'Inline Fans',
        image: 'inlinefans.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.inline,
        hasElevation: true,
        defaultAnswers: {
          ModelGroup: 'InlineFans',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
      },
      {
        name: 'ScrolledBlower',
        text: 'Blowers',
        image: 'blowers.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.blower,
        hasElevation: true,
        defaultAnswers: {
          ModelGroup: 'ScrolledBlower',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
      },
      {
        name: 'Gravity',
        text: 'Gravity',
        image: 'gravity.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.gravity,
        bu: BusinessUnits.fans,
        entity: ProductEntities.gravity,
        defaultAnswers: {
          ModelGroup: 'Gravity',
        },
      },
      {
        name: 'PlugAndPlenum',
        text: 'Plenums',
        image: 'plenums.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.plugAndPlenum,
        hasElevation: true,
        defaultAnswers: {
          ModelGroup: 'PlugAndPlenum',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
      },
      {
        name: 'FumeExhaust',
        text: 'Fume Exhaust',
        image: 'fumeexhaust.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        customRoute: '/graphicalselection',
        type: ProductTypes.fan,
        bu: BusinessUnits.fans,
        entity: ProductEntities.fumeExhaust,
        defaultAnswers: {
          ModelGroup: 'FumeExhaust',
        },
        selectionRequestData: {
          costPerKilowattHour: 0.12,
          numberOfYears: 5,
          operatingDaysPerWeek: 5,
          operatingHoursPerDay: 12,
          modelCatalogName: 'ecaps',
        },
        children: [
          {
            name: 'laboratory',
            image: 'fumeexhaust/laboratory.jpg',
            text: 'Laboratory/Pharmacy',
            infoLink: { url: '/assets/docs/laboratory.pdf' },
            description: [
              `Applications include biological labs, chemical labs, physical material labs,
                                clean rooms, and laboratory support spaces within schools, universities,
                                research facilities, manufacturing facilities, hospitals or government
                                facilities. Equipment to meet ANSI/ASHRAE Z9.5 and NFPA 45.`,
            ],
            defaultAnswers: {
              FumeExhaustApplication: 'Laboratory',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            customRoute: '/graphicalselection',
            type: ProductTypes.fan,
            bu: BusinessUnits.fans,
            entity: ProductEntities.fumeExhaust,
          },
          {
            name: 'wastewater',
            image: 'fumeexhaust/wastewater_treatment.jpg',
            text: 'Wastewater Treatment',
            description: [
              `Exhaust systems designed to dilute and expel odorous and chemical laden air
                                in municipal or industrial wastewater facilities.`,
            ],
            defaultAnswers: {
              FumeExhaustApplication: 'Wastewater',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            customRoute: '/graphicalselection',
            type: ProductTypes.fan,
            bu: BusinessUnits.fans,
            entity: ProductEntities.fumeExhaust,
          },
          {
            name: 'diesel',
            image: 'fumeexhaust/diesel_generator.jpg',
            text: 'Diesel Generator',
            description: [
              `Exhaust systems that dilute and cool diesel exhaust fumes to avoid re-entry
                                back into the facility without excessively tall flue stacks. Suitable for
                                hospitals, schools, data centers, and other facilities where emergency
                                generators are required to ensure continuous operation of the facility.`,
            ],
            defaultAnswers: {
              FumeExhaustApplication: 'DieselGenerator',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            customRoute: '/graphicalselection',
            type: ProductTypes.fan,
            bu: BusinessUnits.fans,
            entity: ProductEntities.fumeExhaust,
          },
          {
            name: 'isolation',
            image: 'fumeexhaust/isolation.jpg',
            text: 'Isolation Room',
            description: [
              `Exhaust systems for safely expelling airborne infectious diseases. Installations
                                include in-patient isolation rooms in hospitals and other medical facilities.`,
            ],
            defaultAnswers: {
              FumeExhaustApplication: 'IsolationRoom',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            customRoute: '/graphicalselection',
            type: ProductTypes.fan,
            bu: BusinessUnits.fans,
            entity: ProductEntities.fumeExhaust,
          },
          {
            name: 'manufacturing',
            image: 'fumeexhaust/industrial_zone.jpg',
            text: 'Manufacturing Process',
            description: [
              `Applications include weld exhaust, high temperature exhaust, and other
                                manufacturing processes that create fumes and odors where re-entrainment is a
                                concern.`,
            ],
            defaultAnswers: {
              FumeExhaustApplication: 'ManufacturingProcess',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            customRoute: '/graphicalselection',
            type: ProductTypes.fan,
            bu: BusinessUnits.fans,
            entity: ProductEntities.fumeExhaust,
          },
          {
            name: 'ammonia',
            image: 'fumeexhaust/ammonia.jpg',
            text: 'Ammonia Refrigeration',
            infoLink: { url: '/assets/docs/ammonia_refrigeration.pdf' },
            description: [
              `Applications include food or beverage processing, manufacturing and storage
                                facilities. Exhaust equipment is designed for general and emergency exhaust due
                                to ammonia leaks. Exhaust systems are designed to meet the guidelines of IIAR 2-2014.`,
            ],
            defaultAnswers: {
              FumeExhaustApplication: 'AmmoniaRefrigeration',
            },
            visible: true,
            revitVisible: true,
            disabled: false,
            customRoute: '/graphicalselection',
            type: ProductTypes.fan,
            bu: BusinessUnits.fans,
            entity: ProductEntities.fumeExhaust,
          },
        ],
      },
    ],
  },
  {
    name: 'Louvers',
    text: 'Louvers',
    visible: true,
    revitVisible: true,
    disabled: false,
    image: 'louvers.png',
    schedulable: true,
    children: [
      {
        name: 'Stationary',
        text: 'Conventional Stationary',
        image: 'louvers_stationary.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.louver,
        bu: BusinessUnits.louvers,
        entity: ProductEntities.louver,
        defaultAnswers: {
          ModelGroup: 'Stationary',
        },
      },
      {
        name: 'Operable',
        text: 'Operable',
        image: 'louvers_operable.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.louver,
        bu: BusinessUnits.louvers,
        entity: ProductEntities.louver,
        defaultAnswers: {
          ModelGroup: 'Operable',
        },
      },
      {
        name: 'WindDrivenRain',
        text: 'Wind Driven Rain',
        image: 'louvers_winddrivenrain.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.louver,
        bu: BusinessUnits.louvers,
        entity: ProductEntities.louver,
        defaultAnswers: {
          ModelGroup: 'WindDrivenRain',
        },
      },
      {
        name: 'Hurricane',
        text: 'Hurricane',
        image: 'louvers_hurricane.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.louver,
        bu: BusinessUnits.louvers,
        entity: ProductEntities.louver,
        defaultAnswers: {
          ModelGroup: 'Hurricane',
        },
      },
      {
        name: 'Specialty',
        text: 'Specialty',
        image: 'louvers_specialty.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.louver,
        bu: BusinessUnits.louvers,
        entity: ProductEntities.louver,
        defaultAnswers: {
          ModelGroup: 'Specialty',
          Material: 'Aluminum',
        },
      },
      {
        name: 'Penthouse',
        text: 'Penthouse',
        image: 'louvers_penthouse.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.louver,
        trackingType: ProductTypes.gravity,
        bu: BusinessUnits.fans,
        entity: ProductEntities.gravity,
        defaultAnswers: {
          ModelGroup: 'Gravity',
          HousingStyle: 'Louvered',
        },
      },
    ],
  },
  {
    name: 'Outdoor-Air',
    text: 'Outdoor-Air',
    visible: true,
    revitVisible: true,
    image: 'doas.png',
    schedulable: true,
    disabled: false,
    children: [
      {
        name: 'RV',
        text: 'Outdoor Air Units',
        image: 'rv.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.temperedAirProduct,
        bu: BusinessUnits.temperedAirProduct,
        entity: ProductEntities.temperedAirProduct,
        customRoute: '/graphicalselection',
        defaultAnswers: {
          HasEnergyRecovery: 'No',
        },
        selectionRequestData: {
          maximumReturnedValidSizes: 3,
        },
      },
      {
        name: 'RVE',
        text: 'Outdoor Air Units With Energy Recovery',
        image: 'rve.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.temperedAirProduct,
        bu: BusinessUnits.temperedAirProduct,
        entity: ProductEntities.temperedAirProduct,
        customRoute: '/graphicalselection',
        defaultAnswers: {
          HasEnergyRecovery: 'Yes',
        },
        selectionRequestData: {
          maximumReturnedValidSizes: 3,
        },
      },
    ],
  },
  {
    name: 'MakeUpAir',
    text: 'Make-up Air',
    visible: true,
    revitVisible: false,
    image: 'makeupair.png',
    schedulable: true,
    disabled: false,
    children: [
      {
        name: 'DirectGas',
        text: 'Direct Gas Heat',
        image: 'mua/direct_gas.png',
        visible: true,
        disabled: false,
        type: ProductTypes.makeUpAir,
        bu: BusinessUnits.makeUpAir,
        entity: ProductEntities.makeUpAir,
        defaultAnswers: {
          HeatingType: 'DirectGas',
        },
        selectionRequestData: {},
      },
      {
        name: 'IndirectGas',
        text: 'Indirect Gas Heat',
        image: 'mua/indirect_gas.png',
        visible: true,
        disabled: false,
        type: ProductTypes.makeUpAir,
        bu: BusinessUnits.makeUpAir,
        entity: ProductEntities.makeUpAir,
        defaultAnswers: {
          HeatingType: 'IndirectGas',
        },
        selectionRequestData: {},
      },
      {
        name: 'None',
        text: 'No Heat',
        image: 'mua/no_heat.png',
        infoLink: { url: '/group/roofsupply', sameWindow: true },
        description: [
          `Other "No Heat" or "Non Tempered" fans are available in the Fans section under Roof Supply.
          The new model KSQ (available under Fans > Roof Supply) has replaced the obsoleted models KSFD,
          KSFB, and MSF (were available under Make-Up Air > No Heat). Click icon to be directed to the Roof Supply Fans.`,
        ],
        visible: true,
        disabled: false,
        type: ProductTypes.makeUpAir,
        bu: BusinessUnits.makeUpAir,
        entity: ProductEntities.makeUpAir,
        defaultAnswers: {
          HeatingType: 'None',
        },
        selectionRequestData: {},
      },
      {
        name: 'Electric',
        text: 'Electric Heat',
        image: 'mua/electric.png',
        visible: true,
        disabled: false,
        type: ProductTypes.makeUpAir,
        bu: BusinessUnits.makeUpAir,
        entity: ProductEntities.makeUpAir,
        defaultAnswers: {
          HeatingType: 'Electric',
        },
        selectionRequestData: {},
      },
      {
        name: 'HotWater',
        text: 'Hot Water Heat',
        image: 'mua/hot_water.png',
        visible: true,
        disabled: false,
        type: ProductTypes.makeUpAir,
        bu: BusinessUnits.makeUpAir,
        entity: ProductEntities.makeUpAir,
        defaultAnswers: {
          HeatingType: 'HotWater',
        },
        selectionRequestData: {},
      },
      {
        name: 'Steam',
        text: 'Steam Heat',
        image: 'mua/steam.png',
        visible: true,
        disabled: false,
        type: ProductTypes.makeUpAir,
        bu: BusinessUnits.makeUpAir,
        entity: ProductEntities.makeUpAir,
        defaultAnswers: {
          HeatingType: 'Steam',
        },
        selectionRequestData: {},
      },
    ],
  },
  {
    name: 'Preconditioners',
    text: 'Energy Recovery Ventilators',
    visible: true,
    revitVisible: true,
    image: 'preconditioners.png',
    schedulable: true,
    disabled: false,
    children: [
      {
        name: 'Indoor',
        text: 'Indoor',
        image: 'preconditioners/erv_indoor.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.preconditioners,
        bu: BusinessUnits.preconditioners,
        entity: ProductEntities.preconditioners,
        defaultAnswers: {
          UnitMounting: 'Indoor',
        },
        selectionRequestData: {},
      },
      {
        name: 'Outdoor',
        text: 'Outdoor',
        image: 'preconditioners/erv_outdoor.png',
        visible: true,
        revitVisible: true,
        disabled: false,
        type: ProductTypes.preconditioners,
        bu: BusinessUnits.preconditioners,
        entity: ProductEntities.preconditioners,
        defaultAnswers: {
          UnitMounting: 'Outdoor',
        },
        selectionRequestData: {},
      },
    ],
  },
  {
    name: 'ATU',
    text: 'Air Terminal Units',
    visible: true,
    revitVisible: false,
    image: 'atu.png',
    disabled: false,
    flags: [
      {
        text: 'Excludes Canada',
        class: 'not-canada',
        position: 'Bottom Right',
        parent: 'Card',
      },
    ],
  },
  {
    name: 'Dampers',
    text: 'Dampers',
    visible: true,
    revitVisible: false,
    image: 'dampers2.png',
    disabled: false,
  },
  {
    name: 'GRD',
    text: 'Grilles, Registers and Diffusers',
    visible: true,
    revitVisible: false,
    image: 'grd.png',
    disabled: false,
    flags: [
      {
        text: 'Excludes Canada',
        class: 'not-canada',
        position: 'Bottom Right',
        parent: 'Card',
      },
    ],
  },
  {
    name: 'HLCP',
    text: 'Healthcare, Laboratory & Cleanroom Products',
    visible: true,
    revitVisible: false,
    image: 'hlc.png',
    disabled: false,
    flags: [
      {
        text: 'Excludes Canada',
        class: 'not-canada',
        position: 'Bottom Right',
        parent: 'Card',
      },
    ],
  },
];
