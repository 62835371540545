export class GeoBounds {
    south: number;
    west: number;
    north: number;
    east: number;
    constructor(data: any) {
        if (Object.keys(data).length > 0) {
            this.south = data[Object.keys(data)[0]].j;
            this.west = data[Object.keys(data)[1]].j;
            this.north = data[Object.keys(data)[0]].l;
            this.east = data[Object.keys(data)[1]].l;
        }
    }
}
