import { IModelItem } from './i-model-item.interface';

export const ModelList: IModelItem[] = [
  {
    allowInsert: true,
    file: 'AE_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Propeller Roof Direct Drive Exhaust Fan ',
    image: 'AE.png',
    model: 'AE',
    modelGroup: 'RoofExhaust',
    name: 'AE',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'AFA-801_LOD200.zip',
    category: 'Specialty',
    description: '8 in. Acoustical, Airfoil Blade',
    image: 'AFA-801.png',
    model: 'AFA-801',
    modelGroup: 'Specialty',
    name: 'AFA-801',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'AFDW_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Airfoil Double Width Blower',
    image: 'AFDW.png',
    model: 'AFDW',
    modelGroup: 'ScrolledBlower',
    name: 'AFDW',
    productType: 'Fans',
    subtitle: 'Blower (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'AFJ-120_LOD200.zip',
    category: 'Specialty',
    description: '12 in. Acoustical, J Blade',
    image: 'AFJ-120.png',
    model: 'AFJ-120',
    modelGroup: 'Specialty',
    name: 'AFJ-120',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'AFJ-601_LOD200.zip',
    category: 'Specialty',
    description: '6 in. Acoustical, J Blade',
    image: 'AFJ-601.png',
    model: 'AFJ-601',
    modelGroup: 'Specialty',
    name: 'AFJ-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'AFJ-801_LOD200.zip',
    category: 'Specialty',
    description: '8 in. Acoustical, J Blade',
    image: 'AFJ-801.png',
    model: 'AFJ-801',
    modelGroup: 'Specialty',
    name: 'AFJ-801',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'AFL-501_LOD200.zip',
    category: 'Specialty',
    description: '5 in. FEMA 361 Tornado, Aluminum',
    image: 'AFL-501.png',
    model: 'AFL-501',
    modelGroup: 'Specialty',
    name: 'AFL-501',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'AFL-601_LOD200.zip',
    category: 'Specialty',
    description: '6 in. FEMA 361 Tornado, Aluminum',
    image: 'AFL-601.png',
    model: 'AFL-601',
    modelGroup: 'Specialty',
    name: 'AFL-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'AFS-120_LOD200.zip',
    category: 'Specialty',
    description: '12 in. Acoustical, Sightproof',
    image: 'AFS-120.png',
    model: 'AFS-120',
    modelGroup: 'Specialty',
    name: 'AFS-120',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'APD_LOD200_AllSizes.zip',
    category: 'Plenum',
    description: 'Plenum fan, coated steel steel, bolted construction.',
    image: 'APD.png',
    model: 'APD',
    modelGroup: 'PlugAndPlenum',
    name: 'APD',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'APH_LOD200_AllSizes.zip',
    category: 'Plenum',
    description: 'Plenum fan, welded and painted steel construction.',
    image: 'APH.png',
    model: 'APH',
    modelGroup: 'PlugAndPlenum',
    name: 'APH',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'APM_LOD200_AllSizes.zip',
    category: 'Plenum',
    description: 'Plenum fan, galvanized steel, bolted construction.',
    image: 'APM.png',
    model: 'APM',
    modelGroup: 'PlugAndPlenum',
    name: 'APM',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'AS_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Propeller Direct Drive Roof Supply Fan',
    image: 'AS.png',
    model: 'AS',
    modelGroup: 'RoofSupply',
    name: 'AS',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'ASSURE-120_LOD200.zip',
    category: 'ResidentialEnergyRecovery',
    description: 'Residential Energy Recovery Ventilator',
    image: 'ASSURE-120.png',
    model: 'ASSURE-120',
    modelGroup: 'ResidentialEnergyRecovery',
    name: 'ASSURE-120',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'AX_DB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Tubular Axial Roof Supply Fan',
    image: 'AX-DOWNBLAST.png',
    model: 'AX',
    modelGroup: 'RoofSupply',
    name: 'AX',
    productType: 'Fans',
    subtitle: 'Downblast, Supply',
  },
  {
    allowInsert: true,
    file: 'AX-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Tube Axial Direct Drive Inline Fan',
    image: 'AX-INLINE.png',
    model: 'AX',
    modelGroup: 'InlineFans',
    name: 'AX',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'AX-Vert_LOD200_v01_AllSizes.zip',
    category: 'Inline',
    description: 'Tube Axial Direct Drive Inline Fan',
    image: 'AX-Inline-Vertical.png',
    model: 'AX',
    modelGroup: 'InlineFans',
    name: 'AX',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'AX_UB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tube Axial Upblast Roof Direct Drive Exhaust Fan',
    image: 'AX-UPBLAST.png',
    model: 'AX',
    modelGroup: 'RoofExhaust',
    name: 'AX',
    productType: 'Fans',
    subtitle: 'Upblast, Exhaust',
  },
  {
    allowInsert: true,
    file: 'BCF_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Belt Drive Cabinet Fan',
    image: 'BCF.png',
    model: 'BCF',
    modelGroup: 'InlineFans',
    name: 'BCF',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'BCSW-FRP_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Fiberglass Backward Curved Single Width Blower',
    image: 'BCSW-FRP.png',
    model: 'BCSW-FRP',
    modelGroup: 'ScrolledBlower',
    name: 'BCSW-FRP',
    productType: 'Fans',
    subtitle: 'Blower',
  },
  {
    allowInsert: true,
    file: 'BDF_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Belt Drive Duct Fan',
    image: 'BDF.png',
    model: 'BDF',
    modelGroup: 'InlineFans',
    name: 'BDF',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'BIDW_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Backward Inclined Double Width Blower',
    image: 'BIDW.png',
    model: 'BIDW',
    modelGroup: 'ScrolledBlower',
    name: 'BIDW',
    productType: 'Fans',
    subtitle: 'Blower (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'BSQ_LOD200_v01_AllSizes.zip',
    category: 'Inline',
    description: 'Belt Drive Centrifugal Inline Fan',
    image: 'BSQ.png',
    model: 'BSQ',
    modelGroup: 'InlineFans',
    name: 'BSQ',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'CSP-A_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Cabinet Fan',
    image: 'CSP-A.png',
    model: 'CSP',
    modelGroup: 'Ceiling',
    name: 'CSP-A',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'CSP-B_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Cabinet Fan',
    image: 'CSP-B.png',
    model: 'CSP',
    modelGroup: 'Ceiling',
    name: 'CSP-B',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'CUBE_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Belt Drive Upblast Centrifugal Roof Exhaust Fan',
    image: 'CUBE.png',
    model: 'CUBE',
    modelGroup: 'RoofExhaust',
    name: 'CUBE',
    productType: 'Fans',
    subtitle: 'Roof Mounted',
  },
  {
    allowInsert: true,
    file: 'CUBE-Wall_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Centrifugal Belt Drive Wall Exhaust Fan',
    image: 'CUBE-wall.png',
    model: 'CUBE',
    modelGroup: 'SidewallMounted',
    name: 'CUBE',
    productType: 'Fans',
    subtitle: 'Wall Mounted',
  },
  {
    allowInsert: true,
    file: 'CUE_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Direct Drive Upblast Centrifugal Roof Exhaust Fan',
    image: 'CUE.png',
    model: 'CUE',
    modelGroup: 'RoofExhaust',
    name: 'CUE',
    productType: 'Fans',
    subtitle: 'Roof Mounted',
  },
  {
    allowInsert: true,
    file: 'CUE-Wall_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Centrifugal Direct Drive Wall Exhaust Fan',
    image: 'CUE-wall.png',
    model: 'CUE',
    modelGroup: 'SidewallMounted',
    name: 'CUE',
    productType: 'Fans',
    subtitle: 'Wall Mounted',
  },
  {
    allowInsert: true,
    file: 'DC-5_LOD200_AllSizes.zip',
    category: 'Circulator',
    description: 'High Volume, Low Speed Ceiling Fan',
    image: 'DC-5.png',
    model: 'DC',
    modelGroup: 'Circulator',
    name: 'DC-5',
    productType: 'HVLS',
  },
  {
    allowInsert: true,
    file: 'DDF_LOD200_AllSizes.zip',
    category: 'DestratFans',
    description: 'Directional Destratification Fan',
    image: 'DDF.png',
    model: 'DDF',
    modelGroup: 'DestratFans',
    name: 'DDF',
    productType: 'HVLS',
  },
  {
    allowInsert: true,
    file: 'DM-3_LOD200_AllSizes.zip',
    category: 'Circulator',
    description: 'High Volume, Low Speed Ceiling Fan',
    image: 'DM3.png',
    model: 'DM',
    modelGroup: 'Circulator',
    name: 'DM-3',
    productType: 'HVLS',
  },
  {
    allowInsert: true,
    file: 'DS-3_LOD200_AllSizes.zip',
    category: 'Circulator',
    description: 'High Volume, Low Speed Ceiling Fan',
    image: 'DS-3.png',
    model: 'DS',
    modelGroup: 'Circulator',
    name: 'DS-3',
    productType: 'HVLS',
  },
  {
    allowInsert: true,
    file: 'DS-6_LOD200_AllSizes.zip',
    category: 'Circulator',
    description: 'High Volume, Low Speed Ceiling Fan',
    image: 'DS.png',
    model: 'DS',
    modelGroup: 'Circulator',
    name: 'DS-6',
    productType: 'HVLS',
  },
  {
    allowInsert: true,
    file: 'EAC-401_LOD200.zip',
    category: 'Operable',
    description: '4 in. Combo Louver/Damper, Drainable Blade',
    image: 'EAC-401.png',
    model: 'EAC-401',
    modelGroup: 'Operable',
    name: 'EAC-401',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EAC-601_LOD200.zip',
    category: 'Operable',
    description: '6 in. Combo Louver/Damper, Drainable Blade',
    image: 'EAC-601.png',
    model: 'EAC-601',
    modelGroup: 'Operable',
    name: 'EAC-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EACA-601_LOD200.zip',
    category: 'Operable',
    description: '6 in. Combo Louver/Damper, Drainable Blade, Airfoil Damper',
    image: 'EACA-601.png',
    model: 'EACA-601',
    modelGroup: 'Operable',
    name: 'EACA-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EACA-601D_LOD200.zip',
    category: 'Hurricane',
    description:
      '6 in. Combo Louver/Damper, Drainable Airfoil Damper, Hurricane Rated',
    image: 'EACA-601D.png',
    model: 'EACA-601D',
    modelGroup: 'Hurricane',
    name: 'EACA-601D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EACC-401_LOD200.zip',
    category: 'Operable',
    description: '4in. Combo Louver/Damper, Drainable',
    image: 'EACC-401.png',
    model: 'EACC-401',
    modelGroup: 'Operable',
    name: 'EACC-401',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EACC-601_LOD200.zip',
    category: 'Operable',
    description: '6in. Combo Louver/Damper, Drainable',
    image: 'EACC-601.png',
    model: 'EACC-601',
    modelGroup: 'Operable',
    name: 'EACC-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EAD-401_LOD200.zip',
    category: 'Operable',
    description: '4 in. Adjustable, Drainable Blade',
    image: 'EAD-401.png',
    model: 'EAD-401',
    modelGroup: 'Operable',
    name: 'EAD-401',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EAD-601_LOD200.zip',
    category: 'Operable',
    description: '6 in. Adjustable, Drainable Blade',
    image: 'EAD-601.png',
    model: 'EAD-601',
    modelGroup: 'Operable',
    name: 'EAD-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EAD-632W_LOD200.zip',
    category: 'Operable',
    description: '6 in. Adjustable, Drainable Blade',
    image: 'EAD-632W.png',
    model: 'EAD-632W',
    modelGroup: 'Operable',
    name: 'EAD-632W',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EAD-635_LOD200.zip',
    category: 'Operable',
    description: '6 in. Adjustable, Drainable Blade',
    image: 'EAD-635.png',
    model: 'EAD-635',
    modelGroup: 'Operable',
    name: 'EAD-635',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EAH-401_LOD200.zip',
    category: 'Operable',
    description: '4 in. Adjustable, J Blade',
    image: 'EAH-401.png',
    model: 'EAH-401',
    modelGroup: 'Operable',
    name: 'EAH-401',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EAH-690_LOD200.zip',
    category: 'Operable',
    description: '6 in. Adjustable, 90 Deg J Blade',
    image: 'EAH-690.png',
    model: 'EAH-690',
    modelGroup: 'Operable',
    name: 'EAH-690',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ECD-401_LOD200.zip',
    category: 'Operable',
    description: '4 in. Combo Louver/Damper, Drainable Blade',
    image: 'ECD-401.png',
    model: 'ECD-401',
    modelGroup: 'Operable',
    name: 'ECD-401',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ECD-601_LOD200.zip',
    category: 'Operable',
    description: '6 in. Combo Louver/Damper, Drainable Blade',
    image: 'ECD-601.png',
    model: 'ECD-601',
    modelGroup: 'Operable',
    name: 'ECD-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ECV_LOD200_AllSizes.zip',
    category: 'EnergyRecoveryVentilators',
    description: 'Energy Recovery Core',
    image: 'ECV.png',
    model: 'ECV',
    modelGroup: 'EnergyRecoveryVentilators',
    name: 'ECV',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'EDD-401_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Dual-Drainable Blade',
    image: 'EDD-401.png',
    model: 'EDD-401',
    modelGroup: 'Stationary',
    name: 'EDD-401',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EDD-601_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Dual-Drainable Blade',
    image: 'EDD-601.png',
    model: 'EDD-601',
    modelGroup: 'Stationary',
    name: 'EDD-601',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EDJ-401_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Drainable Head, J Blade',
    image: 'EDJ-401.png',
    model: 'EDJ-401',
    modelGroup: 'Stationary',
    name: 'EDJ-401',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EDJ-601_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Drainable Head, J Blade',
    image: 'EDJ-601.png',
    model: 'EDJ-601',
    modelGroup: 'Stationary',
    name: 'EDJ-601',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EDK-402_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Drainable Head, K Blade',
    image: 'EDK-402.png',
    model: 'EDK-402',
    modelGroup: 'Stationary',
    name: 'EDK-402',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EDK-430_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Drainable Head, K Blade',
    image: 'EDK-430.png',
    model: 'EDK-430',
    modelGroup: 'Stationary',
    name: 'EDK-430',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EHH-201_LOD200.zip',
    category: 'Wind driven rain',
    description: '2 in. Drainable, Horizontal Rain Resistant',
    image: 'EHH-201.png',
    model: 'EHH-201',
    modelGroup: 'WindDrivenRain',
    name: 'EHH-201',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHH-401_LOD200.zip',
    category: 'Wind driven rain',
    description: '4 in. Horizontal Rain Resistant',
    image: 'EHH-401.png',
    model: 'EHH-401',
    modelGroup: 'WindDrivenRain',
    name: 'EHH-401',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHH-501_LOD200.zip',
    category: 'Wind driven rain',
    description: '5 in. Horizontal Rain Resistant',
    image: 'EHH-501.png',
    model: 'EHH-501',
    modelGroup: 'WindDrivenRain',
    name: 'EHH-501',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHH-501X_LOD200.zip',
    category: 'Hurricane',
    description: '5 in. Horizontal Rain Resistant, Hurricane Rated',
    image: 'EHH-501X.png',
    model: 'EHH-501X',
    modelGroup: 'Hurricane',
    name: 'EHH-501X',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EHH-601_LOD200.zip',
    category: 'Wind driven rain',
    description: '6 in. Horizontal Rain Resistant',
    image: 'EHH-601.png',
    model: 'EHH-601',
    modelGroup: 'WindDrivenRain',
    name: 'EHH-601',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHH-601D_LOD200.zip',
    category: 'Hurricane',
    description: '6 in. Horizontal Rain Resistant, Hurricane Rated',
    image: 'EHH-601D.png',
    model: 'EHH-601D',
    modelGroup: 'Hurricane',
    name: 'EHH-601D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHH-601DE_LOD200.zip',
    category: 'Hurricane',
    description: '6 in. Horizontal Rain Resistant, Hurricane Rated',
    image: 'EHH-601DE.png',
    model: 'EHH-601DE',
    modelGroup: 'Hurricane',
    name: 'EHH-601DE',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHH-601PD_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Gravity intake or exhaust louvered penthouse.',
    image: 'EHH601PD.png',
    model: 'EHH601PD',
    modelGroup: 'Gravity',
    name: 'EHH-601PD',
    productType: 'Penthouse',
  },
  {
    allowInsert: true,
    file: 'EHH-701_LOD200.zip',
    category: 'Wind driven rain',
    description: '7 in. Horizontal Rain Resistant',
    image: 'EHH-701.png',
    model: 'EHH-701',
    modelGroup: 'WindDrivenRain',
    name: 'EHH-701',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EHM-601_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Drainable Blade, Recessed Mullion',
    image: 'EHM-601.png',
    model: 'EHM-601',
    modelGroup: 'Stationary',
    name: 'EHM-601',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EHV-550_LOD200.zip',
    category: 'Hurricane',
    description: '5.5 in Horizontal Rain Resistant, Hurricane Rated',
    image: 'EHV-550.png',
    model: 'EHV-550',
    modelGroup: 'Hurricane',
    name: 'EHV-550',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHV-550D_LOD200.zip',
    category: 'Hurricane',
    description: ' 5.5 in Horizontal Rain Resistant, Hurricane Rated',
    image: 'EHV-550D.png',
    model: 'EHV-550D',
    modelGroup: 'Hurricane',
    name: 'EHV-550D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHV-901_LOD200.zip',
    category: 'Hurricane',
    description: '9 in. Horizontal Rain Resistant, Hurricane Rated',
    image: 'EHV-901.png',
    model: 'EHV-901',
    modelGroup: 'Hurricane',
    name: 'EHV-901',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EHV-901D_LOD200.zip',
    category: 'Hurricane',
    description: '9 in. Horizontal Rain Resistant, Hurricane Rated',
    image: 'EHV-901D.png',
    model: 'EHV-901D',
    modelGroup: 'Hurricane',
    name: 'EHV-901D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ERV_LOD200_AllSizes.zip',
    category: 'EnergyRecoveryVentilators',
    description: 'Energy Recovery Wheel',
    image: 'ERV.png',
    model: 'ERV',
    modelGroup: 'EnergyRecoveryVentilators',
    name: 'ERV',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'ERVe_LOD200_AllSizes.zip',
    category: 'EnergyRecoveryVentilators',
    description: 'Energy Recovery Wheel',
    image: 'ERVE.png',
    model: 'ERVe',
    modelGroup: 'EnergyRecoveryVentilators',
    name: 'ERVe',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'ESD-202_LOD200.zip',
    category: 'Stationary',
    description: '2 in. Drainable Blade',
    image: 'ESD-202.png',
    model: 'ESD-202',
    modelGroup: 'Stationary',
    name: 'ESD-202',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-403_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Drainable Blade',
    image: 'ESD-403.png',
    model: 'ESD-403',
    modelGroup: 'Stationary',
    name: 'ESD-403',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-435_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Drainable Blade',
    image: 'ESD-435.png',
    model: 'ESD-435',
    modelGroup: 'Stationary',
    name: 'ESD-435',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-435X_LOD200.zip',
    category: 'Hurricane',
    description: '4 in. Drainable Blade',
    image: 'ESD-435X.png',
    model: 'ESD-435X',
    modelGroup: 'Hurricane',
    name: 'ESD-435X',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'ESD-603_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Drainable Blade',
    image: 'ESD-603.png',
    model: 'ESD-603',
    modelGroup: 'Stationary',
    name: 'ESD-603',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-635_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Drainable Blade',
    image: 'ESD-635.png',
    model: 'ESD-635',
    modelGroup: 'Stationary',
    name: 'ESD-635',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-635D_LOD200.zip',
    category: 'Hurricane',
    description: '6 in. Drainable Blade, Hurricane Rated',
    image: 'ESD-635D.png',
    model: 'ESD-635D',
    modelGroup: 'Hurricane',
    name: 'ESD-635D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-635DE_LOD200.zip',
    category: 'Hurricane',
    description: '6 in. Drainable Blade, Hurricane Rated',
    image: 'ESD-635DE.png',
    model: 'ESD-635DE',
    modelGroup: 'Hurricane',
    name: 'ESD-635DE',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-635HP_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Drainable Blade',
    image: 'ESD-635HP.png',
    model: 'ESD-635HP',
    modelGroup: 'Stationary',
    name: 'ESD-635HP',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESD-635PD_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Gravity intake or exhaust louvered penthouse.',
    image: 'ESD635PD.png',
    model: 'ESD635PD',
    modelGroup: 'Gravity',
    name: 'ESD-635PD',
    productType: 'Penthouse',
  },
  {
    allowInsert: true,
    file: 'ESD-635X_LOD200.zip',
    category: 'Hurricane',
    description: '6 in. Drainable Blade, Hurricane Rated',
    image: 'ESD-635X.png',
    model: 'ESD-635X',
    modelGroup: 'Hurricane',
    name: 'ESD-635X',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'ESID-430_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Non-drainable',
    image: 'ESID-430.png',
    model: 'ESID-430',
    modelGroup: 'Stationary',
    name: 'ESID-430',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESJ-155_LOD200.zip',
    category: 'Specialty',
    description: '1.5 in. Thin-Line',
    image: 'ESJ-155.png',
    model: 'ESJ-155',
    modelGroup: 'Specialty',
    name: 'ESJ-155',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'ESJ-202_LOD200.zip',
    category: 'Stationary',
    description: '2 in. J Blade',
    image: 'ESJ-202.png',
    model: 'ESJ-202',
    modelGroup: 'Stationary',
    name: 'ESJ-202',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESJ-401_LOD200.zip',
    category: 'Stationary',
    description: '4 in. J Blade',
    image: 'ESJ-401.png',
    model: 'ESJ-401',
    modelGroup: 'Stationary',
    name: 'ESJ-401',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESJ-602_LOD200.zip',
    category: 'Stationary',
    description: '6 in. J Blade',
    image: 'ESJ-602.png',
    model: 'ESJ-602',
    modelGroup: 'Stationary',
    name: 'ESJ-602',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESK-402_LOD200.zip',
    category: 'Stationary',
    description: '4 in. K Blade',
    image: 'ESK-402.png',
    model: 'ESK-402',
    modelGroup: 'Stationary',
    name: 'ESK-402',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'ESS-502D_LOD200.zip',
    category: 'Hurricane',
    description: '5 in. Sightproof, Hurricane Rated',
    image: 'ESS-502D.png',
    model: 'ESS-502D',
    modelGroup: 'Hurricane',
    name: 'ESS-502D',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'ESU-153_LOD200.zip',
    category: 'Specialty',
    description: '1.5 in. Thin-Line',
    image: 'ESU-153.png',
    model: 'ESU-153',
    modelGroup: 'Specialty',
    name: 'ESU-153',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'ESU-153S_LOD200.zip',
    category: 'Specialty',
    description: '1.375 in. Thin-Line, Frameless',
    image: 'ESU-153S.png',
    model: 'ESU-153S',
    modelGroup: 'Specialty',
    name: 'ESU-153S',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'ESU-154_LOD200.zip',
    category: 'Specialty',
    description: '1.5 in. Thin-Line',
    image: 'ESU-154.png',
    model: 'ESU-154',
    modelGroup: 'Specialty',
    name: 'ESU-154',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'ESU-154S_LOD200.zip',
    category: 'Specialty',
    description: '1.5 in. Thin-Line, Frameless',
    image: 'ESU-154S.png',
    model: 'ESU-154S',
    modelGroup: 'Specialty',
    name: 'ESU-154S',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'EVH-302_LOD200.zip',
    category: 'Wind driven rain',
    description: '3 in. Vertical Rain Resistant',
    image: 'EVH-302.png',
    model: 'EVH-302',
    modelGroup: 'WindDrivenRain',
    name: 'EVH-302',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EVH-302D_LOD200.zip',
    category: 'Hurricane',
    description: '3 in. Vertical Rain Resistant, Hurricane Rated',
    image: 'EVH-302D.png',
    model: 'EVH-302D',
    modelGroup: 'Hurricane',
    name: 'EVH-302D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EVH-501_LOD200.zip',
    category: 'Wind driven rain',
    description: '5 in. Vertical Rain Resistant',
    image: 'EVH-501.png',
    model: 'EVH-501',
    modelGroup: 'WindDrivenRain',
    name: 'EVH-501',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EVH-501D_LOD200.zip',
    category: 'Hurricane',
    description: '5 in. Vertical Rain Resistant, Hurricane Rated',
    image: 'EVH-501D.png',
    model: 'EVH-501D',
    modelGroup: 'Hurricane',
    name: 'EVH-501D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'EVH-660D_LOD200.zip',
    category: 'Hurricane',
    description: '6 in. Vertical Rain Resistant, Hurricane Rated',
    image: 'EVH-660D.png',
    model: 'EVH-660D',
    modelGroup: 'Hurricane',
    name: 'EVH-660D',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'FAD-402_LOD200.zip',
    category: 'Operable',
    description: '4 in. Adjustable, Drainable Blade, Fabricated Steel',
    image: 'FAD-402.png',
    model: 'FAD-402',
    modelGroup: 'Operable',
    name: 'FAD-402',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'FAD-635_LOD200.zip',
    category: 'Operable',
    description: '6 in. Adjustable, Drainable Blade, Fabricated Steel',
    image: 'FAD-635.png',
    model: 'FAD-635',
    modelGroup: 'Operable',
    name: 'FAD-635',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'FDS-402_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Drainable Blade, Fabricated Steel',
    image: 'FDS-402.png',
    model: 'FDS-402',
    modelGroup: 'Stationary',
    name: 'FDS-402',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'FDS-602_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Drainable Blade, Fabricated Steel',
    image: 'FDS-602.png',
    model: 'FDS-602',
    modelGroup: 'Stationary',
    name: 'FDS-602',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'FGI_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Hooded Gravity Intake Ventilator',
    image: 'FGI.png',
    model: 'FGI',
    modelGroup: 'Gravity',
    name: 'FGI',
    productType: 'Gravity',
    subtitle: 'Intake',
  },
  {
    allowInsert: true,
    file: 'FGR_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Hooded Gravity Relief Ventilator',
    image: 'FGR.png',
    model: 'FGR',
    modelGroup: 'Gravity',
    name: 'FGR',
    productType: 'Gravity',
    subtitle: 'Relief',
  },
  {
    allowInsert: true,
    file: 'FJC_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description: 'Tubular Centrifugal Belt Drive Fan with High Plume Conical Nozzle',
    image: 'FJC-3.png',
    model: 'FJC-3',
    modelGroup: 'FumeExhaust',
    name: 'FJC',
    productType: 'Fans',
    subtitle: 'Fume Exhaust',
  },
  {
    allowInsert: true,
    file: 'FJC_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description:
      'Tubular Centrifugal Belt Drive Fan with High Plume Conical Nozzle',
    image: 'FJC-3.png',
    model: 'FJC-3',
    modelGroup: 'RoofExhaust',
    name: 'FJC',
    productType: 'Fans',
    subtitle: 'Roof Exhaust',
  },
  {
    allowInsert: true,
    file: 'FJI_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description: 'Centrifugal Belt or Direct Drive Fan with Discharge Stack',
    image: 'FJI.png',
    model: 'FJI',
    modelGroup: 'FumeExhaust',
    name: 'FJI',
    productType: 'Fans',
    subtitle: 'Fume Exhaust',
  },
  {
    allowInsert: true,
    file: 'FJI_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Centrifugal Belt or Direct Drive Fan with Discharge Stack',
    image: 'FJI.png',
    model: 'FJI',
    modelGroup: 'RoofExhaust',
    name: 'FJI',
    productType: 'Fans',
    subtitle: 'Roof Exhaust',
  },
  {
    allowInsert: true,
    file: 'FPB_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Light Duty Pressure Blower',
    image: 'FPB.png',
    model: 'FPB',
    modelGroup: 'ScrolledBlower',
    name: 'FPB',
    productType: 'Fans',
    subtitle: 'Blower',
  },
  {
    allowInsert: true,
    file: 'FSD_LOD200.zip',
    category: 'Dampers',
    description: 'Combination fire smoke damper',
    image: 'FSD.png',
    model: 'FSD',
    modelGroup: 'Dampers',
    name: 'FSD',
    productType: 'Dampers',
  },
  {
    allowInsert: true,
    file: 'FSDR_LOD200.zip',
    category: 'Dampers',
    description: 'Round combination fire smoke damper',
    image: 'FSDR.png',
    model: 'FSDR',
    modelGroup: 'Dampers',
    name: 'FSDR',
    productType: 'Dampers',
  },
  {
    allowInsert: true,
    file: 'FSJ-402_LOD200.zip',
    category: 'Stationary',
    description: '4 in. J Blade, Fabricated Steel',
    image: 'FSJ-402.png',
    model: 'FSJ-402',
    modelGroup: 'Stationary',
    name: 'FSJ-402',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'FSJ-602_LOD200.zip',
    category: 'Stationary',
    description: '6 in. Fabricated steel, Non-drainable',
    image: 'FSJ-602.png',
    model: 'FSJ-602',
    modelGroup: 'Stationary',
    name: 'FSJ-602',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'FSL-401_LOD200.zip',
    category: 'Specialty',
    description: '4 in. Vertical Sand Trap',
    image: 'FSL-401.png',
    model: 'FSL-401',
    modelGroup: 'Specialty',
    name: 'FSL-401',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'G_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Direct Drive Centrifugal Roof Exhaust Fan',
    image: 'G.png',
    model: 'G',
    modelGroup: 'RoofExhaust',
    name: 'G',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'GB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Belt Drive Centrifugal Roof Exhaust Fan',
    image: 'GB.png',
    model: 'GB',
    modelGroup: 'RoofExhaust',
    name: 'GB',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'GCE-402_LOD200.zip',
    category: 'Operable',
    description: '4 in. J Blade, Combo Exhaust Louver/Damper',
    image: 'GCE-402.png',
    model: 'GCE-402',
    modelGroup: 'Operable',
    name: 'GCE-402',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'GCI-402_LOD200.zip',
    category: 'Operable',
    description: '4 in. J Blade, Combo Intake Louver/Damper',
    image: 'GCI-402.png',
    model: 'GCI-402',
    modelGroup: 'Operable',
    name: 'GCI-402',
    productType: 'Louvers',
  },
  {
    allowInsert: true,
    file: 'GJI_LOD200_AllSizes.zip',
    category: 'JetFans',
    description: 'Jet Fan',
    image: 'GJI.png',
    model: 'GJI',
    modelGroup: 'JetFans',
    name: 'GJI',
    productType: 'Jet Fans',
  },
  {
    allowInsert: true,
    file: 'GJX_LOD200_AllSizes.zip',
    category: 'JetFans',
    description: 'Jet Fan',
    image: 'GJX.png',
    model: 'GJX',
    modelGroup: 'JetFans',
    name: 'GJX',
    productType: 'Jet Fans',
  },
  {
    allowInsert: true,
    file: 'GRSI_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Spun Aluminum Gravity Intake Ventilator',
    image: 'GRSI.png',
    model: 'GRSI',
    modelGroup: 'Gravity',
    name: 'GRSI',
    productType: 'Gravity',
    subtitle: 'Intake',
  },
  {
    allowInsert: true,
    file: 'GRSR_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Spun Aluminum Gravity Relief Ventilator',
    image: 'GRSR.png',
    model: 'GRSR',
    modelGroup: 'Gravity',
    name: 'GRSR',
    productType: 'Gravity',
    subtitle: 'Relief',
  },
  {
    allowInsert: true,
    file: 'HPA_LOD200_AllSizes.zip',
    category: 'Plenum',
    description: 'Direct Drive Plenum Fan with Sound Attenuating Housing',
    image: 'HPA.png',
    model: 'HPA',
    modelGroup: 'PlugAndPlenum',
    name: 'HPA',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'IDHB_LOD200.zip',
    category: 'DuctHeaters',
    description: 'Electric Duct Heater',
    image: 'IDHB.png',
    model: 'IDHB',
    modelGroup: 'DuctHeaters',
    name: 'IDHB',
    productType: 'Duct Heaters',
  },
  {
    allowInsert: true,
    file: 'IDHE_LOD200.zip',
    category: 'DuctHeaters',
    description: 'Electric Duct Heater',
    image: 'IDHE.png',
    model: 'IDHE',
    modelGroup: 'DuctHeaters',
    name: 'IDHE',
    productType: 'Duct Heaters',
  },
  {
    allowInsert: true,
    file: 'IDHE-O_LOD200.zip',
    category: 'DuctHeaters',
    description: 'Electric Duct Heater',
    image: 'IDHE-O.png',
    model: 'IDHE-O',
    modelGroup: 'DuctHeaters',
    name: 'IDHE-O',
    productType: 'Duct Heaters',
  },
  {
    allowInsert: true,
    file: 'IP_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Industrial Process Blower',
    image: 'IP.png',
    model: 'IP',
    modelGroup: 'ScrolledBlower',
    name: 'IP',
    productType: 'Fans',
    subtitle: 'Blower',
  },
  {
    allowInsert: true,
    file: 'KSQ_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Filtered Roof Supply Fan',
    image: 'KSQ.png',
    model: 'KSQ',
    modelGroup: 'RoofSupply',
    name: 'KSQ',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'LB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Belt Drive Fabra Hood Centrifugal Roof Exhaust Fan',
    image: 'LB.png',
    model: 'LB',
    modelGroup: 'RoofExhaust',
    name: 'LB',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'LBP_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Belt Drive Louvered Centrifugal Roof Exhaust Fan',
    image: 'LBP.png',
    model: 'LBP',
    modelGroup: 'RoofExhaust',
    name: 'LBP',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'LD_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Direct Drive Fabra Hood Centrifugal Roof Exhaust Fan',
    image: 'LD.png',
    model: 'LD',
    modelGroup: 'RoofExhaust',
    name: 'LD',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'LDP_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Direct Drive Louvered Centrifugal Roof Exhaust Fan',
    image: 'LDP.png',
    model: 'LDP',
    modelGroup: 'RoofExhaust',
    name: 'LDP',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'MiniCore_LOD200_AllSizes.zip',
    category: 'EnergyRecoveryVentilators',
    description: 'Energy Recovery Core',
    image: 'MINICORE.png',
    model: 'Minicore',
    modelGroup: 'EnergyRecoveryVentilators',
    name: 'Minicore',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'MiniVent_LOD200_AllSizes.zip',
    category: 'EnergyRecoveryVentilators',
    description: 'Energy Recovery Wheel',
    image: 'MINIVENT.png',
    model: 'Minivent',
    modelGroup: 'EnergyRecoveryVentilators',
    name: 'Minivent',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'PLG_LOD200_AllSizes.zip',
    category: 'Plenum',
    description: 'Plug Fan',
    image: 'PLG.png',
    model: 'PLG',
    modelGroup: 'PlugAndPlenum',
    name: 'PLG',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'QEI-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Mixed Flow Belt Drive Inline Fan',
    image: 'QEI-INLINE.png',
    model: 'QEI',
    modelGroup: 'InlineFans',
    name: 'QEI',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'QEI-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Mixed Flow Belt Drive Inline Fan',
    image: 'QEI-INLINE-VERTICAL.png',
    model: 'QEI',
    modelGroup: 'InlineFans',
    name: 'QEI',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'QEI_UB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tubular Mixed Flow Upblast Roof Belt Drive Exhaust Fan',
    image: 'QEI-UPBLAST.png',
    model: 'QEI',
    modelGroup: 'RoofExhaust',
    name: 'QEI',
    productType: 'Fans',
    subtitle: 'Upblast',
  },
  {
    allowInsert: true,
    file: 'QEID-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Mixed Flow Direct Drive Inline Fan',
    image: 'QEID.png',
    model: 'QEID',
    modelGroup: 'InlineFans',
    name: 'QEID',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'QEID-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Mixed Flow Direct Drive Inline Fan',
    image: 'QEID-VERTICAL.png',
    model: 'QEID',
    modelGroup: 'InlineFans',
    name: 'QEID',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'QEID_UB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tubular Mixed Flow Upblast Roof Direct Drive Exhaust Fan',
    image: 'QEID-UPBLAST.png',
    model: 'QEID',
    modelGroup: 'RoofExhaust',
    name: 'QEID',
    productType: 'Fans',
    subtitle: 'Upblast',
  },
  {
    allowInsert: true,
    file: 'QEIDFJ-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Mixed Flow Direct Drive Inline Fan',
    image: 'QEIDFJ.png',
    model: 'QEIDFJ',
    modelGroup: 'InlineFans',
    name: 'QEIDFJ',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'QEIDFJ-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Mixed Flow Direct Drive Inline Fan',
    image: 'QEIDFJ-VERTICAL.png',
    model: 'QEIDFJ',
    modelGroup: 'InlineFans',
    name: 'QEIDFJ',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'RBCE_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Propeller Hooded Roof Belt Drive Exhaust Fan',
    image: 'RBCE.png',
    model: 'RBCE',
    modelGroup: 'RoofExhaust',
    name: 'RBCE',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RBCF_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Propeller Belt Drive Filtered Roof Supply Fan',
    image: 'RBCF.png',
    model: 'RBCF',
    modelGroup: 'RoofSupply',
    name: 'RBCF',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RBCS_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Propeller Belt Drive Roof Supply Fan',
    image: 'RBCS.png',
    model: 'RBCS',
    modelGroup: 'RoofSupply',
    name: 'RBCS',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RBE_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Propeller Hooded Roof Belt Drive Exhaust Fan',
    image: 'RBE.png',
    model: 'RBE',
    modelGroup: 'RoofExhaust',
    name: 'RBE',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RBF_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Propeller Belt Drive Filtered Roof Supply Fan',
    image: 'RBF.png',
    model: 'RBF',
    modelGroup: 'RoofSupply',
    name: 'RBF',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RBS_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Propeller Belt Drive Roof Supply Fan',
    image: 'RBS.png',
    model: 'RBS',
    modelGroup: 'RoofSupply',
    name: 'RBS',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RBU_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Propeller Upblast Belt Drive Exhaust Fan',
    image: 'RBU.png',
    model: 'RBU',
    modelGroup: 'RoofExhaust',
    name: 'RBU',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RBUMO_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description:
      'Propeller Upblast Belt Drive Motor Out-of-Airstream Exhaust Fan',
    image: 'RBUMO.png',
    model: 'RBUMO',
    modelGroup: 'RoofExhaust',
    name: 'RBUMO',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RCE3_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Propeller Hooded Roof Direct Drive Exhaust Fan',
    image: 'RCE3.png',
    model: 'RCE3',
    modelGroup: 'RoofExhaust',
    name: 'RCE3',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RCS3_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Propeller Direct Drive Roof Supply Fan',
    image: 'RCS3.png',
    model: 'RCS3',
    modelGroup: 'RoofSupply',
    name: 'RCS3',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RDU_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Propeller Upblast Direct Drive Exhaust Fan',
    image: 'RDU.png',
    model: 'RDU',
    modelGroup: 'RoofExhaust',
    name: 'RDU',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RE2_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Propeller Hooded Roof Direct Drive Exhaust Fan',
    image: 'RE2.png',
    model: 'RE2',
    modelGroup: 'RoofExhaust',
    name: 'RE2',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RGU_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Upblast Gravity Relief Ventilator',
    image: 'RGU.png',
    model: 'RGU',
    modelGroup: 'Gravity',
    name: 'RGU',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'RS2_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Hooded Propeller Direct Drive Roof Supply Fan',
    image: 'RS2.png',
    model: 'RS2',
    modelGroup: 'RoofSupply',
    name: 'RS2',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RSF_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Filtered Roof Supply Fan',
    image: 'RSF.png',
    model: 'RSF',
    modelGroup: 'RoofSupply',
    name: 'RSF',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'RSFP_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Filtered Louvered Roof Supply Fan',
    image: 'RSFP.png',
    model: 'RSFP',
    modelGroup: 'RoofSupply',
    name: 'RSFP',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'RV_LOD200_AllSizes.zip',
    category: 'OutdoorAir',
    description: 'Dedicated Outdoor Air System',
    image: 'RV.png',
    model: 'RV',
    modelGroup: 'OutdoorAir',
    name: 'RV',
    productType: 'DOAS',
  },
  {
    allowInsert: true,
    file: 'RVC_LOD200_AllSizes.zip',
    category: 'OutdoorAir',
    description: 'Dedicated Outdoor Air System with Energy Recovery',
    image: 'RVC.png',
    model: 'RVC',
    modelGroup: 'OutdoorAir',
    name: 'RVC',
    productType: 'DOAS',
  },
  {
    allowInsert: true,
    file: 'RVE_LOD200_AllSizes.zip',
    category: 'OutdoorAir',
    description: 'Dedicated Outdoor Air System with Energy Recovery',
    image: 'RVE.png',
    model: 'RVE',
    modelGroup: 'OutdoorAir',
    name: 'RVE',
    productType: 'DOAS',
  },
  {
    allowInsert: true,
    file: 'SAF_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Filtered Roof Supply Fan',
    image: 'SAF.png',
    model: 'SAF',
    modelGroup: 'RoofSupply',
    name: 'SAF',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SBCE_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Belt Drive Exhaust Fan',
    image: 'SBCE.png',
    model: 'SBCE',
    modelGroup: 'SidewallMounted',
    name: 'SBCE',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SBCR_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Belt Drive Reversible Fan',
    image: 'SBCR.png',
    model: 'SBCR',
    modelGroup: 'SidewallMounted',
    name: 'SBCR',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'SBCS_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Belt Drive Supply Fan',
    image: 'SBCS.png',
    model: 'SBCS',
    modelGroup: 'SidewallMounted',
    name: 'SBCS',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SBE_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Belt Drive Exhaust Fan',
    image: 'SBE.png',
    model: 'SBE',
    modelGroup: 'SidewallMounted',
    name: 'SBE',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SBS_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Belt Drive Supply Fan',
    image: 'SBS.png',
    model: 'SBS',
    modelGroup: 'SidewallMounted',
    name: 'SBS',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SCR3_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Direct Drive Reversible Fan',
    image: 'SCR3.png',
    model: 'SCR3',
    modelGroup: 'SidewallMounted',
    name: 'SCR3',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'SE_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Direct Drive Exhaust Fan',
    image: 'SE.png',
    model: 'SE',
    modelGroup: 'SidewallMounted',
    name: 'SE',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SED-401_LOD200.zip',
    category: 'Stationary',
    description: '4 in. Drainable Blade, Sightproof',
    image: 'SED-401.png',
    model: 'SED-401',
    modelGroup: 'Stationary',
    name: 'SED-401',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SED-501_LOD200.zip',
    category: 'Stationary',
    description: '5 in. Drainable Blade, Sightproof',
    image: 'SED-501.png',
    model: 'SED-501',
    modelGroup: 'Stationary',
    name: 'SED-501',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SES-202_LOD200.zip',
    category: 'Stationary',
    description: '2 in. Non-Drainable Blade, Sightproof',
    image: 'SES-202.png',
    model: 'SES-202',
    modelGroup: 'Stationary',
    name: 'SES-202',
    productType: 'Louvers',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SP-A-Rect_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-A.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-A',
    productType: 'Fans',
    subtitle: 'Rectangle Connector',
  },
  {
    allowInsert: true,
    file: 'SP-A-Round_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-A-Round.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-A',
    productType: 'Fans',
    subtitle: 'Round Connector',
  },
  {
    allowInsert: true,
    file: 'SP-AP_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-AP.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-AP',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'SP-B_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-B.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-B',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'SP-C_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-C.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-C',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'SP-L_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-L.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-L',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SP-LP_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-LP.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-LP',
    productType: 'Fans',
    subtitle: 'Oval Connector',
  },
  {
    allowInsert: true,
    file: 'SP-VG_LOD200_AllSizes.zip',
    category: 'Ceiling',
    description: 'Direct Drive Bathroom Exhaust Fan',
    image: 'SP-VG.png',
    model: 'SP',
    modelGroup: 'Ceiling',
    name: 'SP-VG',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'SQ_LOD200_v01_AllSizes.zip',
    category: 'Inline',
    description: 'Direct Drive Inline Fan',
    image: 'SQ.png',
    model: 'SQ',
    modelGroup: 'InlineFans',
    name: 'SQ',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'SS_LOD200_AllSizes.zip',
    category: 'Wall mounted',
    description: 'Sidewall Direct Drive Supply Fan',
    image: 'SS.png',
    model: 'SS',
    modelGroup: 'SidewallMounted',
    name: 'SS',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'SYNC-110_LOD200.zip',
    category: 'ResidentialEnergyRecovery',
    description: 'Residential Energy Recovery Ventilator',
    image: 'SYNC-110.png',
    model: 'SYNC-110',
    modelGroup: 'ResidentialEnergyRecovery',
    name: 'SYNC-110',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'SYNC-180_LOD200.zip',
    category: 'ResidentialEnergyRecovery',
    description: 'Residential Energy Recovery Ventilator',
    image: 'SYNC-180.png',
    model: 'SYNC-180',
    modelGroup: 'ResidentialEnergyRecovery',
    name: 'SYNC-180',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'SYNC-270_LOD200.zip',
    category: 'ResidentialEnergyRecovery',
    description: 'Residential Energy Recovery Ventilator',
    image: 'SYNC-270.png',
    model: 'SYNC-270',
    modelGroup: 'ResidentialEnergyRecovery',
    name: 'SYNC-270',
    productType: 'Energy Recovery Ventilators',
  },
  {
    allowInsert: true,
    file: 'TAUB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tube Axial Upblast Roof Belt Drive Exhaust Fan',
    image: 'TAUB.png',
    model: 'TAUB',
    modelGroup: 'RoofExhaust',
    name: 'TAUB',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'TAUB_CA_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tube Axial Upblast Roof Belt Drive Exhaust Fan',
    image: 'TAUB-CA.png',
    model: 'TAUB-CA',
    modelGroup: 'RoofExhaust',
    name: 'TAUB-CA',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'TAUD_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tube Axial Upblast Roof Direct Drive Exhaust Fan',
    image: 'TAUD.png',
    model: 'TAUD',
    modelGroup: 'RoofExhaust',
    name: 'TAUD',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'TBI-CA-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: '',
    image: 'TBI-CA-INLINE.png',
    model: 'TBI-CA',
    modelGroup: 'InlineFans',
    name: 'TBI-CA',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'TBI-CA-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: '',
    image: 'TBI-CA-INLINE-VERTICAL.png',
    model: 'TBI-CA',
    modelGroup: 'InlineFans',
    name: 'TBI-CA',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'TBI_CA_UB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: '',
    image: 'TBI-CA-UPBLAST.png',
    model: 'TBI-CA',
    modelGroup: 'RoofExhaust',
    name: 'TBI-CA',
    productType: 'Fans',
    subtitle: 'Upblast',
  },
  {
    allowInsert: true,
    file: 'TBI-FS-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Tube Axial Belt Drive Inline Fan',
    image: 'TBI-FS-INLINE.png',
    model: 'TBI-FS',
    modelGroup: 'InlineFans',
    name: 'TBI-FS',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'TBI-FS-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Tube Axial Belt Drive Inline Fan',
    image: 'TBI-FS-INLINE-VERTICAL.png',
    model: 'TBI-FS',
    modelGroup: 'InlineFans',
    name: 'TBI-FS',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'TBI_FS_UB_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tube Axial Upblast Roof Belt Drive Exhaust Fan',
    image: 'TBI-FS-UPBLAST.png',
    model: 'TBI-FS',
    modelGroup: 'RoofExhaust',
    name: 'TBI-FS',
    productType: 'Fans',
    subtitle: 'Upblast',
  },
  {
    allowInsert: true,
    file: 'TCB-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Tubular Centrifugal Inline Fan',
    image: 'TCB.png',
    model: 'TCB',
    modelGroup: 'InlineFans',
    name: 'TCB',
    productType: 'Fans',
    subtitle: 'Inline Horizontal (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'TCB-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Tubular Centrifugal Inline Fan',
    image: 'TCB-VERTICAL.png',
    model: 'TCB',
    modelGroup: 'InlineFans',
    name: 'TCB',
    productType: 'Fans',
    subtitle: 'Inline Vertical (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'TCBRS_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tubular Centrifugal Roof Supply Fan',
    image: 'TCBRS.png',
    model: 'TCBRS',
    modelGroup: 'RoofSupply',
    name: 'TCBRS',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'TCBRU_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Tubular Centrifugal Upblast Exhaust Fan',
    image: 'TCBRU.png',
    model: 'TCBRU',
    modelGroup: 'RoofExhaust',
    name: 'TCBRU',
    productType: 'Fans',
    subtitle: '(*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'TDI-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Tube Axial Direct Drive Inline Fan',
    image: 'TDI.png',
    model: 'TDI',
    modelGroup: 'InlineFans',
    name: 'TDI',
    productType: 'Fans',
    subtitle: 'Inline Horizontal (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'TDI-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Tube Axial Direct Drive Inline Fan',
    image: 'TDI-VERTICAL.png',
    model: 'TDI',
    modelGroup: 'InlineFans',
    name: 'TDI',
    productType: 'Fans',
    subtitle: 'Inline Vertical (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'TSF_LOD200_AllSizes.zip',
    category: 'MakeUpAir',
    description: 'Non Gas Fired Make-up Air unit',
    image: 'TSF.png',
    model: 'TSF',
    modelGroup: 'MakeUpAir',
    name: 'TSF',
    productType: 'Make Up Air',
  },
  {
    allowInsert: true,
    file: 'TSU_LOD200_AllSizes.zip',
    category: 'MakeUpAir',
    description: 'Direct Gas Fired Make-up Air unit',
    image: 'TSU.png',
    model: 'TSU',
    modelGroup: 'MakeUpAir',
    name: 'TSU',
    productType: 'Make Up Air',
  },
  {
    allowInsert: true,
    file: 'USF-Belt_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Universal Single Width Blower',
    image: 'USFB.png',
    model: 'USF',
    modelGroup: 'ScrolledBlower',
    name: 'USF',
    productType: 'Fans',
    subtitle: 'Belt Drive',
  },
  {
    allowInsert: true,
    file: 'USF-Direct_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Universal Single Width Blower',
    image: 'USFB.png',
    model: 'USF',
    modelGroup: 'ScrolledBlower',
    name: 'USF',
    productType: 'Fans',
    subtitle: 'Direct Drive',
  },
  {
    allowInsert: true,
    file: 'USF-300_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Universal Single Width Blower',
    image: 'USFA-300.png',
    model: 'USF',
    modelGroup: 'ScrolledBlower',
    name: 'USF-300',
    productType: 'Fans',
    subtitle: 'Blower',
  },
  {
    allowInsert: true,
    file: 'USF-500-Belt_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Universal Single Width Blower',
    image: 'USFA-500-Belt.png',
    model: 'USF',
    modelGroup: 'ScrolledBlower',
    name: 'USF-500',
    productType: 'Fans',
    subtitle: 'Belt Drive',
  },
  {
    allowInsert: true,
    file: 'USF-500-Direct_LOD200_AllSizes.zip',
    category: 'Blowers',
    description: 'Universal Single Width Blower',
    image: 'USFA-500.png',
    model: 'USF',
    modelGroup: 'ScrolledBlower',
    name: 'USF-500',
    productType: 'Fans',
    subtitle: 'Direct Drive',
  },
  {
    allowInsert: true,
    file: 'USGF_LOD200_AllSizes.zip',
    category: 'Roof mounted',
    description: 'Ultimate Steel Grease Fan',
    image: 'USGF.png',
    model: 'USGF',
    modelGroup: 'RoofExhaust',
    name: 'USGF',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'VAB-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Belt Drive Inline Fan',
    image: 'VAB.png',
    model: 'VAB',
    modelGroup: 'InlineFans',
    name: 'VAB',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'VAB-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Belt Drive Inline Fan',
    image: 'VAB-VERTICAL.png',
    model: 'VAB',
    modelGroup: 'InlineFans',
    name: 'VAB',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'VABS-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Belt Drive Inline Fan with Sound Trap',
    image: 'VABS.png',
    model: 'VABS',
    modelGroup: 'InlineFans',
    name: 'VABS',
    productType: 'Fans',
    subtitle: 'Inline Horizontal (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'VABS-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Belt Drive Inline Fan with Sound Trap',
    image: 'VABS-VERTICAL.png',
    model: 'VABS',
    modelGroup: 'InlineFans',
    name: 'VABS',
    productType: 'Fans',
    subtitle: 'Inline Vertical (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'VAD-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Direct Drive Inline Fan',
    image: 'VAD.png',
    model: 'VAD',
    modelGroup: 'InlineFans',
    name: 'VAD',
    productType: 'Fans',
    subtitle: 'Inline Horizontal',
  },
  {
    allowInsert: true,
    file: 'VAD-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Direct Drive Inline Fan',
    image: 'VAD-vertical.png',
    model: 'VAD',
    modelGroup: 'InlineFans',
    name: 'VAD',
    productType: 'Fans',
    subtitle: 'Inline Vertical',
  },
  {
    allowInsert: true,
    file: 'VADS-Horiz_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Direct Drive Inline Fan with Sound Trap',
    image: 'VADS.png',
    model: 'VADS',
    modelGroup: 'InlineFans',
    name: 'VADS',
    productType: 'Fans',
    subtitle: 'Inline Horizontal (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'VADS-Vert_LOD200_AllSizes.zip',
    category: 'Inline',
    description: 'Vane Axial Direct Drive Inline Fan with Sound Trap',
    image: 'VAD-vertical.png',
    model: 'VADS',
    modelGroup: 'InlineFans',
    name: 'VADS',
    productType: 'Fans',
    subtitle: 'Inline Vertical (*DISCONTINUED*)',
  },
  {
    allowInsert: true,
    file: 'VCD_LOD200.zip',
    category: 'Dampers',
    description: 'Commercial control damper',
    image: 'VCD.png',
    model: 'VCD',
    modelGroup: 'Dampers',
    name: 'VCD',
    productType: 'Dampers',
  },
  {
    allowInsert: true,
    file: 'VK-CD-Belt_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Backward Inclined Scrolled Blower with Nozzle and Air Entrainment Dilution Windband',
    image: 'VK-CD-Belt.png',
    model: 'VEKTOR-CD',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-CD',
    productType: 'Fans',
    subtitle: 'Belt Drive',
  },
  {
    allowInsert: true,
    file: 'VK-CD-Diesel_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Backward Inclined Scrolled Blower with Nozzle and Air Entrainment Dilution Windband',
    image: 'VK-CD-Diesel.png',
    model: 'VEKTOR-CD-DIESEL',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-CD',
    productType: 'Fans',
    subtitle: 'Diesel',
  },
  {
    allowInsert: true,
    file: 'VK-CD-Direct_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Backward Inclined Scrolled Blower with Nozzle and Air Entrainment Dilution Windband',
    image: 'VK-CD-Direct.png',
    model: 'VEKTOR-CD',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-CD',
    productType: 'Fans',
    subtitle: 'Direct Drive',
  },
  {
    allowInsert: true,
    file: 'VK-CH-Belt_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Backward Inclined Scrolled Blower with High Plume Conical Nozzle',
    image: 'VK-CH-Belt.png',
    model: 'VEKTOR-CH',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-CH',
    productType: 'Fans',
    subtitle: 'Belt Drive',
  },
  {
    allowInsert: true,
    file: 'VK-CH-Direct_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Backward Inclined Scrolled Blower with High Plume Conical Nozzle',
    image: 'VK-CH-Direct.png',
    model: 'VEKTOR-CH',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-CH',
    productType: 'Fans',
    subtitle: 'Direct Drive',
  },
  {
    allowInsert: true,
    file: 'VK-H_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Tubular Centrifugal Belt Drive Fan with High Plume Conical Nozzle',
    image: 'VK-H.png',
    model: 'VEKTOR-H',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-H',
    productType: 'Fans',
  },
  {
    allowInsert: true,
    file: 'VK-MD-Belt_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Mixed Flow Fan with Bifurcated Housing, Nozzle, and Air Entrainment Dilution Windband',
    image: 'VK-MD_Belt-Drive.png',
    model: 'VEKTOR-MD',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-MD',
    productType: 'Fans',
    subtitle: 'Belt Drive',
  },
  {
    allowInsert: true,
    file: 'VK-MD-Direct_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Mixed Flow Fan with Bifurcated Housing, Nozzle, and Air Entrainment Dilution Windband',
    image: 'VK-MD_Direct-Drive.png',
    model: 'VEKTOR-MD',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-MD',
    productType: 'Fans',
    subtitle: 'Direct Drive',
  },
  {
    allowInsert: true,
    file: 'VK-MH-Belt_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Mixed Flow Fan with Bifurcated Housing and High Plume Conical Nozzle',
    image: 'VK-MH_Belt-Drive.png',
    model: 'VEKTOR-MH',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-MH',
    productType: 'Fans',
    subtitle: 'Belt Drive',
  },
  {
    allowInsert: true,
    file: 'VK-MH-Direct_LOD200_AllSizes.zip',
    category: 'Fume exhaust',
    description:
      'Mixed Flow Fan with Bifurcated Housing and High Plume Conical Nozzle',
    image: 'VK-MH_Direct-Drive.png',
    model: 'VEKTOR-MH',
    modelGroup: 'FumeExhaust',
    name: 'VEKTOR-MH',
    productType: 'Fans',
    subtitle: 'Direct Drive',
  },
  {
    allowInsert: true,
    file: 'WIH_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Louvered Gravity Intake Ventilator',
    image: 'WIH.png',
    model: 'WIH',
    modelGroup: 'Gravity',
    name: 'WIH',
    productType: 'Gravity',
    subtitle: 'Intake',
  },
  {
    allowInsert: true,
    file: 'WRH_LOD200_AllSizes.zip',
    category: 'Gravity',
    description: 'Louvered Gravity Relief Ventilator',
    image: 'WRH.png',
    model: 'WRH',
    modelGroup: 'Gravity',
    name: 'WRH',
    productType: 'Gravity',
    subtitle: 'Relief',
  },
  {
    allowInsert: true,
    file: 'XG-3100_1_LOD200.zip',
    category: 'Diffusers',
    description: 'Aluminum round fixed two-cone ceiling diffuser.',
    image: 'XG-3100.png',
    model: 'XG-3100',
    modelGroup: 'Diffusers',
    name: 'XG-3100_1',
    productType: 'Diffusers',
    subtitle: 'Flush surface mount',
  },
  {
    allowInsert: true,
    file: 'XG-3100_2_LOD200.zip',
    category: 'Diffusers',
    description: 'Aluminum round fixed two-cone ceiling diffuser.',
    image: 'XG-3100.png',
    model: 'XG-3100',
    modelGroup: 'Diffusers',
    name: 'XG-3100_2',
    productType: 'Diffusers',
    subtitle: 'Drop surface mount',
  },
  {
    allowInsert: true,
    file: 'XG-5700A_12x12_LOD200.zip',
    category: 'Diffusers',
    description: 'Adjustable round neck two-cone square faced diffuser.',
    image: 'XG-5700A.png',
    model: 'XG-5700A',
    modelGroup: 'Diffusers',
    name: 'XG-5700A',
    productType: 'Diffusers',
    subtitle: '12x12',
  },
  {
    allowInsert: true,
    file: 'XG-5700A_24x24_LOD200.zip',
    category: 'Diffusers',
    description: 'Adjustable round neck two-cone square faced diffuser.',
    image: 'XG-5700A.png',
    model: 'XG-5700A',
    modelGroup: 'Diffusers',
    name: 'XG-5700A',
    productType: 'Diffusers',
    subtitle: '24x24',
  },
  {
    allowInsert: true,
    file: 'XG-5750_LOD200.zip',
    category: 'Diffusers',
    description: 'Round neck square plaque faced diffuser',
    image: 'XG-5750.png',
    model: 'XG-5750',
    modelGroup: 'Diffusers',
    name: 'XG-5750',
    productType: 'Diffusers',
  },
  {
    allowInsert: true,
    file: 'XG-5750RP_LOD200.zip',
    category: 'Diffusers',
    description:
      'Round plaque faced diffuser with square formed backpan and round neck',
    image: 'XG-5750RP.png',
    model: 'XG-5750RP',
    modelGroup: 'Diffusers',
    name: 'XG-5750RP',
    productType: 'Diffusers',
  },
{
    allowInsert: true,
    file: 'XG-5800A_12x12_LOD200.zip',
    category: 'Diffusers',
    description: 'Adjustable round neck two-cone square faced diffuser.',
    image: 'XG-5700A.png',
    model: 'XG-5800A',
    modelGroup: 'Diffusers',
    name: 'XG-5800A',
    productType: 'Diffusers',
    subtitle: '12x12',
  },
  {
    allowInsert: true,
    file: 'XG-5800A_24x24_LOD200.zip',
    category: 'Diffusers',
    description: 'Adjustable round neck two-cone square faced diffuser.',
    image: 'XG-5700A.png',
    model: 'XG-5800A',
    modelGroup: 'Diffusers',
    name: 'XG-5800A',
    productType: 'Diffusers',
    subtitle: '24x24',
  },
  {
    allowInsert: true,
    file: 'XG-CC_LOD200.zip',
    category: 'Grilles',
    description: 'Aluminum sidewall or ceiling return/exhaust grille',
    image: 'XG-CC.png',
    model: 'XG-CC',
    modelGroup: 'Grilles',
    name: 'XG-CC',
    productType: 'Grilles',
  },
  {
    allowInsert: true,
    file: 'XG-FCI-600-HWHeat-RectInlet.zip',
    category: 'AirTerminalUnit',
    description: 'Fan-powered terminal units',
    image: 'XG-FCI-600.png',
    model: 'XG-FCI-600',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FCI-600',
    productType: 'Air Terminal Unit',
    subtitle: 'Rect Inlet, HW Heat',
},
  {
    allowInsert: true,
    file: 'XG-FCI-600-Basic-RectInlet.zip',
    category: 'AirTerminalUnit',
    description: 'Fan-powered terminal units',
    image: 'XG-FCI-600.png',
    model: 'XG-FCI-600',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FCI-600',
    productType: 'Air Terminal Unit',
    subtitle: 'Rectangular Inlet',
  },
  {
    allowInsert: true,
    file: 'XG-FCI-600-ElectricHeat-RectInlet.zip',
    category: 'AirTerminalUnit',
    description: 'Fan-powered terminal units',
    image: 'XG-FCI-600.png',
    model: 'XG-FCI-600',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FCI-600',
    productType: 'Air Terminal Unit',
    subtitle: 'Rectangular Inlet, Electric Heat',
  },
  {
    allowInsert: true,
    file: 'XG-FCI-600-Basic-RndInlet.zip',
    category: 'AirTerminalUnit',
    description: 'Fan-powered terminal units',
    image: 'XG-FCI-600.png',
    model: 'XG-FCI-600',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FCI-600',
    productType: 'Air Terminal Unit',
    subtitle: 'Round Inlet',
  },
  {
    allowInsert: true,
    file: 'XG-FCI-600-ElectricHeat-RndInlet.zip',
    category: 'AirTerminalUnit',
    description: 'Fan-powered terminal units',
    image: 'XG-FCI-600.png',
    model: 'XG-FCI-600',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FCI-600',
    productType: 'Air Terminal Unit',
    subtitle: 'Round Inlet, Electric Heat',
  },
  {
    allowInsert: true,
    file: 'XG-FCI-600-HWHeat-RndInlet.zip',
    category: 'AirTerminalUnit',
    description: 'Fan-powered terminal units',
    image: 'XG-FCI-600.png',
    model: 'XG-FCI-600',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FCI-600',
    productType: 'Air Terminal Unit',
    subtitle: 'Round Inlet, HW Heat',
  },
  {
    allowInsert: true,
    file: 'XG-FVI-500-Basic.zip',
    category: 'AirTerminalUnit',
    description: 'Variable volume parallel fan-powered air terminal units',
    image: 'XG-FVI-500.png',
    model: 'XG-FVI-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FVI-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Basic',
  },
  {
    allowInsert: true,
    file: 'XG-FVI-500-ElectricHeat.zip',
    category: 'AirTerminalUnit',
    description: 'Variable volume parallel fan-powered air terminal units',
    image: 'XG-FVI-500.png',
    model: 'XG-FVI-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FVI-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Electric Heat',
  },
  {
    allowInsert: true,
    file: 'XG-FVI-500-HWHeatOnDischarge.zip',
    category: 'AirTerminalUnit',
    description: 'Variable volume parallel fan-powered air terminal units',
    image: 'XG-FVI-500.png',
    model: 'XG-FVI-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FVI-500',
    productType: 'Air Terminal Unit',
    subtitle: 'HW Heat on Discharge',
  },
{
    allowInsert: true,
    file: 'XG-FVI-500-HWHeatOnInduction.zip',
    category: 'AirTerminalUnit',
    description: 'Variable volume parallel fan-powered air terminal units',
    image: 'XG-FVI-500.png',
    model: 'XG-FVI-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-FVI-500',
    productType: 'Air Terminal Unit',
    subtitle: 'HW Heat on Induction',
  },
  {
    allowInsert: true,
    file: 'XG-H4002_LOD200.zip',
    category: 'Grilles',
    description:
      'Sidewall supply grille with individually adjustable horizontal single deflection blades.',
    image: 'XG-H4002.png',
    model: 'XG-H4002',
    modelGroup: 'Grilles',
    name: 'XG-H4002',
    productType: 'Grilles',
  },
  {
    allowInsert: true,
    file: 'XG-H4004_LOD200.zip',
    category: 'Grilles',
    description:
      'Double deflection sidewall supply grille with individually adjustable horizontal front and vertical rear blades.',
    image: 'XG-H4004.png',
    model: 'XG-H4004',
    modelGroup: 'Grilles',
    name: 'XG-H4004',
    productType: 'Grilles',
  },
  {
    allowInsert: true,
    file: 'XG-RH_LOD200.zip',
    category: 'Grilles',
    description: 'Aluminum Louvered Return Grille',
    image: 'XG-RH.png',
    model: 'XG-RH',
    modelGroup: 'Grilles',
    name: 'XG-RH',
    productType: 'Grilles',
  },
  {
    allowInsert: true,
    file: 'XG-RHE_LOD200.zip',
    category: 'Grilles',
    description: 'Aluminum Louvered Return and Exhaust Grille',
    image: 'XG-RH.png',
    model: 'XG-RHE',
    modelGroup: 'Grilles',
    name: 'XG-RHE',
    productType: 'Grilles',
  },
  {
    allowInsert: true,
    file: 'XG-SRH_LOD200.zip',
    category: 'Grilles',
    description: 'Steel Louvered Return Grille',
    image: 'XG-RH.png',
    model: 'XG-SRH',
    modelGroup: 'Grilles',
    name: 'XG-SRH',
    productType: 'Grilles',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-Basic-RectInlet-Return.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Rect Inlet, Return Air',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-Basic-RectInlet-Supply.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Rect Inlet, Supply Air',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-ElectricHeat-RectInlet.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Rectangular Inlet, Electric Heat',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-HWHeat-RectInlet.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Rectangular Inlet, HW Heat',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-ElectricHeat-RndInlet.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Round Inlet, Electric Heat',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-HWHeat-RndInlet.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Round Inlet, HW Heat',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-Basic-RndInlet-Return.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Round Inlet, Return Air',
  },
  {
    allowInsert: true,
    file: 'XG-TH-500-Basic-RndInlet-Supply.zip',
    category: 'AirTerminalUnit',
    description: 'VAV terminal unit',
    image: 'XG-TH-500.png',
    model: 'XG-TH-500',
    modelGroup: 'AirTerminalUnit',
    name: 'XG-TH-500',
    productType: 'Air Terminal Unit',
    subtitle: 'Round Inlet, Supply Air',
  },
  {
    allowInsert: true,
    file: 'XG-V4002_LOD200.zip',
    category: 'Grilles',
    description:
      'Sidewall supply grille with individually adjustable vertical single deflection blades.',
    image: 'XG-V4002.png',
    model: 'XG-V4002',
    modelGroup: 'Grilles',
    name: 'XG-V4002',
    productType: 'Grilles',
  },
  {
    allowInsert: true,
    file: 'XG-V4004_LOD200.zip',
    category: 'Grilles',
    description:
      'Double deflection sidewall supply grille with individually adjustable vertical front and horizontal rear blades.',
    image: 'XG-V4004.png',
    model: 'XG-V4004',
    modelGroup: 'Grilles',
    name: 'XG-V4004',
    productType: 'Grilles',
  },
];
