import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DocumentSelectorComponent } from '../components/document-selector/document-selector.component';
import { DocumentTypes } from '../enums/document-types.enum';

@Injectable()
export class DocumentSelector {
  constructor(private bottomSheet: MatBottomSheet) {}

  show(documentTypes?: DocumentTypes[]): Promise<DocumentTypes[]> {
    return new Promise<DocumentTypes[]>((results, reject) => {
      if (!documentTypes) {
        documentTypes = [
          DocumentTypes.capsExport,

          DocumentTypes.cutSheet,

          DocumentTypes.equipmentSchedule,

          DocumentTypes.revit
        ];
      }

      const sheetRef = this.bottomSheet.open(DocumentSelectorComponent, {
        data: documentTypes
      });

      const subscription = sheetRef.afterDismissed().subscribe(
        result => {
          results(result);
        },
        errorData => {
          reject(errorData);
        },
        () => {
          subscription.unsubscribe();
        }
      );
    });
  }
}
