import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { Subscription } from 'rxjs';
import { UserData } from '../../models/user-data.model';

@Component({
  selector: 'user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent implements OnInit, OnDestroy {
  iconName = 'person_outline';

  private currentUserSubscription: Subscription;

  constructor(public usersService: UsersService) {
    this.iconName = 'person_outline';

    this.usersService.getCurrentUser().then((userData) => {
      this.currentUser = userData;

      this.iconName = this.currentUser.authenticated
        ? 'person'
        : 'person_outline';
    });

    this.currentUserSubscription =
      this.usersService.currentUserUpdated.subscribe((data) => {
        this.currentUser = data;

        this.iconName = this.currentUser.authenticated
          ? 'person'
          : 'person_outline';
      });
  }

  preLoginMenu = [];
  postLoginMenu = [];

  currentUser: UserData;

  ngOnInit() {
    const userIcon = this;

    this.preLoginMenu = [
      {
        text: 'Sign In / Register',
        click: function () {
          userIcon.usersService.login();
        },
      },
    ];

    this.postLoginMenu = [
      {
        text: 'Log Out',
        click: function () {
          userIcon.usersService.logout();
        },
      },
    ];
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  getActiveMenu = function () {
    if (this.currentUser && this.currentUser.authenticated) {
      return this.postLoginMenu;
    } else {
      return this.preLoginMenu;
    }
  };
}
