import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LachesisService } from '../../analytics/services/lachesis.service';
import { BreadcrumbService, ICrumb } from '../../breadcrumb/breadcrumb.service';
import { ReleaseNotesDialogComponent } from '../../dialogs/release-notes-dialog/release-notes-dialog.component';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { ObserverService } from '../../ecaps-core/services/observer.service';
import { RevitCommsService } from '../../external-communication/services/revit-comms.service';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../google/services/google-analytics.service';
import { IInfoLink } from '../../products/models/product-groups.const';
import {
  ProductCategory,
  ProductGroup,
  ProductsService,
  ToolboxItem,
} from '../../products/services/products.service';
import { ProjectInfoDialog } from '../../projects/controllers/project-info-dialog.controller';
import { ProjectsService } from '../../projects/services/projects.service';
import { LayoutService } from '../../selections/services/layout.service';
import { MenuItemList } from '../../side-bar/services/constants/menu-item-list.const';
import { SideBarService } from '../../side-bar/services/side-bar.service';
import { SideDialogService } from '../../side-dialog/services/side-dialog.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LandingPageComponent implements OnInit, OnDestroy {
  private breadcrumbSub: Subscription;

  groups: ProductGroup[] = this.products.productGroups;
  displayColumns = 5;

  visibleGroups!: ProductGroup[];

  private _selectedGroup?: ProductGroup;
  public get selectedGroup(): ProductGroup | undefined {
    return this._selectedGroup;
  }
  public set selectedGroup(group: ProductGroup | undefined) {
    this.updateTracking(group);

    if (!!group && !(group.children || group.children)) {
      setTimeout(() => {
        this.runSelection(group);
      });

      this._selectedGroup = group.parent;
    } else {
      this._selectedGroup = group;
    }

    this.updateVisibleGroups();

    this.updateCategorySize();

    if (!!this._selectedGroup) {
      // Set crumb trail

      const crumbs: ICrumb[] = [];

      const getParentPath = (groupItem: ProductGroup) => {
        if (!!groupItem.parent) {
          getParentPath(groupItem.parent);
        }

        crumbs.push({
          text: groupItem.text,
          route: () => {
            this.groupSelect(groupItem);
          },
        });
      };

      getParentPath(this._selectedGroup);

      this.breadcrumbs.crumbs = crumbs;
    } else {
      this.breadcrumbs.crumbs = [];
    }
  }

  version: string = environment.version;
  currentYear: string = new Date().getFullYear().toString();

  toolboxItems!: ToolboxItem[];

  defaultToolboxItems: ToolboxItem[] = this.products.getToolboxItems([
    'creatingASchedule',
    'faq',
    'revitAddIn',
    'onlineFanCourses',
  ]);

  versionDate: string = environment.versionDate;

  copyrightBase: string = environment.copyrightBase;

  LS_LAST_GROUP = 'landingPageLastGroup';

  lastGroup: string;

  constructor(
    private products: ProductsService,
    private layout: LayoutService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private core: EcapsCore,
    private analytics: GoogleAnalyticsService,
    private sidebarService: SideBarService,
    private menuItemList: MenuItemList,
    private sideDialogService: SideDialogService,
    private projectsService: ProjectsService,
    private revitComms: RevitCommsService,
    private projectInfoDialog: ProjectInfoDialog,
    private lachesisService: LachesisService,
    private breadcrumbs: BreadcrumbService,
    private cd: ChangeDetectorRef,
    private observer: ObserverService
  ) {
    this.breadcrumbSub = this.breadcrumbs.crumbsUpdated.subscribe(() => {
      if (
        (!this.breadcrumbs.crumbs || this.breadcrumbs.crumbs.length === 0) &&
        !!this.selectedGroup
      ) {
        this.selectedGroup = undefined;

        this.cd.detectChanges();
      }
    });

    this.revitReselect();
    const publicToken =
      this.activatedRoute.snapshot.params.publicToken ||
      this.activatedRoute.snapshot.queryParams.pT;

    if (!!publicToken) {
      let loading = true;

      this.core.showLoadingGraphic('Loading...', () => loading);

      this.projectsService
        .getSharedProjectSummary(publicToken)
        .then((summary) => {
          this.projectInfoDialog.show(summary, publicToken);

          loading = false;

          this.core.hideLoadingGraphic();
        });
    }

    this.lastGroup = window.localStorage.getItem(this.LS_LAST_GROUP);

    let groupParam =
      this.activatedRoute.snapshot.queryParams.group ||
      this.activatedRoute.snapshot.params.selectedGroup;

    groupParam = groupParam === 'MakeUpAir' ? 'mua' : groupParam;

    // Set selected group based on URL/route parameters
    // Doing this before defaulting groupParam to lastGroup to avoid
    // taking user to last selected group
    // (We may want to change this so that the user goes to the last selected group, like they did with the LandingPageComponent)
    if (!!groupParam) {
      let groupParamTrimmed: string = groupParam.toLowerCase().trim();

      switch (groupParamTrimmed) {
        case 'doas': {
          groupParamTrimmed = 'outdoor-air';
          break;
        }
        case 'mua': {
          groupParamTrimmed = 'makeupair';
          break;
        }
      }

      const subCategory =
        this.activatedRoute.snapshot.params.selectedSubCategory;

      if (!!subCategory) {
        const parentGroup = this.products.findCategory(groupParamTrimmed);

        if (!!parentGroup) {
          const subCategoryGroup = parentGroup.children?.find(
            (child) => child.name === subCategory
          );

          if (!!subCategoryGroup) {
            this.selectedGroup = subCategoryGroup;
          }
        }
      } else {
        this.selectedGroup = this.products.findCategory(groupParamTrimmed);
      }
    }

    groupParam = groupParam || this.lastGroup;

    if (!!groupParam && groupParam.toLowerCase().trim() === 'doas') {
      groupParam = 'outdoor-air';
    } else if (!!groupParam && groupParam.toLowerCase().trim() === 'mua') {
      groupParam = 'make-up air';
    } else if (!groupParam) {
      groupParam = 'fans';
    }

    this.updateVisibleGroups();
  }

  ngOnInit() {
    if (
      window.location.pathname.indexOf('/revit/performance/reselect/') !== -1
    ) {
      this.core.showLoadingGraphic('Reselecting...', () => true);
    }

    this.sidebarService.setSidebarItems([
      {
        menuItems: [
          this.menuItemList.equipmentSchedule,
          this.menuItemList.jobs,
        ],
      },
    ]);

    Array.from(document.getElementsByClassName('logo')).forEach(
      (element: HTMLElement) => {
        element.addEventListener('click', () => {
          this.revitLogoClicked.call(this);
        });
      }
    );

    const groupsParent = document.querySelector('.groups-parent');

    if (!!groupsParent) {
      this.observer.observe(
        groupsParent as HTMLElement,
        'resize',
        (element, observing) => {
          if (!observing) {
            return false;
          }

          this.updateCategorySize();

          return true;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.breadcrumbSub.unsubscribe();

    const groupsParent = document.querySelector('.groups-parent');

    if (!!groupsParent) {
      this.observer.unobserve(groupsParent as HTMLElement, 'resize');
    }
  }

  toolboxItemClick(item: ToolboxItem) {
    const group =
      this.selectedGroup?.name !== undefined ? this.selectedGroup.name : 'Main';
    this.lachesisService.trackEvent({
      eventName: 'Toolbox',
      eventInfo: item.text,
      productGroup: group,
    });

    this.analytics.trackEvent_Old(
      EventCategories.toolboxItem,
      'Click',
      `${group} - ${item.text}`
    );

    this.analytics.trackEvent('Toolbox', {
      source: 'Toolbox Items List',
      action: item.text as any,
    });

    item.click.call(this);
  }

  groupSelect(group: ProductGroup) {
    if (!!group.click) {
      group.click.call(this);

      return;
    }

    if ('type' in group && this.selectedGroup?.name !== undefined) {
      const category = group as ProductCategory;
      const path: ProductGroup[] = [];

      const genPath = (groupItem: ProductGroup) => {
        if (!!groupItem.parent) {
          genPath(groupItem.parent);
        }

        path.push(groupItem);
      };

      genPath(group);

      const groupName = path[0].text;
      const catName = path[1].text;
      const subCatName: string = path.length === 3 ? path[2].text : undefined;

      this.lachesisService.trackEvent({
        eventName: 'Category',
        category: catName,
        productType: category.trackingType || category.type,
        productGroup: groupName,
        revitVersion: this.revitComms.isRevitApp
          ? this.revitComms.revitVersion
          : '',
        revitPluginVersion: this.revitComms.isRevitApp
          ? this.revitComms.revitPluginVersion
          : '',
        subCategory: subCatName,
      });

      this.analytics.trackEvent_Old(
        EventCategories.groupSelection,
        'Click',
        group.name
      );
    }

    this.selectedGroup = group;
  }

  openReleaseNotes() {
    this.analytics.trackEvent_Old(EventCategories.viewReleaseNotes, 'Click');

    this.sideDialogService.open(ReleaseNotesDialogComponent);
  }

  revitLogoClicked() {
    this.router.navigate(['/']);
  }

  revitReselect() {
    if (
      window.location.pathname.indexOf('/revit/performance/reselect/') === -1
    ) {
      return;
    }

    this.analytics.trackEvent_Old(EventCategories.revitAddin, 'Reselection');

    this.lachesisService.trackEvent({
      eventName: 'Plugin Reselection Event',
      revitVersion: this.revitComms.revitVersion,
      revitPluginVersion: this.revitComms.revitPluginVersion,
    });

    this.layout
      .revitReselection(this.revitComms.reselectStates)
      .catch((error) => {
        this.core.showMessageBox({
          title: 'Reselection Error',
          message:
            'Unable to reselect based on data from Revit. Please try again or start a new selection.',
          icon: 'error_outline',
        });
        this.core.hideLoadingGraphic(true);
      });
  }

  private runSelection(group: ProductGroup): void {
    if (!('type' in group)) {
      console.error(
        '[LandingPageComponent] runSelection(%o): Invalid category',
        group
      );

      return;
    }

    const category = group as ProductCategory;

    let selecting = true;

    this.core.showLoadingGraphic('Selecting...', () => selecting);

    const defaultAnswers: { [key: string]: any } = {};

    const processCategory = (cat: ProductCategory) => {
      if (!!cat.defaultAnswers) {
        Object.keys(cat.defaultAnswers).forEach((key) => {
          defaultAnswers[key] = cat.defaultAnswers[key];
        });
      }

      if (!!cat.parent) {
        processCategory(cat.parent as ProductCategory);
      }
    };

    processCategory(category);

    this.projectsService.getLocalProject().then((project) => {
      if (category.hasElevation && project.elevation > 0) {
        defaultAnswers.Elevation = project.elevation;
      }

      this.layout
        .createLayoutConfiguration(category.entity, category.bu, defaultAnswers)
        .then((results) => {
          setTimeout(() => {
            if (!!category.customRoute) {
              this.router.navigate([category.customRoute]);
            } else {
              this.router.navigate(['/selection']);
            }

            selecting = false;

            this.core.hideLoadingGraphic();
          });
        });
    });
  }

  updateCategorySize(): void {
    // Get the total number of items in the list
    const maxItems = this.visibleGroups.length;

    // Get the parent element dimensions and padding
    const groupParent = document.querySelector('.groups-parent');

    if (!groupParent) {
      /*
       The page isn't rendered yet.
       This method will fire again with the ngAfterViewChecked event.
      */

      return;
    }

    const padding = {
      top: !!groupParent
        ? parseInt(window.getComputedStyle(groupParent).paddingTop)
        : 24,
      bottom: !!groupParent
        ? parseInt(window.getComputedStyle(groupParent).paddingBottom)
        : 24,
      left: !!groupParent
        ? parseInt(window.getComputedStyle(groupParent).paddingLeft)
        : 24,
      right: !!groupParent
        ? parseInt(window.getComputedStyle(groupParent).paddingRight)
        : 24,
    };
    const parentWidth = groupParent.clientWidth - padding.left - padding.right;
    const parentHeight =
      groupParent.clientHeight - padding.top - padding.bottom;

    // Get the grid element gap
    const groupContent = groupParent.querySelector('.groups');
    const gap = groupContent
      ? parseInt(window.getComputedStyle(groupContent).gap) || 24 // Default to 24px if the gap is not defined
      : 24;

    // Calculate the number of columns that will fit in the parent element with a minimum of 4 columns
    let columns = 3;
    let totalHeight = 0;

    do {
      columns++;

      // Calculate the number of rows necessary based on the number of columns
      const rows = Math.ceil(maxItems / columns);

      // Each item is a square and the width is based on the parent width and the number of columns
      const height = (parentWidth - (columns - 1) * gap) / columns;

      // Calculate the total height of the grid given the number of rows and the height of each item
      totalHeight = height * rows + (rows - 1) * gap;
    } while (totalHeight > parentHeight && columns < maxItems); // Keep increasing the number of columns until the total height is less than or equal to the parent height or the number of columns is greater than or equal to the number of items in the list

    // Only update the number of columns if it has changed
    if (columns !== this.displayColumns) {
      this.displayColumns = columns;

      this.cd.detectChanges();
    }
  }

  infoClick(event: MouseEvent, group: ProductGroup): void {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.cancelBubble = true;
    const link: IInfoLink = (group as any).infoLink;
    if (!link.sameWindow) {
      window.open(link.url, '_new');
    } else {
      window.open(link.url, '_self');
    }
  }

  private updateTracking(group: ProductGroup | undefined): void {
    const path: string[] = [];

    const genPath = (groupItem: ProductGroup) => {
      if (!!groupItem.parent) {
        genPath(groupItem.parent);
      }

      path.push(groupItem.text);
    };

    if (!!group) {
      genPath(group);

      this.analytics.productGroup = path[0];
      this.analytics.category = path.slice(1).join(' > ');
      this.analytics.productType =
        (group as ProductCategory).type ||
        (group as ProductCategory).trackingType;

      this.analytics.trackEvent(EventCategories.groupSelection);
    }
  }

  private updateVisibleGroups(): void {
    this.visibleGroups = (this._selectedGroup?.children ?? this.groups).filter(
      (group) => {
        if (environment.production) {
          if (this.revitComms.isRevitApp) {
            return group.visible && group.revitVisible;
          } else {
            return group.visible;
          }
        } else {
          return true;
        }
      }
    );

    this.updateToolboxItems();
  }

  private updateToolboxItems(): void {
    if (!this._selectedGroup) {
      this.toolboxItems = this.defaultToolboxItems.filter((item) => {
        if (environment.production) {
          if (this.revitComms.isRevitApp) {
            return item.visible && item.revitVisible !== false;
          } else {
            return item.visible;
          }
        } else {
          return true;
        }
      });
    } else {
      const getToolboxParent = (child: ProductGroup): ProductGroup => {
        if (!!child.toolboxItems) {
          return child;
        } else {
          return getToolboxParent(child.parent);
        }
      };

      const parent = getToolboxParent(this.selectedGroup);

      const visibleToolboxItems = (
        !!parent.toolboxItems && parent.toolboxItems.length > 0
          ? parent.toolboxItems
          : this.defaultToolboxItems
      ).filter((item) => {
        if (environment.production) {
          if (this.revitComms.isRevitApp) {
            return item.visible && item.revitVisible !== false;
          } else {
            return item.visible;
          }
        } else {
          return true;
        }
      });

      this.toolboxItems = visibleToolboxItems;
    }
  }
}
