import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { ColumnList } from '../constants/column-list.const';
import { ProductTypes } from '../../../../products/enums/product-types.enum';

@Component({
  selector: 'doas-equipment-schedule-grid',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DoasEquipmentScheduleGridComponent
  extends BaseGridComponent
  implements OnInit
{
  constructor(protected injector: Injector) {
    super(injector);

    this.productType = ProductTypes.temperedAirProduct;
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'model',
      'enthalpyRecoveryRatio',
      'coolingDBWB',
      'coolingCap',
      'heatingLat',
      'heatingCap',
      'mcaMop',
      'weight',
      'dimensions',
      'supplyOutletdBA',
      'supplyOutletSones',
      'exhaustInletSoundDba',
      'exhaustFanInletSoundSones',
      'ashpDeratedLeavingAirDryBulbHeat',
      'ashpDeratedHeatingCapacity',
      'erMedia',
      'erDevice'
    );

    this.setVisibleColumns([
      'model',
      'enthalpyRecoveryRatio',
      'coolingDBWB',
      'coolingCap',
      'heatingLat',
      'heatingCap',
      'mcaMop',
      'weight',
      'dimensions',
    ]);
  }
}
