<side-dialog-title
  >Louver Standards, Certifications, & Licenses</side-dialog-title
>

<side-dialog-content>
  <div class="parent-container">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="selection">
        <th mat-header-cell *matHeaderCellDef>Selection</th>
        <td mat-cell *matCellDef="let element">{{ element.selection }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="['selection', 'description']; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['selection', 'description']"
      ></tr>
    </table>
  </div>
</side-dialog-content>

<side-dialog-actions>
  <button mat-raised-button color="primary" (click)="closeClick()">
    Close
  </button>
</side-dialog-actions>
