import { NgModule, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterModule,
  RouterStateSnapshot,
} from '@angular/router';
import { ICrumb } from './breadcrumb/breadcrumb.service';
import { GoogleAnalyticsService } from './google/services/google-analytics.service';
import { JobManagementPageGuard } from './guards/job-management-page.guard';
import { MaintenancePageGuard } from './guards/maintenance-page.guard';
import { RevitAppGuard } from './guards/revit-app.guard';
import { SelectionPageGuard } from './guards/selection-page.guard';
import { EquipmentSchedulePageComponent } from './pages/equipment-schedule-page/equipment-schedule-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { GraphicalSelectionPageComponent } from './pages/graphical-selection-page/graphical-selection-page.component';
import { JobManagementPageComponent } from './pages/job-management-page/job-management-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MaintenancePageComponent } from './pages/maintenance-page/maintenance-page.component';
import { ModelSelectionPageComponent } from './pages/model-selection-page/model-selection-page.component';
import { NoopPageComponent } from './pages/noop-page/noop-page.component';
import { OutdoorAirAccessoriesPageComponent } from './pages/outdoor-air-accessories-page/outdoor-air-accessories-page.component';
import { PlenumRatingPageComponent } from './pages/plenum-rating-page/plenum-rating-page.component';
import { RevitJobMarksPageComponent } from './pages/revit-job-marks-page/revit-job-marks-page.component';
import { RevitJobsPageComponent } from './pages/revit-jobs-page/revit-jobs-page.component';
import { SelectionPageComponent } from './pages/selection-page/selection-page.component';

export type PageTitles =
  | 'Equipment Schedule'
  | 'FAQs'
  | 'Graphical Selection'
  | 'Job Management'
  | 'Landing Page'
  | 'Maintenance Page'
  | 'Open Shared Job Page'
  | 'Outdoor Air Accessories'
  | 'Plenum Rating'
  | 'Revit Jobs'
  | 'Revit Job Marks'
  | 'Revit Login'
  | 'Revit Models'
  | 'Revit Performance'
  | 'Selection Page'
  | 'Sub-Category Page';

/**
 * @description Interface for route data that can be used to configure analytics tracking
 */
export interface IRouteAnalyticsData {
  /**
   * @description The title of the page
   */
  title?: PageTitles;
  /**
   * @description The product group of the page
   */
  productGroup?: string;
  /**
   * @description The product type of the page
   */
  productType?: string;
  /**
   * @description The category of the page
   */
  category?: string;
  /**
   * @description Track page hits with page_view
   * @default true
   */
  trackPage?: boolean;
}

export interface IRouteData {
  /**
   * @description The analytics data for the route
   */
  analytics?: IRouteAnalyticsData;
  /**
   * @description The breadcrumbs for the route
   */
  breadcrumbs?: ICrumb[];
  [key: string]: any;
}

export interface IRoute extends Route {
  data?: IRouteData;
}

/**
 * A CanActivateFn which tracks direct links to the site and redirects to the new link if necessary
 * @param {ActivatedRouteSnapshot} route The route snapshot
 * @param {RouterStateSnapshot} state The router state snapshot
 * @returns {boolean | UrlTree} True if the route is not a legacy link, otherwise a UrlTree to the new link
 */
const directLinkTracker = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const analytics = inject(GoogleAnalyticsService);
  const navUrl = state.url.toLowerCase().trim();
  const legacyLink = !navUrl.startsWith('/group/');
  const newNavUrl = '/group/' + navUrl.split('/').slice(2).join('/');
  const currentUrl = window.location.pathname.toLowerCase().trim();

  if (navUrl === currentUrl)
    analytics.trackEvent('Navigation', {
      source: 'Direct Link',
      action: navUrl as any,
    });

  if (legacyLink)
    console.debug(
      '[Direct Link] Redirecting to new link: %s -> %s',
      navUrl,
      newNavUrl
    );

  return !legacyLink ? true : router.createUrlTree([newNavUrl]);
};

const routes: IRoute[] = [
  {
    path: '',
    component: LandingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Landing Page',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
      breadcrumbs: [] as ICrumb[],
    },
  },
  {
    path: 'shared/:publicToken',
    component: LandingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Open Shared Job Page',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'catselect/:catName',
    children: [
      {
        path: '',
        canActivate: [MaintenancePageGuard, directLinkTracker],
        children: [],
      },
      {
        path: ':subCat',
        canActivate: [MaintenancePageGuard, directLinkTracker],
        children: [],
      },
    ],
    data: {
      analytics: {
        title: 'Landing Page',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'groupsCategory/:selectedGroup',
    canActivate: [MaintenancePageGuard, directLinkTracker],
    children: [],
    data: {
      analytics: { title: 'Landing Page' },
    },
  },
  {
    path: 'group/:selectedGroup',
    canActivate: [MaintenancePageGuard],
    children: [
      {
        path: '',
        component: LandingPageComponent,
        canActivate: [directLinkTracker],
      },
      {
        path: ':selectedSubCategory',
        component: LandingPageComponent,
        canActivate: [directLinkTracker],
      },
    ],
    data: {
      analytics: { title: 'Landing Page' },
    },
  },
  {
    path: 'selection',
    component: SelectionPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: { title: 'Selection Page' },
      breadcrumbs: [] as ICrumb[],
    },
  },
  {
    path: 'subcategory/:categoryName',
    canActivate: [MaintenancePageGuard, directLinkTracker],
    children: [],
    data: {
      analytics: { title: 'Sub-Category Page' },
    },
  },
  {
    path: 'faqs',
    component: FaqPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'FAQs',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
      breadcrumbs: [
        {
          text: 'Get the Most Out of eCAPS',
          route: 'faqs',
        },
      ] as ICrumb[],
    },
  },
  {
    path: 'graphicalselection',
    component: GraphicalSelectionPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: { title: 'Graphical Selection' },
      breadcrumbs: [] as ICrumb[],
    },
  },
  {
    path: 'equipmentschedule',
    component: EquipmentSchedulePageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Equipment Schedule',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
      breadcrumbs: [
        {
          text: 'Equipment Schedule',
          route: '/equipmentschedule',
        },
      ] as ICrumb[],
    },
  },
  {
    path: 'equipmentschedule/:productType',
    component: EquipmentSchedulePageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Equipment Schedule',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'jobmanagement',
    component: JobManagementPageComponent,
    canActivate: [
      SelectionPageGuard,
      JobManagementPageGuard,
      MaintenancePageGuard,
    ],
    data: {
      analytics: {
        title: 'Job Management',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
      breadcrumbs: [
        {
          text: 'Job Management',
          route: '/jobmanagement',
        },
      ] as ICrumb[],
    },
  },
  {
    path: 'outdoorairaccessories',
    component: OutdoorAirAccessoriesPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Outdoor Air Accessories',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'revit/noop',
    component: NoopPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
  },
  {
    path: 'revit/login/:revitVersion/:pluginVersion',
    component: LoginPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: {
        title: 'Revit Login',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'plenumrating',
    component: PlenumRatingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: { title: 'Plenum Rating' },
    },
  },
  {
    path: 'sign-in/cb',
    component: LandingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Landing Page',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
        trackPage: false,
      },
    },
  },
  {
    path: 'sign-out/cb',
    component: LandingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Landing Page',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
        trackPage: false,
      },
    },
  },
  {
    path: 'revit/performance/:revitVersion',
    component: LandingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: { title: 'Revit Performance' },
    },
  },
  {
    path: 'revit/models/:revitVersion',
    component: ModelSelectionPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: { title: 'Revit Models' },
    },
  },
  {
    path: 'revit/performance/:revitVersion/:pluginVersion',
    component: LandingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: { title: 'Revit Performance' },
    },
  },
  {
    path: 'revit/performance/reselect/:revitVersion/:pluginVersion',
    component: LandingPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: { title: 'Revit Performance' },
    },
  },
  {
    path: 'revit/models/:revitVersion/:pluginVersion',
    component: ModelSelectionPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: {
        title: 'Revit Models',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'revit/jobs/:revitVersion/:pluginVersion',
    component: RevitJobsPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: {
        title: 'Revit Jobs',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'revit/jobs/:jobId/:revitVersion/:pluginVersion',
    component: RevitJobMarksPageComponent,
    canActivate: [SelectionPageGuard, MaintenancePageGuard, RevitAppGuard],
    data: {
      analytics: {
        title: 'Revit Job Marks',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: 'maintenance',
    component: MaintenancePageComponent,
    canActivate: [MaintenancePageGuard],
    data: {
      analytics: {
        title: 'Maintenance Page',
        productGroup: undefined,
        productType: undefined,
        category: undefined,
      },
    },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [SelectionPageGuard, JobManagementPageGuard],
})
export class AppRoutingModule {}
