import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TextChangedEvent } from '../../../../ecaps-core/components/inline-editor/models/text-changed-event.model';
import { EcapsCore } from '../../../../ecaps-core/controllers/ecaps-core.controller';
import { ZeroToDashPipe } from '../../../../ecaps-core/pipes/custom-pipes.pipe';
import { ProductInfoDialog } from '../../../../products/controllers/product-info-dialog.controller';
import { ProductEntities } from '../../../../products/enums/product-entities.enum';
import { ProductTypes } from '../../../../products/enums/product-types.enum';
import { ProductCategory } from '../../../../products/services/products.service';
import { CustomizeGridDialogComponent } from '../../../../selections/dialogs/customize-grid-dialog/customize-grid-dialog.component';
import { IGridColumn } from '../../../../selections/grids/models/grid-column.interface';
import { LayoutService } from '../../../../selections/services/layout.service';
import { SideDialogService } from '../../../../side-dialog/services/side-dialog.service';
import { Users } from '../../../../users/controllers/users.controller';
import { INote } from '../../../../users/models/note.interface';
import { UserNotes } from '../../../../users/models/user-notes.model';
import { UsersService } from '../../../../users/services/users.service';
import { ReselectRequiredDialogComponent } from '../../../dialogs/reselect-required-dialog/reselect-required-dialog.component';
import { ProjectRevisionUpdateTypes } from '../../../enums/project-revision-update-types.enum';
import { IProjectEvent } from '../../../models/i-project-event.interface';
import { Item } from '../../../models/project-item.model';
import { Note } from '../../../models/project-note.model';
import { Project } from '../../../models/project.model';
import { ProjectsService } from '../../../services/projects.service';

interface INoteItem extends INote {
  index: number;
  defaultNote: boolean;
}

interface INoteMenuItem extends INoteItem {
  checked: boolean;
}

@Component({
  selector: 'app-base-grid',
  templateUrl: './base-grid.component.html',
  styleUrls: ['./base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BaseGridComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('noteListMenu') private noteListMenu: MatSelectionList;

  @ViewChild(MatSort, { static: true })
  private matSort: MatSort;

  sortKey = 'tag';
  sortDir = 'asc';

  @Input('showCustomize') public showCustomize: boolean;

  @Output('selectionChanged') public selectionChanged =
    new EventEmitter<void>();

  @ViewChild('selectAllCheckbox', { static: true })
  private selectAllCheckbox: MatCheckbox;

  private _localProject: Project;
  private _localProjectUpdated: Subscription;
  private _localProjectChanged: Subscription;
  protected productType: ProductTypes = ProductTypes.fan;

  visibleColumns: Array<string>;

  visibleColumnsReset: Array<string>;

  columnList: Array<IGridColumn>;

  private columnsRestored = false;

  dataSource = new MatTableDataSource<any>([]);

  private _sizeUpdateSubscriptions: Subscription[] = [];

  private _userUpdatedSubscription: Subscription;

  userNotes: INoteItem[] = [];
  notesMenuList: INoteMenuItem[] = [];
  private _userNotesSubscription: Subscription;

  newNoteForm: UntypedFormGroup;
  newNote: UntypedFormControl;

  private _noteEditorItem: Item;
  private _noteMenuTrigger: MatMenuTrigger;

  private projectsService: ProjectsService;
  private sideDialogService: SideDialogService;
  private productInfoDialog: ProductInfoDialog;
  private usersService: UsersService;
  private users: Users;
  private core: EcapsCore;
  private dialog: MatDialog;
  private layoutService: LayoutService;
  private router: Router;

  constructor(protected injector: Injector) {
    this.projectsService = injector.get(ProjectsService);
    this.sideDialogService = injector.get(SideDialogService);
    this.productInfoDialog = injector.get(ProductInfoDialog);
    this.usersService = injector.get(UsersService);
    this.users = injector.get(Users);
    this.core = injector.get(EcapsCore);
    this.dialog = injector.get(MatDialog);
    this.layoutService = injector.get(LayoutService);
    this.router = injector.get(Router);

    this.projectsService.getLocalProject().then((project) => {
      this._localProject = project;

      this.updateProjectListeners();
    });

    this._localProjectChanged =
      this.projectsService.localProjectUpdated.subscribe((project) => {
        this._localProject = project;

        this.updateProjectListeners();
      });

    this.projectsService.clearSelectedProjectItems();

    this._userUpdatedSubscription =
      this.usersService.currentUserUpdated.subscribe(() => {
        this.usersService.getNotes(true).then((notes) => {
          this.updateNotes(notes);
        });
      });

    this.usersService.getNotes().then((notes) => {
      this.updateNotes(notes);
    });

    this._userNotesSubscription = this.usersService.userNotesUpdated.subscribe(
      (notes) => {
        this.updateNotes(notes);
      }
    );

    this.newNote = new UntypedFormControl('');

    this.newNoteForm = new UntypedFormGroup({
      newNote: this.newNote,
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    this.dataSource.sort = this.matSort;

    if (!!this.productType) {
      this.loadVisibleColumns();
    }

    this.getDataSourceRows();
  }

  ngOnDestroy() {
    this._sizeUpdateSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    this.projectsService.clearSelectedProjectItems();

    this._userUpdatedSubscription.unsubscribe();

    this._userNotesSubscription.unsubscribe();

    this._localProjectUpdated.unsubscribe();

    this._localProjectChanged.unsubscribe();
  }

  sortChange() {
    const newList = new Array<Item>();

    this.dataSource.sortData(this.dataSource.data, this.matSort).map((row) => {
      newList.push(<Item>row.itemData);
    });

    this.projectsService.setSortedProjectItems(this.productType, newList);
  }

  private updateProjectListeners() {
    if (!!this._localProjectUpdated) {
      this._localProjectUpdated.unsubscribe();
    }

    if (!!this._localProject) {
      this._localProjectUpdated = this._localProject.updated.subscribe(
        (data: IProjectEvent) => {
          if (
            data.type === ProjectRevisionUpdateTypes.itemAdded ||
            data.type === ProjectRevisionUpdateTypes.itemUpdated ||
            data.type === ProjectRevisionUpdateTypes.itemDeleted
          ) {
            this.getDataSourceRows();

            if (!!this.selectAllCheckbox) {
              this.selectAllCheckbox.checked = false;
            }
          }
        }
      );

      this.getDataSourceRows();
    }
  }

  private updateNotes(notes: UserNotes) {
    const newNotes: INoteItem[] = notes.defaultNotes
      .filter(
        (note) =>
          note.productType.toLowerCase().trim() ===
          this.productType.toLowerCase().trim()
      )
      .map((note, index) => {
        return {
          ...note,
          index,
          defaultNote: true,
        } as INoteItem;
      });

    newNotes.push(
      ...notes.customNotes
        .filter(
          (note) =>
            note.productType.toLowerCase().trim() ===
            this.productType.toLowerCase().trim()
        )
        .map((note, index) => {
          return {
            ...note,
            index: newNotes.length + index,
            defaultNote: false,
          } as INoteItem;
        })
    );

    this.userNotes = newNotes;

    this.notesMenuList = this.userNotes
      .filter((note) => note.id !== -1)
      .map((note) => {
        return {
          ...note,
          checked: false,
        };
      });
  }

  addNewNote() {
    const value = this.newNote.value.trim();

    this.newNote.setValue('');

    if (value !== '') {
      this.users.showLoginRequiredDialog(false).then((result) => {
        if (result) {
          let adding = true;

          this.core.showLoadingGraphic('Adding Note...', () => adding);

          this.usersService.addNote(this.productType, value).then((newNote) => {
            adding = false;

            this.core.hideLoadingGraphic();
          });
        }
      });
    }
  }

  customNoteEdited($event: TextChangedEvent, note: INoteItem) {
    let updating = true;

    this.core.showLoadingGraphic('Updating Note...', function () {
      return updating;
    });

    this.usersService.updateNote(note, $event.newText).then((success) => {
      updating = false;

      this.core.hideLoadingGraphic();
    });
  }

  deleteCustomNote(note: INoteItem) {
    let deleting = true;

    this.core.showLoadingGraphic('Deleting Note...', function () {
      return deleting;
    });

    this.usersService.deleteNote(note).then((success) => {
      deleting = false;

      this.core.hideLoadingGraphic();
    });
  }

  private updateItemNotes() {
    const selectedNotes = this.noteListMenu.selectedOptions.selected.map(
      (o) => {
        return o.value as INoteMenuItem;
      }
    );

    const newNotes: INoteMenuItem[] = selectedNotes.filter(
      (note) =>
        this._noteEditorItem.notes.findIndex(
          (itemNote) => itemNote.content === note.content
        ) === -1
    );

    const removedNotes: Note[] = this._noteEditorItem.notes.filter(
      (note) =>
        selectedNotes.findIndex(
          (noteItem) => noteItem.content === note.content
        ) === -1
    );

    if (removedNotes.length > 0 || newNotes.length > 0) {
      let updating = true;

      this.core.showLoadingGraphic('Updating Notes...', () => updating);

      const self = this;

      const removeNotes = () => {
        if (removedNotes.length > 0) {
          self.projectsService
            .deleteRevisionItemNote(self._noteEditorItem, removedNotes)
            .then(() => {
              addNotes();
            });
        } else {
          addNotes();
        }
      };

      const addNotes = () => {
        if (newNotes.length > 0) {
          self.projectsService
            .addRevisionItemNoteByContent(
              self._noteEditorItem,
              newNotes.map((note) => note.content)
            )
            .then(() => {
              finishUpdate();
            });
        } else {
          finishUpdate();
        }
      };

      const finishUpdate = () => {
        updating = false;

        self.core.hideLoadingGraphic();
      };

      removeNotes();
    }
  }

  itemNoteOKClick() {
    this.updateItemNotes();

    this._noteMenuTrigger.closeMenu();
  }

  itemNoteClearAllClick() {
    this.noteListMenu.deselectAll();
  }

  editItemNotes(menuTrigger: MatMenuTrigger, itemData: Item) {
    this._noteMenuTrigger = menuTrigger;

    this._noteEditorItem = itemData;

    this.notesMenuList.forEach((note) => {
      note.checked =
        itemData.notes.findIndex(
          (itemNote) => itemNote.content === note.content
        ) > -1;
    });
  }

  getItemNoteText(itemData: Item): string {
    if (itemData.notes.length === 0) {
      return 'Click to Add';
    } else {
      const noteList = [];

      itemData.notes.forEach((itemNote) => {
        const note = this.userNotes.find(
          (userNote) => userNote.content === itemNote.content
        );

        if (!!note) {
          noteList.push(note);
        }
      });

      if (noteList.length > 0) {
        noteList.sort((a: INoteItem, b: INoteItem) => a.index - b.index);

        const indexList = [];

        noteList.forEach((note) => {
          indexList.push(note.index + 1);
        });

        return indexList.join(', ');
      } else {
        return 'Click to Add';
      }
    }
  }

  protected setVisibleColumns(columns: Array<string>) {
    if (!this.columnsRestored) {
      this.visibleColumns = ['selector', 'tag'].concat(columns);

      this.visibleColumns.push('notes');
    }

    this.visibleColumnsReset = [].concat(columns);

    this.projectsService.setEquipmentScheduleGridDefinitions(
      this.productType,
      this.visibleColumns
    );

    this.loadVisibleColumns();
  }

  protected loadVisibleColumns() {
    const rawJSON = window.localStorage.getItem(
      `${this.productType}_EquipmentSchedule`
    );

    if (!!rawJSON && !!this.columnList && this.columnList.length > 0) {
      const savedColumns = <Array<string>>JSON.parse(rawJSON);

      const invalidColumns =
        savedColumns.findIndex(
          (key) => this.columnList.findIndex((item) => item.key === key) === -1
        ) !== -1;

      if (!invalidColumns) {
        this.visibleColumns = ['selector', 'tag'].concat(savedColumns);

        this.visibleColumns.push('notes');

        this.projectsService.setEquipmentScheduleGridDefinitions(
          this.productType,
          this.visibleColumns
        );

        this.columnsRestored = true;
      } else {
        // Remove invalid saved column list.
        window.localStorage.removeItem(`${this.productType}_EquipmentSchedule`);
      }
    }
  }

  protected saveVisibleColumns() {
    const newColumns = [].concat(this.visibleColumns);

    newColumns.splice(
      newColumns.findIndex((item) => item === 'selector'),
      1
    );
    newColumns.splice(
      newColumns.findIndex((item) => item === 'tag'),
      1
    );
    newColumns.splice(
      newColumns.findIndex((item) => item === 'notes'),
      1
    );

    window.localStorage.setItem(
      `${this.productType}_EquipmentSchedule`,
      JSON.stringify(newColumns)
    );

    this.projectsService.setEquipmentScheduleGridDefinitions(
      this.productType,
      this.visibleColumns
    );
  }

  customize() {
    const columnList = [].concat(this.visibleColumns);

    columnList.splice(
      columnList.findIndex((column) => column === 'selector'),
      1
    );
    columnList.splice(
      columnList.findIndex((column) => column === 'tag'),
      1
    );
    columnList.splice(
      columnList.findIndex((column) => column === 'notes'),
      1
    );

    const dialogRef = this.sideDialogService.open(
      CustomizeGridDialogComponent,
      {
        disableClose: false,
        autoFocus: true,
        data: {
          columnList: this.columnList,
          visibleColumns: columnList,
          visibleColumnsReset: this.visibleColumnsReset,
        },
      }
    );

    const subscription = dialogRef.beforeClose.subscribe(
      (newVisible: Array<string>) => {
        subscription.unsubscribe();

        if (!!newVisible && newVisible.length > 0) {
          ['selector', 'tag', 'notes'].forEach((key) => {
            const index = newVisible.findIndex((item) => item === key);

            if (index > -1) {
              newVisible.splice(index, 1);
            }
          });

          this.visibleColumns = ['selector', 'tag'].concat(newVisible);

          this.visibleColumns.push('notes');
        }

        this.saveVisibleColumns();
      }
    );
  }
  //TODO get revit object
  tagClick(itemData: Item) {
    this.productInfoDialog.show(
      itemData.sizeData,
      null,
      itemData,
      itemData.tag,
      itemData.quantity
    );
  }

  selectAllChange($event: MatCheckboxChange) {
    this.dataSource.data.forEach((dataRow: any) => {
      if ($event.checked) {
        this.projectsService.selectProjectItem(dataRow.itemData);
      } else {
        this.projectsService.unselectProjectItem(dataRow.itemData);
      }
    });

    this.selectionChanged.next();
  }

  rowSelect($event: MatCheckboxChange, itemData: Item) {
    if (!$event.checked) {
      if (!!this.selectAllCheckbox) {
        this.selectAllCheckbox.checked = false;
      }

      this.projectsService.unselectProjectItem(itemData);
    } else {
      this.projectsService.selectProjectItem(itemData);
    }

    this.selectionChanged.next();
  }

  rowSelected(itemData: Item): boolean {
    return (
      this.projectsService
        .getSelectedProjectItems()
        .findIndex((selectedItem: Item) => selectedItem.id === itemData.id) > -1
    );
  }

  getSelectedItems(): Array<Item> {
    const output = new Array<Item>();

    this.dataSource.data
      .filter((item: any) => item.selected)
      .forEach((item: any) => {
        output.push(item.itemData);
      });

    return output;
  }

  private getDataSourceRows() {
    const ds = [];

    this._sizeUpdateSubscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });

    if (!!this._localProject && !!this.productType) {
      this._localProject
        .getActiveRevision()
        .items.filter(
          (revItem) =>
            revItem.type.toLowerCase().trim() ===
            this.productType.toLowerCase().trim()
        )
        .forEach((item: Item) => {
          const row = this.getDataSourceRow(item);

          ds.push(row);

          this._sizeUpdateSubscriptions.push(
            item.updated.subscribe(() => {
              const newRow = this.getDataSourceRow(item);

              Object.keys(row).forEach((key) => {
                row[key] = newRow[key];
              });
            })
          );
        });
    }

    this.dataSource.data = ds;

    this.sortChange();
  }

  getDataSourceRow(item: Item) {
    const zeroDash = new ZeroToDashPipe();

    const getConfigValue = (
      itemData: Item,
      key: string,
      selection?: boolean
    ) => {
      const question =
        selection === true
          ? itemData.sizeData.selectionLayoutConfig.getQuestion(key)
          : itemData.sizeData.layoutConfig.getQuestion(key);

      if (!!question) {
        return question.value;
      } else {
        return null;
      }
    };

    const row: any = {
      selected: false,
      tag: item.tag,
      model: item.name,
      location: item.location,
      areaServed: item.areaServed,
      driveType: item.sizeData['outputs']['driveType'],
      application: getConfigValue(item, 'Application'),
      operatingVolume: item.sizeData['outputs']['operatingVolume'],
      totalStaticPressure: item.sizeData['outputs']['operatingStaticPressure'],
      rpm: item.sizeData['outputs']['speed'],
      bhp: item.sizeData['outputs']['operatingPower'],
      motorHP: getConfigValue(item, 'MotorHorsePower'),
      motorQty: getConfigValue(item, 'SupplyBlowerQuantity'),
      vcp: `${getConfigValue(item, 'Voltage')}/${getConfigValue(
        item,
        'PowerFrequency'
      )}/${getConfigValue(item, 'MotorPhase')}`,
      type: item.type,
      esp: getConfigValue(item, 'StaticPressure', true),
      maxRpm: item.sizeData['outputs']['speedMax'],
      quantity: item.quantity,
      modelGroup: item.sizeData.selectionLayoutConfig.modelGroup,
      dischargePosition: getConfigValue(item, 'DischargePosition'),
      arrangement: getConfigValue(item, 'Arrangement'),
      watts: item.sizeData['outputs']['watts'],
      feg: item.sizeData['outputs']['feg'],
      fei: getConfigValue(item, 'CalcFanEnergyIndexProduct'),
      peakTE: item.sizeData['outputs']['peakTotalEfficiency'],
      operatingTE: item.sizeData['outputs']['totalEfficiency'],
      ptsPeakTE: item.sizeData['outputs']['pointsWithinPeakTotalEfficiency'],
      plumeHeight: item.sizeData['outputs']['plumeHeight'],
      dilutionRatio: item.sizeData['outputs']['dilutionRatio'],
      nozzleOutletVelocity: item.sizeData['outputs']['nozzleOutletVelocity'],
      nozzleSize: item.sizeData['outputs']['nozzleSize'],
      fullLoadAmps: getConfigValue(item, 'FullLoadAmps'),
      mca: getConfigValue(item, 'CalcMinimumCircuitAmps'),
      mop: getConfigValue(item, 'CalcMaximumOvercurrentProtection'),
      weight: item.sizeData['pricing']['weight'],
      enclosure: getConfigValue(item, 'MotorEnclosure'),
      actualThroatVelocity: item.sizeData['outputs']['actualThroatVelocity'],
      actualThroatSize: `${item.sizeData['outputs']['actualThroatWidth']} x ${item.sizeData['outputs']['actualThroatLength']}`,
      suggestedWidth: item.sizeData['outputs']['suggestedWidth'],
      suggestedHeight: item.sizeData['outputs']['suggestedHeight'],
      actualDepth: item.sizeData['outputs']['actualDepth'],
      sectionsWide: item.sizeData['outputs']['sectionsWide'],
      sectionsHigh: item.sizeData['outputs']['sectionsHigh'],
      actualVolume: item.sizeData['outputs']['actualVolume'],
      actualPressureDrop: item.sizeData['outputs']['actualPressureDrop'],
      actualVelocity: item.sizeData['outputs']['actualVelocity'],
      actualFreeArea: item.sizeData['outputs']['actualFreeArea'],
      freeAreaPercentage: item.sizeData['outputs']['freeAreaPercentage'],
      maxVelocity: item.sizeData['outputs']['maxVelocity'],
      actualThroatArea: item.sizeData['outputs']['actualThroatArea'],
      hoodWidth: getConfigValue(item, 'HoodWidth'),
      hoodLength: getConfigValue(item, 'HoodLength'),
      overallHeight: getConfigValue(item, 'OverallHeight'),
      wheelDiameter: item.sizeData['outputs']['wheelDiameter'],
      wheelRotation: getConfigValue(item, 'WheelRotation'),
      outletVelocity: item.sizeData['outputs']['outletVelocity'],
      roofOpeningLW: `${item.sizeData['outputs']['roofOpeningLength']} x ${item.sizeData['outputs']['roofOpeningWidth']}`,
      wallOpening: item.sizeData['outputs']['wallOpening'],
      operatingFrequency: item.sizeData['outputs']['operatingFrequency'],
      speedNonAdjusted: item.sizeData['outputs']['speedNonAdjusted'],
      tipSpeed: item.sizeData['outputs']['tipSpeed'],
      dimensionsLWH: `${item.sizeData['outputs']['overallLength']} x ${item.sizeData['outputs']['overallWidth']}
       x ${item.sizeData['outputs']['overallHeight']}`,
      fanQuantity: item.sizeData['outputs']['fanQuantity'],
      fanSeries: item.sizeData['outputs']['constructionType'],
      averageAirSpeed: item.sizeData['outputs']['averageAirSpeed'],
      avgAirSpeedMax: item.sizeData['outputs']['maximumAverageAirSpeed'],
      operatingFanRPM: item.sizeData['outputs']['speed'],
      motorSize: item.sizeData['outputs']['minimumMotorSizeNEMA'],
      motorSizeHP: item.sizeData['outputs']['motorSize'],
      motorFLACalc: getConfigValue(item, 'motorFLACalc'),
      affectedArea: item.sizeData['outputs']['affectedArea'],
      standbyPower: item.sizeData['outputs']['standbyPower'],
      fanEfficiency: item.sizeData['outputs']['fanEfficiency'],
      fanSpacing: item.sizeData['outputs']['actualFanSpacing'],
      minFanSpacing: item.sizeData['outputs']['minimumFanSpacing'],
      impellerDiameter: item.sizeData['outputs']['impellerDiameter'],
      directionOfOperation: item.sizeData['outputs']['directionOfOperation'],
      bladeCount: item.sizeData['outputs']['numberOfBlades'],
      cfei40Speed: item.sizeData['outputs']['cfei40Speed'],
      cfei100Speed: item.sizeData['outputs']['cfei100Speed'],
      dropLength: getConfigValue(item, 'dropLength'),
      mountingStructure: getConfigValue(item, 'mountingKit'),
      coolingEffect: item.sizeData['outputs']['coolingEffect'],
      ledLight: getConfigValue(item, 'ledLight'),
      notes: 'Notes',
      itemData: item,
    };

    const zeroPipe = new ZeroToDashPipe();

    if (!!item.sizeData['dimensions']) {
      try {
        row.length = zeroPipe.transform(
          item.sizeData['dimensions'].find(
            (dimension) =>
              dimension.description.toLowerCase().trim() === 'length'
          ).value,
          '1.0-0'
        );

        row.width = zeroPipe.transform(
          item.sizeData['dimensions'].find(
            (dimension) =>
              dimension.description.toLowerCase().trim() === 'width'
          ).value,
          '1.0-0'
        );

        row.height = zeroPipe.transform(
          item.sizeData['dimensions'].find(
            (dimension) =>
              dimension.description.toLowerCase().trim() === 'height'
          ).value,
          '1.0-0'
        );

        row.dimensions = `${row.length} x ${row.width} x ${row.height}`;
      } catch {
        row.length = null;
        row.width = null;
        row.height = null;
        row.dimensions = null;
      }
    }

    if (!!item.sizeData['outputs']['fanModel']) {
      row.mca = zeroPipe.transform(
        getConfigValue(item, 'minimumCircuitAmps'),
        '1.1-1'
      );

      row.mop = zeroPipe.transform(
        getConfigValue(item, 'maximumOvercurrentProtection'),
        '1.0-0'
      );

      row.mcaMop = `${row.mca} / ${row.mop}`;

      row.supplyFanRPM = item.sizeData['outputs']['fanModel']['supplyFanRPM'];
      row.supplyFanBHP = item.sizeData['outputs']['fanModel']['supplyFanBHP'];
    }

    if (!!item.sizeData['outputs']['coolingModel']) {
      row.coolingDB = zeroPipe.transform(
        item.sizeData['outputs']['coolingModel']['leavingAirDryBulb'],
        '1.0-1'
      );

      row.coolingWB = zeroPipe.transform(
        item.sizeData['outputs']['coolingModel']['leavingAirWetBulb'],
        '1.0-1'
      );

      row.coolingDBWB = `${row.coolingDB} / ${row.coolingWB}`;

      row.coolingCap = zeroPipe.transform(
        item.sizeData['outputs']['coolingModel']['coolingCapacity'],
        '1.0-0'
      );

      if (!!item.sizeData['outputs']['coolingModel']['airSourceHeatPump']) {
        row.ashpDeratedLeavingAirDryBulbHeat =
          item.sizeData['outputs']['coolingModel']['airSourceHeatPump'][
            'ashpDeratedLeavingAirDryBulbHeat'
          ];
        row.ashpDeratedHeatingCapacity =
          item.sizeData['outputs']['coolingModel']['airSourceHeatPump'][
            'ashpDeratedHeatingCapacity'
          ];
      }
    }

    if (!!item.sizeData['outputs']['heatingModel']) {
      row.heatingLat = zeroPipe.transform(
        item.sizeData['outputs']['heatingModel']['leavingDryBulb'],
        '1.0-0'
      );

      row.heatingCap = zeroPipe.transform(
        item.sizeData['outputs']['heatingModel']['heatingCapacity'],
        '1.0-0',
        item.sizeData['outputs']['heatingModel']['selectedHeatingModelType']
          .toLowerCase()
          .trim() === 'electric'
          ? 'kW'
          : 'MBH'
      );
    }

    if (!!item.sizeData['outputs']['housingWheelModel']) {
      // Override dimensions for DOAS
      row.dimensions =
        zeroPipe.transform(
          item.sizeData['outputs']['housingWheelModel']['dimensions']['length'],
          '1.0-0'
        ) +
        ' x ' +
        zeroPipe.transform(
          item.sizeData['outputs']['housingWheelModel']['dimensions']['width'],
          '1.0-0'
        ) +
        ' x ' +
        zeroPipe.transform(
          item.sizeData['outputs']['housingWheelModel']['dimensions']['height'],
          '1.0-0'
        );

      if (
        !!item.sizeData['outputs']['housingWheelModel'][
          'energyRecoveryPerformance'
        ]
      ) {
        row.enthalpyRecoveryRatio =
          item.sizeData['outputs']['housingWheelModel'][
            'energyRecoveryPerformance'
          ]['totalOutdoorEfficiencySummer'];
      }
      {
        row.erDevice =
          item.sizeData['outputs']['housingWheelModel'][
            'energyRecoveryPerformance'
          ]['energyRecoveryTypeActual'];
      }
      {
        row.erMedia =
          item.sizeData['outputs']['housingWheelModel'][
            'energyRecoveryPerformance'
          ]['energyRecoveryMedia'];
      }

      // MUA Overrides
      if (row.application.toLowerCase() === 'makeupair') {
        row.actualVolume =
          item.sizeData['outputs']['housingWheelModel']['adjustedSupplyVolume'];

        row.esp = getConfigValue(item, 'externalSupplyAirStaticPressure');

        row.motorSizeHP = getConfigValue(item, 'supplyFanMotorHorsepower');

        row.vcp = `${getConfigValue(
          item,
          'electricHeatVoltage'
        )}/${getConfigValue(item, 'PowerFrequency')}/${getConfigValue(
          item,
          'phase'
        )}`;

        row.sones =
          item.sizeData['outputs']['fanModel']['sound']['supplySound'][
            'outletSoundSones'
          ];

        row.dba =
          item.sizeData['outputs']['fanModel']['sound']['supplySound'][
            'outletSoundDba'
          ];
      }

      if (!!item.sizeData['outputs']['fanModel']['sound']) {
        row.supplyOutletdBA =
          item.sizeData['outputs']['fanModel']['sound']['supplySound'][
            'outletSoundDba'
          ];
        row.supplyOutletSones =
          item.sizeData['outputs']['fanModel']['sound']['supplySound'][
            'outletSoundSones'
          ];
        row.exhaustInletSoundDba =
          item.sizeData['outputs']['fanModel']['sound']['exhaustSound'][
            'inletSoundDba'
          ];
        row.exhaustFanInletSoundSones =
          item.sizeData['outputs']['fanModel']['sound']['exhaustSound'][
            'inletSoundSones'
          ];
      }

      if (row.application.toLowerCase() === 'preconditioner') {
        row.bhp = item.sizeData['outputs']['fanModel']['supplyFanBHP'];
        row.rpm = item.sizeData['outputs']['fanModel']['supplyFanRPM'];

        row.supplyTotalBhp =
          item.sizeData['outputs']['fanModel']['supplyFanTotalBHP'];
        row.supplySize =
          item.sizeData['outputs']['fanModel']['selectedSupplyMotor'][
            'motorHorsePowerValue'
          ];
        row.exhaustBhp =
          item.sizeData['outputs']['fanModel']['exhaustFanTotalBHP'];
        row.exhaustSize =
          item.sizeData['outputs']['fanModel']['selectedExhaustMotor'][
            'motorHorsePowerValue'
          ];
        row.summerASHRAE901ERR =
          item.sizeData['outputs']['housingWheelModel'][
            'energyRecoveryPerformance'
          ]['totalOutdoorEfficiencySummer'];

        row.supplyFanRPM = item.sizeData['outputs']['fanModel']['supplyFanRPM'];
        row.exhaustFRPM = item.sizeData['outputs']['fanModel']['exhaustFanRPM'];

        row.fanPower = item.sizeData['outputs']['fanModel']['fanPower'];

        row.summerDBWB = `${zeroDash.transform(
          item.sizeData['outputs']['housingWheelModel'][
            'energyRecoveryPerformance'
          ]['summerDryBulbOutdoorAirLeaving'],
          '1.1-1'
        )} /
          ${zeroDash.transform(
            item.sizeData['outputs']['housingWheelModel'][
              'energyRecoveryPerformance'
            ]['summerWetBulbOutdoorAirLeaving'],
            '1.1-1'
          )}`;

        row.winterDBWB = `${zeroDash.transform(
          item.sizeData['outputs']['housingWheelModel'][
            'energyRecoveryPerformance'
          ]['winterDryBulbOutdoorAirLeaving'],
          '1.1-1'
        )} /
          ${zeroDash.transform(
            item.sizeData['outputs']['housingWheelModel'][
              'energyRecoveryPerformance'
            ]['winterWetBulbOutdoorAirLeaving'],
            '1.1-1'
          )}`;

        row.winterASHRAE901 = zeroDash.transform(
          item.sizeData['outputs']['housingWheelModel'][
            'energyRecoveryPerformance'
          ]['totalOutdoorEfficiencyWinter'],
          '1.0-0'
        );
      }
    }

    if (!!item.sizeData['outputs']['inletSound']) {
      row.inletSones = item.sizeData['outputs']['inletSound']['sones'];
      row.sphericalSones =
        item.sizeData['outputs']['inletSound']['sphericalSones'];
      row.inletDba = item.sizeData['outputs']['inletSound']['dba'];
      row.inletLwa = item.sizeData['outputs']['inletSound']['lwa'];
    }

    if (!!item.sizeData['outputs']['outletSound']) {
      row.outletSones = item.sizeData['outputs']['outletSound']['sones'];
      row.outletDba = item.sizeData['outputs']['outletSound']['dba'];
      row.outletLwa = item.sizeData['outputs']['outletSound']['lwa'];
    } else if (item.type.toLowerCase() === 'circulator') {
      // DS products mapped this question differently per Bill - 11/15/18
      row.outletDba = item.sizeData['outputs']['outletdBA'];
    }

    if (!!item.sizeData['outputs']['radiatedSound']) {
      row.radiatedSones = item.sizeData['outputs']['radiatedSound']['sones'];
      row.radiatedDba = item.sizeData['outputs']['radiatedSound']['dba'];
      row.radiatedLwa = item.sizeData['outputs']['radiatedSound']['lwa'];
    }

    return row;
  }

  getStatusMessages(item: Item): string {
    return item.statusMessages.join(', ');
  }

  invalidItemClick(item: Item) {
    const dialogRef = this.dialog.open(ReselectRequiredDialogComponent, {
      disableClose: false,
      autoFocus: true,
      data: item,
    });

    const subscription = dialogRef.beforeClosed().subscribe((reselect) => {
      subscription.unsubscribe();

      if (reselect) {
        let reselecting = true;

        this.core.showLoadingGraphic('Reselecting...', () => reselecting);

        this.layoutService.reselectDerivedEntity(item.sizeData, item).then(() => {
          const category = this.layoutService.getCategory(
            item.sizeData.layoutConfig
          ) as ProductCategory;

          this.projectsService.deleteRevisionItem(item).then(() => {
            if (
              !!category.customRoute &&
              category.entity !== ProductEntities.fumeExhaust
            ) {
              this.router.navigateByUrl(category.customRoute);
            } else if (
              !!category.customRoute &&
              category.entity === ProductEntities.fumeExhaust
            ) {
              this.router.navigateByUrl('/graphicalselection');
            } else {
              this.router.navigateByUrl('/selection');
            }

            reselecting = false;

            this.core.hideLoadingGraphic();
          });
        });
      }
    });
  }

  noop(): void {}
}
