import {
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ZeroToDashPipe } from '../../../../ecaps-core/pipes/custom-pipes.pipe';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { ColumnList } from '../../constants/column-list.const';

@Component({
  selector: 'erv-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErvGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  constructor(protected injector: Injector) {
    super(injector);

    this.footerProductType = 'Energy Recovery Ventilator(s)';
  }

  ngOnInit(): void {
    this.columnList = ColumnList.getColumns(
      'relativeCost',
      'erDevice',
      'erMedia',
      'summerASHRAE901ERR',
      'summerLAT',
      'winterASHRAE901ERR',
      'winterLAT',
      'mcaMop',
      'weight',
      'dimensionsLWH',
      'supplySizeHP',
      'supplyTotalBhp',
      'supplyFanRPM',
      'supplyOutletSones',
      'supplyFEI',
      'exhaustSizeHP',
      'exhaustTotalBhp',
      'exhaustFRPM',
      'exhaustFEI',
      'inletSones',
      'supplyOutletdBA',
      'exhaustInletdBA',
      'fanPower',
      'sensibleEffectivenessSummer',
      'sensibleEffectivenessWinter',
      'totalExhaustEfficiencySummer',
      'totalExhaustEfficiencyWinter',
      'exhaustAirTransferRatio'
    );

    const exhaustInletSones = this.columnList.find(
      (item) => item.key === 'inletSones'
    );

    exhaustInletSones.text = 'Exhaust Inlet Sones';

    this.setVisibleColumns([
      'relativeCost',
      'erDevice',
      'erMedia',
      'summerASHRAE901ERR',
      'summerLAT',
      'winterASHRAE901ERR',
      'winterLAT',
      'mcaMop',
      'weight',
      'dimensionsLWH',
    ]);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();
  }

  private validSizeToDSRow(size: ValidSize) {
    const zeroDash = new ZeroToDashPipe();

    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.outputs.fanModel.fanModelName,
      relativeCost: size.pricing.relativePrice,
      erDevice:
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .energyRecoveryTypeActual,
      erMedia:
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .energyRecoveryMedia,
      summerASHRAE901ERR:
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .totalOutdoorEfficiencySummer,
      summerLAT: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .summerDryBulbOutdoorAirLeaving,
        '1.1-1'
      )} /
        ${zeroDash.transform(
          size.outputs.housingWheelModel.energyRecoveryPerformance
            .summerWetBulbOutdoorAirLeaving,
          '1.1-1'
        )}`,
      winterASHRAE901ERR:
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .totalOutdoorEfficiencyWinter,
      winterLAT: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .winterDryBulbOutdoorAirLeaving,
        '1.1-1'
      )} /
        ${zeroDash.transform(
          size.outputs.housingWheelModel.energyRecoveryPerformance
            .winterWetBulbOutdoorAirLeaving,
          '1.1-1'
        )}`,
      mcaMop: `${zeroDash.transform(
        size.outputs.fanModel.minimumCircuitAmps,
        '1.1-1'
      )} /
        ${zeroDash.transform(
          size.outputs.fanModel.maximumOvercurrentProtection,
          '1.0-1'
        )}`,
      weight: size.pricing.weight,
      dimensionsLWH: `${zeroDash.transform(
        size.outputs.housingWheelModel.dimensions.length,
        '1.0-0'
      )} x
        ${zeroDash.transform(
          size.outputs.housingWheelModel.dimensions.width,
          '1.0-0'
        )} x
        ${zeroDash.transform(
          size.outputs.housingWheelModel.dimensions.height,
          '1.0-0'
        )}`,
      supplySizeHP:
        size.outputs.fanModel.selectedSupplyMotor.motorHorsePowerValue,
      supplyTotalBhp: size.outputs.fanModel.supplyFanTotalBHP,
      supplyFanRPM: size.outputs.fanModel.supplyFanRPM,
      supplyFEI: size.outputs.fanModel.supplyFanEnergyIndex,
      exhaustFEI: size.outputs.fanModel.exhaustFanEnergyIndex,
      supplyOutletSones:
        size.outputs.fanModel.sound.supplySound.outletSoundSones,
      exhaustSizeHP:
        size.outputs.fanModel.selectedExhaustMotor.motorHorsePowerValue,
      exhaustTotalBhp: size.outputs.fanModel.exhaustFanTotalBHP,
      exhaustFRPM: size.outputs.fanModel.exhaustFanRPM,
      inletSones: size.outputs.fanModel.sound.exhaustSound.inletSoundSones,
      supplyOutletdBA: size.outputs.fanModel.sound.supplySound.outletSoundDba,
      exhaustInletdBA: size.outputs.fanModel.sound.exhaustSound.inletSoundDba,
      stable: size.stability.stable,
      sizeData: size,
      fanPower: size.outputs.fanModel.fanPower,
      sensibleEffectivenessSummer: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .sensibleEffectivenessSummer,
        '1.0-1'
      )}`,
      sensibleEffectivenessWinter: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .sensibleEffectivenessWinter,
        '1.0-1'
      )}`,
      totalExhaustEfficiencySummer: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .totalExhaustEfficiencySummer,
        '1.0-1'
      )}`,
      totalExhaustEfficiencyWinter: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .totalExhaustEfficiencyWinter,
        '1.0-1'
      )}`,
      exhaustAirTransferRatio: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .exhaustAirTransferRatio,
        '1.0-1'
      )}`,
    };

    return rowData;
  }
}
