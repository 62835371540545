import { Injectable } from '@angular/core';
import { HttpService } from '../../ecaps-core/services/http.service';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { ProductTypes } from '../enums/product-types.enum';
import { ProjectItemStatus } from '../../projects/models/project-item-status.enum';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  constructor(private http: HttpService) {}

  getModelDescription(size: ValidSize): Promise<any> {
    return new Promise<any>((results, reject) => {
      if (size.status !== ProjectItemStatus.invalid) {
        let catalogName = '';

        switch (size.productType) {
          case ProductTypes.louver: {
            catalogName = 'eCAPSLouvers';

            break;
          }
          case ProductTypes.temperedAirProduct: {
            catalogName = 'eCAPSEnergyRecovery';

            break;
          }
          case ProductTypes.preconditioners: {
            catalogName = 'eCAPSEnergyRecovery';

            break;
          }
          case ProductTypes.makeUpAir: {
            catalogName = 'eCAPSMakeUpAir';

            break;
          }
          default: {
            catalogName = 'ecaps';

            break;
          }
        }

        let query = '';

        if (size.productType === ProductTypes.circulator) {
          query = `?NumberOfBlades=${size.outputs.numberOfBlades}`;
        }

        firstValueFrom(
          this.http.get(`/catalog/${catalogName}/models/${size.model}${query}`)
        ).then(
          (resultData) => {
            results(resultData);
          },
          (errorData) => {
            reject(errorData);
          }
        );
      } else {
        results(null);
      }
    });
  }
}
