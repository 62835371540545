import { OverlayRef } from '@angular/cdk/overlay';

export class ImageDialogRef {

    constructor(private overlayRef: OverlayRef) { }

    close(): void {

        this.overlayRef.dispose();

    }

}
