import { IEmail } from './interfaces/i-email.interface';

export class ShareProjectRequest implements IEmail {

    toAddresses: string[];

    subject?: string;

    content: {

        fromName: string;

        sharedProjectUrl: string;

        projectName: string;

        message: string;

    };

    attachment: {

        user: string;

    };

    constructor() {

        this.toAddresses = [];

        this.content = {

            fromName: undefined,
            sharedProjectUrl: undefined,
            projectName: undefined,
            message: undefined

        };

        this.attachment = {

            user: undefined

        };

    }

}
