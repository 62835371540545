import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { LachesisService } from '../../analytics/services/lachesis.service';
import { EcapsCore } from '../../ecaps-core/controllers/ecaps-core.controller';
import { RevitCommsService } from '../../external-communication/services/revit-comms.service';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../google/services/google-analytics.service';
import { ProjectSummary } from '../../projects/models/project-summary.model';
import { ProjectsService } from '../../projects/services/projects.service';
import { UserData } from '../../users/models/user-data.model';
import { UsersService } from '../../users/services/users.service';

interface rowData {
  id: string;
  name: string;
  lastSavedDate: Date;
  location: string;
  quantity: number;
  version: string;
  summary: ProjectSummary;
}

@Component({
  selector: 'app-revit-jobs-page',
  templateUrl: './revit-jobs-page.component.html',
  styleUrls: ['./revit-jobs-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RevitJobsPageComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<rowData>([]);

  displayedColumns: string[] = [
    'name',
    'lastSavedDate',
    'location',
    'quantity',
    'version',
  ];

  private activeProjectsSubscription: Subscription;

  revitVersion: string;
  pluginVersion: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private projectsService: ProjectsService,
    private core: EcapsCore,
    private usersService: UsersService,
    private analytics: GoogleAnalyticsService,
    private lachesisService: LachesisService,
    private revitComms: RevitCommsService
  ) {
    this.analytics.trackEvent_Old(EventCategories.revitAddin, 'Job List');
    this.lachesisService.trackEvent({
      eventName: 'Plugin Job List',
      revitVersion: this.revitComms.revitVersion,
      revitPluginVersion: this.revitComms.revitPluginVersion,
    });

    this.revitVersion = this.revitComms.revitVersion;
    this.pluginVersion = this.revitComms.revitPluginVersion;

    this.updateDS();

    this.activeProjectsSubscription =
      this.projectsService.activeProjectsUpdated.subscribe(
        (projectSummaryList) => {
          this.updateDS(projectSummaryList);
        }
      );

    this.refreshProjects();

    this.usersService.currentUserUpdated.subscribe((userData: UserData) => {
      if (userData.authenticated) {
        this.refreshProjects();
      }
    });
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.activeProjectsSubscription.unsubscribe();
  }

  private refreshProjects() {
    if (this.dataSource.data.length === 0) {
      let loading = true;

      this.core.showLoadingGraphic('Loading...', function () {
        return loading;
      });

      this.projectsService.refreshActiveProjects().then(() => {
        loading = false;

        this.core.hideLoadingGraphic();
      });
    }
  }

  private updateDS(projectSummaryList?: ProjectSummary[]) {
    projectSummaryList = !!projectSummaryList
      ? projectSummaryList
      : this.projectsService.getActiveProjects();

    if (!!projectSummaryList) {
      this.dataSource.data = projectSummaryList.map((summary) => {
        const revision = summary.revisions.find((rev) => rev.active);

        let quantity = 0;

        revision.items.forEach((item) => {
          quantity += item.quantity;
        });

        return {
          id: summary.id,
          name: summary.name,
          lastSavedDate: summary.lastSavedDate,
          location: summary.location,
          quantity,
          version: revision.name,
          summary,
        };
      });
    }
  }
}
