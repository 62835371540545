<div class="loading-graphic-parent cdk-overlay-container" [style.display]="(show ? 'block' : 'none')">
  <div class="cdk-overlay-backdrop cdk-overlay-dark-backdrop cdk-overlay-backdrop-showing"></div>
  <div class="cdk-global-overlay-wrapper" style="justify-content: center; align-items: center">
    <div class="cdk-overlay-pane">
      <div class="loadingGraphicDialog">
        <ng-container [ngSwitch]="useLegacy">

          <div *ngSwitchCase="true" class="legacy-logo">
            <img class="spinner" src="/assets/images/icons/legacy_logo_spinner.png">
            <img src="/assets/images/icons/legacy_logo.png">
          </div>

          <div *ngSwitchDefault class="icon" loadChild="/assets/images/icons/svg/ecaps_logo.svg"></div>

        </ng-container>
        <span class="text">{{ text }}</span>
      </div>
    </div>
  </div>
</div>