import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClearLocalStorageDialogComponent } from '../../dialogs/clear-local-storage-dialog/clear-local-storage-dialog.component';
import { InvalidSizesDialogComponent } from '../../dialogs/invalid-sizes-dialog/invalid-sizes-dialog.component';
import { WebserviceUrlDialogComponent } from '../../dialogs/webservice-url-dialog/webservice-url-dialog.component';

@Component({
  selector: 'dev-icon',
  templateUrl: './dev-icon.component.html',
  styleUrls: ['./dev-icon.component.scss'],
})
export class DevIconComponent implements OnInit {
  menuItems = [];

  constructor(private dialog: MatDialog, private router: Router) {
    const devIcon = this;

    this.menuItems = [
      {
        text: 'Clear Local Storage',
        click: function () {
          const dialogConfig = new MatDialogConfig();

          dialogConfig.autoFocus = true;

          devIcon.dialog.open(ClearLocalStorageDialogComponent, dialogConfig);
        },
      },
      {
        text: 'Select Group',
        click: function () {
          alert('Select Group!');
        },
      },
      {
        text: 'Display Invalid Sizes',
        click: function () {
          const dialogConfig = new MatDialogConfig();

          dialogConfig.autoFocus = true;

          devIcon.dialog.open(InvalidSizesDialogComponent, dialogConfig);
        },
      },
      {
        text: 'Set Webservice URL',
        click: function () {
          const dialogConfig = new MatDialogConfig();

          dialogConfig.autoFocus = true;

          devIcon.dialog.open(WebserviceUrlDialogComponent, dialogConfig);
        },
      },
      {
        text: 'Plenum Rating UI',
        click: function () {
          this.router.navigate(['/plenumrating']);
        },
      },
    ];
  }

  ngOnInit() {}
}
