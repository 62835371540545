<h2 mat-dialog-title>Invalid Sizes</h2>

<mat-dialog-content [ngSwitch]="!!lastSelectionResults?.invalidSizes">
    <ng-container *ngSwitchCase="true">
        <div *ngFor="let invalidSize of lastSelectionResults.invalidSizes">{{ invalidSize.name }}: {{ invalidSize.message }}</div>
    </ng-container>
    <div *ngSwitchCase="false">No invalid sizes to display.</div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="closeClick()">Close</button>
</mat-dialog-actions>