<div class="equipment-schedule-page">
  <div class="action-bar">
    <mat-toolbar>
      <button mat-raised-button color="primary" routerLink="/">Add Item</button>
      <button mat-raised-button color="primary" (click)="deleteGridItems()">
        Delete Item(s)
      </button>
      <span class="active-project-name">Job Name: {{ localProjectName }}</span>
    </mat-toolbar>
  </div>

  <mat-tab-group
    color="primary"
    [mat-stretch-tabs]="false"
    [selectedIndex]="selectedTab"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Fans</span>
        <mat-icon
          *ngIf="showGroupWarning('Fan')"
          color="warn"
          class="tab-warn-icon"
          matTooltip="Invalid marks, reselection required."
          >warning</mat-icon
        >
      </ng-template>
      <div class="grid-parent">
        <fans-equipment-schedule-grid
          [showCustomize]="true"
        ></fans-equipment-schedule-grid>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Gravity</span>
        <mat-icon
          *ngIf="showGroupWarning('Gravity')"
          color="warn"
          class="tab-warn-icon"
          matTooltip="Invalid marks, reselection required."
          >warning</mat-icon
        >
      </ng-template>
      <div class="grid-parent">
        <gravity-equipment-schedule-grid
          [showCustomize]="true"
        ></gravity-equipment-schedule-grid>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Louvers</span>
        <mat-icon
          *ngIf="showGroupWarning('Louver')"
          color="warn"
          class="tab-warn-icon"
          matTooltip="Invalid marks, reselection required."
          >warning</mat-icon
        >
      </ng-template>
      <div class="grid-parent">
        <louvers-equipment-schedule-grid
          [showCustomize]="true"
        ></louvers-equipment-schedule-grid>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Outdoor-Air</span>
        <mat-icon
          *ngIf="showGroupWarning('TemperedAirProduct')"
          color="warn"
          class="tab-warn-icon"
          matTooltip="Invalid marks, reselection required."
          >warning</mat-icon
        >
      </ng-template>
      <div class="grid-parent">
        <doas-equipment-schedule-grid
          [showCustomize]="true"
        ></doas-equipment-schedule-grid>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>HVLS & Destrat Fans</span>
        <mat-icon
          *ngIf="showGroupWarning('Circulator')"
          color="warn"
          class="tab-warn-icon"
          matTooltip="Invalid marks, reselection required."
          >warning</mat-icon
        >
      </ng-template>
      <div class="grid-parent">
        <circulator-equipment-schedule-grid
          [showCustomize]="true"
        ></circulator-equipment-schedule-grid>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Make-up Air</span>
        <mat-icon
          *ngIf="showGroupWarning('MakeUpAir')"
          color="warn"
          class="tab-warn-icon"
          matTooltip="Invalid marks, reselection required."
          >warning</mat-icon
        >
      </ng-template>
      <div class="grid-parent">
        <makeupair-equipment-schedule-grid
          [showCustomize]="true"
        ></makeupair-equipment-schedule-grid>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Energy Recovery Ventilators</span>
        <mat-icon
          *ngIf="showGroupWarning('Preconditioner')"
          color="warn"
          class="tab-warn-icon"
          matTooltip="Invalid marks, reselection required."
          >warning</mat-icon
        >
      </ng-template>
      <div class="grid-parent">
        <erv-equipment-schedule-grid
          [showCustomize]="true"
        ></erv-equipment-schedule-grid>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
