import { CdkStep } from '@angular/cdk/stepper';
import {
  AfterContentChecked,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { BreadcrumbService, ICrumb } from '../../breadcrumb/breadcrumb.service';
import { ConfigurationQuestionComponent } from '../../ecaps-core/components/configuration-question/configuration-question.component';
import {
  ProductGroup,
  ProductsService,
} from '../../products/services/products.service';
import { BaseGridComponent } from '../../selections/grids/base-grid/base-grid.component';
import { Group as LayoutConfigGroup } from '../../selections/models/layout-config/group.model';
import { LayoutConfiguration } from '../../selections/models/layout-config/layout-configuration.model';
import { Question as LayoutConfigQuestion } from '../../selections/models/layout-config/question.model';
import { SelectionResults } from '../../selections/models/selection-results/selection-results.model';
import { ValidSize } from '../../selections/models/selection-results/valid-size.model';
import { LayoutService } from '../../selections/services/layout.service';
import { MenuItemList } from '../../side-bar/services/constants/menu-item-list.const';
import { SideBarService } from '../../side-bar/services/side-bar.service';

@Component({
  selector: 'app-graphical-selection-page',
  templateUrl: './graphical-selection-page.component.html',
  styleUrls: ['./graphical-selection-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GraphicalSelectionPageComponent
  implements OnInit, AfterContentChecked
{
  @ViewChild('leftStepper', { static: true }) private leftStepper: MatStepper;

  @ViewChild('sizeGrid', { static: false }) private sizeGrid: BaseGridComponent;

  layoutConfig: LayoutConfiguration;

  layoutConfigGroups: Array<LayoutConfigGroup>;

  private selectionResults: SelectionResults;

  modelGroup = '';

  private activeQuestion: ConfigurationQuestionComponent;

  constructor(
    private layout: LayoutService,
    private sidebarService: SideBarService,
    private menuItemList: MenuItemList,
    private breadcrumbs: BreadcrumbService,
    private router: Router,
    private products: ProductsService
  ) {
    this.layout.getLastLayoutConfig().then((layoutConfig) => {
      this.layoutConfig = layoutConfig;

      this.setCrumbs();
      this.modelGroup = this.layoutConfig ? this.layoutConfig.modelGroup : '';

      if (layoutConfig && layoutConfig.group && layoutConfig.group.groups) {
        this.layoutConfigGroups = layoutConfig.group.groups.filter(
          (group) => group.visible
        );
      } else {
        this.layoutConfigGroups = null;
      }
    });
  }

  ngOnInit() {
    this.sidebarService.setSidebarItems([
      {
        menuItems: [
          this.menuItemList.equipmentSchedule,
          this.menuItemList.jobs,
        ],
      },
      {
        menuItems: [this.menuItemList.newSelection],
      },
    ]);
  }

  ngAfterContentChecked() {
    this.scrollResize();
  }

  scrollResize() {
    const panel = document.body.querySelector(
      '.left-block .mat-horizontal-stepper-content[style*="visibility: visible"] .stepContent'
    );

    if (!!panel) {
      const maxHeight = panel.clientHeight;

      const maxBottom =
        (<any>panel.querySelector('.subGroup:last-child')).offsetTop +
        panel.querySelector('.subGroup:last-child').clientHeight -
        (<any>panel).offsetTop;

      if (maxHeight < maxBottom) {
        panel.classList.add('scrollPanel');
      } else {
        panel.classList.remove('scrollPanel');
      }
    }

    this.inputScrollFocus();
  }

  inputScrollFocus() {
    const panel = document.body.querySelector(
      '.left-block .mat-horizontal-stepper-content[style*="visibility: visible"] .stepContent'
    );

    if (!!panel && !!this.activeQuestion) {
      const panelHeight = panel.clientHeight;
      const scrollTop = panel.scrollTop;

      const questionTop = (<any>this.activeQuestion.element.nativeElement)
        .offsetTop;

      if (questionTop - scrollTop > panelHeight) {
        panel.scrollTop = questionTop;
      }
    }
  }

  questionFocus(question: ConfigurationQuestionComponent) {
    this.activeQuestion = question;
  }

  questionBlur(question: ConfigurationQuestionComponent) {
    if (this.activeQuestion === question) {
      this.activeQuestion = null;
    }
  }

  getSubGroups(group: LayoutConfigGroup): Array<LayoutConfigGroup> {
    if (group && group.groups) {
      return group.groups.filter((subGroup) => subGroup.visible);
    } else {
      return null;
    }
  }

  getQuestions(group: LayoutConfigGroup): Array<LayoutConfigQuestion> {
    if (group && group.questions) {
      return group.questions.filter((question) => question.visible);
    } else {
      return null;
    }
  }

  stepCompleted(group: LayoutConfigGroup): boolean {
    if (this.stepOptional(group)) {
      return true;
    }

    let count = 0;

    if (group && group.questions) {
      count += group.questions.filter(
        (question) =>
          question.required &&
          (question.value === null ||
            typeof question.value === 'undefined' ||
            question.value === '')
      ).length;
    }

    if (group && group.groups) {
      count += group.groups.filter(
        (subGroup) =>
          subGroup.questions.filter(
            (question) =>
              question.required &&
              (question.value === null ||
                typeof question.value === 'undefined' ||
                question.value === '')
          ).length > 0
      ).length;
    }

    return count === 0;
  }

  stepOptional(group: LayoutConfigGroup): boolean {
    let count = 0;

    if (group && group.questions) {
      count += group.questions.filter((question) => question.required).length;
    }

    if (group && group.groups) {
      count += group.groups.filter(
        (subGroup) =>
          subGroup.questions.filter((question) => question.required).length > 0
      ).length;
    }

    return count === 0;
  }

  backClick() {
    this.leftStepper.previous();
  }

  nextClick() {
    this.leftStepper.next();
  }

  questionAnswered() {
    if (this.leftStepper.selectedIndex > 0) {
      this.selectionResults = null;

      let found = false;
      let lowestStep = this.leftStepper._steps.length - 1;

      this.leftStepper._steps.forEach((step: CdkStep, index: number) => {
        if (index < lowestStep) {
          if (!step.completed && !step.optional) {
            lowestStep = index;

            found = true;
          }
        }
      });

      if (found && this.leftStepper.selectedIndex !== lowestStep) {
        this.leftStepper.selectedIndex = lowestStep;
      }
    }

    this.scrollResize();

    this.inputScrollFocus();
  }

  getDataSource(): Array<ValidSize> {
    return !!this.selectionResults ? this.selectionResults.validSizes : null;
  }

  getNextText(): string {
    if (!!this.layoutConfigGroups) {
      const index = this.leftStepper.selectedIndex;

      if (index < this.layoutConfigGroups.length - 1) {
        return this.layoutConfigGroups[index + 1].text;
      } else {
        return 'Next!';
      }
    } else {
      return 'Next';
    }
  }

  getPreviousText(): string {
    if (!!this.layoutConfigGroups) {
      const index = this.leftStepper.selectedIndex;

      if (index > 0) {
        return this.layoutConfigGroups[index - 1].text;
      } else {
        return 'Back';
      }
    } else {
      return 'Back!';
    }
  }

  sizeAdded() {
    this.leftStepper.selectedIndex = 0;

    this.selectionResults = null;
  }

  selectFirstField() {
    const questions = document.body.querySelectorAll(
      '.left-block .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) .question'
    );

    const required: HTMLElement[] = [];
    const inputs: HTMLElement[] = [];

    for (let i = 0; i < questions.length; i++) {
      const questionReq = questions[i].querySelectorAll('.required');

      for (let j = 0; j < questionReq.length; j++) {
        required.push(<HTMLElement>questionReq[j]);
      }

      const questionInputs = questions[i].querySelectorAll('input,select');

      for (let j = 0; j < questionInputs.length; j++) {
        inputs.push(<HTMLElement>questionInputs[j]);
      }
    }

    if (required.length === 0 && inputs.length > 0) {
      inputs[0].focus();
    } else if (required.length > 0) {
      (<any>required[0]?.querySelector('input,select'))?.focus();
    }

    this.scrollResize();
  }

  getModelGroup(): string {
    if (!!this.layoutConfig) {
      return this.layoutConfig.modelGroup.toLowerCase().trim();
    } else {
      return null;
    }
  }

  doasPageReset() {
    this.layout
      .updateLayoutConfig(this.layoutConfig, {
        SupplyVolume: null,
        OutdoorAirVolume: null,
        ExhaustVolumeInput: null,
      })
      .then(() => {
        this.sizeGrid.clearGrid();

        this.leftStepper.selectedIndex = 0;
      });
  }

  private setCrumbs(): void {
    let category: ProductGroup;
    let modelGroup = this.layoutConfig.modelGroup;

    if (modelGroup === 'MakeUpAir') {
      const heatingType = this.layoutConfig.group.getQuestion('HeatingType');

      if (!!heatingType) {
        modelGroup = heatingType.value;
      }
    }
    if (modelGroup === 'Preconditioner') {
      const unitMounting = this.layoutConfig.group.getQuestion('UnitMounting');

      if (!!unitMounting) {
        modelGroup = unitMounting.value;
      }
    }

    this.products.productGroups.forEach((group) => {
      if (!category && !!group.children) {
        const subSearch = (parentCategory: ProductGroup) => {
          if (parentCategory.name === modelGroup) {
            category = parentCategory;
          } else if (!!parentCategory.children) {
            parentCategory.children.forEach((subCat) => {
              subSearch(subCat);
            });
          }
        };

        group.children.forEach((cat) => {
          subSearch(cat);
        });
      }
    });

    const crumbs: ICrumb[] = [];

    const getParentPath = (groupItem: ProductGroup) => {
      if (!!groupItem.parent) {
        getParentPath(groupItem.parent);
      }

      crumbs.push({
        text: groupItem.text,
        route: () => {
          if (
            groupItem === category &&
            (!category.children || category.children.length === 0)
          ) {
            // Do Nothing
          } else {
            this.router.navigate([`group/${groupItem.name}`]);
          }
        },
      });
    };

    getParentPath(category);

    if (modelGroup === 'Circulator') {
      const selectionMethod =
        this.layoutConfig.group.getQuestion('SelectionMethod');

      if (selectionMethod.value === 'ByPerformance') {
        crumbs.push({
          text: 'Performance-Based Selection',
          route: () => {},
        });
      } else if (selectionMethod.value === 'ByFanSize') {
        crumbs.push({
          text: 'Size-Based Selection',
          route: () => {},
        });
      }
    } else if (modelGroup === 'FumeExhaust') {
      const selectionMethod = this.layoutConfig.group.getQuestion(
        'FumeExhaustApplication'
      );

      if (selectionMethod.value === 'Laboratory') {
        crumbs.push({
          text: 'Laboratory/Pharmacy',
          route: () => {},
        });
      } else if (selectionMethod.value === 'Wastewater') {
        crumbs.push({
          text: 'Wastewater Treatment',
          route: () => {},
        });
      } else if (selectionMethod.value === 'DieselGenerator') {
        crumbs.push({
          text: 'Diesel Generator',
          route: () => {},
        });
      } else if (selectionMethod.value === 'IsolationRoom') {
        crumbs.push({
          text: 'Isolation Room',
          route: () => {},
        });
      } else if (selectionMethod.value === 'ManufacturingProcess') {
        crumbs.push({
          text: 'Manufacturing Process',
          route: () => {},
        });
      } else if (selectionMethod.value === 'AmmoniaRefrigeration') {
        crumbs.push({
          text: 'Ammonia Refrigeration',
          route: () => {},
        });
      }
    }

    this.breadcrumbs.crumbs = crumbs;
  }
}
