import { ChartLayerPoint } from './chart-layer-point.model';
import { ChartLayerBrush } from './chart-layer-brush.model';
import { ChartLayerBounds } from './chart-layer-bounds.model';

export class ChartLayer {
    bounds: ChartLayerBounds;
    brush: ChartLayerBrush;
    inlineLabel: string;
    label: string;
    labelOption: string;
    name: string;
    points: Array<ChartLayerPoint>;
    style: string;
    visible: boolean;
    xAxis: string;
    yAxis: string;
    constructor(layerData: any) {
        layerData = (layerData || {});
        this.bounds = new ChartLayerBounds(layerData.bounds);
        this.brush = new ChartLayerBrush(layerData.brush);
        this.inlineLabel = layerData.inlineLabel;
        this.label = layerData.label;
        this.labelOption = layerData.labelOption;
        this.name = layerData.name;
        this.points = new Array<ChartLayerPoint>();
        if (layerData.points && layerData.points.length > 0) {
            for (let i = 0; i < layerData.points.length; i++) {
                this.points.push(new ChartLayerPoint(layerData.points[i]));
            }
        }
        this.style = layerData.style;
        this.visible = layerData.visible;
        this.xAxis = layerData.xAxis;
        this.yAxis = layerData.yAxis;
    }
}
