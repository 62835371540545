import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { LoadingGraphic } from '../../ecaps-core/controllers/loading-graphic.controller';
import { EmailService } from '../../ecaps-core/services/email.service';
import { UsersService } from '../../users/services/users.service';

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  name: UntypedFormControl;
  organization: UntypedFormControl;
  email: UntypedFormControl;
  comments: UntypedFormControl;

  validate = false;

  authenticated: boolean;

  constructor(
    private users: UsersService,
    private dialogRef: MatDialogRef<FeedbackDialogComponent>,
    private emailService: EmailService,
    private loadingGraphic: LoadingGraphic
  ) {
    this.name = new UntypedFormControl('', [Validators.required]);

    this.organization = new UntypedFormControl('');

    this.email = new UntypedFormControl('', [
      Validators.required,
      Validators.email,
    ]);

    this.comments = new UntypedFormControl('', [Validators.required]);

    this.formGroup = new UntypedFormGroup({
      name: this.name,
      organization: this.organization,
      email: this.email,
      comments: this.comments,
    });

    this.users.getCurrentUser().then((userData) => {
      this.authenticated = userData.authenticated;

      this.name.setValue(
        this.authenticated ? `${userData.firstName} ${userData.lastName}` : ''
      );

      this.email.setValue(this.authenticated ? userData.email : '');
    });
  }

  ngOnInit() {}

  sendClick() {
    this.validate = true;

    if (
      this.name.valid &&
      this.organization.valid &&
      this.email.valid &&
      this.comments.valid
    ) {
      let sending = true;

      this.loadingGraphic.show('Sending...', () => sending);

      this.emailService
        .sendFeedback({
          toAddresses: [environment.email.defaultEmail],

          fromAddress: this.email.value,

          content: {
            fromName: this.name.value,

            organization: this.organization.value,

            email: this.email.value,

            message: this.comments.value,
          },
        })
        .then(() => {
          sending = false;

          this.loadingGraphic.hide();

          this.dialogRef.close();
        });
    }
  }

  cancelClick() {
    this.dialogRef.close();
  }
}
