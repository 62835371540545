import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription, firstValueFrom } from 'rxjs';
import { LayoutConfiguration } from '../../models/layout-config/layout-configuration.model';

@Component({
  selector: 'selection-svg',
  templateUrl: './selection-svg.component.html',
  styleUrls: ['./selection-svg.component.scss'],
})
export class SelectionSvgComponent implements OnInit, OnChanges, OnDestroy {
  @Input('layout-config') private layoutConfig: LayoutConfiguration;

  private svgFile = '';

  private svgContent: SafeHtml;

  private svgClasses = new Array<string>();

  private svgVisibleClasses = new Array<string>();

  private svgUpdated = false;

  private layoutUpdatedSubscription: Subscription;

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private element: ElementRef
  ) {}

  ngOnInit() {
    this.watchLayoutConfig();

    this.getInitialSVG();
  }

  ngOnChanges() {
    if (!this.layoutUpdatedSubscription) {
      this.watchLayoutConfig();
    }
  }

  ngOnDestroy() {
    if (!!this.layoutUpdatedSubscription) {
      this.layoutUpdatedSubscription.unsubscribe();
    }
  }

  watchLayoutConfig() {
    if (!!this.layoutUpdatedSubscription) {
      this.layoutUpdatedSubscription.unsubscribe();

      this.layoutUpdatedSubscription = null;
    }

    if (!!this.layoutConfig) {
      this.layoutUpdatedSubscription = this.layoutConfig.updated.subscribe(
        () => {
          this.updateSVG();
        }
      );
    }
  }

  updateSVG() {
    const imageMetaData = this.layoutConfig.getQuestion('ImageMetaData');

    if (!!imageMetaData) {
      const updateObj = JSON.parse(imageMetaData.value);

      this.svgVisibleClasses = updateObj.classes;

      this.svgUpdated = true;

      if (
        updateObj.file.toLowerCase().trim() !==
        this.svgFile.toLowerCase().trim()
      ) {
        this.svgFile = updateObj.file;

        this.loadSVG();
      }
    }
  }

  getInitialSVG() {
    if (!!this.layoutConfig) {
      const imageMetaData = this.layoutConfig.getQuestion('ImageMetaData');

      if (!!imageMetaData) {
        let data;

        try {
          data = JSON.parse(imageMetaData.value);
        } catch {
          data = null;
        }

        if (!!data) {
          this.svgVisibleClasses = data.classes;

          this.svgFile = data.file;

          this.loadSVG();
        }
      }
    }
  }

  loadSVG() {
    const url = `/assets/images/models/svg/${this.svgFile
      .toLowerCase()
      .trim()}`;

    firstValueFrom(this.http.get(url, { responseType: 'text' })).then(
      (content) => {
        this.svgContent = this.sanitizer.bypassSecurityTrustHtml(content);

        this.svgClasses = new Array<string>();

        let position = content.indexOf(' class="');

        while (position > 0) {
          position += 8;

          const end = content.indexOf('"', position);

          const className = content.substr(position, end - position);

          if (this.svgClasses.indexOf(className) === -1) {
            this.svgClasses.push(className);
          }

          position = content.indexOf(' class="', position);
        }

        this.svgUpdated = true;
      },
      (errorData) => {
        throw errorData;
      }
    );
  }

  getSVGContent(): SafeHtml {
    if (!!this.svgContent && this.svgUpdated) {
      this.svgUpdated = false;

      const self = this;

      const updateClasses = function () {
        const svg = (<HTMLElement>self.element.nativeElement).querySelector(
          '.svgContent'
        );

        if (!!svg) {
          const hiddenClasses = self.svgClasses.filter(
            (className) =>
              self.svgVisibleClasses.findIndex(
                (visClass) =>
                  visClass.toLowerCase().trim() ===
                  className.toLowerCase().trim()
              ) === -1
          );

          const visibleClasses = self.svgClasses.filter(
            (className) =>
              self.svgVisibleClasses.findIndex(
                (visClass) =>
                  visClass.toLowerCase().trim() ===
                  className.toLowerCase().trim()
              ) > -1
          );

          if (hiddenClasses.length > 0) {
            svg
              .querySelectorAll('.' + hiddenClasses.join(',.'))
              .forEach((layer: HTMLElement) => {
                layer.style.display = 'none';
              });
          }

          if (visibleClasses.length > 0) {
            svg
              .querySelectorAll('.' + visibleClasses.join(',.'))
              .forEach((layer: HTMLElement) => {
                layer.style.display = '';
              });
          }
        } else {
          setTimeout(updateClasses);
        }
      };

      updateClasses();
    }

    return this.svgContent;
  }
}
