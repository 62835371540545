import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { ObserverService } from '../services/observer.service';

/**
 * This directive is used to make a square element.
 * This is primarily support for Chrome versions < 88 which don't support the CSS aspect-ratio property.
 * Chrome 57 is used in Revit 2019.
 * Chrome 65 is used in Revit 2020 - 2022.
 */

@Directive({
  selector: '.square, .square-height, .square-width',
})
export class SquareDirective implements OnDestroy {
  constructor(private element: ElementRef, private observer: ObserverService) {
    const chromeVersion = this.getChromeVersion();

    if (chromeVersion > -1 && chromeVersion < 88) {
      this.observer.observe(
        element.nativeElement,
        'resize',
        (element, observing) => {
          if (!observing) {
            return false;
          }

          this.updateSize();

          return true;
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.element.nativeElement, 'resize');
  }

  private getSource(): 'width' | 'height' {
    const element = this.element.nativeElement as HTMLElement;

    for (let i = 0; i < element.classList.length; i++) {
      const className = element.classList[i];

      if (
        className === 'square' ||
        className === 'square-height' ||
        className === 'square-width'
      ) {
        return ['square', 'square-width'].includes(className)
          ? 'width'
          : 'height';
      }
    }
  }

  private getChromeVersion(): number {
    const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

    return raw ? parseInt(raw[2], 10) : -1;
  }

  private updateSize() {
    const element = this.element.nativeElement as HTMLElement;

    const initialWidth = element.style.width;
    const initialHeight = element.style.height;

    const dimensions: {
      width: number;
      height: number;
      top: number;
      right: number;
      bottom: number;
      left: number;
    } = {
      width: element.offsetWidth,
      height: element.offsetHeight,
      top: parseFloat(window.getComputedStyle(element).paddingTop) || 0,
      right: parseFloat(window.getComputedStyle(element).paddingRight) || 0,
      bottom: parseFloat(window.getComputedStyle(element).paddingBottom) || 0,
      left: parseFloat(window.getComputedStyle(element).paddingLeft) || 0,
    };

    const chromeVersion = this.getChromeVersion();

    if (chromeVersion > -1 && chromeVersion < 88) {
      const source = this.getSource();

      const setDimension = (source: 'width' | 'height') => {
        const destination = source === 'width' ? 'height' : 'width';
        const newDim =
          source === 'width'
            ? dimensions.width - dimensions.top - dimensions.bottom
            : dimensions.height - dimensions.left - dimensions.right;

        if (
          newDim > 0 &&
          `${newDim}px` !== (source === 'width' ? initialHeight : initialWidth)
        ) {
          element.style[destination] = `${newDim}px`;
        }
      };

      setDimension(source);
    }
  }
}
