import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { ColumnList } from '../constants/column-list.const';
import { ProductTypes } from '../../../../products/enums/product-types.enum';

@Component({
  selector: 'gravity-equipment-schedule-grid',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GravityEquipmentScheduleGridComponent
  extends BaseGridComponent
  implements OnInit
{
  constructor(protected injector: Injector) {
    super(injector);

    this.productType = ProductTypes.gravity;
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'location',
      'areaServed',
      'model',
      'application',
      'actualVolume',
      'actualPressureDrop',
      'actualThroatVelocity',
      'actualThroatSize',
      'actualThroatArea',
      'hoodWidth',
      'hoodLength',
      'overallHeight',
      'weight',
      'quantity',
      'mfg'
    );

    this.setVisibleColumns([
      'location',
      'areaServed',
      'model',
      'application',
      'actualVolume',
      'actualPressureDrop',
      'actualThroatVelocity',
      'actualThroatSize',
      'actualThroatArea',
      'hoodWidth',
      'hoodLength',
      'overallHeight',
      'weight',
    ]);
  }
}
