<ventabula-chart
  #chart
  [chartSpacing]="chartSpacing"
  [showElevation]="showElevation"
>
  <form chart-type *ngIf="availableCharts?.length > 1" [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>Fan Chart Type</mat-label>

      <mat-select formControlName="chartType" (selectionChange)="renderChart()">
        <mat-option *ngFor="let type of availableCharts" [value]="type.value">{{
          type.text
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <ng-content select="[sp-table]" ngProjectAs="[sp-table]"></ng-content>
</ventabula-chart>
