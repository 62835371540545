import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import { environment } from '../../../environments/environment';
import { IRoute, PageTitles } from '../../app-routing.module';
import { CookiePolicyService } from '../../ecaps-core/services/cookie-policy.service';

export enum EventCategories {
  documentDownload = 'Document Download',
  staticPressureCalculator = 'Static Pressure Calculator',
  elevationDialog = 'Elevation Dialog',
  authentication = 'Authentication',
  sharing = 'Sharing',
  viewReleaseNotes = 'View Release Notes',
  groupSelection = 'Group Selection',
  toolboxItem = 'Toolbox Item',
  cutSheetProductDialog = 'Cut Sheet - Product Dialog',
  dimensions = 'Dimensions',
  productReselection = 'Product Reselection',
  rvVsRveCompare = 'RV vs RVE Compare',
  comparison = 'Comparison',
  revitAddin = 'Revit Add-in',
  eCAPS = 'eCAPS',
  configuration = 'Configuration',
}

export type ApplicationTypes = 'eCAPS' | 'Revit Add-in';

export type EnvironmentTypes = 'production' | 'qa' | 'dev';

export type EventNames =
  | 'Drawing'
  | 'Group Selection'
  | 'logout'
  | 'login'
  | 'Toolbox'
  | 'Static Pressure Calculator'
  | 'Document Selector'
  | 'Navigation';

export type ViewTypes =
  | 'Cut Sheet'
  | 'Revit Drawings'
  | 'Schedule'
  | 'CAPS Export'
  | 'Save Project'
  | 'Comparison'
  | 'Sharing'
  | 'Reselect';

export type ActionTypes =
  | 'Download'
  | 'Insert'
  | 'Email'
  | 'Cut Sheet'
  | 'Revit Drawings'
  | 'All'
  | 'Schedule'
  | 'CAPS Export'
  | 'Save'
  | 'Save As...'
  | 'Save and New'
  | 'Save and Open'
  | 'OK'
  | 'Information Tab'
  | 'Coverage & Spacing Tab'
  | 'Fan Curves Tab'
  | 'Sound Chart Tab'
  | 'Share Project'
  | 'Open Shared Project'
  | 'Keep'
  | 'Remove';

export type SourceTypes =
  | 'Revit Job Marks'
  | 'Document Selector'
  | 'Product Info Slideout'
  | 'Sidebar Menu'
  | 'Create New Project Lightbox'
  | 'Job Opening Notice Lightbox'
  | 'Project Properties Lightbox'
  | 'Toolbox Items List'
  | 'Model Comparison Slideout'
  | 'Energy Recovery Model Comparison Slideout'
  | 'Shared Project'
  | 'Item Reselection Lightbox'
  | 'Direct Link';

export type DeliveryMethodTypes = 'Download' | 'Insert' | 'Email';

export type ContentTypes =
  | '2D CAD'
  | '3D CAD'
  | 'LOD 200'
  | 'LOD 300'
  | 'SVG'
  | 'PDF'
  | 'ZIP'
  | 'Image'
  | 'Read Me'
  | 'Revit'
  | 'CAPS Export'
  | 'Excel'
  | 'All';

interface IFixedPayload {
  environment: EnvironmentTypes;
  eCAPSVersion: string;
}

interface IGlobalPayload {
  application: ApplicationTypes;
  loggedIn: boolean;
  revitVersion?: string;
  revitAddinVersion?: string;
  productGroup?: string;
  productType?: string;
  category?: string;
}

export interface IEventBasePayload {
  source?: SourceTypes;
  action?: ActionTypes;
  deliveryMethod?: DeliveryMethodTypes;
  contentType?: ContentTypes;
}

export interface IModelPayload {
  model?: string;
  modelName?: string;
}

export interface IEventPayload extends IEventBasePayload, IModelPayload {}

export interface IEventItemModelsPayload
  extends IGAItemExtended,
    IModelPayload {}

export interface IEventPayloadModelList extends IEventBasePayload {
  items: IEventItemModelsPayload[];
}

interface IPageViewPayload {
  page_title?: PageTitles;
  page_location?: string;
}

export interface IGAItemExtended {
  affiliation?: string;
  coupon?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
}

export interface IGAItem extends IGAItemExtended {
  item_id: string;
  item_name: string;
}

declare var gtag: any;
@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private _globalPayload: IGlobalPayload = {
    application: 'eCAPS',
    loggedIn: false,
  };

  public get application(): ApplicationTypes {
    return this._globalPayload.application;
  }
  public set application(value: ApplicationTypes) {
    console.debug('[GoogleAnalyticsService] application(%s)', value);

    this._globalPayload.application = value;
  }

  public get loggedIn(): boolean {
    return this._globalPayload.loggedIn;
  }
  public set loggedIn(value: boolean) {
    console.debug('[GoogleAnalyticsService] loggedIn(%s)', value);

    this._globalPayload.loggedIn = value;
  }

  public get environment(): EnvironmentTypes {
    return environment.environment as EnvironmentTypes;
  }

  public get revitVersion(): string | undefined {
    return this._globalPayload.revitVersion;
  }
  public set revitVersion(value: string | undefined) {
    console.debug('[GoogleAnalyticsService] revitVersion(%s)', value);

    this._globalPayload.revitVersion = value;
  }

  public get revitAddinVersion(): string | undefined {
    return this._globalPayload.revitAddinVersion;
  }
  public set revitAddinVersion(value: string | undefined) {
    console.debug('[GoogleAnalyticsService] revitAddinVersion(%s)', value);

    this._globalPayload.revitAddinVersion = value;
  }

  public get productGroup(): string | undefined {
    return this._globalPayload.productGroup;
  }
  public set productGroup(value: string | undefined) {
    console.debug('[GoogleAnalyticsService] productGroup(%s)', value);

    this._globalPayload.productGroup = value;
  }

  public get productType(): string | undefined {
    return this._globalPayload.productType;
  }
  public set productType(value: string | undefined) {
    console.debug('[GoogleAnalyticsService] productType(%s)', value);

    this._globalPayload.productType = value;
  }

  public get category(): string | undefined {
    return this._globalPayload.category;
  }
  public set category(value: string | undefined) {
    console.debug('[GoogleAnalyticsService] category(%s)', value);

    this._globalPayload.category = value;
  }

  public get eCAPSVersion(): string {
    return environment.version;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cookiePolicy: CookiePolicyService
  ) {
    this.addScripts();

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        const routeConfig: IRoute = event.snapshot.routeConfig;

        this.resetGlobalPayload(routeConfig);
      }

      if (event instanceof NavigationEnd) {
        this.trackPage();
      }
    });
  }

  private addScripts() {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${environment.ga4}`;

    document.head.prepend(scriptTag);

    this.addGMTScript();

    this.init();
  }

  private addGMTScript() {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    const scriptTag = document.createElement('script');
    scriptTag.async = true;
    scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${environment.gtmId}`;
    document.head.prepend(scriptTag);

    const noscriptTag = document.createElement('noscript');

    const iFrame = document.createElement('iframe');
    iFrame.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtmId}`;
    iFrame.height = '0';
    iFrame.width = '0';
    iFrame.style.display = 'none';
    iFrame.style.visibility = 'hidden';

    noscriptTag.append(iFrame);

    document.body.prepend(noscriptTag);
  }

  private init(): void {
    const ga4Options: Record<string, any> = {
      send_page_view: false,
      debug_mode: !environment.production,
    };

    const fixedOptions: IFixedPayload = {
      environment: this.environment,
      eCAPSVersion: this.eCAPSVersion,
    };

    gtag('config', environment.ga4, {
      ...ga4Options,
      ...fixedOptions,
    });
  }

  /**
   * Tracks an event to Google Analytics
   * @param {EventNames} name The name of the event
   * @param {IEventPayload | IEventPayloadModelList} payload The payload of the event
   */
  public trackEvent(
    name: EventNames,
    payload?: IEventPayload | IEventPayloadModelList
  ): void {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug(
        '[GoogleAnalyticsService] trackEvent(%s, %o), %o',
        name,
        payload,
        this.getGlobalPayload()
      );
    }

    if (payload && 'items' in payload) {
      payload.items.forEach((item) => {
        const { items, ...rest } = payload;
        const { model, modelName } = item;

        if (!!model && !!modelName) {
          gtag('event', name, {
            model,
            modelName,
            ...(rest ?? {}),
            ...this.getGlobalPayload(),
          });
        }
      });
    } else {
      gtag('event', name, {
        ...(payload ?? {}),
        ...this.getGlobalPayload(),
      });
    }
  }

  /**
   * Tracks an event to Google Analytics
   * @deprecated Use trackEvent instead
   * @param {EventCategories} category The category of the event
   * @param {string} action The action of the event
   * @param {string} label The label of the event
   * @param {string} value The value of the event
   */
  public trackEvent_Old(
    category: EventCategories,
    action: string,
    label?: string,
    value?: any
  ) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] trackEvent(%s, %s, %s, %o)', {
        category,
        action,
        label,
        value,
      });
    }

    gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
      ...this.getGlobalPayload(),
    });
  }

  public trackPage() {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    const routeConfig: IRoute =
      this.activatedRoute.firstChild.snapshot.routeConfig;

    if (!environment.production) {
      if (routeConfig.data?.analytics?.trackPage !== false) {
        console.debug(
          '[GoogleAnalyticsService] trackPage() %o',
          this.getPageViewPayload()
        );
      } else {
        console.debug(
          '[GoogleAnalyticsService] trackPage() %o (disabled)',
          this.getPageViewPayload()
        );
      }
    }

    if (routeConfig.data?.analytics?.trackPage !== false) {
      gtag('event', 'page_view', {
        ...this.getGlobalPayload(),
      });
    }
  }

  public trackTiming(
    category: string,
    variable: string,
    value: number,
    label?: string
  ) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    gtag('event', 'timing_complete', {
      name: variable,
      value,
      event_category: category,
      event_label: label,
      ...this.getGlobalPayload(),
    });
  }

  public trackException(description: string, fatal?: boolean) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    gtag('event', 'exception', {
      description,
      fatal,
      ...this.getGlobalPayload(),
    });
  }

  public search(query: string) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    gtag('event', 'search', {
      search_term: query,
      ...this.getGlobalPayload(),
    });
  }

  public addToCart(
    itemList: IEventPayloadModelList,
    currency: string = 'USD',
    value: number = 0
  ): void {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] addToCart(%o)', {
        currency,
        value,
        ...itemList,
      });
    }

    gtag('event', 'add_to_cart', {
      currency,
      value,
      items: this.modelsPayloadToGAItem(itemList.items),
      ...this.getGlobalPayload(),
    });
  }

  public removeFromCart(
    items: IEventItemModelsPayload[],
    currency: string = 'USD',
    value: number = 0
  ) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] removeFromCart(%o)', {
        currency,
        value,
        ...items,
      });
    }

    gtag('event', 'remove_from_cart', {
      currency,
      value,
      items: this.modelsPayloadToGAItem(items),
      ...this.getGlobalPayload(),
    });
  }

  public viewCart(
    viewType: ViewTypes,
    itemList: IEventPayloadModelList,
    currency: string = 'USD',
    value: number = 0
  ) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] viewCart(%s, %o)', viewType, {
        currency,
        value,
        ...itemList,
      });
    }

    const { items, ...rest } = itemList;

    gtag('event', 'view_cart', {
      currency,
      value,
      viewType,
      ...rest,
      items: this.modelsPayloadToGAItem(items),
      ...this.getGlobalPayload(),
    });
  }

  public viewItem(
    viewType: ViewTypes,
    itemList: IEventPayloadModelList,
    currency: string = 'USD',
    value: number = 0
  ) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] viewItem(%s, %o)', viewType, {
        currency,
        value,
        ...itemList,
      });
    }

    const { items, ...rest } = itemList;

    gtag('event', 'view_item', {
      currency,
      value,
      viewType,
      ...rest,
      items: this.modelsPayloadToGAItem(items),
      ...this.getGlobalPayload(),
    });
  }

  public viewItemList(
    items: IEventItemModelsPayload[],
    listName?: string,
    listId?: string
  ) {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] viewItemList(%o)', {
        listName,
        listId,
        ...items,
      });
    }

    gtag('event', 'view_item_list', {
      item_list_id: listId,
      item_list_name: listName,
      items: this.modelsPayloadToGAItem(items),
      ...this.getGlobalPayload(),
    });
  }

  public beginCheckout(
    currency: string,
    value: number,
    items: IEventItemModelsPayload[],
    coupon?: string
  ): void {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] beginCheckout(%o)', {
        currency,
        value,
        ...items,
      });
    }

    gtag('event', 'begin_checkout', {
      currency,
      value,
      items: this.modelsPayloadToGAItem(items),
      coupon,
      ...this.getGlobalPayload(),
    });
  }

  public tutorialBegin(): void {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] tutorialBegin()');
    }

    gtag('event', 'tutorial_begin', {
      ...this.getGlobalPayload(),
    });
  }

  public tutorialComplete(): void {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] tutorialComplete()');
    }

    gtag('event', 'tutorial_complete', {
      ...this.getGlobalPayload(),
    });
  }

  public share(method: string, contentType: string, itemId?: string): void {
    if (!this.cookiePolicy.getOption('analytics')) {
      return;
    }

    if (!environment.production) {
      console.debug('[GoogleAnalyticsService] share(%s, %s, %s)', {
        method,
        contentType,
        itemId,
      });
    }

    gtag('event', 'share', {
      method,
      content_type: contentType,
      item_id: itemId,
      ...this.getGlobalPayload(),
    });
  }

  private resetGlobalPayload(config: IRoute): void {
    if (!!config.data && !!config.data.analytics) {
      ['productGroup', 'productType', 'category'].forEach((key) => {
        this._globalPayload[key] =
          key in config.data.analytics
            ? config.data.analytics[key]
            : this._globalPayload[key];
      });
    }
  }

  private getPageViewPayload(): IPageViewPayload {
    const routeConfig: IRoute =
      this.activatedRoute?.firstChild?.snapshot.routeConfig;

    return {
      page_location: `${location.protocol}//${location.hostname}${location.pathname}`, // Added to remove query string from page_location (signin/cb has very long query string)
      page_title: routeConfig?.data?.analytics.title,
    };
  }

  private getGlobalPayload(): { [key: string]: any } {
    return {
      ...this.getPageViewPayload(),
      ...this._globalPayload,
    };
  }

  private modelsPayloadToGAItem(
    items: IEventItemModelsPayload | IEventItemModelsPayload[]
  ): IGAItem | IGAItem[] {
    const convertItem = (item: IEventItemModelsPayload) => {
      const { model, modelName, ...rest } = item;

      return {
        item_id: model,
        item_name: modelName,
        ...rest,
      } as IGAItem;
    };

    if (!Array.isArray(items)) {
      return convertItem(items);
    } else {
      return items.map((item) => convertItem(item));
    }
  }
}
