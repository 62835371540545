import { environment } from '../../../../environments/environment';

export class FaqItem {
  title: any;
  videoUrl?: any;
  html?: any;
}

export const FaqList: Array<FaqItem> = [
  {
    title: 'How do I get the most from eCAPS?',
    videoUrl: 'https://www.youtube.com/embed/w32hLYFAPZI?rel=0',
    html: null,
  },
  {
    title: 'How do I select louvers?',
    videoUrl: 'https://www.youtube.com/embed/VSo0MdQzseU?rel=0',
    html: null,
  },
  {
    title: 'How do I select outdoor air products?',
    videoUrl: 'https://www.youtube.com/embed/NBUXLrwdKSg?rel=0',
    html: null,
  },
  {
    title: 'How to make a HVLS selection?',
    videoUrl: 'https://www.youtube.com/embed/k3ZXl4fnLuU?rel=0',
    html: null,
  },
  {
    title: 'How do I select fan efficiency and fan regulations in eCAPS?',
    videoUrl: 'https://www.youtube.com/embed/KQ9Fc5u23DM?rel=0',
    html: null,
  },
  {
    title: 'What browsers are supported by eCAPS?',
    videoUrl: null,
    html:
      '<span>eCAPS supports the following browsers on all devices:</span>' +
      '<ul>' +
      '<li>Google<sup>®</sup> Chrome</li>' +
      '<li>Microsoft<sup>®</sup> Edge</li>' +
      '<li>Firefox</li>' +
      '</ul>' +
      '<span><b>Note:</b> Minimum supported screen or window size is 1366x1440.</span>',
  },
  {
    title: 'How are products sorted?',
    videoUrl: null,
    html:
      'Greenheck wants to provide you the best product for your needs. The' +
      " products are sorted by ranking. The ranking is Greenheck's best estimate" +
      ' of customer value, based on price and performance.',
  },
  {
    title: 'How do I find additional product information?',
    videoUrl: null,
    html:
      '<div>To view additional product information in the selection grid click' +
      ' on the gear icon in the upper right hand corner of the selection grids.</div>' +
      '<img src="/assets/images/faqs/columnchooser.png" alt="Column Chooser drop down">' +
      '<div>This will open a slide out where you can add additional columns.' +
      'Changing to the "Arrange Columns" tab in this slide out will allow you to reorder your table columns.</div>' +
      '<img style="width:100%" src="/assets/images/faqs/columnchooserdialog.png" alt="Column Chooser Dialog">' +
      '<div><b>Note:</b> Different columns are available in different grids. Greenheck has catered the list to make sure' +
      ' you only view relevent information.</div>',
  },
  {
    title: 'Can I share an eCAPS job with other people?',
    videoUrl: null,
    html:
      '<span>Simply click the' +
      ' <div style="background-color: #005a9b; width: 40px; padding: 0 4px; display: inline-block; color: #fff">' +
      '<mat-icon class="dp40 mat-icon material-icons">share</mat-icon></div>' +
      ' button located on the left hand' +
      ' side menu of the equipment' +
      ' schedule. Type in the name and email address of the person you would like to send it to, and select send.</span>',
  },
  {
    title: 'How do I login to eCAPS?',
    videoUrl: null,
    html:
      '<span>eCAPS uses the same log in as your MyGreenheck account. Simply type in your email and password and you' +
      ' will be able to log in. If you do not have an account, click <a href="' +
      environment.greenheckUrls.register +
      '"' +
      ' target="_blank">Register</a>, and apply for an account.</span>',
  },
  {
    title: 'How do I open an eCAPS job in CAPS?',
    videoUrl: null,
    html:
      '<ol>' +
      '<li>Open eCAPS, and load your job.</li>' +
      '<li>Click "Download" (Found in the left hand menu of the equipment list page).<br>' +
      ' <div style="background-color: #005a9b; width: 40px; padding: 4px; display: inline-block; color: #fff">' +
      '<mat-icon class="dp40 mat-icon material-icons">cloud_download</mat-icon></div>' +
      '</li>' +
      '<li>Select "CAPS File Export" from the dialog. <br>' +
      '<img src="/assets/images/faqs/download_docs_dialog.png">' +
      '</li>' +
      '<li>Open the job file by double-clicking it or by opening CAPS and selecting "Import eCAPS job..." from the File menu.<br>' +
      '<img src="/assets/images/faqs/instr02.jpg">' +
      '</li>' +
      '<li>The Mark import screen opens with all marks selected. Click "OK" to import them into CAPS.</li>' +
      '<li>Now name your job, and your file import is complete.<br>' +
      '<img src="/assets/images/faqs/instr03.jpg">' +
      '</li>' +
      '</ol>',
  },
  {
    title: 'How do I get Revit and CAD drawings?',
    videoUrl: null,
    html:
      '<div>Click on the model name anywhere in the project. This will bring up the product dialog.' +
      ' Either click on the "Drawings" icon in the jump bar, or ' +
      'scroll down to the "Drawings" section. The Revit' +
      ' and CAD drawings will be available for download in the table.</div>' +
      '<img src="/assets/images/faqs/drawing_image.png">',
  },
  {
    title:
      'What is the difference between LOD 200 and 300? When do I use one or the other?',
    videoUrl: null,
    html:
      '<div>Our LOD 200 Revit families typically are intended for schematic design. ' +
      'The family has dimensional parameters only. Our LOD 300 Revit families are intended for design development. ' +
      'The family will contain performance data for your specific selection, as shared parameters. ' +
      'For more information: ' +
      '<a href="/assets/docs/Level-Of-Development_Flyer_2021.pdf" target="_blank">LOD Information Sheet</a></div>',
  },
  {
    title: 'How do I get an equipment schedule?',
    videoUrl: null,
    html:
      '<ol>' +
      '<li>After adding products to the equipment schedule click the schedule icon' +
      ' <div style="background-color: #005a9b; width: 40px; padding: 4px; display: inline-block; color: #fff">' +
      '<mat-icon class="dp40 mat-icon material-icons">list_alt</mat-icon></div> in the bar on the left of the screen to go' +
      ' to the equipment schedule.</li>' +
      "<li>Once you've reordered the columns to your liking. " +
      'Click the <div style="background-color: #005a9b; width: 40px; padding: 0 4px; display: inline-block; color: #fff">' +
      '<mat-icon class="dp40 mat-icon material-icons">cloud_download</mat-icon></div> button.<br>' +
      '<img src="/assets/images/faqs/download_docs_dialog.png" style="margin-top:5px"><br>' +
      '</li>' +
      '<li>From this dialog you can download to your device:' +
      '<ul>' +
      '<li>An equipment schedule as a Excel document.</li>' +
      '<li>An submittal as a PDF.</li>' +
      '<li>A CAPS job based on your current schedule.</li>' +
      '</ul>' +
      '<b>Note:</b> Clicking the ' +
      '<div style="background-color: #005a9b; width: 40px; padding: 0 4px; display: inline-block; color: #fff">' +
      '<mat-icon class="dp40 mat-icon material-icons">mail_outline</mat-icon></div>' +
      ' button will allow you to email these documents to yourself or coworkers.<br>' +
      '</li>' +
      '</ol>',
  },
  {
    title:
      'What is the caution (<mat-icon style="color: var(--color-warning)" class="dp18 warn mat-icon material-icons">warning</mat-icon>) symbol used for?',
    videoUrl: null,
    html:
      '<span>To assist you, the caution symbol will appear in seven different conditions:</span>' +
      '<h4>Fans:</h4>' +
      '<ol>' +
      "<li>The operating speed is within 5% of the fan's maximum RPM.</li>" +
      '<li>The operating curve is within 12% of the max system curve.</li>' +
      '<li>The CFM of the fan is under the requested CFM.</li>' +
      '<li>The CFM of the fan is significantly over the requested CFM.</li>' +
      '<li>When the bhp is within 10% of the max motor size.</li>' +
      '</ol>' +
      '<h4>Louvers:</h4>' +
      '<ol>' +
      '<li>The velocity is within 10% of the maximum velocity.</li>' +
      '<li>The actual pressure is over the requested pressure drop.</li>' +
      '</ol>' +
      '<h4>Gravity:</h4>' +
      '<ol>' +
      '<li>When the Actual Throat Velocity is within 10% of max for each model.</li>' +
      '<li>When the Actual Pressure Drop is over the user requested pressure drop.</li>' +
      '</ol>' +
      '<h4>Outdoor-Air:</h4>' +
      '<ol>' +
      '<li>The Enthalpy Recovery Ratio is under 50%. ASHRAE 90.1/IECC requires it to be at least 50%.</li>' +
      '</ol>' +
      '<h4>HVLS:</h4>' +
      '<ol>' +
      '<li>The Operating speed is within 10% of the fan maximum RPM.</li>' +
      '<li>The Operating speed is within 10% of the fan minimum RPM.</li>' +
      '<li>The fan quantity would require installation within 10% of the maximum spacing requirement.</li>' +
      '</ol>' +
      '<span><b>NOTE: </b>You can still select a fan with a caution (<mat-icon style="color: var(--color-warning)" class="dp18 orange mat-icon ' +
      'material-icons">warning</mat-icon>) symbol. It is just cautioning you.</span>',
  },
  {
    title: "How do I find what's new in eCAPS?",
    videoUrl: null,
    html:
      "<span>To locate what's new in this release of eCAPS, simply click on the release number at the bottom of the landing" +
      " page of eCAPS. This will open the release notes to tell you what's been updated in the last few releases of eCAPS so you" +
      ' always know what has been updated.</span>' +
      '<div style="margin-top: 10px; padding: 10px; color: #fff; background-color: #00316C;">Version ' +
      environment.version +
      ' ' +
      environment.versionDate +
      ' &copy; ' +
      environment.copyrightBase +
      '-' +
      new Date().getFullYear().toString() +
      ' Greenheck Fan Corp.</div>',
  },
];
