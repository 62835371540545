import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-erv-spreadsheet-dialog',
  templateUrl: './erv-spreadsheet-dialog.component.html',
  styleUrls: ['./erv-spreadsheet-dialog.component.scss'],
})
export class ErvSpreadsheetDialogComponent implements OnInit {
  dataSource = [
    {
      model: 'MiniVent',
      merv8: 'X',
      merv13: 'X',
    },
    {
      model: 'MiniCore',
      merv8: 'X',
      merv13: 'X',
    },
    {
      model: 'ECV',
      merv8: 'X',
      merv13: 'X',
    },
    {
      model: 'ERV',
      merv8: 'X',
      merv13: 'X',
    },
    {
      model: 'ERVe',
      merv8: 'X',
      merv13: 'X',
    },
  ];
  dataSourceExtra = [
    {
      model: 'MiniVent',
      terminalStrip: 'X',
      microprocessor: '',
      singleWall: '',
      doubleWall: 'X',
    },
    {
      model: 'MiniCore',
      terminalStrip: 'X',
      microprocessor: '',
      singleWall: 'X',
      doubleWall: '',
    },
    {
      model: 'ECV',
      terminalStrip: 'X',
      microprocessor: 'X',
      singleWall: 'X',
      doubleWall: 'X',
    },
    {
      model: 'ERV',
      terminalStrip: 'X',
      microprocessor: 'X',
      singleWall: 'X',
      doubleWall: 'X',
    },
    {
      model: 'ERVe',
      terminalStrip: 'X',
      microprocessor: 'X',
      singleWall: '',
      doubleWall: 'X',
    },
  ];

  constructor(private dialogRef: MatDialogRef<ErvSpreadsheetDialogComponent>) {}

  ngOnInit() {}

  closeClick() {
    this.dialogRef.close();
  }
}
