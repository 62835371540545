import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EcapsCore } from '../../../ecaps-core/controllers/ecaps-core.controller';
import { GoogleMapsService } from '../../../google/services/google-maps.service';
import { ProjectSummary } from '../../models/project-summary.model';
import { Project } from '../../models/project.model';
import { ProjectsService } from '../../services/projects.service';
import { ProjectPropertiesDialogData } from './models/project-properties-dialog-data.model';

@Component({
  selector: 'app-project-properties-dialog',
  templateUrl: './project-properties-dialog.component.html',
  styleUrls: ['./project-properties-dialog.component.scss'],
})
export class ProjectPropertiesDialogComponent implements OnInit {
  formGroup: UntypedFormGroup;
  jobName: FormControl<string>;
  location: FormControl<string | null>;

  currentProject: Project | ProjectSummary;

  validate: boolean;

  copyProject: boolean;

  constructor(
    private dialogRef: MatDialogRef<ProjectPropertiesDialogComponent>,
    private projects: ProjectsService,
    private googleMaps: GoogleMapsService,
    private core: EcapsCore,
    @Inject(MAT_DIALOG_DATA) private data: ProjectPropertiesDialogData
  ) {
    this.copyProject = data.copyProject;

    this.validate = false;

    this.jobName = new FormControl<string>(data.name, [Validators.required]);
    this.location = new FormControl<string | null>(data.location, {
      updateOn: 'blur',
      asyncValidators: [
        (control: FormControl<string>) => {
          return new Promise<ValidationErrors | null>((resolve) => {
            // TODO: Remove this when the Google Maps API is working.
            resolve(null);
            return;

            if (!!control.value && control.value.trim() !== '') {
              this.googleMaps.apiLoaded.then(() => {
                this.googleMaps.geocodeAddress(control.value).then(
                  (address) => {
                    if (
                      !control.value ||
                      control.value !== address.formattedAddress
                    )
                      control.setValue(address.formattedAddress);

                    resolve(null);
                  },
                  () => {
                    resolve({ invalidAddress: true });
                  }
                );
              });
            } else {
              if (!!control.value && control.value !== control.value.trim())
                control.setValue('');

              resolve(null);
            }
          });
        },
      ],
    });

    this.formGroup = new UntypedFormGroup({
      jobName: this.jobName,
      location: this.location,
    });

    if (!!data.project) {
      this.currentProject = data.project;

      this.jobName.setValue(this.currentProject.name);
    } else {
      this.projects.getLocalProject().then((project) => {
        this.currentProject = project;

        this.jobName.setValue(this.currentProject.name);
      });
    }
  }

  ngOnInit() {}

  okClick() {
    if (this.formGroup.pending) {
      setTimeout(() => {
        this.okClick();
      }, 100);

      return;
    } else if (this.formGroup.invalid) {
      return;
    }

    let updating = true;

    this.core.showLoadingGraphic('Updating...', function () {
      return updating;
    });

    if (this.currentProject instanceof Project) {
      const updateProject = () => {
        this.projects
          .updateProject(this.currentProject as Project, {
            name: this.jobName.value,
            location: this.location.value,
          })
          .then(
            () => {
              this.projects
                .saveProject(
                  this.currentProject as Project,
                  'Project Properties Lightbox',
                  'OK'
                )
                .then(() => {
                  updating = false;

                  this.core.hideLoadingGraphic();

                  this.dialogRef.close(true);
                });
            },
            () => {
              updating = false;

              this.core.hideLoadingGraphic();

              this.dialogRef.close(false);
            }
          );
      };

      if (this.copyProject === true) {
        this.projects
          .copyProject((this.currentProject as Project).key)
          .then((newProject) => {
            this.currentProject = newProject;

            this.projects.setLocalProject(newProject);

            updateProject();
          });
      } else {
        updateProject();
      }
    } else if (this.currentProject instanceof ProjectSummary) {
      this.projects
        .updateSavedProject(this.currentProject as ProjectSummary, {
          name: this.jobName.value,
          location: this.location.value,
        })
        .then(() => {
          if (!!this.data.project) {
            this.data.project.name = this.jobName.value;

            this.data.project.location = this.location.value;

            // EC-3366: Update the display name of the local project if the user updates a project they have open.
            this.projects.getLocalProject().then((localProject) => {
              if (this.data.project.id === localProject.id) {
                localProject.name = this.data.project.name;

                localProject.location = this.data.project.location;

                this.projects.setLocalProject(localProject);
              }
            });
          }

          updating = false;

          this.core.hideLoadingGraphic();

          this.dialogRef.close(true);
        });
    }
  }
}
