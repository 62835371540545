import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { environment } from '../../../../environments/environment';
import { IAsyncCall } from '../../../analytics/models/i-async-call.interface';
import { IEcapsState } from '../../../analytics/models/i-ecaps-state.interface';
import { ProjectsService } from '../../../projects/services/projects.service';
import { LayoutService } from '../../../selections/services/layout.service';
import { UsersService } from '../../../users/services/users.service';

export enum methods {
  post = 'POST',
  get = 'GET',
  put = 'PUT',
  delete = 'DELETE',
}

@Injectable({
  providedIn: 'root',
})
export class EcapsStateService {
  private pendingAsyncCalls: IAsyncCall[] = [];

  layoutService: LayoutService;
  projectsService: ProjectsService;
  usersService: UsersService;

  constructor() {}

  addPendingAsyncCall(
    url: string,
    method: methods | string,
    data?: any
  ): string {
    const key = uuid();

    this.pendingAsyncCalls.push({
      key: key,
      url: url,
      method: method,
      data: data,
    });

    return key;
  }

  removePendingAsyncCall(key: string) {
    const index = this.pendingAsyncCalls.findIndex((item) => item.key === key);

    if (index > -1) {
      this.pendingAsyncCalls.splice(index, 1);
    }
  }

  getCurrentState(): Promise<IEcapsState> {
    return new Promise<IEcapsState>(async (result) => {
      const state: IEcapsState = {
        environment: environment.environment,
        version: environment.version,
        layoutConfig: null,
        project: null,
        userData: null,
        localStorage: window.localStorage,
        currentUrl: window.location.pathname,
        pendingAsyncCalls: this.pendingAsyncCalls,
      };

      if (!!this.layoutService) {
        try {
          state.layoutConfig = await this.layoutService.getLastLayoutConfig();
        } catch {
          state.layoutConfig = null;
        }
      }

      if (!!this.projectsService) {
        try {
          const project = await this.projectsService.getLocalProject();

          if (!!project) {
            state.project = project.toJSON();

            const activeRevision = state.project.revisions
              .find((revision) => revision.active)
              .toJSON();

            state.project.revisions = [activeRevision];

            activeRevision.items = activeRevision.items.map((item) => {
              const jsonMap = {
                areaServed: item.areaServed,
                details: item.details,
                id: item.id,
                location: item.location,
                name: item.name,
                notes: item.notes,
                quantity: item.quantity,
                tag: item.tag,
                type: item.type,
                version: item.version,
                sizeData: item.sizeData,
              };

              if (!!jsonMap.sizeData) {
                jsonMap.sizeData = {
                  id: jsonMap.sizeData.id,
                  model: jsonMap.sizeData.model,
                  name: jsonMap.sizeData.name,
                  selectionLayoutConfig: jsonMap.sizeData.selectionLayoutConfig,
                  selectionKey: jsonMap.sizeData.selectionKey,
                  layoutConfig: jsonMap.sizeData.layoutConfig,
                };
              }

              return jsonMap;
            });
          }
        } catch {
          state.project = null;
        }
      }

      if (!!this.usersService) {
        try {
          state.userData = await this.usersService.getCurrentUser(false);
        } catch {
          state.userData = null;
        }
      }

      result(state);
    });
  }
}
