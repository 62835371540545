import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DocumentTypes } from '../../documents/enums/document-types.enum';
import {
  EventCategories,
  GoogleAnalyticsService,
} from '../../google/services/google-analytics.service';
import { Users } from '../../users/controllers/users.controller';
import { UsersService } from '../../users/services/users.service';
import {
  EcapsStateService,
  methods,
} from '../handlers/services/ecaps-state.service';
import { DocumentRequest } from './models/document-request.model';
import { DrawingRequest } from './models/drawing-request.model';
import { FeedbackRequest } from './models/feedback-request.model';
import { ShareProjectRequest } from './models/share-project-request.model';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(
    private http: HttpClient,
    private users: Users,
    private usersService: UsersService,
    private gaServices: GoogleAnalyticsService,
    private ecapsState: EcapsStateService
  ) {}

  sendFeedback(request: FeedbackRequest): Promise<void> {
    return new Promise<void>((results, reject) => {
      const url = `${environment.email.url}/feedback`;
      const stateKey = this.ecapsState.addPendingAsyncCall(
        url,
        methods.post,
        request
      );

      const subscription = this.http.post(url, request).subscribe({
        next: () => {
          results();
        },
        error: (errorData) => {
          reject(errorData);
        },
        complete: () => {
          subscription.unsubscribe();

          this.ecapsState.removePendingAsyncCall(stateKey);
        },
      });
    });
  }

  sendDrawings(request: DrawingRequest): Promise<any> {
    return new Promise<any>((results, reject) => {
      this.users.showLoginRequiredDialog(false).then((success) => {
        if (success) {
          this.usersService.getCurrentUser().then((userData) => {
            const url = `${environment.email.url}/drawings`;

            const stateKey = this.ecapsState.addPendingAsyncCall(
              url,
              methods.post,
              request
            );

            const subscription = this.http
              .post(url, request, {
                headers: new HttpHeaders({
                  'x-user-token': userData.token,
                }),
              })
              .subscribe({
                next: () => {
                  results(null);
                },
                error: (errorData) => {
                  reject(errorData);
                },
                complete: () => {
                  subscription.unsubscribe();

                  this.ecapsState.removePendingAsyncCall(stateKey);
                },
              });
          });
        }
      });
    });
  }

  sendDocuments(
    projectKey: string,
    revisionID: string,
    request: DocumentRequest
  ): Promise<any> {
    return new Promise<any>((results, reject) => {
      this.users.showLoginRequiredDialog(false).then((success) => {
        if (success) {
          this.usersService.getCurrentUser().then((userData) => {
            // Remove Revit as a valid email option.
            if (
              request.attachment.documentTypes.indexOf(DocumentTypes.revit) >= 0
            ) {
              request.attachment.documentTypes.splice(
                request.attachment.documentTypes.indexOf(DocumentTypes.revit),
                1
              );
            }

            const url = `${environment.email.url}/documents/project/${projectKey}/revisions/${revisionID}`;
            const stateKey = this.ecapsState.addPendingAsyncCall(
              url,
              methods.post,
              request
            );

            const subscription = this.http
              .post(url, request, {
                headers: new HttpHeaders({
                  'x-user-token': userData.token,
                }),
              })
              .subscribe({
                next: () => {
                  this.gaServices.trackEvent_Old(
                    EventCategories.documentDownload,
                    'Email',
                    request.attachment.documentTypes.join(', ')
                  );

                  results(null);
                },
                error: (errorData) => {
                  reject(errorData);
                },
                complete: () => {
                  subscription.unsubscribe();

                  this.ecapsState.removePendingAsyncCall(stateKey);
                },
              });
          });
        }
      });
    });
  }

  sendShareProject(
    projectKey: string,
    revisionID: string,
    request: ShareProjectRequest
  ): Promise<any> {
    return new Promise<any>((results, reject) => {
      this.users.showLoginRequiredDialog(false).then((success) => {
        if (success) {
          this.usersService.getCurrentUser().then((userData) => {
            const url = `${environment.email.url}/shareJob/project/${projectKey}/revisions/${revisionID}`;

            const stateKey = this.ecapsState.addPendingAsyncCall(
              url,
              methods.post,
              request
            );

            const subscription = this.http
              .post(url, request, {
                headers: new HttpHeaders({
                  'x-user-token': userData.token,
                }),
              })
              .subscribe({
                next: () => {
                  this.gaServices.trackEvent_Old(
                    EventCategories.sharing,
                    'Project Shared'
                  );

                  results(null);
                },
                error: (errorData) => {
                  reject(errorData);
                },
                complete: () => {
                  subscription.unsubscribe();

                  this.ecapsState.removePendingAsyncCall(stateKey);
                },
              });
          });
        }
      });
    });
  }
}
