import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  Injector,
} from '@angular/core';
import { ColumnList } from '../../constants/column-list.const';
import { BaseGridComponent } from '../../base-grid/base-grid.component';
import { Subscription } from 'rxjs';
import { SelectionResults } from '../../../models/selection-results/selection-results.model';
import { ValidSize } from '../../../models/selection-results/valid-size.model';
import { ZeroToDashPipe } from '../../../../ecaps-core/pipes/custom-pipes.pipe';
import { ProjectsService } from '../../../../projects/services/projects.service';
import { Project } from '../../../../projects/models/project.model';
import { IProjectEvent } from '../../../../projects/models/i-project-event.interface';
import { ProjectRevisionUpdateTypes } from '../../../../projects/enums/project-revision-update-types.enum';
import { Item } from '../../../../projects/models/project-item.model';

@Component({
  selector: 'doas-rv-grid',
  templateUrl: '../../base-grid/base-grid.component.html',
  styleUrls: ['../../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DoasRvGridComponent
  extends BaseGridComponent
  implements OnInit, OnDestroy
{
  private selectionResultsSubscription: Subscription;

  private localProject: Project;
  private localProjectSubscription: Subscription;
  private projectItemsUpdatedSubscription: Subscription;
  private projectsService: ProjectsService;

  constructor(protected injector: Injector) {
    super(injector);

    this.projectsService = injector.get(ProjectsService);

    this.showSizeCompare = false;

    this.projectsService.getLocalProject().then((project) => {
      this.localProject = project;

      this.resetProjectItemsUpdated();
    });

    this.localProjectSubscription =
      this.projectsService.localProjectUpdated.subscribe((project) => {
        this.localProject = project;

        this.resetProjectItemsUpdated();
      });
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'ervOption',
      'relativeCost',
      'coolingLat',
      'totalCoolingCap',
      'heatingLatDb',
      'heatingCap',
      'mcaMop',
      'weight',
      'dimensions',
      'supplyOutletSones',
      'supplyOutletDba',
      'inletSones',
      'exhaustInletSoundDba',
      'ashpDeratedLeavingAirDryBulbHeat',
      'ashpDeratedHeatingCapacity',
      'erMedia',
      'erDevice',
      'exhaustAirTransferRatio',
      'err'
    );

    const exhaustInletSones = this.columnList.find(
      (item) => item.key === 'inletSones'
    );

    exhaustInletSones.text = 'Exhaust Inlet Sones';

    this.setVisibleColumns([
      'ervOption',
      'relativeCost',
      'coolingLat',
      'totalCoolingCap',
      'heatingLatDb',
      'heatingCap',
      'mcaMop',
      'weight',
      'dimensions',
    ]);

    this.selectionResultsSubscription = this.selectionResultsReceived.subscribe(
      (selectionResults: SelectionResults) => {
        const newData = [];

        selectionResults.validSizes.forEach((size) => {
          newData.push(this.validSizeToDSRow(size));
        });

        this.dataSource.data = newData;
      }
    );

    this.updateColumnHelp();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.selectionResultsSubscription.unsubscribe();

    if (!!this.projectItemsUpdatedSubscription) {
      this.projectItemsUpdatedSubscription.unsubscribe();
    }

    this.localProjectSubscription.unsubscribe();
  }

  private validSizeToDSRow(size: ValidSize) {
    const zeroDash = new ZeroToDashPipe();

    const rowData = {
      rank: size.sorting.recommendedOrder,
      name: size.name,
      relativeCost: size.pricing.relativePrice,
      coolingLat: `${zeroDash.transform(
        size.outputs.coolingModel.leavingAirDryBulb,
        '1.1-1'
      )} /
        ${zeroDash.transform(
          size.outputs.coolingModel.leavingAirDewPoint,
          '1.1-1'
        )}`,
      totalCoolingCap: size.outputs.coolingModel.coolingCapacity,
      heatingLatDb: size.outputs.heatingModel.leavingDryBulb,
      heatingCap: `${zeroDash.transform(
        size.outputs.heatingModel.heatingCapacity,
        '1.0-0',
        size.outputs.heatingModel.selectedHeatingModelType
          .toLowerCase()
          .trim() === 'electric'
          ? 'kW'
          : 'MBH'
      )}`,
      mcaMop: `${zeroDash.transform(
        size.outputs.fanModel.minimumCircuitAmps,
        '1.1-1'
      )} /
        ${zeroDash.transform(
          size.outputs.fanModel.maximumOvercurrentProtection,
          '1.0-1'
        )}`,
      weight: size.pricing.weight,
      dimensions: `${zeroDash.transform(
        size.outputs.housingWheelModel.dimensions.length,
        '1.0-0'
      )} x
        ${zeroDash.transform(
          size.outputs.housingWheelModel.dimensions.width,
          '1.0-0'
        )} x
        ${zeroDash.transform(
          size.outputs.housingWheelModel.dimensions.height,
          '1.0-0'
        )}`,
      supplyOutletSones:
        size.outputs.fanModel.sound.supplySound.outletSoundSones,
      supplyOutletDba: size.outputs.fanModel.sound.supplySound.outletSoundDba,
      inletSones: size.outputs.fanModel.sound.exhaustSound.inletSoundSones,
      exhaustInletSoundDba:
        size.outputs.fanModel.sound.exhaustSound.inletSoundDba,
      stable: size.stability.stable,
      ashpDeratedLeavingAirDryBulbHeat: !!size.outputs.coolingModel
        .airSourceHeatPump
        ? size.outputs.coolingModel.airSourceHeatPump
            .ashpDeratedLeavingAirDryBulbHeat
        : 0,
      ashpDeratedHeatingCapacity: !!size.outputs.coolingModel.airSourceHeatPump
        ? size.outputs.coolingModel.airSourceHeatPump.ashpDeratedHeatingCapacity
        : 0,
      erDevice:
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .energyRecoveryTypeActual,
      erMedia:
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .energyRecoveryMedia,
      exhaustAirTransferRatio: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .exhaustAirTransferRatio,
        '1.0-1'
      )}`,
      err: `${zeroDash.transform(
        size.outputs.housingWheelModel.energyRecoveryPerformance
          .totalOutdoorEfficiencySummer,
        '1.0-1'
      )}`,
      sizeData: size,
    };

    return rowData;
  }

  private resetProjectItemsUpdated() {
    if (!!this.projectItemsUpdatedSubscription) {
      this.projectItemsUpdatedSubscription.unsubscribe();
    }

    if (!!this.localProject) {
      this.projectItemsUpdatedSubscription = this.localProject
        .getActiveRevision()
        .updated.subscribe((data: IProjectEvent) => {
          if (data.type === ProjectRevisionUpdateTypes.itemAdded) {
            this.sizeAdded.next((data.source as Item).sizeData);
          }
        });
    }
  }
}
