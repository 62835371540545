import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { BaseGridComponent } from '../base-grid/base-grid.component';
import { ColumnList } from '../constants/column-list.const';
import { ProductTypes } from '../../../../products/enums/product-types.enum';

@Component({
  selector: 'makeupair-equipment-schedule-grid',
  templateUrl: '../base-grid/base-grid.component.html',
  styleUrls: ['../base-grid/base-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MakeupairEquipmentScheduleGridComponent
  extends BaseGridComponent
  implements OnInit
{
  constructor(protected injector: Injector) {
    super(injector);

    this.productType = ProductTypes.makeUpAir;
  }

  ngOnInit() {
    this.columnList = ColumnList.getColumns(
      'location',
      'areaServed',
      'model',
      'actualVolume',
      'esp',
      'motorSizeHP',
      'motorQty',
      'vcp',
      'supplyFanBHP',
      'supplyFanRPM',
      'heatingLat',
      'heatingCap',
      'coolingDBWB',
      'coolingCap',
      'mcaMop',
      'weight',
      'dimensions',
      'sones',
      'dba'
    );

    this.setVisibleColumns([
      'location',
      'areaServed',
      'model',
      'actualVolume',
      'esp',
      'motorSizeHP',
      'vcp',
      'heatingCap',
      'coolingCap',
      'mcaMop',
      'sones',
      'weight',
    ]);
  }
}
