import { GeoLocation } from './geo-location.model';
import { GeoBounds } from './geo-bounds.model';

export class Geometry {
    bounds: GeoBounds;
    location: GeoLocation;
    locationType: string;
    viewport: GeoBounds;
    constructor(data: any) {
        this.bounds = (data.bounds ? new GeoBounds(data.bounds) : null);
        this.location = new GeoLocation(data.location);
        this.locationType = data.location_type;
        this.viewport = new GeoBounds(data.viewport);
    }
}
